import React, {useState, useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import { useStoragedState } from 'cli/ui/hooks/useStoragedState'
import { b64toBlob } from 'farrapa/encoding'
import FileSaver from 'file-saver'

import Page             from 'cli/ui/pages/layout/Page'
import {Row, Col}       from 'reactstrap'
import { ActionAdd, ActionDocX }    from 'cli/ui/components/buttons/actions'
import {LoadingBig}     from 'cli/ui/components/loading'
import DocsFilter       from './DocsFilter'
import DocsList         from './DocsList'
import DocsPrepareForm  from './DocsPrepareForm'
import { ActionGo } from 'cli/ui/components/buttons/actions'
import { IfPermission } from 'cli/context/IfPermission'
import { DOCS_FOR_PENALTY } from 'bl/ns/process/steps'
import withContext from 'cli/context/withContext'


const Docs = ({fetcher, docGroup}) => {
  const location= useLocation()
  const navigate= useNavigate()

  const [docs, setDocs]= useState([])
  const [filteredDocs, setFilteredDocs]= useState(undefined)

  const [showPrepare, setShowPrepare]= useState(false)
  
  const [filterFields, setFilterFields]= useStoragedState({
    'i5': true,
    'lu': true,
    'ae': true,
    'to': true,
    'vt': true,
    'shared': true
  })

  const [urlFilter, setUrlFilter]= useState(false)
  const [breads, setBreads]= useState([])
  const [title, setTitle]= useState('Documentos')


  // If URL params are right, go to details page
  useEffect(() => {
    if (urlFilter===false) {
      setUrlFilter(true)

      const urlParams = new URLSearchParams(location.search)
      const processDoc= parseInt(urlParams.get('processDoc'))
      if (! isNaN(processDoc)) {
        const taxCode= urlParams.get('taxCode')
        if (taxCode) {
          const fDoc= docs.find((d) => d.tax_code==taxCode && d.process_doc==processDoc)
          if (fDoc) {
            navigate(`/docs/det/${fDoc.id}`)
          }          
        }
      }
    }
  }, [urlFilter, docs, navigate, location.search]) 

  // Set breads and title on load
  useEffect(() => {
    const nTitle= `Documentos de ${docGroup=='inspect' ? 'Inspección' : 'Sancionador'}`
    setBreads([
      ['/', 'Inicio'], ['/docs', 'Gestión Documental'], [nTitle]
    ])
    setTitle(nTitle)
  }, [docGroup])

  // Fetch data on load
  useEffect(() => {
    async function _fetchData() {
      const res = await fetcher.get('/api/doc/detail', {group: docGroup})
      const nDocs = res.data
      setDocs(nDocs)
    }
    _fetchData()
  }, [fetcher, docGroup])


  // Filter docs
  useEffect(() => {
    const filterit = (doc) => {
      const processDoc = doc.process_doc
      //const docPType= doc?.conditions?.penalty_type
      const docTax=  doc?.conditions?.tax_code
      const fltTaxes= Object.entries(filterFields)
                      .filter(([_k,v]) => v)
                      .map(([k,_v]) => k)
      
      const pTypeOk= (docGroup=='inspect' && DOCS_FOR_PENALTY.indexOf(processDoc)<0) || (docGroup=='penalty'  && DOCS_FOR_PENALTY.indexOf(processDoc)>=0)
      const taxOk= docTax==undefined || fltTaxes.indexOf(docTax)>=0
      const sharedOk= docTax!=undefined || filterFields.shared
      return pTypeOk && taxOk && sharedOk

    }
    
    setFilteredDocs(undefined)
    const nDocs= docs.filter(filterit)
    setFilteredDocs(nDocs)
  }, [docs, filterFields, docGroup])

  const handleRemove = async (docId) => {
    const done = await fetcher.remove('/api/doc', docId)
    if (done) {
      let nDocs= [...docs]
      nDocs.splice(
        nDocs.find((c) => c.id==docId), 1
      )
      setDocs(nDocs)
    }
    return done
  }

  const handleVersion = async (docId) => {
    const res = await fetcher.post('/api/doc/version', {id: docId})
    if (res.data) {
      navigate(`/docs/det/${res.data}`)
    }
  }

  const handleDownload = async (docId) => {
    try {
      const res = await fetcher.get('/api/doc/download_doc', {id: docId})
      const doc = res.data
      const b = b64toBlob(doc.buffer, doc.type)
      FileSaver.saveAs(b, doc.name)    
    } catch(e) {
      alert('Error descargando fichero')
      console.error(e)
    }
  }

  const handlePrepare = async (file) => {
    const res = await fetcher.get('/api/doc/prepare_doc', {doc: file})
    const doc = res.data
    const b = b64toBlob(doc.buffer, doc.type)
    FileSaver.saveAs(b, doc.name)      
  }

  
  return (
    <Page breads = {breads}
          title  = {title}
          actions= {<>
                      {docGroup=='penalty'
                       ? <ActionGo to="/docs/inspect" label="Ver docs de Inspección"/>
                       : <ActionGo to="/docs/penalty" label="Ver docs de Sancionador"/>
                      }
                      <IfPermission code="docs_edit">
                        <ActionDocX onClick={() => setShowPrepare(!showPrepare)} label="Preparar doc"/>
                        <ActionAdd to="/docs/det/new" label="Crear nuevo"/>
                      </IfPermission>
                    </>}>
      {showPrepare
       ? 
         <Row>
           <Col xs="12">
             <DocsPrepareForm onCancel ={() => setShowPrepare(false)}
                              onUpload ={(file) => handlePrepare(file)}/>  
           </Col>
         </Row>
       : null}
      <Row>
        <Col xs="12">
          <DocsFilter filterFields={filterFields}
                      onChangeFilter={(n,v) => setFilterFields({...filterFields, [n]: v})}/>
        </Col>
      </Row>
      <Row>
        <Col xs="12">

         {filteredDocs==undefined
          ? <LoadingBig />
          : <DocsList 
              docs={filteredDocs}
              onRemove={(cid) => handleRemove(cid)}
              onVersion={(cid) => handleVersion(cid)}
              onDownload={(cid) => handleDownload(cid)}/>    
         }
        </Col>
      </Row>
    </Page>
  )
}


export default withContext(Docs)








