import React from "react"


const CalendarEventMore = ({onClick, count}) => 
   <div className="event empty ellipsis" onClick={(ev) => {ev.stopPropagation(); onClick()}}>
      <div className={`event-content`}>
        ({count})
      </div>
   </div>

export default CalendarEventMore