
import React, {useEffect, useState}  from 'react'
import Line from 'cli/ui/components/charts/Line'

const IRatesChart = ({iRates, onSelectIRate}) => {
  const [min, setMin]= useState(99)
  const [max, setMax]= useState(0)

  useEffect(( )=> {
    let nMin = 99, nMax= 0
    iRates.map((irate) => {
      nMin = Math.min(nMin, irate.value)
      nMax = Math.max(nMax, irate.value)
    })
    setMin(nMin)
    setMax(nMax)
  }, [iRates])

  return (
    <div className= "chart-wrapper">
      <Line data   = { iRates.map((i) => i['value'])           }
            labels = { iRates.map((i) => i['year'].toString()) }
            onClick= { (idx) => {onSelectIRate(idx)}}
            min    = { parseInt(min - 1) }
            max    = { parseInt(max + 1) }
      />
    </div>
  )
}



export default IRatesChart

