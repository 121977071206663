import { range }    from 'farrapa/iter'
import { collSort, collTotalBy } from 'farrapa/collections'

import ProcessAETaxDetCalc from './ProcessAETaxDet'
import {getCoefficientByRevenues}from './ProcessAETaxTables'

class ProcessAETaxCalc  {

  id                 = undefined
  process_id         = undefined
  section            = undefined
  epigraph           = ''
  division           = undefined

  //custom_coefficient = undefined
  province_tax       = undefined
  location_index     = undefined
  revenues           = undefined
  _details       = []

  license = {}

  constructor(obj, process) {
    this.process= process
    this.update(obj)
  }

  update(obj) {
    if (typeof obj == 'object') {
      Object.keys(this)
            .filter((k) => Object.prototype.hasOwnProperty.call(obj, k))
            .filter((k) => ['details'].indexOf(k)==-1)
            .map((k) => {
              this[k]= obj[k]
            })
      this._details= obj.details || []
    }

    if (this.process.id!=undefined)
      this.process_id = this.process.id
    

      /// NOTE: On the first load, db record for taxes does not exists,
      /// so its strange to autfill this values but just after saving the tax
    if (this.license?.id != undefined) {
      if (this.province_tax == undefined) {
        this.province_tax= this.license.province_tax
      }
      if (this.location_index == undefined) {
        this.location_index= this.license.location_index
      }
      if (this.revenues == undefined) {
        this.revenues= this.license.revenues
      }
      if (this.epigraph == undefined) {
        this.epigraph= this.license.epigraph
      }
      if (this.section == undefined) {
        this.section= this.license.section
      }
      if (this.division == undefined) {
        this.division= this.license.division
      }
    }
  }

  get coefficient() {
    /*if (! isNaN(this.custom_coefficient) && this.custom_coefficient>0) {
      return this.custom_coefficient
    }*/
    return getCoefficientByRevenues(this.revenues)
  }


  get details() {
    let details = []

    range(this.process.year_from, this.process.year_to).map((year) => {
      const dat = this._details.find((d) => d.year==year) || {year: year}
      const det = new ProcessAETaxDetCalc(this, dat)
      details.push(det)
    })

    return collSort(details, 'year')
  }


  get ready_for_interests() {
    const miss_council= (isNaN(this.process.council_inhabitants) || this.process.council_inhabitants<=0) 
    const miss_from= (isNaN(parseInt(this.process.ordinance_tax_data.liq_limit_day)) || isNaN(parseInt(this.process.ordinance_tax_data.liq_limit_month)))    
    const miss_to = isNaN(parseInt(this.process.end_liq_date))
    const miss_activity = (! this.process.activity)
    return {
      ok: ! (miss_council || miss_from || miss_to || miss_activity),
      missing: {
        council: miss_council,
        from: miss_from,
        to: miss_to,
        activity: miss_activity
      }
    }
  }

  get ready() {
    return this.ready_for_interests.ok 
  }


  get total() {
    return collTotalBy(this.details, 'total_debt')
  }

  forDocx() {
    return {
      epigraph           : this.epigraph,
      //interests          : this.interests,
      total              : this.total,
      details            : this.details.map((d) => d.forDocx()),
      coefficient        : this.coefficient,
      location_index     : this.location_index,
      license            : this.license || {}

    }
  }  
  
  toJson() {
    return {
      id                 : this.id             ,
      process_id         : this.process_id     ,

      section            : this.section     ,
      epigraph           : this.epigraph    ,
      division           : this.division    ,
      //custom_coefficient : this.custom_coefficient,
      location_index     : this.location_index,
      province_tax       : this.province_tax,
      revenues           : this.revenues,
      license            : this.license,

      details            : this.details.map((d) => d.toJson())
    }
  }  

  export() {
    return {
      ...this.toJson(),
      ready_for_interests: this.ready_for_interests,
      ready              : this.ready,
      coefficient        : this.coefficient,
      section: this.section,
      epigraph: this.epigraph,
      division: this.division,
      total: this.total,
    }
  }
}


export default ProcessAETaxCalc


