import React from 'react'
import {/*FInputCheckbox,*/ FInputSelectSearch} from 'formiga-reactstrap'

//const CalendarFilter = ({filter, shownCalendars, usedCalendars, councils, onFilterChange, onToggleShownCalendar}) => {
const CalendarFilter = ({filter, councils, onFilterChange}) => {
  if (Object.keys(councils).length<=0) {
    return null
  }

  return (
    <div className="afi-calendar-filter">
      {/*
      <h2>Mostrar eventos</h2>

      {shownCalendars
        ? <div style={{display: "table", width: "100%"}}>
            {usedCalendars.map((cal) =>
              <div key = {`calendar_filter_${cal.id}`} style={{display: "table-row"}}>
                <div style={{display: "table-cell", width: "50px"}}>
                  <FInputCheckbox 
                                  name           = {`calendar_filter_${cal.id}`}
                                  value          = {shownCalendars[cal.id] === true}
                                  onChange       = {(v) => onToggleShownCalendar(cal.id, v)}
                                  icon           = {false}
                                  showValidity   = {0}
                                  />
                </div>
                <div style={{display: "table-cell", textAlign: "left"}}>
                  <div style={{display: "inline-block", fontWeight: "bold", backgroundColor: cal.color, color: "#663b3b", padding: "0 0.5em"}}>
                    {`${cal.name} (${cal.event_count})`}
                  </div>
                </div>               
              </div>
            )}
          </div>
        : null
      }
      <br/>
      <FInputCheckbox 
                      name           = {`calendar_filter_show_done`}
                      value          = {filter.show_done}
                      onChange       = {(v) => onFilterChange('show_done', v)}
                      icon           = {false}
                      checkboxLabel  = {'Mostrar hechos'}
                      showValidity   = {0}
                      />
      <FInputCheckbox 
                      name           = {`calendar_filter_show_mine_pvt`}
                      value          = {filter.show_mine_pvt}
                      onChange       = {(v) => onFilterChange('show_mine_pvt', v)}
                      icon           = {false}
                      checkboxLabel  = {'Mostrar mis eventos privados'}
                      showValidity   = {0}
                      />      
      <FInputCheckbox 
                      name           = {`calendar_filter_show_mine_shared`}
                      value          = {filter.show_mine_shared}
                      onChange       = {(v) => onFilterChange('show_mine_shared', v)}
                      icon           = {false}
                      checkboxLabel  = {'Mostrar mis eventos compartidos'}
                      showValidity   = {0}
                      />   
      <FInputCheckbox 
                      name           = {`calendar_filter_show_team_shared`}
                      value          = {filter.show_team_shared}
                      onChange       = {(v) => onFilterChange('show_team_shared', v)}
                      icon           = {false}
                      checkboxLabel  = {'Mostrar los eventos compartidos por otros'}
                      showValidity   = {0}
                      />   
      <FInputCheckbox 
                      name           = {`calendar_filter_show_auto`}
                      value          = {filter.show_auto}
                      onChange       = {(v) => onFilterChange('show_auto', v)}
                      icon           = {false}
                      checkboxLabel  = {'Mostrar los eventos automáticos'}
                      showValidity   = {0}
                      />                                                                              
      */}
    
        <FInputSelectSearch 
                        options        = {councils}
                        name           = {`calendar_filter_councils`}
                        value          = {filter.council_id}
                        onChange       = {(v) => onFilterChange('council_id', v)}
                        icon           = {false}
                        showValidity   = {0}
                        clearable      = {true}
                        searchable     = {true}
                        placeholder    = {"Buscar ayuntamiento"}
                        />

    </div>




  )
}

export default CalendarFilter

/*

    <FInputSelect name="calendar"
                  icon={<span>Calendario</span>}
                  options={calendarMap}
                  value={calendar}
                  onChange={(v)=>selectCalendar(v)}
                  required={false}
                  novalidate
                  inline
    />    

    */