
import React from 'react'
import Hyper from './base'

const HyperTaxPayer = ({ id, name }) =>
  <Hyper bcolor  = "#e0e0d1"
         icon    = "taxpayer"
         to      = {"/taxpayers/det/" + id}
         text    = {name}
         permissionCode = "tax_payer_read"
         >
  </Hyper>

export default HyperTaxPayer
