import React, {useState, useEffect, useCallback} from 'react'
import {useNavigate, useParams} from 'react-router-dom'


// components
import Page from 'cli/ui/pages/layout/Page'
import {Row, Col} from 'reactstrap'
import { ActionBack } from 'cli/ui/components/buttons/actions'
import { LoadingBig } from 'cli/ui/components/loading'
import { I5, TAX_NAMES } from 'bl/ns/taxes'
import withContext from 'cli/context/withContext'


const OrdinanceDetails = ({fetcher, taxCode, taxDataFields, renderForm, permiss}) => {

  const navigate= useNavigate()
  const params= useParams()

  const [ordinance, setOrdinance]= useState({})
  const [nameList, setNameList]= useState([])
  const [nameOrig, setNameOrig]= useState('')
  const [councilName, setCouncilName]= useState('')
  const [status, setStatus]= useState(0)




  useEffect(() => {
    async function fetchData() {
      const nNameList = await fetcher.name_list('/api/ordinance')
      setNameList(nNameList) 

      if (isNew()) {
        setOrdinance({
          council_id: params.idCouncil,
          tax_data: {
            ... taxCode==I5
              ? {tax_rate: 1.5}
              : {}
          }
        })
        setNameOrig('')
      } else {
        await fetchOrdinance(params.idOrdinance)
      }

      const council= await fetcher.find('/api/council', params.idCouncil)
      setCouncilName(council.name)

      setStatus(1)
    }
    fetchData()
  }, [fetchOrdinance, fetcher, isNew, params.idCouncil, params.idOrdinance, taxCode])

  const isNew = useCallback(() => {
    return params.idOrdinance == 'new'
  }, [params.idOrdinance])


  const fetchOrdinance = useCallback(async(ordinanceId) => {
    const res = await fetcher.get('/api/ordinance/detail', {id: ordinanceId})
    const nVTOrdinances = res.data
    const nVTOrdinance = nVTOrdinances[0]
    setOrdinance(nVTOrdinance)
    setNameOrig(nVTOrdinance.name)
  }, [fetcher])


  const handleChange = useCallback((name, value) => {
    const nVTOrdinance= {...ordinance}
    if (taxDataFields.indexOf(name)>=0) {
      nVTOrdinance.tax_data= {
        ...nVTOrdinance.tax_data,
        [name]: value
      }
    } else {
      nVTOrdinance[name]= value
    }

    setOrdinance(nVTOrdinance)
  }, [ordinance, taxDataFields])

  const handleSave = useCallback(() => {
    async function _ordSave() {
      // TODO hack this better
      ordinance['tax_code']= taxCode
      const res= await fetcher.upsave('/api/ordinance', ordinance)
      if (isNew() && res>=1) {
        setStatus(0)
        navigate(`/councils/${params.idCouncil}/${taxCode}/${res}`)
        await fetchOrdinance(res)
        setStatus(1)

      }    
    }
    _ordSave()

  }, [fetchOrdinance, fetcher, isNew, navigate, taxCode, ordinance, params.idCouncil])

  const handleCancel = useCallback(() => {
    navigate(`/councils/${params.idCouncil}`)
  }, [params.idCouncil, navigate])

  const handleDelete = useCallback(() => {
    async function _ordDelete() {
      const _res= await fetcher.remove('/api/ordinance', ordinance.id)
      navigate(`/councils/${params.idCouncil}`)
    }
    _ordDelete()
  }, [fetcher, navigate, ordinance.id, params.idCouncil])

  const title = useCallback((short= false) => {
    return isNew() 
      ? `Creando nueva ordenanza de ${TAX_NAMES[taxCode]}`
      : ordinance.name
        ? short 
          ? ordinance.name.length>30
            ? ordinance.name.substr(0,30)+' ...'
            : ordinance.name
          : ordinance.name
        : ''
  }, [taxCode, ordinance, isNew])

  const existingNames = useCallback(() => {
    return nameList.filter((n) => n!=nameOrig)
  }, [nameList, nameOrig])


  return (
    <Page breads = {[['/', 'Inicio'], ['/councils', 'Ayuntamientos'], ['/councils/'+params.idCouncil, councilName], title(true)]}
          title  = {`Ordenanza: ${title()}`}
          actions= {
            <ActionBack to={`/councils/${params.idCouncil}`} label="Al ayuntamiento"/>
          }>
      <Row>
        <Col xs="12">
            {status < 1 
             ? <LoadingBig/>
             :
              renderForm({
                   ordinance       : ordinance,
                   existingNames   : existingNames(),
                   onChange        : (v,n) => handleChange(v,n),
                   onCancel        : () => handleCancel(),
                   onDelete        : () => handleDelete(),
                   onSave          : () => handleSave(),
                   isNew           : isNew(),
                   permiss         : permiss
                   })
            }
        </Col>

      </Row>
    </Page>
  )
}


export default withContext(OrdinanceDetails)

