
import {toPrice, sort} from '../../converters'


function makeIAETotalsHeading() {
  return `
  <w:tbl>
  <w:tblPr>
    <w:tblW w:w="8057" w:type="dxa" />
    <w:tblCellSpacing w:w="0" w:type="dxa" />
    <w:tblCellMar>
      <w:top w:w="75" w:type="dxa" />
      <w:left w:w="75" w:type="dxa" />
      <w:bottom w:w="75" w:type="dxa" />
      <w:right w:w="75" w:type="dxa" />
    </w:tblCellMar>
    <w:tblLook w:val="0000" w:firstRow="0" w:lastRow="0" w:firstColumn="0" w:lastColumn="0" w:noHBand="0"
      w:noVBand="0" />
  </w:tblPr>
  <w:tblGrid>
    <w:gridCol w:w="1474" />
    <w:gridCol w:w="914" />
    <w:gridCol w:w="914" />
    <w:gridCol w:w="914" />
    <w:gridCol w:w="914" />
    <w:gridCol w:w="914" />
    <w:gridCol w:w="914" />
    <w:gridCol w:w="914" />
    <w:gridCol w:w="185" />
  </w:tblGrid>
  <w:tr w:rsidR="00CD7292" w:rsidRPr="00CD7292" w14:paraId="535E54AC" w14:textId="77777777" w:rsidTr="00DA4137">
    <w:trPr>
      <w:trHeight w:val="195" />
      <w:tblCellSpacing w:w="0" w:type="dxa" />
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1474" w:type="dxa" />
        <w:tcBorders>
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="auto" w:fill="AACCFF" />
        <w:vAlign w:val="center" />
      </w:tcPr>
      <w:p w14:paraId="63EDE6AE" w14:textId="77777777" w:rsidR="00CD7292" w:rsidRPr="00CD7292" w:rsidRDefault="00CD7292"
        w:rsidP="00C30C04">
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00CD7292">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
          </w:rPr>
          <w:t>Totales</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="0" w:type="auto" />
        <w:tcBorders>
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="auto" w:fill="AACCFF" />
        <w:vAlign w:val="center" />
      </w:tcPr>
      <w:p w14:paraId="38B78325" w14:textId="77777777" w:rsidR="00CD7292" w:rsidRPr="00CD7292" w:rsidRDefault="00CD7292"
        w:rsidP="00C30C04">
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
          </w:rPr>
        </w:pPr>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="0" w:type="auto" />
        <w:tcBorders>
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="auto" w:fill="AACCFF" />
        <w:vAlign w:val="center" />
      </w:tcPr>
      <w:p w14:paraId="732EE56D" w14:textId="77777777" w:rsidR="00CD7292" w:rsidRPr="00CD7292" w:rsidRDefault="00CD7292"
        w:rsidP="00C30C04">
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
          </w:rPr>
        </w:pPr>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="0" w:type="auto" />
        <w:tcBorders>
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="auto" w:fill="AACCFF" />
        <w:vAlign w:val="center" />
      </w:tcPr>
      <w:p w14:paraId="69246F45" w14:textId="77777777" w:rsidR="00CD7292" w:rsidRPr="00CD7292" w:rsidRDefault="00CD7292"
        w:rsidP="00C30C04">
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
          </w:rPr>
        </w:pPr>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="0" w:type="auto" />
        <w:tcBorders>
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="auto" w:fill="AACCFF" />
        <w:vAlign w:val="center" />
      </w:tcPr>
      <w:p w14:paraId="35CDA0D4" w14:textId="77777777" w:rsidR="00CD7292" w:rsidRPr="00CD7292" w:rsidRDefault="00CD7292"
        w:rsidP="00C30C04">
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
          </w:rPr>
        </w:pPr>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="0" w:type="auto" />
        <w:tcBorders>
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="auto" w:fill="AACCFF" />
        <w:vAlign w:val="center" />
      </w:tcPr>
      <w:p w14:paraId="2C9FBB09" w14:textId="77777777" w:rsidR="00CD7292" w:rsidRPr="00CD7292" w:rsidRDefault="00CD7292"
        w:rsidP="00C30C04">
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
          </w:rPr>
        </w:pPr>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="0" w:type="auto" />
        <w:tcBorders>
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="auto" w:fill="AACCFF" />
        <w:vAlign w:val="center" />
      </w:tcPr>
      <w:p w14:paraId="06D70FD4" w14:textId="77777777" w:rsidR="00CD7292" w:rsidRPr="00CD7292" w:rsidRDefault="00CD7292"
        w:rsidP="00C30C04">
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
          </w:rPr>
        </w:pPr>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="0" w:type="auto" />
        <w:tcBorders>
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="auto" w:fill="AACCFF" />
        <w:vAlign w:val="center" />
      </w:tcPr>
      <w:p w14:paraId="0386FD67" w14:textId="77777777" w:rsidR="00CD7292" w:rsidRPr="00CD7292" w:rsidRDefault="00CD7292"
        w:rsidP="00C30C04">
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
          </w:rPr>
        </w:pPr>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="185" w:type="dxa" />
        <w:tcBorders>
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="auto" w:fill="AACCFF" />
        <w:vAlign w:val="center" />
      </w:tcPr>
      <w:p w14:paraId="6CB41389" w14:textId="77777777" w:rsidR="00CD7292" w:rsidRPr="00CD7292" w:rsidRDefault="00CD7292"
        w:rsidP="00C30C04">
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
          </w:rPr>
        </w:pPr>
      </w:p>
    </w:tc>
  </w:tr>
</w:tbl>

<w:p w14:paraId="64E32585" w14:textId="77777777" w:rsidR="00CD7292" w:rsidRPr="00705390" w:rsidRDefault="00CD7292"
  w:rsidP="00CD7292">
  <w:pPr>
    <w:ind w:left="426" w:right="-41" />
  </w:pPr>
</w:p>

  `
}

function makeIAETotalsRow(detail) {
  return `
  <w:tr w:rsidR="00DA4137" w:rsidRPr="00DA4137" w14:paraId="05739CB5" w14:textId="77777777" w:rsidTr="00DA4137">
    <w:trPr>
      <w:trHeight w:val="189" />
      <w:jc w:val="center" />
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="575" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="60A8AF03" w14:textId="77777777" w:rsidR="00DA4137" w:rsidRPr="00DA4137" w:rsidRDefault="00DA4137"
        w:rsidP="00D52B98">
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00DA4137">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>${detail.year}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="924" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="424EEADE" w14:textId="77777777" w:rsidR="00DA4137" w:rsidRPr="00DA4137" w:rsidRDefault="00DA4137"
        w:rsidP="00D52B98">
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00DA4137">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t xml:space="preserve">${toPrice(detail.checked_debt_total)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="808" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="68952C40" w14:textId="77777777" w:rsidR="00DA4137" w:rsidRPr="00DA4137" w:rsidRDefault="00DA4137"
        w:rsidP="00D52B98">
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00DA4137">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t xml:space="preserve">${toPrice(detail.declared_debt_total)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="849" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="3DCF78C7" w14:textId="77777777" w:rsidR="00DA4137" w:rsidRPr="00DA4137" w:rsidRDefault="00DA4137"
        w:rsidP="00D52B98">
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00DA4137">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t xml:space="preserve">${toPrice(detail.total_debt_difference)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="849" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="5B5948DA" w14:textId="77777777" w:rsidR="00DA4137" w:rsidRPr="00DA4137" w:rsidRDefault="00DA4137"
        w:rsidP="00D52B98">
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00DA4137">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t xml:space="preserve">${toPrice(detail.interest.interest)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="994" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="0B4CB5E1" w14:textId="77777777" w:rsidR="00DA4137" w:rsidRPr="00DA4137" w:rsidRDefault="00DA4137"
        w:rsidP="00D52B98">
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00DA4137">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t xml:space="preserve">${toPrice(detail.total_debt)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>
  `
}

function makeIAETotalsTotalRow(totals) {
  return `
  <w:tr w:rsidR="00DA4137" w:rsidRPr="00DA4137" w14:paraId="663391B7" w14:textId="77777777" w:rsidTr="00DA4137">
    <w:trPr>
      <w:trHeight w:val="200" />
      <w:jc w:val="center" />
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="575" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="02E83AF7" w14:textId="77777777" w:rsidR="00DA4137" w:rsidRPr="00DA4137" w:rsidRDefault="00DA4137"
        w:rsidP="00D52B98">
        <w:pPr>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00DA4137">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>Totales</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="924" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="222AB50D" w14:textId="77777777" w:rsidR="00DA4137" w:rsidRPr="00DA4137" w:rsidRDefault="00DA4137"
        w:rsidP="00D52B98">
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00DA4137">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t xml:space="preserve">${toPrice(totals.checked_debt_total)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="808" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="5B708AB7" w14:textId="77777777" w:rsidR="00DA4137" w:rsidRPr="00DA4137" w:rsidRDefault="00DA4137"
        w:rsidP="00D52B98">
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00DA4137">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t xml:space="preserve">${toPrice(totals.declared_debt_total)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="849" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="07867AE8" w14:textId="77777777" w:rsidR="00DA4137" w:rsidRPr="00DA4137" w:rsidRDefault="00DA4137"
        w:rsidP="00D52B98">
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00DA4137">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t xml:space="preserve">${toPrice(totals.total_debt_difference)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="849" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="4D25C363" w14:textId="77777777" w:rsidR="00DA4137" w:rsidRPr="00DA4137" w:rsidRDefault="00DA4137"
        w:rsidP="00D52B98">
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00DA4137">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t xml:space="preserve">${toPrice(totals.interest)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="994" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="3CC59424" w14:textId="77777777" w:rsidR="00DA4137" w:rsidRPr="00DA4137" w:rsidRDefault="00DA4137"
        w:rsidP="00D52B98">
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00DA4137">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t xml:space="preserve">${toPrice(totals.total_debt)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>  
  `
}

function makeIAETotalsTable(tax) {

   
  const heading= makeIAETotalsHeading()
  const detailLines= sort(tax.details, 'year')
                     .map((detail) => makeIAETotalsRow(detail))
                     .join('\n') 

  const totals= {
    checked_debt_total: 0.0,
    declared_debt_total: 0.0,
    total_debt_difference: 0.0,
    interest: 0.0,
    total_debt: 0.0
  }
  tax.details.map(
    (det) => {
      totals.checked_debt_total= totals.checked_debt_total + det.checked_debt_total
      totals.declared_debt_total= totals.declared_debt_total + det.declared_debt_total
      totals.total_debt_difference= totals.total_debt_difference + det.total_debt_difference
      totals.interest= totals.interest + det.interest.interest
      totals.total_debt= totals.total_debt + det.total_debt
    }
  )
                    
  const totalRow= makeIAETotalsTotalRow(totals)

  return `
  ${heading}

  <w:tbl>
    <w:tblPr>
      <w:tblW w:w="3594" w:type="pct" />
      <w:jc w:val="center" />
      <w:tblBorders>
      <w:top w:val="single" w:sz="4" w:space="0" w:color="auto" />
      <w:left w:val="single" w:sz="4" w:space="0" w:color="auto" />
      <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
      <w:right w:val="single" w:sz="4" w:space="0" w:color="auto" />
      <w:insideH w:val="single" w:sz="4" w:space="0" w:color="auto" />
      <w:insideV w:val="single" w:sz="4" w:space="0" w:color="auto" />
    </w:tblBorders>       
      <w:tblCellMar>
        <w:left w:w="70" w:type="dxa" />
        <w:right w:w="70" w:type="dxa" />
      </w:tblCellMar>
      <w:tblLook w:val="04A0" w:firstRow="1" w:lastRow="0" w:firstColumn="1" w:lastColumn="0" w:noHBand="0"
        w:noVBand="1" />
    </w:tblPr>
    <w:tblGrid>
      <w:gridCol w:w="702" />
      <w:gridCol w:w="1128" />
      <w:gridCol w:w="986" />
      <w:gridCol w:w="1035" />
      <w:gridCol w:w="1035" />
      <w:gridCol w:w="1212" />
    </w:tblGrid>
    <w:tr w:rsidR="00DA4137" w:rsidRPr="00DA4137" w14:paraId="4B9CFD27" w14:textId="77777777" w:rsidTr="00DA4137">
      <w:trPr>
        <w:trHeight w:val="389" />
        <w:jc w:val="center" />
      </w:trPr>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="575" w:type="pct" />
          <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
          <w:vAlign w:val="bottom" />
          <w:hideMark />
        </w:tcPr>
        <w:p w14:paraId="2BB353E8" w14:textId="77777777" w:rsidR="00DA4137" w:rsidRPr="00DA4137" w:rsidRDefault="00DA4137"
          w:rsidP="00D52B98">
          <w:pPr>
            <w:jc w:val="center" />
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
              <w:b />
              <w:bCs />
              <w:color w:val="003366" />
              <w:sz w:val="16" />
              <w:szCs w:val="16" />
              <w:lang w:eastAsia="es-ES" />
            </w:rPr>
          </w:pPr>
          <w:r w:rsidRPr="00DA4137">
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
              <w:b />
              <w:bCs />
              <w:color w:val="003366" />
              <w:sz w:val="16" />
              <w:szCs w:val="16" />
              <w:lang w:eastAsia="es-ES" />
            </w:rPr>
            <w:t>Años</w:t>
          </w:r>
        </w:p>
      </w:tc>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="924" w:type="pct" />
          <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
          <w:vAlign w:val="bottom" />
          <w:hideMark />
        </w:tcPr>
        <w:p w14:paraId="19A6C81F" w14:textId="77777777" w:rsidR="00DA4137" w:rsidRPr="00DA4137" w:rsidRDefault="00DA4137"
          w:rsidP="00D52B98">
          <w:pPr>
            <w:jc w:val="center" />
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
              <w:b />
              <w:bCs />
              <w:color w:val="003366" />
              <w:sz w:val="16" />
              <w:szCs w:val="16" />
              <w:lang w:eastAsia="es-ES" />
            </w:rPr>
          </w:pPr>
          <w:r w:rsidRPr="00DA4137">
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
              <w:b />
              <w:bCs />
              <w:color w:val="003366" />
              <w:sz w:val="16" />
              <w:szCs w:val="16" />
              <w:lang w:eastAsia="es-ES" />
            </w:rPr>
            <w:t>Deuda Comprobada</w:t>
          </w:r>
        </w:p>
      </w:tc>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="808" w:type="pct" />
          <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
          <w:vAlign w:val="bottom" />
          <w:hideMark />
        </w:tcPr>
        <w:p w14:paraId="2E5802C3" w14:textId="77777777" w:rsidR="00DA4137" w:rsidRPr="00DA4137" w:rsidRDefault="00DA4137"
          w:rsidP="00D52B98">
          <w:pPr>
            <w:jc w:val="center" />
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
              <w:b />
              <w:bCs />
              <w:color w:val="003366" />
              <w:sz w:val="16" />
              <w:szCs w:val="16" />
              <w:lang w:eastAsia="es-ES" />
            </w:rPr>
          </w:pPr>
          <w:r w:rsidRPr="00DA4137">
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
              <w:b />
              <w:bCs />
              <w:color w:val="003366" />
              <w:sz w:val="16" />
              <w:szCs w:val="16" />
              <w:lang w:eastAsia="es-ES" />
            </w:rPr>
            <w:t>Deuda Declarada</w:t>
          </w:r>
        </w:p>
      </w:tc>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="849" w:type="pct" />
          <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
          <w:vAlign w:val="bottom" />
          <w:hideMark />
        </w:tcPr>
        <w:p w14:paraId="016EC0D8" w14:textId="77777777" w:rsidR="00DA4137" w:rsidRPr="00DA4137" w:rsidRDefault="00DA4137"
          w:rsidP="00D52B98">
          <w:pPr>
            <w:jc w:val="center" />
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
              <w:b />
              <w:bCs />
              <w:color w:val="003366" />
              <w:sz w:val="16" />
              <w:szCs w:val="16" />
              <w:lang w:eastAsia="es-ES" />
            </w:rPr>
          </w:pPr>
          <w:r w:rsidRPr="00DA4137">
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
              <w:b />
              <w:bCs />
              <w:color w:val="003366" />
              <w:sz w:val="16" />
              <w:szCs w:val="16" />
              <w:lang w:eastAsia="es-ES" />
            </w:rPr>
            <w:t>Deuda resultante</w:t>
          </w:r>
        </w:p>
      </w:tc>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="849" w:type="pct" />
          <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
          <w:vAlign w:val="bottom" />
          <w:hideMark />
        </w:tcPr>
        <w:p w14:paraId="10E8D552" w14:textId="77777777" w:rsidR="00DA4137" w:rsidRPr="00DA4137" w:rsidRDefault="00DA4137"
          w:rsidP="00D52B98">
          <w:pPr>
            <w:jc w:val="center" />
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
              <w:b />
              <w:bCs />
              <w:color w:val="003366" />
              <w:sz w:val="16" />
              <w:szCs w:val="16" />
              <w:lang w:eastAsia="es-ES" />
            </w:rPr>
          </w:pPr>
          <w:r w:rsidRPr="00DA4137">
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
              <w:b />
              <w:bCs />
              <w:color w:val="003366" />
              <w:sz w:val="16" />
              <w:szCs w:val="16" />
              <w:lang w:eastAsia="es-ES" />
            </w:rPr>
            <w:t>Interés</w:t>
          </w:r>
        </w:p>
      </w:tc>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="994" w:type="pct" />
          <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
          <w:vAlign w:val="bottom" />
          <w:hideMark />
        </w:tcPr>
        <w:p w14:paraId="4F4C5CC4" w14:textId="77777777" w:rsidR="00DA4137" w:rsidRPr="00DA4137" w:rsidRDefault="00DA4137"
          w:rsidP="00D52B98">
          <w:pPr>
            <w:jc w:val="center" />
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
              <w:b />
              <w:bCs />
              <w:color w:val="003366" />
              <w:sz w:val="16" />
              <w:szCs w:val="16" />
              <w:lang w:eastAsia="es-ES" />
            </w:rPr>
          </w:pPr>
          <w:r w:rsidRPr="00DA4137">
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
              <w:b />
              <w:bCs />
              <w:color w:val="003366" />
              <w:sz w:val="16" />
              <w:szCs w:val="16" />
              <w:lang w:eastAsia="es-ES" />
            </w:rPr>
            <w:t>Deuda a pagar</w:t>
          </w:r>
        </w:p>
      </w:tc>
    </w:tr>
    
  
    ${detailLines}
    ${totalRow}
  
  
  </w:tbl>
  `
}

export {makeIAETotalsTable}