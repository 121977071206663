import React, {useState} from 'react'
import {TimelineEvent, TimelineEventInfo, TimelineEventRight} from 'cli/ui/components/timeline'
import ProcessDocuAdd from '../../../docu/ProcessDocuAdd'
import {AddDoc, Provide, ProvideOut, NotProvide} from './parts/actions'
import DocuList from './parts/DocuList'

const EventProvide = ({subStep, onProcessChange, onDocuDownload, onDocuSave, onDocuOpen, onDocuRemove, canEdit}) => {
  const [addingDoc, setAddingDoc]= useState(false)
  

  const handleDocuSave = async (docu) => {
    docu.diligence= subStep.diligence_number
    await onDocuSave(docu)
    setAddingDoc(false)
  }

  const handleOnSatisfied = async (satisfied) => {
    const changes= {[subStep.field_name]: satisfied}
    onProcessChange(changes)
  }


  return (
      <TimelineEvent key      ={`process_inspect_dlg_provide_${subStep.diligence_number}`}
                     legend   = {{icon: "file-zip", color: subStep.satisfied===0 
                                                           ? 'red' 
                                                           : subStep.satisfied===2
                                                             ? 'orange'
                                                             : 'green'}}
                      date     = {undefined }
                      mini     = {false}
                      isLast   = {subStep.done===false}
                      >

        <TimelineEventInfo>
          {addingDoc
            ? <ProcessDocuAdd onCancel  = {() => setAddingDoc(false)}
                                onSave    = {handleDocuSave}
                                onDownload= {onDocuDownload}/>
            : <>
              <div>
                  <i style={{color: "gray"}}>
                    {subStep.satisfied==undefined
                      ? 'A la espera de documentación'
                      : subStep.satisfied===1
                        ? 'Aporta documentación'
                        : subStep.satisfied===2
                          ? 'Aporta documentación (fuera de plazo)'
                          : 'NO Aporta documentación'
                    }
                  </i>
              </div>
              <div>
                {subStep.docus.length>0
                ? <DocuList docus={subStep.docus}
                            onDocuOpen={onDocuOpen}
                            onDocuRemove={onDocuRemove}
                            canEdit={canEdit}/>
                : null
                }
              </div>
            </>
          }
        </TimelineEventInfo>
        <TimelineEventRight>
          {canEdit
           ?  <>
                <AddDoc     onClick  = {() => setAddingDoc(true)}/>

                <Provide    onClick  = {() => handleOnSatisfied(1)}
                            disabled = {subStep.satisfied===1}/>
                <ProvideOut onClick  = {() => handleOnSatisfied(2)}
                            disabled = {subStep.satisfied===2}/>
                <NotProvide onClick  = {() => handleOnSatisfied(0)}
                            disabled = {subStep.satisfied===0}/>
              </>
            : null}

        </TimelineEventRight>


      </TimelineEvent>
  )


}

//ProcessDocuAdd = ({onCancel, onSave, onDownload}) 

export default EventProvide