import React from 'react'
import {intre_pretty_long} from 'intre'

import {
  Card,
  CardHeader,
  CardBody,
  CardActions
} from 'cli/ui/components/cards'
import { ActionClose } from 'cli/ui/components/buttons/actions'


const UserEmailsPreview =  ({ currentEmail, onClose }) => {
  return (
    <Card>
      <CardHeader>
        {"De " + currentEmail.mfrom_name +
          " <" + currentEmail.mfrom_email + ">, el " +
          intre_pretty_long(currentEmail.mdate)}
        <CardActions>
         <ActionClose onClick={() => onClose()} />
        </CardActions>
      </CardHeader>
      <CardBody>
        <div style={{ overflow: "auto", maxWidth: "100%" }}>
          <h2>{currentEmail.msubject}</h2>
          <div dangerouslySetInnerHTML={{ __html: currentEmail.mbody }} />
        </div>
      </CardBody>
    </Card>  
  )
}

export default UserEmailsPreview