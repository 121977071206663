const PERMISSION_GROUPS= {

  'council': 'Ayuntamientos',
  'user_email': 'Email del usuario',
  'setts': 'Configuración',
  'tax_payer': 'Contribuyentes',
  'process': 'Expedientes',
  'docs': 'Documentos',
  'stats': 'Indicadores',
  'calendar': 'Calendario',
  'income': 'Recaudación'


}

export {PERMISSION_GROUPS}