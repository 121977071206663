import React from 'react'
import {TimelineEvent, TimelineEventInfo, TimelineEventRight} from 'cli/ui/components/timeline'
import {WithAppeal, WithoutAppeal} from './parts/actions'


const EventAppeal = ({subStep, onProcessChange, canEdit}) => {
  
  const handleOnAppealed = (v) => {
    onProcessChange({[subStep.field_name]: v})
  }


  return (
      <TimelineEvent  key      ={`process_inspect_appeal`}
                      legend   = {{icon: "agree", 
                                  color: subStep.appeal_status=='pending'
                                         ? 'gray'
                                         : subStep.appealed
                                           ? 'red' 
                                           : 'lightgreen'
                                 }}
                      date     = {undefined /*processDoc.file.notification_date*/}
                      isLast   = {subStep.done===false}
                      >

        <TimelineEventInfo>
          <i style={{color: "gray"}}>
            {subStep.appeal_status=='pending'
             ? "Pendiente de posible Recurso reposición"
             : subStep.appealed==true
               ? "Con recurso reposición"
               : "Sin recurso reposición"
            }
         </i>
        </TimelineEventInfo>
        <TimelineEventRight>
          {canEdit
           ? <>
              <WithAppeal disabled={subStep.appealed==true}
                            onClick={() => handleOnAppealed(true)}/>
              <WithoutAppeal disabled={subStep.appealed==false}
                            onClick={() => handleOnAppealed(false)}/>
            </>
           : null}
        </TimelineEventRight>
      </TimelineEvent>
  )


}

export default EventAppeal