import React from 'react'
import ActionButton from './ActionButton'

const ActionCheck = ({ onClick, enabled, label, checked, color }) =>
  <ActionButton color  = {color ? color : checked ? 'blue' : 'gray'}
                label={label}
                icon   = {checked ? 'checkmark' : 'checkmark'}
                onClick= {() => { onClick(!checked) }}
                enabled= {enabled != undefined ? enabled : true}>
  </ActionButton>
export default ActionCheck
