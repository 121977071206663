import React, {useState, useEffect} from 'react'
import {intre_pretty_short} from 'intre'
import {TimelineEvent, TimelineEventInfo, TimelineEventRight} from 'cli/ui/components/timeline'
import EventAppointmentForm from './EventAppointmentForm'
import {ChangeAppointment, Back} from './parts/actions'

const EventAppointment = ({subStep, onProcessChange, canEdit}) => {
  const [showEdit, setShowEdit]= useState(subStep.appointment_status=='pending')

  useEffect(() => {
    setShowEdit(subStep.appointment_status=='pending')
  }, [subStep.appointment_status])


  const handleOnAppointment = (appDate, appTime) => {
    let changes= {}
    if (appDate!=subStep.appointment_date) {
      changes= {appointment_date: appDate}
    } else {
      if (subStep.needs_appointment_time && subStep.appointment_time!=appTime) {
        changes.tax_data= {appointment_time: appTime}
      }
    }

    if (Object.keys(changes).length>0) {
      onProcessChange(changes)
    }

    setShowEdit(false)
  }

  return (
    <TimelineEvent  key      ={`process_inspect_appointment`}
                    legend   = {{icon: "pen", 
                                color: subStep.done
                                      ? 'green'
                                      : 'gray'}}
                    date     = {subStep.appointment_date}
                    isLast   = {subStep.done===false}
                    >

      <TimelineEventInfo>
        {subStep.done
          ? <i style={{color: "gray"}}>{`Fecha de firma: ${intre_pretty_short(subStep.appointment_date)} ${subStep.needs_appointment_time ? subStep.appointment_time : ''}`}</i>
          : <i style={{color: "gray"}}>Pendiente de fijar fecha de firma</i>
        }
      </TimelineEventInfo>
      <TimelineEventRight>
        {canEdit
         ? 
            (subStep.done===false || showEdit)
            ? <>
                <EventAppointmentForm subStep       = {subStep}
                                      onAppointment = {(appDate, appTime) => handleOnAppointment(appDate, appTime)}/>
                {showEdit && subStep.done
                  ? <Back onClick={() => setShowEdit(false)}/>
                  : null
                }
              </>
            : <ChangeAppointment onClick={() => setShowEdit(true)}/>
         : null
         }
      </TimelineEventRight>
    </TimelineEvent>
  )
}

export default EventAppointment