import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionGo, ActionBack } from 'cli/ui/components/buttons/actions'
import {FForm} from 'formiga-reactstrap'
import TaxSelect from './TaxSelect'

const CouncilDetailsAddOrdinance = ({councilId, handleBack}) => {
  const navigate= useNavigate()
  const [taxCode, setTaxCode]= useState('i5')

  const handleAdd = () => {
    const url= taxCode=='lu'
     ? `/councils/${councilId}/${taxCode}/new/det`
     : `/councils/${councilId}/${taxCode}/new`
    navigate(url)
  }

  return (
    <>
    <style>{`
      .mb-3 {
        margin-bottom: 0 !important;
      }
    `}</style>
    <FForm inline      
           renderButtons= {() => 
            <>
              <ActionGo   onClick={() => handleAdd()}
                          label="Crear Ordenanza"/>
              {handleBack!=undefined
               ? 
                <ActionBack onClick={() => handleBack()}
                            label=""/>
               : null}
            </>            
           }>
     <>
       <TaxSelect taxCode={taxCode}
                  onChange={(v) => setTaxCode(v)}/>
     </>
    </FForm>
    </>
  )
}


export default CouncilDetailsAddOrdinance
