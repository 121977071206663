import React from 'react'
import { Link } from 'react-router-dom';

import Icon from 'cli/ui/components/icon'
import { Card, CardText, CardBody, CardTitle, Alert } from 'reactstrap';

class MenuCard extends React.Component {


  render() {
    return (
      <Link className="afi_menu_card"
        to={this.props.link}>
        <Card>
          <Alert /*color={this.props.color ? this.props.color : 'secondary'}*/
                 style={{backgroundColor: this.props.color || 'white'}}>
            <Icon icon={this.props.icon ? this.props.icon : 'question'} />
          </Alert>
          <CardBody>
            {this.props.title &&
              <CardTitle>
                {this.props.title}
              </CardTitle>
            }

            <CardText>
              {this.props.children}
            </CardText>

            {this.props.link_text &&
              <div>
                {this.props.link_text}
              </div>
            }

          </CardBody>
        </Card>
      </Link>
    )
  }
}


export default MenuCard


