import React, {useState, useEffect /*, useCallback*/} from 'react'
import {objFilter} from 'farrapa/objects'
import { PTYPE_NAMES } from 'bl/ns/process/process'
import {LU} from 'bl/ns/taxes'
import { LoadingBig } from 'cli/ui/components/loading'
import {FForm, FInputText, FInputSelect, FInputSelectSearch, FInputInt} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'

import {intre_noon, intre_get_year} from 'intre'
const year= intre_get_year(intre_noon())

// const  getAddingProcess = (taxCode, editionId) => {
//   
// 
//   return {
//     edition_id       : editionId,
//     status           : 1,
//     taxpayer_id      : undefined,
//     taxpayer_name    : '',
//     taxpayer_sector_id: undefined,
//     taxpayer_activity: '',
//     name             : '',
//     process_type     : 1,
//     year_from        : year-5,
//     year_to          : year-1,
//     tax_code         : taxCode
//   }
// }


const EditionDetailsProcessForm = ({fetcher, edition, onSave}) => {
  const [taxpayerList, setTaxpayerList]= useState({})
  const [unavailableTaxPayers, setUnavailableTaxPayers]= useState([])
  const [unavailableNames, setUnavailableNames]= useState([])
  const [loaded, setLoaded]= useState(false)

  const [status          , setStatus]= useState(1)
  const [taxpayerId      , setTaxpayerId]= useState(undefined)
  const [taxpayerName    , setTaxpayerName]= useState('')
  const [taxpayerSectorId, setTaxpayerSectorId]= useState(undefined)
  const [taxpayerActivity, setActivity]= useState('')
  const [name            , setName]= useState('')
  const [processType     , setProcessType]= useState(1)
  const [yearFrom        , setYearFrom]= useState(year-5)
  const [yearTo          , setYearTo]= useState(year-1)

  
  useEffect(() => {
    const fetchData = async () => {
      //setTaxpayerList(await fetcher.key_list('/api/tax_payer'))     

      const params= {
        tax_code: edition.tax_code, 
        council_id: edition.council_id, 
        ordinance_id: edition.ordinance_id, 
        edition_id: edition.id,
        repeat_tax_payer: true
      }

      const res= await fetcher.get('/api/tax_payer/taxpayers_for_edition', params)
      const tps = {}
      res.data.map(tp => {
        tps[tp.id] = tp.name
      })
      setTaxpayerList(tps)

    }
    fetchData()

    const nUnavailableNames= edition.processes.map((p) => p.name)
    setUnavailableNames(nUnavailableNames)

    //const nUnavailableTaxPayers= edition.processes
    //      .map((p) => p.taxpayer_id)
    //      .filter((tpid) => tpid!=undefined)
    //setUnavailableTaxPayers(nUnavailableTaxPayers)
    
    setLoaded(true)
    
  }, [fetcher, edition.tax_code, edition.processes, edition.council_id, edition.ordinance_id, edition.id])



  const handleSave = /*useCallback(*/async () => {
    

    const process= {
      edition_id        : edition.id,
      status            : status          ,
      taxpayer_id       : taxpayerId      ,
      taxpayer_name     : taxpayerName    ,
      taxpayer_sector_id: taxpayerSectorId,
      taxpayer_activity : taxpayerActivity,
      name              : name            ,
      process_type      : processType     ,
      year_from         : yearFrom        ,
      year_to           : yearTo          ,
      tax_code          : edition.tax_code
    }
    await onSave(process)


    setStatus(1)
    setTaxpayerId(undefined)
    setTaxpayerName('')
    setTaxpayerSectorId(undefined)
    setActivity('')
    setName(undefined)
    setProcessType(1)
    setYearFrom(year-5)
    setYearTo(year-1)

  } //, [status, taxpayerId, taxpayerName, taxpayerSectorId, taxpayerActivity, name, processType, yearFrom, yearTo, edition.tax_code, edition.id, onSave])

  if (! loaded /*|| process==undefined*/) {
   return (
     <LoadingBig/>
   )
  }

  return (
  <FForm  onCancel     = {undefined}
          onSave       = {() => handleSave()}>
      <>
          <FInputSelectSearch  
                      options     = {objFilter(taxpayerList, (k,_v) => unavailableTaxPayers.indexOf(k)==-1)}
                      name        = 'taxpayer_id'
                      icon        = {<Icon icon={"taxpayer"}/>}
                      value       = {taxpayerId}
                      onChange    = {(v) => {setTaxpayerId(v); setTaxpayerName(taxpayerList[v])}}
                      feedback    = {"Seleciona un Contribuyente"}
                      clearable   = {true}
                      searchable  = {true}
                      required    = {true}
                      //disallowedValues={unavailableTaxPayers}
                      autocomplete= {"off"}
          />
          <FInputSelect 
                      options     = {PTYPE_NAMES[edition.tax_code]}
                      name        = 'process_type'
                      value       = {processType}
                      onChange    = {(v) => setProcessType(v)}
                      feedback    = {"Selecciona tipo de expediente"}
                      clearable   = {true}
                      required    = {true}
                      checkValue  = {(v) => (v!=undefined) && (v!='') && (v.length>0)}
                      autocomplete= {"off"}
          />
          <FInputText  
                      name        = 'name'
                      feedback    = 'Nº Expediente'
                      value       = {name}
                      onChange    = {(v) => setName(v)}
                      required    = {true}
                      autocomplete= {"off"}
                      disallowedValues={unavailableNames}
          />
          {edition.tax_code==LU
            ? null
            : <>          
                <FInputInt  
                            name        = 'year_from'
                            icon        = {<Icon icon={"calendar"}/>}
                            feedback    = 'Ejercicio desde'
                            value       = {yearFrom}
                            onChange    = {(v) => setYearFrom(v)}
                            required    = {true}
                            gt          = {1990}
                />
                <FInputInt  
                            name        = 'year_to'
                            icon        = {<Icon icon={"calendar"}/>}
                            feedback    = 'Ejercicio hasta'
                            value       = {yearTo}
                            onChange    = {(v) => setYearTo(v)}
                            required    = {true}
                            gt          = {1990}
                />
              </>
          }
      </>
  </FForm>
  )
}


export default EditionDetailsProcessForm
