import React, {useState, useEffect, useCallback} from 'react'
import {useParams, useNavigate} from 'react-router-dom'


// components
import Page from 'cli/ui/pages/layout/Page'
import {Row, Col} from 'reactstrap'
import { ActionBack } from 'cli/ui/components/buttons/actions'
import { LoadingBig } from 'cli/ui/components/loading'
import SectorDetailsForm from './SectorDetailsForm'
import withContext from 'cli/context/withContext'

let _BREADS= [['/', 'Inicio'], ['/settings', 'Configuración'], ['/settings/sectors', 'Sectores']]

const SectorDetails = ({fetcher}) => {

  const params= useParams()
  const navigate= useNavigate()
  
  const [sector, setSector]= useState({})
  const [status, setStatus]= useState(0)

  const [nameList, setNameList]= useState([])
  const [nameOrig, setNameOrig]= useState('')
  
  const paramId = params.idSector
  const isNew = paramId == 'new'
  const title = isNew ? 'Creando nuevo' : sector.name||'...'

  const fetchSector = useCallback((sectorId) => {
    const _fetchSector = async() => {
      setStatus(0)

      const nNameList = await fetcher.name_list('/api/sector')
      setNameList(nNameList)
      if (sectorId!=undefined) {
        const nSector = await fetcher.find('/api/sector', sectorId)
        setSector(nSector)
        setNameOrig(nSector.name)      
      }  else {
        setSector({
          color: '#000000'
        })
        setNameOrig('')      
      }
      setStatus(1)
    }
    _fetchSector()
  }, [fetcher])

  useEffect(() => {
    async function fetchData() {
      await fetchSector(isNew ? undefined : paramId)
    }
    fetchData()
  }, [fetchSector, isNew, paramId])

  const existingNames = () => {
    return nameList.filter((n) => n!=nameOrig)
  }

  const handleChange = (name, value) => {
    const nSector= {...sector}
    nSector[name]= value
    setSector(nSector)
  }

  const handleSave = async () => {
    const res= await fetcher.upsave('/api/sector', sector)
    if (isNew && res>=1) {
      navigate(`/settings/sectors/${res}`)
      await fetchSector(res)
    }    
  }

  const handleCancel = () => {
    navigate('/settings/sectors')
  }

  return (
    <Page breads={[..._BREADS, title]}
          title={`Sector: ${title}`}
          actions={<ActionBack to={"/settings/sectors"} label="A Sectores"/>}>
      <Row>
        <Col xs="12">
            {status < 1 
             ? <LoadingBig/>
             : <SectorDetailsForm
                  sector           = {sector}
                  existingNames    = {existingNames()}
                  onChange         = {(v,n) => handleChange(v,n)}
                  onCancel         = {() => handleCancel()}
                  onSave           = {() => handleSave()}
                />
            }
        </Col>
      </Row>
    </Page>
  )
}


export default withContext(SectorDetails)

