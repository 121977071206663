import getStatusName from './getStatusName'
import getSerialText from './getSerialText'
import getProgress from './getProgress'
import getColor from './getColor'

const getProcessInfo = (process, what) => {
  
  const status= getStatusName(process, what)
  const serial= getSerialText(process, what)
  const amount= process.amounts_real[what]
  const amountColor= process.amount_colors[what]
  const progress= getProgress(process, what)
  const color= getColor(process, what, progress)

  return {
    status, 
    serial, 
    amount, 
    amountColor, 
    progress, 
    color
  }

}



export default getProcessInfo