const getProcessListCount = (processes, what) => {
  if (what=='penalty_20') {
    return processes.filter((p) => p.penalty_20_on==true).length
  }
  if (what=='penalty_19') {
    return processes.filter((p) => p.penalty_19_on==true).length
  }
  return processes.length  
}

export default getProcessListCount