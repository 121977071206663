import React from 'react'
import {Row, Col} from 'reactstrap'
import Page from 'cli/ui/pages/layout/Page'
import {MenuCard} from 'cli/ui/components/cards'
import {TAX_NAMES, TAX_BACK_COLORS} from 'bl/ns/taxes'
const _BREADS = [['/', 'Inicio'], ['Tributos']]

const Taxes = () => {
  return (
      <Page breads={_BREADS}
            title="Tributos">
        <Row>  
          {Object.keys(TAX_NAMES).map((tax) => 
            <Col key = {`taxes_card_${tax}`}
                 xs="12" sm="6" md="4"> 
              <MenuCard color    = {TAX_BACK_COLORS[tax]}
                        icon     = {tax}
                        link     = {`/taxes/${tax}`}
                        title    = {TAX_NAMES[tax]}>
              </MenuCard>   
            </Col>          
           )}
        </Row>

      </Page>
  )
}


export default Taxes



