import {uvl} from 'farrapa/commons'
import {memoize} from 'farrapa/memoize'
import {makeSubStepDoc, makeSubStepAgreePenalty,
        makeSubStepArguments, makeSubStepArgumentsDate, makeSubStepAgreeDate,
        makeStep, makeColor} from './util'
// import {CLOSING_REASONS} from 'bl/ns/process/process'
import {PENALTY_19_STEPS, DOC, SUB_STEP_DOC} from 'bl/ns/process/steps'
import {PENALTY_INFRACTION_LEVEL, PENALTY_INFRACTION_LEVEL_NAMES } from 'bl/ns/process/penalty'

class Inspect {
  constructor(process) {
    this.p= process
  }

  @memoize
  get open () {
    const subDoc= makeSubStepDoc(this.p.files, PENALTY_19_STEPS.OPEN, DOC.PENALTY_OPEN, false, undefined, {
      penalty_type: this.p.penalty_19_type
    })

    return makeStep( PENALTY_19_STEPS.OPEN, [
      subDoc
    ])
  }

  @memoize
  get start () {
    const subDoc= makeSubStepDoc(this.p.files, PENALTY_19_STEPS.START, DOC.PENALTY_START, true, undefined, {
      penalty_type: this.p.penalty_19_type
    })
    return makeStep(PENALTY_19_STEPS.START, [
      subDoc
    ])
  }

  @memoize
  get report () {

    const resolDoc = () => {                  
      const subDoc= makeSubStepDoc(this.p.files, PENALTY_19_STEPS.REPORT, DOC.PENALTY_REPORT_RESOL, false, 
        [DOC.PENALTY_DOC_RESOL, DOC.PENALTY_NOTIF_RESOL], {
        penalty_type: this.p.penalty_19_type
      })     
      return subDoc 
    }
    const legalDoc = () => {
      const subDoc= makeSubStepDoc(this.p.files, PENALTY_19_STEPS.REPORT, DOC.PENALTY_REPORT_LEGAL, false, undefined, {
        penalty_type: this.p.penalty_19_type
      })     
      return subDoc 
    }


    let subSteps= []
    //Arguments substep
    const subArg= makeSubStepArguments(this.p.penalty_19_arguments, 'penalty_19_arguments')
    subSteps.push(subArg)
    if (subArg.done) {
      // If Arguments YES
      if (this.p.penalty_19_arguments==true) {
        // Arguments date
        const subArgD= makeSubStepArgumentsDate(this.p.penalty_19_arguments_date, 'penalty_19_arguments_date')
        subSteps.push(subArgD)
        if (subArgD.done) {
          // Resol Doc
          subSteps.push(resolDoc())
        }

        
      } else {
        // If Arguments NO
        // Agree substep
        const subAgr= makeSubStepAgreePenalty(this.p.penalty_19_agreed, 'penalty_19_agreed', false)
        subSteps.push(subAgr)
        if (subAgr.done) {
          // If Agree NO
          if (this.p.penalty_19_agreed===false) {
            // Resol Doc
            subSteps.push(resolDoc())
          } else {
            // If Agree YES
            // Agree date
            const subAgrD= makeSubStepAgreeDate(this.p.penalty_19_agreed_date, 'penalty_19_agreed_date')
            subSteps.push(subAgrD)
            if (subAgrD.done) {
              // Legal Doc
              subSteps.push(legalDoc())        
            }
          }
        }
      }
    }


    return makeStep(PENALTY_19_STEPS.REPORT, subSteps)
  }

  @memoize
  get payment () {
    const subDoc= makeSubStepDoc(this.p.files, PENALTY_19_STEPS.PAYMENT, DOC.PENALTY_PAYMENT, false, undefined, {
      penalty_type: this.p.penalty_19_type
    }) 
    return makeStep(PENALTY_19_STEPS.PAYMENT, [
      subDoc
    ])
  }  

  @memoize
  get start_notif_date() {
    try {
      return this.start.sub[0].doc.file.notification_date
    } catch(e) {}
    return undefined    
  }

  @memoize
  get start_notif_accepted() {
    try {
      return this.start.sub[0].doc.file.notification_accepted
    } catch(e) {}
    return undefined    
  }
 

  getInfractionLevel(year) {
    let level= PENALTY_INFRACTION_LEVEL.SOFT
    if (this.p.tax_code=='i5') {
      if (this.p.tax!=undefined) {
        this.p.tax.details.map((det) => {
          if (year===undefined || year===det.year) {
            if (!isNaN(det.fee)) {
              if (det.fee>=3000) {
                level= PENALTY_INFRACTION_LEVEL.BAD
              }
            }
          }
        })
      }
    }
    return level
  }

  @memoize
  get infraction_level() {
    return this.getInfractionLevel(undefined)
  }

  mainDiscount(taxDetail) {
    const yearInfractionLevel= this.getInfractionLevel(taxDetail?.year)
    if (yearInfractionLevel== PENALTY_INFRACTION_LEVEL.SOFT) {
      return [0.50, '50%']
    }
    //const calc1= (taxDetail.checked_taxbase * taxDetail.tax.tax_rate) / taxDetail.fee
    const calc= (taxDetail.difference / taxDetail.checked_taxbase) * 100

    if (isNaN(calc) || calc < 10) {
      return [0.50, '50%']
    }
    if (calc >= 10 && calc < 25) {
      return [0.60, '50%+10%']
    }
    if (calc >= 25 && calc < 50) {
      return [0.65, '50%+15%']
    }
    if (calc >= 50 && calc < 75) {
      return [0.70, '50%+20%']
    }
    if (calc >= 75) {
      return [0.75, '50%+25%']
    }    
    return [0.50, '50%']
  }  

  @memoize
  get applies_agree_discount() {
    return uvl(this.p.penalty_19_reduct_agreed, uvl(this.p.minutes_agreed, true))===true
  }

  get agree_discount() {
    return 0.70
  }

  get payment_discount() {
    const red= this.p.penalty_19_reduct_payment_value>0 ?
               this.p.penalty_19_reduct_payment_value/100 : 0.40    
    return 1-red
  }

  get tax_years() {
    if (this.p.penalty_19_years!=undefined) {
      return this.p.penalty_19_years
    }
    let years= []
    for (let i= this.p.year_from; i<= this.p.year_to; i++) {
      years.push(i)
    }
    return years
  }

  @memoize
  get amounts() {
    if (this.p.tax_code!='i5' || this.p.tax==undefined ) {
      return []
    }

    let amounts= []

    this.p.tax.details.map((det, _didx) => {
      const [discount, discount_description]= this.mainDiscount(det)
      const tyears= this.tax_years
      


      if (tyears.indexOf(det.year)<0) {
        amounts.push({
          year      : det.year,
          difference: det.difference,
          base      : det.fee,
          discount  : 0.0,
          discount_description: '',
          infraction_level: undefined,
          infraction_description: '',
          after_discount: 0.0,
          after_agreed: 0.0,
          total: 0.0,
          red_by_discount: 0.0,
          red_by_agreed: 0.0,
          red_by_payment: 0.0
        })
      } else {
        const infr_level= this.getInfractionLevel(det.year)
        const infr_desc= PENALTY_INFRACTION_LEVEL_NAMES[infr_level] || '-'

        const after_discount= det.fee * discount
        const red_by_discount= det.fee - after_discount
        
        const after_agreed= this.applies_agree_discount
                            ? after_discount * this.agree_discount
                            : after_discount
        const red_by_agreed= after_discount - after_agreed
        
        const total= this.p.penalty_19_reduct_payment===false
                            ? after_agreed
                            : after_agreed * this.payment_discount
        const red_by_payment= after_agreed - total


        amounts.push({
          year      : det.year,
          difference: det.difference,
          base      : det.fee,
          discount  : discount,
          discount_description: discount_description,
          infraction_level: infr_level,
          infraction_description: infr_desc,
          after_discount,
          after_agreed,
          total,
          red_by_discount,
          red_by_agreed,
          red_by_payment
        })
      }
    })


    return amounts
  }

  @memoize
  get amounts_sum() {
    let difference= 0.0, base= 0.0, raw= 0.0, total= 0.0, 
        after_discount = 0.0, after_agreed = 0.0, 
        red_by_discount = 0.0, red_by_agreed = 0.0, red_by_payment = 0.0
    this.amounts
    .filter((am) => am.total>0)
    .map((am) => {
      difference+= am.difference
      base+= am.base
      total+= am.total
      after_discount+= am.after_discount
      after_agreed+= am.after_agreed
      red_by_discount += am.red_by_discount
      red_by_agreed += am.red_by_agreed
      red_by_payment += am.red_by_payment
    })
    const the_amounts_sum= {difference, base, raw, after_discount, after_agreed, red_by_discount, red_by_agreed, red_by_payment, total}
    return the_amounts_sum
  }

  @memoize
  get steps() {
    /*
    if (this.p.is_closed)
      return []
    */

    let steps= []

    if (this.p.penalty_19_on===true){
      steps.push(this.open)
      if (this.open.done) {
        steps.push(this.start)
        if (this.start.done) {
          steps.push(this.report)
          if (this.report.done) {
            steps.push(this.payment)
          }
        }
      }        
    }

    return steps
  }

  @memoize
  get status_step() {
    // if (this.p.is_closed || !this.p.has_penalty)
    //  return undefined

    if (!this.p.has_penalty)
      return undefined      

    let st_step= undefined
    for (let i= this.steps.length-1; i>=0; i--) {
      if (st_step==undefined) {
        if (this.steps[i].completed) {
          st_step= this.steps[i]
        }
      }
    }
    if (st_step==undefined && this.steps.length>0) {
      return this.steps[0]
    }
    return st_step
  }


  @memoize
  get progress() {
    if (this.is_done) {
      return 100
    }
    if (this.steps.length==0) {
      return 0
    }

    const totSteps= 6
    
    let doneSteps= 0
    for (let i=0; i<this.steps.length;i++) {
      for (let j=0; j<this.steps[i].sub.length;j++) {
        if (this.steps[i].sub[j].done) {
          doneSteps+= 1
        }
      }
    }

    const progress = Math.round(doneSteps*100 / totSteps,0)
    return progress
  }


  @memoize
  get color() {
    let color= 'green'
    if ( this.p.penalty_19_on===true && this.report.agreed===false) {
     color= 'pink'
    }
    return makeColor(color, this.progress)
  }


  @memoize
  get is_done() {
    if (this.status_step!=undefined) {
      return this.status_step.id===PENALTY_19_STEPS.PAYMENT
    }
    return false
  }

  @memoize
  get current_step() {
    return this.steps.slice(-1)[0]
  }

  @memoize
  get current_step_doc() {
    if (this.current_step) {
      for (let i=0; i<this.current_step.sub.length; i++) {
        if (this.current_step.sub[i].type==SUB_STEP_DOC) {
          return this.current_step.sub[i]
        }
      }
    }
    return undefined
  }

  export() {
    return {
      open             : this.open,
      start            : this.start,
      report           : this.report,
      payment          : this.payment,
      infraction_level : this.infraction_level  ,
      amounts          : this.amounts,
      amounts_sum      : this.amounts_sum,
      
      start_notif_date : this.start_notif_date,
      start_notif_accepted : this.start_notif_accepted,
      status_step      : this.status_step,
      current_step     : this.current_step,
      is_done          : this.is_done
    }
  }
}



export default Inspect  