import React, {useEffect, useState} from 'react'
import {FForm, FInputText, FInputEmail, FInputPassword, /*FInputCheckbox,*/ FInputSelect} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'

const _getRoleOptions = (roleList) => {
  let roleOptions= {}
  roleList.map(r => {
    roleOptions[r.id]= r.description
  })
  return roleOptions
}

const UserDetailsForm = ({ user, existingNames, roleList, onChange, onSave, onCancel}) => {
  const [roleOptions, setRoleOptions]= useState(_getRoleOptions(roleList))
  
  useEffect(() => {
    setRoleOptions(_getRoleOptions(roleList))
  }, [roleList])


  return (
    <FForm  onCancel     = {user.id==undefined ? onCancel : undefined}
            onSave       = {onSave}>
            <>
              <FInputText name        = "name"
                          label       = "Nombre"
                          icon        = {<Icon icon={"user"}/>}
                          value       = {user.name}
                          required    = {true}
                          feedback    = {'Inserta un nombre'}
                          onChange    = {(v) => onChange('name', v)}
                          autoComplete= "off"
              />
              <FInputEmail name       = "username"
                          label       = "Usuario"
                          icon        = {<Icon icon={"user"}/>}
                          value       = {user.username}
                          required    = {true}
                          checkValue  = {(v) => existingNames.map((n) => n.toLowerCase()).indexOf(v.toLowerCase())==-1}
                          feedback    = {'Inserta un email válido y único'}
                          onChange    = {(v) => onChange('username', v)}
                          autoComplete= "off"
              />
              {user.id==undefined
              ? 
                <FInputPassword name    = "password"
                            label       = "Contraseña"
                            icon        = {<Icon icon={"password"}/>}
                            value       = {user.password}
                            required    = {true}
                            feedback    = {'Inserta una contraseña potente'}
                            onChange    = {(v) => onChange('password', v)}
                            autoComplete= "off"
                />
              : null}
              <FInputSelect name        = "role"
                          label       = "Perfil"
                          icon        = {<Icon icon={"shield"}/>}
                          value       = {user.acc_role_id || ''}
                          required    = {true}
                          feedback    = {'Escoge un perfil'}
                          options     = {roleOptions}
                          onChange    = {(v) => onChange('acc_role_id', v)}
              />                     
              {/*
              <FInputCheckbox 
                          name        = "admin"
                          label       = "Administrador"
                          icon        = {<Icon icon={"checkmark"}/>}
                          value       = {user.admin || false}
                          onChange    = {(v) => onChange('admin', v)}
              />
              */}
              </>
    </FForm>
  )
}


export default UserDetailsForm




