import React from 'react'
import { EditingIBox, IBoxRow, IBoxIconedText } from 'cli/ui/components/ibox'
import TaxPayerDetailsSectorForm from './TaxPayerDetailsSectorForm'


const TaxPayerDetailsSector = ({taxPayer, sectorOptions, onChange, onSave}) => {

  const empty= ! (taxPayer.sector_id || taxPayer.activity)
  return (
    <>
      <EditingIBox title="Sector y Actividad principales"
                   empty={empty}
                   fitHeight
                   permissionCode="tax_payer_edit"
                   resume={() => 
                      <>
                        {empty
                        ? <IBoxRow center >
                            {"Falta por rellenar Sector y actividad principales"}
                          </IBoxRow>
                        : <>
                            <IBoxRow center >
                              <IBoxIconedText icon="sector"
                                              text={sectorOptions[taxPayer.sector_id] || ''}/>
                            </IBoxRow>
                            <IBoxRow center >
                              <IBoxIconedText icon="text"
                                              text={taxPayer.activity}/>
                            </IBoxRow>
                          </>
                        }
                      </>
                   }
                   form={(gotoResume) => 
                      <TaxPayerDetailsSectorForm taxPayer={taxPayer}  
                                                 sectorOptions={sectorOptions}
                                                 onChange={onChange}
                                                 onSave ={() => {onSave(); gotoResume()}}/>
                  }>
      </EditingIBox>
    </>
  )
}

export default TaxPayerDetailsSector