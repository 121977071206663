import React from 'react'
import TaxSection from '../common/TaxSection'
import ProcessPenalty20Fields from './ProcessPenalty20Fields'
import ProcessPenalty20Table from './ProcessPenalty20Table'
import {PENALTY_TYPE, PENALTY_TYPE_NAMES} from 'bl/ns/process/penalty'

const ProcessPenalty20 = ({process, onChangeProcess, canEdit}) => 
  <TaxSection title={`Sanción ${PENALTY_TYPE_NAMES[PENALTY_TYPE.ART_203]}`}>
    <ProcessPenalty20Fields process={process}
                            onChangeProcess={onChangeProcess}
                            canEdit       = {canEdit}/>

    <ProcessPenalty20Table  process={process}
                            onChangeProcess={onChangeProcess}
                            canEdit       = {canEdit}/>
  </TaxSection>


export default ProcessPenalty20