import React from 'react'
import OrdinanceDetails from '../../common/OrdinanceDetails'
import VTOrdinanceDetailsForm from './VTOrdinanceDetailsForm'

const VTOrdinanceDetails = (props) =>
  <OrdinanceDetails
             taxCode='vt'
             taxDataFields={['incr_coefficient', 'tax_rate', 'discount_rate']}
             renderForm={(fprops) => VTOrdinanceDetailsForm({...fprops})}
             {...props}/>

export default VTOrdinanceDetails