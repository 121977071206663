import withContext from 'cli/context/withContext'
import React, { useEffect, useState } from 'react'
import {FInputText} from 'formiga-reactstrap'

const IncomeInvoice = ({process, onChangeProcess, permiss}) => {
  const [invoice, setInvoice]= useState(process.invoice_number)

  useEffect(() => {
    setInvoice(process.invoice_number)
  }, [process])

  const handleChangeInvoice = (val, confirm)=> {
    setInvoice(val)
    if (confirm) {
      onChangeProcess('invoice_number', val)
    }
  }

  return ( 
    <FInputText 
      name     = {`process_${process.id}_invoice_number`}
      label    = {undefined}
      icon     = {false}
      value    = {invoice}
      required = {false}
      feedback = {undefined}
      onChange = {handleChangeInvoice}
      inline   = {true}
      showValidity={0}
      readOnly  = {!permiss.can_user_edit_income()}
    />
  )
}

export default withContext(IncomeInvoice)
