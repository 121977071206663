import React from 'react'
import { IBox, IBoxRow } from 'cli/ui/components/ibox'
import { asPrice } from 'farrapa/numbers'


const NiceTotalValue = ({label, value, color}) => 
  <div style={{marginLeft: '2em'}}>
    <span style={{color: "gray", fontStyle: "italic"}}>{label}: </span>
    <span style={{color}}>
      {value}
    </span>
  </div>


const NiceTotals = ({totals}) => {
  return (
    <IBox narrow
          watermark={{text: 'Totales', color: '#123456'}}>
      <IBoxRow right>
        {totals.map((tot, tidx) => 
          <NiceTotalValue key  = {`nice_total_${tidx}`}
                          label= {tot.label}
                          value= {tot.price ? asPrice(tot.value) : tot.value} 
                          color= {tot.color}/>

        )}
      </IBoxRow>
    </IBox>
  )
}

export default NiceTotals