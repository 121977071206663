const GENRE_MALE= 1
const GENRE_FEMALE= 2
const GENRE_OTHERS= 3

const GENRES= [
  [GENRE_MALE  , 'Masculino'],
  [GENRE_FEMALE, 'Femenino'],
  [GENRE_OTHERS, 'Otros']
]

module.exports = {GENRE_MALE, GENRE_FEMALE, GENRE_OTHERS, GENRES}