import React from 'react'
import {intre_pretty_short} from 'intre'
import {TimelineEvent, TimelineEventInfo, TimelineEventRight} from 'cli/ui/components/timeline'
import {Disagree, Agree} from './parts/actions'
import EventAgreeWithDateForm from './EventAgreeWithDateForm'

const EventAgreeWithDate = ({subStep, onProcessChange, canEdit}) => {
  const handleOnAgreed = (v) => {
    onProcessChange({[subStep.field_name_agreed]: v})
  }
  const handleOnAgreedDate = (v) => {
    onProcessChange({[subStep.field_name_agreed_date]: v})
  }

  return (
      <TimelineEvent  key      ={`process_inspect_agree`}
                      legend   = {{icon: "agree", 
                                  color: subStep.agreed==undefined
                                         ? 'gray'
                                         : subStep.agreed 
                                           ? 'lightgreen' 
                                           : 'red'}}
                      date     = {undefined}
                      isLast   = {subStep.done===false}
                      >

        <TimelineEventInfo>
          <span>
            <i style={{color: "gray"}}>
              {subStep.agreed==undefined
              ? "Pendiente de decidir conformidad"
              : subStep.agreed==true
                ? `Conforme ${subStep.agreed_date!=undefined ? intre_pretty_short(subStep.agreed_date) : ''}`
                : `Disconforme ${subStep.agreed_date!=undefined ? intre_pretty_short(subStep.agreed_date) : ''}`
              }
            </i>
          </span>     
        </TimelineEventInfo>
          <TimelineEventRight>
            {canEdit
             ? <>
                {subStep.agreed==true
                ? 
                  <EventAgreeWithDateForm subStep      = {subStep}
                                          onAgreedDate = {(v) => handleOnAgreedDate(v)}/>
                : null}
                <Disagree     disabled={subStep.agreed==false}
                              onClick={() => handleOnAgreed(false)}/>
                <Agree        disabled={subStep.agreed==true}
                              onClick={() => handleOnAgreed(true)}/>
              </>
             : null}                          
          </TimelineEventRight>
      </TimelineEvent>
  )


}

export default EventAgreeWithDate