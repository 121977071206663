import React, { useState, useEffect, useCallback } from 'react'
import withContext from 'cli/context/withContext'

import {LoadingBig}  from 'cli/ui/components/loading'
import Page from 'cli/ui/pages/layout/Page'
//import {Row, Col}    from 'reactstrap'
import CalendarFilter from './CalendarFilter'
import Calendar from 'cli/ui/components/calendar/Calendar'
// import { ActionAdd } from 'cli/ui/components/buttons/actions'
import { useStoragedState } from 'cli/ui/hooks/useStoragedState'
import { arrayRemove } from 'farrapa/collections'

//const _filterEvents = (events, filter, shownCalendars, uid) => {
  const _filterEvents = (events, filter, _uid) => {
  const filteredEvents= events.filter((ev) => {
    /*
    const isMine= ev.acc_user_id==uid
    const isAuto= ev.acc_user_id==undefined
    const isTeam= !isMine && !isAuto
    const isPrivate= ev.private===true
    
    const isMineAndPvt= isMine && isPrivate
    const isMineAndShared= isMine && !isPrivate
    const isTeamAndShared= isTeam && !isPrivate

    const okCal= shownCalendars[ev.calendar_id] === true
    const okDone = (ev.done!==true || filter.show_done===true)
    
    const okMinePvt = (!isMineAndPvt || filter.show_mine_pvt===true)
    const okMineShared = (!isMineAndShared || filter.show_mine_shared===true)
    const okTeamShared = (!isTeamAndShared || filter.show_team_shared===true)
    const okAuto = (!isAuto || filter.show_auto===true)

    let okCouncil= true
    if (filter?.council_id) {
      if (ev.process_id!=undefined) {        
        okCouncil= ev?.process_info?.council.id== filter.council_id
      } else {
        okCouncil = false
      }
    }

    if  (okCal && okDone && okMinePvt && okMineShared && okTeamShared && okAuto && okCouncil) {
      return true
    }
    */
    const isAuto= ev.acc_user_id==undefined
    const okDone = true // (ev.done!==true)

    let okCouncil= true
    if (filter?.council_id) {
      if (ev.process_id!=undefined) {        
        okCouncil= ev?.process_info?.council.id== filter.council_id
      } else {
        okCouncil = false
      }
    }

    if  (isAuto && okDone && okCouncil) {
      return true
    }


  })
  return filteredEvents
  
}


const CalendarPage = ({fetcher, user}) => {
  
  const [loading, setLoading]= useState(1)
  const [userCalendars, _setUserCalendars]= useState([])
  //const [usedCalendars, setUsedCalendars]= useState([])
  const [councils, setCouncils]= useState([])
  const [events, setEvents]= useState([])
  
  const [filter, setFilter]= useStoragedState({
    show_done: false,
    show_mine_pvt: true,
    show_mine_shared: true,
    show_team_shared: true,
    show_auto: true,
    council_id: undefined
  }, 'calendar_filter')

  // const [shownCalendars, setShownCalendars]= useStoragedState({}, 'calendar_shown_cals')
  const [filteredEvents, setFilteredEvents]= useState([])

  
  // load main data: events and userCalendars
  const handleFetchData = useCallback((cb) => {
    async function fetchData() { 
      setLoading(1)
      //const ccs= await fetcher.get('/api/calendar/detail', {acc_user_id: user.id, include_sys: 1})
      //const ccs= await fetcher.get('/api/calendar/detail', {})
      //const nUserCalendars= ccs.data
      //setUserCalendars(nUserCalendars || [])

      const res= await fetcher.get('/api/calendar_event/detail', {acc_user_id: user.id})
      const nEvents= res.data
      setEvents(nEvents || [])

      if (cb!=undefined) {
        cb()
      }
    }

    fetchData()
  }, [fetcher, user.id])
  
  // test data 
  // const handleTestData = useCallback(() => {
  //   async function createTestData() {
  //     await fetcher.post('/api/calendar_event/test_data', {})
  //     handleFetchData()
  //   }
  //   createTestData()
  // }, [fetcher, handleFetchData])

  
  // when main data is loaded, lets parse usedCalendars and councils

  useEffect(() => {

    let /*nCalendars= {},*/ nCouncils= {}
    events.map((ev) => {
      // if (Object.keys(nCalendars).indexOf(ev.calendar_id) < 0) {
      //   nCalendars[ev.calendar_id]= {
      //     id: ev.calendar_id,
      //     name: ev.calendar_name,
      //     color: ev.calendar_color,
      //     event_count: 1
      //   }
      // } else {
      //   nCalendars[ev.calendar_id]+= 1
      // }
      if (ev.process_id!=undefined) {
        const councilId= ev?.process_info?.council.id
        if (Object.keys(nCouncils).indexOf(councilId) < 0) {
          nCouncils[councilId]= {
            id: councilId,
            name: ev?.process_info?.council?.name,
            event_count: 1
          }
        } else {
          nCouncils[councilId]+= 1
        }
      }
    })
    let nCouncilsMap= {}
    Object.values(nCouncils).map(c => 
      nCouncilsMap[c.id]= `${c.name} (${c.event_count})`)

    //setUsedCalendars(Object.values(nCalendars))
    setCouncils(nCouncilsMap)
  }, [events])

  useEffect(() => {
    //const nFilteredEvents= _filterEvents(events, filter, shownCalendars, user.id)
    const nFilteredEvents= _filterEvents(events, filter, user.id)
    setFilteredEvents(nFilteredEvents)
    setLoading(0)

  }, [events, filter, /*shownCalendars,*/ user.id])


  useEffect(() => {
    handleFetchData()
  }, [handleFetchData])



  

  const handleEventUpsave = async (event) => {
    if (event.calendar_id==undefined && event.calendar==undefined) {
      return
    }
    
    let grpid

    if (event.calendar!=undefined) {
      event.color = event.calendar.color
      
      event.calendar.acc_user_id= user.id
      grpid= await fetcher.upsave('/api/calendar', event.calendar)
      
      delete event.calendar
      event.calendar_id= grpid
    }

    event.acc_user_id= user.id
    
    await fetcher.upsave('/api/calendar_event', event)

    handleFetchData(() => {
      // If a new calendar is created, show it by default
      // if (grpid!=undefined) {
      //   onToggleShownCalendar(grpid, true)
      // }
    })
  }   

  const handleEventRemove = async (eid) => {
    await fetcher.remove('/api/calendar_event', eid)

    const nFilteredEvents= [
      ...filteredEvents
    ]
    arrayRemove(nFilteredEvents,
      nFilteredEvents.find((e) => e.id==eid)
    )
    setFilteredEvents(nFilteredEvents)
  }     


  //  const onToggleShownCalendar = (calId, show) => {
  //    setLoading(1)
  //    const nShownCalendars= {
  //      ...shownCalendars,
  //      [calId]: show
  //    }
  //    setShownCalendars(nShownCalendars)
  //  }

  const onFilterChange = (opt, show) => {
    setLoading(1)
    const nFilter= {
      ...filter,
      [opt]: show
    }
    setFilter(nFilter)
  }

  if (loading==1) {
    return <LoadingBig/>
  }
  
  return (
    <Page breads = {['Inicio']}
          title  = "Inicio"
          actions= {
            <CalendarFilter
              filter        = {filter}
              //shownCalendars= {shownCalendars}
              //usedCalendars = {usedCalendars}
              councils      = {councils}
              onFilterChange= {onFilterChange}
              //onToggleShownCalendar= {onToggleShownCalendar}  
            />           
          }>
      <Calendar   userCalendars = {userCalendars}
                  events       = {filteredEvents}
                  onEventUpsave= {(event) => handleEventUpsave(event)}
                  onEventRemove= {(eid) => handleEventRemove(eid)}
                  uid          = {user.id}/>  
    </Page>

  )
}

export default withContext(CalendarPage)
