import React from 'react'

const ProgressBarLabel = ({left, leftColor, center, right, rightColor}) => 
  <div style={{display: "flex"}}>
    <div style={{color:leftColor, flex: "1 0 auto", textAlign: "left"}}>
      {left || ''}
    </div>
    {center!=undefined && center!=''
     ? 
      <div>
        {center}
      </div>
     : null
    }
    <div style={{color:rightColor, flex: "1 1 auto", textAlign: "right"}}>
      {right || ''}
    </div>
  </div>


export default ProgressBarLabel