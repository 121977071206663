import React, {useState} from 'react'
import { ActionAdd} from 'cli/ui/components/buttons/actions'
import CouncilDetailsAddEdition from './CouncilDetailsAddEdition'
import CouncilDetailsAddOrdinance from './CouncilDetailsAddOrdinance'
import withContext from 'cli/context/withContext'

const CouncilDetailsActions = ({councilId, hasOrdinances, permiss}) => {
  const [creating, setCreating]= useState(undefined)

  if (creating=='edition') {
    return (
      <CouncilDetailsAddEdition 
        handleBack={() => setCreating(undefined)}/>
    )
  }

  if (creating=='ordinance') {
    return (
      <CouncilDetailsAddOrdinance 
        councilId={councilId}  
        handleBack={() => setCreating(undefined)}/>
    )
  }  

  return (
    <>   
    {(permiss.can_user_edit_processes() && hasOrdinances)
     ? 
      <ActionAdd key    = {`council_det_edi_new`}
                 onClick= {() => setCreating('edition')}
                 label  = {'Crear una tirada'}/>
     : null
    }
    {permiss.can_user_edit_ordinances()
     ?
      <ActionAdd key    = {`council_det_ord_new`}
                onClick= {() => setCreating('ordinance')}
                label  = {'Crear una ordenanza'}/>
     : null}
   </>)
}

export default withContext(CouncilDetailsActions)
