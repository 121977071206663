import React from 'react'
import {intre_pretty_medium} from 'intre'
import Icon from 'cli/ui/components/icon'
import { getIconForFileType, toMB } from 'bl/ns/docus'
import { DOCU_RECEPTION_MODES } from 'bl/ns/process/process'
import { ActionRemove } from 'cli/ui/components/buttons/actions'
import NiceList from 'cli/ui/components/nice_list'
import { IfPermission } from 'cli/context/IfPermission'




const ProcessDocuList = ({ process, onOpen, onRemove}) => {
  const docuFields= [
    /* eslint react/display-name:0 */
    {
      label: "Archivo",
      className: "main", 
      value: (docu) => docu.name || '',
      render: (docu) =>
        <div style={{ cursor: "pointer", textDecorationLine: "underline" }} onClick={() => onOpen(docu.id)}>
          <Icon icon={getIconForFileType(docu.dtype)} inline={true}/>
          {docu.name + ' (' + toMB(docu.size) + 'mb)'}
        </div>
    }, 
    {
      label: "Recibido",
      value: (docu) => docu.reception_date,
      render: (docu) => intre_pretty_medium(docu.reception_date)
    }, 
    {
      label: "Vía",
      value: (docu) => DOCU_RECEPTION_MODES[docu.reception_mode]
    },
    {
      label: "Completo",
      value: (docu) => docu.status,
      render: (docu) => docu.status==1
          ? <Icon icon={'checkmark'}/>
          : null
        
    },
    {
      label: "Diligencia",
      value: (docu) => docu.diligence==undefined
                       ? ''
                       : docu.diligence==1
                         ? 'Primera'
                         : docu.diligence==2
                           ? 'Segunda'
                           : docu.diligence==3
                             ? 'Tercera'
                             : docu.diligence.toString()
    },
    {
      label: "Acciones", 
      className: "right", 
      render: (docu, onEvent) => 
        <IfPermission code="process_edit">
          <ActionRemove onClick={() => onEvent('remove', {id: docu.id})}
                        enabled={true}/>
        </IfPermission>
    }
  ]

  
  return (
    <NiceList fields     = {docuFields}
              initialSort= {[0, "asc"]}
              data       = {process.docus}
              makeKey    = {(docu) => `docu_list_row_${docu.id}`}
              onEvent    = {(ev, params) => {
                if (ev=='remove') {
                  onRemove(params.id)
                }
              }}
          />
  )
}


export default ProcessDocuList
