import React from 'react'
import SimpleModal from 'cli/ui/components/SimpleModal'
import { FInputTextArea } from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'
import withContext from 'cli/context/withContext'

const IncomeRemarksModal = ({show, title, remarks, onChange, onClose, onSave, permiss}) => {

  return (
    <SimpleModal title  ={title}
                 show   ={show}
                 onClose={() => onClose()}
                 onSave = {
                  permiss.can_user_edit_income()
                  ? () => onSave()
                : undefined}>

      <div style={{width: '100%'}}>
        <FInputTextArea 
          name      = "income_remarks"
          icon      = {<Icon icon='bubble2'/>}
          value     = {remarks}
          onChange  = {(v, _c) => onChange(v)}
          readOnly  = {!permiss.can_user_edit_income()}
          />
      </div>
    </SimpleModal>
  )
}
export default withContext(IncomeRemarksModal)
