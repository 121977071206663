import React from 'react';
import {Button} from '../buttons'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class SimpleMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      backdrop: true
    };
  }

  close() {
    this.setState({
      modal: false
    });

  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.show} toggle={() => this.props.onClose()} 
               backdrop={true}
               modalTransition={{ timeout: 20 }} backdropTransition={{ timeout: 10 }}>
          <ModalHeader toggle={() => this.props.onClose()}>{this.props.title}</ModalHeader>
          <ModalBody>
            {this.props.message}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" icon="check" onClick={() => this.props.onClose()}>
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default SimpleMessage;