import React from 'react'
import ActionButton from './ActionButton'
import {uvl} from 'farrapa/commons'

const ActionCloseProc = ({ onClick, to, enabled, label }) =>
  <ActionButton color='#000000'
    label={label}
    icon={'file-zip'}
    onClick={(e) => { onClick(e) }}
    to={to}
    enabled={uvl(enabled, true)}>
  </ActionButton>

export default ActionCloseProc
