import React, {useState} from 'react'
import { FForm, FInputFile} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'
import { arrayBufferToBase64 } from 'farrapa/encoding'


const DocsPrepareForm = ({ onUpload, onCancel}) => {
  const [file, setFile]= useState({})

  const handleChange = (file) => {
    setFile(file)
    if (file.buffer) {
      const nFile= {...file}
      nFile.buffer= arrayBufferToBase64(file.buffer)
      onUpload(nFile)
    }
  }

  return (
    <FForm onCancel = {onCancel}
          onSave   = {undefined}>
        <>
          <FInputFile   name       = "doc"
                        label      = "Fichero"
                        value      = {file}
                        onChange   = {(v) => handleChange(v)}
                        onDownload = {undefined}
                        accept     = ".docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        icon       = {<Icon icon="file-word"/>}
                        />
        </>
    </FForm>
  )
}



export default DocsPrepareForm




