import React, {useState, useEffect, useCallback} from 'react'

import withContext from 'cli/context/withContext'
//
import Page from 'cli/ui/pages/layout/Page'
import { Row, Col } from 'reactstrap'
import UserEmailsPreview from './UserEmailsPreview'

import UserEmailsList from './UserEmailsList'
import { LoadingBig } from 'cli/ui/components/loading'

const _BREADS = [['/', 'Inicio'], ['/user', 'Mis cosas'], ['Correo Electrónico']]

const UserEmails = ({fetcher, user}) => {
  
  const [emailLoaded , setEmailLoaded ] = useState(false)
  const [emailList   , setEmailList   ] = useState([])
  const [previewEmail, setPreviewEmail] = useState(undefined)


  useEffect(() => {
    const fetchData= async() => {
      const res= await fetcher.get('/api/user_email_archive/detail', { acc_user_id: user.id })
      setEmailList(res.data)
      setEmailLoaded(true)
    }
    fetchData()
  }, [fetcher, user.id])

  const handlePreview = useCallback((uemid) => {
    const nPreviewEmail= emailList.find((e) => e.id == uemid)
    setPreviewEmail(nPreviewEmail)

  }, [emailList])


  return (
    <Page breads={_BREADS}
          title="Emails">
      <Row>
        <Col xs="12">       
          {emailLoaded==false ?
            <LoadingBig /> :
            <UserEmailsList emailList={emailList}
                            onPreview={(uemid) => handlePreview(uemid)}/>
          }         
        </Col>
      </Row>
      {previewEmail==undefined
       ?  null
       : 
        <Row>
          <Col xs="12">
            <UserEmailsPreview currentEmail={previewEmail}
                              onClose     ={setPreviewEmail(undefined)}/>
          </Col>
        </Row>
      }
    </Page>
  )
}


export default withContext(UserEmails)
