import React, {useState} from 'react'
import {intre_from_str, intre_to_str} from 'intre'

import {Row, Col} from 'reactstrap'
import {Button} from 'cli/ui/components/buttons'
import {FForm, FInputDate} from 'formiga-reactstrap'
import TaxHeaderFields from'../common/TaxHeaderFields'
import TaxHeaderFieldsRow from'../common/TaxHeaderFieldsRow'

import {PTYPE} from 'bl/ns/process/process'

const ProcessI5TaxFields = ({ process, onChangeProcess, onGoToInspect, canEdit }) => {
  const [customEndLiqDate, setCustomEndLiqDate]= useState(process.tax_data.custom_end_liq_date)

  const handleCustomEndLiqDateChange = () => {
    const nTaxData= {...process.tax_data}
    nTaxData.custom_end_liq_date= customEndLiqDate
    onChangeProcess({tax_data: nTaxData}, true)
  }

  return (
    <Row>
      <Col md="12" lg="6">
        {process.tax.ready_for_interests.ok
        ? null
        : 
          <TaxHeaderFields title="Falta info">
            <TaxHeaderFieldsRow>
              <p style={{color: "gray"}}>
                <span>Falta info para calcular los intereses. Revisa </span>
                {process.tax.ready_for_interests.missing.from
                  ? <a href={`/councils/${process.council_id}/${process.tax_code}/${process.ordinance_id}`}>
                      {"Fecha Inicio de Intereses en la Ordenanza"}
                    </a>
                  : null
                }
                {process.tax.ready_for_interests.missing.from && process.tax.ready_for_interests.missing.to
                  ? <span> y </span>
                  : null
                }
                {process.tax.ready_for_interests.missing.to 
                  ? process.process_type==PTYPE.COMPLETE
                    ? <a href="#!" onClick={() => onGoToInspect()}>
                        {"la Fecha de Firma del Expediente"}
                      </a>
                    : <span>{"la Fecha Límite de intereses"}</span>
                  : null
                }
                <span>.</span>
              </p>
            </TaxHeaderFieldsRow>
          </TaxHeaderFields>
        }
      </Col>

      <Col md="12" lg="6">
        {process.process_type==PTYPE.REDUCED
        ? <TaxHeaderFields title="Fecha Límite Intereses">
            <TaxHeaderFieldsRow>

              <FForm  inline
                      renderButtons={(valid) => 
                        canEdit
                        ?
                          <Button style   = {{backgroundColor: '#b3dbe8', color: 'black'}}
                                  onClick = {() => handleCustomEndLiqDateChange()}
                                  disabled= {! valid}
                                  icon    = {'calendar'}>
                            {`${customEndLiqDate==undefined ? 'Asignar' : 'Cambiar'}`}
                          </Button>
                        : null
                        }>
                  <FInputDate   name       = "custom_end_liq_date"
                                value      = {intre_to_str(customEndLiqDate)}
                                onChange   = {(v) => setCustomEndLiqDate(intre_from_str(v))}
                                icon       = {"Fecha Límite Intereses"}
                                required   = {true}
                                feedback   = {' '}
                                readOnly   = {!canEdit}
                                locale     = 'es'/>
              </FForm>

            </TaxHeaderFieldsRow>
          </TaxHeaderFields>
        : null
        }
      </Col>
    </Row>
  )
}


export default ProcessI5TaxFields




