import React from 'react';
import {Container} from 'reactstrap';


class PageAnonym extends React.Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          { this.props.children }
        </Container>
      </div>
    );
  }
}

export default PageAnonym;
