import React from 'react'
import SimpleModal from 'cli/ui/components/SimpleModal'
import Icon from 'cli/ui/components/icon'
import {intre_to_str} from 'intre'
import { HyperCouncil, HyperEdition, HyperProcess, HyperTaxPayer } from 'cli/ui/components/hyper'


const RowInfo = ({icon, text}) =>
  <div className="row" style={{marginBottom: "2em", flexWrap: "nowrap"}}>
    <div>
      <Icon icon={icon}></Icon>
    </div>
    <div style={{flex: "auto", marginLeft: "1em"}}>
      {text}
    </div>
  </div>

const _buildEventName = (event) => {
  //if (event.process_id) {
  //  return event?.process_info?.event_name || ''
  //}
  return event.name
}


const _buildDatesText = (event) => {
  const has_dend= (event.dend && event.dend!=event.dstart)
  const dstart= intre_to_str(event.dstart)
  if (! has_dend) {
    const dtime= event.all_day
          ? '(todo el día)'
          : event.dtime || ''

    return `${dstart} ${dtime}`
  }

  const dend= intre_to_str(event.dend)
  return `${dstart} => ${dend}`
}

const ProcessActionsCalendarModal = ({show, title, event, onClose}) => {





  return (
    <SimpleModal title  ={title}
                  show   ={show}
                  onClose={() => onClose()}>

      <div className="calendar_popup">
        <div className="row mbottom">
          <div className="col col-start">
            <h3>{_buildEventName(event) }</h3>
          </div>
          <div className="col col-end" style={{textAlign: "right"}}>
            <div style={{
                   display: "inline-block", 
                   marginLeft: "2em", 
                   fontWeight: "bold", 
                   backgroundColor: event.calendar_color, 
                   color: "#663b3b", 
                   padding: "0 0.5em"}}>
              {`${event.calendar_name}`}
            </div>
          </div>
        </div>

        <RowInfo icon="calendar" text={_buildDatesText(event)}/>

        {event.description!=undefined && event.description!=''
          ? <RowInfo icon="text" text={event.description}/>
          : null
        }

        {((event.process_id!=undefined) && (event.process_info!=undefined))
        ? <>
            <div className="row" style={{marginBottom: "2em"}}>
              <HyperProcess id= {event.process_id} name={event.process_info.process_name}
                            taxCode={event.process_info.tax_code} editionId={event.process_info.edition.id}
                            />
            </div>
            <div className="row" style={{marginBottom: "2em"}}>
              <HyperEdition {...event.process_info.edition}/>
            </div>
            <div className="row" style={{marginBottom: "2em"}}>
              <HyperCouncil {...event.process_info.council}/>
            </div>
            <div className="row" style={{marginBottom: "2em"}}>
              <HyperTaxPayer {...event.process_info.taxpayer}/>
            </div>
          </>
        : <>
            {event.done
            ? <RowInfo icon="checkmark" text={'Está hecho'}/>
            : <RowInfo icon="time" text={'Pendiente'}/>
            }
            
            <RowInfo icon="shield" text={event.private ? 'Privado' : 'Compartido'}/>
          </>}

      </div>


    </SimpleModal>
  )
}
export default ProcessActionsCalendarModal
