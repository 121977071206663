import React from 'react'
import { EditingIBox, IBoxRow, IBoxIconedText } from 'cli/ui/components/ibox'
import { ActionDownload } from 'cli/ui/components/buttons/actions'
import CouncilDetailsWordForm from './CouncilDetailsWordForm'

const CouncilDetailsWord = ({council, onChange, onSave, onDownloadLetterhead}) => {
  const empty= council.letterhead_doc==undefined

  const letterStatus=
    council.letterhead_doc!=undefined
    ? council.letterhead===true
      ? council.letterhead_margins===true
        ? 'Membrete OK, con sus márgenes'
        : 'Membrete OK'
      : 'Membrete desactivado'
    : 'Sin membrete aún'

  return (
    <>
      <EditingIBox title="Documentación"
                   empty={empty}
                   fitHeight
                   permissionCode="council_edit"
                   actions={() =>
                    <ActionDownload label="Ver membrete" onClick={() => onDownloadLetterhead()}/>
                   }
                   resume={() => 
                      <IBoxRow center >
                        {empty
                         ? 'Sin membrete aún'
                         : <IBoxIconedText icon="file-word"
                                           text={letterStatus}/>
                         }
                      </IBoxRow>
                   }
                   form={(gotoResume) => 
                      <CouncilDetailsWordForm council={council}  
                                               onChange={onChange}
                                               onSave ={() => {onSave(); gotoResume()}}
                                               onDownloadLetterhead={onDownloadLetterhead}/>
                  }>
      </EditingIBox>
    </>
  )
}

export default CouncilDetailsWord

/*
import React, { useState } from 'react'
import { IBox, IBoxRow, IBoxIconedText } from 'cli/ui/components/ibox'
import { ActionEdit, ActionBack, ActionDownload } from 'cli/ui/components/buttons/actions'
import CouncilDetailsWordForm from './CouncilDetailsWordForm'

const CouncilDetailsWord = ({council, onChange, onSave, onDownloadLetterhead}) => {
  const [editMode, setEditMode]= useState(false)

  const letterStatus=
    council.letterhead_doc!=undefined
    ? council.letterhead===true
      ? council.letterhead_margins===true
        ? 'Membrete OK, con sus márgenes'
        : 'Membrete OK'
      : 'Membrete desactivado'
    : 'Sin membrete aún'

  return (
    <>
      <IBox narrow
            title="Documentación"
            actions={
                editMode
                ? <ActionBack label={undefined} onClick={() => setEditMode(false)}/>
                : <>
                    <ActionDownload label="Ver membrete" onClick={() => onDownloadLetterhead()}/>
                    <ActionEdit label={undefined} onClick={() => setEditMode(true)}/>
                  </>
            }>
        {editMode==false
         ? 
          <IBoxRow>
            <IBoxIconedText icon="file-word"
                            text={letterStatus}/>
          </IBoxRow>
         : <CouncilDetailsWordForm council={council}
                                   onChange={onChange}
                                   onSave ={() => {onSave(); setEditMode(false)}}
                                   onDownloadLetterhead={onDownloadLetterhead}/>}
      </IBox>
    </>
  )
}


export default CouncilDetailsWord
*/