import {intre_pretty_medium} from 'intre'

const getOrdinanceInfoAE = (tax_data) => {

  const aeDateInfo= 
    tax_data?.bulletin_date!=undefined
    ? `Boletín del ${intre_pretty_medium(tax_data.bulletin_date)}`
    : 'Sin fecha de boletín'
 
  /*
  const luFrom= tax_data?.from_date!=undefined ? intre_pretty_medium(tax_data.from_date) : ''
  const luTo  = tax_data?.to_date!=undefined ? intre_pretty_medium(tax_data.to_date) : ''

  const luPeriod=
  (!luFrom && !luTo)
  ? 'Sin fechas de inicio/fin aún'
  : (luFrom && luTo)
    ? `Desde ${luFrom} hasta ${luTo}`
    : luFrom
      ? `Desde ${luFrom}`
      : `Hasta ${luTo}`

  const icioLicense= tax_data?.icio_license_num
    ? `Licencia ICIO: ${tax_data.icio_license_num}`
    : ''
  
  const icioRate= tax_data?.icio_tax_rate
    ? `Tasa ICIO: ${tax_data.icio_tax_rate}%`
    : ''
  
  const icioInfo= (icioLicense && icioRate)
    ? [icioLicense, icioRate].join('. ')
    : (icioLicense || icioRate)

  const luLicense= tax_data?.lu_license_num
    ? `Licencia LU: ${tax_data.lu_license_num}`
    : ''
  
  const luRate= tax_data?.lu_tax_rate
    ? `Tasa LU: ${tax_data.lu_tax_rate}%`
    : ''
  
  const luInfo= (luLicense && luRate)
    ? [luLicense, luRate].join('. ')
    : (luLicense || luRate)

  let info= [
    {
      icon: 'calendar',
      text: luDateInfo
    },
    {
      icon: 'calendar',
      text: luPeriod
    }      
  ]  

  if (icioInfo) {
    info.push({
      icon: 'percent',
      text: icioInfo      
    })
  }

  if (luInfo) {
    info.push({
      icon: 'percent',
      text: luInfo      
    })
  }
  */

  const aeLicense= tax_data?.license_num
    ? `Licencia: ${tax_data.license_num}`
    : ''

  let info= [
    {
      icon: 'calendar',
      text: aeDateInfo
    }   
  ]  
  if (aeLicense) {
    info.push({
      icon: 'text',
      text: aeLicense

    })
  }



  return info
}

export default getOrdinanceInfoAE