import React from 'react'

const AELicenseImportStep3 = ({ ordinance}) =>
  <>
    <a href={`/councils/${ordinance.council_id}/ae/${ordinance.id}`}>
      {`Ir a la lista de licencias de ${ordinance.name}`}
    </a>
  </>


export default AELicenseImportStep3
        
        
        
        
