import React from 'react'
import {intre_from_str, intre_to_str} from 'intre'
import {Row, Col} from 'reactstrap'
import {FForm, FInputFloat, FInputDate} from 'formiga-reactstrap'
import TaxHeaderFields from'../common/TaxHeaderFields'
import TaxHeaderFieldsRow from'../common/TaxHeaderFieldsRow'

const ProcessVTTaxFields = ({ process, onChange, canEdit }) => {

  const handleChange = (name, value, confirmed) => {
    onChange(value, name, confirmed)
  }

  const missingLinks = () => {
    let mlinks= []
    if (process.tax.ready_for_calcs.missing.incr_coefficient) {
      mlinks.push(
        <a href={`/councils/${process.council_id}/${process.tax_code}/${process.ordinance_id}`}>
          {`el coeficiente de Incremento`}
        </a>)
    }
    if (process.tax.ready_for_calcs.missing.tax_rate) {
      mlinks.push(
        <a href={`/councils/${process.council_id}/${process.tax_code}/${process.ordinance_id}`}>
          {`el Tipo Impositivo`}
        </a>)
    }
    return mlinks
  }

  const mlinks= missingLinks()
  
  return (
      <FForm  inline
              renderButtons={(_valid) => null}>
        <>
          {process.tax.ready_for_calcs.ok===false
           ? <Row>
              <Col lg="6">
                <TaxHeaderFields title="Falta info">
                  <TaxHeaderFieldsRow>
                    <p style={{color: "gray"}}>
                      <span>Falta info para los cálculos. Revisa </span>
                      {mlinks.map((l,idx) => 
                        <span key={`missing_link_idx`}>
                          {l}
                          {idx<(mlinks.length-1)
                          ? <span>,</span>
                          : null}
                        </span>
                      )}
                      <span>.</span>
                    </p>
                  </TaxHeaderFieldsRow>
                </TaxHeaderFields>           
              </Col>
             </Row>
            : null}

          <Row>
            <Col lg="12" >    
              <TaxHeaderFields title="Datos">
                <TaxHeaderFieldsRow>
                  <Row>
                    <Col md="4" ></Col>
                    <Col md="4" >
                      <FInputDate   name       = "buy_date"
                                    value      = {intre_to_str(process.tax.buy_date)}
                                    onChange   = {(v, c) =>  handleChange('buy_date', intre_from_str(v), c)}
                                    icon       = {"Fecha Adquisición"}
                                    required   = {true}
                                    feedback   = {' '}
                                    readOnly   = {!canEdit}
                                    locale     = 'es'/>                   
                    </Col>
                    <Col md="4" >
                      <FInputDate   name       = "sell_date"
                                    value      = {intre_to_str(process.tax.sell_date)}
                                    onChange   = {(v, c) =>  handleChange('sell_date', intre_from_str(v), c)}
                                    icon       = {"Fecha Transmisión"}
                                    required   = {true}
                                    feedback   = {' '}
                                    readOnly   = {!canEdit}
                                    locale     = 'es'/>                   
                    </Col>                    
                  </Row>
                </TaxHeaderFieldsRow>
                <TaxHeaderFieldsRow>
                  <Row>
                    <Col md="4" >   
                      <FInputFloat name       = "cadastral_price"
                                    value      = {process.tax.cadastral_price}
                                    onChange   = {(v, c) => handleChange('cadastral_price', v|| undefined, c)}
                                    icon       = {"Valor Catastral"}
                                    decimalSign= {","}
                                    required   = {true}
                                    feedback   = {' '}
                                    readOnly   = {!canEdit}/>
                    </Col>
                    <Col md="4" >   

                      <FInputFloat name       = "buy_price"
                                    value      = {process.tax.buy_price}
                                    onChange   = {(v, c) => handleChange('buy_price', v|| undefined, c)}
                                    icon       = {"Valor Adquisición"}
                                    decimalSign= {","}
                                    required   = {true}
                                    feedback   = {' '}
                                    readOnly   = {!canEdit}/>
                    
                    </Col>
                    <Col md="4" >   

                      <FInputFloat name       = "sell_price"
                                    value      = {process.tax.sell_price}
                                    onChange   = {(v, c) => handleChange('sell_price', v || undefined, c)}
                                    icon       = {"Valor Transmisión"}
                                    decimalSign= {","}
                                    required   = {true}
                                    feedback   = {' '}
                                    readOnly   = {!canEdit}/>
                    
                    </Col>
                  </Row>
                </TaxHeaderFieldsRow>
              </TaxHeaderFields>
            </Col>

          </Row>
        </>
      </FForm>
  )
}


export default ProcessVTTaxFields




