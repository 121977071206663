

const councils= {
  name: 'Ayuntamientos',
  url: '/councils',
  icon: 'council',
  permissionCode: 'council_read'
}

const taxpayers= {
  name: 'Contribuyentes',
  url: '/taxpayers',
  icon: 'taxpayer',
  permissionCode: 'tax_payer_read'
}

const taxes= {
  name: 'Tributos',
  url: '/taxes',
  icon: 'dollar',
  permissionCode: 'process_read',
  children: [    
    {
      name: '1,5',
      url: '/taxes/i5',
      icon: 'i5',
      children: [
        {
        name: 'Tiradas',
        url: '/taxes/i5/edition',
        icon: 'edition'
        },
        {
          name: 'Nueva Tirada',
          url: '/taxes/i5/newedition',
          icon: 'plus',
          permissionCode: 'process_edit'
        },
        {
          name: 'Histórico',
          url: '/taxes/i5/historical',
          icon: 'history'
        }/*,
        {
          name: 'Búsqueda',
          url: '/taxes/i5/search',
          icon: 'search'
        }*/
      ]
    },
    {
      name: 'ICIO/LU',
      url: '/taxes/lu',
      icon: 'lu',
      children: [
        {
        name: 'Tiradas',
        url: '/taxes/lu/edition',
        icon: 'edition'
        },
        {
          name: 'Nueva Tirada',
          url: '/taxes/lu/newedition',
          icon: 'plus',
          permissionCode: 'process_edit'
        },
        {
          name: 'Histórico',
          url: '/taxes/lu/historical',
          icon: 'history'
        }/*,
        {
          name: 'Búsqueda',
          url: '/taxes/lu/search',
          icon: 'search'
        }*/
      ]
    },
    {
      name: 'IAE',
      url: '/taxes/ae',
      icon: 'ae',
      children: [
        {
        name: 'Tiradas',
        url: '/taxes/ae/edition',
        icon: 'edition'
        },
        {
          name: 'Nueva Tirada',
          url: '/taxes/ae/newedition',
          icon: 'plus',
          permissionCode: 'process_edit'
        },
        {
          name: 'Histórico',
          url: '/taxes/ae/historical',
          icon: 'history'
        }/*,
        {
          name: 'Búsqueda',
          url: '/taxes/ae/search',
          icon: 'search'
        }*/
      ]
    },
    {
      name: 'TODP',
      url: '/taxes/to',
      icon: 'to',
      children: [
        {
        name: 'Tiradas',
        url: '/taxes/to/edition',
        icon: 'edition'
        },
        {
          name: 'Nueva Tirada',
          url: '/taxes/to/newedition',
          icon: 'plus',
          permissionCode: 'process_edit'
        }, 
        {
          name: 'Histórico',
          url: '/taxes/to/historical',
          icon: 'history'
        }/*,
        {
          name: 'Búsqueda',
          url: '/taxes/to/search',
          icon: 'search'
        }*/
      ]
    },
    {
      name: 'IIVTNU',
      url: '/taxes/vt',
      icon: 'vt',
      children: [
        {
        name: 'Tiradas',
        url: '/taxes/vt/edition',
        icon: 'edition'
        },
        {
          name: 'Nueva Tirada',
          url: '/taxes/vt/newedition',
          icon: 'plus',
          permissionCode: 'process_edit'
        }, 
        {
          name: 'Histórico',
          url: '/taxes/vt/historical',
          icon: 'history'
        }/*,
        {
          name: 'Búsqueda',
          url: '/taxes/vt/search',
          icon: 'search'
        }*/
      ]
    },
    {
      name: 'Búsqueda',
      url: '/taxes/search',
      icon: 'search'
    }
  ]
}

const income= {
  name: 'Recaudación',
  url: '/income',
  icon: 'dollar',
  permissionCode: 'income_read'
}

const stats = {
  name: 'Indicadores',
  url: '/stats',
  icon: 'percent',
  permissionCode: 'stats_read'
}

const docs = {
  name: 'Gestión Documental',
  url: '/docs',
  icon: 'file-word',
  permissionCode: 'docs_read',
  children: [
    {
      name: 'Inspección',
      url: '/docs/inspect',
      icon: 'file-word'
    },
    {
      name: 'Sancionador',
      url: '/docs/penalty',
      icon: 'file-word'
    },        
  ]
}

const setts = {
  name: 'Configuración',
  url: '/settings',
  icon: 'cogs',
  permissionCode: 'setts_read',
  children: [
    {
      name: 'Usuarios y perfiles',
      url: '/settings/accontrol',
      icon: 'users',
      permissionCode: 'setts_user_edit',
      children: [
        {
          name: 'Usuarios',
          url: '/settings/accontrol/users',
          icon: 'users',
          permissionCode: 'setts_user_edit'
        },
        {
          name: 'Perfiles y permisos',
          url: '/settings/accontrol/roles',
          icon: 'shield',
          permissionCode: 'setts_user_edit'
        },        
      ]
    },
    {
      name: 'Tipos de Interés',
      url: '/settings/irates',
      icon: 'irate',
      permissionCode: 'setts_irate_edit'
    },
    {
      name: 'Agentes',
      url: '/settings/agents',
      icon: 'agent',
      permissionCode: 'setts_agent_edit'
    },
    {
      name: 'Sectores',
      url: '/settings/sectors',
      icon: 'sector',
      permissionCode: 'setts_sector_edit'
    },
    {
      name: 'Calendario',
      url: '/settings/calendar',
      icon: 'calendar',
      permissionCode: 'setts_calendar_edit'
    }
  ]
}

const menu= 
  [
    councils,
    taxpayers,
    taxes,
    income,
    stats,
    docs,
    setts,    
  ]

export { menu }
