import React from 'react'
import Button from './Button'

const ButtonStepsEnd = ({ onDone, disabled }) =>
  <Button icon = {'checkmark2'}
          onClick = {onDone}
          disabled= {disabled!=undefined ? disabled : false}>
    {"OK"}
  </Button>

export default ButtonStepsEnd