import React from 'react'

import { b64toBlob, arrayBufferToBase64 } from 'farrapa/encoding'
import FileSaver from 'file-saver'

import {Row, Col} from 'reactstrap'
import CouncilDetailsPlans from './boxes/CouncilDetailsPlans'
import CouncilDetailsWord from './boxes/CouncilDetailsWord'
import CouncilDetailsClerk from './boxes/CouncilDetailsClerk'
import CouncilDetailsData from './boxes/CouncilDetailsData'
import CouncilDetailsNotes from './boxes/CouncilDetailsNotes'
import useAlert from 'cli/ui/hooks/useAlert'
import withContext from 'cli/context/withContext'

const CouncilDetailsInfo = ({fetcher, council, plans, onChangeCouncil}) => {
  const ualert= useAlert()

  const handleChange = (name, value) => {
    if (name=='letterhead_doc') {
      if (value.buffer) {
        value.buffer= arrayBufferToBase64(value.buffer)
      }
    }

    onChangeCouncil(name, value)
  }

  const handleSave = async () => {
    const _res= await fetcher.upsave('/api/council', council)
  }

  const handleDownloadLetterhead = async () => {
    try {
      const council = await fetcher.find('/api/council', council.id)
      const doc = council.letterhead_doc
      const blob = b64toBlob(doc.buffer, doc.type)
      FileSaver.saveAs(blob, doc.name)    
            
    } catch(e) {
      ualert('Error descargando fichero', 'error')
      console.error(e)
    }
  }  
  
  //  const handleRemoveOrdinance = useCallback(async (ordinanceId) => {
  //    await fetcher.remove('/api/ordinance', ordinanceId)
  //
  //    ///await fetchCouncil(councilId)
  //
  //  }, [fetcher])


  return (
    <>
      {/*<Row>
          <Col xs="12">
            <div>
              <h2>Info del ayuntamiento</h2>
            </div>    
          </Col>
      </Row>*/}
      <Row>   
        <Col xs="12" md="6" lg="4">
          <CouncilDetailsPlans council={council}
                                plans={plans}/>
        </Col>
        <Col xs="12" md="6" lg="2">
          <CouncilDetailsClerk council={council}
                              onChange={(n,v) => handleChange(n,v)}
                              onSave={() => handleSave()}/>
        </Col>
        <Col xs="12" md="6" lg="2">
          <CouncilDetailsWord council={council}
                              onChange={(n,v) => handleChange(n,v)}
                              onSave={() => handleSave()}
                              onDownloadLetterhead={() => handleDownloadLetterhead()}/>
        </Col>
        <Col xs="12" md="6" lg="2">
          <CouncilDetailsData council={council}
                              onChange={(n,v) => handleChange(n,v)}
                              onSave={() => handleSave()}/>
        </Col>
        <Col xs="12" md="6" lg="2">
          <CouncilDetailsNotes council={council}
                              onChange={(n,v) => handleChange(n,v)}
                              onSave={() => handleSave()}/>
        </Col>
      </Row>
    </>

  )
}


export default withContext(CouncilDetailsInfo)
