class I5ProcFile {

  id                    = undefined
  process_id            = undefined
  
  process_step          = undefined
  process_doc           = undefined

  doc_date              = undefined
  agent_id              = undefined
  agent_name            = ''
  agent_phone           = ''
  signer_name           = ''
  signer_nif            = ''
  signer_pos            = ''

  notification_date     = undefined
  notification_accepted = 1
  goon                  = false

  last_print_at         = undefined
  last_print_by         = undefined
  last_print_by_name    = ''
  created_at            = undefined
  created_by            = undefined
  last_update_at        = undefined
  last_update_by        = undefined
  
  constructor(obj) {
    this.update(obj)
  }

  update(obj) {
    if (typeof obj == 'object') {
      Object.keys(this)
            .filter((k) => Object.prototype.hasOwnProperty.call(obj, k))
            .map((k) => {
              this[k]= obj[k]
            }) 
    }
  }
  /*
  forDocx() {
    return {
      id          : this.id,
      process_id  : this.process_id,
      process_step: this.process_step,
      process_doc : this.process_doc,
      doc_date    : this.doc_date,
      agent_name  : this.agent_name,
      agent_phone : this.agent_phone,
      signer_name : this.signer_name,
      signer_nif  : this.signer_nif ,
      signer_pos  : this.signer_pos
    }
  }  
  */

  toJson() {
    return {
      id                 : this.id                ,
      process_id         : this.process_id        ,
      process_step       : this.process_step      ,
      process_doc        : this.process_doc       ,
      doc_date           : this.doc_date          ,
      agent_id           : this.agent_id          ,
      agent_name         : this.agent_name        ,
      agent_phone        : this.agent_phone       ,
      signer_name        : this.signer_name       ,
      signer_nif         : this.signer_nif        ,
      signer_pos         : this.signer_pos        ,
      notification_date  : this.notification_date ,
      notification_accepted: this.notification_accepted,
      goon               : this.goon              ,
      last_print_at      : this.last_print_at     ,
      last_print_by      : this.last_print_by     ,
      last_print_by_name : this.last_print_by_name,
      created_at         : this.created_at        ,
      created_by         : this.created_by        ,
      last_update_at     : this.last_update_at    ,
      last_update_by     : this.last_update_by    ,
    }
  }
}

export default I5ProcFile
