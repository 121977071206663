import React from 'react'
import {FForm, FInputText, FInputFloat, FInputCheckbox, FInputInt} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'


const AELicenseDetailsForm = ({ license, existingNames, onChange, onSave, onCancel, isNew, permiss}) => 

  <FForm  onCancel     = {isNew ? onCancel : undefined}
          onSave       = {permiss.can_user_edit_ordinances() ? onSave : undefined}>
          <>
            <FInputText  name       = "name"
                         label      = "Licencia"
                         icon       = {<Icon icon="file-zip"/>}
                         value      = {license.name}
                         required   = {true}
                         feedback   = {'Inserta un nombre válido'}
                         onChange   = {(v) => onChange('name', v)}
                         disallowedValues={existingNames}
                         readOnly    = {!permiss.can_user_edit_ordinances()}
            />

            <FInputText  name       = "taxpayer_name"
                         label      = "Nombre"
                         icon       = {<Icon icon="user"/>}
                         value      = {license.taxpayer_name}
                         required   = {true}
                         feedback   = {'Nombre Contribuyente'}
                         onChange   = {(v) => onChange('taxpayer_name', v)}
                         readOnly    = {!permiss.can_user_edit_ordinances()}
            />    

            <FInputText  name       = "nif"
                         label      = "NIF"
                         icon       = {<Icon icon="profile"/>}
                         value      = {license.nif}
                         feedback   = {'Introduzca un NIF'}
                         onChange   = {(v) => onChange('nif', v)}
                         readOnly    = {!permiss.can_user_edit_ordinances()}
            /> 

            <FInputText  name       = "taxpayer_address"
                         label      = "Dirección"
                         icon       = {<Icon icon="location"/>}
                         value      = {license.taxpayer_address}
                         feedback   = {'Inserta una dirección del contribuyente'}
                         onChange   = {(v) => onChange('taxpayer_address', v)}
                         readOnly    = {!permiss.can_user_edit_ordinances()}
            />     

            <FInputInt name       = "section"
                          label      = "Sección"
                          icon       = {<Icon icon="tree"/>}
                          value      = {license.section}
                          decimalSign= {","}
                          onChange   = {(v) => onChange('section', v)}
                          readOnly    = {!permiss.can_user_edit_ordinances()}
            />   

            <FInputInt name       = "division"
                          label      = "División"
                          icon       = {<Icon icon="tree"/>}
                          value      = {license.division}
                          decimalSign= {","}
                          onChange   = {(v) => onChange('division', v)}
                          readOnly    = {!permiss.can_user_edit_ordinances()}
            />   

            <FInputText  name       = "epigraph"
                         label      = "Epígrafe"
                         icon       = {<Icon icon="file-zip"/>}
                         value      = {license.epigraph}
                         required   = {false}
                         feedback   = {'Inserta un epígrafe'}
                         onChange   = {(v) => onChange('epigraph', v)}
                         readOnly    = {!permiss.can_user_edit_ordinances()}
            />


            <FInputFloat  name       = "coefficient"
                          label      = "Coeficiente"
                          icon       = {<Icon icon="dollar"/>}
                          value      = {license.coefficient}
                          required   = {false}
                          gt         = {0}
                          decimalSign= {","}
                          onChange   = {(v) => onChange('coefficient', v)}
                          readOnly    = {!permiss.can_user_edit_ordinances()}
            />

            <FInputFloat  name       = "revenues"
                          label      = "Cifra de Negocio"
                          icon       = {<Icon icon="dollar"/>}
                          value      = {license.revenues}
                          decimalSign= {","}
                          onChange   = {(v) => onChange('revenues', v)}
                          readOnly    = {!permiss.can_user_edit_ordinances()}
            />

            <FInputFloat  name       = "province_tax"
                          label      = "Recargo provincial"
                          icon       = {<Icon icon="percent"/>}
                          value      = {license.province_tax}
                          decimalSign= {","}
                          onChange   = {(v) => onChange('province_tax', v)}
                          readOnly    = {!permiss.can_user_edit_ordinances()}
            />   

            <FInputFloat  name       = "location_index"
                          label      = "Índice de situación"
                          icon       = {<Icon icon="number"/>}
                          value      = {license.location_index}
                          decimalSign= {","}
                          onChange   = {(v) => onChange('location_index', v)}
                          readOnly    = {!permiss.can_user_edit_ordinances()}
            />   

            <FInputCheckbox name       = "is_taxpayer"
                          label      = "Es contribuyente"
                          icon       = {<Icon icon="dollar"/>}
                          value      = {license.is_taxpayer}
                          onChange   = {(v) => onChange('is_taxpayer', v)}
                          readOnly    = {!permiss.can_user_edit_ordinances()}
            />            
                          
          </>
  </FForm>

export default AELicenseDetailsForm




