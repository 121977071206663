import React, {useState, useEffect, useCallback} from 'react'


import {Row, Col}    from 'reactstrap'
import {LoadingBig}  from 'cli/ui/components/loading'
import CouncilsList   from './CouncilsList'
import { arrayRemove } from 'farrapa/collections'
import withContext from 'cli/context/withContext'

const CouncilsBase = ({fetcher, historical}) => {
  const [councils, setCouncils]= useState([])
  const [loaded, setLoaded]= useState(0)


  useEffect(() => {
    async function fetchData() {
      const res = await fetcher.get('/api/council/detail', {historical})
      const nCouncils = res.data
      setCouncils(nCouncils)
      setLoaded(1)
    }
    
    fetchData()
  }, [fetcher, historical])  

  
  const localRemove = useCallback((councilId) => {
    const nCouncils= [...councils]
    arrayRemove(nCouncils,
      nCouncils.find((c) => c.id==councilId)
    )
    setCouncils(nCouncils)
  }, [councils])


  const handleRemove = useCallback((councilId) => {
    const _handleRemove = async () => {
      const done = await fetcher.remove('/api/council', councilId)
      if (done) {
        localRemove(councilId)
      }
      return done
    }   
    return _handleRemove()
  }, [fetcher, localRemove])


  const handleAsHistorical= useCallback((councilId) => {
    const _asHistorical = async () => {
      const council= councils.find((c) => c.id==councilId)

      let pids = []
      for (const [_taxCode, processes] of Object.entries(council.processes)) {
        processes.map((p) => pids.push(p.id))
      }
      
      const params= {process_ids: pids, historical: !historical}
      const res= await fetcher.post('/api/process/as_historical', params)
      const done = res.data

      if (done) {
        localRemove(councilId)
      }

      return done
    }
    return _asHistorical()
  } , [fetcher, historical, councils, localRemove])



  return (
      <Row>
        <Col xs="12">
          {
            !loaded
            ? <LoadingBig/>        
            : <CouncilsList councils    = {councils}
                            onRemove    = {(cid) => handleRemove(cid)}
                            isHistorical= {historical}
                            onHistorical= {(cid) => handleAsHistorical(cid)}/>
          }
        </Col>
      </Row>
  )
}




export default withContext(CouncilsBase)








