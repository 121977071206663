import React from 'react'
import ActionButton from './ActionButton'

const IconPenalty = ({color}) =>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024" width="16" height="16">
    <defs>
      <path d="M765.88 12.39L774.76 707.41L90.47 707.41L90.47 12.39L765.88 12.39Z" id="bdGr0Pthk"></path>
      <path d="M466.48 1015.62L459.43 935.83L466.48 875.99L487.61 798.2L498.18 724.4L487.61 536.9L525.18 508.97L600.33 502.99L644.94 522.93L666.08 724.4L788.18 718.41L788.18 390.29L1062.92 614.69L1062.92 692.88L835.15 1101.39L506.4 1101.39L466.48 1015.62Z" id="e9gx89YJd"></path>
    </defs>
    <g>
      <g>
        <g>
          <use xlinkHref="#bdGr0Pthk" opacity="1" fill={color=='red' ? '#f74a4a' : 'green'} fillOpacity="1"></use>
          <g>
            <use xlinkHref="#bdGr0Pthk" opacity="1" fillOpacity="0" stroke="#000000" strokeWidth="3" strokeOpacity="1"></use>
          </g>
        </g>
        <g>
          <use xlinkHref="#e9gx89YJd" opacity="1" fill="#4e4c4b" fillOpacity="1"></use>
          <g>
            <use xlinkHref="#e9gx89YJd" opacity="1" fillOpacity="0" stroke="#000000" strokeWidth="3" strokeOpacity="1"></use>
          </g>
        </g>
      </g>
    </g>
  </svg>

const ActionPenalty = ({ onClick, to, enabled, label, color }) =>
  <ActionButton /*color='red'*/
    label={label}
    icon={<IconPenalty color={color!=undefined ? color : 'red'}></IconPenalty>}
    onClick={(e) => { onClick(e) }}
    to={to}
    enabled={enabled != undefined ? enabled : true}>
  </ActionButton>

export default ActionPenalty
