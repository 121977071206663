import React, {useState, useEffect} from 'react'
import {intre_noon} from 'intre'
import {FForm, FInputSelect, FInputEpoch, FInputText} from 'formiga-reactstrap'
import {Print} from './parts/actions'

const _getInitialFile = (process, file, requiredStr, agents, processStepId, processDocId) => {
  const nFile= {
    process_step: processStepId,
    process_doc: processDocId,
    agent_id: requiredStr.indexOf('Agente')>=0
              ? file.agent_id || process.agent_id || parseInt(Object.keys(agents)[0])
              : undefined,
    doc_date: requiredStr.indexOf('Fecha Pie')>=0
              ? file.doc_date || intre_noon()
              : undefined,
    signer_name: requiredStr.indexOf('Firmante')>=0
            ? file.signer_name || ''
            : undefined,
    signer_pos: requiredStr.indexOf('Firmante')>=0
            ? file.signer_pos || ''
            : undefined,
    signer_nif: requiredStr.indexOf('Firmante')>=0
            ? file.signer_nif || ''
            : undefined,  
  }
  return nFile
}

const _getDocMap = (docList) => {
  const docMap= docList.map((d) => [d.id, d.name])
  let docId= undefined
  try {
    docId= docMap[0][0]
  } catch(_) {}  
  return [docMap, docId]
}


const EventDocForm = ({process, file, required, agents, docList, onPrint, processStepId, processDocId}) => { 
  const [pDocMap, pDocId] = _getDocMap(docList)
  const [docId, setDocId]= useState(pDocId)
  const [docMap, setDocMap]= useState(pDocMap)
  const [requiredStr, _setRequiredStr]= useState(required.join(';'))
  const [iFile, setIFile]= useState(_getInitialFile(process, file, requiredStr, agents, processStepId, processDocId))

  useEffect(() => {
    const [nDocMap, nDocId] = _getDocMap(docList)

    setDocMap(nDocMap)
    setDocId(nDocId)
    
  }, [docList])
  
  useEffect(() => {
    _setRequiredStr(required.join(';'))
  }, [required])

  useEffect(() => {
    setIFile(_getInitialFile(process, file, requiredStr, agents, processStepId, processDocId))
  }, [process, processDocId, processStepId, file, agents, process.agent_id, requiredStr])


  const handleChange = (n, v) => {
    const changes= {[n]: v}
    const nFile= {
      ...iFile,
      ...changes
    }
    setIFile(nFile)
  }

  if (docMap==undefined) {
    return null
  }

  return (
    <FForm  inline
            renderButtons={(valid) => 
              <Print onClick={() => onPrint(iFile, docId)} 
                     disabled={false}/>
            }>
              <>   
                  <FInputSelect name       = "doc_id"
                                options    = {docMap}
                                value      = {docId}
                                onChange   = {(v) => setDocId(v)}
                                icon       = {"Versión doc"}    
                                required   = {true}
                                feedback   = {' '}/>
                
                {requiredStr.indexOf('Agente')>=0
                 ?
                  <FInputSelect name       = "agent_id"
                                options    = {agents}
                                value      = {iFile.agent_id}
                                onChange   = {(v) => handleChange('agent_id', v)}
                                icon       = {"Agente"}    
                                required   = {true}
                                feedback   = {' '}/>
                 : null}
                
                {requiredStr.indexOf('Fecha Pie')>=0
                 ?
                  <FInputEpoch   name       = "doc_date"
                                value      = {iFile.doc_date}
                                onChange   = {(v) => handleChange('doc_date', v)}
                                icon       = {"Fecha pie"}
                                required   = {true}
                                feedback   = {' '}
                                locale     = 'es'/>
                 : null}

                {requiredStr.indexOf('Firmante')>=0
                 ?
                  <>
                    <FInputText   name       = "signer_name"
                                  value      = {iFile.signer_name}
                                  onChange   = {(v) => handleChange('signer_name', v)}
                                  icon       = {"Firmante"}
                                  required   = {true}
                                  feedback   = {' '}/>
                    <FInputText   name       = "signer_nif"
                                  value      = {iFile.signer_nif}
                                  onChange   = {(v) => handleChange('signer_nif', v)}
                                  icon       = {"NIF"}
                                  required   = {true}
                                  feedback   = {' '}/>
                    <FInputText   name       = "signer_pos"
                                  value      = {iFile.signer_pos}
                                  onChange   = {(v) => handleChange('signer_pos', v)}
                                  icon       = {"Puesto"}
                                  required   = {true}
                                  feedback   = {' '}/>
                  </>                                                                    
                 : null}            
              </>
    </FForm>
  )
}

export default EventDocForm
