import React from 'react'
import {TimelineEvent, TimelineEventInfo, TimelineEventRight} from 'cli/ui/components/timeline'
import EventSerialForm from './EventSerialForm'

const EventSerial = ({subStep, onProcessChange, onProcessGetNextSerial, canEdit}) => {
  const handleOnSetSerial = (v) => {
    onProcessChange({[subStep.field_name]: v, minutes_serial_year: subStep.serial_year})
  }

  return (
      <TimelineEvent  key      ={`process_inspect_serial`}
                      legend   = {{icon: "file", 
                                  color: subStep.serial_text==undefined
                                         ? 'gray'
                                         : 'blue'}}
                      date     = {undefined}
                      isLast   = {subStep.done===false}
                      >

        <TimelineEventInfo>
          <span>
              {subStep.serial_text!=undefined
              ? <>
                  Acta <b>{subStep.serial_text}</b> {subStep.serial_year}
                </>
              : <>
                  Pendiente de asignar número de acta
                </>
              }
          </span>          
        </TimelineEventInfo>

          <TimelineEventRight>
            {canEdit
             ?
              <EventSerialForm subStep  = {subStep}
                              onChange = {(v) => handleOnSetSerial(v)}
                              onProcessGetNextSerial = {onProcessGetNextSerial}/>
             : null}
          </TimelineEventRight>

      </TimelineEvent>
  )


}

export default EventSerial