import React from 'react'
import {asPriceWithCurrency} from 'farrapa/numbers'
import getProcessListInfo from 'bl/util/process/getProcessListInfo'
import ProgressBarEmpty from 'cli/ui/fragments/process/fields/progress/bar/ProgressBarEmpty'
import ProgressBar from 'cli/ui/fragments/process/fields/progress/bar/ProgressBar'


const ProcessListProgressDetail = ({processes, what}) => {
  if (processes.length==0) {
    return (
      <ProgressBarEmpty/>
    )
  }
  
  const i= getProcessListInfo(processes, what)

  return (
    <ProgressBar left = {i.status}
                 leftColor= {i.statusColor}
                 center = {''}
                 right = {i.amount==0 ? '' : asPriceWithCurrency(i.amount)}
                 rightColor = {i.amountColor}
                 progress = {i.progress}
                 color = {i.color}
                 />
)
}

export default ProcessListProgressDetail