import React from 'react'
import Hyper from './base'
import {TAX_NAMES} from 'bl/ns/taxes'

const HyperTax = ({ code }) =>
  <Hyper bcolor  = "#f2d9d9"
         icon    = {code}
         to      = {"/taxes/" + code}
         text    = {TAX_NAMES[code]}
         permissionCode = "process_read">
  </Hyper>

export default HyperTax
