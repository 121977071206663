import React from 'react'
import { ActionDetails, ActionRemove } from 'cli/ui/components/buttons/actions'
import NiceList from 'cli/ui/components/nice_list'
import { HyperAgent } from 'cli/ui/components/hyper'

const agentFields= [
  /* eslint react/display-name:0 */
  {
    label: "Agente",
    className: "main", 
    value: (agent) => agent.name,
    render: (agent) =>
      <HyperAgent key={`agent_list_row_${agent.id}_id`} id={agent.id} name={agent.name}/>
  }, 
  {
    label: "Usuario", 
    value: (agent) => agent.user_username!=undefined ? `${agent.user_username} ${agent.user_name ? '('+agent.user_name+')' : '' }` : ''
  },
  {
    label: "Email", 
    value: (agent) => agent.email
  },
  {
    label: "Teléfono", 
    value: (agent) => agent.phone
  },
  {
    label: "Color",
    render: (agent) => <span style={{color: agent.color}}>&#9864;</span>
  },
  {
    label: "Tiradas",
    value: (agent) => agent.edition_count
  },
  {
    label: "Acciones", 
    className: "right", 
    render: (agent, onEvent) => 
      <>
        <ActionDetails to={"/settings/agents/" + agent.id} />
        <ActionRemove onClick={() => onEvent('remove', {id: agent.id})}
                      enabled={agent.edition_count==0}/>
      </>
  }
]

const makeKey= (agent) =>
  `agent_list_row_${agent.id}`


const AgentsList = ({ agents, onRemove }) =>
  <NiceList fields     = {agentFields}
            initialSort= {[0, "asc"]}
            data       = {agents}
            makeKey    = {makeKey}
            onEvent    = {(ev, params) => {
              if (ev=='remove') {
                onRemove(params.id)
              }
            }}
            transparent
  />

export default AgentsList