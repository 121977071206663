import React, {useState, useEffect, useCallback} from 'react'

import { useStoragedState } from 'cli/ui/hooks/useStoragedState'

import { IBox } from 'cli/ui/components/ibox'
import { LoadingBig } from 'cli/ui/components/loading'
import Icon from 'cli/ui/components/icon'
import ProcessesFilterForm from './ProcessesFilterForm'
import ProcessesFilterResume from './ProcessesFilterResume'
import { useLocation } from 'react-router-dom'
import withContext from 'cli/context/withContext'

const EMPTY_FILTER= {
  'council_id' : '',  
  'tax_code' : '',  
  'taxpayer_name' : '',  
  'taxpayer_nif' : '',  
  'status_step' : [], 
  'penalty_19_step' : '', 
  'penalty_20_step' : '', 
  'process_name' : '',  
  'penalty_type' : '',
  'taxes_sum_from' : '',  
  'taxes_sum_to' : '',  
  'invoicable' : '',  
  'start_date_from' : '',  
  'start_date_to' : '',  
  'end_date_from' : '',  
  'end_date_to': ''
}

const FILTER_FIELDS = Object.keys(EMPTY_FILTER)


const ProcessesFilter = ({fetcher, filterMode, onToggleMode, onFilter, hiddenFields, defaultFilters}) => { 
  const location= useLocation()
  const [filterData, setFilterData]= useState(undefined)

  const [urlFilter, setUrlFilter]= useState(false)

  const [innerMode, setInnerMode]= useState(filterMode)
  const [filteredOnce, setFilteredOnce]= useState(false)

  const [filter, setFilter]= useStoragedState(EMPTY_FILTER, 'process_search')

  useEffect(() => {
    setInnerMode(filterMode)
  }, [filterMode])

  useEffect(() => {
    if (defaultFilters!=undefined) {
      setFilter({
        ...filter,
        ...defaultFilters
      })
    }
  }, [defaultFilters /*, filter, setFilter*/])


  const handleOnFilter = useCallback((nFilter) => {
    let notEmpty= {}
      
    Object.entries(nFilter)
        .filter(([_,v]) => v!='' && v!=undefined)
        .map(([k,v]) => notEmpty[k]= v)
    
    try {
      notEmpty.status_step= notEmpty.status_step.join(',')
    } catch(e) {
      delete notEmpty.status_step
    }

    if (Object.keys(notEmpty).length>0) {
      onFilter(notEmpty)
      setFilteredOnce(true)
    }
  }, [onFilter])

  // Init filter. 
  // Check URL query. If some param, just launch the filter
  useEffect(() => {
    if (urlFilter==false) {
      setUrlFilter(true)

      const urlParams = new URLSearchParams(location.search)

      let urlFilter= {}

      FILTER_FIELDS.map((field) =>  {
        const value= urlParams.get(field) || undefined  
        if (value!=undefined) {
          urlFilter[field]= value
        }
      })

      const hasSomeFilter= Object.keys(urlFilter).length>0
      
      if (hasSomeFilter) {
        setFilter(urlFilter)
        handleOnFilter(urlFilter)
      }
    }
  }, [location, urlFilter, setFilter, handleOnFilter])

  // Callback for loading filter data
  const fetchFilterData = useCallback(() => {
    const _fetchFilterData = async () => {
      const councils= await fetcher.key_list('/api/council')
      setFilterData({
        councils
      })
    }
    _fetchFilterData()
  }, [fetcher]) 

  // Load filter data if filter is shown and just once
  useEffect(() => {
    if (innerMode=='filter' && filterData==undefined) {
      fetchFilterData()
    }
  }, [innerMode, filterData, fetchFilterData])  
 

  const handleChange = (n, v) => {
    const nFilter= {...filter}
    nFilter[n]= v
    setFilter(nFilter)
  }

  const handleSave = (valid, _readElements) => {
    if (valid) {
      handleOnFilter(filter)
    }
  }

  const handleToggleFilter = (newMode) => {
    setInnerMode(newMode)
    onToggleMode(newMode)
  }


  if (innerMode=='hidden') {
    return null
  }

  return (
    <IBox narrow={innerMode=='resume'}
          watermark = {{text: <Icon size={innerMode=='resume' ? 18 : 42} icon="search" color={"#123456"}/> }}
          >
      {filterData==undefined 
       ? <LoadingBig/>
       : innerMode=='resume'
         ?  <ProcessesFilterResume 
              filter      = {filter}
              filterData  = {filterData}
              hiddenFields= {hiddenFields || []}
              onToggleFilter={() => handleToggleFilter('filter')}/>
         : <ProcessesFilterForm 
              filter      = {filter}
              filterData  = {filterData}
              onChange    = {handleChange}
              onCancel    = {filteredOnce 
                             ? () => handleToggleFilter('resume')
                             : undefined} 
              onSave      = {handleSave}
              hiddenFields= {hiddenFields || []}/>
       }
    </IBox>

  )

}

export default withContext(ProcessesFilter)




