import React from 'react'
import Button from './Button'

const ButtonStepsStart = ({ onNext, disabled }) =>
  <Button icon    = {'forward'}
          color   = {'primary'}
          onClick = {onNext}
          disabled= {disabled!=undefined ? disabled : false}>
    {'Siguiente'}
  </Button>

export default ButtonStepsStart
