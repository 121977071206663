import React, {useState, useEffect} from 'react'

import {FForm, FInputText, FInputUrl, FInputSelectSearch, FInputUInt} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'

import { SPANISH_PROVINCES } from 'bl/ns/geo'
import { LoadingBig } from 'cli/ui/components/loading'
import withContext from 'cli/context/withContext'


const CouncilDetailsDataForm = ({ fetcher, council, onChange, onSave}) => {
  const [status, setStatus]= useState(0)
  const [nameList, setNameList]= useState([])
  const [nameOrig, setNameOrig]= useState(council.name)

  
  useEffect(() => {
    async function fetchData() {
      const nNameList = await fetcher.name_list('/api/council')

      setNameList(nNameList)
      setNameOrig(council.name)
      setStatus(1)
    }

    fetchData()

  }, [fetcher, council.name])

  const existingNames= nameList.filter((n) => n!=nameOrig)

  if (status==0) {
    return (
      <LoadingBig/>
    )
  }

  return (
    <FForm onCancel     = {undefined}
          onSave       = {onSave}>
            <>
              <FInputText name        = "name"
                          label       = "Nombre"
                          icon        = {<Icon icon={"council"}/>}
                          value       = {council.name}
                          required    = {true}
                          checkValue  = {(v) => existingNames.map((n) => n.toLowerCase()).indexOf(v.toLowerCase())==-1}
                          feedback    = {'Inserta un nombre válido y único'}
                          onChange    = {(v) => onChange('name', v)}
              />
              <FInputSelectSearch  
                          options     = {SPANISH_PROVINCES}
                          name        = 'provincia'
                          label       = 'Provincia'
                          icon        = {<Icon icon={"map-signs"}/>}
                          value       = {council.province}
                          onChange    = {(v) => onChange('province', v)}
                          feedback    = {"Seleciona una Provincia"}
                          clearable   = {true}
                          searchable  = {true}
                          required    = {true}
              />
              <FInputText name        = "address"
                          label       = "Dirección"
                          icon        = {<Icon icon={"location"}/>}
                          value       = {council.address}
                          onChange    = {(v) => onChange('address', v)}
              />
              <FInputText name        = "nif"
                          label       = "NIF"
                          icon        = {<Icon icon={"profile"}/>}
                          value       = {council.nif}
                          onChange    = {(v) => onChange('nif', v)}
              />            
              <FInputUrl  name        = "deh"
                          label       = "Dirección Electrónica Habilitada"
                          value       = {council.deh}
                          icon        = {<Icon icon={"url"}/>}
                          feedback    = {'Inserta un email o una dirección web'}
                          onChange    = {(v) => onChange('deh', v)}
                          pattern     = {"^(https?://)?([a-zA-Z0-9]([a-zA-ZäöüÄÖÜ0-9-]{0,61}[a-zA-Z0-9])?.)+[a-zA-Z/]{2,6}$"}
              />


              <FInputText name        = "iban"
                          label       = "Cuenta Bancaria"
                          icon        = {<Icon icon={"dollar"}/>}
                          value       = {council.iban}
                          onChange    = {(v) => onChange('iban', v)}
              /> 
              <FInputUInt name      = "inhabitants"
                          label       = "Habitantes"
                          icon        = {<Icon icon={"users"}/>}
                          value       = {council.inhabitants}
                          onChange    = {(v) => onChange('inhabitants', v)}
              />                                                      
            </>
    </FForm>
  )
}

export default withContext(CouncilDetailsDataForm)




