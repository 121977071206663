import React from 'react'
import { EditingIBox, IBoxRow, IBoxIconedText } from 'cli/ui/components/ibox'
import CouncilDetailsNotesForm from './CouncilDetailsNotesForm'

const CouncilDetailsNotes = ({council, onChange, onSave}) => {
  const empty= ! council.remarks
  return (
    <>
      <EditingIBox title="Observaciones"
                   empty={empty}
                   fitHeight
                   permissionCode="council_edit"
                   resume={() => 
                      <IBoxRow center >
                        {empty
                        ? "Sin observaciones"
                        : <>
                            <IBoxIconedText icon="bubble"
                                            text={council.remarks}/>
                          </>
                        }
                      </IBoxRow>
                   }
                   form={(gotoResume) => 
                      <CouncilDetailsNotesForm council={council}  
                                               onChange={onChange}
                                               onSave ={() => {onSave(); gotoResume()}}/>
                  }>
      </EditingIBox>
    </>
  )
}

export default CouncilDetailsNotes


/*
import React, { useState } from 'react'
import { IBox, IBoxRow, IBoxIconedText } from 'cli/ui/components/ibox'
import { ActionEdit, ActionBack } from 'cli/ui/components/buttons/actions'
import CouncilDetailsNotesForm from './CouncilDetailsNotesForm'

const CouncilDetailsNotes = ({council, onChange, onSave}) => {
  const [editMode, setEditMode]= useState(false)
  return (
    <>
      <IBox narrow
            title="Observaciones"
            actions={
                editMode
                ? <ActionBack label={undefined} onClick={() => setEditMode(false)}/>
                : <ActionEdit label={undefined} onClick={() => setEditMode(true)}/>
            }>
        {editMode==false
         ? 
            <IBoxRow>
              {council.remarks
               ? <IBoxIconedText icon="bubble"
                                 text={council.remarks}/>
               : <i style={{color: "lightgray"}}>Sin observaciones</i>}
            </IBoxRow>
         : <CouncilDetailsNotesForm council={council}
                                   onChange={onChange}
                                   onSave ={() => {onSave(); setEditMode(false)}}/>}
      </IBox>
    </>
  )
}


export default CouncilDetailsNotes
*/