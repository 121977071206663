import {makeI5TaxesTable} from './i5/taxes'
import {makeI5InterestsTable} from './i5/interests'
import {make19Table} from './p19/p19'
import {makeIAEDeclaredTable} from './iae/declared'
import {makeIAECheckedTable} from './iae/checked'
import {makeIAEInterestsTable} from './iae/interests'
import {makeIAETotalsTable} from './iae/totals'


function makeTaxesTable(data) {
  if (data.process.tax_code=='i5') {
    return makeI5TaxesTable(data.tax)
  }
  if (data.process.tax_code=='ae') {
    const margin= '<w:p> </w:p>'
    const declared= makeIAEDeclaredTable(data.tax)
    const checked= makeIAECheckedTable(data.tax)
    const totals= makeIAETotalsTable(data.tax)
    return [margin, declared, margin, checked, margin, totals, margin].join('\n')
  }

  return ''

}


function makeInterestsTable(data) {

  if (data.process.tax_code=='i5') {
    return makeI5InterestsTable(data.tax)
  }
  if (data.process.tax_code=='ae') {
    return makeIAEInterestsTable(data.tax)
  }
  return ''

}

export {makeTaxesTable, makeInterestsTable, make19Table}