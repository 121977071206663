import React from 'react'
import { Route } from 'react-router-dom'

import TaxPayers          from 'cli/ui/pages/taxpayers/TaxPayers'
import TaxPayerNew        from 'cli/ui/pages/taxpayers/det/TaxPayerNew'
import TaxPayerDetails    from 'cli/ui/pages/taxpayers/det/TaxPayerDetails'

const TaxPayerRoutes = (permiss) => {
  if (!permiss.can_user_read_taxpayers()) {
    return []
  }

  return [
    <Route key="tpayers_00" path={'/taxpayers'} element={<TaxPayers/>}/>,

    ...permiss.can_user_edit_taxpayers()
    ? [<Route key="tpayers_01" path={'/taxpayers/new'} element={<TaxPayerNew/>}/>]
    : [],

    <Route key="tpayers_02" path={'/taxpayers/det/:idTaxPayer'} element={<TaxPayerDetails/>}/>
  ]
}

export default TaxPayerRoutes