import React from 'react'
import Icon from 'cli/ui/components/icon'

const ActionEmail = ({ email, enabled }) =>
  <a className = {`afi-action-button ${enabled === false ? 'disabled' : ''}`}
     style     = {{color: 'gray', textDecoration: 'none'}}
     href      = {`mailto:${email}`}>
    <Icon icon = {'email'} 
          color= {'gray'}/>
  </a>



export default ActionEmail
