import React from 'react'
import {FForm, FInputText, FInputColor} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'

const SectorDetailsForm = ({ sector, existingNames, onChange, onSave, onCancel}) =>

  <FForm  onCancel     = {sector.id==undefined ? onCancel : undefined}
          onSave       = {onSave}>
          <>
            <FInputText name        = "name"
                        label       = "Nombre"
                        icon        = {<Icon icon={"sector"}/>}
                        value       = {sector.name}
                        required    = {true}
                        checkValue  = {(v) => existingNames.map((n) => n.toLowerCase()).indexOf(v.toLowerCase())==-1}
                        feedback    = {'Inserta un nombre válido y único'}
                        onChange    = {(v) => onChange('name', v)}
            />

            <FInputText name        = "code"
                        label       = "Código"
                        icon        = {<Icon icon={"sector"}/>}
                        value       = {sector.code}
                        required    = {true}
                        feedback    = {'Inserta un código para los docs'}
                        onChange    = {(v) => onChange('code', v)}
                        readOnly    = {true}
            />

            <FInputText name        = "activity"
                        label       = "Descripción actividad"
                        icon        = {<Icon icon={"text"}/>}
                        value       = {sector.activity}
                        required    = {true}
                        feedback    = {'Inserta una descripción de actividad para los docs'}
                        onChange    = {(v) => onChange('activity', v)}
            />
            <FInputColor name        = "color"
                         label       = "Color"
                         value       = {sector.color}
                         onChange    = {(v) => onChange('color', v)}
            /> 
            </>
  </FForm>


export default SectorDetailsForm




