import {uvl} from 'farrapa/commons'
import {memoize} from 'farrapa/memoize'
import {intre_get_year} from 'intre'
import {makeSubStepDoc, makeSubStepDocuProvide, makeSubStepAppointment,
        makeSubStepArguments, makeSubStepArgumentsDate, makeSubStepAgree, makeSubStepSerial, makeSubStepAppeal,
        makeSubStepEstimated, makeSubStepJudicial, makeStep, makeColor} from './util'
import {PTYPE} from 'bl/ns/process/process'
import {FLOW_STEPS, DOC, SUB_STEP_DOC} from 'bl/ns/process/steps'

class Inspect {
  constructor(process) {
    this.p= process
  }

  @memoize
  get open () {
    const subDoc= makeSubStepDoc(this.p.files, FLOW_STEPS.OPEN, DOC.OPEN, false)
    
    return makeStep(FLOW_STEPS.OPEN, [
      subDoc
    ])
  }

  @memoize
  get start () {
    const extraDocs= this.p.process_type==PTYPE.COMPLETE
                ? [DOC.RIGHTS]
                : []
    const subDoc= makeSubStepDoc(this.p.files, FLOW_STEPS.START, DOC.START, true, extraDocs)
    
    return makeStep(FLOW_STEPS.START, [
      subDoc
    ])
  }

  @memoize
  get first_dlg () {
    let subSteps= []
    const subProv= makeSubStepDocuProvide(1, this.p.first_dlg_satisfied, this.p.docus, 'first_dlg_satisfied')
    subSteps.push(subProv)
    if (subProv.done) {
      const doc= this.p.first_dlg_satisfied>0
                 ? DOC.PROVIDE_DLG
                 : DOC.NOT_PROVIDE_DLG

      const subDoc = makeSubStepDoc(this.p.files, FLOW_STEPS.FIRST_DLG, doc, false)
      subSteps.push(subDoc)
    }
    

    return makeStep(FLOW_STEPS.FIRST_DLG, subSteps)
  }

  @memoize
  get second_req () {
    const subDoc= makeSubStepDoc(this.p.files, FLOW_STEPS.SECOND_REQ, DOC.SECOND_REQ, true)
    return makeStep(FLOW_STEPS.SECOND_REQ, [
        subDoc
    ])
  }

  @memoize
  get second_dlg () {
    let subSteps= []
    const subProv= makeSubStepDocuProvide(2, this.p.second_dlg_satisfied, this.p.docus, 'second_dlg_satisfied')
    subSteps.push(subProv)
    if (subProv.done) {
      const doc= this.p.second_dlg_satisfied>0
                 ? DOC.PROVIDE_DLG
                 : DOC.NOT_PROVIDE_DLG

      const subDoc = makeSubStepDoc(this.p.files, FLOW_STEPS.SECOND_DLG, doc, false)
      subSteps.push(subDoc)
    }
    

    return makeStep(FLOW_STEPS.SECOND_DLG, subSteps)    
  }

  @memoize
  get third_req () {
    const subDoc= makeSubStepDoc(this.p.files, FLOW_STEPS.THIRD_REQ, DOC.THIRD_REQ, true)
    return makeStep(FLOW_STEPS.THIRD_REQ, [
        subDoc
    ])
  }

  @memoize
  get third_dlg () {
    let subSteps= []
    const subProv= makeSubStepDocuProvide(3, this.p.third_dlg_satisfied, this.p.docus, 'third_dlg_satisfied')
    subSteps.push(subProv)
    if (subProv.done) {
      const doc= this.p.third_dlg_satisfied>0
                 ? DOC.PROVIDE_DLG
                 : DOC.NOT_PROVIDE_DLG

      const subDoc = makeSubStepDoc(this.p.files, FLOW_STEPS.THIRD_DLG, doc, false)
      subSteps.push(subDoc)
    }
    

    return makeStep(FLOW_STEPS.THIRD_DLG, subSteps)   
  }

  @memoize
  get real_unatt_requirement() {
    return this.p.first_dlg_satisfied===1
           ? 0
           : this.p.first_dlg_satisfied===2 || this.p.second_dlg_satisfied===1
             ? 1
             : this.p.second_dlg_satisfied===2 || this.p.third_dlg_satisfied===1
              ? 2
              : 3
  }

  @memoize
  get unatt_requirement() {
    try {
      
      if (this.p.force_unatt_requirement!=undefined) {
        const forced= parseInt(this.p.force_unatt_requirement)
        if (forced>=1 && forced<=3) {
          return forced
        }
      }      
    } catch (_) {}
    
    return this.p.first_dlg_satisfied===1
           ? 0
           : this.p.first_dlg_satisfied===2 || this.p.second_dlg_satisfied===1
             ? 1
             : this.p.second_dlg_satisfied===2 || this.p.third_dlg_satisfied===1
              ? 2
              : 3
  }

  @memoize
  get liq_proposal () {
    const subSteps= []
    let completed= false
    // Appointment substep
    const needsAppointmentTime= this.p.tax_code=='lu'
    const appointmentTime= this.p.tax_data!=undefined ? this.p.tax_data.appointment_time : undefined
    const subApp= makeSubStepAppointment(this.p.appointment_date, needsAppointmentTime, appointmentTime)
    subSteps.push(subApp)
    if (subApp.done) {
      // Docs substep
      const subDoc= makeSubStepDoc(this.p.files, FLOW_STEPS.LIQ_PROPOSAL, DOC.LIQ_PROPOSAL, true)
      if (subDoc.print_status!='pending') {
        completed= true
      }
      subSteps.push(subDoc)
      if (subDoc.done) {
        // Arguments substep
        const subArg= makeSubStepArguments(this.p.liq_proposal_arguments, 'liq_proposal_arguments')
        subSteps.push(subArg)
      }
    }

    return makeStep(FLOW_STEPS.LIQ_PROPOSAL,
      subSteps, {
        completed: completed
      }
    )
  }

  @memoize
  get serial_prefix () {
    return this.p.minutes_agreed 
        ? 'A01' 
        : 'A02'
  } 


  @memoize
  get serial_text () {

    return this.p.minutes_agreed==undefined
      ? undefined
      : `${this.serial_prefix}-${uvl(this.p.minutes_serial,-1)!=-1 ? this.p.minutes_serial : 'XX'}`
  }  

  @memoize
  get minutes () {
    let subSteps= []
    // Agreed substep
    const subAgr= makeSubStepAgree(this.p.minutes_agreed, 'minutes_agreed', true, this.p.minutes_agreed_date, 'minutes_agreed_date')
    subSteps.push(subAgr)
    if (subAgr.done) {
      // Serial substep
      const subSer= makeSubStepSerial(this.p.minutes_agreed, this.p.minutes_serial, 
             this.serial_prefix, this.serial_text, this.p.minutes_serial_year || intre_get_year(), 'minutes_serial')
      subSteps.push(subSer)
      if (subSer.done) {
        // Docs substep
        const doc= this.p.minutes_agreed===true
                  ? DOC.MINUTES_AGREE
                  : this.p.minutes_agreed===false
                    ? DOC.MINUTES_DISAGREE
                    : undefined
        const needsNotif = this.p.minutes_agreed===false
        const extraDocs= this.p.minutes_agreed===false
                        ? [DOC.MINUTES_DISAGREE_AMP]
                        : undefined                 
        const subDoc= makeSubStepDoc(this.p.files, FLOW_STEPS.MINUTES, doc, needsNotif, extraDocs)
        subSteps.push(subDoc)
        if (subDoc.done) {
          // Arguments substep
          const subArg= makeSubStepArguments(this.p.minutes_arguments, 'minutes_arguments')
          subSteps.push(subArg)

          if (subArg.done) {
            // If Arguments YES
            if (this.p.minutes_arguments==true) {
              // Arguments date
              const subArgD= makeSubStepArgumentsDate(this.p.minutes_arguments_date, 'minutes_arguments_date')
              subSteps.push(subArgD)
            }
          }
        }
      }
    }

    return makeStep(FLOW_STEPS.MINUTES, subSteps)
  }

  @memoize
  get liq_approval () {
    const subDoc= makeSubStepDoc(this.p.files, FLOW_STEPS.LIQ_APPROVAL, DOC.LIQ_APPROVAL_DISAGREE, true)

    return makeStep(FLOW_STEPS.LIQ_APPROVAL, [
      subDoc
    ])
  }


  @memoize
  get notify () {
    const subDoc= makeSubStepDoc(this.p.files, FLOW_STEPS.NOTIFY, DOC.NOTIFY, false)
    return makeStep(FLOW_STEPS.NOTIFY, [
      subDoc
    ])
  }


  @memoize
  get payment () {
    const subDoc= makeSubStepDoc(this.p.files, FLOW_STEPS.PAYMENT, DOC.PAYMENT)
    return makeStep(FLOW_STEPS.PAYMENT, [
      subDoc
    ])
  }  

  @memoize
  get appeal() {
    let subSteps= []
    // Appeal substep
    const supApp= makeSubStepAppeal(this.p.informal_appeal, 'informal_appeal')
    subSteps.push(supApp)
    if (supApp.done && this.p.informal_appeal===true) {
      // Estimate substep
      const subEst= makeSubStepEstimated(this.p.estimated, 'estimated')
      subSteps.push(subEst)
      if (subEst.done && this.p.estimated===false) {
        const subJud= makeSubStepJudicial(this.p.judicial, 'judicial')
        subSteps.push(subJud)        
      }
    }

    return makeStep(FLOW_STEPS.APPEAL,
      subSteps
    )    
  }

  @memoize
  get steps() {
    let steps= []
    
    steps.push(this.open)


    if (this.open.done) {
      steps.push(this.start)
      if (this.start.done) {
        steps.push(this.first_dlg) 
        
        // Requeriments cycle
        let reqCycleDone= false
        if (this.first_dlg.done) {
          if (this.p.first_dlg_satisfied===0) {
            steps.push(this.second_req)
            if (this.second_req.done) {
              steps.push(this.second_dlg)
              if (this.second_dlg.done) {
                if (this.p.second_dlg_satisfied===0) {
                  steps.push(this.third_req)
                  if (this.third_req.done) {
                    steps.push(this.third_dlg)
                    if (this.third_dlg.done) {
                      reqCycleDone= true    
                    }
                  }
                } else {
                  reqCycleDone= true
                }
              }
            }
          } else {
            reqCycleDone= true
          }
        }


        if (reqCycleDone) {
          // For type inspect
          if (this.p.process_type == PTYPE.COMPLETE) {
            steps.push(this.liq_proposal)
            if (this.liq_proposal.done) {
              steps.push(this.minutes)
              if (this.minutes.done) {
                if (this.p.minutes_agreed==false) {
                  steps.push(this.liq_approval)
                  if (this.liq_approval.done) {
                    steps.push(this.payment)
                    if (this.payment.done) {
                      steps.push(this.appeal)
                    }
                  }
                } else {
                  steps.push(this.payment)
                }
              }
            }
          // For Type Checking
          } else {
            steps.push(this.notify)
            if (this.notify.done) {
              steps.push(this.payment)
            }
          }
        }
      }
    }

    return steps
  }

  @memoize
  get status_step() {
    let st_step= undefined, st_step_idx= undefined
    for (let i= this.steps.length-1; i>=0; i--) {
      if (st_step==undefined) {
        if (this.steps[i].completed) {
          st_step= this.steps[i]
          st_step_idx = i
        }
      }
    }
    if (st_step==undefined) {
      return this.steps[0]
    }

    // SI Prop. Liq. pero ya con numero de acta, entonces el paso
    // es el acta aunque no se haya terminado
    if (st_step.id == FLOW_STEPS.LIQ_PROPOSAL) {
      if (this.p.minutes_agreed!==undefined) {
        if (this.p.minutes_serial) {
          const min_step= this.steps[st_step_idx+1]
          if (min_step!=undefined) {
            return min_step
          }
        }
      }
    }
    return st_step
  }

  @memoize
  get current_step() {
    return this.steps.slice(-1)[0]
  }

  @memoize
  get current_step_doc() {
    for (let i=0; i<this.current_step.sub.length; i++) {
      if (this.current_step.sub[i].type==SUB_STEP_DOC) {
        return this.current_step.sub[i]
      }
    }    
    return undefined
  }

  @memoize
  get progress() {
    if (this.is_done) {
      return 100
    }

    const totSteps= this.p.process_type == PTYPE.REDUCED 
                    ? 6 
                    : this.p.minutes_agreed==false 
                      ? 17
                      : 13
    
    let doneSteps= 0
    for (let i=0; i<this.steps.length;i++) {
      for (let j=0; j<this.steps[i].sub.length;j++) {
        if (this.steps[i].sub[j].done) {
          doneSteps+= 1
        }
      }
    }

    const progress = Math.round(doneSteps*100 / totSteps,0)
    return progress
  }


  @memoize
  get color() {
    let color= 'green'

    if (this.p.is_closed) {
      color= 'black'
    } else {      
      if (this.p.minutes_agreed===false) {
        color= 'pink'
      } else {
        const someDlgDone = 
          this.first_dlg.done === true || 
          this.second_dlg.done=== true || 
          this.third_dlg.done === true        
        if (! someDlgDone) {
          color= 'turqoise'
        }
      }
    }

    return makeColor(color, this.progress)
  }

  @memoize
  get is_done() {
    if (this.p.is_closed) {
      return true
    } 
    if (this.p.process_type ==PTYPE.REDUCED || this.p.minutes_agreed===true) {
      return this.status_step.id===FLOW_STEPS.PAYMENT
    }
    if (this.status_step?.id==FLOW_STEPS.APPEAL) {
      const a= this.appeal.sub
      if (a==undefined || a.length==0) {
        return false
      }
      if (a.length==1 && a[0].appealed===false) {
        return true
      }
      if (a.length==2 && a[1].estimated===true) {
        return true
      }
      /* 17/06/2022
         y luego otra cosilla los exptes del programa que aparecen sin recurso de reposicion 
         aparecen como cerrados pero los que son con recurso de reposicion y luego cualquiera 
         de las opciones queremos tambien que se cierren finalmente  
         Nota DEV: "luego cualquiera de las opciones" vamos a aplicarlo por ahora a la ultima pregunta    
      */

      /*if (a.length==3 && a[1].judicial===false) {
        return true
      }*/
      if (a.length==3) {
        return true
      }      
    }
    
    return false
  }


  export() {
    return {
      steps           : this.steps,
      dlg_satisfied   : this.dlg_satisfied,
      unatt_requirement: this.unatt_requirement,
      status_step     : this.status_step,
      current_step    : this.current_step,
      is_done         : this.is_done
    }
  }
}



export default Inspect