import React from 'react'
import ActionButton from './ActionButton'

const ActionCalendar = ({ onClick, enabled, label }) =>
  <ActionButton color  = {'gray'}
                label = {label}
                icon   = {'calendar'}
                onClick= {(e) => { onClick(e) }}
                enabled= {enabled != undefined ? enabled : true}>
  </ActionButton>

export default ActionCalendar
