import { hslToHex } from 'farrapa/colors'

const makeColor = (color, progress) => {
  if (color=='black') {
    return '#000000'
  }
  /*
  Quick ref:
      0 – red
      60 – yellow
      120 – green
      180 – turquoise
      240 – blue
      300 – pink
      360 – red
  */          
  //value from 0 to 100
  const b60= (progress*60)/100
  const hue= color=='green'
            ? b60 + 60
            : color=='red'
              ? b60 + 300
              : color=='pink'
              ? b60 + 240
              : b60 + 180
  return hslToHex(hue, 100, 50)
}


export default makeColor