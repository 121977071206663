import {uvl} from 'farrapa/commons'
import {intre_diff} from 'intre'

class ProcessVTTaxCalc  {

  id             = undefined
  process_id     = undefined
  cadastral_price= undefined
  buy_price      = undefined
  buy_date       = undefined
  sell_price     = undefined
  sell_date      = undefined

  constructor(obj, process) {
    this.process= process
    this.update(obj)
  }

  update(obj) {
    if (typeof obj == 'object') {
      Object.keys(this)
            .filter((k) => Object.prototype.hasOwnProperty.call(obj, k))
            .map((k) => {
              this[k]= obj[k]
            })
    }

    if (this.process.id!=undefined)
      this.process_id = this.process.id
  }

  get incr_coefficient() {
    try {
      return this.process.ordinance_tax_data.incr_coefficient
    } catch(e) {
      return 0.0
    }
  }

  get tax_rate() {
    try {
      return this.process.ordinance_tax_data.tax_rate
    } catch(e) {
      return 0.0
    }
  }

  get discount_rate() {
    try {
      return this.process.ordinance_tax_data.discount_rate
    } catch(e) {
      return 0.0
    }
  }  


  get ready_for_calcs() {
    const miss_coeff= this.incr_coefficient<=0
    const miss_tax_rate= this.tax_rate<=0
    return {
      ok: !miss_coeff && !miss_coeff,
      missing: {
        incr_coefficient: miss_coeff,
        tax_rate: miss_tax_rate
      }
    }
  }


  get ready() {
    return this.ready_for_calcs.ok 
  }

  get years() {
    try {
      if (this.buy_date!=undefined && this.sell_date!=undefined) {
        return intre_diff(this.sell_date, this.buy_date, 'years')
      }
    } catch(e) {}
    return 0
  }

  get increment() {
    return uvl(this.incr_coefficient,0) * this.years
  }

  get base_amount() {
    return uvl(this.cadastral_price,0) * this.increment/100
  }

  get discounted_amount() {
    return this.base_amount * uvl(this.discount_rate,0)/100
  }

  get base_discounted() {
    return this.base_amount - this.discounted_amount
  }

  get total() {
    return this.base_discounted * uvl(this.tax_rate,0)/100
  }

  forDocx() {
    return {

    }
  }  
  
  toJson() {
    return {
      id             : this.id             ,
      process_id     : this.process_id     ,
      cadastral_price: this.cadastral_price,
      buy_price      : this.buy_price      ,
      buy_date       : this.buy_date       ,
      sell_price     : this.sell_price     ,
      sell_date      : this.sell_date      ,
    }
  }  

  export() {
    return {
      ...this.toJson(),
      total: this.total,
    }
  }
}


export default ProcessVTTaxCalc


