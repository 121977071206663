import {toPrice, sort} from '../../converters'
import {PENALTY_INFRACTION_LEVEL } from 'bl/ns/process/penalty'
import {uvl} from 'farrapa/commons'

function make19TableRow(detail) {
  return `
    <w:tr w:rsidR="000E4341" w:rsidRPr="00FB24C2" w14:paraId="5291898D" w14:textId="77777777" w:rsidTr="000E4341">
    <w:trPr>
      <w:trHeight w:val="237" />
      <w:jc w:val="center" />
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1472" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:left w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="auto" w:fill="auto" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
      </w:tcPr>
      <w:p w14:paraId="2D8B91FF" w14:textId="77777777" w:rsidR="000E4341" w:rsidRPr="00FB24C2"
        w:rsidRDefault="000E4341" w:rsidP="000E4341">
        <w:pPr>
          <w:suppressAutoHyphens w:val="0" />
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:b />
            <w:bCs />
            <w:szCs w:val="20" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00FB24C2">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:b />
            <w:bCs />
            <w:szCs w:val="20" />
          </w:rPr>
          <w:t>${detail.year}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1482" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:left w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="auto" w:fill="auto" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
      </w:tcPr>
      <w:p w14:paraId="5E6C9DA1" w14:textId="77777777" w:rsidR="000E4341" w:rsidRPr="00FB24C2"
        w:rsidRDefault="000E4341" w:rsidP="000E4341">
        <w:pPr>
          <w:suppressAutoHyphens w:val="0" />
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:color w:val="000000" />
            <w:szCs w:val="20" />
          </w:rPr>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:color w:val="000000" />
            <w:szCs w:val="20" />
          </w:rPr>
          <w:t>${ toPrice(detail.base) }</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1776" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:left w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="auto" w:fill="auto" />
        <w:noWrap />
        <w:vAlign w:val="center" />
      </w:tcPr>
      <w:p w14:paraId="07E819F6" w14:textId="77777777" w:rsidR="000E4341" w:rsidRPr="00FB24C2"
        w:rsidRDefault="000E4341" w:rsidP="000E4341">
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:szCs w:val="20" />
          </w:rPr>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:szCs w:val="20" />
          </w:rPr>
          <w:t>${ detail.infraction_description}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1848" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:left w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:vAlign w:val="center" />
      </w:tcPr>
      <w:p w14:paraId="6E389821" w14:textId="77777777" w:rsidR="000E4341" w:rsidRPr="00FB24C2"
        w:rsidRDefault="000E4341" w:rsidP="000E4341">
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:szCs w:val="20" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00FB24C2">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:szCs w:val="20" />
          </w:rPr>
          <w:t>${detail.discount_description}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1818" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:left w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:vAlign w:val="center" />
      </w:tcPr>
      <w:p w14:paraId="0C3118D0" w14:textId="77777777" w:rsidR="000E4341" w:rsidRPr="00FB24C2"
        w:rsidRDefault="000E4341" w:rsidP="000E4341">
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:szCs w:val="20" />
          </w:rPr>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:szCs w:val="20" />
          </w:rPr>
          <w:t>${toPrice(detail.after_discount)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`
}

function make19ParagraphText(process, penalty) {
  
  function getYearsAsText(years) {
    if (years==undefined || years.length==0) {
      return ''
    }
    if (years.length==1) {
      return `el ejercicio ${years[0]}`
    }
    const byCommas= years.slice(0, -1).join(', ')
    const last= years.slice(-1)[0]
    return `los ejercicios ${byCommas} y ${last}`
  }

  const softYears= penalty.detail
        .filter((det) => det.total > 0)
        .filter((det) => det.infraction_level===PENALTY_INFRACTION_LEVEL.SOFT)
        .map((det) => det.year)
  const badYears= penalty.detail
        .filter((det) => det.total > 0)
        .filter((det) => det.infraction_level===PENALTY_INFRACTION_LEVEL.BAD)
        .map((det) => det.year)

  const dtoMap= {}
  penalty.detail.map((det) => {
    if (det.total>0) {
      const discount= (det.discount*100).toString()
      if (! (discount in dtoMap)) {
        dtoMap[discount]= [det.year]
      } else {
        dtoMap[discount].push(det.year)
      }
    }
  })


  let text= 'La infracción tributaria será calificada como '

  if (badYears.length>=1) {
    text+= `grave, ya que la base de la sanción es superior a 3.000€, en ${getYearsAsText(badYears)}`
  }

  if (badYears.length>=1 && softYears.length>=1) {
    text+= ', y será '
  }

  if (softYears.length>=1) {
    text+= `leve, ya que la base de la sanción es inferior a 3.000€, en ${getYearsAsText(softYears)}`
  }

  text+= '. Por ello la sanción pecuniaria prevista alcanzaría '
  
  const dtoTexts= Object.entries(dtoMap)
        .map(([discount, discountYears]) => `el ${discount}% en ${getYearsAsText(discountYears)}`)
  if (dtoTexts.length>1) {
    const byCommas= dtoTexts.slice(0, -1).join(', ')
    const last= dtoTexts.slice(-1)[0]
    text+= `${byCommas} y ${last}`
  } else {
    text+= dtoTexts[0]
  }

  text+= ' de las cuotas tributarias regularizadas en el '
  
  if (process.minutes_agreed===false) {
    text+= 'Acta de disconformidad '  
  } else {
    text+= 'Acta de conformidad '
  }
  text+= uvl(process.minutes_serial, '??')
  text+= ', por aplicación de los criterios de graduación regulados en el art. 187.1 de la LGT. '



  return text
  /*
  //return 'HEY HEY HEY'
  return `La infracción tributaria será calificada como grave, ya que la base de la sanción es superior a 3.000€, en los ejercicios 
  2015 y 2016, y será leve, ya que la base de la sanción es inferior a 3.000 euros en los ejercicios 2017 y 2018.
  Por ello la sanción pecuniaria prevista alcanzaría el 70% en los ejercicios 2015 y 2016 y un 50% en el 2017 y 2018, 
  de las cuotas tributarias regularizadas en el Acta de conformidad A01 18, 
  por aplicación de los criterios de graduación regulados en el art. 187.1 de la LGT.`
  */
}

function make19Table(process, penalty) {

  const detailLines= sort(penalty.detail, 'year')
                     .filter((t) => t.total > 0)
                     .map((detail) => make19TableRow(detail))
                     .join('\n')
  
  return `<w:tbl>
  <w:tblPr>
    <w:tblW w:w="8396" w:type="dxa" />
    <w:jc w:val="center" />
    <w:tblCellMar>
      <w:left w:w="70" w:type="dxa" />
      <w:right w:w="70" w:type="dxa" />
    </w:tblCellMar>
    <w:tblLook w:val="04A0" w:firstRow="1" w:lastRow="0" w:firstColumn="1" w:lastColumn="0" w:noHBand="0"
      w:noVBand="1" />
  </w:tblPr>
  <w:tblGrid>
    <w:gridCol w:w="1472" />
    <w:gridCol w:w="1482" />
    <w:gridCol w:w="1776" />
    <w:gridCol w:w="1848" />
    <w:gridCol w:w="1818" />
  </w:tblGrid>
  <w:tr w:rsidR="000E4341" w:rsidRPr="00FB24C2" w14:paraId="03A35F4C" w14:textId="77777777" w:rsidTr="000E4341">
    <w:trPr>
      <w:trHeight w:val="237" />
      <w:jc w:val="center" />
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1472" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:left w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="auto" w:fill="auto" />
        <w:noWrap />
        <w:vAlign w:val="center" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="5AE46057" w14:textId="77777777" w:rsidR="000E4341" w:rsidRPr="00FB24C2"
        w:rsidRDefault="000E4341" w:rsidP="000E4341">
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:szCs w:val="20" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00FB24C2">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:szCs w:val="20" />
          </w:rPr>
          <w:t>AÑO</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1482" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:left w:val="nil" />
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="auto" w:fill="auto" />
        <w:noWrap />
        <w:vAlign w:val="center" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="0650C326" w14:textId="77777777" w:rsidR="000E4341" w:rsidRPr="00FB24C2"
        w:rsidRDefault="000E4341" w:rsidP="000E4341">
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:szCs w:val="20" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00FB24C2">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:szCs w:val="20" />
          </w:rPr>
          <w:t>BASE SANCIÓN</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1776" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:left w:val="nil" />
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="auto" w:fill="auto" />
        <w:noWrap />
        <w:vAlign w:val="center" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="635B3E9A" w14:textId="77777777" w:rsidR="000E4341" w:rsidRPr="00FB24C2"
        w:rsidRDefault="000E4341" w:rsidP="000E4341">
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:szCs w:val="20" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00FB24C2">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:szCs w:val="20" />
          </w:rPr>
          <w:t>CALIFICACIÓN SANCIÓN</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1848" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:left w:val="nil" />
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:vAlign w:val="center" />
      </w:tcPr>
      <w:p w14:paraId="3E7D83D2" w14:textId="77777777" w:rsidR="000E4341" w:rsidRPr="00FB24C2"
        w:rsidRDefault="000E4341" w:rsidP="000E4341">
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:szCs w:val="20" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00FB24C2">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:szCs w:val="20" />
          </w:rPr>
          <w:t>CRITERIO GRADUACIÓN</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1818" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:left w:val="nil" />
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:vAlign w:val="center" />
      </w:tcPr>
      <w:p w14:paraId="7036DC03" w14:textId="77777777" w:rsidR="000E4341" w:rsidRPr="00FB24C2"
        w:rsidRDefault="000E4341" w:rsidP="000E4341">
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:szCs w:val="20" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00FB24C2">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:szCs w:val="20" />
          </w:rPr>
          <w:t>SANCIÓN APLICABLE</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>

  ${detailLines}

  <w:tr w:rsidR="000E4341" w:rsidRPr="00FB24C2" w14:paraId="211C50FB" w14:textId="77777777" w:rsidTr="000E4341">
    <w:trPr>
      <w:trHeight w:val="237" />
      <w:jc w:val="center" />
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1472" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="auto" w:fill="auto" />
        <w:noWrap />
        <w:vAlign w:val="center" />
      </w:tcPr>
      <w:p w14:paraId="548821E2" w14:textId="77777777" w:rsidR="000E4341" w:rsidRPr="00FB24C2"
        w:rsidRDefault="000E4341" w:rsidP="000E4341">
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:szCs w:val="20" />
          </w:rPr>
        </w:pPr>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1482" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="auto" w:fill="auto" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
      </w:tcPr>
      <w:p w14:paraId="0588C5BA" w14:textId="77777777" w:rsidR="000E4341" w:rsidRPr="00FB24C2"
        w:rsidRDefault="000E4341" w:rsidP="000E4341">
        <w:pPr>
          <w:suppressAutoHyphens w:val="0" />
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:color w:val="333333" />
            <w:szCs w:val="20" />
          </w:rPr>
        </w:pPr>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1776" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="auto" w:fill="auto" />
        <w:noWrap />
        <w:vAlign w:val="center" />
      </w:tcPr>
      <w:p w14:paraId="6B163640" w14:textId="77777777" w:rsidR="000E4341" w:rsidRPr="00FB24C2"
        w:rsidRDefault="000E4341" w:rsidP="000E4341">
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:szCs w:val="20" />
          </w:rPr>
        </w:pPr>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1848" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:left w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:vAlign w:val="center" />
      </w:tcPr>
      <w:p w14:paraId="4E2C329B" w14:textId="77777777" w:rsidR="000E4341" w:rsidRPr="00FB24C2"
        w:rsidRDefault="000E4341" w:rsidP="000E4341">
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:b />
            <w:bCs />
            <w:szCs w:val="20" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00FB24C2">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:b />
            <w:bCs />
            <w:szCs w:val="20" />
          </w:rPr>
          <w:t>TOTAL</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1818" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:left w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="4" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:vAlign w:val="center" />
      </w:tcPr>
      <w:p w14:paraId="109E39CE" w14:textId="77777777" w:rsidR="000E4341" w:rsidRPr="00FB24C2"
        w:rsidRDefault="000E4341" w:rsidP="000E4341">
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:b />
            <w:bCs />
            <w:szCs w:val="20" />
          </w:rPr>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" />
            <w:b />
            <w:bCs />
            <w:szCs w:val="20" />
          </w:rPr>
          <w:t>${toPrice(penalty.after_discount)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>

</w:tbl>

<w:p w14:paraId="6EAB7E43" w14:textId="77777777" w:rsidR="004A59C5" w:rsidRPr="003D5B70" w:rsidRDefault="004A59C5"
  w:rsidP="003D5B70">
  <w:pPr>
    <w:spacing w:line="276" w:lineRule="auto" />
    <w:jc w:val="both" />
    <w:rPr>
      <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
      <w:szCs w:val="20" />
    </w:rPr>
  </w:pPr>
</w:p>

<w:p w14:paraId="4417C72D" w14:textId="77777777" w:rsidR="003D5B70" w:rsidRPr="003D5B70" w:rsidRDefault="003D5B70"
  w:rsidP="003D5B70">
  <w:pPr>
    <w:spacing w:line="276" w:lineRule="auto" />
    <w:jc w:val="both" />
    <w:rPr>
      <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
      <w:szCs w:val="20" />
    </w:rPr>
  </w:pPr>

  <w:r w:rsidRPr="003D5B70">
    <w:rPr>
      <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
      <w:szCs w:val="20" />
    </w:rPr>
    <w:t>${ make19ParagraphText(process, penalty) }</w:t>
  </w:r>
</w:p>
`
}

export {make19Table}

