import React from 'react'
import {FForm, FInputCheckbox2} from 'formiga-reactstrap'
import {TAX_NAMES} from 'bl/ns/taxes'
import { IBox, IBoxRow } from 'cli/ui/components/ibox'

const DocsFilter = ({filterFields, onChangeFilter}) => {
  return (
    <IBox narrow
          watermark = {{text: "Filtrar por tasa"}}>
      <IBoxRow right>

        <FForm  inline
                renderButtons={(_valid) => null}>
                  <>
                    <FInputCheckbox2 key={`docs_filter_shared`}
                                    name={'docs_filter_shared'}
                                    value={filterFields['shared']}
                                    onChange={(v) => onChangeFilter('shared', v)}
                                    icon={false}
                                    description={'Compartidos'}
                                    inline={true}
                                    inputGroupStyle={{display: "inline-block"}}
                                    />                     
                    {Object.entries(TAX_NAMES).map(([code, name]) => 
                      <FInputCheckbox2 key={`docs_filter_tax_${code}`}
                                      name={code}
                                      value={filterFields[code]}
                                      onChange={(v) => onChangeFilter(code, v)}
                                      icon={false}
                                      description={name}
                                      
                                      inline={true}
                                      inputGroupStyle={{display: "inline-block"}}
                                      />
                    )}
            
                  </>
        </FForm>
      </IBoxRow>
    </IBox>

  )
}

export default DocsFilter