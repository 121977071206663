import React from 'react'
import { IBox, IBoxRow } from 'cli/ui/components/ibox'

const CouncilDetailsPlanEmpty = ({taxPayer}) => {
 
  return (
    <>
      <IBox empty>           
        <IBoxRow center>
            {`${taxPayer.name} no tiene ningún expediente aún.`}
        </IBoxRow>
      </IBox>
    </>
  )
}


export default CouncilDetailsPlanEmpty