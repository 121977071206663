import React from 'react'
import {FForm, FInputText, FInputFloat, FInputCheckbox} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'


const LULicenseDetailsForm = ({ license, existingNames, onChange, onSave, onCancel, isNew, permiss}) => 

  <FForm  onCancel     = {isNew ? onCancel : undefined}
          onSave       = {permiss.can_user_edit_ordinances() ? onSave : undefined}>
          <>
            <FInputText  name       = "name"
                         label      = "Licencia"
                         icon       = {<Icon icon="file-zip"/>}
                         value      = {license.name}
                         required   = {true}
                         feedback   = {'Inserta un nombre válido'}
                         onChange   = {(v) => onChange('name', v)}
                         disallowedValues={existingNames}
                         readOnly    = {!permiss.can_user_edit_ordinances()}
            />
            <FInputText  name       = "cadastre_ref"
                         label      = "Referencia catastral"
                         icon       = {<Icon icon="file-zip"/>}
                         value      = {license.cadastre_ref}
                         required   = {false}
                         feedback   = {'Inserta una ref. catastral'}
                         onChange   = {(v) => onChange('cadastre_ref', v)}
                         readOnly    = {!permiss.can_user_edit_ordinances()}
            />
            <FInputText  name       = "taxpayer_name"
                         label      = "Nombre"
                         icon       = {<Icon icon="user"/>}
                         value      = {license.taxpayer_name}
                         required   = {true}
                         feedback   = {'Nombre Contribuyente'}
                         onChange   = {(v) => onChange('taxpayer_name', v)}
                         readOnly    = {!permiss.can_user_edit_ordinances()}
            />    

            <FInputText  name       = "nif"
                         label      = "NIF"
                         icon       = {<Icon icon="profile"/>}
                         value      = {license.nif}
                         feedback   = {'Introduzca un NIF'}
                         onChange   = {(v) => onChange('nif', v)}
                         readOnly    = {!permiss.can_user_edit_ordinances()}
            /> 

            <FInputText  name       = "taxpayer_address"
                         label      = "Dirección"
                         icon       = {<Icon icon="location"/>}
                         value      = {license.taxpayer_address}
                         feedback   = {'Inserta una dirección del contribuyente'}
                         onChange   = {(v) => onChange('taxpayer_address', v)}
                         readOnly    = {!permiss.can_user_edit_ordinances()}
            />     

            <FInputText  name       = "work_type"
                         label      = "Tipo de Obra"
                         icon       = {<Icon icon="truck"/>}
                         value      = {license.work_type}
                         feedback   = {'Inserta un tipo de Obra'}
                         onChange   = {(v) => onChange('work_type', v)}
                         readOnly    = {!permiss.can_user_edit_ordinances()}
            />    

            <FInputText  name       = "work_description"
                         label      = "Descripción de Obra"
                         icon       = {<Icon icon="truck"/>}
                         value      = {license.work_description}
                         feedback   = {'Inserta una descripción de la Obra'}
                         onChange   = {(v) => onChange('work_description', v)}
                         readOnly    = {!permiss.can_user_edit_ordinances()}
            />    

            <FInputText  name       = "work_address"
                         label      = "Dirección"
                         icon       = {<Icon icon="location"/>}
                         value      = {license.work_address}
                         feedback   = {'Inserta una dirección de la obra'}
                         onChange   = {(v) => onChange('work_address', v)}
                         readOnly    = {!permiss.can_user_edit_ordinances()}
            />  

            <FInputFloat  name       = "pem"
                          label      = "PEM"
                          icon       = {<Icon icon="dollar"/>}
                          value      = {license.pem}
                          required   = {true}
                          gt         = {0}
                          decimalSign= {","}
                          onChange   = {(v) => onChange('pem', v)}
                          readOnly    = {!permiss.can_user_edit_ordinances()}
            />

            <FInputFloat  name       = "icio_provisional"
                          label      = "ICIO Provisional"
                          icon       = {<Icon icon="dollar"/>}
                          value      = {license.icio_provisional}
                          decimalSign= {","}
                          onChange   = {(v) => onChange('icio_provisional', v)}
                          readOnly    = {!permiss.can_user_edit_ordinances()}
            />

            <FInputFloat  name       = "lu_provisional"
                          label      = "LU Provisional"
                          icon       = {<Icon icon="dollar"/>}
                          value      = {license.lu_provisional}
                          decimalSign= {","}
                          onChange   = {(v) => onChange('lu_provisional', v)}
                          readOnly    = {!permiss.can_user_edit_ordinances()}
            />   

            <FInputCheckbox name       = "is_taxpayer"
                          label      = "Es contribuyente"
                          icon       = {<Icon icon="dollar"/>}
                          value      = {license.is_taxpayer}
                          onChange   = {(v) => onChange('is_taxpayer', v)}
                          readOnly    = {!permiss.can_user_edit_ordinances()}
            />            
                          
          </>
  </FForm>

export default LULicenseDetailsForm




