import React, {useState, useEffect, useCallback} from 'react'
import NiceList from 'cli/ui/components/nice_list'
import {ActionDocX, ActionGo, ActionBack} from 'cli/ui/components/buttons/actions'
import eventProcessFields from 'cli/ui/fragments/process/fields/sets/eventProcessFields'
import { arrayRemove } from 'farrapa/collections'
import { IfPermission } from 'cli/context/IfPermission'

const ProcessesList = ({processes, hiddenFields, initialSort, docs, onProcessRemove, onProcessesListPrint, onProcessesPrintStart, onProcessHistorical}) => {

  const [selectedDocs, setSelectedDocs]= useState([
    /*[pid, {inspect: docId, penalty_20: docId, penalty_19: docId}]*/
  ])
  const [someSelected, setSomeSelected]= useState(false)
  const [printMode, setPrintMode]= useState(false)

  useEffect(() => {
    let count= 0
    selectedDocs.map(([_pid, docs]) => {
      Object.keys(docs).map((what) => {
        if (docs[what]!=undefined) {
          count+= 1
        }
      })
    })
    setSomeSelected(count>0)
  }, [selectedDocs])
  

  const handleSelectDoc = useCallback((pid, what, docId) => {
    const nDocId= (docId==undefined || docId=='') ? undefined : parseInt(docId)
    const nSelectedDocs= [...selectedDocs]
    
    let thisSelection= nSelectedDocs.find(([dpid, _docs]) => dpid==pid)
    let previousDocs= {}
    if (thisSelection) {
      arrayRemove(nSelectedDocs, thisSelection)
      previousDocs= thisSelection[1] || {}
    }

    thisSelection= [
      pid,
      {
        ...previousDocs,
        [what]: nDocId
      }
    ]

    nSelectedDocs.push(thisSelection)

    setSelectedDocs(nSelectedDocs)
  }, [selectedDocs])

  const processFields= eventProcessFields(hiddenFields, printMode, docs, selectedDocs)
    
  const makeKey= (process) =>
    `edition_${process.edition_id}_processX_${process.id}`
  

  return (
    <>
        <NiceList fields     = {processFields}
                  initialSort= {initialSort || [0, "asc"]}
                  data       = {processes}
                  makeKey    = {makeKey}
                  transparent= {true}
                  onEvent    = {(ev, params) => {
                    if (ev=='remove') {
                      onProcessRemove(params.id)
                    }
                    if (ev=='sel_doc') {
                      handleSelectDoc(params.id, params.what, params.docId)
                    } 
                    if (ev=='historical') {
                      onProcessHistorical(params.id, params.historical)
                    }                                         
                  }}
        />
        <div style={{margin: "1em", textAlign: "right"}}>
          <IfPermission code="process_print">
            {printMode
            ? <>
                <ActionBack onClick = {() => setPrintMode(false)}
                            label   = "Otras acciones"/>
                <ActionDocX onClick = {() => onProcessesPrintStart()}
                            enabled = {true}
                            label   = "Imprimir diligencias"/> 
                <ActionDocX onClick = {() => onProcessesListPrint(selectedDocs)}
                            enabled = {someSelected}
                            label   = "Imprimir seleccionados"/>
              </>
            : <ActionGo   onClick = {() => setPrintMode(true)}
                          label   = "Impresión múltiple"/>
            }
          </IfPermission>
        </div>
      </>
  )
}





export default ProcessesList








