import React from 'react'
import Page  from 'cli/ui/pages/layout/Page'
import { ActionAdd, 
        ActionHistory} from 'cli/ui/components/buttons/actions'
import CouncilsBase    from './CouncilsBase'
import { IfPermission } from 'cli/context/IfPermission'

// breads
const _BREADS = [['/', 'Inicio'], ['Ayuntamientos']]

const Councils = () => {
  
  return (
    <Page breads = {_BREADS}
          title  = "Ayuntamientos"
          actions= {<>
                      <ActionHistory label="Ver Histórico" to="/councils/hist" isHistorical={true}/>
                      <IfPermission code="council_edit">
                        <ActionAdd to="/councils/new" label="Crear nuevo"/>
                      </IfPermission>
                    </>}>
      <CouncilsBase historical={false}/>
    </Page>
  )
}




export default Councils








