import React from 'react'
import ButtonPair from './ButtonPair'

const ButtonStepsMiddle = ({ onPrev, onNext, disabled }) =>
  <ButtonPair labels  = {['Atrás', 'Siguiente']}
              icons   = {['undo', 'forward']}
              onCancel= {onPrev}
              onSave  = {onNext}
              disabled= {disabled!=undefined ? disabled : false}>
  </ButtonPair>

export default ButtonStepsMiddle