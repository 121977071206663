import {dateLong, toUpperCase, toLowerCase, toTitleCase, toFirstCap, escape, toPrice} from './converters'
import {makeTaxesTable, makeInterestsTable, make19Table} from './tables'
import { GENRE_MALE, GENRE_FEMALE } from 'bl/ns/genres'
import {PENALTY_TYPE, PENALTY_INFRACTION_LEVEL } from 'bl/ns/process/penalty'
import {uvl} from 'farrapa/commons'

const gv = (value, mod)  => {
  if (value==undefined) {
    return ''
  }
  let s
  if (! mod) {
    s= value
  } else if (mod=='Upper') {
    s= toUpperCase(value)
  } else if (mod=='Lower') {
    s= toLowerCase(value)
  } else if (mod=='Caps') {
    s= toTitleCase(value)
  } else if (mod=='FirstCap') {
    s= toFirstCap(value)
  } else {
    console.error('[SERVER][DOCS]------------------------')
    console.error(`[SERVER][DOCS] Unknown MOD: [${mod}]`)
    console.error('[SERVER][DOCS]------------------------')
  }
  s= escape(s)
  return s
}

const DATA_SOURCE= {
  'Expediente: Número': {
    'sample': '01/2020',
    'value' : (data, mod) => gv(data.process.name, mod),
    'check' : (data) => uvl(data.process.name,'')!='',
    'scope' : 'process'
  },
  'Expediente: Fecha de firma': {
    'sample': '25 de Mayo de 2020',
    'value' : (data, mod) => gv(dateLong(data.process.appointment_date), mod),
    'check' : (data) => data.process.appointment_date!=undefined,
    'scope' : 'process'
  },
  'Expediente: Hora de firma': {
    'sample': '15:00',
    'value' : (data, mod) => gv(data.process.taxdata!=undefined ? data.process.taxdata.appointment_time : '', mod),
    'check' : (data) => data.process.taxdata!=undefined && uvl(data.process.appointment_time,'')!='',
    'scope' : 'process'
  },
  'Expediente: Fecha Fin Liquidación': {
    'sample': '31 de Diciembre de 2015',
    'value' : (data, mod) => gv(dateLong(data.process.end_liq_date), mod),
    'check' : (data) => data.process.end_liq_date!=undefined,
    'scope' : 'process'
  },
  'Expediente: Fecha de Inicio de Actuaciones': {
    'sample': '1 de Enero de 2020',
    'value' : (data, mod) => gv(dateLong(data.process.start_date), mod),
    'check' : (data) => data.process.start_date!=undefined,
    'scope' : 'process'
  },
  'Expediente: Ejercicio desde': {
    'sample': '2015',
    'value' : (data, mod) => gv(data.process.year_from, mod),
    'check' : (data) => data.process.year_from!=undefined,
    'scope' : 'process'
  },
  'Expediente: Ejercicio Hasta': {
    'sample': '2019',
    'value' : (data, mod) => gv(data.process.year_to, mod),
    'check' : (data) => data.process.year_to!=undefined,
    'scope' : 'process'
  },
  'Expediente: Ejercicios': {
    'sample': '2015, 1026, 2017, 2018 y 2019',
    'value' : (data, mod) => gv(data.process.year_list, mod),
    'check' : (data) => data.process.year_list!=undefined,
    'scope' : 'process'
  },
  'Expediente: Fecha de Notificación de Propuesta de Liquidación': {
    'sample': '1 de Junio de 2020',
    'value' : (data, mod) => gv(dateLong(data.process.liq_proposal_date), mod),
    'check' : (data) => data.process.liq_proposal_date!=undefined,
    'scope' : 'process'
  },
  'Expediente: Fecha de Notificación Segundo Req': {
    'sample': '5 de Junio de 2020',
    'value' : (data, mod) => gv(dateLong(data.process.second_req_date), mod),
    'check' : (data) => data.process.first_dlg_satisfied!=0 || data.process.second_req_date!=undefined,
    'scope' : 'process'
  },  
  'Expediente: Fecha de Notificación Tercer Req': {
    'sample': '5 de Junio de 2020',
    'value' : (data, mod) => gv(dateLong(data.process.third_req_date), mod),
    'check' : (data) => data.process.first_dlg_satisfied!=0 || data.process.second_dlg_satisfied!=0 || data.process.third_req_date!=undefined,
    'scope' : 'process'
  },  
  'Expediente: Fecha de Conformidad': {
    'sample': '5 de Junio de 2020',
    'value' : (data, mod) => gv(dateLong(data.process.minutes_agreed_date), mod),
    'check' : (data) => data.process.minutes_agreed_date!=undefined,
    'scope' : 'process'
  }, 
  'Expediente: Fecha de Notificación de Acta en disconformidad': {
    'sample': '5 de Junio de 2020',
    'value' : (data, mod) => gv(dateLong(data.process.minutes_notification_date), mod),
    'check' : (data) => data.process.minutes_notification_date!=undefined,
    'scope' : 'process'
  }, 
  'Expediente: Nº de Acta': {
    'sample': 'A01-123',
    'value' : (data, mod) => gv(data.process.minutes_serial, mod),
    'check' : (data) => uvl(data.process.minutes_serial,'')!='',
    'scope' : 'process'
  },
  'Expediente: Fecha de Alegaciones': {
    'sample': '5 de Junio de 2020',
    'value' : (data, mod) => gv(dateLong(data.process.minutes_arguments_date), mod),
    'check' : (data) => data.process.minutes_arguments_date!=undefined,
    'scope' : 'process'
  },   
  'Expediente: Total Cuota 1,5': {
    'sample': '123,45',
    'value' : (data, mod) => gv(toPrice(data.tax.fees), mod),
    'check' : (data) => data.tax.fees!=undefined,
    'scope' : 'process'
  },
  'Expediente: Total Intereses 1,5': {
    'sample': '123,45',
    'value' : (data, mod) => gv(toPrice(data.tax.interests), mod),
    'check' : (data) => data.tax.interests!=undefined,
    'scope' : 'process'
  },
  'Expediente: Total Tasas': {
    'sample': '123,45',
    'value' : (data, mod) => gv(toPrice(data.process.amounts.inspect), mod),
    'check' : (data) => data.process.amounts.inspect!=undefined,
    'scope' : 'process'
  },
  'Expediente: Tabla Tasas': {
    'sample': '--tabla--',
    'value' : (data, _mod) => makeTaxesTable(data),
    'check' : (_data) => true,
    'scope' : 'process',
    'paragraph': true
  },
  'Expediente: Tabla Intereses': {
    'sample': '--tabla--',
    'value' : (data, _mod) => makeInterestsTable(data),
    'check' : (_data) => true,
    'scope' : 'process',
    'paragraph': true
  },
  'Sancion 191-192: Nº de Acta': {
    'sample': 'PS-123',
    'value' : (data, mod) => gv(data.penalty_19.serial, mod),
    'check' : (data) => !data.penalty_19.on || uvl(data.penalty_19.serial,'')!='',
    'scope' : 'process'
  },
  'Sancion 191-192: Fecha Notif Inicio': {
    'sample': '5 de Junio de 2020',
    'value' : (data, mod) => gv(dateLong(data.penalty_19.start_notif_date), mod),
    'check' : (data) => !data.penalty_19.on || data.penalty_19.start_notif_date != undefined,
    'scope' : 'process'
  },
  'Sancion 191-192: Fecha Conformidad': {
    'sample': '5 de Junio de 2020',
    'value' : (data, mod) => gv(dateLong(data.penalty_19.agreed_date), mod),
    'check' : (data) => !data.penalty_19.on || data.penalty_19.agreed_date != undefined,
    'scope' : 'process'
  },
  'Sancion 191-192: Fecha Alegaciones': {
    'sample': '5 de Junio de 2020',
    'value' : (data, mod) => gv(dateLong(data.penalty_19.arguments_date), mod),
    'check' : (data) => !data.penalty_19.on || data.penalty_19.arguments_date != undefined,
    'scope' : 'process'
  },
  'Sancion 191-192: Base Imponible': {
    'sample': '123,45€',
    'value' : (data, mod) => gv(toPrice(data.penalty_19.difference), mod),
    'check' : (data) => !data.penalty_19.on || data.penalty_19.difference !=undefined,
    'scope' : 'process'
  },
  'Sancion 191-192: Cuota': {
    'sample': '123,45€',
    'value' : (data, mod) => gv(toPrice(data.penalty_19.fee), mod),
    'check' : (data) => !data.penalty_19.on || data.penalty_19.fee !=undefined,
    'scope' : 'process'
  },
  'Sancion 191-192: Tasa con descuento': {
    'sample': '123,45€',
    'value' : (data, mod) => gv(toPrice(data.penalty_19.after_discount), mod),
    'check' : (data) => !data.penalty_19.on || data.penalty_19.after_discount !=undefined,
    'scope' : 'process'
  },
  'Sancion 191-192: Tasa Conformidad': {
    'sample': '123,45€',
    'value' : (data, mod) => gv(toPrice(data.penalty_19.after_agreed), mod),
    'check' : (data) => !data.penalty_19.on || data.penalty_19.after_agreed !=undefined,
    'scope' : 'process'
  },
  'Sancion 191-192: Red Descuento': {
    'sample': '123,45€',
    'value' : (data, mod) => gv(toPrice(data.penalty_19.red_by_discount), mod),
    'check' : (data) => !data.penalty_19.on || data.penalty_19.red_by_discount !=undefined,
    'scope' : 'process'
  },  
  'Sancion 191-192: Red Conforme': {
    'sample': '123,45€',
    'value' : (data, mod) => gv(toPrice(data.penalty_19.red_by_agreed), mod),
    'check' : (data) => !data.penalty_19.on || data.penalty_19.red_by_agreed !=undefined,
    'scope' : 'process'
  },  
  'Sancion 191-192: Red Pago': {
    'sample': '123,45€',
    'value' : (data, mod) => gv(toPrice(data.penalty_19.red_by_payment), mod),
    'check' : (data) => !data.penalty_19.on || data.penalty_19.red_by_payment !=undefined,
    'scope' : 'process'
  },
  'Sancion 191-192: Total': {
    'sample': '123,45€',
    'value' : (data, mod) => gv(toPrice(data.penalty_19.total), mod),
    'check' : (data) => !data.penalty_19.on || data.penalty_19.total !=undefined,
    'scope' : 'process'
  },
  'Sancion 191-192: Tabla Importes': {
    'sample': '--tabla--',
    'value' : (data, _mod) => make19Table(data.process, data.penalty_19),
    'check' : (_data) => true,
    'scope' : 'process',
    'paragraph': true
  },
  'Sancion 203: Fecha Notif Inicio': {
    'sample': '5 de Junio de 2020',
    'value' : (data, mod) => gv(dateLong(data.penalty_20.start_notif_date), mod),
    'check' : (data) => !data.penalty_20.on || data.penalty_20.start_notif_date != undefined,
    'scope' : 'process'
  }, 
  'Sancion 203: Nº de Acta': {
    'sample': 'PS-123',
    'value' : (data, mod) => gv(data.penalty_20.serial, mod),
    'check' : (data) => !data.penalty_20.on || uvl(data.penalty_20.serial,'')!='',
    'scope' : 'process'
  },
  'Sancion 203: Fecha Conformidad': {
    'sample': '5 de Junio de 2020',
    'value' : (data, mod) => gv(dateLong(data.penalty_20.agreed_date), mod),
    'check' : (data) => !data.penalty_20.on || data.penalty_20.agreed_date != undefined,
    'scope' : 'process'
  },
  'Sancion 203: Fecha Alegaciones': {
    'sample': '5 de Junio de 2020',
    'value' : (data, mod) => gv(dateLong(data.penalty_20.arguments_date), mod),
    'check' : (data) => !data.penalty_20.on || data.penalty_20.arguments_date != undefined,
    'scope' : 'process'
  },
  'Sancion 203: Cifra Negocio': {
    'sample': '123,45€',
    'value' : (data, mod) => gv(toPrice(data.penalty_20.revenues), mod),
    'check' : (data) => !data.penalty_20.on || data.penalty_20.revenues !=undefined,
    'scope' : 'process'
  },
  'Sancion 203: Importe Base': {
    'sample': '123,45€',
    'value' : (data, mod) => gv(toPrice(data.penalty_20.raw_amount), mod),
    'check' : (data) => !data.penalty_20.on || data.penalty_20.raw_amount !=undefined,
    'scope' : 'process'
  },
  'Sancion 203: Cantidad descontada': {
    'sample': '123,45€',
    'value' : (data, mod) => gv(toPrice(data.penalty_20.discounted), mod),
    'check' : (data) => !data.penalty_20.on || data.penalty_20.discounted !=undefined,
    'scope' : 'process'
  }, 
  'Sancion 203: Cantidad reducida': {
    'sample': '123,45€',
    'value' : (data, mod) => gv(toPrice(data.penalty_20.reducted), mod),
    'check' : (data) => !data.penalty_19.on || data.penalty_20.reducted !=undefined,
    'scope' : 'process'
  },
  'Sancion 203: Importe Final': {
    'sample': '123,45€',
    'value' : (data, mod) => gv(toPrice(data.penalty_20.total), mod),
    'check' : (data) => !data.penalty_20.on || data.penalty_20.total !=undefined,
    'scope' : 'process'
  },
  'Ayuntamiento: Nombre': {
    'sample': 'Villarriba',
    'value' : (data, mod) => gv(data.council.name, mod),
    'check' : (data) => uvl(data.council.name,'')!='',
    'scope' : 'council'
  },
  'Ayuntamiento: DEH': {
    'sample': 'alegaciones@villariba.es',
    'value' : (data, mod) => gv(data.council.deh, mod),
    'check' : (data) => uvl(data.council.deh,'')!='',
    'scope' : 'council'
  },
  'Ayuntamiento: Actuario: Nombre': {
    'sample': 'Manuel Pérez',
    'value' : (data, mod) => gv(data.council.clerk_name, mod),
    'check' : (data) => uvl(data.council.clerk_name,'')!='',
    'scope' : 'council'
  },
  'Ayuntamiento: Actuario: Cargo': {
    'sample': 'El Concejal',
    'value' : (data, mod) => gv(data.council.clerk_pos, mod),
    'check' : (data) => uvl(data.council.clerk_pos,'')!='',
    'scope' : 'council'
  },
  'Ayuntamiento: Alcalde(sa)': {
    'sample': 'El Alcalde',
    'value' : (data, mod) => gv(data.council.name, mod),
    'check' : (data) => uvl(data.council.name,'')!='',
    'scope' : 'council'
  },
  'Ayuntamiento: IBAN': {
    'sample': 'ES12 3456 7890 1234 5678',
    'value' : (data, mod) => gv(data.council.iban, mod),
    'check' : (data) => uvl(data.council.iban,'')!='',
    'scope' : 'council'
  },
  'Contribuyente: Nombre': {
    'sample': 'Gas Eosas S.A.',
    'value' : (data, mod) => gv(data.taxpayer.name, mod),
    'check' : (data) => uvl(data.taxpayer.name,'')!='',
    'scope' : 'tax_payer'
  },
  'Contribuyente: NIF': {
    'sample': '12345678P',
    'value' : (data, mod) => gv(data.taxpayer.nif, mod),
    'check' : (data) => uvl(data.taxpayer.nif,'')!='',
    'scope' : 'tax_payer'
  },
  'Contribuyente: Dirección': {
    'sample': 'Avenida de los gases, 1',
    'value' : (data, mod) => gv(data.taxpayer.address, mod),
    'check' : (data) => uvl(data.taxpayer.address,'')!='',
    'scope' : 'tax_payer'
  },
  'Contribuyente: Provincia': {
    'sample': 'Jaén',
    'value' : (data, mod) => gv(data.taxpayer.province, mod),
    'check' : (data) => uvl(data.taxpayer.province,'')!='',
    'scope' : 'tax_payer'
  },
  'Contribuyente: Sector': {
    'sample': 'Distribuidoras',
    'value' : (data, mod) => gv(data.taxpayer.sector_name, mod),
    'check' : (data) => uvl(data.taxpayer.sector_name,'')!='',
    'scope' : 'tax_payer'
  },

  'Licencia: Tipo Obra': {
    'sample': 'Cambio Tubería',
    'value' : (data, mod) => gv(data.tax?.license?.work_type, mod),
    'check' : (data) => data.tax?.license?.work_type!=undefined,
    'scope' : 'process'
  },
  'Contribuyente: Actividad': {
    'sample': 'distribución de gas',
    'value' : (data, mod) => gv(data.taxpayer.activity, mod),
    'check' : (data) => uvl(data.taxpayer.activity,'')!='',
    'scope' : 'tax_payer'
  },
  'Ordenanza: Título': {
    'sample': 'TASA POR UTILIZACIÓN PRIVATIVA O APROVECHAMIENTO ESPECIAL CONSTITUIDO EN EL SUELO, SUBSUELO O VUELO DE LAS VIAS PÚBLICAS MUNICIPALES A FAVOR DE EMPRESAS EXPLOTADORAS DE SERVICIOS DE SUMINISTROS',
    'value' : (data, mod) => gv(data.ordinance.title, mod),
    'check' : (data) => uvl(data.ordinance.title,'')!='',
    'scope' : 'ordinance'
  },
  'Ordenanza: Fecha Plan Inspección': {
    'sample': '01 de Enero de 2020',
    'value' : (data, mod) => gv(dateLong(data.ordinance.inspection_date), mod),
    'check' : (data) => data.ordinance.inspection_date!=undefined,
    'scope' : 'ordinance'
  },
  'Agente: Nombre': {
    'sample': 'David',
    'value' : (data, mod) => gv(data.doc.agent_name || data.agent.name, mod),
    'check' : (data) => uvl(data.doc.agent_name,'')!='' || uvl(data.agent.name,'')!='',
    'scope' : 'file'
  },
  'Agente: Teléfono': {
    'sample': '666777888',
    'value' : (data, mod) => gv(data.doc.agent_phone || data.agent.phone, mod),
    'check' : (data) => uvl(data.doc.agent_phone,'')!='' || uvl(data.agent.phone,'')!='',
    'scope' : 'file'
  },
  'Firmante: Nombre': {
    'sample': 'Rodrigo Trigo',
    'value' : (data, mod) => gv(data.doc.signer_name, mod),
    'check' : (data) => uvl(data.doc.signer_name,'')!='',
    'scope' : 'file'
  },
  'Firmante: NIF': {
    'sample': '87654321Q',
    'value' : (data, mod) => gv(data.doc.signer_nif, mod),
    'check' : (data) => uvl(data.doc.signer_nif,'')!='',
    'scope' : 'file'
  },
  'Firmante: Puesto': {
    'sample': 'Director general',
    'value' : (data, mod) => gv(data.doc.signer_pos, mod),
    'check' : (data) => uvl(data.doc.signer_pos,'')!='',
    'scope' : 'file'
  },
  'Documento: Fecha Pie': {
    'sample': '01 de Mayo de 2020',
    'value' : (data, mod) => gv(dateLong(data.doc.doc_date), mod),
    'check' : (data) => data.doc.doc_date!=undefined,
    'scope' : 'file'
  },
  'SI__Disconformidad Con Alegaciones': {
    'sample': '[Con Alegaciones=>]',
    //'if'    : (data) => data.process.minutes_arguments===true,
    'if'    : (data) => data.process.liq_proposal_arguments===true,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'disagree'
    }
  },
  'SI__Disconformidad Sin Alegaciones': {
    'sample': '[Sin alegaciones=>]',
    //'if'    : (data) => data.process.minutes_arguments!==true,
    'if'    : (data) => data.process.liq_proposal_arguments!==true,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'disagree'
    }
  },
  'SI__Sancion es 191': {
    'sample': '[Art. 191=>]',
    'if'    : (data) => data.step.penalty_type===PENALTY_TYPE.ART_191,
    'check' : (data) => data.step.penalty_type!==undefined,
    'scope' : 'process',
    'if_props': {
      'name': 'penalty_type'
    }
  },
  'SI__Sancion es 192': {
    'sample': '[Art. 192=>]',
    'if'    : (data) => data.step.penalty_type===PENALTY_TYPE.ART_192,
    'check' : (data) => data.step.penalty_type!==undefined,
    'scope' : 'process',
    'if_props': {
      'name': 'penalty_type'
    }
  },
  'SI__Sancion es 203': {
    'sample': '[Art. 203=>]',
    'if'    : (data) => data.step.penalty_type===PENALTY_TYPE.ART_203,
    'check' : (data) => data.step.penalty_type!==undefined,
    'scope' : 'process',
    'if_props': {
      'name': 'penalty_type'
    }
  },
  'SI__Req No Atendido es 1': {
    'sample': '[Primer req no atendido=>]',
    'if'    : (data) => data.process.unatt_requirement===1,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'unatt_requirement'
    }
  },
  'SI__Req No Atendido es 2': {
    'sample': '[Segundo req no atendido=>]',
    'if'    : (data) => data.process.unatt_requirement===2,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'unatt_requirement'
    }
  },
  'SI__Req No Atendido es 3': {
    'sample': '[Tercer req no atendido=>]',
    'if'    : (data) => data.process.unatt_requirement===3,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'unatt_requirement'
    }
  },
  'SI__Inicio de Actuaciones Rechazado': {
    'sample': '[Inicio de Actuaciones Rechazado=>]',
    'if'    : (data) => data.process.start_accepted===0,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'start_accepted'
    }
  },
  'SI__Segundo Req Rechazado': {
    'sample': '[Segundo Req Rechazado=>]',
    'if'    : (data) => data.process.second_req_accepted===0,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'second_req_accepted'
    }
  },
  'SI__Tercer Req Rechazado': {
    'sample': '[Tercer Req Rechazado=>]',
    'if'    : (data) => data.process.third_req_accepted===0,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'third_req_accepted'
    }
  },  
  'SI__Aporta documentación': {
    'sample': '[Aporta documentación=>]',
    'if'    : (data) => data.process.doc_provided_date!=undefined,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'doc_provided_date'
    }
  }, 
  'SI__203: Notif Rechazado': {
    'sample': '[203: Notif Rechazado=>]',
    'if'    : (data) => data.penalty_20.start_notif_accepted===0,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'penalty_20_start_notif_accepted'
    }
  },
  'SI__191-192: Notif Rechazado': {
    'sample': '[191-192: Notif Rechazado=>]',
    'if'    : (data) => data.penalty_19.start_notif_accepted===0,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'penalty_19_start_notif_accepted'
    }
  },

  'SI__191/192 Conforme': {
    'sample': '[191/192 Conforme=>]',
    'if'    : (data) => data.penalty_19.applies_agree_discount===true, //data.penalty_19.agreed===true,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'penalty_19_agreed'
    }
  },
  'SI__191/192 Leve': {
    'sample': '[191/192 Leve=>]',
    'if'    : (data) => data.penalty_19.infraction_level===PENALTY_INFRACTION_LEVEL.SOFT,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'penalty_19_infraction_level'
    }
  },
  'SI__191/192 Grave': {
    'sample': '[191/192 Grave=>]',
    'if'    : (data) => data.penalty_19.infraction_level===PENALTY_INFRACTION_LEVEL.BAD,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'penalty_19_infraction_level'
    }
  },
  'SI__191/192 Ingreso Voluntario': {
    'sample': '[191/192 Ingreso=>]',
    'if'    : (data) => data.penalty_19.reduct_payment!==false,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'penalty_19_reduct_payment'
    }
  },
  'SI__191/192 No Ingreso Voluntario': {
    'sample': '[191/192 Ingreso=>]',
    'if'    : (data) => data.penalty_19.reduct_payment===false,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'penalty_19_reduct_payment'
    }
  },
  'SI__191/192 Con Alegaciones': {
    'sample': '[191/192 Alegaciones=>]',
    'if'    : (data) => data.penalty_19.arguments===true,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'penalty_19_arguments'
    }
  },
  'SI__191/192 Sin Alegaciones': {
    'sample': '[191/192 Alegaciones=>]',
    'if'    : (data) => data.penalty_19.arguments!==true,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'penalty_19_arguments'
    }
  },  
  'SI__203 Aporta Doc': {
    'sample': '[Aporta documentación=>]',
    'if'    : (data) => data.penalty_20.reduct_provided===true,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'reduct_provided'
    }
  },
  'SI__203 No Aporta Doc': {
    'sample': '[No aporta documentación=>]',
    'if'    : (data) => data.penalty_20.reduct_provided!==true,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'reduct_provided'
    }
  },
  'SI__203 Ingreso voluntario': {
    'sample': '[Ingreso voluntario=>]',
    'if'    : (data) => data.penalty_20.reduct_payment!==false,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'reduct_payment'
    }
  },
  'SI__203 No Ingreso voluntario': {
    'sample': '[No ingreo voluntario=>]',
    'if'    : (data) => data.penalty_20.reduct_payment===false,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'reduct_payment'
    }
  },
  'SI__203 Con Alegaciones': {
    'sample': '[203 Alegaciones=>]',
    'if'    : (data) => data.penalty_20.arguments===true,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'penalty_20_arguments'
    }
  },
  'SI__203 Sin Alegaciones': {
    'sample': '[203 Alegaciones=>]',
    'if'    : (data) => data.penalty_20.arguments!==true,
    'check' : (_data) => true,
    'scope' : 'process',
    'if_props': {
      'name': 'penalty_20_arguments'
    }
  },    
  'SI__Ayuntamiento tiene Alcalde': {
    'sample': '[Alcalde?=>]',
    'if'    : (data) => data.council.major_genre==GENRE_MALE,
    'check' : (data) => data.council.major_genre!=undefined,
    'scope' : 'council',
    'if_props': {
      'name': 'major'
    }
  },
  'SI__Ayuntamiento tiene Alcaldesa': {
    'sample': '[Alcaldesa?=>]',
    'if'    : (data) => data.council.major_genre==GENRE_FEMALE,
    'check' : (data) => data.council.major_genre!=undefined,
    'scope' : 'council',
    'if_props': {
      'name': 'major'
    }
  },
  'SI__Sector es Distribuidoras': {
    'sample': '[¿Distribuidoras?=>]',
    'if'    : (data) => data.taxpayer.sector_code=='DIS',
    'check' : (data) => uvl(data.taxpayer.sector_code,'')!='',
    'scope' : 'tax_payer',
    'if_props': {
      'name': 'sector'
    }
  },
  'SI__Sector es Comercializadoras': {
    'sample': '[¿Comercializadoras?=>]',
    'if'    : (data) => data.taxpayer.sector_code=='COM',
    'check' : (data) => uvl(data.taxpayer.sector_code,'')!='',
    'scope' : 'tax_payer',
    'if_props': {
      'name': 'sector'
    }
  },
  'SI__Sector es Telecomunicaciones': {
    'sample': '[¿Telecomunicaciones?=>]',
    'if'    : (data) => data.taxpayer.sector_code=='TEL',
    'check' : (data) => uvl(data.taxpayer.sector_code,'')!='',
    'scope' : 'tax_payer',
    'if_props': {
      'name': 'sector'
    }
  },
  'SI__Sector es Comerciales IAE': {
    'sample': '[¿Comerciales IAE?=>]',
    'if'    : (data) => data.taxpayer.sector_code=='COM_IAE',
    'check' : (data) => uvl(data.taxpayer.sector_code,'')!='',
    'scope' : 'tax_payer',
    'if_props': {
      'name': 'sector'
    }
  },
  'SI__Sector es Industrias IAE': {
    'sample': '[Industrias IAE?=>]',
    'if'    : (data) => data.taxpayer.sector_code=='IND_IAE',
    'check' : (data) => uvl(data.taxpayer.sector_code,'')!='',
    'scope' : 'tax_payer',
    'if_props': {
      'name': 'sector'
    }
  },
  'SI__FIN': {
    'sample': '[<=Fin]',
    'if'    : (_data) => true,
    'check' : (_data) => true,
    'scope' : undefined,
    'if_props': {
      'name': 'end'
    }
  }
}

export {DATA_SOURCE}

//module.exports = {DATA_SOURCE}
