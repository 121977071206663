import React from 'react'
import TaxSection from '../common/TaxSection'

const ProcessTOTax = (/*{ process, onChangeProcess, onChange, onGoToInspect, canEdit }*/) => {

  return (
    <TaxSection title="Tasas">
      <p>--Por definir--</p>
    </TaxSection>
  )
}


export default ProcessTOTax




