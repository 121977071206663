import React from 'react'
import { Route } from 'react-router-dom'

import Stats from 'cli/ui/pages/stats/Stats'

const StatsRoutes = (permiss) => {
  return permiss.can_user_read_stats()
  ? [<Route key="stats_00" path={'/stats'} element={<Stats/>}/>]
  : []
    
}

export default StatsRoutes