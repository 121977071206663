import React from 'react'
import {TimelineEvent, TimelineEventInfo, TimelineEventRight} from 'cli/ui/components/timeline'
import {WithArguments, WithoutArguments} from './parts/actions'


const EventArguments = ({subStep, onProcessChange, canEdit}) => {

  const handleOnArgumented = (v) => {
    onProcessChange({[subStep.field_name]: v})
  }
  
  return (
      <TimelineEvent  key      ={`process_inspect_arguments`}
                      legend   = {{icon: "agree", 
                                  color: subStep.arguments_status=='pending'
                                         ? 'gray'
                                         : subStep.argumented 
                                           ? 'red' 
                                           : 'lightgreen'
                                 }}
                      date     = {undefined}
                      isLast   = {subStep.done==false}
                      >

        <TimelineEventInfo>
          <i style={{color: "gray"}}>
            {subStep.arguments_status=='pending'
             ? "Pendiente de posibles alegaciones"
             : subStep.argumented==true
               ? "Con alegaciones"
               : "Sin alegaciones"
            }
         </i>
        </TimelineEventInfo>
        <TimelineEventRight>
          {canEdit
           ? <>
              <WithArguments disabled={subStep.argumented==true}
                            onClick={() => handleOnArgumented(true)}/>
              <WithoutArguments disabled={subStep.argumented==false}
                            onClick={() => handleOnArgumented(false)}/>
            </>
           : null}
        </TimelineEventRight>
      </TimelineEvent>
  )


}

export default EventArguments