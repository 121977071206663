import React from 'react'
import ProcessListCountThreeFlows from './ProcessListCountThreeFlows'
import {TAX_NAMES} from 'bl/ns/taxes'


const processListCountThreeFlowsAllTaxes = () => {
  /* eslint react/display-name:0 */      

  return Object.entries(TAX_NAMES)
    .map(([taxCode, taxName]) => {
      return {
          name     : undefined,
          label    : taxName, 
          className: "center", 
          render   : (rec) => <ProcessListCountThreeFlows processes={rec.processes != undefined 
                                                                     ? rec.processes[taxCode] || []
                                                                     : []} />,
          empty    : (rec) => rec.processes != undefined 
                              ? (rec.processes[taxCode] || []).length==0
                              : true
        }
    })
}

export default processListCountThreeFlowsAllTaxes








