class ProcessTOTaxCalc  {

  id             = undefined
  process_id     = undefined

  constructor(obj, process) {
    this.process= process
    this.update(obj)
  }

  update(obj) {
    if (typeof obj == 'object') {
      Object.keys(this)
            .filter((k) => Object.prototype.hasOwnProperty.call(obj, k))
            .map((k) => {
              this[k]= obj[k]
            })
    }

    if (this.process.id!=undefined)
      this.process_id = this.process.id
  }

  get ready() {
    return {
      ok: true,
      missing: {}
    }
  }

  get total() {
    return 0.0
  }

  forDocx() {
    return {

    }
  }  
  
  toJson() {
    return {
      id             : this.id             ,
      process_id     : this.process_id     ,
    }
  }  

  export() {
    return {
      ...this.toJson(),
    }
  }
}


export default ProcessTOTaxCalc


