import React from 'react'
import {ProgressBar as UiProgressBar} from 'cli/ui/components/progress'
import ProgressBarLabel from './ProgressBarLabel'

const ProgressBar = ({left, leftColor, center, right, rightColor, title, progress, color}) => {
  return (
    <div className="afi-process-resume">
      <div className="afi-process-info"
           data-title={title}>
        <UiProgressBar progress = {progress} 
                     label    = {
                       <ProgressBarLabel left={left} leftColor={leftColor} center={center} right={right} rightColor={rightColor} />
                     }
                     color    = {color}
                     stripped = {false}/>
      </div>
    </div>
  )
}

export default ProgressBar