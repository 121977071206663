import React from 'react'
import {intre_pretty_short} from 'intre'
import {TimelineEvent, TimelineEventInfo, TimelineEventRight} from 'cli/ui/components/timeline'
import EventArgumentsDateForm from './EventArgumentsDateForm'


const EventArgumentsDate = ({subStep, onProcessChange, canEdit}) => {

  const handleOnChange = (argumentsDate) => {
    if (argumentsDate!=subStep.arguments_date) {
      const changes= {[subStep.field_name]: argumentsDate}
      onProcessChange(changes)
    }
  }

  return (
    <TimelineEvent  key      ={`process_inspect_arguments_date`}
                    legend   = {{icon: "pen", 
                                color: subStep.arguments_date_status!='pending'
                                      ? 'green'
                                      : 'gray'}}
                    date     = {subStep.arguments_date}
                    isLast   = {subStep.done===false}
                    >

      <TimelineEventInfo>
        {subStep.arguments_date_status=='pending'
          ? <i style={{color: "gray"}}>Pendiente de fijar fecha de alegaciones</i>
          : <i style={{color: "gray"}}>{`Fecha de alegaciones: ${intre_pretty_short(subStep.arguments_date)}`}</i>
        }
      </TimelineEventInfo>
      <TimelineEventRight>
        {canEdit
         ?
            <EventArgumentsDateForm subStep  = {subStep}
                                    onChange = {(argumentsDate) => handleOnChange(argumentsDate)}/>
         : null}
      </TimelineEventRight>
    </TimelineEvent>
  )
}

export default EventArgumentsDate