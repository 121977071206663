import React from 'react'
import {TimelineEvent, TimelineEventInfo, TimelineEventRight} from 'cli/ui/components/timeline'
import {WithEstimate, WithoutEstimate} from './parts/actions'


const EventEstimate = ({subStep, onProcessChange, canEdit}) => {
  const handleOnEstimated = (v) => {
    onProcessChange({[subStep.field_name]: v})
  }
  

  return (
      <TimelineEvent  key      ={`process_inspect_estimate`}
                      legend   = {{icon: "agree", 
                                  color: subStep.estimate_status=='pending'
                                         ? 'gray'
                                         : subStep.estimated
                                           ? 'red' 
                                           : 'lightgreen'
                                 }}
                      date     = {undefined /*processDoc.file.notification_date*/}
                      isLast   = {subStep.done===false}
                      >

        <TimelineEventInfo>
          <i style={{color: "gray"}}>
            {subStep.estimate_status=='pending'
             ? "Pendiente de estimación o desestimación"
             : subStep.estimated==true
               ? "Estimado"
               : "Desestimado"
            }
         </i>
        </TimelineEventInfo>
        <TimelineEventRight>
          {canEdit
           ? <>
              <WithEstimate disabled={subStep.estimated==true}
                            onClick={() => handleOnEstimated(true)}/>
              <WithoutEstimate disabled={subStep.estimated==false}
                            onClick={() => handleOnEstimated(false)}/>
            </>
           : null}                    
        </TimelineEventRight>
      </TimelineEvent>
  )


}

export default EventEstimate