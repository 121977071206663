import React, {useState, useEffect} from 'react'


// Components
import Page          from 'cli/ui/pages/layout/Page'
import {Row, Col}    from 'reactstrap'
import { ActionAdd }   from 'cli/ui/components/buttons/actions'
import {LoadingBig}    from 'cli/ui/components/loading'
import RolesList    from './RolesList'
import { arrayRemove } from 'farrapa/collections'
import withContext from 'cli/context/withContext'

// breads
const _BREADS = [['/', 'Inicio'], ['/settings', 'Configuración'], ['/settings/accontrol', 'Usuarios y perfiles'], ['Perfiles y permisos']]

const Roles = ({fetcher}) => {
  const [roles, setRoles]= useState([])
  const [status, setStatus]= useState(0)

  useEffect(() => {
    async function fetchData() {
      const raw= await fetcher.get('/api/role/detail')
      setRoles(raw.data.roles)
      setStatus(1)
    }
    fetchData()

  }, [fetcher])

  const handleRemove = async (roleId) => {
    const done = await fetcher.remove('/api/acc_role', roleId)
    if (done) {
      let nroles= [...roles]
      arrayRemove(nroles,
        nroles.find((c) => c.id==roleId)
      )
      setRoles(nroles)
    }
    return done
  }

  return (
    <Page breads={_BREADS}
          title={"Perfiles y permisos"}
          actions={<ActionAdd to="/settings/accontrol/roles/new" label="Crear nuevo"/>}>
      <Row>
        <Col xs="12">
            {status < 1 ?
              <LoadingBig /> :
              <RolesList roles={roles}
                            onRemove={(cid) => handleRemove(cid)}/>
            }
        </Col>
      </Row>
    </Page>
  )
}


export default withContext(Roles)








