import React from 'react'
import { Route } from 'react-router-dom'
import Settings             from 'cli/ui/pages/settings/Settings'
import Accontrol            from 'cli/ui/pages/settings/accontrol/Accontrol'
import Users                from 'cli/ui/pages/settings/accontrol/users/Users'
import UserDetails          from 'cli/ui/pages/settings/accontrol/users/det/UserDetails'
import Roles                from 'cli/ui/pages/settings/accontrol/roles/Roles'
import RoleDetails          from 'cli/ui/pages/settings/accontrol/roles/det/RoleDetails'
import IRates               from 'cli/ui/pages/settings/irates/IRates'
import Agents               from 'cli/ui/pages/settings/agents/Agents'
import AgentDetails         from 'cli/ui/pages/settings/agents/det/AgentDetails'
import Sectors              from 'cli/ui/pages/settings/sectors/Sectors'
import SectorDetails        from 'cli/ui/pages/settings/sectors/det/SectorDetails'
import Calendar             from 'cli/ui/pages/settings/calendar/Calendar'


const SettingRoutes = (permiss) => {
  if (!permiss.can_user_read_setts()) {
    return []
  }

  return [
    <Route key="settings_01" path={'/settings'} element={<Settings/>}/>,

    ...permiss.can_user_edit_setts_users()
    ? [<Route key="settings_020" path={'/settings/accontrol'} element={<Accontrol/>}/>,
       <Route key="settings_021" path={'/settings/accontrol/users'} element={<Users/>}/>,
       <Route key="settings_022" path={'/settings/accontrol/users/:idUser'} element={<UserDetails/>}/>,
       <Route key="settings_031" path={'/settings/accontrol/roles'} element={<Roles/>}/>,
       <Route key="settings_032" path={'/settings/accontrol/roles/:idRole'} element={<RoleDetails/>}/>]
    : [],

    ...permiss.can_user_edit_setts_irates()
    ? [<Route key="settings_04" path={'/settings/irates'} element={<IRates/>}/>]
    : [],

    ...permiss.can_user_edit_setts_agents()
    ? [<Route key="settings_05" path={'/settings/agents'} element={<Agents/>}/>,
    <Route key="settings_06" path={'/settings/agents/:idAgent'} element={<AgentDetails/>}/>]
    : [],

    ...permiss.can_user_edit_setts_sectors()
    ? [<Route key="settings_07" path={'/settings/sectors'} element={<Sectors/>}/>,
    <Route key="settings_08" path={'/settings/sectors/:idSector'} element={<SectorDetails/>}/>]
    : [],

    ...permiss.can_user_edit_setts_calendar()
    ? [<Route key="settings_09" path={'/settings/calendar'} element={<Calendar/>}/>]
    : []
  ]
}

export default SettingRoutes

