import React from 'react'
import {Timeline} from 'cli/ui/components/timeline'
import ProcessStep from './steps/ProcessStep'


const ProcessPenalty = (
  {process, processPenalty, agents, docs,
    onPrint, onProcessChange, onProcessFileChange, canEdit, canPrint}) => {


  return (
    <div>
      <Timeline>
        {processPenalty.steps.map((processStep) => 
            <ProcessStep
                key                = {`process_penalty_step_${processStep.id}`}
                process            = {process}
                processStep        = {processStep}
                agents             = {agents}
                docs               = {docs}
                onPrint            = {onPrint}
                onProcessChange    = {onProcessChange}
                onProcessFileChange= {onProcessFileChange}
                canEdit={canEdit}
                canPrint={canPrint}/>
          )}
      </Timeline>
    </div>
  )
}

export default ProcessPenalty  