import React from 'react'
import { HyperCouncil, HyperTaxPayer, HyperProcess, HyperTax } from 'cli/ui/components/hyper'
import { PTYPE_NAMES } from 'bl/ns/process/process'
import {TAX_NAMES } from 'bl/ns/taxes'
import eventProcessFieldsMain from './eventProcessFieldsMain'
import eventProcessFieldsPrint from './eventProcessFieldsPrint'


const eventProcessFields = (hiddenFields, printMode, docs, selectedDocs) => {
   /* eslint react/display-name:0 */

  let fields= []

  if (!hiddenFields || hiddenFields.indexOf('tax_code')<0) {
    fields.push(
      {
        label: "Tasa",
        value: (p) => TAX_NAMES[p.tax_code],
        render: (p) => <HyperTax code={p.tax_code}/>
      }
    )
  }

  if (!hiddenFields || hiddenFields.indexOf('name')<0) {
    fields.push(
      {
        label: "Expdte.",
        value: (p) => p.name,
        render: (process) =>
          <HyperProcess taxCode={process.tax_code}  id={process.id} name={process.name} editionId={process.edition_id}/>
      }
    )
  }
    
  if (!hiddenFields || hiddenFields.indexOf('council')<0) {
    fields.push(
      {
        label: "Ayun.",
        className: "main", 
        value: (process) => process.council_name,
        render: (process) =>
          <HyperCouncil id={process.council_id} name={process.council_name}/>
      }
    )
  }
  
  if (!hiddenFields || hiddenFields.indexOf('taxpayer')<0) {
    fields.push(
      {
        label: "Contribuyente",
        value: (process) => process.taxpayer_name,
        render: (process) =>
          <HyperTaxPayer id={process.taxpayer_id} name={process.taxpayer_name}/>
      }
    )
  }
  
  if (!hiddenFields || hiddenFields.indexOf('process_type')<0) {
    fields.push(
      {
        label: "Tipo",
        value: (p) => PTYPE_NAMES[p.tax_code][p.process_type]  
      }
    )
  }

  fields= [
    ...fields,
    ... printMode===false
          ? eventProcessFieldsMain(hiddenFields)
          : eventProcessFieldsPrint(hiddenFields, docs, selectedDocs)
  ]
  return fields
}



export default eventProcessFields








