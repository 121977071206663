
import {toPrice, sort, dateShort} from '../../converters'

function makeI5InterestsRow(detail, iresume) {
  return `
    <w:tr w:rsidR="0070407C" w:rsidTr="00FD29B1">
    <w:trPr>
      <w:trHeight w:val="240"/>
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="688" w:type="pct"/>
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:left w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:right w:val="single" w:sz="4" w:space="0" w:color="000000"/>
        </w:tcBorders>
        <w:shd w:val="clear" w:color="EDEDED" w:fill="EDEDED"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="0070407C" w:rsidRDefault="004A3F28">
        <w:pPr>
          <w:jc w:val="center"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t>${ detail.year }</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="689" w:type="pct"/>
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:left w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:right w:val="single" w:sz="4" w:space="0" w:color="000000"/>
        </w:tcBorders>
        <w:shd w:val="clear" w:color="EDEDED" w:fill="EDEDED"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="0070407C" w:rsidRDefault="004A3F28">
        <w:pPr>
          <w:jc w:val="center"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t>${ toPrice(detail.fee)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="689" w:type="pct"/>
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:left w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:right w:val="single" w:sz="4" w:space="0" w:color="000000"/>
        </w:tcBorders>
        <w:shd w:val="clear" w:color="EDEDED" w:fill="EDEDED"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="0070407C" w:rsidRDefault="004A3F28">
        <w:pPr>
          <w:jc w:val="center"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t>${ dateShort(iresume.dfrom) }</w:t>
        </w:r>
      </w:p>          
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="689" w:type="pct"/>
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:left w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:right w:val="single" w:sz="4" w:space="0" w:color="000000"/>
        </w:tcBorders>
        <w:shd w:val="clear" w:color="EDEDED" w:fill="EDEDED"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="0070407C" w:rsidRDefault="004A3F28">
        <w:pPr>
          <w:jc w:val="center"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t>${ dateShort(iresume.dto) }</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="689" w:type="pct"/>
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:left w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:right w:val="single" w:sz="4" w:space="0" w:color="000000"/>
        </w:tcBorders>
        <w:shd w:val="clear" w:color="EDEDED" w:fill="EDEDED"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="0070407C" w:rsidRDefault="004A3F28">
        <w:pPr>
          <w:jc w:val="center"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t>${ iresume.days }</w:t>
        </w:r>
      </w:p>          
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="689" w:type="pct"/>
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:left w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:right w:val="single" w:sz="4" w:space="0" w:color="000000"/>
        </w:tcBorders>
        <w:shd w:val="clear" w:color="EDEDED" w:fill="EDEDED"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="0070407C" w:rsidRDefault="004A3F28">
        <w:pPr>
          <w:jc w:val="center"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t>${ iresume.irate }%</w:t>
        </w:r>
      </w:p>          
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="866" w:type="pct"/>
        <w:tcBorders>
          <w:top w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:left w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:bottom w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          <w:right w:val="single" w:sz="4" w:space="0" w:color="000000"/>
        </w:tcBorders>
        <w:shd w:val="clear" w:color="EDEDED" w:fill="EDEDED"/>
        <w:vAlign w:val="center"/>
      </w:tcPr>
      <w:p w:rsidR="0070407C" w:rsidRDefault="004A3F28">
        <w:pPr>
          <w:jc w:val="center"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t>${ toPrice(iresume.interest) }</w:t>
        </w:r>
      </w:p> 
    </w:tc>
  </w:tr>

  `
}


function makeI5InterestsTable(tax) {

  const detailsWithInterests= 
    sort(tax.details, 'year')
    .filter((detail) => detail.interests_resume.length>0)

  let detailLineList= []
  detailsWithInterests.map((detail) => {
    detail.interests_resume.map((iresume) => {
      detailLineList.push(makeI5InterestsRow(detail, iresume))
    })
  })

  const detailLines = detailLineList.join('\n')  

  return `<w:p>
    <w:pPr>
      <w:pStyle w:val="Normal" />
      <w:rPr>      
        <w:sz w:val="15" />
        <w:szCs w:val="15" />
      </w:rPr>
    </w:pPr>
    <w:r>
      <w:rPr>
        <w:sz w:val="15" />
        <w:szCs w:val="15" />
      </w:rPr>
      <w:t xml:space="preserve">* </w:t>
    </w:r>
    <w:r>
      <w:rPr>
        <w:i />
        <w:sz w:val="15" />
        <w:szCs w:val="15" />
      </w:rPr>
      <w:t>Detalle de los intereses de demora</w:t>
    </w:r>
  </w:p>
  <w:p>
    <w:pPr>
      <w:pStyle w:val="Normal" />
      <w:rPr></w:rPr>
    </w:pPr>
    <w:r>
      <w:rPr></w:rPr>
    </w:r>
  </w:p>

  <w:tbl>
    <w:tblPr>
      <w:tblW w:w="5000" w:type="pct"/>
      <w:tblBorders>
        <w:top w:val="single" w:sz="4" w:space="0" w:color="000000"/>
        <w:left w:val="single" w:sz="4" w:space="0" w:color="000000"/>
        <w:bottom w:val="single" w:sz="4" w:space="0" w:color="000000"/>
        <w:right w:val="single" w:sz="4" w:space="0" w:color="000000"/>
        <w:insideH w:val="single" w:sz="4" w:space="0" w:color="000000"/>
        <w:insideV w:val="single" w:sz="4" w:space="0" w:color="000000"/>
      </w:tblBorders>
      <w:tblCellMar>
        <w:left w:w="70" w:type="dxa"/>
        <w:right w:w="70" w:type="dxa"/>
      </w:tblCellMar>
      <w:tblLook w:val="04A0" w:firstRow="1" w:lastRow="0" w:firstColumn="1" w:lastColumn="0" w:noHBand="0" w:noVBand="1"/>
    </w:tblPr>
    <w:tblGrid>
      <w:gridCol w:w="1170"/>
      <w:gridCol w:w="1171"/>
      <w:gridCol w:w="1171"/>
      <w:gridCol w:w="1171"/>
      <w:gridCol w:w="1170"/>
      <w:gridCol w:w="1170"/>
      <w:gridCol w:w="1471"/>
    </w:tblGrid>
    <w:tr w:rsidR="0070407C" w:rsidTr="00FD29B1">
      <w:trPr>
        <w:trHeight w:val="240"/>
      </w:trPr>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="688" w:type="pct"/>
          <w:tcBorders>
            <w:top w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:left w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:bottom w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:right w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          </w:tcBorders>
          <w:shd w:val="clear" w:color="auto" w:fill="auto"/>
          <w:vAlign w:val="center"/>
        </w:tcPr>
        <w:p w:rsidR="0070407C" w:rsidRDefault="004A3F28">
          <w:pPr>
            <w:jc w:val="center"/>
          </w:pPr>
          <w:r>
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
              <w:b/>
              <w:bCs/>
              <w:sz w:val="15"/>
              <w:szCs w:val="15"/>
            </w:rPr>
            <w:t>Años</w:t>
          </w:r>
        </w:p>
      </w:tc>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="689" w:type="pct"/>
          <w:tcBorders>
            <w:top w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:left w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:bottom w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:right w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          </w:tcBorders>
          <w:shd w:val="clear" w:color="auto" w:fill="auto"/>
          <w:vAlign w:val="center"/>
        </w:tcPr>
        <w:p w:rsidR="0070407C" w:rsidRDefault="004A3F28">
          <w:pPr>
            <w:jc w:val="center"/>
          </w:pPr>
          <w:r>
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
              <w:b/>
              <w:bCs/>
              <w:sz w:val="15"/>
              <w:szCs w:val="15"/>
            </w:rPr>
            <w:t>Deuda</w:t>
          </w:r>
        </w:p>
      </w:tc>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="689" w:type="pct"/>
          <w:tcBorders>
            <w:top w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:left w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:bottom w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:right w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          </w:tcBorders>
          <w:shd w:val="clear" w:color="auto" w:fill="auto"/>
          <w:vAlign w:val="center"/>
        </w:tcPr>
        <w:p w:rsidR="0070407C" w:rsidRDefault="004A3F28">
          <w:pPr>
            <w:jc w:val="center"/>
          </w:pPr>
          <w:r>
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
              <w:b/>
              <w:bCs/>
              <w:sz w:val="15"/>
              <w:szCs w:val="15"/>
            </w:rPr>
            <w:t>Inicio</w:t>
          </w:r>
        </w:p>
      </w:tc>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="689" w:type="pct"/>
          <w:tcBorders>
            <w:top w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:left w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:bottom w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:right w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          </w:tcBorders>
          <w:shd w:val="clear" w:color="auto" w:fill="auto"/>
          <w:vAlign w:val="center"/>
        </w:tcPr>
        <w:p w:rsidR="0070407C" w:rsidRDefault="004A3F28">
          <w:pPr>
            <w:jc w:val="center"/>
          </w:pPr>
          <w:r>
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
              <w:b/>
              <w:bCs/>
              <w:sz w:val="15"/>
              <w:szCs w:val="15"/>
            </w:rPr>
            <w:t>Fin</w:t>
          </w:r>
        </w:p>
      </w:tc>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="689" w:type="pct"/>
          <w:tcBorders>
            <w:top w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:left w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:bottom w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:right w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          </w:tcBorders>
          <w:shd w:val="clear" w:color="auto" w:fill="auto"/>
          <w:vAlign w:val="center"/>
        </w:tcPr>
        <w:p w:rsidR="0070407C" w:rsidRDefault="004A3F28">
          <w:pPr>
            <w:jc w:val="center"/>
          </w:pPr>
          <w:r>
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
              <w:b/>
              <w:bCs/>
              <w:sz w:val="15"/>
              <w:szCs w:val="15"/>
            </w:rPr>
            <w:t>Nº  días</w:t>
          </w:r>
        </w:p>
      </w:tc>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="689" w:type="pct"/>
          <w:tcBorders>
            <w:top w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:left w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:bottom w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:right w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          </w:tcBorders>
          <w:shd w:val="clear" w:color="auto" w:fill="auto"/>
          <w:vAlign w:val="center"/>
        </w:tcPr>
        <w:p w:rsidR="0070407C" w:rsidRDefault="004A3F28">
          <w:pPr>
            <w:jc w:val="center"/>
          </w:pPr>
          <w:r>
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
              <w:b/>
              <w:bCs/>
              <w:sz w:val="15"/>
              <w:szCs w:val="15"/>
            </w:rPr>
            <w:t>T/i</w:t>
          </w:r>
        </w:p>
      </w:tc>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="866" w:type="pct"/>
          <w:tcBorders>
            <w:top w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:left w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:bottom w:val="single" w:sz="4" w:space="0" w:color="000000"/>
            <w:right w:val="single" w:sz="4" w:space="0" w:color="000000"/>
          </w:tcBorders>
          <w:shd w:val="clear" w:color="auto" w:fill="auto"/>
          <w:vAlign w:val="center"/>
        </w:tcPr>
        <w:p w:rsidR="0070407C" w:rsidRDefault="004A3F28">
          <w:pPr>
            <w:jc w:val="center"/>
          </w:pPr>
          <w:r>
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
              <w:b/>
              <w:bCs/>
              <w:sz w:val="15"/>
              <w:szCs w:val="15"/>
            </w:rPr>
            <w:t>Interés*</w:t>
          </w:r>
        </w:p>
      </w:tc>
    </w:tr>

    ${detailLines}
  </w:tbl>
`
}

export {makeI5InterestsTable}