import React from 'react'
import { EditingIBox, IBoxRow, IBoxIconedText } from 'cli/ui/components/ibox'
import TaxPayerDetailsNotesForm from './TaxPayerDetailsNotesForm'

const TaxPayerDetailsNotes = ({taxPayer, onChange, onSave}) => {
  const empty= ! taxPayer.remarks
  return (
    <>
      <EditingIBox title="Observaciones"
                   empty={empty}
                   fitHeight
                   permissionCode="tax_payer_edit"
                   resume={() => 
                      <IBoxRow center >
                        {empty
                        ? "Sin observaciones"
                        : <>
                            <IBoxIconedText icon="bubble"
                                            text={taxPayer.remarks}/>
                          </>
                        }
                      </IBoxRow>
                   }
                   form={(gotoResume) => 
                      <TaxPayerDetailsNotesForm taxPayer={taxPayer}  
                                               onChange={onChange}
                                               onSave ={() => {onSave(); gotoResume()}}/>
                  }>
      </EditingIBox>
    </>
  )
}

export default TaxPayerDetailsNotes
