import React, {useState, useCallback} from 'react'


// Components
import Page            from 'cli/ui/pages/layout/Page'
import {Row, Col}      from 'reactstrap'
import {LoadingBig}    from 'cli/ui/components/loading'
import ProcessesFilter from 'cli/ui/fragments/process/filter/ProcessesFilter'
import Processes       from 'cli/ui/fragments/process/list/Processes'
import { IBox, IBoxRow, IBoxIconedText } from 'cli/ui/components/ibox'
import withContext from 'cli/context/withContext'

const ProcessSearch = ({fetcher}) => {
  
  const [processes, setProcesses]= useState([])
  const [filterMode, setFilterMode]= useState('filter')
  const [dataLoaded, setDataLoaded]= useState(true)
  const [dataEmpty, setDataEmpty]= useState(false)

  const fetchProcesses = useCallback((filter) => {
    const _fetchProcesses = async () => {      
      setDataLoaded(false)
      setDataEmpty(false)
      
      const nFilter= {
        ...filter
      }
      const res = await fetcher.get('/api/process/search_processes', nFilter)
      const nProcesses = res.data
      
      setProcesses(nProcesses)
      setDataLoaded(true)
      if (nProcesses.length==0) {
        setDataEmpty(true)
      }
    }

    _fetchProcesses()

  }, [fetcher])


  const handleSearchProcesses = useCallback((filter) => {
    setFilterMode('resume')
    fetchProcesses(filter)
  }, [fetchProcesses])

  
  return (
    <Page breads = {[['/', 'Inicio'], ['/taxes', 'Tributos'], 'Búsqueda']}
          title  = {`Búsqueda de inspecciones`}
          actions= {undefined}>
      <Row>
        <Col xs="12">
            <ProcessesFilter filterMode   = {filterMode}
                             onToggleMode = {(mode) => setFilterMode(mode)}
                             onFilter     = {(filter) => handleSearchProcesses(filter)}
                             hiddenFields = {[]}/>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
             {!dataLoaded
              ? <LoadingBig/>  

              : processes.length>0
                ? <Processes   processes = {processes}/>
                : dataEmpty
                  ? <IBox>
                      <IBoxRow center>
                        <IBoxIconedText icon="empty" text="No hay expedientes con los parámetros buscados"/>
                      </IBoxRow>
                    </IBox>
                  : null
            }
        </Col>       
      </Row>
    </Page>
  )
}



export default withContext(ProcessSearch)
