import { useCallback } from 'react'

const showAlert = (message, type) => {
  alert(message)
}

const useAlert = () => { 
  const showAlertCb = useCallback((message, type) => {
    showAlert(message, type)
  }, [])
  return showAlertCb
}

export default useAlert