import React      from 'react'
import Icon from 'cli/ui/components/icon'
import { Button } from 'reactstrap';

const ButtonPair = ({onSave, onCancel, colors, icons, labels, disabled, children}) =>

  <div className="afi-button-pair">
    {onCancel!=undefined
      ? <Button color   = {colors ? colors[0] : 'secondary'}
                onClick = {(ev) => onCancel(ev)}
                disabled= {disabled != undefined 
                           ? Array.isArray(disabled) 
                             ? disabled[0]
                             : false
                           : false}>
          <Icon icon  = {icons ? icons[0] : 'ban'} inline={true}/>
          {labels ? labels[0] : 'Cancelar'}
        </Button>
      : null
    }
    {onSave!=undefined
      ? <Button color   = {colors ? colors[1] : 'primary'}
              onClick = {(ev) => onSave(ev)}
              disabled= {disabled != undefined 
                ? Array.isArray(disabled) 
                  ? disabled[1]
                  : disabled
                : false}>
          <Icon icon  = {icons ? icons[1] : 'save'} inline={true}/>
          {labels ? labels[1] : 'Guardar'}
        </Button>
      : null
    }
    {children}
  </div>



export default ButtonPair

        
        