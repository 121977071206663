import React from 'react'
import { LoadingBig } from 'cli/ui/components/loading'

const AELicenseImportStep2 = () => 
  <LoadingBig/>

export default AELicenseImportStep2
        
        
        
        
