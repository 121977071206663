import React from 'react'
import {FForm, FInputText} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'

const RoleDetailsForm = ({ role, existingNames, onChange, onSave, onCancel}) =>

  <FForm  onCancel     = {role.id==undefined ? onCancel : undefined}
          onSave       = {onSave}>
          <>
            <FInputText name        = "name"
                        label       = "Nombre corto"
                        icon        = {<Icon icon={"shield"}/>}
                        value       = {role.name}
                        checkValue  = {(v) => existingNames.map((n) => n.toLowerCase()).indexOf(v.toLowerCase())==-1}
                        required    = {true}
                        feedback    = {'Inserta un nombre corto que no esté ya en uso'}
                        onChange    = {(v) => onChange('name', v)}
            />
            <FInputText name        = "description"
                        label       = "Descripción"
                        icon        = {<Icon icon={"shield"}/>}
                        value       = {role.description}
                        required    = {true}
                        feedback    = {'Descripcción'}
                        onChange    = {(v) => onChange('description', v)}
            />
            </>
  </FForm>


export default RoleDetailsForm




