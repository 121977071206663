import React, {useState, useEffect} from 'react'
//import {asDecimal} from 'farrapa/numbers'
import {/*FInputSelect, FInputFloat,*/ FInputTextArea} from 'formiga-reactstrap'
import {Row, Col} from 'reactstrap'
import TaxHeaderFields from'../common/TaxHeaderFields'
import TaxHeaderFieldsRow from'../common/TaxHeaderFieldsRow'
import TaxInputCheckbox from '../common/TaxInputCheckbox' 
import {uvl} from 'farrapa/commons'

import {PENALTY_19_RANK_RULE /*, PENALTY_19_RANK_RULE_NAMES,
PENALTY_INFRACTION_LEVEL, PENALTY_INFRACTION_LEVEL_NAMES*/} from 'bl/ns/process/penalty'


const ProcessPenalty19Fields = ({process, /*proposedValue, */ onChangeProcess, canEdit}) => {
  const [requiredValue, setRequiredValue]= useState(false)

  useEffect(() => {
    let toRequired= false
    if (   (process.penalty_19_rank_rule!=PENALTY_19_RANK_RULE.REPETITIVE_INFRACTION)
        && (process.penalty_19_rank_rule!=PENALTY_19_RANK_RULE.ECONOMICAL_LOSS)) {
      toRequired= true
    }
    if (toRequired!=requiredValue) {
      setRequiredValue(toRequired)
    }
    
  }, [requiredValue, process.penalty_19_rank_rule])

  const handleSave= (n, v, c) => {
    onChangeProcess({[n]: v}, c)
  }

  return (
    <>
      <Row>
        <Col md="12" lg= "4">
          <TaxHeaderFields title="Reducción">
            <TaxHeaderFieldsRow>
            <TaxInputCheckbox 
                 id         = "penalty_19_reduct_agreed"
                 name       = "penalty_19_reduct_agreed"
                 label      = "Conformidad"
                 value      = {uvl(process.penalty_19_reduct_agreed, uvl(process.minutes_agreed, true))}
                 onChange   = {(v, c) => handleSave('penalty_19_reduct_agreed', v, c)}
                 readOnly   = {!canEdit}
             />  

              <TaxInputCheckbox 
                  id         = "penalty_19_reduct_payment"
                  name       = "penalty_19_reduct_payment"
                  label      = "Ingreso voluntario"
                  value      = {uvl(process.penalty_19_reduct_payment, true)}
                  onChange   = {(v, c) => handleSave('penalty_19_reduct_payment', v, c)}
                  readOnly   = {!canEdit}
              />            

            </TaxHeaderFieldsRow>
          </TaxHeaderFields>
        </Col>
        {/*
        <Col md="12" lg= "5">
          {process.penalty_19.infraction_level==PENALTY_INFRACTION_LEVEL.BAD
           ?  <TaxHeaderFields title="Criterio graduación">
                <TaxHeaderFieldsRow>
                  <FInputSelect 
                    options     = {PENALTY_19_RANK_RULE_NAMES}
                    name        = 'penalty_19_rank_rule'
                    value       = {process.penalty_19_rank_rule}
                    onChange    = {(v, c) => handleSave('penalty_19_rank_rule', v, c)}
                    feedback    = {'Seleccione un criterio}
                    clearable   = {true}
                    required    = {true}
                    autocomplete= {"off"}
                    label       = {undefined}
                    icon        = {'Criterio'}
                    inline
                    readOnly   = {!canEdit}
                  />
                </TaxHeaderFieldsRow>
                <TaxHeaderFieldsRow>
                  <>
                    {process.penalty_19_rank_rule==PENALTY_19_RANK_RULE.REPETITIVE_INFRACTION
                      ? 
                        <FInputSelect 
                          options     = {PENALTY_INFRACTION_LEVEL_NAMES}
                          name        = 'penalty_19_rank_rule_level'
                          value       = {process.penalty_19_rank_rule_level}
                          onChange    = {(v, c) => handleSave('penalty_19_rank_rule_level', v, c)}
                          feedback    = {'Seleccione una calificación'}
                          clearable   = {true}
                          required    = {true}
                          autocomplete= {"off"}
                          label       = {undefined}
                          icon        = {"Cualificación"}
                          formGroupStyle= {{verticalAlign: "middle"}}
                          inputStyle  = {{maxWidth: "130px"}}
                          inline
                          readOnly   = {!canEdit}
                        />        
                      : null
                    }
                    {process.penalty_19_rank_rule==PENALTY_19_RANK_RULE.ECONOMICAL_LOSS
                      ?
                        <FInputFloat
                          name        = 'penalty_19_rank_rule_loss'
                          value       = {process.penalty_19_rank_rule_loss}
                          onChange    = {(v, c) => handleSave('penalty_19_rank_rule_loss', parseFloat(v), c)}
                          feedback    = {'Indicar %'}
                          required    = {true}
                          autocomplete= {"off"}
                          label       = {undefined}
                          icon        = {"% Perjuicio"}
                          decimalSign= {","}
                          formGroupStyle= {{verticalAlign: "middle"}}
                          inputStyle  = {{maxWidth: "80px"}}
                          max         = {100}
                          inline
                          readOnly   = {!canEdit}

                        />            
                      : null
                    }          
                    {proposedValue>0
                    ? <div style={{display: "inline-block", color: "gray", fontSize: "1.2em", verticalAlign: "middle"}}>
                        {`Criterio propuesto: ${asDecimal(proposedValue, 0)}%`}
                      </div>
                    : null}
                  </>
                </TaxHeaderFieldsRow>
              </TaxHeaderFields>
           : null}
        </Col>
        */}



        <Col md="12" lg= "3">
          <TaxHeaderFields title="Observaciones">
            <TaxHeaderFieldsRow>
              <FInputTextArea 
                      name       = "penalty_19_remarks"
                      icon       = {false}
                      label      = {undefined}
                      value      = {process.penalty_19_remarks || ''}
                      onChange   = {(v, c) => handleSave('penalty_19_remarks', v, c)}
                      formGroupStyle = {{width: "100%"}}
                      inline
                      readOnly   = {!canEdit}/>
            </TaxHeaderFieldsRow>
          </TaxHeaderFields>
        </Col>               
      </Row>
    </>
  )
}

export default ProcessPenalty19Fields