import React, {useState} from 'react'

import ProcessDocuAdd from './ProcessDocuAdd'
import ProcessDocuList from './ProcessDocuList'
import { ActionAdd } from 'cli/ui/components/buttons/actions'

const ProcessDocu = ({ process, onOpen, onSave, onRemove, onDownload, canEdit}) => {
  const [adding, setAdding]= useState(false)

  const handleSave = (docu) => {
    onSave(docu)
    setAdding(false)
  }

  return (
    <div className="proc-docu"> 
      {adding 
       ? <div className="proc-docu-adding"> 
          <ProcessDocuAdd onCancel={() => setAdding(false)}
                            onSave={(docu) => handleSave(docu)}
                            onDownload={(docu) => onDownload(docu)}/>
         </div>
       : <div className="proc-docu-showing"> 
          <div className="proc-docu-actions">
            {canEdit
             ? 
              <ActionAdd onClick={() => setAdding(! adding)}
                          label="Añadir un documento"/>
             : null}
          </div>
          {process.docus.length==0
          ? <div className="proc-docu-nodocs">
              No hay documentos
            </div>
          : <div className="proc-docu-list">
              <ProcessDocuList process = {process}
                                  onOpen  = {(docid) => onOpen(docid)}
                                  onRemove= {(docid) => onRemove(docid)}/>
            </div>
          }
        </div>
       }
    </div>
  )
}

export default ProcessDocu
