import { range }    from 'farrapa/iter'
import { collSort, collTotalBy } from 'farrapa/collections'

import ProcessI5TaxDetCalc from './ProcessI5TaxDet'

class ProcessI5TaxCalc  {

  id             = undefined
  process_id     = undefined  // process.id
  tax_rate       = 1.5        // process.ordinance_tax_data.tax_rate
  earnings_label = 'Ingresos'
  expenses_label = 'Gastos'

  _details       = []

  constructor(obj, process) {
    this.process= process
    this.update(obj)
  }

  update(obj) {
    if (typeof obj == 'object') {
      Object.keys(this)
            .filter((k) => Object.prototype.hasOwnProperty.call(obj, k))
            .filter((k) => ['details'].indexOf(k)==-1)
            .map((k) => {
              this[k]= obj[k]
            })
      this._details= obj.details || []
    }

    if (this.process.id!=undefined)
      this.process_id = this.process.id
    if (this.process.ordinance_tax_data != undefined && this.process.ordinance_tax_data.tax_rate != undefined)
      this.tax_rate = this.process.ordinance_tax_data.tax_rate
  }


  get ready_for_interests() {
    const miss_from= (isNaN(parseInt(this.process.ordinance_tax_data.liq_limit_day)) || isNaN(parseInt(this.process.ordinance_tax_data.liq_limit_month)))
    const miss_to = isNaN(parseInt(this.process.end_liq_date))
    return {
      ok: ! (miss_from || miss_to),
      missing: {
        from: miss_from,
        to: miss_to
      }
    }
  }

  get ready() {
    return this.ready_for_interests.ok 
  }


  get details() {
    let details = []

    range(this.process.year_from, this.process.year_to).map((year) => {
      const dat = this._details.find((d) => d.year==year) || {year: year}
      const det = new ProcessI5TaxDetCalc(this, dat)
      details.push(det)
    })

    return collSort(details, 'year')
  }

  get earnings() {
    return collTotalBy(this.details, 'earnings')
  }
  get expenses() {
    return collTotalBy(this.details, 'expenses')
  }
  get checked_taxbase() {
    return collTotalBy(this.details, 'checked_taxbase')
  }
  get declared_taxbase() {
    return collTotalBy(this.details, 'declared_taxbase')
  }
  get difference() {
    return collTotalBy(this.details, 'difference')
  }
  get fees() {
    return collTotalBy(this.details, 'fee')
  }
  get interests() {
    return collTotalBy(this.details, 'interest')
  }
  get total() {
    return this.fees + this.interests
  }


  forDocx() {
    return {
      ready_for_interests: this.ready_for_interests.ok,
      id                 : this.id,
      tax_rate           : this.tax_rate,
      earnings_label     : this.earnings_label,
      expenses_label     : this.expenses_label,
      earnings           : this.earnings,
      expenses           : this.expenses,
      declared_taxbase   : this.declared_taxbase,
      fees               : this.fees,
      interests          : this.interests,
      total              : this.total,
      details            : this.details.map((d) => d.forDocx())
    }
  }  
  
  toJson() {
    return {
      id             : this.id             ,
      process_id     : this.process_id     ,
      tax_rate       : this.tax_rate       ,
      earnings_label : this.earnings_label ,
      expenses_label : this.expenses_label ,
      details        : this.details.map((d) => d.toJson())
    }
  }  

  export() {
    return {
      ...this.toJson(),
      ready_for_interests: this.ready_for_interests,
      ready              : this.ready,
      earnings           : this.earnings,
      expenses           : this.expenses,
      declared_taxbase   : this.declared_taxbase,
      fees               : this.fees,
      interests          : this.interests,
      total              : this.total
    }
  }
}


export default ProcessI5TaxCalc


