import React, {useState, useEffect} from 'react'
import {FForm, FInputEpoch} from 'formiga-reactstrap'
import {SetArgumentsDate} from './parts/actions'


const EventArgumentsDateForm = ({subStep, onChange}) => {
  const [argumentsDate, setArgumentsDate]= useState(subStep.arguments_date)
  
  useEffect(() => {
    setArgumentsDate(subStep.arguments_date)
  }, [subStep])

  return (
    <FForm  inline
            renderButtons={(valid) => 
              <SetArgumentsDate onClick={() => onChange(argumentsDate)} disabled={!valid}/>
            }>
                <FInputEpoch   name       = "arguments_date"
                              value      = {argumentsDate}
                              onChange   = {(v) => setArgumentsDate(v)}
                              icon       = {"Fecha de alegaciones"}
                              required   = {true}
                              feedback   = {' '}
                              locale     = 'es'/>
    </FForm>
  )
}

export default EventArgumentsDateForm
