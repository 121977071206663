import React, {useState, useEffect} from 'react'
import {intre_pretty_short} from 'intre'
import {collSort} from 'farrapa/collections'
import {TimelineEvent, TimelineEventInfo, TimelineEventRight} from 'cli/ui/components/timeline'
import EventDocForm from './EventDocForm'
import FileMissings from './parts/FileMissings'
import {DATA_SOURCE} from 'docs/mailing/data/data_source'


const _getDocListAndRequiredFields = (process, docs, subStep, processDoc, processStepId) => {
  const thisDoc= processDoc===undefined ? subStep.doc : processDoc

  const docList= process.filterDocVersionsForStep(docs, subStep, thisDoc.id)

  const allRequiredFields= []
  docList.map((v) => {
    if (v.required_fields) {
      v.required_fields.map((f) => {
        if (allRequiredFields.indexOf(f)==-1) {
          allRequiredFields.push(f)
        }
      })
    }      
  })  

  let allMissingFields= []
  allRequiredFields.map((f) => {
    const check= DATA_SOURCE[f]['check']
    if (check) {
      const scope= DATA_SOURCE[f]['scope']
      
      if (scope) {
        allMissingFields.push({
          'name': f,
          'scope': scope
        })          
      }
    }
  })
  allMissingFields= collSort(allMissingFields, 'scope')

  const pDocx= process.forDocx(processStepId, thisDoc.id)
  //const tDocx= process.tax.forDocx()

  let requiredOutterFields= []
  let requiredDocFields= []
  allMissingFields.map((f) => {
    if (f.scope=='file') {
      requiredDocFields.push(f.name)
    } else {
      const check= DATA_SOURCE[f.name]['check']
      //const v= check(pDocx, thisDoc.file, tDocx)
      const v= check(pDocx)
      if (v===false) {
        requiredOutterFields.push(f)
      }
    }
  })


  return {
    thisDoc,
    docList, 
    requiredOutterFields, 
    requiredDocFields
  }
}


const EventDoc = ({process, processStepId, subStep, processDoc, agents, docs, onPrint, canPrint}) => {
  
  const [state, setState] = useState(_getDocListAndRequiredFields(process, docs, subStep, processDoc, processStepId))

  useEffect(() => {
    setState(_getDocListAndRequiredFields(process, docs, subStep, processDoc, processStepId))
  }, [process, docs, subStep, processDoc, processStepId])


  return (
      <TimelineEvent key      = {`process_inspect_${state.thisDoc.id}_print_doc`}
                     legend   = {{icon: "file-word", 
                                 color: state.thisDoc.print_status=='printed'
                                        ? 'green'
                                        : 'gray'}}
                     date     = {state.thisDoc.file!=undefined ? state.thisDoc.file.last_print_at : undefined}
                     isLast   = {subStep.done===false}
                     >
          

        <TimelineEventInfo>
          <div>
            <b>{state.thisDoc.name}</b>
          </div>
          {state.thisDoc.print_status=='printed'
          ? <div>
              <i style={{color: "gray"}}>{`Impreso el ${intre_pretty_short(state.thisDoc.file.last_print_at)} por ${state.thisDoc.file.last_print_by_name}`}</i>
            </div>
          : null}
          {state.requiredOutterFields.length>0
          ? <div>
              <FileMissings process={process} 
                            missingFields={state.requiredOutterFields}/>
            </div>
          : null}
        </TimelineEventInfo>
        <TimelineEventRight>
          {Object.keys(state.docList).length==0
          ? <i style={{color: "gray"}}>
              {`No hay documentos para ${state.thisDoc.name}. `}
              <a href={`/docs?taxCode=${process.tax_code}&thisDoc=${state.thisDoc.id}`}>{"Has de versionar alguno"}</a>
              {" para poder imprimirlo."}
            </i>
          : <>
             { (canPrint && state.requiredDocFields)
              ? 
                <EventDocForm 
                            process      = {process}
                            file         = {state.thisDoc.file}
                            required     = {state.requiredDocFields}
                            agents       = {agents}
                            docList      = {state.docList}
                            processStepId= {processStepId}
                            processDocId = {state.thisDoc.id}
                            onPrint      = {(file, docId) => onPrint(file, docId)}
                            canPrint      = {canPrint}/> 
              : null}
            </>
          }                 
        </TimelineEventRight>

      </TimelineEvent>
  )


}

export default EventDoc