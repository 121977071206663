import React from 'react'
import { ActionDetails, ActionRemove, ActionHistory } from 'cli/ui/components/buttons/actions'
import NiceList from 'cli/ui/components/nice_list'
import { HyperEdition, HyperCouncil, HyperOrdinance } from 'cli/ui/components/hyper'
import ProcessListProgressDetail from 'cli/ui/fragments/process/fields/progress/multi/detail/ProcessListProgressDetail'
import {uvl} from 'farrapa/commons'
import { IfPermission } from 'cli/context/IfPermission'

const makeKey= (edition) =>
  `edition_list_row_${edition.id}`


const EditionsList = ({ taxCode, editions, showHistorical, onRemove, onHistorical  }) => {

  const editionFields= [
    /* eslint react/display-name:0 */
    {
      label: "Tirada",
      className: "main", 
      value: (edition) => edition.name,
      render: (edition) =>
        <HyperEdition taxCode={taxCode} key={`edition_list_row_${edition.id}_id`} id={edition.id} name={edition.name}/>
    }, 
    {
      label: "Ayuntamiento", 
      value: (edition) => edition.council_name,
      render: (edition) =>
        <HyperCouncil key={`edition_list_row_${edition.id}_council`} id={edition.council_id} name={edition.council_name}/>
  
    },
    {
      label: "Ordenanza", 
      value: (edition) => edition.ordinance_name,
      render: (edition) =>
        <HyperOrdinance taxCode={taxCode} key={`edition_list_row_${edition.id}_ordinance`} id={edition.ordinance_id} name={edition.ordinance_name} councilId={edition.council_id}/>
  
    },
    {
      label: "Inspección", 
      className: "center", 
      render: (edition) =>
        <ProcessListProgressDetail processes={edition.processes} what={'inspect'}/>
    },
    {
      label: "Sanción 203", 
      className: "center", 
      render: (edition) =>
        <ProcessListProgressDetail processes={edition.processes} what={'penalty_20'}/>
    },
  
    {
      label: "Sanción 191/192", 
      className: "center", 
      render: (edition) =>
        <ProcessListProgressDetail processes={edition.processes} what={'penalty_19'}/>
    },
    {
      label: "Acciones", 
      className: "right", 
      render: (edition, onEvent) => 
        <>
          <ActionDetails to={`/taxes/${taxCode}/edition/${edition.id}`} />
          <IfPermission code="process_edit">
            <ActionHistory onClick={() => onEvent('historical', {id: edition.id})}
                          isHistorical={showHistorical}
                          enabled={edition.processes
                                    .filter((p) => uvl(p.historical, false)==showHistorical)
                                    .length > 0}/>
            <ActionRemove onClick={() => onEvent('remove', {id: edition.id})}
                          enabled={edition.processes.length == 0}/>
          </IfPermission>
        </>
    }
  ]

  return (
    <NiceList fields     = {editionFields}
              initialSort= {[0, "asc"]}
              data       = {editions}
              makeKey    = {makeKey}
              onEvent    = {(ev, params) => {
                if (ev=='remove') {
                  onRemove(params.id)
                }
                if (ev=='historical') {
                  onHistorical(params.id)
                }                
              }}
              transparent = {true}
    />
  )
}

export default EditionsList
