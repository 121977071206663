
import {toPrice, toDecimal, sort, dateShort} from '../../converters'

function makeIAEInterestsRow(detail) {
  return `
  <w:tr w:rsidR="00D05A88" w:rsidRPr="00D05A88" w14:paraId="3FA1D64B" w14:textId="77777777" w:rsidTr="00D05A88">
    <w:trPr>
      <w:trHeight w:val="270" />
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="800" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="nil" />
          <w:left w:val="single" w:sz="8" w:space="0" w:color="auto" />
          <w:bottom w:val="single" w:sz="8" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="8" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="000000" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="center" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="5D0117A3" w14:textId="77777777" w:rsidR="00D05A88" w:rsidRPr="00D05A88" w:rsidRDefault="00D05A88"
        w:rsidP="00D05A88">
        <w:pPr>
          <w:suppressAutoHyphens w:val="0" />
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00D05A88">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>${detail.year}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1440" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="nil" />
          <w:left w:val="nil" />
          <w:bottom w:val="single" w:sz="8" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="8" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="000000" w:fill="FFFFFF" />
        <w:vAlign w:val="center" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="0DF4BFF4" w14:textId="77777777" w:rsidR="00D05A88" w:rsidRPr="00D05A88" w:rsidRDefault="00D05A88"
        w:rsidP="00D05A88">
        <w:pPr>
          <w:suppressAutoHyphens w:val="0" />
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00D05A88">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>${toPrice(detail.total_debt_difference)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1240" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="nil" />
          <w:left w:val="nil" />
          <w:bottom w:val="single" w:sz="8" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="8" w:space="0" w:color="000000" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="000000" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="center" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="0174B129" w14:textId="77777777" w:rsidR="00D05A88" w:rsidRPr="00D05A88" w:rsidRDefault="00D05A88"
        w:rsidP="00D05A88">
        <w:pPr>
          <w:suppressAutoHyphens w:val="0" />
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00D05A88">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>${dateShort(detail.interest.from)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1400" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="nil" />
          <w:left w:val="nil" />
          <w:bottom w:val="single" w:sz="8" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="8" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="000000" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="center" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="74BD2A3D" w14:textId="77777777" w:rsidR="00D05A88" w:rsidRPr="00D05A88" w:rsidRDefault="00D05A88"
        w:rsidP="00D05A88">
        <w:pPr>
          <w:suppressAutoHyphens w:val="0" />
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00D05A88">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>${dateShort(detail.interest.to)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1340" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="nil" />
          <w:left w:val="nil" />
          <w:bottom w:val="single" w:sz="8" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="8" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="000000" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="center" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="180A56C8" w14:textId="77777777" w:rsidR="00D05A88" w:rsidRPr="00D05A88" w:rsidRDefault="00D05A88"
        w:rsidP="00D05A88">
        <w:pPr>
          <w:suppressAutoHyphens w:val="0" />
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00D05A88">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>${detail.interest.days}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1200" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="nil" />
          <w:left w:val="nil" />
          <w:bottom w:val="single" w:sz="8" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="8" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="000000" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="center" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="5FAFF5F8" w14:textId="77777777" w:rsidR="00D05A88" w:rsidRPr="00D05A88" w:rsidRDefault="00D05A88"
        w:rsidP="00D05A88">
        <w:pPr>
          <w:suppressAutoHyphens w:val="0" />
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00D05A88">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>${toDecimal(detail.interest.irate)}%</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1300" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="nil" />
          <w:left w:val="nil" />
          <w:bottom w:val="single" w:sz="8" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="8" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="000000" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="center" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="7C7C1796" w14:textId="77777777" w:rsidR="00D05A88" w:rsidRPr="00D05A88" w:rsidRDefault="00D05A88"
        w:rsidP="00D05A88">
        <w:pPr>
          <w:suppressAutoHyphens w:val="0" />
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00D05A88">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>${toPrice(detail.interest.interest)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>
  `
}


function makeIAEInterestsTable(tax) {

  const detailLines= sort(tax.details, 'year')
                     .map((detail) => makeIAEInterestsRow(detail))
                     .join('\n')  

  return `
  <w:tbl>
  <w:tblPr>
    <w:tblW w:w="8720" w:type="dxa" />
    <w:tblCellMar>
      <w:left w:w="70" w:type="dxa" />
      <w:right w:w="70" w:type="dxa" />
    </w:tblCellMar>
    <w:tblLook w:val="04A0" w:firstRow="1" w:lastRow="0" w:firstColumn="1" w:lastColumn="0" w:noHBand="0"
      w:noVBand="1" />
  </w:tblPr>
  <w:tblGrid>
    <w:gridCol w:w="800" />
    <w:gridCol w:w="1440" />
    <w:gridCol w:w="1240" />
    <w:gridCol w:w="1400" />
    <w:gridCol w:w="1340" />
    <w:gridCol w:w="1200" />
    <w:gridCol w:w="1300" />
  </w:tblGrid>
  <w:tr w:rsidR="00D05A88" w:rsidRPr="00D05A88" w14:paraId="55475C51" w14:textId="77777777" w:rsidTr="00D05A88">
    <w:trPr>
      <w:trHeight w:val="270" />
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="800" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="nil" />
          <w:left w:val="nil" />
          <w:bottom w:val="nil" />
          <w:right w:val="nil" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="000000" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="045D4AB2" w14:textId="77777777" w:rsidR="00D05A88" w:rsidRPr="00D05A88" w:rsidRDefault="00D05A88"
        w:rsidP="00D05A88">
        <w:pPr>
          <w:suppressAutoHyphens w:val="0" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00D05A88">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t></w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="7920" w:type="dxa" />
        <w:gridSpan w:val="6" />
        <w:tcBorders>
          <w:top w:val="single" w:sz="8" w:space="0" w:color="auto" />
          <w:left w:val="single" w:sz="8" w:space="0" w:color="auto" />
          <w:bottom w:val="single" w:sz="8" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="8" w:space="0" w:color="000000" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="000000" w:fill="FFFFFF" />
        <w:vAlign w:val="center" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="0A074DFB" w14:textId="77777777" w:rsidR="00D05A88" w:rsidRPr="00D05A88" w:rsidRDefault="00D05A88"
        w:rsidP="00D05A88">
        <w:pPr>
          <w:suppressAutoHyphens w:val="0" />
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00D05A88">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>Intereses de demora *</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>
  <w:tr w:rsidR="00D05A88" w:rsidRPr="00D05A88" w14:paraId="01C40ABF" w14:textId="77777777" w:rsidTr="00D05A88">
    <w:trPr>
      <w:trHeight w:val="270" />
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="800" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="single" w:sz="8" w:space="0" w:color="auto" />
          <w:left w:val="single" w:sz="8" w:space="0" w:color="auto" />
          <w:bottom w:val="single" w:sz="8" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="8" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="000000" w:fill="FFFFFF" />
        <w:vAlign w:val="center" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="2DAD69DE" w14:textId="77777777" w:rsidR="00D05A88" w:rsidRPr="00D05A88" w:rsidRDefault="00D05A88"
        w:rsidP="00D05A88">
        <w:pPr>
          <w:suppressAutoHyphens w:val="0" />
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00D05A88">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>Años</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1440" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="nil" />
          <w:left w:val="nil" />
          <w:bottom w:val="single" w:sz="8" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="8" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="000000" w:fill="FFFFFF" />
        <w:vAlign w:val="center" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="70C32656" w14:textId="77777777" w:rsidR="00D05A88" w:rsidRPr="00D05A88" w:rsidRDefault="00D05A88"
        w:rsidP="00D05A88">
        <w:pPr>
          <w:suppressAutoHyphens w:val="0" />
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00D05A88">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>Deuda tributaria</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1240" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="nil" />
          <w:left w:val="nil" />
          <w:bottom w:val="single" w:sz="8" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="8" w:space="0" w:color="000000" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="000000" w:fill="FFFFFF" />
        <w:vAlign w:val="center" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="09A2B665" w14:textId="77777777" w:rsidR="00D05A88" w:rsidRPr="00D05A88" w:rsidRDefault="00D05A88"
        w:rsidP="00D05A88">
        <w:pPr>
          <w:suppressAutoHyphens w:val="0" />
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00D05A88">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>Fecha inicial</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1400" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="nil" />
          <w:left w:val="nil" />
          <w:bottom w:val="single" w:sz="8" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="8" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="000000" w:fill="FFFFFF" />
        <w:vAlign w:val="center" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="423BEBA0" w14:textId="77777777" w:rsidR="00D05A88" w:rsidRPr="00D05A88" w:rsidRDefault="00D05A88"
        w:rsidP="00D05A88">
        <w:pPr>
          <w:suppressAutoHyphens w:val="0" />
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00D05A88">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>Fecha final</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1340" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="nil" />
          <w:left w:val="nil" />
          <w:bottom w:val="single" w:sz="8" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="8" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="000000" w:fill="FFFFFF" />
        <w:vAlign w:val="center" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="0FCB360A" w14:textId="77777777" w:rsidR="00D05A88" w:rsidRPr="00D05A88" w:rsidRDefault="00D05A88"
        w:rsidP="00D05A88">
        <w:pPr>
          <w:suppressAutoHyphens w:val="0" />
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:proofErr w:type="spellStart" />
        <w:proofErr w:type="gramStart" />
        <w:r w:rsidRPr="00D05A88">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>Nº</w:t>
        </w:r>
        <w:proofErr w:type="spellEnd" />
        <w:r w:rsidRPr="00D05A88">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t xml:space="preserve"> días</w:t>
        </w:r>
        <w:proofErr w:type="gramEnd" />
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1200" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="nil" />
          <w:left w:val="nil" />
          <w:bottom w:val="single" w:sz="8" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="8" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="000000" w:fill="FFFFFF" />
        <w:vAlign w:val="center" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="01E4DA1B" w14:textId="77777777" w:rsidR="00D05A88" w:rsidRPr="00D05A88" w:rsidRDefault="00D05A88"
        w:rsidP="00D05A88">
        <w:pPr>
          <w:suppressAutoHyphens w:val="0" />
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00D05A88">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>T/i</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1300" w:type="dxa" />
        <w:tcBorders>
          <w:top w:val="nil" />
          <w:left w:val="nil" />
          <w:bottom w:val="single" w:sz="8" w:space="0" w:color="auto" />
          <w:right w:val="single" w:sz="8" w:space="0" w:color="auto" />
        </w:tcBorders>
        <w:shd w:val="clear" w:color="000000" w:fill="FFFFFF" />
        <w:vAlign w:val="center" />
        <w:hideMark />
      </w:tcPr>
      <w:p w14:paraId="438FE153" w14:textId="77777777" w:rsidR="00D05A88" w:rsidRPr="00D05A88" w:rsidRDefault="00D05A88"
        w:rsidP="00D05A88">
        <w:pPr>
          <w:suppressAutoHyphens w:val="0" />
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r w:rsidRPr="00D05A88">
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="000000" />
            <w:sz w:val="18" />
            <w:szCs w:val="18" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>Interés*</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>
  ${detailLines}
</w:tbl>
  `
}

export {makeIAEInterestsTable}