import React from 'react'
import {intre_pretty_short} from 'intre'
import Icon from 'cli/ui/components/icon'

/*
  legend {
    icon
    size (28)
    color (black)
  }

*/

const defaultLegend= {
  icon: 'text',
  size: 28,
  color: undefined
}


const TimelineEvent = ({mini, legend, date, children, style, noIcon, isLast}) => {

  const legendProps= {...defaultLegend, ...legend}
  
  if (mini==true) {
    legendProps.size= 16
  }

  return (
    <div className={`item ${mini ? 'mini' : ''} ${isLast===true ? 'last' : ''}`}>
      {noIcon==true
        ? null
        : <div className="image">
            <div>
              <Icon {...legendProps} />
              <span>
              {date ? intre_pretty_short(date) : ''}
              </span>
            </div>
          </div>
      }
      <div className="details">
        <div className="details_content" style={ {...style||{}} }>
          {children}
        </div>
      </div>
    </div>
  )
}

export default TimelineEvent