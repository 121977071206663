import React, { useState } from 'react'


import { Link } from 'react-router-dom'

import Icon from 'cli/ui/components/icon'
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown
} from 'reactstrap';
import withContext from 'cli/context/withContext'

const AVATAR_FOLDER = '/static/img/avatars/'


const HeaderDropdown = ({logout, user, permiss}) => {
  const userInfo = user.user_info
  const [dropdownOpen, setDropdownOpen]= useState(false)

  const toggle = () => {
    setDropdownOpen(! dropdownOpen)
  }

  return (
    <Dropdown nav isOpen={dropdownOpen} toggle={() => toggle()}>
      <DropdownToggle nav>
        {userInfo.avatar != undefined
          ? <img style={{height: "35px", margin: "0 10px"}} 
                 src={AVATAR_FOLDER + userInfo.avatar} 
                 className="img-avatar" 
                 alt={user.email} />
          : null
        }
        {(permiss.can_user_read_user_email() && userInfo.unread_emails>0)
          ? <Badge color="info">{userInfo.unread_emails}</Badge>
          : null
        }
        
      </DropdownToggle>
      <DropdownMenu 
        style={{ margin: 0 }}
        modifiers= {
          {'name': 'offset', 'options': {offset: [-10, 17]}}
        }>
        <DropdownItem header tag="div" className="text-center"><strong>{user.username}</strong></DropdownItem>
        
        {/*<DropdownItem><Icon size={12} inline={true} icon="bell"/> Avisos<Badge color="info">1</Badge></DropdownItem>*/}
        {/*<DropdownItem><Icon size={12} inline={true} icon="email"/> Mails<Badge color="success">5</Badge></DropdownItem>*/}

        {permiss.can_user_read_user_email()
         ? 

          <Link className="dropdown-item" to="/user/emails">
            <Icon size={12} inline={true} icon="email" /> Mails
            {userInfo.unread_emails>0
              ? <Badge color="info">{userInfo.unread_emails}</Badge>
              : null
            }
          </Link>
         : null}

        {/*
        <DropdownItem><Icon size={12} inline={true} icon="tasks"/> Tasks<Badge color="danger">42</Badge></DropdownItem>
        <DropdownItem><Icon size={12} inline={true} icon="bubble"/> Comments<Badge color="warning">42</Badge></DropdownItem>
        <DropdownItem header tag="div" className="text-center"><strong>Settings</strong></DropdownItem>
        <DropdownItem><Icon size={12} inline={true} icon={["far","user"]}/> Profile</DropdownItem>
        */}
        <Link className="dropdown-item" to="/user/settings">
            <Icon size={12} inline={true} icon="wrench"/> Mi Cuenta
        </Link>
        {/*
        <DropdownItem><Icon size={12} inline={true} icon="dollar"/> Payments<Badge color="secondary">42</Badge></DropdownItem>
        <DropdownItem><Icon size={12} inline={true} icon="file"/> Projects<Badge color="primary">42</Badge></DropdownItem>
        <DropdownItem divider/>
        <DropdownItem><Icon size={12} inline={true} icon="shield-alt"/> Lock Account</DropdownItem>
        */}
        <DropdownItem onClick={logout}>
          <Icon size={12} inline={true} icon="password" /> Logout
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )

}

export default withContext(HeaderDropdown)
