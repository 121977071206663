import {intre_get_year} from 'intre'
import { parseNum } from 'farrapa/numbers'
import { range } from 'farrapa/iter'
import { collSort, collTotalBy } from 'farrapa/collections'
import ProcessI5TaxIntCalc from './ProcessI5TaxInt'

class I5ProcTaxDetCalc {
  
  id                   = undefined
  process_i5_tax_id    = undefined
  year                 = undefined
  earnings             = 0.0
  expenses             = 0.0
  declared_taxbase     = 0.0
  earnings_sum         = undefined
  expenses_sum         = undefined
  declared_taxbase_sum = undefined
  with_interests       = 1

  constructor(tax, detail) {
    this.tax  = tax
    this.update(detail)
  }

  update(det) {
    if (det!=undefined) {
      this.id                   = det.id                   != undefined ? det.id                              : undefined
      this.year                 = det.year                 != undefined ? det.year                            : undefined
      this.earnings             = det.earnings             != undefined ? parseNum(det.earnings)              : 0.0
      this.expenses             = det.expenses             != undefined ? parseNum(det.expenses)              : 0.0
      this.declared_taxbase     = det.declared_taxbase     != undefined ? parseNum(det.declared_taxbase)      : 0.0
      this.earnings_sum         = det.earnings_sum         != undefined ? det.earnings_sum.map((n) => parseNum(n))  : undefined
      this.expenses_sum         = det.expenses_sum         != undefined ? det.expenses_sum.map((n) => parseNum(n))  : undefined
      this.declared_taxbase_sum = det.declared_taxbase_sum != undefined ? det.declared_taxbase_sum.map((n) => parseNum(n))  : undefined
      this.with_interests       = det.with_interests       === 0 ? 0 : 1
    }
  }

  toJson() {
    return {
      id                    : this.id              ,
      process_i5_tax_id     : this.tax.id          ,
      year                  : this.year            ,
      earnings              : this.earnings        ,
      expenses              : this.expenses        ,
      declared_taxbase      : this.declared_taxbase,
      earnings_sum          : this.earnings_sum    ,
      expenses_sum          : this.expenses_sum    ,
      declared_taxbase_sum  : this.declared_taxbase_sum,
      with_interests        : this.with_interests
    }
  }

  get interests() {
    if (this.with_interests===0) {
      return []
    }

    const yfrom = this.year + 1
    let yto   = this.tax.process.year_to   
    if (this.tax.process.end_liq_date) {
      yto = intre_get_year(this.tax.process.end_liq_date)
    }
    let interests = []
    range(yfrom, yto).map((year) => {
      const interest = new ProcessI5TaxIntCalc(year, this)
      if (interest.days > 0) {
        interests.push(interest)
      }
    })
    return collSort(interests, 'year')
  }

  get interests_resume() {
    let int_map= {}
    this.interests.map(int => {
      const key= int.irate.toString()
      if (Object.keys(int_map).indexOf(key)<0) {
        int_map[key]= {
          irate   : int.irate,
          dfrom   : int.dfrom,
          dto     : int.dto,
          days    : int.days,
          interest: int.interest          
        }
      } else {
        int_map[key].dto= Math.max(int_map[key].dto, int.dto)
        int_map[key].days+= int.days
        int_map[key].interest+= int.interest
      }
    })

    return collSort(Object.values(int_map), 'dfrom')
  }


  get checked_taxbase() {
    return parseNum(this.earnings) - parseNum(this.expenses)
  }

  get difference() {
    return this.checked_taxbase - parseNum(this.declared_taxbase)
  }

  get fee() {
    return this.difference * this.tax.tax_rate / 100.0
  }
  
  get interest() {
    if (this.tax.ready_for_interests.ok)
      return collTotalBy(this.interests, 'interest')
    return 0.0
  }  

  get total() {
    return this.fee + this.interest
  }  


  forDocx() {
    return {
      id              : this.id,
      year            : this.year,
      earnings        : this.earnings,
      expenses        : this.expenses,
      declared_taxbase: this.declared_taxbase,
      checked_taxbase : this.checked_taxbase,
      difference      : this.difference,
      fee             : this.fee,
      interest        : this.interest,
      total           : this.total,
      interests       : this.interests.map((i) => i.forDocx()),
      interests_resume: this.interests_resume
    }
  }   
}

export default I5ProcTaxDetCalc