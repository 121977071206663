import React from 'react'
import Page from 'cli/ui/pages/layout/Page'
import {Row, Col} from 'reactstrap'

import UserSettingsEmail from './UserSettingsEmail'
import UserSettingsAvatar from './UserSettingsAvatar'
import UserSettingsPassword from './UserSettingsPassword'
import {IfPermission, IfNotPermission} from 'cli/context/IfPermission'

const _BREADS = [['/', 'Inicio'], ['/user', 'Mis cosas'], ['Mi cuenta']]

const UserSettings = () => {
  return (
    <Page breads={_BREADS}
          title ="Mi Cuenta">
        
      
      <IfPermission code="user_email_read">
        <Row>
          <Col xs="12" md="6">
            <UserSettingsEmail/>
          </Col>

          <Col xs="12" md="6">
            <Row>
              <Col xs="12">
                <UserSettingsAvatar/>    
              </Col>
              <Col xs="12">
                <UserSettingsPassword/>    
              </Col>
            </Row>
          </Col>
        </Row>
      </IfPermission>

      <IfNotPermission code="user_email_read">
        <Row>
          <Col xs="12" md="6">
              <UserSettingsAvatar/>    
          </Col>
          <Col xs="12" md="6">
              <UserSettingsPassword/> 
          </Col>
        </Row>
      </IfNotPermission>

    </Page>
  )
}



export default UserSettings



