const getOrdinanceInfoTO = (tax_data) => {

  return [
    {
      icon: 'calendar',
      text: '----'
    }
  ]  
}

export default getOrdinanceInfoTO