import React from 'react'
import { EditingIBox, IBoxRow, IBoxIconedText } from 'cli/ui/components/ibox'
import TaxPayerDetailsDataForm from './TaxPayerDetailsDataForm'
import { SPANISH_PROVINCES } from 'bl/ns/geo'

const TaxPayerDetailsData = ({taxPayer, onChange, onSave}) => {
  const addrStatus = 
        taxPayer.address
        ? `${taxPayer.address} (${SPANISH_PROVINCES[taxPayer.province]})`
        : SPANISH_PROVINCES[taxPayer.province]
  const emailStatus = 
        !taxPayer.email
        ? 'EMAIL pendiente de informar'
        :  <a href={`mailto:${taxPayer.email}`}>{taxPayer.email}</a>

  const urlStatus = 
        !taxPayer.url
        ? 'URL pendiente de informar'
        : taxPayer.url.indexOf('http')>0
        ? <a href={taxPayer.url}>{taxPayer.url}</a>
        : taxPayer.url.indexOf('@')>0
        ? <a href={`mailto:${taxPayer.url}`}>{taxPayer.url}</a>
        : taxPayer.url

  
  const nifStatus = 
        !taxPayer.nif
        ? 'NIF pendiente de informar'
        : taxPayer.nif

  return (
    <>
      <EditingIBox title="Info"
                   empty={false}
                   fitHeight
                   permissionCode="tax_payer_edit"
                   resume={() => 
                      <>
                        <IBoxRow center >
                          <IBoxIconedText icon="location"
                                          text={addrStatus}/>
                        </IBoxRow>
                        <IBoxRow center >
                          <IBoxIconedText icon="url"
                                          text={urlStatus}/>
                        </IBoxRow>
                        <IBoxRow center >
                          <IBoxIconedText icon="email"
                                          text={emailStatus}/>
                        </IBoxRow>
                        <IBoxRow center >
                          <IBoxIconedText icon="profile"
                                          text={nifStatus}/>
                        </IBoxRow>
                      </>
                   }
                   form={(gotoResume) => 
                      <TaxPayerDetailsDataForm taxPayer={taxPayer}  
                                               onChange={onChange}
                                               onSave ={() => {onSave(); gotoResume()}}/>
                  }>
      </EditingIBox>
    </>
  )
}

export default TaxPayerDetailsData