import withContext from 'cli/context/withContext'

const _IfPermission = ({code, permiss, children}) => {
  if (! permiss.has_permission(code)) {
    return null
  }

  return children
}

const _IfNotPermission = ({code, permiss, children}) => {
  if (permiss.has_permission(code)) {
    return null
  }

  return children
}

const IfPermission= withContext(_IfPermission)
const IfNotPermission= withContext(_IfNotPermission)

export {IfPermission, IfNotPermission}