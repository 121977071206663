import React, {useEffect, useState} from 'react'
import {intre_pretty_medium} from 'intre'
import { DOC_NAMES } from 'bl/ns/process/steps'
import { TAX_NAMES } from 'bl/ns/taxes'
import { ActionSearch }   from 'cli/ui/components/buttons/actions'
import {uvl} from 'farrapa/commons'


const getTaxCodeDescription = (taxCode) => {
  if (uvl(taxCode,'')!='') {
    return `de ${TAX_NAMES[taxCode]}`
  }
  return ''
}

const getCouncilDescription = (councilId, councils) => {
  if (uvl(councilId,'')!='') {
    return `del ayuntamiento ${councils[councilId]}`
  }
  return ''
}

const getProcessNameDescription = (processName) => {
  if (uvl(processName,'')!='') {
    return `cuyo nombre contenga <${processName}>`
  }
  return ''
}

const getTaxPayerDescription = (taxPayerName, taxPayerNif) => {
  if (uvl(taxPayerName,'')!='' && uvl(taxPayerNif,'')!='') {
    return `con contribuyentes cuyo nombre contenga <${taxPayerName}> y cuyo NIF <${taxPayerNif}>`
  }
  if (uvl(taxPayerName,'')!='') {
    return `con contribuyentes cuyo nombre contenga <${taxPayerName}>`
  }
  if (uvl(taxPayerNif,'')!='') {
    return `con contribuyentes cuyo NIF contenga <${taxPayerNif}>`
  }    
  return ''
}

const getCurrentDocDescription = (currentDoc) => {
  if (uvl(currentDoc,'')!='') {
    return `en estado ${DOC_NAMES[currentDoc]}`
  }
  return ''
}

const getTaxesSumDescription = (sumFrom, sumTo) => {
  if (uvl(sumFrom,'')!='' && uvl(sumTo,'')!='') {
    return `con tasas entre ${sumFrom} y ${sumTo}`
  }
  if (uvl(sumFrom,'')!='') {
    return `con tasas mayores o iguales que ${sumFrom}`
  }
  if (uvl(sumTo,'')!='') {
    return `con tasas menores o iguales que ${sumTo}`
  }    
  return ''
}

const getTaxesInvoicableDescription = (val) => {
  if (val==1) {
    return 'pendientes de pago'
  }
  
  return ''
}

const _getDateDescription = (name, dFrom, dTo) => {
  if (uvl(dFrom,'')!='' && uvl(dTo,'')!='') {
    return `con ${name} entre ${intre_pretty_medium(dFrom)} y ${intre_pretty_medium(dTo)}`
  }
  if (uvl(dFrom,'')!='') {
    return `con ${name} mayor o igual que ${intre_pretty_medium(dFrom)}`
  }
  if (uvl(dTo,'')!='') {
    return `con ${name} menor o igual que ${intre_pretty_medium(dTo)}`
  }    
  return ''
}

const getStartDateDescription = (dFrom, dTo) => {
  return _getDateDescription('fecha de inicio', dFrom, dTo)
}

const getEndDateDescription = (dFrom, dTo) => {
  return _getDateDescription('fecha de fin', dFrom, dTo)
}


const getAllDescriptions = (filter, filterData, hiddenFields) => {
  const descriptions= []

  if (hiddenFields.indexOf('tax_code')<0) {
    
    const d= getTaxCodeDescription(filter.tax_code)
    if (d) {
      descriptions.push(d)
    }
  }

  if (hiddenFields.indexOf('council_id')<0) {
    const d= getCouncilDescription(filter.council_id, filterData.councils)
    if (d) {
      descriptions.push(d)
    }
  }

  if (hiddenFields.indexOf('process_name')<0) {
    const d= getProcessNameDescription(filter.process_name)
    if (d) {
      descriptions.push(d)
    }
  }
    
  if (hiddenFields.indexOf('taxpayer_name')<0) {
    const d= getTaxPayerDescription(filter.taxpayer_name, filter.taxpayer_nif)
    if (d) {
      descriptions.push(d)
    }
  }

  if (hiddenFields.indexOf('current_doc')<0) {
    const d= getCurrentDocDescription(filter.current_doc)
    if (d) {
      descriptions.push(d)
    }
  }

  if (hiddenFields.indexOf('taxes_sum_from')<0) {
    const d= getTaxesSumDescription(filter.taxes_sum_from, filter.taxes_sum_to)
    if (d) {
      descriptions.push(d)
    }
  }

  if (hiddenFields.indexOf('invoicable')!=undefined) {
    const d= getTaxesInvoicableDescription(filter.invoicable)
    if (d) {
      descriptions.push(d)
    }
  }

  if (hiddenFields.indexOf('start_date_from')<0) {
    const d= getStartDateDescription(filter.start_date_from, filter.start_date_to)
    if (d) {
      descriptions.push(d)
    }
  }

  if (hiddenFields.indexOf('end_date_from')<0) {
    const d= getEndDateDescription(filter.end_date_from, filter.end_date_to)
    if (d) {
      descriptions.push(d)
    }
  }

  return descriptions
}




const ProcessesFilterResume = ({filter, filterData, hiddenFields, onToggleFilter}) => {
  const [descriptions, setDescriptions]= useState([])
  const [text, setText]= useState('Sin filtros especificados')

  useEffect(() => {
    setDescriptions(
      getAllDescriptions(filter, filterData, hiddenFields)
    )
  }, [filter, filterData, hiddenFields])
  
  useEffect(() => {
    let nText= ''
    if (descriptions.length>0) {
      nText= `Filtrando expedientes ${descriptions.join(', ')}.`
    }
    setText(nText)
  }, [descriptions])


  return (
    <div style={{textAlign: "right"}}>
      {text}
      <span style={{marginLeft: "1em"}}>
        <ActionSearch onClick={() => onToggleFilter()} label="Volver a buscar"/>
      </span>
    </div>
  )
}

export default ProcessesFilterResume