/**
 * https://medium.com/@david.gilbertson/icons-as-react-components-de3e33cb8792
 */

import React from 'react'
import IconBase from './IconBase'
import getIcon from './library'

const Icon =({icon, color, size, inline}) => {
  if (typeof icon == "string" && icon.length>0)
    return <IconBase icon={getIcon(icon)} color={color} size={size} inline={inline}/>
  return icon
}

export default Icon