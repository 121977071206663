import React from 'react'
import ProgressBar from './bar/ProgressBar'
import {asPriceWithCurrency} from 'farrapa/numbers'

const PaidProgress = ({paid, to_be_paid}) => {

  const progress = 100*paid / to_be_paid
  const color = paid>=to_be_paid
    ? 'green'
    : paid<=0
      ? 'red'
      : 'lightgreen'

  return (
      <ProgressBar right = {
                     <span>
                       <span style={{color}}>{asPriceWithCurrency(paid || 0)}</span> de <span>{asPriceWithCurrency(to_be_paid)}</span>
                     </span>
                    }
                   progress = {progress}
                   color = {color}
                   />
  )
}

    

export default PaidProgress