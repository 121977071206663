
import React, {useState, useEffect} from 'react'
import { withContext as mioloWithContext} from 'miolo/cli-react'

// const ALL_PERM= [
//   'user_email_read',
//   'calendar_read',
//   'council_read',
//   'council_edit',
//   'council_ordinance',
//   'ordinance_edit',
//   'tax_payer_read',
//   'tax_payer_edit',
//   'process_read',
//   'process_edit',
//   'process_print',
//   'income_read',
//   'income_edit',
//   'stats_read',
//   'docs_read',
//   'docs_edit',
//   'setts_read',
//   'setts_user_edit',
//   'setts_irate_edit',
//   'setts_agent_edit',
//   'setts_sector_edit',
//   'setts_calendar_edit'
//   ]

class Permissioner {
  constructor( permissions) {
    this.permissions= [] //ALL_PERM
    if (permissions) {
      this.permissions= permissions.map(p => p.code)
    }
  }
  has_permission (code) {
    if (! code) {
      return true
    }
    return this.permissions
      .filter(p => p==code)
      .length >= 1
  }
  can_user_read_user_email     () { return this.has_permission('user_email_read') }
  can_user_read_calendar       () { return this.has_permission('calendar_read') }
  can_user_read_councils       () { return this.has_permission('council_read') }
  can_user_edit_councils       () { return this.has_permission('council_edit') }
  can_user_read_ordinances     () { return this.has_permission('council_ordinance') }
  can_user_edit_ordinances     () { return this.has_permission('ordinance_edit') }
  can_user_read_taxpayers      () { return this.has_permission('tax_payer_read') }
  can_user_edit_taxpayers      () { return this.has_permission('tax_payer_edit') }
  can_user_read_processes      () { return this.has_permission('process_read') }
  can_user_edit_processes      () { return this.has_permission('process_edit') }
  can_user_print_processes     () { return this.has_permission('process_print') }
  can_user_read_income         () { return this.has_permission('income_read') }
  can_user_edit_income         () { return this.has_permission('income_edit') }
  can_user_read_stats          () { return this.has_permission('stats_read') }
  can_user_read_docs           () { return this.has_permission('docs_read') }
  can_user_edit_docs           () { return this.has_permission('docs_edit') }
  can_user_read_setts          () { return this.has_permission('setts_read') }
  can_user_edit_setts_users    () { return this.has_permission('setts_user_edit') }
  can_user_edit_setts_irates   () { return this.has_permission('setts_irate_edit') }
  can_user_edit_setts_agents   () { return this.has_permission('setts_agent_edit') }
  can_user_edit_setts_sectors  () { return this.has_permission('setts_sector_edit') }
  can_user_edit_setts_calendar () { return this.has_permission('setts_calendar_edit') }
}

const make_permissioner = (user) => {
  const permissions = user?.user_info?.permissions || []
  return new Permissioner(permissions)
}

/* eslint react/display-name:0 */
const withContext = (BaseComponent) => mioloWithContext((props) => {
  const [permissioner, setPermissioner]= useState(make_permissioner(props.user))

  useEffect(() => {
    setPermissioner(make_permissioner(props.user))
  }, [props.user])

  return (
    <BaseComponent {...props}
                   permiss={permissioner}
                   />
  );
})


export default withContext
