import React from 'react'
import {FForm, FInputText, FInputEmail, FInputSelectSearch, FInputColor} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'

const AgentDetailsForm = ({ agent, existingNames, availableUsers, onChange, onSave, onCancel}) => 

  <FForm  onCancel     = {agent.id==undefined ? onCancel : undefined}
          onSave       = {onSave}>
          <>
            <FInputText name        = "name"
                        label       = "Nombre"
                        icon        = {<Icon icon={"user"}/>}
                        value       = {agent.name}
                        required    = {true}
                        checkValue  = {(v) => existingNames.map((n) => n.toLowerCase()).indexOf(v.toLowerCase())==-1}
                        feedback    = {'Inserta un nombre válido y único'}
                        onChange    = {(v) => onChange('name', v)}
            />
            <FInputSelectSearch  
                        options     = {availableUsers}
                        name        = 'acc_user_id'
                        label       = 'Usuario'
                        icon        = {<Icon icon={"user"}/>}
                        value       = {agent.acc_user_id!=undefined ? agent.acc_user_id.toString() : ''}
                        onChange    = {(v) => onChange('acc_user_id', parseInt(v))}
                        feedback    = {"Seleciona un Usuario"}
                        clearable   = {true}
                        searchable  = {false}
                        required    = {true}
            />
            <FInputEmail name       = "email"
                        label       = "E-Mail"
                        value       = {agent.email}
                        icon        = {<Icon icon={"email"}/>}
                        feedback    = {'Inserta un email'}
                        onChange    = {(v) => onChange('email', v)}
            />
            <FInputText name        = "phone"
                        label       = "Teléfono"
                        icon        = {<Icon icon={"mobile"}/>}
                        value       = {agent.phone}
                        onChange    = {(v) => onChange('phone', v)}
            />
            <FInputColor name        = "color"
                         label       = "Color"
                         value       = {agent.color}
                         onChange    = {(v) => onChange('color', v)}
            /> 
          </>
  </FForm>

export default AgentDetailsForm




