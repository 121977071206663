import {intre_from_parts, intre_diff} from 'intre'
import {parseNum} from 'farrapa/numbers'

class ProcessI5TaxIntCalc {
  detail = {}
  
  constructor(year, detail) {
    this.year   = year
    this.detail = detail
  }

  get irates() {
    return this.detail.tax.process.irates
  }

  get irate() {
    try {
      return parseNum(this.irates.filter((i) => i.year == this.year)[0].value)
    } catch (e) {
      //console.error('*** taxes/i5/ProcessI5TaxInt Error ' + e + ' [ Year: ' + this.year + '] [Irates: ' + JSON.stringify(this.irates) + ']')
    }
    return 0.0
  }

  get missing_liq_limit() {
    if (this.year == this.detail.year + 1) {
      if (isNaN(parseInt(this.detail.tax.process.ordinance_tax_data.liq_limit_day)) || isNaN(parseInt(this.detail.tax.process.ordinance_tax_data.liq_limit_month))) {
        return true
      }
    }
    return false
  }

  get dfrom() {
    if ( (this.year == this.detail.year + 1) &&
         (! this.missing_liq_limit) ) {
      
      const day = this.detail.tax.process.ordinance_tax_data.liq_limit_day || 1
      const month = this.detail.tax.process.ordinance_tax_data.liq_limit_month || 1
      return intre_from_parts(this.year, month, day)
      
    }
    return intre_from_parts(this.year, 1, 1)
  }

  get dto() {
    const dec31 = intre_from_parts(this.year, 12, 31)

    if (this.detail.tax.process.end_liq_date) {
      return Math.min(this.detail.tax.process.end_liq_date, dec31)
    }
    return dec31
  }

  get days() {
    if (this.dfrom!=undefined && this.dto!=undefined && this.dfrom<this.dto)
      return intre_diff(this.dto, this.dfrom, 'days') + 1
    return 0
  }

  get interest() {
    return this.detail.fee * parseNum(this.days / 365) * parseNum(this.irate / 100)
  }  


  forDocx() {
    return {
      id               : this.id,
      missing_liq_limit: this.missing_liq_limit,
      year             : this.year,
      irate            : this.irate,
      dfrom            : this.dfrom,
      dto              : this.dto,
      days             : this.days,
      interest         : this.interest
    }    
  }     
}

export default ProcessI5TaxIntCalc