import React from 'react'
import { ResumeIBox, IBoxRow, IBoxIconedText } from 'cli/ui/components/ibox'
import { HyperOrdinance, HyperEdition } from 'cli/ui/components/hyper'
import getOrdinanceInfo from 'bl/util/ordinance/getOrdinanceInfo'

const CouncilDetailsPlans = ({council, plans}) => {
  const empty= plans==undefined || plans.length==0
  return (
      <ResumeIBox title="Plan de Inspección"
                   empty={empty}
                   fitHeight>
        <>
          {empty
          ? <IBoxRow center >
              {"Sin ordenanzas todavía"}
            </IBoxRow>
          : plans.map( (plan,plidx) => 
              <div key={`council_${council.id}_ordinance_info_${plan.tax_code}_${plidx}`}>
                <div style={{textAlign: "center"}}>
                  <HyperOrdinance taxCode  = {plan.tax_code}
                                  id       = {plan.ordinance_id} 
                                  name     = {plan.ordinance_name} 
                                  councilId= {council.id} 
                                  maxWidth = {'100%'}/>                    

                </div>
                              
                <IBoxRow key={`council_${council.id}_plan_${plidx}`}>

                    <div style={{flexBasis: "50%"}}>
                      {getOrdinanceInfo(plan.tax_code, plan.tax_data).map((i, oidx) => {
                        if (!i.text) {
                          return null
                        }
                        return (
                          <div style={{marginBottom: "1em"}}
                               key={`council_${council.id}_ordinance_info_${plan.tax_code}_text_${oidx}`}>
                            <IBoxIconedText icon={i.icon}
                                            text={i.text}/>
                          </div>
                        )
                      })}
                    </div>
                    <div style={{flexBasis: "50%"}}>
                      <div><b>Tiradas:</b></div>
                      {plan.editions.length==0
                      ? <div style={{color: "gray", fontStyle: "italic"}}>
                          Sin tiradas aún
                        </div>
                      :
                        plan.editions.map((edition, edidx) => 
                          <HyperEdition key={`council_${council.id}_ordinance_info_${plan.tax_code}_edition_${edidx}`}
                                        taxCode={plan.tax_code} id={edition.id} name={edition.name}/>
                        )
                      }
                    </div>

                </IBoxRow>
              </div>     
            )
          }
        </>
      </ResumeIBox>
  )
}

export default CouncilDetailsPlans

/*

                        <div style={{marginLeft: "2em"}}
                            key={`council_${council.id}_ordinance_info_${plan.tax_code}_${idx}`}>
                          <Icon icon={i.icon}></Icon>
                          <span style={{marginLeft: "0.5em"}}>{i.text}</span>
                        </div>

                        */