import React from 'react'
import ButtonBase from './ButtonBase'

const ExcelFile = ({color= 'black', enabled, onClick, width= 24}) => 
  <ButtonBase {...{color, enabled, onClick} }>
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      width ={width}
      height={width}
      viewBox={`0 0 1024 1024`}>
        
      <path d="M743.028 384h-135.292l-95.732 141.032-95.742-141.032h-135.29l162.162 242.464-182.972 269.536h251.838v-91.576h-50.156l50.156-74.994 111.396 166.57h140.444l-182.976-269.536 162.164-242.464z" />
      <path d="M917.806 229.076c-22.21-30.292-53.174-65.7-87.178-99.704s-69.412-64.964-99.704-87.178c-51.574-37.82-76.592-42.194-90.924-42.194h-496c-44.112 0-80 35.888-80 80v864c0 44.112 35.886 80 80 80h736c44.112 0 80-35.888 80-80v-624c0-14.332-4.372-39.35-42.194-90.924v0zM785.374 174.626c30.7 30.7 54.8 58.398 72.58 81.374h-153.954v-153.946c22.982 17.78 50.678 41.878 81.374 72.572v0zM896 944c0 8.672-7.328 16-16 16h-736c-8.672 0-16-7.328-16-16v-864c0-8.672 7.328-16 16-16 0 0 495.956-0.002 496 0v224c0 17.672 14.324 32 32 32h224v624z" />
    </svg>
  </ButtonBase>

export default ExcelFile
