import React, {useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import {Container} from 'reactstrap'
import Header from './Header'
import PageHeader from './PageHeader'
import Sidebar from './Sidebar'
import Footer from './Footer'

const Page = ({breads, title, actions, left, children}) => {

  const location= useLocation()

  useEffect(() => {
    if (document) {
      document.title= title
    }
  }, [title])

  return (
    <div className="app">
      <Header breads={breads}/>
      <div className="app-body">
        <Sidebar location={location}/>
        <main className="main">       
          <Container fluid className="page">          
            <PageHeader title  = {title}
                        actions= {actions}
                        left   = {left}/>
                                  
            { children }
          </Container>
        </main>
      </div>
      <Footer />
    </div>
  )

}

export default Page
