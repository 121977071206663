
import React from 'react'
import Hyper from './base'

const HyperOrdinance = ({ id, name, councilId, maxWidth, taxCode }) =>
  <Hyper icon    = "hammer2"
         to      = {`/councils/${councilId}/${taxCode}/${id}`}
         text    = {name}
         maxWidth= {maxWidth}
         permissionCode = "ordinance_read">
  </Hyper>

export default HyperOrdinance
