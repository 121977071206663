import React from 'react'
import { IBox } from 'cli/ui/components/ibox'

const ResumeIBox = ({title, empty, fitHeight, children}) => {

  return (
    <>
      <IBox narrow
            title={title}
            empty={empty}
            fitHeight={fitHeight}
            actions={undefined}>
        {children}
      </IBox>
    </>
  )
}


export default ResumeIBox
