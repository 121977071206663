import React from 'react'
import {asDecimal} from 'farrapa/numbers'
import {FInputCheckbox /*, FInputFloat*/} from 'formiga-reactstrap'
import TaxTable from '../common/TaxTable'
import {PENALTY_INFRACTION_LEVEL} from 'bl/ns/process/penalty'
import { arrayRemove } from 'farrapa/collections'


const NotApply = () =>
  <span style={{color: "gray", fontSize: "0.8em"}}>N.A.</span>

const ProcessPenalty19Table = ({process, onChangeProcess, canEdit}) => {
  
  /*const handleRuleSave= (aidx, v, c) => {
    const nRule= [...process.penalty_19_rank_rule_value||[]]
    nRule[aidx]= v
    onChangeProcess({penalty_19_rank_rule_value: nRule}, c)
  }*/

  const handleYearToggle= (year, enable) => {
    const nYears= [...process.penalty_19.tax_years]
    if (enable) {
      nYears.push(year)
    } else {
      if (nYears.length==1) {
        return
      }
      arrayRemove(nYears, year)
    }
    onChangeProcess({penalty_19_years: nYears}, true)
  }

  const payment_red= process.penalty_19_reduct_payment_value || 40    


  return (
    <TaxTable>

      <thead>
        <tr>
          <th style={{width: "50px"}}></th>
          <th style={{textAlign: "center"}}>Año</th>
          <th style={{textAlign: "center"}}>Base Imponible</th>
          <th style={{textAlign: "center"}}>Cuota</th>
          <th style={{textAlign: "center"}}>
            {process.penalty_19.infraction_level==PENALTY_INFRACTION_LEVEL.SOFT
              ? "Descuento"
              : "Tipo Aplicado"
            }
          </th>
          {/*
          {process.penalty_19.infraction_level==PENALTY_INFRACTION_LEVEL.BAD
           ? <th style={{textAlign: "center"}}>Criterio Graduación</th>
           : null}
          */}
          {/*<th style={{textAlign: "center"}}>Importe Sanción</th>*/}
          <th style={{textAlign: "center"}}>Conformidad</th>
          <th style={{textAlign: "center"}}>Ingreso voluntario</th>
          <th style={{textAlign: "center"}}>Total</th>
        </tr>
      </thead>
      <tbody>
        {process.penalty_19.amounts.map((am, aidx) => 
        
          <tr className="afi_taxes_det" key={`process_${process.id}_19_taxes_table_row_${aidx}`}>
            <td style={{verticalAlign: "middle", textAlign: "left", width: "50px"}}>
              <FInputCheckbox 
                name           = {`afi_taxes_19_det_${aidx}`}
                value          = {process.penalty_19.tax_years.indexOf(am.year)>=0}
                onChange       = {(v) => handleYearToggle(am.year, v)}
                icon           = {false}
                showValidity   = {0}
                formGroupStyle = {{marginBottom: "0", marginLeft: "-1em"}}
                readOnly       = {!canEdit}
                />
                              
            </td>            
            <td style={{verticalAlign: "middle", textAlign: "center"}}>
              {am.year}               
            </td>
            <td style={{verticalAlign: "middle", textAlign: "center"}}>
              {`${asDecimal(am.difference, 2)} €`}               
            </td>
            <td style={{verticalAlign: "middle", textAlign: "center"}}>
              {`${asDecimal(am.base, 2)} €`}               
            </td>
            <td style={{verticalAlign: "middle", textAlign: "center"}}>
              {`${am.discount * 100} %`}
            </td>
            {/*
            {process.penalty_19.infraction_level==PENALTY_INFRACTION_LEVEL.BAD
            ?  <td style={{verticalAlign: "middle", textAlign: "center"}}>
                <FInputFloat
                  name        = 'penalty_19_rank_rule_value'
                  value       = {am.rule} //{process.penalty_19_rank_rule_value!=undefined ? process.penalty_19_rank_rule_value[aidx] : 0}
                  onChange    = {(v, c) => handleRuleSave(aidx, parseFloat(v), c)}
                  showValidity= {1}
                  required    = {true}
                  autocomplete= {"off"}
                  label       = {undefined}
                  icon        = {false}
                          decimalSign= {","}
                  formGroupStyle= {{verticalAlign: "middle"}}
                  inputStyle  = {{maxWidth: "120px"}}
                  inline
                  readOnly   = {!canEdit}
                />                 
                </td>
            : null}*/}
            {/*
            <td style={{verticalAlign: "middle", textAlign: "center"}}>
              {`${asDecimal(am.raw, 2)} €`}
            </td>*/}
            <td style={{verticalAlign: "middle", textAlign: "center"}}>
              {process.penalty_19.applies_agree_discount
                ? "30%"
                : <NotApply/>}
            </td>            
            <td style={{verticalAlign: "middle", textAlign: "center"}}>
              {process.penalty_19_reduct_payment!==false
                ? `${payment_red}%`
                : <NotApply/>}
            </td>
            <td style={{color: "gray", fontSize: "1.2em", verticalAlign: "middle", textAlign: "center"}}>
            {`${asDecimal(am.total, 2)} €`}
            </td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr className="afi_taxes_det">
          <td style={{verticalAlign: "middle", textAlign: "center"}}>
          </td>
          <td style={{verticalAlign: "middle", textAlign: "center"}}>
            {"Total"}               
          </td>             
          <td style={{verticalAlign: "middle", textAlign: "center"}}>
            {`${asDecimal(process.penalty_19.amounts_sum.difference, 2)} €`}  
          </td>       
          <td style={{verticalAlign: "middle", textAlign: "center"}}>
            {`${asDecimal(process.penalty_19.amounts_sum.base, 2)} €`}               
          </td>
          <td style={{verticalAlign: "middle", textAlign: "center"}}>
            {process.penalty_19.infraction_level==PENALTY_INFRACTION_LEVEL.SOFT
              ? `50%`
              : null
            }            
          </td>
          {/*
          {process.penalty_19.infraction_level==PENALTY_INFRACTION_LEVEL.BAD
          ?  <td style={{verticalAlign: "middle", textAlign: "center"}}>                
              </td>
          : null}*/}
          {/*<td style={{verticalAlign: "middle", textAlign: "center"}}>
            {`${asDecimal(process.penalty_19.amounts_sum.raw, 2)} €`}
          </td>*/}
          <td style={{verticalAlign: "middle", textAlign: "center"}}>
            {process.penalty_19.applies_agree_discount
              ? "30%"
              : <NotApply/>}
          </td>            
          <td style={{verticalAlign: "middle", textAlign: "center"}}>
            {process.penalty_19_reduct_payment===true
              ? `${payment_red}%`
              : <NotApply/>}
          </td>
          <td style={{color: "gray", fontSize: "1.2em", verticalAlign: "middle", textAlign: "center"}}>
          {`${asDecimal(process.penalty_19.amounts_sum.total, 2)} €`}
          </td>
        </tr>
      </tfoot>      
    </TaxTable>
  )
}

export default ProcessPenalty19Table