import React from 'react'
import ActionButton from './ActionButton'

const ActionGo = ({ onClick, to, enabled, label }) =>
  <ActionButton color='black'
                label={label}
                icon='arrow-right'
                onClick={onClick}
                to={to}
                enabled={enabled != undefined ? enabled : true}>
  </ActionButton>

export default ActionGo
