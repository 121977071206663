import React from 'react'
import { FForm, FInputFile} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'

const LuLicenseImportStep1 = ({file, onUpload}) => 
    <FForm onCancel     = {undefined}
           onSave       = {undefined}>
        <FInputFile   name        = "buffer"
                      label       = {undefined}
                      value       = {file}
                      onChange    = {(v) => onUpload(v)}
                      onDownload  = {undefined}
                      accept      = ".xls,.xlsx,application/msexcel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      icon        = {<Icon icon="file-excel"/>}
                      autocomplete= "off"
                      />
    </FForm>

export default LuLicenseImportStep1
        
        
        
        
