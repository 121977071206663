import React, { Component } from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  display: PropTypes.any,
  mobile: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};


const sidebarCssClasses = [
  'sidebar-show',
  'sidebar-sm-show',
  'sidebar-md-show',
  'sidebar-lg-show',
  'sidebar-xl-show'
];

const validBreakpoints = [ 'sm', 'md', 'lg', 'xl' ]

function checkBreakpoint (breakpoint, list) {
  return list.indexOf(breakpoint) > -1
}

function toggleClasses (toggleClass, classList, force) {
  const level = classList.indexOf(toggleClass)
  const removeClassList = classList.slice(0, level)
  removeClassList.map((className) => document.body.classList.remove(className))
  if (force === true) {
    document.body.classList.add(toggleClass);
  } else if (force === false) {
    document.body.classList.remove(toggleClass);
  } else {
    document.body.classList.toggle(toggleClass);
  }
  return document.body.classList.contains(toggleClass);
}



class HeaderSidebarToggler extends Component {
  constructor(props) {
    super(props);
    this.sidebarToggle = this.sidebarToggle.bind(this);
  }

  sidebarToggle(e) {
    e.preventDefault();
    this.toggle();
  }

  toggle(force) {
    const [display, mobile] = [this.props.display || 'lg', this.props.mobile===true]
    let cssClass = sidebarCssClasses[0]
    if (!mobile && display && checkBreakpoint(display, validBreakpoints)) {
      cssClass = `sidebar-${display}-show`
    }
    toggleClasses(cssClass, sidebarCssClasses, force)
  }

  render() {
    const { className, children, ...attributes } = this.props;

    const Tag = this.props.tag || 'a'

    delete attributes.mobile
    delete attributes.display

    return (
      <Tag type="button" className={`navbar-toggler ${className}`} {...attributes} onClick={(event)=>this.sidebarToggle(event)} data-sidebar-toggler>
        {children || <span className="navbar-toggler-icon" />}
      </Tag>
    );
  }
}

HeaderSidebarToggler.propTypes = propTypes;

export default HeaderSidebarToggler;
