import React, {useState, useEffect} from 'react'

import {Row, Col} from 'reactstrap'
import { IBox, IBoxRow } from 'cli/ui/components/ibox'
import { LoadingBig } from 'cli/ui/components/loading'
import Processes       from 'cli/ui/fragments/process/list/Processes'
import withContext from 'cli/context/withContext'

const CouncilDetailsProcesses = ({fetcher, council}) => {
  const [processes, setProcesses]= useState([])
  const [loading, setLoading]= useState(0)


  useEffect(() => {
    async function fetchData() {

      setLoading(1)  
      
      const nFilter= {
        council_id: council.id
      }
      const res = await fetcher.get('/api/process/search_processes', nFilter)
      const nProcesses = res.data
      
      setProcesses(nProcesses)
      setLoading(0)

    }
    fetchData()

  }, [fetcher, council.id])

  return (
    <>
      <Row>
          <Col xs="12">
            <div>
              <h2 style={{marginTop: "2em"}}>Expedientes</h2>
            </div>    
          </Col>
      </Row>     
      <Row>
        <Col xs="12">    
          {loading==1
          ? <LoadingBig/>
          : <>
            {  processes==undefined || processes.length==0
                ? 
                  <IBox empty>           
                    <IBoxRow center>
                        {`${council.name} no tiene ningún expediente aún.`}
                    </IBoxRow>
                  </IBox>
                
                : <Processes   processes = {processes}
                              initialSort= {[1, 'asc']}
                              hiddenFields={['council', 'historic', 'remove']}/>
            }            
            </>
          }
        </Col>
      </Row>
    </>
  )
}


export default withContext(CouncilDetailsProcesses)

