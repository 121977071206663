import React from 'react'
import getProcessListCount from 'bl/util/process/getProcessListCount'
import ProcessListCount from './ProcessListCount'

const ProcessListCountThreeFlows = ({processes}) => {

  const cins= getProcessListCount(processes, 'inspect')
  const cp20= getProcessListCount(processes, 'penalty_20')
  const cp19= getProcessListCount(processes, 'penalty_19')

  return (
    <div style={{display: "flex"}}>
    {cins>0
    ? 
      <div style={{marginRight: "1em", flex: "auto"}}>
        <ProcessListCount processes={processes}
                             what = "inspect"/>
      </div>
    : null}
    {cp20 > 0 
    ? 
      <div style={{marginRight: "1em", flex: "auto"}}>
        <ProcessListCount processes={processes}
                             what = "penalty_20"/>
      </div>
    : null}
    {cp19 > 0
    ? 
      <div style={{marginRight: "1em", flex: "auto"}}>
        <ProcessListCount processes={processes}
                             what = "penalty_19"/>
      </div>
    : null}
  </div>
  )
}


export default ProcessListCountThreeFlows