import React from 'react'
import {FForm, FInputFloat} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'


const IRatesUpdate = ({year, value, onChange, onSave, onCancel}) =>

  <FForm onCancel     = {onCancel}
         onSave       = {onSave}>
          <>
            <FInputFloat name        = "value"
                          label       = {year.toString()}
                          icon        = {<Icon icon={"dollar"}/>}
                          value       = {value}
                          step        = {0.001}
                          decimalSign = ","
                          required    = {true}
                          gt          = {0}
                          feedback    = {'Pon un número aquí, máximo de 3 decimales'}
                          onChange    = {(v) => onChange( v)}
            />
          </>
  </FForm>


export default IRatesUpdate


