import React from 'react'
import ActionButton from './ActionButton'

const ActionSearch = ({ onClick, to, enabled, label }) =>
  <ActionButton color='darkcyan'
    label={label}
    icon='search'
    onClick={onClick}
    to={to}
    enabled={enabled != undefined ? enabled : true}>
  </ActionButton>

export default ActionSearch
