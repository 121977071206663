import React from 'react'

const Timeline = ({disabled, children}) => {
  return (
    <div className={`timeline ${disabled ? 'disabled' : ''}`}>
      {children}
    </div>
  )
}

export default Timeline