import React, {useState, useEffect, useRef} from 'react'
import withContext from 'cli/context/withContext'
import {intre_pretty_short_with_time} from 'intre'
import SimpleModal from 'cli/ui/components/SimpleModal'
import { FInputText } from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'
import {Button} from 'cli/ui/components/buttons'

const ProcessActionsNotesModal = ({user, show, title, notes, onClose, onRemove, onSave}) => {

  const [value, setValue]= useState('')
  const [chatsLoaded, setChatsLoaded] = useState(false)
  const containerRef = useRef()

  const contentRef = (node) => {
    if (node !== null) {
      containerRef.current= node
      setChatsLoaded(true)
    }
  }

  useEffect(() => {
    if (show && chatsLoaded) {
      autoScroll()
    }
  }, [show, chatsLoaded])

  const autoScroll = () => {
    const el= containerRef.current
    if (el!=undefined) {
      el.scrollTo(0, el.scrollHeight)
    }
  }

  const handleSave = async () => {
    await onSave(value)
    setValue('')
    autoScroll()
  }
  
  const sameUser = (note, nidx) => {
    if (nidx>=1) {
      const prevNote= notes[nidx-1]
      return prevNote.name==note.name
    }
    return false
  }


  return (
    <SimpleModal title  ={title}
                  show   ={show}
                  onClose={() => onClose()}>

      <div className="direct-chat-messages"
           ref={contentRef}>
        {notes.map((note, nidx) => {
          return (
            <div key      ={'EditionProcNotesModal_'+note.id}
                  className={"direct-chat-msg " + (note.created_by == user.id ? '' : 'right')}>
              <div className="direct-chat-info clearfix">
                {sameUser(note, nidx)
                 ? null
                 : <span className="direct-chat-name pull-left">{note.name}</span>
                }
                <span className="pull-right">
                  <span className="direct-chat-timestamp">{ intre_pretty_short_with_time(note.created_at) }</span>
                  {(note.created_by == user.id)
                    ? <button type="button" 
                              onClick={() => onRemove(note.id)}
                              className="close" aria-label="Close" style={{marginLeft: "5px", color: "#b43535"}}>
                        <span aria-hidden="true">(×)</span>
                      </button>
                    : null
                  }
                </span>
              </div>
              <div className="direct-chat-text">
                { note.msg }
              </div>
            </div>
          )
        })}
      </div>
      <div style={{display: 'flex'}}>
        <div style={{width: '100%'}}>
          <FInputText name      = "note_msg"
                      icon      = {<Icon icon='bubble2'/>}
                      value     = {value}
                      onChange  = {(v,_n) => setValue(v)}/>
        </div>
        <div style={{width: 'auto', flex: 'auto', 'textAlign' : 'right'}}>
          <Button
                      icon      = {"pencil"}
                      label     = {"Escribir"}
                      disabled  = {value==''}
                      onClick   = {() => handleSave()}
                      />
        </div>
      </div>
    </SimpleModal>
  )
}
export default withContext(ProcessActionsNotesModal)
