import React from 'react'
import {intre_pretty_short_with_from_now} from 'intre'
import NiceList from 'cli/ui/components/nice_list'
import { ActionRemove, ActionEdit, ActionRunning} from 'cli/ui/components/buttons/actions'

const userEmailFields= [
  /* eslint react/display-name:0 */
  {
    label: "Nombre",
    className: "main", 
    value: (uemail) => uemail.name,
  }, 
  {
    label: "Email",
    value: (uemail) => uemail.email,
  }, 
  {
    label: "Actualizado",
    value: (uemail) => uemail.last_fetched ? intre_pretty_short_with_from_now(uemail.last_fetched) : ''
  }, 
  {
    label: "Recibidos",
    value: (uemail) => uemail.total_fetched ? uemail.total_fetched : ''
  }, 
  {
    label: "Acciones",
    className: "right", 
    render: (uemail, onEvent) => 
      <>
        <ActionRunning onClick= {() => { onEvent('toggle', uemail.id) }} 
                        running= {uemail.enabled}
        />
        <ActionEdit    onClick= {() => { onEvent('edit', uemail.id) }} />
        <ActionRemove  onClick= {() => { onEvent('remove', uemail.id) }}/>
      </>
  }, 
]

const makeKey= (agent) =>
  `user_emails_list_row_${agent.id}`


const UserSettingsEmailList = ({ uemails, onToggle, onEdit, onRemove }) =>
  <NiceList fields     = {userEmailFields}
            initialSort= {[0, "asc"]}
            data       = {uemails}
            makeKey    = {makeKey}
            onEvent    = {(ev, params) => {
              if (ev=='toggle') {
                onToggle(params)
              } else if (ev=='edit') {
                onEdit(params)
              } else if (ev=='remove') {
                onRemove(params)
              }
            }}
            transparent
  />



export default UserSettingsEmailList



