import React from 'react'
import { FForm, FInputSelect} from 'formiga-reactstrap'
import { LU_LICENSE_FIELDS} from 'bl/ns/process/lu_license'


const LuLicenseImportStep3 = ({header, onChange}) => {

  const getAvailableFields = (fldId) => {
    const taken= []
    if (header!=undefined && header.map!=undefined) {
      Object.keys(header.map)
        .filter((k) => k!=fldId)
        .map((k) => taken.push(header.map[k]))
    }
      
    const fields= {'': ''}
    Object.keys(LU_LICENSE_FIELDS).map((t) => {
      if (taken.indexOf(t)<0) {
        fields[t]= LU_LICENSE_FIELDS[t]
      }
    })

    return fields
  }

  return (
    <FForm onCancel      = {undefined}
            onSave       = {undefined}>
        <>
          {header.fields.map((fld, idx) => 
            <FInputSelect key         = {`lu_license_import_head_pick_${idx}`}
                          name        = {fld.id}
                          options     = {getAvailableFields(fld.id)}
                          label       = {fld.label}
                          value       = {header.map[fld.id]}
                          onChange    = {(v) => onChange(fld.id, v)}
                          />
          )}
        </>
    </FForm>

  )
}




export default LuLicenseImportStep3
        
        
        
        
