import React from 'react'
import {FInputSelect} from 'formiga-reactstrap'

const eventProcessFieldsPrint = (hiddenFields, docs, selectedDocs) => {

  const getDocsForStep = (proc, flow) => {
    const pdocs= proc.filterDocVersionsUntilStep(docs, flow)
    if (! pdocs.length) {
      return []
    }

    const edocs= pdocs.map((doc) => {
      return [doc.id, doc.name]
    })

    return [
      [undefined, '',],
      ...edocs
    ]

  }

  const getSelectedDoc = (pid, what) => {
    try {
      const thisSel= selectedDocs.find(([dpid, _docs]) => dpid==pid)
      return thisSel[1][what]
    } catch {}

    return undefined
  }


  return [
    /* eslint react/display-name:0 */ 
    {
      label: "Inspección", 
      className: "right", 
      value: undefined,
      render: (p, onEvent) =>
          <FInputSelect name     = {`inspect_doc_id_for_${p.id}`}
                        options  = {getDocsForStep(p, p.inspect)}
                        value    = {getSelectedDoc(p.id, 'inspect')}
                        onChange = {(docId) =>  onEvent('sel_doc', {id: p.id, what: 'inspect', docId})}
                        icon     = {false}
                        showValidity={0}
                        inline/>

    },
    {
      label: "Sanción 203", 
      className: "right", 
      value: undefined,
      render: (p, onEvent) => 
      p.penalty_20_on===true 
        ? <FInputSelect name     = {`penalty_20_doc_id_for_${p.id}`}
                        options  = {getDocsForStep(p, p.penalty_20)}
                        value    = {getSelectedDoc(p.id, 'penalty_20')}
                        onChange = {(docId) =>  onEvent('sel_doc', {id: p.id, what: 'penalty_20', docId})}
                        icon     = {false}
                        showValidity={0}
                        inline/>        
          : null
    },
    {
      label: "Sanción 191/192", 
      className: "right", 
      value: undefined,
      render: (p, onEvent) => 
      p.penalty_19_on===true 
        ? <FInputSelect name     = {`penalty_19_doc_id_for_${p.id}`}
                        options  = {getDocsForStep(p, p.penalty_19)}
                        value    = {getSelectedDoc(p.id, 'penalty_19')}
                        onChange = {(docId) =>  onEvent('sel_doc', {id: p.id, what: 'penalty_19', docId})}
                        icon     = {false}
                        showValidity={0}
                        inline/>    
          : null
    }
  ]
}



export default eventProcessFieldsPrint








