import React from 'react'

const IBoxRow = ({children, right, center}) => {
  return (
    <div className={`ibox-row ${right===true ? 'ibox-right' : ''} ${center===true ? 'ibox-center' : ''}`}>
      <div>
        {children}
      </div>
    </div>
  )
}

export default IBoxRow