import {I5, LU, IAE, TODP, IIVTNU} from 'bl/ns/taxes'
import getOrdinanceInfoI5 from './getOrdinanceInfoI5'
import getOrdinanceInfoLU from './getOrdinanceInfoLU'
import getOrdinanceInfoAE from './getOrdinanceInfoAE'
import getOrdinanceInfoTO from './getOrdinanceInfoTO'
import getOrdinanceInfoVT from './getOrdinanceInfoVT'

const getOrdinanceInfo = (tax_code, tax_data) => {
  if (tax_code==I5) {
    return getOrdinanceInfoI5(tax_data)
  }
  
  if (tax_code==LU) {
    return getOrdinanceInfoLU(tax_data)
  }

  if (tax_code==IAE) {
    return getOrdinanceInfoAE(tax_data)
  }

  if (tax_code==TODP) {
    return getOrdinanceInfoTO(tax_data)
  }

  if (tax_code==IIVTNU) {
    return getOrdinanceInfoVT(tax_data)
  }  

  return []
}

export default getOrdinanceInfo