import React, {useState, useEffect} from 'react'
import {FForm, FInputEpoch, FInputTime} from 'formiga-reactstrap'
import {SetAppointment} from './parts/actions'


const EventAppointmentForm = ({subStep, onAppointment}) => {
  const [appointmentDate, setAppointmentDate]= useState(subStep.appointment_date)
  const [appointmentTime, setAppointmentTime]= useState(subStep.appointment_time)
  
  useEffect(() => {
    setAppointmentDate(subStep.appointment_date)
    setAppointmentTime(subStep.appointment_time)
  }, [subStep])

  return (
    <FForm  inline
            renderButtons={(valid) => 
              <SetAppointment onClick={() => onAppointment(appointmentDate, appointmentTime)} disabled={!valid}/>
            }>
              <>
                <FInputEpoch   name       = "appointment_date"
                              value      = {appointmentDate}
                              onChange   = {(v) => setAppointmentDate(v)}
                              icon       = {"Fecha de firma"}
                              required   = {true}
                              feedback   = {' '}
                              locale     = 'es'/>
                {subStep.needs_appointment_time
                ?
                  <FInputTime name       = "appointment_time"
                              value      = {appointmentTime}
                              onChange   = {(v) => setAppointmentTime(v)}
                              icon       = {"Hora de firma"}
                              required   = {true}
                              feedback   = {' '}/>               
                : null
                }
              </>
    </FForm>
  )
}

export default EventAppointmentForm
