import { parseNum } from 'farrapa/numbers'
import {PTYPE} from 'bl/ns/process/process'

class ProcessLUTaxCalc  {

  id         = undefined
  process_id = undefined  // process.id
  pem_module  = 0.0
  pem_project = 0.0
  pem         = 0.0
  expenses    = 0.0



  free_text  = ''
  solar_energy = false
  accessibility= false

  license = {}

  constructor(obj, process) {
    this.process= process
    this.update(obj)
  }

  update(obj) {
    if (typeof obj == 'object') {
      Object.keys(this)
            .filter((k) => Object.prototype.hasOwnProperty.call(obj, k))
            .map((k) => {
              this[k]= obj[k]
            })
      this.pem_module = obj.pem_module != undefined ? parseNum(obj.pem_module)  : 0.0
      this.pem_project = obj.pem_project != undefined ? parseNum(obj.pem_project)  : 0.0
      this.pem = obj.pem != undefined ? parseNum(obj.pem)  : 0.0
      this.expenses = obj.expenses != undefined ? parseNum(obj.expenses)  : 0.0
    }

    if (this.process.id!=undefined)
      this.process_id = this.process.id
  }

  get is_definitive() {
    return this.process.process_type == PTYPE.COMPLETE
  }

  get difference() {
    if (!this.is_definitive) {
      return 0
    }
    return this.expenses - this.pem
  }

  get base_without_bonifs() {
    if (this.is_definitive) {
      return this.difference
    }
    return this.pem
  }

  get base_with_bonifs() {
    let b= this.base_without_bonifs
    if (this.solar_energy) {
      b= b*0.5
    }
    if (this.accessibility) {
      b= b*0.1
    }
    return b
  }


  get icio_tax_rate() {
    try {
      return this.process.ordinance_tax_data.icio_tax_rate || 0.0
    } catch(e) {
      return 0.0
    }
  }

  get lu_tax_rate() {
    try {
      return this.process.ordinance_tax_data.lu_tax_rate || 0.0
    } catch(e) {
      return 0.0
    }
  }

  get icio_debt() {
    return this.base_with_bonifs * (this.icio_tax_rate/100)
  }
  get lu_debt() {
    return this.base_without_bonifs * (this.lu_tax_rate/100)
  }

  get ready() {
    return true
  }

  get total() {
    return this.icio_debt + this.lu_debt
  }  

  forDocx() {
    return {
      id            : this.id,
      pem_module    : this.pem_module ,
      pem_project   : this.pem_project,
      pem           : this.pem        ,
      expenses      : this.expenses   ,
      free_text     : this.free_text,
      solar_energy  : this.solar_energy,
      accessibility : this.accessibility,
      icio_tax_rate : this.icio_tax_rate,
      lu_tax_rate   : this.lu_tax_rate,
      difference    : this.difference,
      icio_debt     : this.icio_debt,
      lu_debt       : this.lu_debt,
      license       : this.license || {}
    }
  }  

  toJson() {
    return {
      id             : this.id,
      process_id     : this.process_id,
      pem_module     : this.pem_module ,
      pem_project    : this.pem_project,
      pem            : this.pem        ,
      expenses       : this.expenses   ,
      free_text      : this.free_text,
      solar_energy   : this.solar_energy,
      accessibility  : this.accessibility,
      license        : this.license
    }
  }  

  export() {
    return {
      ...this.toJson(),
      ready              : this.ready,
      icio_tax_rate      : this.icio_tax_rate,
      lu_tax_rate        : this.lu_tax_rate,
      difference         : this.difference,
      icio_debt          : this.icio_debt,
      lu_debt            : this.lu_debt,
      total              : this.total
    }
  }  
}


export default ProcessLUTaxCalc


