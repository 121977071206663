import React from 'react';
import PopupBase from './PopupBase'

const Popup = ({show, title, children, onClose, renderFooter}) => 
  <PopupBase show   ={show}
              title  ={title}
              onClose={onClose}
              renderFooter={renderFooter}>
    {children}
  </PopupBase>
          

export default Popup