import React from 'react'
import Hyper from './base'

const HyperCouncil = ({ id, name }) =>
  <Hyper bcolor  = "#e0e0d1"
         icon    = "council"
         to      = {"/councils/" + id}
         text    = {name}
         permissionCode = "council_read">
  </Hyper>

export default HyperCouncil

