import React, {useState, useEffect} from 'react'

const CLASSNAME_BY_LENGTH= [
  'one',
  'two',
  'three',
  'four',
  'five',
  'six'
]

const Tabs = ({defaultActiveTab, titles, disabled, renderPanel, onChangeTab, lightTabs}) => {
  const [innerActiveTab, setInnerActiveTab]= useState(defaultActiveTab!=undefined ? defaultActiveTab : 0)

  useEffect(() => {
    setInnerActiveTab(defaultActiveTab)
  }, [defaultActiveTab])

  const isDisabled = (index) => 
    disabled!=undefined && disabled.indexOf(index)>=0


  const isLight = (index) => {
    try {
      return lightTabs[index]
    } catch(_) {
      return false
    }
  }

  const handleTabClick = (index) => {
    if (! isDisabled(index)) {
      setInnerActiveTab(index)
      if (onChangeTab!=undefined) {
        onChangeTab(index)
      }
    } 
  }

  return (
    <>
      <div className="afi-tabs">
        <ul>
          {titles.map((title, index) => 
            <li key={`afi-tabs-tab-$${title}`}
                className={`${innerActiveTab==index ? 'afi-tabs-active' : ''} ${isDisabled(index) ? 'afi-tabs-disabled' : ''} ${CLASSNAME_BY_LENGTH[titles.length-1]} ${isLight(index) ? 'afi-tabs-light' : ''}`}
                onClick={() => handleTabClick(index)}>
              <h2 className="afi-tabs-tab-title">{title}</h2>
            </li>          
          )}
        </ul>
      </div>

      <div className="afi-tabs-panels">
        {renderPanel(innerActiveTab)}
        {/*
        {panels.map((panel, idx) =>
          <div key={`afi-tabs-panel-$${titles[idx]}`}
               className="afi-tabs-panel" 
               style={{... innerActiveTab!=idx ? {display: "none"} : {}}}>
            {panel}
          </div>
        )}
        */}
      </div>
    </>
  )
}

export default Tabs