import React    from 'react'
import {Button} from 'cli/ui/components/buttons'
import Icon from 'cli/ui/components/icon'
import {uvl} from 'farrapa/commons'

const COLORS= {
  openForm  : {backgroundColor: '#5b91d8', color: 'black'},
  saveForm  : {backgroundColor: '#b3dbe8', color: 'black'},
  closeForm : {backgroundColor: '#f5d3d3', color: 'black'},
  goNextStep: {backgroundColor: '#bceabc', color: 'black'},
  colab     : {backgroundColor: '#b2eac6', color: 'black'},
  notColab  : {backgroundColor: '#d3c8c8', color: 'black'},
  warning   : {backgroundColor: '#fbd0a3', color: 'black'},
}


const Prepare = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.openForm}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'file-word'}>
    {"Editar doc"}
  </Button> 


const Print = ({onClick, disabled, label}) =>
  <Button style   = {{backgroundColor: '#ecec5c', color: 'black'}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'printer'}>
    {uvl(label, "Imprimir")}
  </Button> 


const RePrint = ({onClick, disabled}) =>
  <Button style   = {{backgroundColor: '#eaea9e', color: 'black'}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'printer'}>
    {"Imprimir"}
  </Button>

const MarkNotification = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.saveForm}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'download2'}>
    {"Notificada"}
  </Button>

const MarkRejection = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.notColab}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'file-ko'}>
    {"Rechazada"}
  </Button>

const GoOn = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.goNextStep}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'forward2'}>
    {"Ir al Siguiente"}
  </Button>  

const AddDoc = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.openForm}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'file-add'}>
    {"Añadir doc"}
  </Button> 

const Provide = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.goNextStep}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'file-ok'}>
    {"Aporta"}
  </Button>  

const ProvideOut = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.warning}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'file-ok'}>
    {"Aporta fuera de plazo"}
  </Button>  

const NotProvide = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.notColab}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'file-ko'}>
    {"No Aporta"}
  </Button>    

const Back = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.closeForm}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'arrow-left'}>
    {"Volver"}
  </Button>      

const SetAppointment = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.saveForm}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'pen'}>
    {"Fijar fecha de firma"}
  </Button>

const SetArgumentsDate = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.saveForm}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'pen'}>
    {"Fijar fecha de alegaciones"}
  </Button>


const SetAgree = ({onClick, disabled}) =>
<Button style   = {{...COLORS.saveForm}}
        onClick = {(ev) => onClick(ev)}
        disabled= {disabled}
        icon    = {'pen'}>
  {"Fijar fecha de conformidad"}
</Button>

const SetSerial = ({onClick, disabled}) =>
<Button style   = {{...COLORS.saveForm}}
        onClick = {(ev) => onClick(ev)}
        disabled= {disabled}
        icon    = {'pen'}>
  {"Modificar Núm de Acta"}
</Button>

const SearchNextSerial = ({onClick, disabled}) =>
<Button style   = {{...COLORS.goNextStep}}
        onClick = {(ev) => onClick(ev)}
        disabled= {disabled}
        icon    = {'search'}>
  {"Fijar siguiente Núm de Acta"}
</Button>

const ChangeNotification = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.openForm}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'pen'}>
    {"Cambiar fecha de notificación"}
  </Button>  


const ChangeAppointment = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.openForm}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'pen'}>
    {"Cambiar fecha de firma"}
  </Button>  

const WithArguments = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.notColab}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'hand_ko'}>
    {"Con alegaciones"}
  </Button>  


const WithoutArguments = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.colab}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'hand_ok'}>
    {"Sin alegaciones"}
  </Button>  

const Disagree = ({onClick, disabled, label ='Disconforme'}) =>
  <Button style   = {{...COLORS.notColab}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'hand_ko'}>
    {label}
  </Button>  


const Agree = ({onClick, disabled, label= 'Conforme'}) =>
  <Button style   = {{...COLORS.colab}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'hand_ok'}>
    {label}
  </Button>  


const WithAppeal = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.notColab}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'hand_ko'}>
    {"Con recurso reposición"}
  </Button>  


const WithoutAppeal = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.colab}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'hand_ok'}>
    {"Sin recurso reposición"}
  </Button>  


const WithEstimate = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.notColab}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'hand_ko'}>
    {"Estimado"}
  </Button>  


const WithoutEstimate = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.colab}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'hand_ok'}>
    {"Desestimado"}
  </Button>  

const WithJudicial = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.notColab}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'hand_ko'}>
    {"Con Rec. Contencioso"}
  </Button>  


const WithoutJudicial = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.colab}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {'hand_ok'}>
    {"Sin Rec. Contencioso"}
  </Button>  

const Close = ({onClick, disabled}) =>
  <Button style   = {{backgroundColor: '#1e1d1d', color: 'white'}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {<Icon icon='file-zip' color='white' inline/>}>
    {"Cerrar"}
  </Button>  


const Save = ({onClick, disabled}) =>
  <Button style   = {{...COLORS.saveForm}}
          onClick = {(ev) => onClick(ev)}
          disabled= {disabled}
          icon    = {<Icon icon='file-zip' color='white' inline/>}>
    {"Guardar"}
  </Button>  


export {Prepare, Print, RePrint, MarkNotification, MarkRejection, GoOn, AddDoc, Provide, ProvideOut, NotProvide, Back, 
        SetAppointment, SetAgree, SetSerial, SearchNextSerial, ChangeAppointment, ChangeNotification, SetArgumentsDate, WithoutArguments, WithArguments,
        Disagree, Agree, WithAppeal, WithoutAppeal, WithEstimate, WithoutEstimate, 
        WithJudicial, WithoutJudicial, Close, Save}