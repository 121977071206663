import React from 'react'
import withContext from 'cli/context/withContext'
import { ActionCheck } from 'cli/ui/components/buttons/actions'
import NiceList from 'cli/ui/components/nice_list'
import {FInputColor} from 'formiga-reactstrap'

const parseCalendarDescription = (calendar) => {
  return calendar.description.replace('{days}', calendar.days)
}


const calendarFields = (admin) => [
  /* eslint react/display-name:0 */
  {
    label: "Orden",
    className: "main", 
    value: (calendar) => calendar.sorting
  },   
  {
    label: "Nombre evento",
    className: "main", 
    value: (calendar) => calendar.name
  }, 
  {
    label: "Título corto",
    className: "main", 
    value: (calendar) => calendar.shortname
  }, 
  {
    label: "Descripción", 
    value: (calendar) => parseCalendarDescription(calendar)
  },
  //{
  //  label: "Tipo de Expedientes",
  //  value: (calendar) => calendar.process_type===1
  //         ? 'Inspección'
  //         : calendar.process_type===2
  //           ? 'Comprobación'
  //           : '*'
  //},  
  {
    label: "Intervalo", 
    className: "right", 
    render: (calendar, onEvent) =>  {
      if (calendar.can_be_interval!==true) {
        return null
      }
      return (
        <ActionCheck onClick={() => onEvent('change', {id: calendar.id, show_as_interval: !calendar.show_as_interval})}
                    enabled={admin}
                    checked={calendar.show_as_interval}
                    color={calendar.show_as_interval ? 'purple' : 'gray'}/>
      )}
  },  
  {
    label: "Color", 
    className: "right", 
    render: (calendar, onEvent) => 
      <FInputColor name       = "color"
                  label       = {undefined}
                  value       = {calendar.color}
                  onChange    = {(v, c) => {
                    if (c) {
                      onEvent('change', {id: calendar.id, color: v})
                    }
                  }}
                  showValidity= {0}
                  icon        = {false}
      />
  },  
  {
    label: "Mostrar", 
    className: "right", 
    render: (calendar, onEvent) => 
      <ActionCheck onClick={() => onEvent('change', {id: calendar.id, shown: !calendar.shown})}
                  enabled={admin}
                  checked={calendar.shown}/>
  }
]

const makeKey= (calendar) =>
  `calendar_list_row_${calendar.id}`


const CalendarList = ({ user, calendars, onChange }) => {
    const admin =['devel@afialapis.com', 'david@bonafidetributos.es', 'cristina@bonafidetributos.es']
               .indexOf(user.username)>=0

  return (
    <NiceList fields     = {calendarFields(admin)}
              initialSort= {[0, "asc"]}
              data       = {calendars}
              makeKey    = {makeKey}
              onEvent    = {(ev, params) => {
                if (ev=='change') {
                  onChange(params)
                }                
              }}
              transparent/>
  )
}


export default withContext(CalendarList)