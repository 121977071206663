import {I5, LU, IAE, TODP, IIVTNU} from 'bl/ns/taxes'

const PTYPE= {
  COMPLETE : 1,
  REDUCED: 2
}

const PTYPE_NAMES_I5= {
  [PTYPE.COMPLETE] : 'Inspección',
  [PTYPE.REDUCED]: 'Comprobación'
}

const PTYPE_NAMES_LU= {
  [PTYPE.COMPLETE] : 'Definitiva',
[PTYPE.REDUCED]: 'Provisional'
}

const PTYPE_NAMES_IAE= {
  [PTYPE.COMPLETE] : 'Inspección',
  [PTYPE.REDUCED]: 'Comprobación'
}

const PTYPE_NAMES_TODP= {
  [PTYPE.COMPLETE] : 'Inspección',
  [PTYPE.REDUCED]: 'Comprobación'
}

const PTYPE_NAMES_IIVTNU= {
  [PTYPE.COMPLETE] : 'Inspección',
  [PTYPE.REDUCED]: 'Comprobación'
}

const PTYPE_NAMES= {
  [I5     ] : PTYPE_NAMES_I5,
  [LU     ] : PTYPE_NAMES_LU,
  [IAE    ] : PTYPE_NAMES_IAE,
  [TODP   ] : PTYPE_NAMES_TODP,
  [IIVTNU ] : PTYPE_NAMES_IIVTNU
}


const DOCU_RECEPTION_MODES= {
  1: 'Correo ordinario',
  2: 'E-Mail',
  3: 'Registro'
}

const DOCU_STATUSES = {
  1: 'Completo',
  0: 'Incompleto'
}

const CLOSING_REASONS = {
  1: 'Declaración correcta',
  2: 'Cierre de empresa',
  99: 'Otros'
}

const UNATT_REQUIREMENT= {
  1: 'Primero',
  2: 'Segundo',
  3: 'Tercero'
}

const ACTION= {
  EDIT            : 'processEdit',
  APPOINTMENT_DATE: 'appointmentDate',
  INSPECTION_STEP : 'inspectionStep',
  CLOSE           : 'close',
  REOPEN          : 'reopen',
  TAX_UPD         : 'updTax',
  NOTE_ADD        : 'addNote',
  NOTE_REM        : 'remNote',
  DOCU_ADD        : 'addDocu',
  DOCU_REM        : 'remDocu',
  FILE_UPD        : 'upsaveFile',
  OPEN_PENALTY    : 'penaltyOpen',
  PENALTY_TYPE    : 'penaltyType',
  PENALTY_STEP    : 'penaltyStep',
  PENALTY_DATA    : 'penaltyData',
  SERIAL          : 'serial',
  PAYMENT_DATA    : 'paymentData'
}

export {PTYPE, PTYPE_NAMES, DOCU_RECEPTION_MODES, DOCU_STATUSES, CLOSING_REASONS, UNATT_REQUIREMENT, ACTION}