import React from 'react'
import { Button } from 'cli/ui/components/buttons'

const LuLicenseImportStep2 = ({sheetNames, sheetIndex, onChoose}) =>
    <div style={{display: "flex"}}>
      {sheetNames.map((sh, idx) => 
        <div key={`lu_license_import_sheet_idx_${idx}`}
             style={{paddingBottom: "1em",
                     ...(sheetIndex==idx && {borderBottom: "1px solid black"})
             }}>
          <Button key    = {`lu_license_import_sheet_pick_${idx}`}
                  onClick= {() => onChoose(idx)}
                  icon   = {"file"}
                  style  = {{margin: "0 1em", backgroundColor: sheetIndex==idx ? "#26cc26" : "#7ed07e"}}>
            {sh}
          </Button>
        </div>
      )}
    </div>

export default LuLicenseImportStep2
        
        
        
        
