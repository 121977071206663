import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { intre_locale_init } from 'intre'

import withContext from 'cli/context/withContext'
import Dashboard          from 'cli/ui/pages/dash/Dashboard'

import Login  from 'cli/ui/pages/accontrol/Login'
import Forgot from 'cli/ui/pages/accontrol/Forgot'

import DevIconset from 'cli/ui/pages/_dev_/Iconset'
import CouncilRoutes from './routes/CouncilRoutes'
import DocRoutes from './routes/DocRoutes'
import IncomeRoutes from './routes/IncomeRoutes'
import SettingRoutes from './routes/SettingRoutes'
import StatsRoutes from './routes/StatsRoutes'
import TaxPayerRoutes from './routes/TaxPayerRoutes'
import TaxRoutes from './routes/TaxRoutes'
import UserRoutes from './routes/UserRoutes'

intre_locale_init('es')

const AppIndex = ({ authenticated, permiss}) => {

  return (
    <Routes> 
      { authenticated
        ? <>
            {UserRoutes(permiss)}]
            {SettingRoutes(permiss)}
            {CouncilRoutes(permiss)}
            {TaxPayerRoutes(permiss)}
            {TaxRoutes(permiss)}
            {DocRoutes(permiss)}
            {IncomeRoutes(permiss)}
            {StatsRoutes(permiss)}

            {process.env.DEV===true
            ? <Route path={'/dev/icons'} element={<DevIconset/>}/>
            : null}

            <Route path="/" element={<Dashboard/>}/>

            <Route
                path="*"
                element={<Dashboard/>}
            />    
          </>
        : <>
            <Route path={'/page/login'} element={<Login/>}/>
            <Route path={'/page/forgot'} element={<Forgot/>}/>

            <Route
                path="*"
                element={<Login/>}
            />              
          </>                
      }


    </Routes>
  )

}



export default withContext(AppIndex)
