import React, {useState, useEffect} from 'react'
import {FForm, FInputText, FInputUInt, FInputSelect} from 'formiga-reactstrap'
//import {Back, Save} from '../steps/parts/actions'
import { PTYPE_NAMES } from 'bl/ns/process/process'
import { PENALTY_GROUPS } from 'bl/ns/process/penalty'
import withContext from 'cli/context/withContext'

const ProcessEditForm = ({fetcher, process, onProcessEdit, onCancel}) => {
  const [sectorList, setSectorList]= useState({})
  const [name, setName]= useState(process.name)
  const [processType, setProcessType]= useState(process.process_type)
  const [yearFrom, setYearFrom]= useState(process.year_from)
  const [yearTo, setYearTo]= useState(process.year_to)
  const [serial19, setSerial19]= useState(process.penalty_19_serial)
  const [serial20, setSerial20]= useState(process.penalty_20_serial)
  const [taxpayerName, setTaxpayerName]= useState(process.taxpayer_name_custom)
  const [taxpayerSectorId, setTaxpayerSectorId]= useState(process.taxpayer_sector_id_custom)
  const [taxpayerActivity, setTaxpayerActivity]= useState(process.taxpayer_activity_custom)

  useEffect(() => {
    async function fetchSectors() {
      const res= await fetcher.key_list('/api/sector')
      setSectorList(res)
    }
    fetchSectors()
  }, [fetcher])

  const handleSave = () => {
    onProcessEdit({
      name: name,
      process_type: processType,
      year_from: yearFrom,
      year_to: yearTo,
      penalty_19_serial: serial19,
      penalty_20_serial: serial20,
      taxpayer_name: taxpayerName,
      taxpayer_sector_id: taxpayerSectorId,
      taxpayer_activity: taxpayerActivity
    })
  }

  return (
    <FForm  
            onSave = {handleSave}
            onCancel={onCancel}>
            <>
              <FInputText  
                          name        = 'name'
                          feedback    = 'Nº Expediente'
                          value       = {name}
                          onChange    = {(v) => setName(v)}
                          required    = {true}
                          label       = "Nº Expediente"
              />
              <FInputSelect 
                          options     = {PTYPE_NAMES[process.tax_code]}
                          name        = 'process_type'
                          value       = {processType}
                          onChange    = {(v) => setProcessType(v)}
                          feedback    = {"Selecciona tipo de expediente"}
                          clearable   = {true}
                          required    = {true}
                          autocomplete= {"off"}
                          label       = "Tipo de Expediente"
              />
              <FInputUInt  
                          name        = 'year_from'
                          feedback    = 'Ejercicio desde'
                          value       = {yearFrom}
                          onChange    = {(v) => setYearFrom(v)}
                          required    = {true}
                          gt          = {1990}
                          label       = "Ejercicio desde"
              />
              <FInputUInt  
                          name        = 'year_to'
                          feedback    = 'Ejercicio hasta'
                          value       = {yearTo}
                          onChange    = {(v) => setYearTo(v)}
                          required    = {true}
                          gt          = {1990}
                          label       = "Ejercicio hasta"
              />
              {process.penalty_19_on===true
               ?
                <FInputText  
                            name        = 'penalty_19_serial'
                            feedback    = {`Acta Sanción ${PENALTY_GROUPS['19']}`}
                            value       = {serial19}
                            onChange    = {(v) => setSerial19(v)}
                            required    = {true}
                            label       = {`Acta Sanción ${PENALTY_GROUPS['19']}`}
                />               
               : null}
              {process.penalty_20_on===true
               ?
                <FInputText  
                            name        = 'penalty_20_serial'
                            feedback    = {`Acta Sanción ${PENALTY_GROUPS['20']}`}
                            value       = {serial20}
                            onChange    = {(v) => setSerial20(v)}
                            required    = {true}
                            label       = {`Acta Sanción ${PENALTY_GROUPS['20']}`}
                />               
               : null} 
               <FInputText  
                           name        = 'taxpayer_name'
                           feedback    = 'Nombre Contribuyente'
                           value       = {taxpayerName}
                           onChange    = {(v) => setTaxpayerName(v)}
                           required    = {true}
                           label       = "Nombre Contribuyente"
               />       
              <FInputSelect 
                          options     = {sectorList}
                          name        = 'taxpayer_sector_id'
                          value       = {taxpayerSectorId}
                          onChange    = {(v) => setTaxpayerSectorId(v)}
                          feedback    = {"Sector Contribuyente"}
                          clearable   = {true}
                          required    = {false}
                          autocomplete= {"off"}
                          label       = "Sector Contribuyente"
              />  
               <FInputText  
                           name        = 'taxpayer_activity'
                           feedback    = 'Actividad Contribuyente'
                           value       = {taxpayerActivity}
                           onChange    = {(v) => setTaxpayerActivity(v)}
                           required    = {false}
                           label       = "Actividad Contribuyente"
               />                                         
            </>
      </FForm>
  )
}
export default withContext(ProcessEditForm)
