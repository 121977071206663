import React, {useState, useEffect, useCallback} from 'react'
import {useParams, useNavigate} from 'react-router-dom'

import {isEmptyObject} from 'farrapa/objects'
import {utils as xlsx_utils, read as xlsx_read} from 'xlsx'
import {uvl} from 'farrapa/commons'
// components
import Page      from 'cli/ui/pages/layout/Page'
import {Row,Col} from 'reactstrap';
import {Card, CardBody, CardFooter} from 'cli/ui/components/cards'
import { ActionBack } from 'cli/ui/components/buttons/actions'
import { LoadingBig} from 'cli/ui/components/loading'
import { ButtonStepsMiddle, ButtonStepsStart, ButtonStepsEnd } from 'cli/ui/components/buttons'
import LuLicenseImportStep1 from './LuLicenseImportStep1'
import LuLicenseImportStep2 from './LuLicenseImportStep2'
import LuLicenseImportStep3 from './LuLicenseImportStep3'
import LuLicenseImportStep4 from './LuLicenseImportStep4'
import LuLicenseImportStep5 from './LuLicenseImportStep5'
import withContext from 'cli/context/withContext'


const xlsToLicenseList = async (xls, ordinance) => {
  let indexes= {}
  Object.keys(xls.header.map).map((k) => {
    const src_index = xls.header.fields.indexOf(
      xls.header.fields.filter((h) => h.id==k)[0]
    )
    const dest_field = xls.header.map[k]
    indexes[dest_field]= src_index
  })

  let licenses= []    
  await Promise.all(
    xls.data.map(async (data) => {
      let license= {
        ordinance_id: ordinance.id,
      }
      Object.keys(indexes).map((fld) => {
        const idx= indexes[fld]
        license[fld]= data[idx]
      })

      if (! license.name) {
        license.name= `Licencia núm ${uvl(ordinance.license_count,0) + licenses.length + 1}`
      }
      licenses.push(license)
    })
  )
  return licenses
}  

const shortIt = (s) => 
  s==undefined
    ? ''
    : s.length>30
      ? s.substr(0,30)+' ...'
      : s

const LULicenseImport = ({fetcher}) => {

  const params= useParams()
  const navigate= useNavigate()
  const [ordinance, setOrdinance]= useState(undefined)
  const [status, setStatus] = useState(0)
  
  const [step, setStep]= useState(1)
  const [file, setFile]= useState({})
  const [workbook, setWorkbook]= useState(undefined)
  const [sheetIndex, setSheetIndex]= useState(undefined)
  const [xls, setXls]= useState({
    header: {
      fields: [],
      map: {}
    },
    data: {}
  })
  

  const paramCouncilId = params.idCouncil
  const paramOrdinanceId = params.idOrdinance

  
  useEffect(() => {
    async function fetchData() {
      const res = await fetcher.get('/api/ordinance/detail', {id: paramOrdinanceId})
      const nOrdinances = res.data
      const nOrdinance = nOrdinances[0]      
      setOrdinance(nOrdinance)
      setStatus(1)
    }
    fetchData()

  }, [fetcher, paramOrdinanceId])  

  const getBreads = useCallback(() => {
    if (status==0)
      return null
    return [['/', 'Inicio'], ['/councils', 'Ayuntamientos'], 
            [`/councils/${paramCouncilId}`, ordinance.council_name], 
            [`/councils/${paramCouncilId}/lu/${paramOrdinanceId}`, shortIt(ordinance.name)], 'Importar Licencias']
  }, [paramCouncilId, paramOrdinanceId, ordinance, status])




  useEffect(() => {
    if (workbook!=undefined && sheetIndex!=undefined) {
      const sheetName= workbook.SheetNames[sheetIndex]
      const worksheet = workbook.Sheets[sheetName]
      const djson = xlsx_utils.sheet_to_json(worksheet, { header: 1 })

      setXls({
        header: {
          map   : {},
          fields: djson[0].map((f) => { 
            return { 
              id: f.replace(new RegExp(' ', 'g'), '_'), 
              label: f

            }
          })
        },
        data  : djson.slice(1)
      })
      setStep(3)
    }
  }, [workbook, sheetIndex])

  const handleUploadXls = (file) => {
    setFile(file)

    const nWorkbook=xlsx_read(file.buffer, { type: 'array' }) 
    setWorkbook(nWorkbook)

    if (nWorkbook.SheetNames.length==1) {
      setSheetIndex(0)
    } else {
      setStep(2)
    }
  }  

  const onChangeHeaderMap = async (n, v) => {
    const nHeaderMap= {
      ...xls.header.map,
      [n]: v
    }

    const nXls= {
      ...xls,
      header: {
        ...xls.header,
        map: nHeaderMap
      }
    }
    setXls(nXls)
  }

  const handleChangeStep = async (s) => {
    if (step==3 && s==4) {
      setStep(4)
      const licenses = await xlsToLicenseList(xls, ordinance)
      await fetcher.post('/api/lu/license/import_data', {licenses})
      setStep(5)
    } else {
      setStep(s)
    }
    
  }

  const readyToGo = (s) => {
    if (s==1) {
      return !isEmptyObject(file)
    }
    if (s==2) {
      return sheetIndex!=undefined
    }
    if (s==3) {
      return xls.header!=undefined && xls.header.map!=undefined && Object.values(xls.header.map).length>0
    }
    if (s==5) {
      return true
    }
    return false
  }



  return (
    <Page breads = {getBreads()}
          title  = {'Importar Licencias'}
          actions= {<>
                    {status==1
                     ? <ActionBack to={`/councils/${paramCouncilId}/lu/${paramOrdinanceId}`} label="A la ordenanza"/>
                     : null
                    }
                    </>}>
      <Row>
        <Col xs="12">
          <ul className="progress-indicator">
            <li className={`${step<1 ? 'active' : step>=1 ? 'completed' : ''}`}> <span className="bubble"></span>Seleccione el fichero a cargar</li>
            <li className={`${step<2 ? 'active' : step>=2 ? 'completed' : ''}`}> <span className="bubble"></span>¿De qué hoja se han de importar datos?</li>
            <li className={`${step<3 ? 'active' : step>=3 ? 'completed' : ''}`}> <span className="bubble"></span>Asigne la columnas del fichero</li>
            <li className={`${step<4 ? 'active' : step>=4 ? 'completed' : ''}`}> <span className="bubble"></span>Importando licencias...</li>
            <li className={`${step<5 ? 'active' : step>=5 ? 'completed' : ''}`}> <span className="bubble"></span>Licencias importadas!</li>
          </ul>          
        </Col>

        <Col xs="12">
          <Card>
            <CardBody>
              {step == 0 &&
                <LoadingBig />
              }

              {step == 1 &&
                <>
                  <h2>Seleccione el fichero a cargar</h2>
                  <LuLicenseImportStep1 file = {file}
                                        onUpload ={(file) => handleUploadXls(file)}/>
                </>
              }
              {step == 2 &&
                <>
                  <h2>¿De qué hoja se han de importar datos?</h2>
                  <LuLicenseImportStep2 sheetNames= {workbook.SheetNames}
                                        sheetIndex= {sheetIndex}
                                        onChoose  = {(idx) => setSheetIndex(idx)}/>
                </>
              }

              {step == 3 &&
                <>
                  <h2>Asigne la columnas del fichero</h2>
                    <LuLicenseImportStep3 header   = {xls.header}
                                          onChange = {(n,v) => {onChangeHeaderMap(n,v)} }/>
                </>
              }

              {step == 4 &&
                <>
                  <h2>Importando licencias...</h2>
                  <LuLicenseImportStep4/>
                </>
              }

              {step == 5 &&
                <>
                  <h2>Licencias importadas!</h2>
                  <LuLicenseImportStep5 ordinance={ordinance}/>
                </>
              }
            </CardBody>


            <CardFooter>
              {step == 1 &&
                <ButtonStepsStart
                  onNext   = {() => handleChangeStep(step+1)}
                  disabled = {!readyToGo(step)}
                />
              }

              {(step >= 2 && step<=4) &&
                <ButtonStepsMiddle
                  onPrev   = {() => handleChangeStep(step-1)}
                  onNext   = {() => handleChangeStep(step+1)}
                  disabled = {!readyToGo(step)}
                />
              }

              {step == 5 &&
                <ButtonStepsEnd
                  onDone   = {() => navigate(`/councils/${paramCouncilId}/lu/${paramOrdinanceId}`)}
                  disabled = {!readyToGo(step)}
                />
              }
            </CardFooter>                  
          </Card>
        </Col>              


      </Row>
    </Page>
  )
}

export default withContext(LULicenseImport)
        
        
        
        
