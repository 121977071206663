import React, {useState, useEffect, useCallback} from 'react'
import {useLocation, useParams, useNavigate} from 'react-router-dom'

import withContext from 'cli/context/withContext'
import { b64toBlob } from 'farrapa/encoding'
import FileSaver from 'file-saver'


// components
import Page from 'cli/ui/pages/layout/Page'
import {Row, Col} from 'reactstrap'
import { LoadingBig } from 'cli/ui/components/loading'
import Tabs from 'cli/ui/components/tabs'
import ProcessResume from './header/ProcessResume'
import ProcessEdit from './header/ProcessEdit'
import ProcessClose from './header/ProcessClose'
import ProcessPenaltyInit from './header/ProcessPenaltyInit'
import ProcessActions from './header/ProcessActions'
import ProcessInspect from './flow/ProcessInspect'
import ProcessPenalty from './flow/ProcessPenalty'
import ProcessCalcs from './calcs/ProcessCalcs'
import ProcessDocu from './docu/ProcessDocu'


import ProcessCalc from 'bl/calc/Process'

// utils
import {ACTION} from 'bl/ns/process/process'
import {PENALTY_TYPE} from 'bl/ns/process/penalty'
import {TAX_NAMES, LU} from 'bl/ns/taxes'
import { useEffectOnMount } from 'cli/ui/hooks/useEffectOnMount'
import useAlert from 'cli/ui/hooks/useAlert'
import { arrayRemove } from 'farrapa/collections'


const TAB_INSPECT= "Inspección"
const TAB_PENALTY_20= "Sanción 203"
const TAB_PENALTY_19= "Sanción 191/192"
const TAB_PENALTY_CALC= "Cálculo"
const TAB_PENALTY_DOC= "Documentación"


const Process = ({fetcher, user, permiss}) => {
  const params= useParams()
  const location= useLocation()
  const navigate= useNavigate()
  
  const ualert= useAlert()
  const [breads, setBreads]= useState([])
  const [process, setProcess]= useState(undefined)
  const [agents, setAgents]= useState({})
  const [docs, setDocs]= useState({})
  const [loaded, setLoaded]= useState(false)

  const [event, setEvent]= useState(undefined)

  const [visibleTabs, setVisibleTabs]= useState([])
  const [lightTabs, setLightTabs]= useState([])
  const [tab, setTab]= useState(TAB_INSPECT)
  const [disabledTabs, setDisabledTabs]= useState([])
  const [showEdit, setShowEdit]= useState(false)
  const [showClose, setShowClose]= useState(false)
  const [showPenalty, setShowPenalty]= useState(false)

  const taxCode= location.pathname.split('/')[2]
  const paramId = params.idProcess  
  const paramEditionId = params.idEdition
  const title = process?.name || '...' 

  const canEdit = permiss.can_user_edit_processes()
  const canPrint = permiss.can_user_print_processes()


  useEffect(() => {
    const edition_name= process!=undefined ? process.edition_name : ''
    const process_name= process!=undefined ? process.name : ''

    setBreads(
      [['/', 'Inicio'], 
       ['/taxes', 'Tributos'], 
       [`/taxes/${taxCode}`, TAX_NAMES[taxCode]], 
       [`/taxes/${taxCode}/edition`, 'Inspecciones'],
       [`/taxes/${taxCode}/edition/${paramEditionId}`, edition_name],
       process_name]
    )
  }, [paramEditionId, taxCode, process])

  useEffect(() => {
    if (taxCode==LU) {
      setVisibleTabs(
        [TAB_INSPECT, TAB_PENALTY_20,
         TAB_PENALTY_CALC, TAB_PENALTY_DOC
      ])
      setLightTabs([
        false,
        ( process?.penalty_20_on && (process?.penalty_20_enabled===false)) ,
        false, false
      ])
    } else {
      setVisibleTabs(
        [TAB_INSPECT, TAB_PENALTY_20,
          TAB_PENALTY_19, TAB_PENALTY_CALC, TAB_PENALTY_DOC
      ]) 
      setLightTabs([
        false,
        ( process?.penalty_20_on && (process?.penalty_20_enabled===false)) ,
        ( process?.penalty_19_on && (process?.penalty_19_enabled===false)) ,
        false, false
      ])           
    }

  }, [taxCode, process])

  useEffectOnMount(
    useCallback(() => {
    let toTab= TAB_INSPECT, toDisabled= [TAB_PENALTY_20, TAB_PENALTY_19]
    if (process?.penalty_20_on===true) {
      toTab= TAB_PENALTY_20
      arrayRemove(toDisabled, TAB_PENALTY_20)
    }
    if (process?.penalty_19_on===true) {
      toTab= TAB_PENALTY_19
      arrayRemove(toDisabled, TAB_PENALTY_19)
    }

    setTab(toTab)
    setDisabledTabs(toDisabled)
    
  }, [process?.penalty_20_on, process?.penalty_19_on]))

  /*
  useEffectOnMount(
    useCallback(() => {
    let toTab= 0, toDisabled= [1,2]
    if (process?.penalty_20_on===true) {
      toTab= 1
      arrayRemove(toDisabled, 1)
    }
    if (process?.penalty_19_on===true) {
      toTab= 2
      arrayRemove(toDisabled, 2)
    }

    setTab(toTab)
    setDisabledTabs(toDisabled)
    
  }, [process?.penalty_20_on, process?.penalty_19_on]))
  */

  const fetchEvent = useCallback( (pid) => {
    const _fetchEvent = async () => {
      const raw= await fetcher.get('/api/calendar_event/detail', {process_id: pid})
      if (Array.isArray(raw.data) && (raw.data.length>=1)) {
        setEvent(raw.data[0])
      }
    }
    _fetchEvent()
  }, [fetcher])


  const fetchProcess = useCallback( () => {
    const _fetchProcess = async () => {
      try {
        const res= await fetcher.get('/api/process/find_extended', {id: paramId})
        if (res == undefined) {
          navigate('/')
        } else {
          const nProcess= res.data
          setProcess(new ProcessCalc(nProcess))
        }
      } catch(e) {
        console.error(e)
        navigate('/')
      }
    }
    _fetchProcess()
  }, [fetcher, paramId, navigate])


  useEffect(() => {
    async function fetchData() {
      await fetchProcess()

      const nAgents= await fetcher.key_list('/api/agent')
      setAgents(nAgents)

      const res= await fetcher.get('/api/doc/detail')
      const nDocs= res.data
      setDocs(nDocs)

      setLoaded(true)
    }
    fetchData()
    

  }, [fetcher, fetchProcess, fetchEvent])  

  useEffect(() => {
    fetchEvent(paramId)
  }, [fetchEvent, paramId])



  const handleProcessUpsave = async (changes, actionName, confirmed) => {
    if (!canEdit) {
      return
    }

    const nChanges= {
      ...changes
    }
    if (changes.tax_data) {
      nChanges.tax_data= {
        ...process.tax_data,
        ...changes.tax_data
      }
    }

    if (confirmed===false) {
      const nProcess= {
        ...process.toJson(),
        ...nChanges
      }
      setProcess(new ProcessCalc(nProcess))
    } else {
      const {data}= await fetcher.post('/api/process/upsave_proc', {id: process.id, changes: nChanges, actionName})
      setProcess(new ProcessCalc(data))
    }
    
  }   

  const handleProcessPenaltyInit = async (penaltyType) => {
    if (!canEdit) {
      return
    }
    
    let changes= {}
    if (penaltyType==PENALTY_TYPE.ART_203) {
      changes= {penalty_20_on: true}
    } else {
      changes= {
        penalty_19_on: true,
        penalty_19_type: penaltyType
      }
    }
    await handleProcessUpsave(changes, ACTION.OPEN_PENALTY)
  }

  const handleProcessClose = async (reason, explanation) => {
    if (!canEdit) {
      return
    }
    
    const params= {
      process_id : process.id,
      reason     : reason,
      explanation: explanation
    }

    const {data} = await fetcher.post('/api/process/close', params)
    setProcess(new ProcessCalc(data))  
  }  

  const handleProcessUndo = async () => {
    if (!canEdit) {
      return
    }
    
    const {data}= await fetcher.post('/api/process/undo', {id: process.id})
    if (data!=-1) {
      setProcess(new ProcessCalc(data))
    }
    return 1
  }

  const handleProcessReopen = async () => {
    if (!canEdit) {
      return
    }
    
    const {data}= await fetcher.post('/api/process/reopen', {id: process.id})
    if (data!=-1) {
      setProcess(new ProcessCalc(data))
    }
    return 1
  }

  const handleProcessAsDisagree = async () => {
    if (!canEdit) {
      return
    }
    
    const {data}= await fetcher.post('/api/process/as_disagree', {id: process.id})
    if (data!=-1) {
      setProcess(new ProcessCalc(data))
    }
    return 1
  }

  const handleProcessAsTest = async () => {
    if (!canEdit) {
      return
    }
    
    const {data}= await fetcher.post('/api/process/as_test', {id: process.id})
    if (data!=-1) {
      setProcess(new ProcessCalc(data))
    }
    return 1
  }  


  const handleProcessDropPenalty19 = async () => {
    if (!canEdit) {
      return
    }
    
    const {data}= await fetcher.post('/api/process/drop_penalty_19', {id: process.id})
    if (data!=-1) {
      setProcess(new ProcessCalc(data))
    }
    return 1
  }

  const handleProcessTogglePenalty19 = async (enabled) => {
    if (!canEdit) {
      return
    }
    
    const {data}= await fetcher.post('/api/process/toggle_penalty_19', {id: process.id, enabled})
    if (data!=-1) {
      setProcess(new ProcessCalc(data))
    }
    return 1
  }


  const handleProcessDropPenalty20 = async () => {
    if (!canEdit) {
      return
    }
    
    const {data}= await fetcher.post('/api/process/drop_penalty_20', {id: process.id})
    if (data!=-1) {
      setProcess(new ProcessCalc(data))
    }
    return 1
  }  

  const handleProcessTogglePenalty20 = async (enabled) => {
    if (!canEdit) {
      return
    }
    
    const {data}= await fetcher.post('/api/process/toggle_penalty_20', {id: process.id, enabled})
    if (data!=-1) {
      setProcess(new ProcessCalc(data))
    }
    return 1
  }

  const handleProcessSwitchReductPaymentValue = async (v, g) => {
    if (!canEdit) {
      return
    }
    
    const {data}= await fetcher.post('/api/process/switch_payment_reduct_value', {id: process.id, value: v, group: g})
    if (data!=-1) {
      setProcess(new ProcessCalc(data))
    }
    return 1
  }  

  


  const handleGetNextSerial = async (year, agreed) => {
    if (!canEdit) {
      return
    }
    
    const res = await fetcher.get('/api/process/get_next_serial', {year, agreed, process_id: process.id})
    return res.data.serial_num
  }

  const handleProcessRemove = async () => {
    if (!canEdit) {
      return
    }
    
    const done = await fetcher.remove('/api/process', process.id)
    if (done) {
      navigate(`/taxes/${taxCode}/edition/${paramEditionId}`)
    }
    return done
  }

  const handleProcessDebug = () => {
    const json = JSON.stringify(process.export());
    const blob = new Blob([json], {type: "application/json"});    
    FileSaver.saveAs(blob, `${title}.json`)    
  }
  
  const handleProcessFileUpsave = async (processFileId, changes) => {
    if (!canEdit) {
      return
    }
    
    let nProcess= process.toJson()

    const file= {
      ...nProcess.files.find((f) => f.id==processFileId),
      ...changes
    }

    const params = { id: process.id, file: file }
    const res= await fetcher.post('/api/process/upsave_file', params)
    const fileu= res.data

    if (fileu) {
      const fileIdx= nProcess.files.findIndex((f) => f.id==processFileId)
      nProcess.files[fileIdx] = fileu.file
      nProcess.undo= [
        ...nProcess.undo,
        ...fileu.undo
      ]
      setProcess(new ProcessCalc(nProcess))
    }    
  }

  const handleFilePrint = async (file, docId) => {
    const fileIdx= process.files.findIndex((f) => f.process_step==file.process_step && f.process_doc==file.process_doc)
    
    let pfile
    if (fileIdx>=0) {
      pfile= {
        ...process.files[fileIdx],
        ...file
      }
    } else {
      pfile= file
    }

    const params = { 
      id: process.id,
      file: pfile,
      doc_id: docId
    }

    const res = await fetcher.post('/api/process/print_file', params)
    const filep= res.data

    if (filep) {

      if (filep.doc.buffer) {

        const nProcess= process.toJson()

        if (fileIdx>=0) {
          nProcess.files[fileIdx] = filep.file
        } else {
          nProcess.files.push(filep.file)
        }
        nProcess.undo= [
          ...nProcess.undo,
          ...filep.undo
        ]
  
        setProcess(new ProcessCalc(nProcess))  

        // some transition please
        const b = b64toBlob(filep.doc.buffer, 'application/zip')
        FileSaver.saveAs(b, filep.doc.filename)    
        
        return 1
      } else {
        ualert('Oops! Parece que el documento asociado está vacío!', 'error')

        return 0
      }
      
    }
  }

  const handleNoteSave = async (msg) => {
    if (!canEdit) {
      return
    }
    
    const userName = user.name || user.username
    const params = { id: process.id, user_name: userName, msg: msg }
    const res = await fetcher.post('/api/process/add_note', params)
    const note = res.data
    if (note && note!=-1) {
      const nProcess= process.toJson()
      nProcess.notes.push(note.note)
      nProcess.undo= [
        ...nProcess.undo,
        ...note.undo
      ]
      setProcess(new ProcessCalc(nProcess))
    }  
  }

  const handleNoteRemove = async (id) => {
    if (!canEdit) {
      return
    }
    
    const params = { process_id: process.id, id: id }
    const res= await fetcher.post('/api/process/rem_note', params)
    const note = res.data
    if (note && note!=-1) {
      const nProcess= {...process}
      arrayRemove(nProcess.notes,
        nProcess.notes.find((n) => n.id== id)
      )
      nProcess.undo= [
        ...nProcess.undo,
        ...note.undo
      ]
      setProcess(new ProcessCalc(nProcess))
    }
  }

  const handleDocuOpen = async (docid) => {
    const res = await fetcher.post('/api/process/docu/download_doc', {id: docid})
    const doc= res.data
    const b = b64toBlob(doc.doc, doc.dtype)
    FileSaver.saveAs(b, doc.name)
  }

  const handleDocuAdd = async (docu) => {
    if (!canEdit) {
      return
    }
    
    docu.process_id = process.id
    const params = { id: process.id, docu: docu }
    const res= await fetcher.post('/api/process/add_docu', params)  
    const doca = res.data

    if (doca && doca!=-1) {
      const nProcess= process.toJson()
      nProcess.docus.push(doca.docu)
      nProcess.undo= [
        ...nProcess.undo,
        ...doca.undo
      ]
      setProcess(new ProcessCalc(nProcess))
    }      
  }

  const handleDocuRemove = async (docid) => {
    if (!canEdit) {
      return
    }
    
    const params = { process_id: process.id, id: docid }
    const res= await fetcher.post('/api/process/rem_docu', params)
    const docu = res.data
    if (docu && docu!=-1) {
      const nProcess= process.toJson()
      arrayRemove(nProcess.docus,
        nProcess.docus.find((d) => d.id==docid)
      )
      nProcess.undo= [
        ...nProcess.undo,
        ...docu.undo
      ]
      setProcess(new ProcessCalc(nProcess)) 
    }

  }

  const handleDocuDownload = async (docu) => {
    try {
      if (! docu.doc && docu.id) {
        await handleDocuOpen(docu.id)
      } else {
        const blob = b64toBlob(docu.doc, docu.dtype)
        FileSaver.saveAs(blob, docu.name)    
      }
    } catch(e) {
      ualert('Error descargando fichero', 'error')
      console.error(e)
    }    
  }

  const handleTaxesI5Change = async (year, changes) => {
    if (!canEdit) {
      return
    }
    
    if (process.tax.id == undefined) {
      const taxJson = process.tax.toJson()
      const taxDetIdx= taxJson.details.findIndex((det) => det.year==year)
      let taxDet = taxJson.details[taxDetIdx]
      taxDet= {
        ...taxDet,
        ...changes
      }
      taxJson.details.splice(taxDetIdx, 1)
      taxJson.details.push(taxDet)
      taxJson.details.map((det) => delete det.id)
      delete taxJson.id

      const nProcess= process.toJson()
      nProcess.tax= taxJson

      setProcess(new ProcessCalc(nProcess))
    } else {
      //
      let taxdetJson= process.tax.details.find((det) => det.year == year).toJson()

      taxdetJson= {
        ...taxdetJson,
        ...changes
      }
      const nProcess= process.toJson()

      const idx= nProcess.tax.details.indexOf(nProcess.tax.details.find((det) => det.year == year))
      nProcess.tax.details[idx]= taxdetJson

      setProcess(new ProcessCalc(nProcess))
    }
      
  }
  

  const handleTaxesI5Save = async () => {
    if (!canEdit) {
      return
    }
    

    const taxJson = process.tax.toJson()

    const res= await fetcher.post('/api/process/upsave_i5_tax', {tax: taxJson})
    const ntax= res.data

    const nProcess= process.toJson()
    nProcess.tax= ntax

    setProcess(new ProcessCalc(nProcess))
    
  }  

  const handleTaxesLUChange = async (changes, confirmed) => {
    if (!canEdit) {
      return
    }
    
    const nProcess= process.toJson()
    
    nProcess.tax= {
      ...nProcess.tax,
      ...changes
    }
    
    if (confirmed == true) {
      const taxJson = nProcess.tax
      const res= await fetcher.post('/api/process/upsave_lu_tax', {tax: taxJson})
      const nTax= res.data
      nProcess.tax= nTax
    }

    setProcess(new ProcessCalc(nProcess))
  }  


  const handleTaxesAEChange = async (val, name) => {
    if (!canEdit) {
      return
    }
    
    const nProcess= process.toJson()

    nProcess.tax[name]= val

    setProcess(new ProcessCalc(nProcess))
  }  



  const handleTaxesAEDetChange = async (year, changes) => {

    if (!canEdit) {
      return
    }
    
    if (process.tax.id == undefined) {
      const taxJson = process.tax.toJson()
      const taxDetIdx= taxJson.details.findIndex((det) => det.year==year)
      let taxDet = taxJson.details[taxDetIdx]
      taxDet= {
        ...taxDet,
        ...changes
      }
      taxJson.details.splice(taxDetIdx, 1)
      taxJson.details.push(taxDet)
      taxJson.details.map((det) => delete det.id)
      delete taxJson.id

      const nProcess= process.toJson()
      nProcess.tax= taxJson

      setProcess(new ProcessCalc(nProcess))
    } else {
      //
      let taxdetJson= process.tax.details.find((det) => det.year == year).toJson()

      taxdetJson= {
        ...taxdetJson,
        ...changes
      }
      const nProcess= process.toJson()

      const idx= nProcess.tax.details.indexOf(nProcess.tax.details.find((det) => det.year == year))
      nProcess.tax.details[idx]= taxdetJson

      setProcess(new ProcessCalc(nProcess))
    }    

  }

  const handleTaxesAESave = async () => {
    if (!canEdit) {
      return
    }
    

    const taxJson = process.tax.toJson()

    const res= await fetcher.post('/api/process/upsave_ae_tax', {tax: taxJson})
    const ntax= res.data

    const nProcess= process.toJson()
    nProcess.tax= ntax

    setProcess(new ProcessCalc(nProcess))
    
  }  



  const handleTaxesTOChange = async (val, name, confirmed) => {
    if (!canEdit) {
      return
    }
    
    const nProcess= process.toJson()
    
    nProcess.tax[name]= val
    
    if (confirmed == true) {
      const taxJson = nProcess.tax
      const res= await fetcher.post('/api/process/upsave_to_tax', {tax: taxJson})
      const nTax= res.data
      nProcess.tax= nTax
    }

    setProcess(new ProcessCalc(nProcess))
  }  


  const handleTaxesVTChange = async (val, name, confirmed) => {
    if (!canEdit) {
      return
    }
    
    const nProcess= process.toJson()
    
    nProcess.tax[name]= val
    
    if (confirmed == true) {
      const taxJson = nProcess.tax
      const res= await fetcher.post('/api/process/upsave_vt_tax', {tax: taxJson})
      const nTax= res.data
      nProcess.tax= nTax
    }

    setProcess(new ProcessCalc(nProcess))
  }    


  const renderPanel = (activeTab) => {
    const activeTabName= visibleTabs[activeTab]
    if (activeTabName==TAB_INSPECT) {
      return (
        loaded==false
        ? <LoadingBig/>        
        : <ProcessInspect process = {process}
                          agents  = {agents}
                          docs = {docs}
                          onPrint = {(file, docId) => handleFilePrint(file, docId)}
                          onProcessChange= {(changes) => handleProcessUpsave(changes, ACTION.INSPECTION_STEP)}
                          onProcessFileChange= {(processFileId, changes) => handleProcessFileUpsave(processFileId, changes)}
                          onDocuDownload = {(docu) => handleDocuDownload(docu)}
                          onDocuSave     = {(docu) => handleDocuAdd(docu)}
                          onDocuOpen     = {(docid) => handleDocuOpen(docid)}
                          onDocuRemove   = {(docId) => handleDocuRemove(docId)}
                          onProcessGetNextSerial = {(year, agreed) => handleGetNextSerial(year, agreed)}
                          canEdit={canEdit}
                          canPrint={canPrint}
                          />
      )
    }

    if (activeTabName==TAB_PENALTY_20) {
      return (
        loaded==false
        ? <LoadingBig/>
        : <ProcessPenalty process = {process}
                          processPenalty = {process.penalty_20}
                          agents  = {agents}
                          docs = {docs}
                          onPrint = {(file, docId) => handleFilePrint(file, docId)}
                          onProcessChange= {(changes) => handleProcessUpsave(changes, ACTION.PENALTY_STEP)}
                          onProcessFileChange= {(processFileId, changes) => handleProcessFileUpsave(processFileId, changes)}
                          canEdit={canEdit}
                          canPrint={canPrint}
                          />
      )
    }
    if (activeTabName==TAB_PENALTY_19) {
      return (
        loaded==false
        ? <LoadingBig/>
        : <ProcessPenalty process = {process}
                          processPenalty = {process.penalty_19}
                          agents  = {agents}
                          docs = {docs}
                          onPrint = {(file, docId) => handleFilePrint(file, docId)}
                          onProcessChange= {(changes) => handleProcessUpsave(changes, ACTION.PENALTY_STEP)}
                          onProcessFileChange= {(processFileId, changes) => handleProcessFileUpsave(processFileId, changes)}
                          canEdit={canEdit}
                          canPrint={canPrint}
                          />
      )
    }

    if (activeTabName==TAB_PENALTY_CALC) {
      return (
        loaded==false
        ? <LoadingBig/>
        : <ProcessCalcs process  = {process}
                        onChangeProcess = {(changes, action, confirmed) => handleProcessUpsave(changes, action, confirmed)}
                        onI5Change = {(year, changes, _confirmed) => handleTaxesI5Change(year, changes)}
                        onI5Save = {() => handleTaxesI5Save()}
                        onLUChange = {(changes, confirmed) => handleTaxesLUChange(changes, confirmed)}
                        onAEChange = {(val, name, _confirmed) => handleTaxesAEChange(val, name)}
                        onAEDetChange = {(year, changes, _confirmed) => handleTaxesAEDetChange(year, changes)}
                        onAESave = {() => handleTaxesAESave()}
                        onTOChange = {(val, name, confirmed) => handleTaxesTOChange(val, name, confirmed)}
                        onVTChange = {(val, name, confirmed) => handleTaxesVTChange(val, name, confirmed)}
                        onGoToInspect = {() => setTab(TAB_INSPECT)}
                        canEdit={canEdit}/>
      )
    }    

    if (activeTabName==TAB_PENALTY_DOC) {
      return (
        loaded==false
        ? <LoadingBig/>
        : <ProcessDocu process ={process}
                         onOpen  ={(docid) => handleDocuOpen(docid)}
                         onSave  ={(docu) => handleDocuAdd(docu)}
                         onRemove={(docid) => handleDocuRemove(docid)}
                         onDownload={(docu) => handleDocuDownload(docu)}
                         canEdit={canEdit}/>
      )
    }
  }

  return (
    <Page breads  = {breads}
          left    = {loaded
                     ? <ProcessResume process={process}/>
                      : null}
          title   = {`Expediente: ${title}`}
          actions = {loaded
                      ? <ProcessActions   process       = {process}
                                          event         = {event}
                                          onRemove      = {() => handleProcessRemove()} 
                                          onUndo        = {() => handleProcessUndo()} 
                                          onSaveNote    = {(v) => handleNoteSave(v)}
                                          onRemoveNote  = {(v) => handleNoteRemove(v)}
                                          onProcessDebug= {( )=> handleProcessDebug()}
                                          onEdit        = {() => setShowEdit(! showEdit)}
                                          onClose       = {() => setShowClose(! showClose)}
                                          onProcessPenalty={() => setShowPenalty(!showPenalty)}
                                          canEdit={canEdit}
                      />
                    : null}>
      {loaded
       ? <>
          {showEdit
          ? <Row>
              <Col xs="12">
                <ProcessEdit  process       = {process}
                                onProcessEdit = {(changes) => { handleProcessUpsave(changes, ACTION.EDIT); setShowEdit(false)}}
                                onCancel      = {() => setShowEdit(false)}
                                onProcessReopen = {() => handleProcessReopen()}
                                onProcessAsDisagree = {() => handleProcessAsDisagree()}
                                onProcessAsTest = {() => handleProcessAsTest()}
                                onProcessDropPenalty19 = {() => handleProcessDropPenalty19()}
                                onProcessDropPenalty20 = {() => handleProcessDropPenalty20()}
                                onProcessTogglePenalty19 = {(enabled) => handleProcessTogglePenalty19(enabled)}
                                onProcessTogglePenalty20 = {(enabled) => handleProcessTogglePenalty20(enabled)}
                                onProcessSwitchReductPaymentValue = {(v, g) => handleProcessSwitchReductPaymentValue(v, g)}/>
              </Col>
            </Row>
          : null
          }
          {(showClose || (process!=undefined && process.is_closed))
          ? <Row>
              <Col xs="12">
                <ProcessClose process       = {process}
                                onProcessClose = {(reason, explanation) => {handleProcessClose(reason, explanation); setShowClose(false)}}
                                onCancel      = {() => setShowClose(false)}/>
              </Col>
            </Row>
          : null
          }
          {showPenalty
          ? <Row>
              <Col xs="12">
                <ProcessPenaltyInit  process       = {process}
                                onPenaltyInit = {(penaltyType) => { handleProcessPenaltyInit(penaltyType); setShowPenalty(false)}}
                                onProcessChange={(changes) => {handleProcessUpsave(changes, ACTION.OPEN_PENALTY); setShowPenalty(false);}}
                                onCancel      = {() => setShowPenalty(false)}/>
              </Col>
            </Row>
          : null
          }
        </>
       : null
      }
      <Row>
        <Col xs="12">
          <Tabs defaultActiveTab = {visibleTabs.indexOf(tab)}
                onChangeTab      = {(t) => setTab(visibleTabs[t])}
                disabled         = {disabledTabs.map(t => visibleTabs.indexOf(t))}
                titles           = {visibleTabs}
                lightTabs        = {lightTabs}
                renderPanel      = {renderPanel}
          />
        </Col>
      </Row>
    </Page>    
  )
}

export default withContext(Process)
