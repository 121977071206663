import React from 'react'
import {FForm, FInputText, FInputSelect, FInputSelectSearch} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'

const EditionDetailsInfoForm = ({ agents, ordinances, edition, existingNames, onChange, onSave}) => {
  
  return (

    <FForm onCancel     = {undefined}
          onSave       = {onSave}>
            <>
              <FInputText name        = "name"
                          label       = "Nombre"
                          icon        = {<Icon icon={"edition"}/>}
                          value       = {edition.name}
                          required    = {true}
                          checkValue  = {(v) => existingNames.map((n) => n.toLowerCase()).indexOf(v.toLowerCase())==-1}
                          feedback    = {'Inserta un nombre válido y único'}
                          onChange    = {(v) => onChange('name', v)}
              />
              <FInputSelectSearch  
                          options     = {ordinances}
                          name        = 'ordinance_id'
                          label       = 'Ordenanza'
                          icon        = {<Icon icon={"ordinance"}/>}
                          value       = {edition.ordinance_id}
                          onChange    = {(v) => onChange('ordinance_id', v)}
                          feedback    = {"Seleciona una Ordenanza"}
                          clearable   = {true}
                          searchable  = {true}
                          required    = {true}
              />
              <FInputSelect  
                          options     = {agents}
                          name        = 'agent_id'
                          label       = 'Agente'
                          icon        = {<Icon icon={"agent"}/>}
                          value       = {edition.agent_id}
                          onChange    = {(v) => onChange('agent_id', v)}
                          feedback    = {"Seleciona un Agente"}
                          clearable   = {true}
                          required    = {true}
              />
            </>
    </FForm>
  )
}

export default EditionDetailsInfoForm




