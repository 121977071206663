

function getIconForFileType(ftype) {
  if (ftype) {
    const parts= ftype.split('/')
    if (parts.indexOf('image')>=0)
      return 'image'
  }
  return 'file'
}

function toMB(size) {
  let mb = 0
  if (parseInt(size) > 0) {
    mb = parseFloat(size / 1048576)
    if (parseFloat(mb.toFixed(2)) != parseFloat(mb.toFixed(0))) {
      if (parseFloat(mb.toFixed(1)) != parseFloat(mb.toFixed(0))) {
        return mb.toFixed(1)
      }
      return mb.toFixed(2)
    }
    return mb.toFixed(0)
  }
  return mb
}


export {getIconForFileType, toMB}