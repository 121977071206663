import React from 'react'
import Hyper from './base'

const HyperDoc = ({ id, name }) =>
  <Hyper bcolor  = "rgb(0, 102, 255)"
         icon    = "file-word"
         to      = {"/docs/det/" + id}
         text    = {name}
         permissionCode = "docs_read">
  </Hyper>

export default HyperDoc

