import React, {useState, useEffect, useCallback} from 'react'
import {useParams, useNavigate} from 'react-router-dom'

// components
import Page      from 'cli/ui/pages/layout/Page'
import {Row,Col} from 'reactstrap';
import {Card, CardBody, CardFooter} from 'cli/ui/components/cards'
import { ActionBack } from 'cli/ui/components/buttons/actions'
import { LoadingBig} from 'cli/ui/components/loading'
import { ButtonStepsStart, ButtonStepsEnd } from 'cli/ui/components/buttons'
import AELicenseImportStep1 from './AELicenseImportStep1'
import AELicenseImportStep2 from './AELicenseImportStep2'
import AELicenseImportStep3 from './AELicenseImportStep3'
import withContext from 'cli/context/withContext';

const shortIt = (s) => 
  s==undefined
    ? ''
    : s.length>30
      ? s.substr(0,30)+' ...'
      : s

const AELicenseImport = ({fetcher}) => {

  const params= useParams()
  const navigate= useNavigate()
  const [ordinance, setOrdinance]= useState(undefined)
  const [status, setStatus] = useState(0)
  
  const [step, setStep]= useState(1)
  

  const paramCouncilId = params.idCouncil
  const paramOrdinanceId = params.idOrdinance
  
  useEffect(() => {
    async function fetchData() {
      const res = await fetcher.get('/api/ordinance/detail', {id: paramOrdinanceId})
      const nOrdinances = res.data
      const nOrdinance = nOrdinances[0]      
      setOrdinance(nOrdinance)
      setStatus(1)
    }
    fetchData()

  }, [fetcher, paramOrdinanceId])  

  const getBreads = useCallback(() => {
    if (status==0)
      return null
    return [['/', 'Inicio'], ['/councils', 'Ayuntamientos'], 
            [`/councils/${paramCouncilId}`, ordinance.council_name], 
            [`/councils/${paramCouncilId}/ae/${paramOrdinanceId}`, shortIt(ordinance.name)], 'Importar Licencias']
  }, [paramCouncilId, paramOrdinanceId, ordinance, status])



  const handleUploadTxt = async (file) => {
    setStep(2)    
    const res= await fetcher.post('/api/ae/license/import_data', {council_id: paramCouncilId, ordinance_id: paramOrdinanceId, buffer: Object.values(file.buffer)})
    
    if (! res.data.ok) {
      alert(res.data.msg)
      setStep(1)
    } else {
      setStep(3)
    }
  }  

  return (
    <Page breads = {getBreads()}
          title  = {'Importar Licencias'}
          actions= {<>
                    {status==1
                     ? <ActionBack to={`/councils/${paramCouncilId}/ae/${paramOrdinanceId}`} label="A la ordenanza"/>
                     : null
                    }
                    </>}>
      <Row>
        <Col xs="12">
          <ul className="progress-indicator">
            <li className={`${step<1 ? 'active' : step>=1 ? 'completed' : ''}`}> <span className="bubble"></span>Seleccione el fichero a cargar</li>
            <li className={`${step<2 ? 'active' : step>=2 ? 'completed' : ''}`}> <span className="bubble"></span>Importando licencias...</li>
            <li className={`${step<3 ? 'active' : step>=3 ? 'completed' : ''}`}> <span className="bubble"></span>Licencias importadas!</li>
          </ul>          
        </Col>

        <Col xs="12">
          <Card>
            <CardBody>
              {step == 0 &&
                <LoadingBig />
              }

              {step == 1 &&
                <>
                  <h2>Seleccione el fichero a cargar</h2>
                  <AELicenseImportStep1 onUpload ={(file) => handleUploadTxt(file)}/>
                </>
              }


              {step == 2 &&
                <>
                  <h2>Importando licencias...</h2>
                  <AELicenseImportStep2/>
                </>
              }

              {step == 3 &&
                <>
                  <h2>Licencias importadas!</h2>
                  <AELicenseImportStep3 ordinance={ordinance}/>
                </>
              }
            </CardBody>


            <CardFooter>
              {step == 1 &&
                <ButtonStepsStart
                  onNext   = {() => setStep(2)}
                  disabled = {true}
                />
              }

              {step == 3 &&
                <ButtonStepsEnd
                  onDone   = {() => navigate(`/councils/${paramCouncilId}/ae/${paramOrdinanceId}`)}
                  disabled = {false}
                />
              }
            </CardFooter>                  
          </Card>
        </Col>              


      </Row>
    </Page>
  )
}

export default withContext(AELicenseImport)
        
        
        
        
