import React from 'react'
import Icon from 'cli/ui/components/icon'
import CardActions from './CardActions'
import {SimpleMessage} from 'cli/ui/components/alerts'


class CardHelp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      helpShown: false,
      timer: undefined
    };
  }

  toggleHelp(e) {
    e.stopPropagation();

    if (this.state.helpShown) {
      this.hideHelp()
    } else {
      const self = this;
      self.setState({
        helpShown: true,
        timer: setTimeout(function () {
          self.setState({
            helpShown: false
          })
        }, 3000)
      })
    }
  }

  hideHelp(e) {
    if (e) e.stopPropagation();
    clearTimeout(this.state.timer)
    this.setState({
      helpShown: false,
      timer: undefined
    })
  }


  render() {

    return (
      <CardActions id={'card_actions_help'}
        onClick={(e) => { this.toggleHelp(e) }}
        style={{ cursor: "pointer" }}>
        <small className="text-muted">
          <Icon icon="info" />
        </small>

        <SimpleMessage show={this.state.helpShown}
          onClose={() => this.hideHelp()}
          title={this.props.title}
          message={this.props.text}>
        </SimpleMessage>

      </CardActions>
    )
  }
}


export default CardHelp


