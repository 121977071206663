import React, {useState} from 'react'
import { FForm, FInputFile} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'

const AELicenseImportStep1 = ({onUpload}) => {
    const [file, setFile]= useState({})

    const handleChange = (nfile) => {
        setFile(nfile)
        onUpload(nfile)
    }

    return (
        <FForm onCancel     = {undefined}
            onSave       = {undefined}>
            <FInputFile   name        = "buffer"
                        label       = {undefined}
                        value       = {file}
                        onChange    = {(v) => handleChange(v)}
                        onDownload  = {undefined}
                        accept      = ".txt"
                        icon        = {<Icon icon="file"/>}
                        autocomplete= "off"
                        />
        </FForm>
    )
}

export default AELicenseImportStep1
        
        
        
        
