import React from 'react'
import {asPrice, asDecimal} from 'farrapa/numbers'
import TaxTable from '../common/TaxTable'
import TaxHeaderFields from'../common/TaxHeaderFields'
import {uvl} from 'farrapa/commons'

const ProcessVTTaxTable = ({ process, canEdit }) => {
  return (
    <>
      <TaxHeaderFields title="Declarados">
        <TaxTable className='afi_taxes_table'>
            <thead>
              <tr>
                <th>Años</th>
                <th className="right">Coeficiente incremento</th>
                <th className="right">Incremento</th>
                <th className="right">Base imponible</th>
                <th className="right">Tipo Reducción</th>
                <th className="right">Base Imponible reducida</th>
                <th className="right">Tipo Impositivo</th>
                <th className="right">Cuota Tributaria</th>
              </tr>
            </thead>
            <tbody>
              <tr className="afi_taxes_process.tax">
                <td>{process.tax.years}</td>
                <td  className="right"> 
                  {`${asDecimal(uvl(process.tax.incr_coefficient,0))} %`}
                </td>
                <td  className="right"> 
                  {`${asDecimal(process.tax.increment)} %`}
                </td>
                <td  className="right"> 
                  {asPrice(process.tax.base_amount, 2)}
                </td>
                <td  className="right"> 
                {`${asDecimal(uvl(process.tax.discount_rate,0))} %`}
                </td>
                <td  className="right"> 
                  {asPrice(process.tax.base_discounted)}
                </td>
                <td  className="right"> 
                {`${asDecimal(uvl(process.tax.tax_rate,0))} %`}
                </td>
                <td  className="right"> 
                  {asPrice(process.tax.total)}
                </td>
              </tr> 
            </tbody>
            <tfoot>
              <tr>
              </tr>
            </tfoot>
          </TaxTable>
        </TaxHeaderFields>

    </>        
  )
}


export default ProcessVTTaxTable




