import React from "react"
import CalendarBodyRowCell from './CalendarBodyRowCell'

const CalendarBodyRow= ({month, dayRow, selectedDate, hoveredEvent, onDateClick, onEventClick, onEventHover, onMoreClick}) => 
  <div className="row">
    {dayRow.map((dr) => 
      <CalendarBodyRowCell key         = {`calendar_cell_day_${dr.day}`}
                           day         = {dr.day}
                           events      = {dr.events || []}
                           month       = {month}
                           selectedDate= {selectedDate}
                           hoveredEvent= {hoveredEvent}
                           onDateClick = {onDateClick}
                           onEventClick= {onEventClick}
                           onEventHover= {onEventHover}
                           onMoreClick = {onMoreClick}
                           />  
    )}
  </div>


export default CalendarBodyRow