import React     from 'react'
import PropTypes from 'prop-types'

const IconBase = props => {
  const styles = {
    svg: props.inline===true
         ? {marginRight  : '0.5em',
            marginTop    : '0.1em',
            verticalAlign: 'middle'}
         : {display      : 'inline-block',
            verticalAlign: 'middle'},
    path: {
      fill: props.color || 'black',
    },
  }

  const lpaths = typeof props.icon=="string"
                 ? [props.icon]
                 : props.icon

  return (
    <svg
      style={styles.svg}
      width={`${props.size || 16}px`}
      height={`${props.size || 16}px`}
      viewBox="0 0 1024 1024"
    >
      {lpaths.map((p, i) => 
        <path key={i}
              style={styles.path}
              d={p}/>
      )}
    </svg>
  )
}

IconBase.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string), PropTypes.object]).isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  inline: PropTypes.bool
}


export default IconBase