import React, {useState, useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import {intre_noon, intre_get_year} from 'intre'

// components
import Page      from 'cli/ui/pages/layout/Page'
import {Row,Col} from 'reactstrap'
import { ButtonStepsMiddle, ButtonStepsStart, ButtonStepsEnd } from 'cli/ui/components/buttons'
import { LoadingBig } from 'cli/ui/components/loading'
import { ActionBack } from 'cli/ui/components/buttons/actions'
import NewEditionStep1 from './NewEditionStep1'
import NewEditionStep2 from './NewEditionStep2'
import NewEditionStep3 from './NewEditionStep3'
import {TAX_NAMES} from 'bl/ns/taxes'
import { arrayRemove } from 'farrapa/collections'
import withContext from 'cli/context/withContext'

const NewEdition = ({fetcher}) => {
  const location= useLocation()
  const navigate= useNavigate()

  const taxCode= location.pathname.split('/')[2]

  const [edition, setEdition]= useState({
    name: '',
    tax_code: taxCode,
    processes: [],
    process_type: '',
    repeat_tax_payer: false
  })
  const [years, setYears]= useState({
    from: intre_get_year(intre_noon()) - 5,
    to: intre_get_year(intre_noon()) - 1
  })

  const [step, setStep]= useState(0)

  const [ordinances, setOrdinances]= useState([])
  const [agents, setAgents]= useState({})
  const [taxPayers, setTaxPayers]= useState({})

  const [ordinance, setOrdinance]= useState({})
  
  useEffect(() => {
    const fetchData = async () => {
      const res= await fetcher.get('/api/ordinance/detail', {tax_code: taxCode})
      setOrdinances(res.data)
      setAgents(await fetcher.key_list('/api/agent'))
      
      setStep(1)
    }
    
    fetchData()
  }, [fetcher, taxCode])

  useEffect(() => {
    const fetchOrdinance = async () => {
      let nOrdinance= {}
      if (edition.ordinance_id!=undefined) {
        const res = await fetcher.get('/api/ordinance/detail', {id: edition.ordinance_id})
        const nOrdinances = res.data
        nOrdinance = nOrdinances[0]        
      }
      setOrdinance(nOrdinance)

      const params= {
        tax_code: taxCode, 
        council_id: nOrdinance.council_id, 
        ordinance_id: edition.ordinance_id,
        repeat_tax_payer: edition.repeat_tax_payer
      }
      
      const res= await fetcher.get('/api/tax_payer/taxpayers_for_edition', params)
      setTaxPayers(res.data)
      
    }
    fetchOrdinance()
  }, [taxCode, fetcher, edition.ordinance_id, edition.repeat_tax_payer])


  const readyToGo = (s) => {
    if (s == 1) {
      if (edition.name == undefined || edition.name.length == 0) {
        return false
      }
      if (!edition.ordinance_id) {
        return false
      }
      if (!edition.agent_id) {
        return false
      }
      if (!years.from || !years.to) {
        return false
      }
    }
    return true
  }

  const goToEdition = (edition_id) => {
    
    if (edition_id!=undefined) {
      navigate(`/taxes/${taxCode}/edition/${edition_id}`)
    } else {
      navigate(`/taxes/${taxCode}`)
    }
  }

  const handleChangeEdition = (field, value) => {
    const nEdition= {...edition}
    nEdition[field]= value
    setEdition(nEdition)
  }

  const handleChangeYears = (field, value) => {
    const nYears= {...years}
    nYears[field]= value
    setYears(nYears)
  }

  const handleChangeStep = (s) => {
    setStep(s)
  }

  const handleCreateTaxPayer = async (name) => {
    const taxPayer= {name}
    const taxPayerId= await fetcher.upsave('/api/tax_payer', taxPayer)


    setTaxPayers([
      ...taxPayers,
      {id: taxPayerId, name}
    ])
    
    return taxPayerId
  }

  const handleAddProcess = (p) => {
    const nProc= {...p}
    nProc.edition_id = edition.id
    nProc.tax_code = taxCode
    nProc.status = 1
    nProc.year_from= years.from
    nProc.year_to= years.to

    const nEdition= {...edition}
    nEdition.processes.push(nProc)
    
    setEdition(nEdition)
  }

  const handleRemoveProcess = (tpid) => {
    const nEdition= {...edition}
    arrayRemove(
    nEdition.processes,
      nEdition.processes.find((p) => p.taxpayer_id == tpid)
    )
    setEdition(nEdition)    
  }

  const handleFinalSave = async () => {
    const res = await fetcher.post('/api/edition/save_with_processes', edition)
    const nid = res.data
    goToEdition(nid)
  }

  const renderStepFirst = () => {
    return (
      <>
        <h2 style={{marginBottom: "2em"}}>Datos de tirada</h2>
        <NewEditionStep1
          taxCode       = {taxCode} 
          editing       =  {true}
          edition       = {edition}
          years         = {years}
          ordinanceList = {ordinances}
          agentList     = {agents}
          onChangeEdition= {(field, value) => { handleChangeEdition(field, value) }}
          onChangeYears= {(field, value) => { handleChangeYears(field, value) }}
        />
        <div style={{textAlign: "center", marginTop: "2em"}}>
          <ButtonStepsStart
            onNext   = {() => handleChangeStep(2)}
            disabled = {!readyToGo(step)}
          />
        </div>
      </>
    )
  }

  const renderStepSecond = () => {
    return (
      <>
        <h2 style={{marginBottom: "2em"}}>Contribuyenyes / Expedientes</h2>
        <NewEditionStep2
          taxCode         = {taxCode} 
          editing         = {true}
          edition         = {edition}
          taxpayerList    = {taxPayers}
          onCreateTaxPayer= {handleCreateTaxPayer}
          onAddProcess    = {(p) => handleAddProcess(p)}
          onRemoveProcess = {(tpid) => handleRemoveProcess(tpid)}
        />
        <div style={{textAlign: "center", marginTop: "2em"}}>
          <ButtonStepsMiddle
            onPrev   = {() => handleChangeStep(1)}
            onNext   = {() => handleChangeStep(3)}
            disabled = {edition.processes.length == 0}
          />        
        </div>
      </>      
    )
  }

  const renderStepThird = () => {
    return (
      <>
        <h2 style={{marginBottom: "2em"}}>Confirmar</h2>
        <NewEditionStep3
          taxCode       = {taxCode} 
          edition       = {edition}
          ordinance     = {ordinance}
          taxpayerList  = {taxPayers}
        />
        <div style={{textAlign: "center", marginTop: "2em"}}>
          <ButtonStepsEnd
            onPrev   = {() => handleChangeStep(2)}
            onDone   = {() => handleFinalSave()}
            disabled = {!readyToGo(step)}
          />        
        </div>
      </>      
    )
  }  

  return (
    <Page title="Nueva tirada"
          breads={[['/', 'Inicio'], ['/taxes', 'Tributos'], [`/taxes/${taxCode}`, TAX_NAMES[taxCode]], ['Nueva Tirada']]}
          actions={<>
                      <ActionBack to={`/taxes/${taxCode}/edition`}
                                  label="Lista de tiradas"/>                       
                   </>}>
      <Row>
        <Col xs="12">
          <ul className="progress-indicator">
            <li className={`${step==1 ? 'active' : step>1 ? 'completed' : ''}`}> <span className="bubble"></span>Datos de tirada</li>
            <li className={`${step==2 ? 'active' : step>2 ? 'completed' : ''}`}> <span className="bubble"></span>Contribuyenyes / Expedientes</li>
            <li className={`${step==3 ? 'active' : step>3 ? 'completed' : ''}`}> <span className="bubble"></span>Confirmar</li>
          </ul>          
        </Col>
      </Row>

      <Row>

        <Col xs="12">
          {step == 0
            ? <LoadingBig />
            : step == 1
              ? renderStepFirst()
              : step==2
                ? renderStepSecond()
                : renderStepThird()
          }
        </Col>
      </Row>
    </Page>
  )
}


export default withContext(NewEdition)




