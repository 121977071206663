import React from 'react'
import {Row, Col} from 'reactstrap'
import Page from 'cli/ui/pages/layout/Page'
import {MenuCard} from 'cli/ui/components/cards'

const DocsIndex = () => {
  return (
      <Page breads = {[['/', 'Inicio'], ['Gestión Documental']]}
            title  = {'Gestión Documental'}>
        <Row>
          <Col xs="12" sm="6"> 
            <MenuCard color    = "success"
                      icon     = "edition"
                      link     = {`/docs/inspect`}
                      title    = "Inspección">
            </MenuCard>
          </Col>
          
          <Col xs="12" sm="6"> 
            <MenuCard color    = "info"
                      icon     = "plus"
                      link     = {`/docs/penalty`}
                      title    = "Sancionador">
            </MenuCard>              
          </Col>        
                    
        </Row>
      </Page>
  )
}


export default DocsIndex



