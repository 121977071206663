import React from 'react'
import TaxSection from '../common/TaxSection'
import ProcessVTTaxFields from './ProcessVTTaxFields'
import ProcessVTTaxTable from './ProcessVTTaxTable'

const ProcessVTTax = ({ process, onChange, canEdit }) => {

  return (
    <TaxSection title="Tasas">
      <ProcessVTTaxFields process  = {process}
                          onChange = {onChange}
                          canEdit  = {canEdit}/>
      <ProcessVTTaxTable  process  = {process}
                          canEdit  = {canEdit}/>
    </TaxSection>
  )
}


export default ProcessVTTax






