import React from 'react'
import ActionButton from './ActionButton'

const ActionChat = ({ onClick, enabled, label, hasNotes }) =>
  <ActionButton color  = {hasNotes ? 'green' : 'gray'}
                label={label}
                icon   = {hasNotes ? 'bubble' : 'bubble2'}
                onClick= {(e) => { onClick(e) }}
                enabled= {enabled != undefined ? enabled : true}>
  </ActionButton>

export default ActionChat
