import React, {useState} from 'react'

import withContext from 'cli/context/withContext'
import { IBox } from 'cli/ui/components/ibox'
import { ActionEdit } from 'cli/ui/components/buttons/actions'
import UserSettingsPasswordEdit from './UserSettingsPasswordEdit'

const UserSettingsPassword = ({fetcher, user}) => {

  const [showPasswordEdit, setShowPasswordEdit] = useState(false)
  
  const passwordChange= async (pwds) => {
    try {
      const params= {username: user.username, passwords: pwds}
      const _res= await fetcher.post('/user/chpwd', params)
      setShowPasswordEdit(false)
    } catch(e) {}
    
  }

  return (
    <IBox title="Contraseña"
          actions={
            showPasswordEdit
            ? null
            : <ActionEdit key={'user_settings_pwd_edit'}
                            onClick={() => setShowPasswordEdit(!showPasswordEdit)} 
                            label="Modificar"/>
          }>

      {showPasswordEdit 
        ?
          <UserSettingsPasswordEdit onSave   = {(pwds) => passwordChange(pwds)}
                                    onCancel = {() => setShowPasswordEdit (false)}
          />
        : null
      }
    </IBox>
  )
}

export default withContext(UserSettingsPassword)



