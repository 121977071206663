import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import Icon from 'cli/ui/components/icon'

const ClickableButton = (color, icon, label, enabled, onClick) => {
  const handleClick = (e) => {
    if (enabled != undefined && !enabled) {
      e.stopPropagation();
    } else {
      if (onClick != undefined)
        onClick(e)
    }
  }

  const dstyle= enabled ? {cursor: "pointer"} : {opacity: "0.5"}

  return (
    <div className= {`afi-action-button ${label ? 'with-label' : ''} ${enabled === false ? 'disabled' : ''}`}
         style    = {dstyle} 
         onClick  = {handleClick}>
      <span style={{ color: color, cursor: "pointer" }}>
          <Icon icon={icon} color={color}/>
          {label!=undefined ? <span className="label">{label}</span> : null}
      </span>
    </div>
  )
}


const RefButton = (color, icon, label, to, enabled) => 
  <Link className = {`afi-action-button ${label ? 'with-label' : ''} ${enabled === false ? 'disabled' : ''}`}
        style     = {{color}}
        to        = {to}>
    <Icon icon     = {icon} color={color}/>
    {label!=undefined ? <span className="label">{label}</span> : null}
  </Link>


const ActionButton = ({enabled, color, icon, label, to, onClick}) => {
   if (to!=undefined) {
     return RefButton(color|| 'black', icon, label, to, enabled)
   }  
   
   return ClickableButton(color|| 'black', icon, label, enabled, onClick)
}

ActionButton.propTypes= {
  icon    :  PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  label   : PropTypes.string,
  to : function(props, _propName, _componentName) {
      if (! ('to' in props) && ! ('onClick' in props)) {
          return new Error('Please provide a {to} string or a {onClick} handler to the ActionButton');
      }
  },
  onClick : PropTypes.func,
  enabled : PropTypes.bool,
  color   : PropTypes.string
}

export default ActionButton



