import React, {useState} from 'react'
import {asPrice} from 'farrapa/numbers'
import ProcessI5TaxLines from './ProcessI5TaxLines'
import TaxTable from '../common/TaxTable'

const ProcessI5TaxTable = ({ process, onChange, canEdit }) => {
  const [showInterests, setShowInterests]= useState(false)
  return (
    <TaxTable className='afi_taxes_table'>
        <thead>
          <tr>
            <th>Año</th>
            <th>{process.tax.earnings_label || 'Ingresos'}</th>
            <th>{process.tax.expenses_label || 'Gastos'}</th>
            <th className="right">BI Comp.</th>
            <th>BI Decl.</th>
            <th className="right">Diferencia</th>
            <th className="right">T.I.</th>
            <th className="right">Cuota</th>
            <th className={`right interests ${process.tax.ready_for_interests.ok ? 'ready' : 'not-ready'} ${showInterests ? 'detail-show' : 'detail-hide'}`}>
              {process.tax.ready_for_interests.ok
              ? <div onClick={() => setShowInterests(! showInterests)}>
                <a className={`${showInterests ? 'active' : ''}`}>
                  Intereses
                </a> 
                <span>{showInterests 
                  ? "⇧"
                  : "⇩"
                }</span>                
                </div>
              : "Intereses"
              }
            </th>
            <th className="right">Deuda Total</th>
          </tr>
        </thead>
        <tbody>
          <ProcessI5TaxLines tax={process.tax}
                             onChange={(year, val, name, confirmed) => onChange(year, val, name, confirmed)}
                             showInterests={showInterests}
                             canEdit       = {canEdit}

          />            
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td className="right">{asPrice(process.tax.earnings)}</td>
            <td className="right">{asPrice(process.tax.expenses)}</td>
            <td className="right">{asPrice(process.tax.checked_taxbase)}</td>
            <td className="right">{asPrice(process.tax.declared_taxbase)}</td>
            <td className="right">{asPrice(process.tax.difference)}</td>
            <td></td>
            <td className="right">{asPrice(process.tax.fees)}</td>
            <td className="right" style={process.tax.ready_for_interests.ok ? {} : {color: "gray"}}>
              {asPrice(process.tax.interests)}
            </td>
            <td className="right">{asPrice(process.tax.total)}</td>
          </tr>
        </tfoot>
      </TaxTable>
  )
}


export default ProcessI5TaxTable




