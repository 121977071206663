/*
  https://blog.flowandform.agency/create-a-custom-calendar-in-react-3df1bfd0b728

  Modified to use moment.js
*/

import React, {useState, useEffect} from "react";
import {intre_first_of_month,
  intre_last_of_month, 
  intre_first_of_week, 
  intre_last_of_week,
  intre_noon,
  intre_add_months,
  intre_sub_months  } from 'intre'
import {uvl} from 'farrapa/commons'
import CalendarHeader from './CalendarHeader'
import CalendarDays from './CalendarDays'
import CalendarBody from './CalendarBody'
import CalendarShowEventDetails2 from './CalendarShowEventDetails2'
import CalendarShowDayEvents from './CalendarShowDayEvents'

const config= {
  header: {
    format: 'MMMM YYYY',
  },
  days: {
    format: 'dddd'
  }
}

//
// TODO filter current month events
//
const monthGetBounds = (currentMonth) => {
  const monthFirst = intre_first_of_month(currentMonth)
  const monthLast  = intre_last_of_month (monthFirst)
  const startDate  = intre_first_of_week (monthFirst)
  const endDate    = intre_last_of_week  (monthLast)
  return {
    first: monthFirst,
    start: startDate,
    end: endDate
  }
}

const monthFilterEvents = (events, monthBounds) => {
  return events.filter((ev) => {
    if (ev.dstart<=monthBounds.end) {
      const dend= uvl(ev.dend, ev.dstart)
      if (dend>=monthBounds.start) {
        return true
      }
    }
  })
}

const dayFilterEvents = (events, day) => {
  return events.filter((ev) => {
    const dend= uvl(ev.dend, ev.dstart)
    if (intre_noon(ev.dstart)<=day && intre_noon(dend)>=day) {
      return true
    }
  })
}


const Calendar = ({userCalendars, events, onEventUpsave, onEventRemove, uid}) => {
  
  const [currentMonth, setCurrentMonth]= useState( intre_noon() )
  const [selectedDate, setSelectedDate]= useState( intre_noon() )
  const [monthBounds, setMonthBounds]= useState({})
  const [filteredEvents, setFilteredEvents]= useState([])

  

  const [showEventDetail, setShowEventDetail]= useState(undefined)
  const [showEventsList, setShowEventsList]= useState(undefined)
  
  useEffect(() => {
    const nMonthBounds= monthGetBounds(currentMonth)
    const nEvents= monthFilterEvents(events, nMonthBounds)
    
    setMonthBounds(nMonthBounds)
    setFilteredEvents(nEvents)
  }, [currentMonth, events])



  const onNextMonth = () => {
    setCurrentMonth(intre_add_months(currentMonth, 1))
  }

  const onPrevMonth = () => {
    setCurrentMonth(intre_sub_months(currentMonth, 1))
  }

  const onDateClick = (day) => {
    setSelectedDate(day)
    onEventClick({dstart: day}, day)
  }

  const onEventClick = (event, day) => {
    const nDetail= {
      day,
      event
    }

    setShowEventDetail(nDetail)
  }


  const onMoreClick = (day) => {
    const nList= {
      day,
      events: dayFilterEvents(filteredEvents, day)
    }

    setShowEventsList(nList)
  }

  return (
    <div className="calendar">
      <CalendarHeader config       = {config}
                      monthBounds  = {monthBounds}
                      onPrevMonth  = {onPrevMonth}
                      onNextMonth  = {onNextMonth}/>
      <CalendarDays   config       = {config}
                      monthBounds  = {monthBounds}/>

      <CalendarBody   currentMonth = {currentMonth}
                      selectedDate = {selectedDate}
                      events       = {filteredEvents}
                      monthBounds  = {monthBounds}
                      onDateClick  = {onDateClick}
                      onEventClick = {onEventClick}
                      onMoreClick  = {onMoreClick}
                      />
        {showEventDetail?.event!=undefined
         ? 
          <CalendarShowEventDetails2 
                {...showEventDetail}
                //calendars= {userCalendars}
                uid = {uid}
                onClose={() => setShowEventDetail(undefined)} 
                //onEventUpsave={onEventUpsave}                       
                onEventRemove={onEventRemove}
          />
         : null}
        <CalendarShowDayEvents 
               {...showEventsList}
               onClose={() => setShowEventsList(undefined)} 
               onEventClick={onEventClick}/>
    </div>
  )
}

export default Calendar