import React, {useEffect, useState} from 'react'
import { ActionEye } from 'cli/ui/components/buttons/actions'
import { FInputText } from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'
import { Button } from 'cli/ui/components/buttons'

function _sortComparer(a,b){
  return a.name.localeCompare(b.name)
}

function _filterCouncil(c, f) {
  if (! f) {
    return true
  }

  if (c.name.toLowerCase().indexOf(f.toLowerCase())>=0) {
    return true
  }
  if (c.province_name.toLowerCase().indexOf(f.toLowerCase())>=0) {
    return true
  }  
  return false
}

const _UserDetailsCouncilLine = ( {user, council, onCouncilToggle, isEven} ) => {
  let userCouncilId 
  try {
    userCouncilId = user.councils.filter(c => c.council_id == council.id )[0].id
  } catch(_) {}

  const isOn = userCouncilId!=undefined
  return (
    <div style={{display: "flex", backgroundColor: isEven ? '#f0f0f0' : ''}}>
      <div style={{flex: "1", alignSelf: "center"}}>
        {council.name}
      </div>
      <div style={{flex: "1", alignSelf: "center"}}>
        {council.province_name}
      </div>            
      <div style={{marginRight: "auto", alignSelf: "center"}}>
        <ActionEye 
          onClick   = {() => onCouncilToggle(userCouncilId, user.id, council.id, !isOn)}
          enabled   = {true}
          isOn      = {isOn}
          label     = {undefined}/>
      </div>
    </div>

  ) 
}


const UserDetailsCouncils = ({ user, councilList, onCouncilToggle, disabled}) => {
  const [councilFilt, setCouncilFilt]= useState(undefined)
  const [sortedCouncils, setSortedCouncils]= useState([])
  const [filteredCouncils, setFilteredCouncils]= useState([])
  const [startEditing, setStartEditing]= useState(user.councils!=undefined)

  useEffect(() => {
    setSortedCouncils(
      councilList.sort(_sortComparer)
    )
  }, [councilList])

  useEffect(() => {
    const nFilteredCouncils= sortedCouncils.filter((council) => _filterCouncil(council, councilFilt) )
    setFilteredCouncils(nFilteredCouncils)
  }, [sortedCouncils, councilFilt])


  return (
    <>

      {!startEditing
       ?
       <div>
         <p>
          Actualmente, este usuario puede ver todos los ayuntamientos.
         </p>
         <p>
          <Button 
            icon="pencil"
            color="primary"
            disabled={disabled}
            onClick={() => setStartEditing(true)}>Editar lista</Button>
         </p>
       </div>
       :
       <>

      
        <div>
          <FInputText
            name        = 'council'
            label       = {undefined}
            icon        = {<Icon icon={"council"}/>}
            value       = {councilFilt}
            onChange    = {(v) => setCouncilFilt(v)}
            clearable   = {true}
            placeholder = "Busca ayuntamientos por nombre o provincia"
            showValidity= {0}
          />
        </div>
        <div style={{maxHeight: "500px", overflow: "scroll"}}>

          {filteredCouncils.map((council, cidx) => 
            <_UserDetailsCouncilLine
              key={`user_council_${council.id}`}
              user={user}
              council={council}
              onCouncilToggle={onCouncilToggle}
              isEven={cidx%2 === 0}/>
          )}
        </div>
      </>
      }
    </>


  )
}

export default UserDetailsCouncils

