import React from 'react'
import { Route } from 'react-router-dom'

import {TAX_NAMES} from 'bl/ns/taxes'

import Taxes              from 'cli/ui/pages/taxes/Taxes'
import TaxIndex           from 'cli/ui/pages/taxes/TaxIndex'
import Editions           from 'cli/ui/pages/taxes/edition/Editions'
import NewEdition         from 'cli/ui/pages/taxes/edition/new/NewEdition'
import EditionDetails     from 'cli/ui/pages/taxes/edition/det/EditionDetails'
import EditionProcesses   from 'cli/ui/pages/taxes/edition/processes/EditionProcesses'
import Process            from 'cli/ui/pages/taxes/process/det/Process'
import ProcessSearch      from 'cli/ui/pages/taxes/process/search/ProcessSearch'


const TaxRoutes = (permiss) => {
  if (!permiss.can_user_read_processes()) {
    return []
  }

  const taxRoutes=  
    Object.entries(TAX_NAMES)
          .map(([taxCode, taxName]) => {
    return [
      <Route key={`tax_${taxCode}_idx`} path={`/taxes/${taxCode}`} element={<TaxIndex/>}/>,

      ...permiss.can_user_edit_processes()
      ? [<Route key={`tax_${taxCode}_newedition`} path={`/taxes/${taxCode}/newedition`} element={<NewEdition/>}/>]
      : [],
      <Route key={`tax_${taxCode}_edition`} path={`/taxes/${taxCode}/edition`} element={<Editions taxCode={taxCode} showHistorical={false}/>}/>,
      <Route key={`tax_${taxCode}_procs`} path={`/taxes/${taxCode}/edition/:idEdition`} element={<EditionProcesses/>}/>,
      <Route key={`tax_${taxCode}_edidet`} path={`/taxes/${taxCode}/edition/:idEdition/det`} element={<EditionDetails/>}/>,
      <Route key={`tax_${taxCode}_process`} path={`/taxes/${taxCode}/edition/:idEdition/process/:idProcess`} element={<Process/>}/>,
      <Route key={`tax_${taxCode}_historical`} path={`/taxes/${taxCode}/historical`} element={<Editions taxCode={taxCode} showHistorical={true}/>}/>
    ]})
      
    
  return [
    <Route key="tax_idx" path={'/taxes'} element={<Taxes/>}/>,
    ...taxRoutes,
    <Route key="tax_search" path={'/taxes/search'} element={<ProcessSearch/>}/>
  ]
}

export default TaxRoutes


