import React, {Component} from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="app-footer">
        <span><a href="mailto:help@afialapis.com"><strong>Soporte</strong></a></span>
        <span className="ml-auto"><a href="http://www.afialapis.com">&copy; Afialapis</a></span>
      </footer>
    )
  }
}

export default Footer;
