import React from 'react'
import ActionButton from './ActionButton'
import {uvl} from 'farrapa/commons'

const BTN_STEPS= [
  { color: '#ff6699', label: ''       , icon: 'trash' },
  { color: '#ff0000', label: 'Seguro?', icon: 'trash' },
  { color: '#000000', label: ''       , icon: 'waiting' },
  { color: '#000000', label: 'Error!' , icon: 'cross' }
]


export default class ActionRemove extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0
    };
  }

  handleClick(ev) {
    if (this.state.step<3) {
      this.setState({
        step: this.state.step + 1
      });

      if (this.state.step == 1) {
        const result= this.props.onClick(ev)
        if (result!=undefined) {
          if (result == Promise.resolve(result)) {
            result.then((ok) => {
              if (! ok) {
                this.setState({step: 3});
              }
            })
          } else {
            if (! result) {
              this.setState({step: 3});
            }
          }        
        }
      }
    }
  }

  render() {
    const enabled = uvl(this.props.enabled, true);
    const props = BTN_STEPS[this.state.step]
    if (!props.label && this.props.label!=undefined) {
      props.label= this.props.label
    }

    return (
        <ActionButton {...props}
                      onClick = {() => this.handleClick()}
                      enabled = {enabled}>
        </ActionButton>
    )
  }
}
