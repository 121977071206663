import React from 'react'


const CardActions= ({children}) => 
  <div className="card-actions">
    <div className="card-actions-cont">    
      {children.map!=undefined
        ? children.map((ch, i) => 
            <div key={i}
                 className="card-actions-group">
              {ch}
            </div>
          )
        : <div className="card-actions-group">
            {children}
          </div>
      }
    </div>
  </div>

export default CardActions


