import React from 'react'
import { ActionDetails, ActionRemove } from 'cli/ui/components/buttons/actions'
import NiceList from 'cli/ui/components/nice_list'
import { HyperRole } from 'cli/ui/components/hyper'

const roleFields= [
  /* eslint react/display-name:0 */
  {
    label: "Perfil",
    className: "main", 
    value: (role) => role.description,
    render: (role) =>
      <HyperRole key={`role_list_row_${role.id}_id`} id={role.id} name={role.description}/>
  }, 
  {
    label: "Usuarios",
    value: (role) => role.user_count,
  },   
  {
    label: "Permisos",
    value: (role) => `${role.permissions.length} de ${role.all_permission_count}`,
  },
  {
    label: "Acciones", 
    className: "right", 
    render: (role, onEvent) => 
      <>
        <ActionDetails to={"/settings/accontrol/roles/" + role.id} />
        {role.protected==1
         ? null
         : <ActionRemove onClick={() => onEvent('remove', {id: role.id})}/>
        }
      </>
  }
]

const makeKey= (role) =>
  `role_list_row_${role.id}`


const RolesList = ({ roles, onRemove }) =>
  <NiceList fields     = {roleFields}
            initialSort= {[0, "asc"]}
            data       = {roles}
            makeKey    = {makeKey}
            onEvent    = {(ev, params) => {
              if (ev=='remove') {
                onRemove(params.id)
              }
            }}
            transparent
  />

export default RolesList
