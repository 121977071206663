import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const PopupBase = ({show, title, children, onClose, renderFooter}) => 
  <div>
    <Modal isOpen            = {show} 
           toggle            = {() => onClose()} 
           backdrop          = {true}
           modalTransition   = {{ timeout: 20 }} 
           backdropTransition= {{ timeout: 10 }}>
      
      {title!=undefined
        ? <ModalHeader toggle={() => onClose()}>{title}</ModalHeader>
        : null}

      <ModalBody>
        {children}
      </ModalBody>
      
      <ModalFooter>
        {renderFooter!=undefined
          ? renderFooter()
          : null}
      </ModalFooter>
      
    </Modal>
  </div>

export default PopupBase