import React from 'react'
import ActionButton from './ActionButton'

const ActionHistory = ({ onClick, to, enabled, isHistorical, label }) =>
  <ActionButton color  = {isHistorical ? '#1A7777' : '#B6E3E1'}
                label  = {label}
                icon   = 'history'
                onClick= {onClick}
                to     = {to}
                enabled= {enabled != undefined ? enabled : true}>
  </ActionButton>

export default ActionHistory
