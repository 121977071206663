import React from 'react'
import { Route } from 'react-router-dom'

import Income from 'cli/ui/pages/income/Income'


const IncomeRoutes = (permiss) => {
  return permiss.can_user_read_income()
  ? [<Route key="income_00" path={'/income'} element={<Income/>}/>]
  : []

}

export default IncomeRoutes