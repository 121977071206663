import React, {useState, useEffect, useCallback} from 'react'
import {useParams, useNavigate} from 'react-router-dom'


// components
import Page from 'cli/ui/pages/layout/Page'
import {Row, Col} from 'reactstrap'
import { ActionBack } from 'cli/ui/components/buttons/actions'
import { LoadingBig } from 'cli/ui/components/loading'
import AgentDetailsForm from './AgentDetailsForm'
import withContext from 'cli/context/withContext'

let _BREADS= [['/', 'Inicio'], ['/settings', 'Configuración'], ['/settings/agents', 'Agentes']]

const AgentDetails = ({fetcher}) => {

  const params= useParams()
  const navigate= useNavigate()

  const [agentList, setAgentList]= useState([])
  const [userList, setUserList]= useState([])

  const [agent, setAgent]= useState({})
  const [status, setStatus]= useState(0)

  const paramId = params.idAgent

  const isNew = paramId == 'new'

  const title = isNew ? 'Creando nuevo' : agent.name||'...'

  const fetchAgent = useCallback((agentId) => {
    const _fetchAgent = async() => {
      const res = await fetcher.get('/api/agent/detail')
      const aList = res.data
      setAgentList(aList)
      if (agentId!=undefined) {
        const nAgent = aList.find((a) => a.id==agentId)
        setAgent(nAgent)    
      }
      setStatus(1)
    }
    _fetchAgent()
  }, [fetcher])

  useEffect(() => {
    async function fetchData() {
      const uList= await fetcher.read('/api/acc_user')
      setUserList(uList)

      await fetchAgent(isNew ? undefined : paramId)
    }
    fetchData()
  }, [fetcher, fetchAgent, isNew, paramId])

  const takenUids = () => {
    return agentList
      .filter((a) => a.id != agent.id)
      .map((a) => a.acc_user_id)
  }  

  const availableUsers = () => {
    let users= {}
    userList
      .filter((u) => takenUids().indexOf(u.id)==-1)
      .map((u) => {
        users[u.id]= u.name || u.username
      })
    return users
  }

  const existingNames = () => {
    return agentList
      .filter((a) => a.id != agent.id)
      .map((a) => a.name.toUpperCase())
  }    

  const handleChange = (name, value) => {
    const nAgent= {...agent}
    nAgent[name]= value
    setAgent(nAgent)
  }

  const handleSave = async () => {
    const res= await fetcher.upsave('/api/agent', agent)
    if (isNew && res>=1) {
      setStatus(0)
      navigate(`/settings/agents/${res}`)
      await fetchAgent(res)
      setStatus(1)
    }    
  }

  const handleCancel = () => {
    navigate('/settings/agents')
  }

  return (
    <Page breads={[..._BREADS, title]}
          title={`Agente: ${title}`}
          actions={ <ActionBack to={"/settings/agents"} label="A Agentes"/>}>
      <Row>
        <Col xs="12">
            {status < 1 
             ? <LoadingBig/>
             : <AgentDetailsForm
                  agent            = {agent}
                  existingNames    = {existingNames()}
                  availableUsers   = {availableUsers()}
                  onChange         = {(v,n) => handleChange(v,n)}
                  onCancel         = {() => handleCancel()}
                  onSave           = {() => handleSave()}
                />
            }
        </Col>

      </Row>
    </Page>
  )
}


export default withContext(AgentDetails)
