import React from 'react'

const ProgressBar = ({progress, label,  color, stripped, style}) => {
  const prg = progress!=undefined ? progress : 0
  const col = color != undefined 
              ? stripped===true
                ? `repeating-linear-gradient(45deg,${color}, ${color} 10px, #FFFFFF 10px, #FFFFFF 20px)`
                : color 
              : (progress == 100 ? '#00e64d' : '#ffcc00')

  return (
    <div>
      <div className="bars" style={{...style || {}}}>
        <div className="progress-xs  mb-0 progress-gray progress">
          <div className={"progress-bar"} // bg-#col
               style={{ width: prg+"%", background: col }}
               role="progressbar"
               aria-valuenow={prg.toString()}
               aria-valuemin="0"
               aria-valuemax="100">
          </div>
        </div>
      </div>
      {label!=undefined &&
        <div className="bars-label" style={{ marginTop: "5px" }}>
          <i>
            {label}
          </i>
        </div>
      }
    </div>
  )
}

export default ProgressBar