import React from 'react';
import PopupBase from './PopupBase'
import {Button} from '../buttons'

const PopupOk = ({show, title, children, onClose}) => 
  <PopupBase show   ={show}
              title  ={title}
              onClose={onClose}
              renderFooter={() => 
                <>
                <Button color="primary" icon="checkmark" onClick={() => onClose()}>
                  Ok
                </Button>
                </>
              }>
              {children}
            </PopupBase>
          

export default PopupOk