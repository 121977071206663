import React from 'react'
import Icon from 'cli/ui/components/icon'
import { Button } from 'reactstrap';

class AButton extends React.Component {

  handleClick(ev) {
    ev.stopPropagation()
    this.props.onClick()
  }

  render() {
    return (
      <Button color={this.props.color ? this.props.color : 'secondary'}
              onClick={(ev) => this.handleClick(ev)}
              disabled={this.props.disabled != undefined ? this.props.disabled : false}
              style={this.props.style}>
        <Icon icon={this.props.icon ? this.props.icon : 'ban'}  inline={true}/>
         {this.props.children}
      </Button>
    )
  }
}


export default AButton


