import React from 'react'
import {intre_from_str, intre_to_str} from 'intre'
import {FForm, FInputText, FInputFloat, /*FInputEpoch,*/ FInputDate, FInputSelect, FInputFile} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'

const all_days= Array(31).fill().map((_,idx) => [idx+1,idx+1])

const DAYS= [].concat([ ['', ''] ], all_days)

const MONTHS= [
  ['', ''],
  [ 1, 'Enero'],
  [ 2, 'Febrero'],
  [ 3, 'Marzo'],
  [ 4, 'Abril'],
  [ 5, 'Mayo'],
  [ 6, 'Junio'],
  [ 7, 'Julio'],
  [ 8, 'Agosto'],
  [ 9, 'Septiembre'],
  [10, 'Octubre'],
  [11, 'Noviembre'],
  [12, 'Diciembre']
]


const I5OrdinanceDetailsForm = ({ ordinance, existingNames, onChange, onSave, onCancel, onDelete, isNew, permiss}) => {

  const allowed= permiss.can_user_edit_ordinances()

  return (

  <FForm  onCancel     = {allowed ? isNew ? onCancel : onDelete : onCancel}
          labels       = {[isNew ? 'Cancelar' : 'Borrar', 'Guardar']}
          colors       = {[isNew ? 'secondary' : 'danger', 'primary']}
          onSave       = {allowed ? onSave : undefined}>
          <>
            <FInputText name        = "name"
                        label       = "Nombre"
                        icon        = {<Icon icon={"ordinance"}/>}
                        value       = {ordinance.name}
                        required    = {true}
                        checkValue  = {(v) => existingNames.map((n) => n.toLowerCase()).indexOf(v.toLowerCase())==-1}
                        feedback    = {'Inserta un nombre válido y único'}
                        onChange    = {(v) => onChange('name', v)}
                        readOnly    = {!allowed}
            />
            <FInputText name        = "title"
                        label       = "Título"
                        icon        = {<Icon icon={"text"}/>}
                        value       = {ordinance.title}
                        placeholder = {'Inserta un título para los documentos'}
                        onChange    = {(v) => onChange('title', v)}
                        readOnly    = {!allowed}
            />
            
            <FInputFloat  name        = "tax_rate"
                          label       = {"Tipo Impositivo"}
                          icon        = {<Icon icon={"dollar"}/>}
                          decimalSign = ','
                          value       = {ordinance.tax_data?.tax_rate}
                          gt          = {0}
                          required    = {true}
                          feedback    = {'Pon un número aquí!'}
                          onChange    = {(v) => onChange('tax_rate', v)}
                          readOnly    = {!allowed}
            />
            
            <FInputDate
                          name        = "inspection_date"
                          label       = "Fecha Aprobación P. Inspección"
                          icon        = {"calendar"}
                          value       = {intre_to_str(ordinance.tax_data?.inspection_date)}
                          onChange    = {(v) => onChange('inspection_date', intre_from_str(v))}
                          readOnly    = {!allowed}
                          locale      = 'es'
            />
            <FInputDate
                          name        = "bulletin_date"
                          label       = "Fecha Publicación Boletín"
                          icon        = "calendar"
                          value       = {intre_to_str(ordinance.tax_data?.bulletin_date)}
                          onChange    = {(v) => onChange('bulletin_date', intre_from_str(v))}
                          readOnly    = {!allowed}
                          locale      = 'es'
            />
            {/*
            <FInputEpoch
                          name        = "inspection_date"
                          label       = "Fecha Aprobación P. Inspección"
                          icon        = {"calendar"}
                          value       = {ordinance.tax_data?.inspection_date}
                          onChange    = {(v) => onChange('inspection_date', v)}
                        readOnly    = {!allowed}
                            locale     = 'es'
            />
            <FInputEpoch
                          name        = "bulletin_date"
                          label       = "Fecha Publicación Boletín"
                          icon        = "calendar"
                          value       = {ordinance.tax_data?.bulletin_date}
                          onChange    = {(v) => onChange('bulletin_date', v)}
                        readOnly    = {!allowed}
                            locale     = 'es'
            />
            */}

            <FInputSelect name        = "liq_limit_day"
                          label       = "Fecha Inicio Intereses (Día)"
                          icon        = "calendar"
                          value       = {ordinance.tax_data?.liq_limit_day}
                          options     = {DAYS}
                          required    = {true}
                          onChange    = {(v) => onChange('liq_limit_day', v)}
                          readOnly    = {!allowed}
            />
            <FInputSelect name        = "liq_limit_month"
                          label       = "Fecha Inicio Intereses (Mes)"
                          icon        = "calendar"
                          value       = {ordinance.tax_data?.liq_limit_month}
                          options     = {MONTHS}
                          required    = {true}
                          onChange    = {(v) => onChange('liq_limit_month', v)}
                          readOnly    = {!allowed}
            />            
            <FInputFile   name        = "doc"
                          label       = "Documento"
                          value       = {ordinance.doc}
                          onChange    = {(v) => onChange('doc', v)}
                          readOnly    = {!allowed}
                          />
                          
            </>
  </FForm>
  )
}

export default I5OrdinanceDetailsForm




