import React from 'react'

const NiceListRow = ({ children, className }) => {
  return (
    <div className={`afi-nice-list-row ${className || ''}`}>
      {children}
    </div>
 )
}

export default NiceListRow
