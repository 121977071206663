import React, {useState, useEffect, useCallback} from 'react'
import {useParams, useNavigate} from 'react-router-dom'


// components
import Page from 'cli/ui/pages/layout/Page'
import {Row, Col} from 'reactstrap'
import { ActionBack } from 'cli/ui/components/buttons/actions'
import { LoadingBig } from 'cli/ui/components/loading'
import UserDetailsForm from './UserDetailsForm'
import UserDetailsCouncils from './UserDetailsCouncils'
import { IBox } from 'cli/ui/components/ibox'
import withContext from 'cli/context/withContext'

let _BREADS= [['/', 'Inicio'], ['/settings', 'Configuración'], ['/settings/accontrol', 'Usuarios y perfiles'], ['/settings/accontrol/users', 'Usuarios']]

const UserDetails = ({fetcher}) => {

  const params= useParams()
  const navigate= useNavigate()
  const [user, setUser]= useState(undefined)
  const [roleList, setRoleList]= useState([])
  const [councilList, setCouncilList]= useState(undefined)

  const [nameList, setNameList]= useState([])
  const [nameOrig, setNameOrig]= useState('')
  
  const paramId = params.idUser
  const isNew = paramId == 'new'
  const title = isNew 
    ? 'Creando nuevo' 
    : user==undefined
    ? '...'
    : user.name||user.email

  const fetchUser = useCallback((userId) => {
    const _fetchUser = async() => {

      const nNameList = await fetcher.name_list('/api/acc_user')
      setNameList(nNameList)
      if (userId!=undefined) {
        const res = await fetcher.get('/api/user/detail', {id: userId})
        const nUser= res.data.users[0]
        setUser(nUser)
        setNameOrig(nUser.name)      
      }  else {
        setUser({
          admin: false
        })
        setNameOrig('')      
      }

      const raw= await fetcher.get('/api/role/detail')
      setRoleList(raw.data.roles)

    }
    _fetchUser()
  }, [fetcher])


  const fetchCouncilList = useCallback(() => {
    const _fetchCouncilList = async () => {
      const res= await fetcher.get('/api/council/names')
      setCouncilList(res.data)
    }
    _fetchCouncilList()
  }, [fetcher])

  useEffect(() => {
    async function fetchData() {
      await fetchUser(isNew ? undefined : paramId)
      await fetchCouncilList()
    }
    fetchData()
  }, [fetchUser, isNew, paramId, fetchCouncilList])

  const existingNames = () => {
    return nameList.filter((n) => n!=nameOrig)
  }

  const handleChange = (name, value) => {
    const nUser= {...user}
    nUser[name]= value
    setUser(nUser)
  }

  const handleSave = async () => {
    const raw= await fetcher.post('/api/user/save', user)
    const nuid = raw.data.res
    if (isNew && nuid>=1) {
      navigate(`/settings/accontrol/users/${nuid}`)
      await fetchUser(nuid)
    }    
  }

  const handleCancel = () => {
    navigate('/settings/accontrol/users')
  }


  const handleCouncilToggle = async ( user_council_id, user_id, council_id, is_on )=> {

    if (is_on) {
      const user_council= {
        acc_user_id: user_id,
        council_id: council_id
      }
      const nUCId= await fetcher.upsave('/api/acc_user_council', user_council)

      if (nUCId>=1) {
        const nUser= {
          ...user,
          councils: [
            ...user.councils,
            {
              id: nUCId,
              ...user_council
            }
          ]
        }
        setUser(nUser)
      } 
    } else {
      const res= await fetcher.remove('/api/acc_user_council', user_council_id)
      if (res>=1) {
        const nUserCouncils= user.councils.filter(c => c.id!=user_council_id)
        const nUser= {
          ...user,
          councils: nUserCouncils
        }
        setUser(nUser)
      } 
    }
  }



  return (
    <Page breads={[..._BREADS, title]}
          title={`Usuario: ${title}`}
          actions={<ActionBack to={"/settings/accontrol/users"} label="A Usuarios"/>}>
      <Row>



        <Col xs="12" md="6">
            <IBox title="Detalles">
              {
                user==undefined
                ? <LoadingBig/>
                :
                  <UserDetailsForm
                     user             = {user}
                     existingNames    = {existingNames()}
                     roleList         = {roleList}
                     onChange         = {(v,n) => handleChange(v,n)}
                     onCancel         = {() => handleCancel()}
                     onSave           = {() => handleSave()}
                   />
              }
            </IBox>
          </Col>        

          <Col xs="12" md="6">
            <IBox title="Ayuntamientos">
              {
                ( (user==undefined) || (councilList==undefined) )
                ? <LoadingBig/>
                : <UserDetailsCouncils
                      user = {user}
                      councilList = {councilList}
                      onCouncilToggle = {handleCouncilToggle}
                      disabled = {isNew}/>           
              }
            </IBox>
          </Col> 
      </Row>
    </Page>
  )
}


export default withContext(UserDetails)

