import React from 'react'
import TaxFooterSave from '../common/TaxFooterSave'
import TaxSection from '../common/TaxSection'
import ProcessAETaxFields from './ProcessAETaxFields'
import ProcessAETaxTable from './ProcessAETaxTable'

const ProcessAETax = ({ process, onChangeProcess, onChange, onSave, onDetChange, onGoToInspect, canEdit }) => {

  return (
    <TaxSection title="Tasas">
      <ProcessAETaxFields process  = {process}
                          onChangeProcess = {onChangeProcess}
                          onChange = {onChange}
                          onGoToInspect = {onGoToInspect}
                          canEdit       = {canEdit}/>
      {canEdit
       ? 
         <TaxFooterSave      onClick  = {() => onSave()}/>
      : null}
      
      <ProcessAETaxTable  process  = {process}
                          onChange = {onDetChange}
                          canEdit       = {canEdit}/>

     {canEdit
       ? 
         <TaxFooterSave      onClick  = {() => onSave()}/>
      : null}

    </TaxSection>
  )
}


export default ProcessAETax




