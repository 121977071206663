import React from 'react'
import { ActionDetails, ActionRemove } from 'cli/ui/components/buttons/actions'
import ActionTaxPayerMark from './ActionTaxPayerMark'
import {HyperLULicense, HyperTaxPayer} from 'cli/ui/components/hyper'
import NiceList from 'cli/ui/components/nice_list'
import {uvl} from 'farrapa/commons'
import { IfPermission } from 'cli/context/IfPermission'

const licenseFields= [
  /* eslint react/display-name:0 */
  {
    label: "Licencia",
    className: "main", 
    value: (license) => uvl(license.name,''),
    render: (license) => <HyperLULicense license={license}/>
  }, 
  {
    label: "Ref. Catastral",
    value: (license) => `${uvl(license.cadastre_ref,'')}`,
  },
  {
    label: "Contribuyente",
    value: (license) => uvl(license.taxpayer_name,''),
    render: (license, onEvent) => 
      license.taxpayer_id==undefined
      ? 
       license.candidate_id==undefined
       ?
        <>
          <IfPermission code="tax_payer_edit">
            <ActionTaxPayerMark onClick={() => onEvent('taxpayer_add', {id: license.id})}
                                marked={false}/>
          </IfPermission>

          <span style={{marginLeft: '0.5em', fontStyle: 'italic', color: 'darkgray'}}>
            {uvl(license.taxpayer_name,'')}
          </span>
        </>
       :
        <>
          <IfPermission code="tax_payer_edit">
            <ActionTaxPayerMark onClick={() => onEvent('taxpayer_confirm_candidate', {id: license.id})}
                                marked={false}/>
          </IfPermission>

          <span style={{marginLeft: '0.5em'}}>
            <HyperTaxPayer id={license.candidate_id} name={uvl(license.candidate_name,'')}/>
          </span>
        </>
      : <>
          <IfPermission code="tax_payer_edit">
            <ActionTaxPayerMark onClick={() => onEvent('taxpayer_rem', {id: license.id})}
                                marked={true}/>
          </IfPermission>

          <span style={{marginLeft: '0.5em'}}>
            <HyperTaxPayer id={license.taxpayer_id} name={uvl(license.taxpayer_name,'')}/>
          </span>
        </>

  },   
  {
    label: "NIF",
    value: (license) => uvl(license.nif,'')
  },   
  {
    label: "Tipo Obra",
    value: (license) => uvl(license.work_type,'')
  },     
  {
    label: "Descripción Obra", 
    className: "cut_200", 
    value: (license) => uvl(license.work_description,'')
  },
  {
    label: "PEM", 
    value: (license) => license.pem || 0.0,
    render: (license) => license.pem==undefined ? null : <span style={{color: "green"}}>{`${license.pem} €`}</span>
  },
  {
    label: "ICIO prov.", 
    value: (license) => license.icio_provisional || 0,
    render: (license) => `${license.icio_provisional ? license.icio_provisional + ' €' : ''}`
  },
  {
    label: "LU prov.", 
    value: (license) => license.lu_provisional || 0,
    render: (license) => `${license.lu_provisional ? license.lu_provisional + ' €' : ''}`
  },
  {
    label: "Acciones", 
    className: "right", 
    render: (license, onEvent) => 
      <>
        <ActionDetails to={`/councils/${license.council_id}/lu/${license.ordinance_id}/license/${license.id}`} />
        <IfPermission code="ordinance_edit">
          <ActionRemove onClick={() => onEvent('remove', {id: license.id})}
                        enabled={true}/>
        </IfPermission>
      </>
  }
]

const makeKey= (license) =>
  `license_list_row_${license.id}`


const LUOrdinanceLicensesList = ({ licenses, onRemove, onTaxPayerAdd, onTaxPayerConfirmCandidate, onTaxPayerRem }) =>
  <NiceList fields     = {licenseFields}
            initialSort= {[0, "asc"]}
            data       = {licenses}
            makeKey    = {makeKey}
            onEvent    = {(ev, params) => {
              if (ev=='remove') {
                onRemove(params.id)
              } else if (ev=='taxpayer_add') {
                onTaxPayerAdd(params.id)   
              } else if (ev=='taxpayer_confirm_candidate') {
                onTaxPayerConfirmCandidate(params.id)                             
              } else if (ev=='taxpayer_rem') {
                onTaxPayerRem(params.id)
              }
            }}
            transparent
  />

export default LUOrdinanceLicensesList








