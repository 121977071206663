import React, {useState, useEffect} from "react"
import CalendarBodyRow from './CalendarBodyRow'
import {intre_are_same, intre_range, intre_diff, intre_get_month} from 'intre'
import {arrayChunk} from 'farrapa/collections'
import {uvl} from 'farrapa/commons'

const eventSorter= function (a, b) {
  const aLen = intre_diff( a.dstart, uvl(a.dend, a.dstart) )
  const bLen = intre_diff( b.dstart, uvl(b.dend, b.dstart) )
  const aFrom = a.dstart
  const bFrom = b.dstart

  if(aLen == bLen)
  {
      return (aFrom < bFrom) ? -1 : (aFrom > bFrom) ? 1 : 0;
  }
  else
  {
      return (aLen < bLen) ? -1 : 1;
  }
}


const CalendarBody= ({monthBounds, selectedDate, events, onDateClick, onEventClick, onMoreClick}) => {
  const [eventsMap, setEventsMap]= useState({})
  const [dayRowsWithEvents, setDayRowsWithEvents]= useState([])
  const [hoveredEvent, setHoveredEvent]= useState(undefined)
  
  // Sort events and map them by day
  useEffect(() => {

    let nEventsMap= {}
    if (events.length>0) {

      // prepare events:
      // -- initialize position
      // -- sort by (length, start)
      const nEvents= events
                     .map((ev) => {
                       return {
                         ...ev,
                         position: undefined
                       }})
                     .sort(eventSorter)

      // Map events by day
      nEvents.map((e) => {
        intre_range(e.dstart, uvl(e.dend, e.dstart)).map((day) => {      
          const dayIsInMap= Object.keys(nEventsMap).indexOf(day.toString())>=0
          if (dayIsInMap) {
            nEventsMap[day].push(e)
          } else {
            nEventsMap[day]= [e]
          }
        })
      })

      const allDays= Object.keys(nEventsMap).sort().map((d) => parseInt(d))

      // Assign event positions
      
      let positionSlots= [false]
      allDays.map((day) => {
        const evs= nEventsMap[day]
        const toClear=[]
        evs.map((ev) => {
          let iPos=0
          while (ev.position==undefined) {
            if (iPos == positionSlots.length) {
              positionSlots.push(false)
            }
            if (positionSlots[iPos]===false) {
              ev.position= parseInt(iPos) + 1
              positionSlots[iPos]= true
            } else {
              iPos+=1
            }
          }
        
          if (intre_are_same(uvl(ev.dend, ev.dstart), day, 'day')) {
            toClear.push(ev.position-1)
          }
        })
        // clear finished events
        for (let iPos of toClear) {
          positionSlots[iPos]= false
        }
      })      
    }
    setEventsMap(nEventsMap)
  }, [events])


  // Chunk events into weekly rows
  useEffect(() => {

    const daysRange= intre_range(monthBounds.start, monthBounds.end, true)
    const dayRows= arrayChunk(daysRange, 7)

    let nDayRowsWithEvents= []
    
    dayRows.map((days, rIdx) => {
      nDayRowsWithEvents[rIdx]= []
      days.map((day) => {
        nDayRowsWithEvents[rIdx].push({
          day: day,
          events: eventsMap[day] || []
        })
      })
    })
    

    setDayRowsWithEvents(nDayRowsWithEvents)
  }, [monthBounds, eventsMap])

  const onEventHover = (event, on) => {
    if (on) {
      setHoveredEvent(event.id)
    } else {
      setHoveredEvent(undefined)
    }
  }

  return (
    <div className="body">
      {dayRowsWithEvents.map((dayRow, ridx) => 
        <CalendarBodyRow key          = {`calendar_row_${ridx}`}
                         month        = {intre_get_month(monthBounds.first)}
                         dayRow       = {dayRow}
                         selectedDate = {selectedDate}
                         hoveredEvent = {hoveredEvent}
                         onDateClick  = {onDateClick}
                         onEventClick = {onEventClick}
                         onEventHover = {onEventHover}
                         onMoreClick  = {onMoreClick}
                         />
      )}
    </div>
  )
}

export default CalendarBody