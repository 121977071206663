import React from 'react'
import {Row, Col} from 'reactstrap'
import Page from 'cli/ui/pages/layout/Page'
import {MenuCard} from 'cli/ui/components/cards'
const _BREADS = [['/', 'Inicio'], ['Usuarios y perfiles']]

const Taxes = () => {
  return (
      <Page breads={_BREADS}
            title="Usuarios y perfiles">
        <Row>  

          <Col xs="12" sm="6" md="4"> 
            <MenuCard color    = {"success"}
                      icon     = {'user'}
                      link     = {`/settings/accontrol/users`}
                      title    = {'Usuarios'}>
            </MenuCard>   
          </Col>          

          <Col xs="12" sm="6" md="4"> 
            <MenuCard color    = {"secondary"}
                      icon     = {'shield'}
                      link     = {`/settings/accontrol/roles`}
                      title    = {'Perfiles y permisos'}>
            </MenuCard>   
          </Col>   

        </Row>

      </Page>
  )
}


export default Taxes



