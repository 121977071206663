import React from 'react'
import {ActionDetails, ActionRemove, ActionHistory} from 'cli/ui/components/buttons/actions'
import ProcessProgress from 'cli/ui/fragments/process/fields/progress/single/ProcessProgress'
import getStatusName from 'bl/util/process/getStatusName'
// import {LU} from 'bl/ns/taxes'
import {uvl} from 'farrapa/commons'
import { intre_pretty_medium } from 'intre'
import { IfPermission } from 'cli/context/IfPermission'

const eventProcessFieldsMain = (hiddenFields) => {

  return [
    /* eslint react/display-name:0 */      
    {
      label: "Ejercicios",
      value: (p) => p.year_from + p.year_to,
      render: (p) => `${p.year_from} → ${p.year_to}`
    },


    {
      label: "Not. Inicio",
      value: (p) => intre_pretty_medium(p.start_date)
    },  
    {
      label: "Aporta doc.",
      value: (p) => intre_pretty_medium(p.doc_provided_date)
    }, 
    {
      label: "2º req.",
      value: (p) => intre_pretty_medium(p.second_req_date)
    }, 
    {
      label: "3º req.",
      value: (p) => intre_pretty_medium(p.third_req_date)
    },  
    {
      label: "Firma",
      value: (p) => intre_pretty_medium(p.appointment_date)
    }, 

    {
      label: "Inspección", 
      className: "center", 
      value: (p) => getStatusName(p, 'inspect'),
      render: (p) =>
        <ProcessProgress process={p} what={'inspect'}/>
    },
    {
      label: "Sanción 203", 
      className: "center", 
      value: (p) =>  getStatusName(p, 'penalty_20'),
      render: (p) => 
      p.penalty_20_on===true 
        ? <ProcessProgress process={p} what={'penalty_20'}/>
          : null
    },
    (hiddenFields && hiddenFields.indexOf('penalty_19')>=0)
    ? null
    : 
      {
        label: "Sanción 191/192", 
        className: "center", 
        value: (p) => getStatusName(p, 'penalty_19'),
        render: (p) => 
        p.penalty_19_on===true 
          ? <ProcessProgress process={p} what={'penalty_19'}/>
            : null
      },
    {
      label: "Acciones", 
      className: "right", 
      render: (process, onEvent) => 
        <>
          <ActionDetails to = {`/taxes/${process.tax_code}/edition/${process.edition_id}/process/${process.id}`} />
          <IfPermission code="process_edit">
            {!hiddenFields || hiddenFields.indexOf('historic')>=0
            ? null
            : <ActionHistory 
                  onClick = {() => onEvent('historical', {id: process.id, historical: ! (uvl(process.historical, false))})}
                  isHistorical = {uvl(process.historical, false)}/> 
            }
            {!hiddenFields || hiddenFields.indexOf('remove')>=0
            ? null
            : <ActionRemove  
                  onClick = {() => onEvent('remove', {id: process.id})}
                  enabled = {true}/>
            }
          </IfPermission>
        </>                                     
    }
  ]
  
}





export default eventProcessFieldsMain








