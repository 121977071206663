import React, {useState, useEffect, useCallback} from 'react'
import {useParams} from 'react-router-dom'


// components
import Page from 'cli/ui/pages/layout/Page'
import {Row, Col} from 'reactstrap'
import { ActionBack, ActionGo, ActionAdd, ActionEdit} from 'cli/ui/components/buttons/actions'
import { LoadingBig } from 'cli/ui/components/loading'
import AEOrdinanceLicensesList from './AEOrdinanceLicensesList'
import { arrayRemove } from 'farrapa/collections'
import { IfPermission } from 'cli/context/IfPermission'
import withContext from 'cli/context/withContext'

const AEOrdinanceDetails = ({fetcher}) => {
  const params= useParams()

  const [aeOrdinance, setAeOrdinance]= useState({})
  const [licenseList, setLicenseList]= useState([])
  const [status, setStatus]= useState(0)

  const paramCouncilId = params.idCouncil
  const paramId = params.idOrdinance
  

  useEffect(() => {
    async function fetchData() {
      let res = await fetcher.get('/api/ordinance/detail', {id: paramId})
      const nAeOrdinances = res.data
      const nAeOrdinance = nAeOrdinances[0]   
      setAeOrdinance(nAeOrdinance)

      res= await fetcher.get('/api/ae/license/detail', {ordinance_id: paramId})
      const nLicenseList= res.data
      setLicenseList(nLicenseList)
      setStatus(1)
    }
    fetchData()
  }, [fetcher, paramId])

  const getTitle = useCallback((short= false) => {
    return aeOrdinance?.name
        ? short 
          ? aeOrdinance.name.length>30
            ? aeOrdinance.name.substr(0,30)+' ...'
            : aeOrdinance.name
          : aeOrdinance.name
        : ''
  }, [aeOrdinance])


  const handleRemoveLicense = async (licenseId) => {
    const done = await fetcher.remove('/api/ae/license', licenseId)
    if (done) {
      let nLicenseList= [...licenseList]
      arrayRemove(nLicenseList,
        nLicenseList.find((c) => c.id==licenseId)
      )
      setLicenseList(nLicenseList)
    }
    return done
  }

  const handleTaxPayerAdd = async (licenseId) => {
    const res = await fetcher.post('/api/ae/license/taxpayer_add', {id: licenseId})
    const tpId = res.data
    if (tpId>0) {
      let nLicenseList= [...licenseList]
      nLicenseList.find((c) => c.id==licenseId).taxpayer_id= tpId
      setLicenseList(nLicenseList)
    }
  }

  const handleTaxPayerConfirmCandidate = async (licenseId) => {
    let nLicenseList= [...licenseList]
    let nLicense= nLicenseList.find((c) => c.id==licenseId)
    nLicense.taxpayer_id= nLicense.candidate_id
    nLicense.taxpayer_name= nLicense.candidate_name

    await fetcher.post('/api/ae/license/taxpayer_upd', {id: licenseId, taxpayer_id: nLicense.taxpayer_id})
    setLicenseList(nLicenseList)
  }

  const handleTaxPayerRem = async (licenseId) => {
    const res = await fetcher.post('/api/ae/license/taxpayer_rem', {id: licenseId})
    const done = res.data
    if (done>0) {
      let nLicenseList= [...licenseList]
      nLicenseList.find((c) => c.id==licenseId).taxpayer_id= undefined
      setLicenseList(nLicenseList)
    }
  }  
  

  return (
    <Page breads = {[['/', 'Inicio'], ['/councils', 'Ayuntamientos'], ['/councils/'+paramCouncilId, aeOrdinance?.council_name||''], getTitle(true)]}
          title  = {`Licencias de la ordenanza: ${getTitle()}`}
          actions= {
            <>
              {aeOrdinance 
                ? <>
                    <IfPermission code="ordinance_edit">
                      <ActionGo   to={`/councils/${aeOrdinance.council_id}/ae/${aeOrdinance.id}/import`} label="Importar Fichero"/>
                      <ActionAdd  to={`/councils/${aeOrdinance.council_id}/ae/${aeOrdinance.id}/license/new`} label="Nueva licencia"/>
                    </IfPermission>
                    <ActionEdit to={`/councils/${aeOrdinance.council_id}/ae/${aeOrdinance.id}/det`} label="Detalles"/>
                  </>
                : null}
              <ActionBack to={`/councils/${paramCouncilId}`} label="Al ayuntamiento"/>
            </>            
          }>
      <Row>
        <Col xs="12">
          {
            status < 1 
            ? <LoadingBig/>        
            : <AEOrdinanceLicensesList
                licenses      = {licenseList}
                onRemove      = {(lid) => handleRemoveLicense(lid)}
                onTaxPayerAdd = {(lid) => handleTaxPayerAdd(lid)}
                onTaxPayerConfirmCandidate = {(lid) => handleTaxPayerConfirmCandidate(lid)}
                onTaxPayerRem = {(lid) => handleTaxPayerRem(lid)}
              />
          }
        </Col>
      </Row>
    </Page>
  )

}


export default withContext(AEOrdinanceDetails)
