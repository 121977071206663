import React from 'react'
import {asPrice} from 'farrapa/numbers'
import TaxTable from '../common/TaxTable'
import {FInputFloat} from 'formiga-reactstrap'

const ProcessLUTaxTable =  ({ tax, onChange, canEdit }) => {

  const handleChange = (v, n, persist) => {
    let changes

    if (n=='pem_module' || n=='pem_project') {

      let modu= tax.pem_module==undefined  ? 0.0 : tax.pem_module
      let proj= tax.pem_project==undefined  ? 0.0 : tax.pem_project

      if (n=='pem_module') {
        modu= v || 0.0
      } else {
        proj= v || 0.0
      }

      const pem= modu + proj

      changes= {
        [n]: v,
        pem: pem
      }

    } else {
      changes= {[n]: v}
    }

    onChange(changes, persist)
    
    
  }



  return (

    <TaxTable>
      <thead>
        <tr>
          <th colSpan="3" className="center"></th>
          <th colSpan="2" className="center">ICIO</th>
          <th colSpan="2" className="center">LU</th>
        </tr>
        <tr>
          
          {tax.is_definitive 
           ? <>
              <th>PEM</th>
              <th>Coste comprobado</th>
              <th>Diferencia</th>
             </>
          : <>
              <th>Módulos</th>
              <th>Proyecto</th>
              <th>PEM</th>
            </>}
          <th>Tipo Impositivo</th>
          <th>A regularizar</th>
          <th>Tipo Impositivo</th>
          <th>A regularizar</th>
        </tr>
      </thead>    
      <tbody>
        <tr>
          {tax.is_definitive 
           ? <>          
              <td>
                <FInputFloat name="pem"
                          value={tax.pem}
                          onChange={(v, persist) => handleChange(v, 'pem', persist)}
                          showValidity= {1}
                          required    = {true}
                          autocomplete= {"off"}
                          label       = {undefined}
                          icon        = {false}
                          decimalSign= {","}
                          inline
                          readOnly   = {!canEdit}/>            
              </td>      
              <td>
                <FInputFloat name="expenses"
                          value={tax.expenses}
                          onChange={(v, persist) => handleChange(v, 'expenses', persist)}
                          showValidity= {1}
                          required    = {true}
                          autocomplete= {"off"}
                          label       = {undefined}
                          icon        = {false}
                          decimalSign= {","}
                          inline      
                          readOnly   = {!canEdit}/>
              </td>
              <td>{asPrice(tax.difference)}</td>
            </>
           : <>          
              <td>
                <FInputFloat name="pem_module"
                          value={tax.pem_module}
                          onChange={(v, persist) => handleChange(v, 'pem_module', persist)}
                          showValidity= {1}
                          required    = {true}
                          autocomplete= {"off"}
                          label       = {undefined}
                          icon        = {false}
                          decimalSign= {","}
                          inline      
                          readOnly   = {!canEdit}/>            
              </td>      
              <td>
                <FInputFloat name="pem_project"
                          value={tax.pem_project}
                          onChange={(v, persist) => handleChange(v, 'pem_project', persist)}
                          showValidity= {1}
                          required    = {true}
                          autocomplete= {"off"}
                          label       = {undefined}
                          icon        = {false}
                          decimalSign= {","}
                          inline      
                          readOnly   = {!canEdit}/>
              </td>
              <td>{asPrice(tax.pem)}</td>
            </>
          }
          <td>{asPrice(tax.icio_tax_rate)}</td>
          <td>{asPrice(tax.icio_debt)}</td>
          <td>{asPrice(tax.lu_tax_rate)}</td>
          <td>{asPrice(tax.lu_debt)}</td>        
        </tr>

      </tbody>
    </TaxTable>
  )
}


export default ProcessLUTaxTable




