import React, {useState} from 'react'
import {FForm, FInputTextArea, FInputSelectSearch} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'
import { CLOSING_REASONS } from 'bl/ns/process/process'

const ProcessCloseForm = ({onProcessClose, onCancel}) => {
  const [reason, setReason]= useState(undefined)
  const [explanation, setExplanation]= useState('')

  const handleSave = () => {
    onProcessClose(reason, explanation)
  }

  return (
    <FForm  onSave = {handleSave}
            onCancel={onCancel}>
            <>
              <FInputSelectSearch  
                          options     = {CLOSING_REASONS}
                          name        = 'reason'
                          label       = 'Razón'
                          icon        = {<Icon icon={"question"}/>}
                          value       = {reason}
                          onChange    = {(v) => setReason(v)}
                          feedback    = {'Selecciona un motivo de cierre'}
                          clearable   = {true}
                          required    = {true}
              />              
              <FInputTextArea name    = "explanation"
                          label       = "Observaciones"
                          icon        = {<Icon icon={"text"}/>}
                          value       = {explanation}
                          required    = {true}
                          feedback    = {'Adjunta observaciones'}
                          onChange    = {(v) => setExplanation(v)}
              />
            </>
      </FForm>
  )
}
export default ProcessCloseForm
