import React from 'react'
import {FForm, FInputText, FInputEmail, FInputUrl, FInputSelectSearch, FInputTextArea} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'
import { SPANISH_PROVINCES } from 'bl/ns/geo'

const TaxPayerNewForm = ({ taxPayer, existingNames, existingNifs, sectorOptions, onChange, onSave}) =>

  <FForm onCancel     = {undefined}
         onSave       = {onSave}>
         <>
          <FInputText name        = "name"
                      label       = "Nombre"
                      icon        = {<Icon icon={"taxpayer"}/>}
                      value       = {taxPayer.name}
                      required    = {true}
                      checkValue  = {(v) => existingNames.map((n) => n.toLowerCase()).indexOf(v.toLowerCase())==-1}
                      feedback    = {'Inserta un nombre válido y único'}
                      onChange    = {(v) => onChange('name', v)}
          />
          <FInputText name        = "address"
                      label       = "Dirección"
                      icon        = {<Icon icon={"location"}/>}
                      value       = {taxPayer.address}
                      onChange    = {(v) => onChange('address', v)}
          />
          <FInputSelectSearch  
                      options     = {SPANISH_PROVINCES}
                      name        = 'provincia'
                      label       = 'Provincia'
                      icon        = {<Icon icon={"map-signs"}/>}
                      value       = {taxPayer.province}
                      onChange    = {(v) => onChange('province', v)}
                      feedback    = {"Seleciona una Provincia"}
                      clearable   = {true}
                      searchable  = {true}
                      required    = {true}
          />
                    
          <FInputEmail name       = "email"
                      label       = "E-Mail"
                      value       = {taxPayer.email}
                      icon        = {<Icon icon={"email"}/>}
                      feedback    = {'Inserta un email'}
                      onChange    = {(v) => onChange('email', v)}
          />
          <FInputUrl  name       = "url"
                      label       = "Dirección electrónica habilitada"
                      value       = {taxPayer.url}
                      icon        = {<Icon icon={"url"}/>}
                      feedback    = {'Inserta una url'}
                      onChange    = {(v) => onChange('url', v)}
          />
          <FInputText name        = "nif"
                      label       = "NIF"
                      icon        = {<Icon icon={"profile"}/>}
                      value       = {taxPayer.nif}
                      checkValue  = {(v) => existingNifs.map((n) => n.toLowerCase()).indexOf(v.toLowerCase())==-1}
                      feedback    = {'Inserta un NIF único'}
                      onChange    = {(v) => onChange('nif', v)}
          />
          <FInputText name        = "activity"
                      label       = "Descripción de la actividad principal"
                      icon        = {<Icon icon={"text"}/>}
                      value       = {taxPayer.activity}
                      required    = {true}
                      feedback    = {'Inserta una descripción de actividad para los docs'}
                      onChange    = {(v) => onChange('activity', v)}
          />
                      
          <FInputTextArea name        = "remarks"
                      label       = "Observaciones"
                      value       = {taxPayer.remarks}
                      onChange    = {(v) => onChange('remarks', v)}
          />
          <FInputSelectSearch  
                      options     = {sectorOptions}
                      name        = 'sector_id'
                      label       = 'Sector principal'
                      icon        = {<Icon icon={"sector"}/>}
                      value       = {taxPayer.sector_id!=undefined ? taxPayer.sector_id.toString() : ''}
                      onChange    = {(v) => onChange('sector_id', parseInt(v))}
                      feedback    = {"Seleciona un Sector"}
                      clearable   = {true}
                      searchable  = {true}
                      required    = {true}
          />
       </>
</FForm>


export default TaxPayerNewForm




