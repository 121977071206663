import React, {useState, useEffect, useCallback} from 'react'

import EditionDetailsInfoForm from './EditionDetailsInfoForm'
import { ActionRemove } from 'cli/ui/components/buttons/actions'

const EditionDetailsInfo = ({fetcher, history, edition, onChange, taxCode}) => {

  const [status, setStatus]= useState(0)
  const [nameList, setNameList]= useState([])
  const [nameOrig, setNameOrig]= useState('')
  const [agents, setAgents]= useState({})
  const [ordinances, setOrdinances]= useState([])

  useEffect(() => {
    async function fetchData() {
      const res= await fetcher.get('/api/ordinance/key_names')
      setOrdinances(res.data)
      setAgents(await fetcher.key_list('/api/agent'))
      setStatus(1)
    }
    fetchData()

  }, [fetcher])


  useEffect(() => {
    async function fetchData() {
      const nNameList = await fetcher.name_list('/api/edition')
      const nNameOrig = edition.name
      // TODO
      // each state change performs a render... can be improved
      setNameList(nNameList)
      setNameOrig(nNameOrig)
    }
    fetchData()

  }, [fetcher, edition.name])
  

  const handleSaveEdition = useCallback(() => {
    const _handleSaveEdition = async () => {
      const changes= {
        id: edition.id,
        name: edition.name,
        ordinance_id: edition.ordinance_id,
        agent_id: edition.agent_id,
        tax_code: taxCode
      }
      await fetcher.upsave('/api/edition', changes)
    }  
    _handleSaveEdition()
  }, [fetcher, edition.id, edition.name, edition.ordinance_id, edition.agent_id, taxCode])


  const handleRemoveEdition = useCallback(() => {
    const _handleRemoveEdition = async () => {
      await fetcher.remove('/api/edition', edition.id)
      history.push(`/councils/${edition.council_id}`)
    }
    _handleRemoveEdition()
  }, [fetcher, history, edition.id, edition.council_id])


  const existingNames = () => {
    return nameList.filter((n) => n!=nameOrig)
  }


  return (
    <>
      {edition.processes.length == 0
       ? 
        <div style={{textAlign: "right"}}>
          <ActionRemove onClick={() => handleRemoveEdition()}
                        label="Borrar"/>
        </div>
       : null}
      {status==1
        ? 
        <EditionDetailsInfoForm
          edition          = {edition}
          existingNames    = {existingNames()}
          agents           = {agents}
          ordinances       = {ordinances}
          onChange         = {(n, v) => onChange(n, v)}
          onSave           = {() => handleSaveEdition()}/>
        : null}
    </>
  )
}


export default EditionDetailsInfo
