import React from 'react'
import ActionButton from './ActionButton'

const ActionSave = ({ onClick, to, enabled, label }) =>
  <ActionButton color='blue'
    label={label}
    icon='save'
    onClick={onClick}
    to={to}
    enabled={enabled != undefined ? enabled : true}>
  </ActionButton>

export default ActionSave
