import React from 'react'
import ActionButton from './ActionButton'
import {uvl} from 'farrapa/commons'

const ActionDocX = ({ onClick, to, enabled, label, color }) =>
  <ActionButton color  = {uvl(color, 'rgb(0, 102, 255)')}
                label  = {label}
                icon   = 'file-word'
                onClick= {(e) => { onClick(e) }}
                to     = {to}
                enabled= {enabled!=undefined ? enabled : true}>
  </ActionButton>

export default ActionDocX

