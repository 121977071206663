import React, {useState, useEffect} from 'react'
import {Row, Col} from 'reactstrap';
import TaxHeaderFields from'../common/TaxHeaderFields'
import TaxHeaderFieldsRow from'../common/TaxHeaderFieldsRow'
import TaxInputCheckbox from '../common/TaxInputCheckbox' 
import { FInputTextArea} from 'formiga-reactstrap'
import Button from 'cli/ui/components/buttons/Button'
import {uvl} from 'farrapa/commons'

const ProcessLUTaxFields = ({tax, onChange, canEdit}) => {
  const [freeText, setFreeText]= useState(tax.free_text)

  useEffect(() => {
    setFreeText(tax.free_text)
  }, [tax])


  return (
    <Row>
      <Col xs="12" sm="12" md="3">
          <TaxHeaderFields title="Bonificaciones">
            <TaxHeaderFieldsRow>
              <TaxInputCheckbox 
                  id         = "solar_energy"
                  name       = "solar_energy"
                  label      = "Energía solar"
                  value      = {uvl(tax.solar_energy, true)}
                  onChange   = {(v, c) => onChange({'solar_energy': v}, c)}
                  readOnly   = {!canEdit}
              />            

            </TaxHeaderFieldsRow>
            <TaxHeaderFieldsRow>
              <TaxInputCheckbox 
                  id         = "accessibility"
                  name       = "accessibility"
                  label      = "Accesibilidad discapacitados"
                  value      = {uvl(tax.accessibility, true)}
                  onChange   = {(v, c) => onChange({'accessibility': v}, c)}
                  readOnly   = {!canEdit}
              />            

            </TaxHeaderFieldsRow>

          </TaxHeaderFields>        
      </Col>
      <Col xs="12" sm="12" md="6">    
        <TaxHeaderFields title="Observaciones">
          <TaxHeaderFieldsRow>
            <FInputTextArea name     = "free_text"
                            value    = {freeText}
                            onChange = {(v) => setFreeText(v)}
                            inline
                            readOnly   = {!canEdit}
                            />
            {canEdit
             ?
              <Button onClick={() => onChange({'free_text': freeText}, true) }
                      icon='save'>
                {"Guardar"}
              </Button>
            : null}
          </TaxHeaderFieldsRow>
        </TaxHeaderFields>
      </Col>
    </Row>
  )
}


export default ProcessLUTaxFields