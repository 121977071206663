import React from 'react'
import { ActionPermission } from 'cli/ui/components/buttons/actions'
import { PERMISSION_GROUPS } from 'bl/ns/permissions'

const _RoleDetailsPermissionLines = ({role, permissions, onPermissionToggle}) => {

  return (
    permissions.map((p, idx) => {
      let rolePermId 
      try {
        rolePermId= role.permissions.filter(rp => rp.acc_permission_id==p.id)[0].acc_role_permission_id
      } catch(_) {}

      const isAllowed = rolePermId!=undefined
      return (
        <div key={`role_permission_${p.id}`}
            style={{display: "flex", backgroundColor: idx%2===0 ? '#f0f0f0' : ''}}>
          <div style={{flex: "1", alignSelf: "center"}}>
            {p.description}
          </div>
          <div style={{marginRight: "auto", alignSelf: "center"}}>
            <ActionPermission 
              onClick   = {() => onPermissionToggle(rolePermId, role.id, p.id, !isAllowed)}
              enabled   = {!role.admin}
              isAllowed = {isAllowed}
              label     = {undefined}/>
          </div>
        </div>
      )
    })
  )
}


const RoleDetailsPermissions = ({ role, permissionList, onPermissionToggle}) => {

  let group_keys = []
  for (const g in PERMISSION_GROUPS) {
    group_keys.push([g, PERMISSION_GROUPS[g]])
  }
  group_keys= group_keys.sort(function(a, b) {
    return a[1] - b[1];
  })

  return (
    <>
      {role.admin==1
       ? <div>
           <i style={{color: "gray"}}>Nota: Este perfil es el administrador: no se pueden modificar sus permisos</i>
         </div>
       : null}

      {group_keys.map((gg, gidx) => {
        return (
          <div key={`role_permission_group_${gidx}`}
              style={{width: "100%", marginTop: "1em"}}>
            <div style={{border: "1px solid #f0f0f0", textAlign: "center"}}>
                  {gg[1]}
            </div>
            <_RoleDetailsPermissionLines
              role={role}
              permissions={permissionList[gg[0]]}
              onPermissionToggle={onPermissionToggle}/>
          </div>
        )        
      })}

    </>


  )
}

export default RoleDetailsPermissions

