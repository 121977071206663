import React, {useState, useEffect} from 'react'


// Components
import Page from 'cli/ui/pages/layout/Page'
import {Row, Col} from 'reactstrap'
import {LoadingBig} from 'cli/ui/components/loading'
import CalendarList from './CalendarList'
import { ActionEdit} from 'cli/ui/components/buttons/actions'
import withContext from 'cli/context/withContext'
// breads
const _BREADS = [['/', 'Inicio'], ['/settings', 'Configuración'], ['Calendario']]

const Calendar = ({fetcher}) => {
 
  const [calendars, setCalendars]= useState([])
  const [status, setStatus]= useState(0)

  useEffect(() => {
    async function fetchData() {
      const raw = await fetcher.get('/api/calendar/detail')
      const nCalendars = raw.data
      setCalendars(nCalendars)
      setStatus(1)
    }
    fetchData()

  }, [fetcher])


  const handleChange = async (changes) => {
    const setid = changes.id
    const idx= calendars.findIndex(s => s.id == setid)
    const curSet = calendars[idx]
    
    const _res= await fetcher.upsave('/api/calendar', changes)
    
    let nCalendars= [...calendars]
    nCalendars[idx]= {
      ...curSet,
      ...changes
    }

    setCalendars(nCalendars)
  }


  return (
    <Page breads={_BREADS}
          title="Eventos automáticos"
          actions= {
            <ActionEdit onClick={() => alert('hey')} />
          }>
      <Row>
        <Col xs="12">   
          {status < 1 ?
            <LoadingBig /> :
            <CalendarList calendars={calendars}
                          onChange={(changes) => handleChange(changes)}/>
          }  
        </Col>
      </Row>
    </Page>
  )
}


export default withContext(Calendar)








