import React from 'react'
import Hyper from './base'

const HyperEdition = ({ id, name, taxCode }) =>
  <Hyper bcolor  = "#cce0ff"
         icon    = {"edition"}
         to      = {`/taxes/${taxCode}/edition/${id}`}
         text    = {name}
         permissionCode = "process_read">
  </Hyper>

export default HyperEdition
