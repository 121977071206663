import React from 'react'
import { Route } from 'react-router-dom'

import Councils            from 'cli/ui/pages/councils/Councils'
import CouncilsHist        from 'cli/ui/pages/councils/CouncilsHist'
import CouncilDetails      from 'cli/ui/pages/councils/det/CouncilDetails'
import CouncilNew          from 'cli/ui/pages/councils/det/CouncilNew'
import I5OrdinanceDetails  from 'cli/ui/pages/councils/det/ordinances/i5/det/I5OrdinanceDetails'

import LUOrdinanceLicenses from 'cli/ui/pages/councils/det/ordinances/lu/LUOrdinanceLicenses'
import LUOrdinanceDetails  from 'cli/ui/pages/councils/det/ordinances/lu/det/LUOrdinanceDetails'
import LULicenseDetails    from 'cli/ui/pages/councils/det/ordinances/lu/det/licenses/LULicenseDetails'
import LULicenseImport     from 'cli/ui/pages/councils/det/ordinances/lu/det/licenses/import/LULicenseImport'

import AEOrdinanceLicenses from 'cli/ui/pages/councils/det/ordinances/ae/AEOrdinanceLicenses'
import AEOrdinanceDetails  from 'cli/ui/pages/councils/det/ordinances/ae/det/AEOrdinanceDetails'
import AELicenseDetails    from 'cli/ui/pages/councils/det/ordinances/ae/det/licenses/AELicenseDetails'
import AELicenseImport     from 'cli/ui/pages/councils/det/ordinances/ae/det/licenses/import/AELicenseImport'

import TOOrdinanceDetails  from 'cli/ui/pages/councils/det/ordinances/to/det/TOOrdinanceDetails'
import VTOrdinanceDetails  from 'cli/ui/pages/councils/det/ordinances/vt/det/VTOrdinanceDetails'

const CouncilRoutes = (permiss) => {
  if (!permiss.can_user_read_councils()) {
    return []
  }
  return [
    <Route key="councils_00" path={'/councils'} element={<Councils/>}/>,
    ...permiss.can_user_edit_councils()
    ? [<Route key="councils_01" path={'/councils/new'} element={<CouncilNew/>}/>]
    : [],
    <Route key="councils_02" path={'/councils/hist'} element={<CouncilsHist/>}/>,
    <Route key="councils_03" path={'/councils/:idCouncil'} element={<CouncilDetails/>}/>,
    <Route key="councils_04" path={'/councils/:idCouncil/ae/:idOrdinance'} element={<AEOrdinanceLicenses/>}/>,
    <Route key="councils_05" path={'/councils/:idCouncil/ae/:idOrdinance/det'} element={<AEOrdinanceDetails/>}/>,
    <Route key="councils_06" path={'/councils/:idCouncil/ae/:idOrdinance/license/:idLicense'} element={<AELicenseDetails/>}/>,
    ...permiss.can_user_edit_ordinances()
    ? [<Route key="councils_07" path={'/councils/:idCouncil/ae/:idOrdinance/import'} element={<AELicenseImport/>}/>]
    : [],
    <Route key="councils_08" path={'/councils/:idCouncil/i5/:idOrdinance'} element={<I5OrdinanceDetails/>}/>,
    <Route key="councils_09" path={'/councils/:idCouncil/to/:idOrdinance'} element={<TOOrdinanceDetails/>}/>,
    <Route key="councils_10" path={'/councils/:idCouncil/vt/:idOrdinance'} element={<VTOrdinanceDetails/>}/>,
    <Route key="councils_11" path={'/councils/:idCouncil/lu/:idOrdinance'} element={<LUOrdinanceLicenses/>}/>,
    <Route key="councils_12" path={'/councils/:idCouncil/lu/:idOrdinance/det'} element={<LUOrdinanceDetails/>}/>,
    <Route key="councils_13" path={'/councils/:idCouncil/lu/:idOrdinance/license/:idLicense'} element={<LULicenseDetails/>}/>,

    ...permiss.can_user_edit_ordinances()
    ? [<Route key="councils_14" path={'/councils/:idCouncil/lu/:idOrdinance/import'} element={<LULicenseImport/>}/>]
    : []
]}



export default CouncilRoutes
