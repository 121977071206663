import {intre_from_parts, intre_diff, intre_get_year} from 'intre'
import { parseNum } from 'farrapa/numbers'
import {getAreaFee, getCorrectIndexByActivity}from './ProcessAETaxTables'

const IAE_FEE_MIN = 37.32

class ProcessAETaxDet {
  
  id                       = undefined
  process_ae_tax_id        = undefined
  year                     = undefined
  declared_unit_price      = 0.0
  declared_quantity        = 0.0
  declared_area            = 0.0
  declared_unit_price_sum  = undefined
  declared_quantity_sum    = undefined
  declared_area_sum        = undefined
  checked_unit_price       = 0.0
  checked_quantity         = 0.0
  checked_area             = 0.0
  checked_unit_price_sum   = undefined
  checked_quantity_sum     = undefined
  checked_area_sum         = undefined  

  constructor(tax, detail) {
    this.tax  = tax
    this.update(detail)
  }

  update(det) {
    if (det!=undefined) {
      this.id                      = det.id                      != undefined ? det.id                                              : undefined
      this.year                    = det.year                    != undefined ? det.year                                            : undefined
      this.declared_unit_price     = det.declared_unit_price     != undefined ? parseNum(det.declared_unit_price)                   : 0.0
      this.declared_quantity       = det.declared_quantity       != undefined ? parseNum(det.declared_quantity)                     : 0.0
      this.declared_area           = det.declared_area           != undefined ? parseNum(det.declared_area)                         : 0.0
      this.declared_unit_price_sum = det.declared_unit_price_sum != undefined ? det.declared_unit_price_sum.map((n) => parseNum(n)) : undefined
      this.declared_quantity_sum   = det.declared_quantity_sum   != undefined ? det.declared_quantity_sum.map((n) => parseNum(n))   : undefined
      this.declared_area_sum       = det.declared_area_sum       != undefined ? det.declared_area_sum.map((n) => parseNum(n))       : undefined
      this.checked_unit_price      = det.checked_unit_price      != undefined ? parseNum(det.checked_unit_price)                    : 0.0
      this.checked_quantity        = det.checked_quantity        != undefined ? parseNum(det.checked_quantity)                      : 0.0
      this.checked_area            = det.checked_area            != undefined ? parseNum(det.checked_area)                          : 0.0
      this.checked_unit_price_sum  = det.checked_unit_price_sum  != undefined ? det.checked_unit_price_sum.map((n) => parseNum(n))  : undefined
      this.checked_quantity_sum    = det.checked_quantity_sum    != undefined ? det.checked_quantity_sum.map((n) => parseNum(n))    : undefined
      this.checked_area_sum        = det.checked_area_sum        != undefined ? det.checked_area_sum.map((n) => parseNum(n))        : undefined
    }
  }

  get declared_tax_element() {
    return (isNaN(this.declared_quantity) || this.declared_quantity<=0)
      ? this.declared_unit_price
      : this.declared_quantity * this.declared_unit_price
  }
  get checked_tax_element() {
    return (isNaN(this.checked_quantity) || this.checked_quantity<=0)
      ? this.checked_unit_price
      : this.checked_quantity * this.checked_unit_price
  }

  get declared_area_fee() {
    return getAreaFee(this.tax.division, this.declared_area, this.tax.process.council_inhabitants)
  }
  get checked_area_fee() {
    return getAreaFee(this.tax.division, this.checked_area, this.tax.process.council_inhabitants)
  } 

  get declared_correct_index() {
    return getCorrectIndexByActivity(this.tax.division, this.declared_tax_element)
  }
  get checked_correct_index() {
    return getCorrectIndexByActivity(this.tax.division, this.checked_tax_element)
  }

  get declared_fee_rate() {
    const fee_rate= this.declared_tax_element + (this.declared_area_fee * this.declared_correct_index)
    return Math.max(fee_rate || 0.0, IAE_FEE_MIN)
  }
  get checked_fee_rate() {
    const fee_rate= this.checked_tax_element + (this.checked_area_fee * this.checked_correct_index)
    return Math.max(fee_rate || 0.0, IAE_FEE_MIN)
  }

  get declared_weighted_rate() {
    return this.declared_fee_rate * this.tax.coefficient
  }
  get checked_weighted_rate() {
    return this.checked_fee_rate * this.tax.coefficient
  }
  
  get declared_increment_rate() {
    return this.declared_weighted_rate * (this.tax.location_index || 0)
  }
  get checked_increment_rate() {
    return this.checked_weighted_rate * (this.tax.location_index || 0)
  }  

  get declared_province_rate() {
    return this.declared_weighted_rate * (this.tax.province_tax || 0)/100
  }

  get checked_province_rate() {
    return this.checked_weighted_rate * (this.tax.province_tax || 0)/100
  }

  get declared_debt_total() {
    return this.declared_increment_rate + this.declared_province_rate
  }
  get checked_debt_total() {
    return this.checked_increment_rate + this.checked_province_rate
  }

  get dfrom() {
    if (this.year<this.tax.process.year_to) {
      return intre_from_parts(this.year+1, 1, 1)
    }
    return undefined
  }

  get dto() {
    const dec31 = intre_from_parts(this.year, 12, 31)
    
    if (this.tax.process.end_liq_date) {
      
      return Math.min(this.tax.process.end_liq_date, dec31)
    }
    return dec31
  }

  get total_fee_rate() {
    return this.declared_fee_rate + this.checked_fee_rate
  }
  get total_weighted_rate() {
    return this.declared_weighted_rate + this.checked_weighted_rate
  }
  get total_province_rate() {
    return this.total_weighted_rate * (this.tax.province_tax || 0)/100
  }
  get total_debt_difference() {
    return this.checked_debt_total - this.declared_debt_total
  }
  /*
  get total_interest() {
    let interest= 0.0
    for (let year= this.year+1; year<= this.tax.process.year_to; year++) {
      const irate =  parseNum(this.tax.process.irates.filter((i) => i.year == year)[0].value)
      
      const dfrom= intre_from_parts(year, 1, 1)
      
      const dec31 = intre_from_parts(this.year, 12, 31)
      let dto= dec31
      if (this.tax.process.end_liq_date) {
        dto= Math.min(this.tax.process.end_liq_date, dec31)
      }      
      
      const days= intre_diff(dto, dfrom, 'days') + 1

      interest+= this.total_debt_difference * (irate/100) / 365 * days
    }

    return interest

  }*/

  get interest() {
    // irate TODO cual aplica? this.year? Dentro de cada calculo el del año e cuestion?
    const irate =  parseNum(this.tax.process.irates.filter((i) => i.year == this.year)[0].value)

    let year_to   = this.tax.process.year_to   
    if (this.tax.process.end_liq_date) {
      year_to = intre_get_year(this.tax.process.end_liq_date)
    }

    // dfrom - dto
    const dfrom= intre_from_parts(this.year+1, 1, 1)

    const dec31 = intre_from_parts(year_to, 12, 31)
    let dto= dec31
    
    if (this.tax.process.end_liq_date) {
      dto= Math.min(this.tax.process.end_liq_date, dec31)
    }  

    let sum_days= 0
    let sum_interest= 0.0

    for (let year= this.year+1; year<= year_to; year++) {
      //const irate =  parseNum(this.tax.process.irates.filter((i) => i.year == year)[0].value)
      
      const ydfrom= intre_from_parts(year, 1, 1)
      const ydec31 = intre_from_parts(year, 12, 31)
      const ydto = Math.min(dto, ydec31)

      const ydays= intre_diff(ydto, ydfrom, 'days') + 1

      sum_days += ydays
      sum_interest+= this.total_debt_difference * (irate/100) / 365 * ydays
    }

    return {
      from: dfrom,
      to: dto,
      days: sum_days,
      irate: irate,
      interest: sum_interest
    }

  }  




  get total_debt() {
    return this.total_debt_difference + this.interest.interest
  }


  toJson() {
    return {
      id                      : this.id,
      process_ae_tax_id       : this.tax.id,
      year                    : this.year,
      declared_unit_price     : this.declared_unit_price,
      declared_quantity       : this.declared_quantity,
      declared_area           : this.declared_area,
      declared_unit_price_sum : this.declared_unit_price_sum,
      declared_quantity_sum   : this.declared_quantity_sum,
      declared_area_sum       : this.declared_area_sum,
      checked_unit_price      : this.checked_unit_price,
      checked_quantity        : this.checked_quantity,
      checked_area            : this.checked_area,
      checked_unit_price_sum  : this.checked_unit_price_sum,
      checked_quantity_sum    : this.checked_quantity_sum,
      checked_area_sum        : this.checked_area_sum
    }
  }


  forDocx() {
    return {
      id                  : this.id,
      year                : this.year,
      declared_unit_price : this.declared_unit_price,
      declared_quantity   : this.declared_quantity,
      declared_area       : this.declared_area,
      declared_area_fee   : this.declared_area_fee,
      declared_fee_rate   : this.declared_fee_rate,
      declared_weighted_rate: this.declared_weighted_rate,
      declared_increment_rate: this.declared_increment_rate,
      declared_province_rate: this.declared_province_rate,
      declared_debt_total: this.declared_debt_total,
      checked_unit_price  : this.checked_unit_price,
      checked_quantity    : this.checked_quantity,
      checked_area        : this.checked_area,
      checked_area_fee    : this.checked_area_fee,
      checked_fee_rate    : this.checked_fee_rate,
      checked_weighted_rate: this.checked_weighted_rate,
      checked_increment_rate: this.checked_increment_rate,
      checked_province_rate: this.checked_province_rate,
      checked_debt_total: this.checked_debt_total,
      interest                : this.interest,
      total_fee_rate: this.total_fee_rate,
      total_weighted_rate: this.total_weighted_rate,
      total_province_rate: this.total_province_rate,
      total_debt_difference: this.total_debt_difference,
      total_debt: this.total_debt,

    }
  }   
}

export default ProcessAETaxDet




