import React from 'react'
import ActionButton from './ActionButton'


const _ActionIsRunning = ({ onClick, to, enabled }) => 
  <ActionButton color='#6fc306'
    label='En funcionamiento'
    icon='cogs'
    onClick={(e) => { onClick(e) }}
    to={to}
    enabled={enabled != undefined ? enabled : true}>
  </ActionButton>

const _ActionIsPaused = ({ onClick, to, enabled }) => 
  <ActionButton color='rgb(243, 141, 8)'
    label='Pausado'
    icon='pause2'
    onClick={(e) => { onClick(e) }}
    to={to}
    enabled={enabled != undefined ? enabled : true}>
  </ActionButton>


const ActionRunning = ({ onClick, to, enabled, running }) => {
  return running ? _ActionIsRunning({ onClick, to, enabled }) : _ActionIsPaused({ onClick, to, enabled })
}
  
export default ActionRunning
