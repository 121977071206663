import React from "react"
import {intre_add_days, intre_to_str} from 'intre'

const CalendarDays= ({config, monthBounds}) => {
  const days = [];

  for (let i = 0; i < 7; i++) {
    days.push(
      <div className="col col-center" key={i}>
        {intre_to_str( intre_add_days(monthBounds.start, i), config.days.format)}
      </div>
    );
  }

  return (
    <div className="days row">{days}</div>
  )
}

export default CalendarDays