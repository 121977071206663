import {CLOSING_REASONS} from 'bl/ns/process/process'

import { getStepShortName, getStepName } from 'bl/ns/process/steps'

const getStatusName = (process, what, short= true) => {
  const getStatusNameFor = (flow) => {
    const stepId= flow?.status_step?.id
    if (stepId) {
      if (short) {
        return getStepShortName(stepId, process)
      } else {
        return getStepName(stepId, process)
      }
    }
    return ''    
  }

  let step_name= ''

  if (what == 'penalty_19') {
    if (process.penalty_19_on===true) {
      if (process.penalty_19_enabled===false) {
        step_name= 'Deshabilitada'
      } else {
        step_name= getStatusNameFor(process.penalty_19)
      }      
    }
  } else if (what == 'penalty_20') {
    if (process.penalty_20_on===true) {
      if (process.penalty_20_enabled===false) {
        step_name= 'Deshabilitada'
      } else {
        step_name= getStatusNameFor(process.penalty_20)
      }
    } 
  } else {
    if (process.is_closed) {
      //step_name= `${CLOSING_REASONS[process.closing_reason]}: ${process.closing_explanation}`
      step_name= `Cerrado: ${CLOSING_REASONS[process.closing_reason]}`
    } else {
      step_name= getStatusNameFor(process.inspect)
    }
  }

  return step_name
}


export default getStatusName