
import React from 'react'
import Hyper from './base'
import {uvl} from 'farrapa/commons'

const HyperAELicense = ({ license, maxWidth }) =>
  <Hyper icon    = "license"
         to      = {`/councils/${license.council_id}/ae/${license.ordinance_id}/license/${license.id}`}
         text    = {`${uvl(license.name,'')} - ${uvl(license.taxpayer_name,'')}`}
         maxWidth= {maxWidth}
         permissionCode = "ordinance_read">
  </Hyper>

export default HyperAELicense
