import React from 'react'
import ActionButton from './ActionButton'

const ActionPermission = ({ onClick, enabled, isAllowed, label }) =>
  <ActionButton color  = {isAllowed ? '#1A7777' : '#B6E3E1'}
                label  = {label}
                icon   = 'shield'
                onClick= {onClick}
                enabled= {enabled != undefined ? enabled : true}>
  </ActionButton>

export default ActionPermission
