import {IfPermission, IfNotPermission} from 'cli/context/IfPermission'
import React from 'react'
import Page from 'cli/ui/pages/layout/Page'
import Calendar from './Calendar'


const Dashboard = () => {
  return (
    <>
      <IfPermission code="calendar_read">
        <Calendar/>
      </IfPermission>
      <IfNotPermission code="calendar_read">
        <Page breads = {['Inicio']}
              title  = "Inicio"
              actions= {null}>
        </Page>
      </IfNotPermission>    
    </>
  )
}

export default Dashboard
