import React from "react"
import {uvl} from 'farrapa/commons'
import {intre_are_same} from 'intre'


const CalendarEvent = ({day, event, color, onClick, hovered, onHover}) => {
  return (
    <div className={`event 
                     ${ intre_are_same(day, event.dstart, 'day') ? 'starts' : '' } 
                     ${ intre_are_same(day, uvl(event.dend, event.dstart), 'day') ? 'ends' : '' }
                     ${hovered ? 'hover' : ''}`}
         style={{
                cursor: "pointer",
                ...color!=undefined ? {backgroundColor: color} : {}
                }}
         onClick     ={(ev) => {ev.stopPropagation(); onClick(event, day)}}
         onMouseEnter={(ev) => {ev.stopPropagation(); onHover(event, true)}}
         onMouseLeave={(ev) => {ev.stopPropagation(); onHover(event, false)}}
         data-tooltip={event.name}>
      <div className={`event-content`}>
        {event.name}
      </div>
    </div>
  )
}

export default CalendarEvent