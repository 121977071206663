const COLOR_DISABLED = 'lightgray'
const COLOR_INSPECT = 'green'
const COLOR_INSPECT_TAX_NOT_READY = 'gray'
const COLOR_INSPECT_NEGATIVE = 'red'
const COLOR_PENALTY_20 = '#d879cc'
const COLOR_PENALTY_19_BAD = 'red'
const COLOR_PENALTY_19 = 'orange'
const COLOR_GROUPS= {
  'inspect': COLOR_INSPECT,
  'penalty_19': COLOR_PENALTY_19,
  'penalty_20': COLOR_PENALTY_20
}

export {
  COLOR_DISABLED,
  COLOR_INSPECT,
  COLOR_INSPECT_TAX_NOT_READY,
  COLOR_INSPECT_NEGATIVE,
  COLOR_PENALTY_20,
  COLOR_PENALTY_19_BAD,
  COLOR_PENALTY_19,
  COLOR_GROUPS
}