import React from 'react'
import {FForm, FInputText, FInputSelectSearch, FInputUInt, FInputCheckbox} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'
import {LU} from 'bl/ns/taxes'

const NewEditionStep1 = ({taxCode, edition, years, ordinanceList, agentList, onChangeEdition, onChangeYears}) => {


  let ordinanceMap = {}
  ordinanceList.map(o => ordinanceMap[o.id]= `[${o.council_name}] ${o.name}`)

  let council_id = undefined
  if (edition.ordinance_id!=undefined) {
    for (const o of ordinanceList) {
      if (o.id==edition.ordinance_id) {
        council_id= o.council_id
        break
      }
    }
  }
    
  return (
    <FForm onCancel     = {undefined}
            onSave       = {undefined}>
            <>
              <FInputText name        = "name"
                          label       = "Nombre"
                          value       = {edition.name}
                          required    = {true}
                          feedback    = {'Inserta un nombre para la tirada'}
                          onChange    = {(v) => onChangeEdition('name', v)}
              />
              <FInputSelectSearch  
                          options     = {ordinanceMap}
                          name        = 'ordinance_id'
                          label       = 'Ordenanza'
                          icon        = {<Icon icon={"ordinance"}/>}
                          value       = {edition.ordinance_id!=undefined ? edition.ordinance_id.toString() : ''}
                          onChange    = {(v) => onChangeEdition('ordinance_id', v ? parseInt(v) : undefined)}
                          feedback    = {"Seleciona una Ordenanza"}
                          clearable   = {true}
                          searchable  = {true}
                          required    = {true}
              />
              <FInputCheckbox name={'repeat_tax_payer'}
                              value={edition.repeat_tax_payer}
                              onChange={(v) => onChangeEdition('repeat_tax_payer', v)}
                              icon={<Icon icon={"search-plus"}/>}
                              label={'Repetir contribuyentes de otras tiradas'}
                              /*inline={true}
                              inputGroupStyle={{display: "inline-block"}}*/
                              />   
              { ((['ae','lu'].indexOf(taxCode) >= 0) && (edition.ordinance_id!=undefined))
              ? <div style={{marginBottom: "2em"}}>
                  <a href={`/councils/${council_id}/${taxCode}/${edition.ordinance_id}/import`} 
                     target={"_blank"} rel="noreferrer">
                  Importar archivo de licencias
                  </a>
                </div>
              : null}
              <FInputSelectSearch  
                          options     = {agentList}
                          name        = 'agent_id'
                          label       = 'Agente'
                          icon        = {<Icon icon={"user"}/>}
                          value       = {edition.agent_id!=undefined ? edition.agent_id.toString() : ''}
                          onChange    = {(v) => onChangeEdition('agent_id', v ? parseInt(v) : undefined)}
                          feedback    = {"Seleciona un Agente"}
                          clearable   = {true}
                          searchable  = {true}
                          required    = {true}
              />
              {taxCode==LU
              ? null
              : <>
                  <FInputUInt  
                              name        = 'year_from'
                              label       = 'Ejercicio desde'
                              feedback    = 'Ejercicio desde'
                              value       = {years.from}
                              onChange    = {(v) => onChangeYears('from', v)}
                              required    = {true}
                              gt          = {1990}
                  />
                  <FInputUInt  
                              name        = 'year_to'
                              label       = 'Ejercicio hasta'
                              feedback    = 'Ejercicio hasta'
                              value       = {years.to}
                              onChange    = {(v) => onChangeYears('to', v)}
                              required    = {true}
                              gt          = {1990}
                  /> 
                </>
              }             
            </>
    </FForm>
  )
}

export default NewEditionStep1




