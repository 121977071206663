import React, {useState, useEffect} from 'react'
import {intre_pretty_short} from 'intre'
import {TimelineEvent, TimelineEventInfo, TimelineEventRight} from 'cli/ui/components/timeline'

import EventNotifForm from './EventNotifForm'
import {GoOn, Back, ChangeNotification} from './parts/actions'


const EventNotif = ({subStep, onProcessFileChange, canEdit}) => {
  const [showEdit, setShowEdit]= useState(true)

  useEffect(() => {
    if (subStep.doc.file.notification_date!=undefined) {
      setShowEdit(false)
    }
  }, [subStep.doc.file.notification_date])

  const handleOnNotificated = (v) => {
    onProcessFileChange(subStep.doc.file.id, {notification_date: v, notification_accepted: 1})
    setShowEdit(false)
  }

  
  const handleOnRejected = (v) => {
    onProcessFileChange(subStep.doc.file.id, {notification_date: v, notification_accepted: 0})
    setShowEdit(false)
  }

  const handleOnGoOn = () => {
    onProcessFileChange(subStep.doc.file.id, {goon: true})
  }

  return (
    <>
      {subStep.doc.notif_status=='hidden'
       ? null
       :  <TimelineEvent key      ={`process_inspect_${subStep.doc.id}_print_notif`}
                         legend   = {{icon: "agree", 
                                     color: subStep.doc.file.notification_date!=undefined
                                            ? subStep.doc.file.notification_accepted==0
                                              ? 'red'
                                              : 'green'
                                            : 'gray'}}
                         date     = {subStep.doc.file.notification_date}
                         isLast   = {subStep.done===false}
                         >

            <TimelineEventInfo>
              {subStep.doc.notif_status=='pending' || subStep.doc.notif_status=='postponed'
                ? <i style={{color: "gray"}}>Pendiente de notificación</i>
                : <i style={{color: "gray"}}>{`${subStep.doc.file.notification_accepted==0 ? 'Rechazada' : 'Notificada'} el: ${intre_pretty_short(subStep.doc.file.notification_date)}`}</i>
              }
            </TimelineEventInfo>
            <TimelineEventRight>
              {canEdit
               ? <>
                  {subStep.doc.file.notification_date==undefined || showEdit
                  ? <>
                      <EventNotifForm file          = {subStep.doc.file}
                                      onNotificated = {(v) => handleOnNotificated(v)}
                                      onRejected    = {(v) => handleOnRejected(v)}/>
                        {subStep.doc.notif_status=='pending'
                        ? <GoOn      onClick   = {() => handleOnGoOn()}/>
                        : null}
                      
                      {showEdit && subStep.doc.file.notification_date!=undefined
                        ? <Back onClick={() => setShowEdit(false)}/>
                        : null
                      }
                    </>
                  : <ChangeNotification onClick={() => setShowEdit(true)}/>
                  }
                </>
               : null}
            </TimelineEventRight>




          </TimelineEvent>
      }
    </>
  )


}

export default EventNotif