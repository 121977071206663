import React, {useState, useEffect} from 'react'
import EventTitle from './events/EventTitle'
import { getStepName } from 'bl/ns/process/steps'

const ProcessStepContainer = ({process, processStep, children, collapsed}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed || false)
  useEffect(() => {
    if (collapsed!=isCollapsed) {
      setIsCollapsed(collapsed)
    }
  }, [collapsed, isCollapsed])
  return (
    <>
      {/* Title */}
      <EventTitle id         = {processStep.id}
                  title      = {getStepName(processStep.id, process)}
                  collapsed  = {isCollapsed}
                  onCollapse = {() => setIsCollapsed(! isCollapsed)}/>
      
      {isCollapsed
       ? null
       : children
      }
  </>
  )
}


export default ProcessStepContainer