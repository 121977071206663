import React from 'react'


const TaxSection = ({title, children}) => 
   <div style={{border: "1px solid #c2cfd6", padding: "1em", margin: "1em 0"}}>
     <h2>{title}</h2>
     {children}
   </div>

export default TaxSection

