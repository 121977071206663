import React, {useState, useEffect} from 'react'
import {FForm, FInputEpoch} from 'formiga-reactstrap'
import {MarkNotification, MarkRejection} from './parts/actions'


const EventNotifForm = ({file,  onNotificated, onRejected}) => {
  const [notifDate, setNotifDate]= useState(file.notification_date)
  
  useEffect(() => {
    setNotifDate(file.notification_date)
  }, [file])

  return (
    <FForm  inline
            renderButtons={(valid, _readElements) => {
              return (
              <>
                <MarkNotification onClick={() => onNotificated(notifDate)} disabled={!valid || notifDate=='' || notifDate==undefined}/>
                <MarkRejection onClick={() => onRejected(notifDate)} disabled={!valid || notifDate=='' || notifDate==undefined}/>
              </>
              )}
            }>
              <FInputEpoch  name       = {`notification_date_${file.id}`}
                            value      = {notifDate}
                            onChange   = {(v) => setNotifDate(v)}
                            icon       = {"Fecha de notificación"}
                            required   = {true}
                            feedback   = {' '}
                            locale     = 'es'
                            />
    </FForm>
  )
}

export default EventNotifForm
