import React from 'react'
import {TimelineEvent, TimelineEventInfo, TimelineEventRight} from 'cli/ui/components/timeline'
import {WithJudicial, WithoutJudicial} from './parts/actions'


const EventJudicial = ({subStep, onProcessChange, canEdit}) => {
  const handleOnJudicial = (v) => {
    onProcessChange({[subStep.field_name]: v})
  }
    

  return (
      <TimelineEvent  key      ={`process_inspect_judicial`}
                      legend   = {{icon: "agree", 
                                  color: subStep.judicial_status=='pending'
                                         ? 'gray'
                                         : subStep.judicial
                                           ? 'red' 
                                           : 'lightgreen'
                                 }}
                      date     = {undefined /*processDoc.file.notification_date*/}
                      isLast   = {subStep.done===false}
                      >

        <TimelineEventInfo>
          <i style={{color: "gray"}}>
            {subStep.judicial_status=='pending'
             ? "Pendiente de posible Rec. Contencioso"
             : subStep.judicial==true
               ? "Con Rec. Contencioso"
               : "Sin Rec. Contencioso"
            }
         </i>
        </TimelineEventInfo>
        <TimelineEventRight>
          {canEdit
           ? <>
              <WithJudicial disabled={subStep.judicial==true}
                            onClick={() => handleOnJudicial(true)}/>
              <WithoutJudicial disabled={subStep.judicial==false}
                            onClick={() => handleOnJudicial(false)}/>
            </>
           : null}
        </TimelineEventRight>
      </TimelineEvent>
  )


}

export default EventJudicial