import React from 'react'
import ProcessEditForm from './ProcessEditForm'
import { ActionLink} from 'cli/ui/components/buttons'
import {Row, Col} from 'reactstrap'


const ProcessEdit = (
  {process, onProcessEdit, onCancel, onProcessReopen, onProcessAsDisagree, onProcessAsTest, 
    onProcessDropPenalty19, onProcessDropPenalty20, 
    onProcessTogglePenalty19, onProcessTogglePenalty20, 
    onProcessSwitchReductPaymentValue}) => {
  const showReopen= process.is_closed 
  const showAsDisagree= process.minutes_agreed==true && process.minutes_serial==-1
  const showDropPenalty= process.penalty_19_on===true || process.penalty_20_on===true
  const showAsTest= process.minutes_serial!=-1
  return (
    <div style={{border: "1px solid #c2cfd6", 
                  borderRadius: "0.5em",
                  padding: "1.5em",
                  margin: "1em 0",
                  default: undefined}}>    
      <Row>
        <Col xs="12" md="6">
          {/* Title */}
          <div style={{margin: "1em"}}>
            <b style={{fontSize: "1.25em"}}>Modificar expediente</b>
          </div>

          {/* Form */}
          <ProcessEditForm process       = {process}
                            onProcessEdit = {onProcessEdit}
                            onCancel      = {onCancel}/>
        </Col>

        <Col xs="12" md="6">
          {(showReopen || showAsDisagree || showDropPenalty || showAsTest)
           ? <>
              {/* Title */}
              <div style={{margin: "1em"}}>
                <b style={{fontSize: "1.25em"}}>Otras opciones</b>
              </div>

                {/* Reopen if closed */}
                {(showReopen)
                  ? <div style={{margin: "1em 0"}}>
                      <ActionLink onClick={() => onProcessReopen()}>
                        {"Reabrir expediente"}
                      </ActionLink>
                    </div>
                  : null
                }


                {/* Hack for migrated processes */}
                {(showAsDisagree)
                  ? <div style={{margin: "1em 0"}}>
                      <ActionLink onClick={() => onProcessAsDisagree()}>
                        {"Pasar a disconformidad"}
                      </ActionLink>
                    </div>
                  : null
                }

                {/* drop Penalties */}
                {( process.penalty_19_on===true)
                  ? <>
                      {( process.penalty_19_enabled===false)
                      ? <div style={{margin: "1em 0"}}>
                          <ActionLink onClick={() => onProcessTogglePenalty19(true)}>
                            {"Habilitar sanción 191/192"}
                          </ActionLink>
                        </div>
                      :  <div style={{margin: "1em 0"}}>
                          <ActionLink onClick={() => onProcessTogglePenalty19(false)}>
                            {"Deshabilitar sanción 191/192"}
                          </ActionLink>
                        </div>
                     }                      
                      <div style={{margin: "1em 0"}}>
                        <ActionLink onClick={() => onProcessDropPenalty19()}>
                          {"Eliminar sanción 191/192"}
                        </ActionLink>
                      </div>                      
                    </>
                  : null
                }

                {( process.penalty_20_on===true)
                  ? <>
                     {( process.penalty_20_enabled===false)
                      ? <div style={{margin: "1em 0"}}>
                          <ActionLink onClick={() => onProcessTogglePenalty20(true)}>
                            {"Habilitar sanción 203"}
                          </ActionLink>
                        </div>
                      :  <div style={{margin: "1em 0"}}>
                          <ActionLink onClick={() => onProcessTogglePenalty20(false)}>
                            {"Deshabilitar sanción 203"}
                          </ActionLink>
                        </div>
                     }
                      <div style={{margin: "1em 0"}}>
                        <ActionLink onClick={() => onProcessDropPenalty20()}>
                          {"Eliminar sanción 203"}
                        </ActionLink>
                      </div>
                    </>
                  : null
                }         


                {( process.penalty_20_on===true)
                  ? <div style={{margin: "1em 0"}}>
                    <span>Sanción Art. 203 LGT. </span>
                    {process.penalty_20_reduct_payment_value==40
                     ?  <>
                          <span>Reducción por pago: 40% </span>
                          <ActionLink onClick={() => onProcessSwitchReductPaymentValue(25, '20')}>
                            {"Pasar a 25%"}
                          </ActionLink>
                        </>
                     : <>
                        <span>Reducción por pago: 25% </span>
                        <ActionLink onClick={() => onProcessSwitchReductPaymentValue(40, '20')}>
                          {"Pasar a 40%"}
                        </ActionLink>
                      </>
                    }
                    </div>
                  : null
                }           


                {( process.penalty_19_on===true)
                  ? <div style={{margin: "1em 0"}}>
                    <span>Sanción Art. 191/192 LGT. </span>
                    {process.penalty_19_reduct_payment_value==40
                     ?  <>
                          <span>Reducción por pago: 40% </span>
                          <ActionLink onClick={() => onProcessSwitchReductPaymentValue(25, '19')}>
                            {"Pasar a 25%"}
                          </ActionLink>
                        </>
                     : <>
                        <span>Reducción por pago: 25% </span>
                        <ActionLink onClick={() => onProcessSwitchReductPaymentValue(40, '19')}>
                          {"Pasar a 40%"}
                        </ActionLink>
                      </>
                    }
                    </div>
                  : null
                }                                 

                {/* Hack for test processes */}
                {(showAsTest)
                  ? <div style={{margin: "1em 0"}}>
                      <ActionLink onClick={() => onProcessAsTest()}>
                        {"Marcar como test (sin Nº de Acta)"}
                      </ActionLink>
                    </div>
                  : null
                }                
              </>
            : null}
        </Col>
      </Row>
    </div>
  )


}

export default ProcessEdit