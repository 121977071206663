import React from 'react'
import OrdinanceDetails from '../../common/OrdinanceDetails'
import I5OrdinanceDetailsForm from './I5OrdinanceDetailsForm'

const I5OrdinanceDetails = (props) =>
  <OrdinanceDetails
             taxCode='i5'
             taxDataFields={['tax_rate', 'inspection_date', 'bulletin_date', 'liq_limit_day', 'liq_limit_month']}
             renderForm={(fprops) => I5OrdinanceDetailsForm({...fprops})}
             {...props}/>

export default I5OrdinanceDetails

