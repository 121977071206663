import React from 'react'
import TaxFooterSave from '../common/TaxFooterSave'
import TaxSection from '../common/TaxSection'
import ProcessI5TaxFields from './ProcessI5TaxFields'
import ProcessI5TaxTable from './ProcessI5TaxTable'

const ProcessI5Taxes = ({ process, onChangeProcess, onChange, onSave, onGoToInspect, canEdit }) => {
  
  return (
    <TaxSection title="Tasas">
      <ProcessI5TaxFields process      ={process}
                          onChangeProcess={onChangeProcess}
                          onGoToInspect={onGoToInspect}
                          canEdit       = {canEdit}/>
      {canEdit
       ?
         <TaxFooterSave onClick={() => onSave()}/>
         : null}
      <ProcessI5TaxTable  process = {process}
                          onChange = {onChange}
                          canEdit       = {canEdit}/>
      
    </TaxSection>
  )
}


export default ProcessI5Taxes




