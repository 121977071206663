import React, {useState, useEffect, useCallback} from 'react'
import {FForm, FInputUInt} from 'formiga-reactstrap'
import {SetSerial, SearchNextSerial} from './parts/actions'


const EventSerialForm = ({subStep, onChange, onProcessGetNextSerial}) => {
  const [serialNum, setSerialNum]= useState({
    current: subStep.serial_num,
    available: undefined
  })

  const handleChange = useCallback((num, what) => {
    if (num!=serialNum[what]) {
      const nSerialNum= {
        ...serialNum,
        [what]: num}
      setSerialNum(nSerialNum)    
    }
  }, [serialNum])

  useEffect(() => {
    if (serialNum.available==undefined) {
      async function fetchData() {
        const avail= await onProcessGetNextSerial(subStep.serial_year, subStep.agreed)
        handleChange(avail, 'available')
      }
      
      fetchData()
    }
  }, [handleChange, onProcessGetNextSerial, subStep.serial_year, subStep.agreed, serialNum.available]) 

  const handleSearchNext = async () => {
    const avail= await onProcessGetNextSerial(subStep.serial_year, subStep.agreed)
    handleChange(avail, 'available')
    handleChange(avail, 'current')
    onChange(avail)
  }

  if (!serialNum.available) {
    return null
  }

  return (
    <FForm  inline
            renderButtons={(valid) => 
              <>
                {serialNum.current!=undefined
                 ? <SetSerial onClick={() => onChange(serialNum.current)} 
                              disabled={!valid}/>
                 : null}
                
                <SearchNextSerial onClick={() => handleSearchNext()} 
                                  disabled={!valid}/>
              </>
              
            }>
              <FInputUInt name       = "serial_num"
                          value      = {serialNum.current || serialNum.available}
                          onChange   = {(v) => handleChange(v, 'current')}
                          icon       = {subStep.prefix}
                          required   = {true}
                          feedback   = {' '}/>
    </FForm>
  )
}

export default EventSerialForm
