import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'


// components
import Page from 'cli/ui/pages/layout/Page'
import {Row, Col} from 'reactstrap'
import { LoadingBig } from 'cli/ui/components/loading'
import TaxPayerNewForm from './TaxPayerNewForm'
import withContext from 'cli/context/withContext'


let _BREADS= [['/', 'Inicio'], ['/taxpayers', 'Contribuyentes']]

const TaxPayerNew = ({fetcher}) => {
  const navigate= useNavigate()
  
  const [sectorOptions, setSectorOptions]= useState({})
  const [existingNames, setExistingNames]= useState([])
  const [existingNifs, setExistingNifs]= useState([])
    const [status, setStatus]= useState(0)

  const [taxPayer, setTaxPayer]= useState({})

  useEffect(() => {
    async function fetchData() {
      const existingNames = await fetcher.name_list('/api/tax_payer')
      setExistingNames(existingNames)

      const nExistingNifs = await fetcher.distinct('/api/tax_payer', 'nif')
      setExistingNifs(nExistingNifs.map((n) => n || ''))      

      const sOptions = await fetcher.key_list('/api/sector')
      setSectorOptions(sOptions)

      setStatus(1)
    }

    fetchData()

  }, [fetcher])

  
  const handleChange = (name, value) => {
    const nTaxPayer= {...taxPayer}
    nTaxPayer[name]= value
    setTaxPayer(nTaxPayer)
  }

  const handleSave = async () => {
    const res= await fetcher.upsave('/api/tax_payer', taxPayer)
    if (res>=1) {
      navigate(`/taxpayers/det/${res}`)
    }
  }

  

  if (status==0) {
    return (
      <LoadingBig/>
    )
  }

  return (
    <Page breads={[..._BREADS, 'Nuevo']}
          title  = {`Contribuyente: Nuevo`}
          actions={null}>
      <Row>
        <Col xs="12">
          {
            status < 1 
            ? <LoadingBig/>
            : <TaxPayerNewForm    taxPayer = {taxPayer}
                                  sectorOptions={sectorOptions}
                                  existingNames={existingNames}
                                  existingNifs={existingNifs}
                                  onChange= {(n,v) => handleChange(n,v)}
                                  onSave= {() => handleSave()}/>            
          }
        </Col>
      </Row>
    </Page>
  )
}


export default withContext(TaxPayerNew)
