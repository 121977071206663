const I5= 'i5'
const LU= 'lu'
const IAE= 'ae'
const TODP= 'to'
const IIVTNU= 'vt'

const TAX_NAMES= {
  [I5]  : '1,5',
  [LU]  : 'ICIO/LU',
  [IAE] : 'IAE',
  [TODP]: 'TODP',
  [IIVTNU]: 'IIVTNU'
}

const TAX_BACK_COLORS= {
  [I5]    : '#d2eef7',
  [LU]    : '#d8e3b9',
  [IAE]   : '#dbf2e3',
  [TODP]  : '#fff3cd',
  [IIVTNU]: '#e1c5da'
}

const TAX_COLORS= {
  [I5]    : '#2b8aa8',
  [LU]    : '#aae113',
  [IAE]   : '#0a9b3d',
  [TODP]  : '#ecc753',
  [IIVTNU]: '#df5ebf'
}

module.exports = {I5, LU, IAE, TODP, IIVTNU, TAX_NAMES, TAX_BACK_COLORS, TAX_COLORS}