import React, {useState, useEffect} from 'react'
import TaxSection from '../common/TaxSection'
import ProcessPenalty19Fields from './ProcessPenalty19Fields'
import ProcessPenalty19Table from './ProcessPenalty19Table'

import {PENALTY_19_RANK_RULE,
  PENALTY_INFRACTION_LEVEL, PENALTY_TYPE_NAMES} from 'bl/ns/process/penalty'

const REPETITIVE_INFRACTION_VALUES= {
  [PENALTY_INFRACTION_LEVEL.SOFT   ]:  5.0,
  [PENALTY_INFRACTION_LEVEL.BAD    ]: 15.0,
  [PENALTY_INFRACTION_LEVEL.SERIOUS]: 25.0,
}

const ECONOMICAL_LOSS_VALUES= [
  [10.0, 25.0, 10.0],
  [25.0, 50.0, 15.0],
  [50.0, 75.0, 20.0],
  [75.0,100.0, 25.0],
]

const ProcessPenalty19 = ({process, onChangeProcess, canEdit}) => {
  const [proposedValue, setProposedValue]= useState(0.0)

  useEffect(() => {
    let toValue= 0.0
    if (process.penalty_19_rank_rule!=undefined) {
      switch (process.penalty_19_rank_rule) {
        case PENALTY_19_RANK_RULE.REPETITIVE_INFRACTION:
          if (process.penalty_19_rank_rule_level!=undefined) {
            toValue= REPETITIVE_INFRACTION_VALUES[process.penalty_19_rank_rule_level]
          }
          break;
        
        case PENALTY_19_RANK_RULE.ECONOMICAL_LOSS:
          const l= process.penalty_19_rank_rule_loss
          if (l!=undefined) {
            ECONOMICAL_LOSS_VALUES.map(([f,t,v]) => {
              if (l>f && l<=t) {
                toValue= v
              }
            })
          } 
          break;
        /*
        case PENALTY_19_RANK_RULE.INVOICING_UNFULFILMEN:
          break;
        
        case PENALTY_19_RANK_RULE.SUBJECT_AGREE:
          break;
        */
        }       
    }
    if (toValue!=proposedValue) {
      setProposedValue(toValue)
    }
  }, [process.penalty_19_rank_rule, process.penalty_19_rank_rule_level, 
     process.penalty_19_rank_rule_loss, process.penalty_19_rank_rule_value, proposedValue])



  return (
    <TaxSection title={`Sanción ${PENALTY_TYPE_NAMES[process.penalty_19_type]}`}>
      <ProcessPenalty19Fields process={process}
                              onChangeProcess={onChangeProcess}
                              /*proposedValue={proposedValue}*/
                              canEdit       = {canEdit}
                              />

      <ProcessPenalty19Table  process={process}
                              onChangeProcess={onChangeProcess}
                              proposedValue={proposedValue}
                              canEdit       = {canEdit}/>
    </TaxSection>
    
  )
}

export default ProcessPenalty19