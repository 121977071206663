import {PTYPE} from 'bl/ns/process/process'

const _getFlowProgress = (flow, totSteps) => {
  if (flow.is_done) {
    return 100
  }
  if (!flow.steps || flow.steps.length==0) {
    return 0
  }
  
  let doneSteps= 0
  for (let i=0; i<flow.steps.length;i++) {
    for (let j=0; j<flow.steps[i].sub.length;j++) {
      if (flow.steps[i].sub[j].done) {
        doneSteps+= 1
      }
    }
  }

  const progress = Math.round(doneSteps*100 / totSteps,0)
  return progress
}

const getProgress = (process, what) => {
 
  if (what=='penalty_20') {
    return _getFlowProgress(process.penalty_20, 6)
  }
  
  if (what=='penalty_19') {
    return _getFlowProgress(process.penalty_19, 6)
  }

  const totSteps= process.process_type == PTYPE.REDUCED 
  ? 6 
  : process.minutes_agreed==false 
    ? 17
    : 13

  return _getFlowProgress(process.inspect, totSteps)
}

export default getProgress