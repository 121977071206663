import React, {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import withContext from 'cli/context/withContext'

import { Row, Col,  CardGroup, Card, CardHeader, CardBody, Alert } from 'reactstrap'
import {FForm, FInputText, FInputPassword} from 'formiga-reactstrap'

import Icon from 'cli/ui/components/icon'
import PageAnonym from '../layout/PageAnonym'


const msg_validations= {
  'Credenciales incorrectas': {username : false, password: false},
  'Usuario inexistente':      {username : false, password: true},
  'Contraseña incorrecta':    {username : true,  password: false}
}

const Login = ({login}) => {
  const navigate= useNavigate()

  const [credentials, setCredentials] = useState({
    username : '',
    password : ''
  })

  const [validation, setValidation] = useState({
    username : true,
    password : true
  })  

  const [message, setMessage] = useState(undefined)

  const handleChange = (n, v) => {
    const nCredentials= {...credentials}
    nCredentials[n]= v

    setCredentials(nCredentials)
    setValidation({
      username : true,
      password : true})
    setMessage(undefined)
  }

  async function handleLogin() {
    const {data} = await login(credentials)
    if (data?.authenticated) {
      setValidation({
        username : true,
        password : true})
      setMessage(undefined)
      navigate('/')
      // TODO WHY I need this now
      location.reload()
    } else {
      const msg = data?.info || 'Credenciales incorrectas'
      setMessage(msg)
      setValidation(msg_validations[msg] ||
        {username : false,
         password : true}
      )
    }
  }

  return (
    <PageAnonym>
      <Row className="justify-content-center">
        <Col md="6">
            <CardGroup>
              <Card className="p-4"> 
                <CardHeader style={{textAlign: "center"}}>
                  <h1>Login</h1>
                </CardHeader>                
                <CardBody>

                <FForm  onCancel     = {undefined}
                        onSave       = {() => handleLogin()}
                        labels       = {["","LogIn"]}>
                          <>
                            <FInputText name        = "username"
                                        label       = "Usuario"
                                        icon        = {<Icon icon={"council"}/>}
                                        value       = {credentials.username}
                                        //required    = {true}
                                        showValidity= {1}
                                        feedback    = {'Inserta tu nombre de usuario'}
                                        onChange    = {(v) => handleChange('username', v)}
                                        checkValue  = {(_) => validation.username}
                            />
                            <FInputPassword name    = "password"
                                        label       = "Contraseña"
                                        value       = {credentials.password}
                                        //required    = {true}
                                        showValidity= {1}
                                        feedback    = {'Inserta tu contraseña'}
                                        onChange    = {(v) => handleChange('password', v)}
                                        checkValue  = {(_) => validation.password}
                            />
                            {message!=undefined
                             ? <Alert color="danger">{message}</Alert>
                             : null
                            }
                            <Link to="/page/forgot" className="pull-left">
                              Has olvidado tu contraseña?
                            </Link>
                          </>
                </FForm>

                </CardBody>
              </Card>
            </CardGroup>

        </Col>
      </Row>

    </PageAnonym>
  )
}

export default withContext(Login)


