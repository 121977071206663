import React, { useState, useEffect, useRef } from 'react'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  //Title,
  Tooltip,
  Legend,
} from 'chart.js';

import {Line, getElementsAtEvent} from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  //Title,
  Tooltip,
  Legend,
  ChartDataLabels
);


const makeChartData = (data, labels) => {
  return  {
    labels: labels,
    datasets: [
      {
        label: '',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: data,
        radius: 3,
        pointRadius: 7,
        hoverRadius: 9,
        hitRadius: 9
      }
    ]
  }  
}


const makeChartOptions = (min, max) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
         easing:  'easeOutCirc',
         duration: 500
   },
   scales: {
       y: {
        'min': min || 2,
        'max': max || 6
       }
   },
   plugins: {
      legend: {
        display: false
      },     
      datalabels: {
          display: true,
          'align': 'top',
          color: 'rgba(255,99,132,1)'
      }
   },
    hover: {
       onHover: function(e) {
          const point = this.getElementAtEvent(e)
          if (point.length) e.target.style.cursor = 'pointer'
          else e.target.style.cursor = 'default'
       }
    }
 }
}

const ChartLine = ({data, labels, min, max, onClick}) => {

  const [cdata, setCData]= useState({labels: [], datasets: []})
  const [options, setOptions]= useState({})
  const chartRef = useRef()

  useEffect(() => {
    setCData(makeChartData(data, labels))
    setOptions(makeChartOptions(min, max))
  }, [data, labels, min, max])


  const handleClick = (event) => {
    const { current: chart } = chartRef

    if (!chart) {
      return;
    }

    const elems= getElementsAtEvent(chart, event)

    let idx= -1
    try{
      const elem= elems[0]
      idx= elem['index']
    } catch(e) {}

    onClick(idx)
  }

  return (
    <div className="chart-wrapper">
      <Line ref    = {chartRef}
            datasetIdKey='id'
            data   = {cdata}
            options= {options}                
            onClick={handleClick}
      />
    </div>
  )
}


export default ChartLine

