import React, {useState, useEffect} from 'react'
import {FForm, FInputEpoch} from 'formiga-reactstrap'
import {SetAgree} from './parts/actions'


const EventAgreeWithDateForm = ({subStep, onAgreedDate}) => {
  const [agreedDate, setAgreedDate]= useState(subStep.agreed_date)

  useEffect(() => {
    setAgreedDate(subStep.agreed_date)
  }, [subStep])

  return (
    <FForm  inline
            renderButtons={(valid) => 
              <SetAgree onClick={() => onAgreedDate(agreedDate)} disabled={!valid}/>
            }>
              <FInputEpoch   name       = "minutes_agreed_date"
                            value      = {agreedDate}
                            onChange   = {(v) => setAgreedDate(v)}
                            icon       = {"Fecha de conformidad"}
                            required   = {true}
                            feedback   = {' '}
                            locale     = 'es'/>
    </FForm>
  )
}

export default EventAgreeWithDateForm
