import React from 'react'
import { Row, Col } from 'reactstrap'
import Page from 'cli/ui/pages/layout/Page'
import {MenuCard} from 'cli/ui/components/cards'
import {IfPermission} from 'cli/context/IfPermission'

const _BREADS = [['/', 'Inicio'], ['Mis cosas']]

const User = () => {

  return (
    <Page title="Mis cosas"
          breads={_BREADS}>
      <Row>
        <Col xs="12" sm="6" md="4">
          <MenuCard color="success"
            icon="user"
            link="/user/settings"
            title="Mi Cuenta">
          </MenuCard>
        </Col>
        <IfPermission code="user_email_read">
          <Col xs="12" sm="6" md="4">
            <MenuCard color="info"
              icon="email"
              link="/user/emails"
              title="Correo Electrónico">
            </MenuCard>
          </Col>
        </IfPermission>
      </Row>

    </Page>
  )
}


export default User



