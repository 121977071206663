
import React, {useEffect, useState}  from 'react'

import {intre_get_month, intre_get_year, intre_noon} from 'intre'
import { collSort, collMaxBy } from 'farrapa/collections'

// Components
import Page from 'cli/ui/pages/layout/Page'
import { Row, Col } from 'reactstrap';
import { Card, CardHeader, CardBody } from 'cli/ui/components/cards'
import { ActionBack } from 'cli/ui/components/buttons/actions'
import { LoadingBig } from 'cli/ui/components/loading'
import IRatesUpdate from './IRatesUpdate'
import IRatesChart from './IRatesChart'
import withContext from 'cli/context/withContext';

const lastMissingYear = (iRates) => {
  if (iRates!=undefined && iRates.length > 0) {
    let myears = []
    const current_month = intre_get_month(intre_noon()) + 1
    const current_year = intre_get_year(intre_noon())
    const until_year = current_month >= 11 ? current_year + 1 : current_year
    const max_existing = collMaxBy(iRates, 'year')['year'];
    for (let y = max_existing + 1; y <= until_year; y++) {
      myears.push(y)
    }
    return myears[0]
  }
  return {}
}



const _BREADS = [['/', 'Inicio'], ['/settings', 'Configuración'], ['Tipos de Interés']]

const IRates = ({fetcher}) => {

  const [iRates, setIRates]= useState([])
  const [editingIdx, setEditingIdx]= useState(undefined)
  const [editingOrig, setEditingOrig]= useState(undefined)
  const [status, setStatus]= useState('pending')
  const [newIRate, setNewIRate]= useState(undefined)

  useEffect(() => {
    async function fetchData() {
      let nIRates= await fetcher.read('/api/irate')
      nIRates= collSort(nIRates, 'year')
      setIRates(nIRates)

      const lmy= lastMissingYear(nIRates)
      if (lmy) {
        setNewIRate({
          year : lmy,
          value: 0.0
        })
      }
      setStatus('done')
    }
    fetchData()
  },[fetcher])


  const selectIrate= (idx) => {
    setEditingIdx(idx)
    if (idx!=undefined) {
      if (iRates[idx]!=undefined) {
        setEditingOrig(iRates[idx].value)
      }
    }
  }

  const editingIRate = () => {
    try {
      return iRates[editingIdx]
    } catch(e) {
      return undefined
    }    
  }

  const handleChange = (value) => {
    let nIRates= [...iRates]
    nIRates[editingIdx].value = value
    setIRates(collSort(nIRates, 'year'))    
  }

  const handleUpdate = async () => {
    if (editingIRate()) {
      await fetcher.upsave('/api/irate', editingIRate())
      const nIRates = await fetcher.read('/api/irate')
      setIRates(collSort(nIRates, 'year'))
      selectIrate()
    }
  }

  const handleCancel = () => {
    if (editingIdx!=undefined) {
      let nIRates= [...iRates]
      nIRates[editingIdx].value = editingOrig
      setIRates(collSort(nIRates, 'year'))
      selectIrate()
    }
  }

  const handleNewIrateChange = (v) => {
    const nNewIRate= {...newIRate}
    nNewIRate.value= v
    setNewIRate(nNewIRate)
  }

  const handleNewIrateSave = async () => {
    await fetcher.upsave('/api/irate', newIRate)
    const nIRates = await fetcher.read('/api/irate')
    setIRates(collSort(nIRates, 'year'))
    selectIrate()
    setNewIRate(undefined)
  }  

  return (
    <Page breads={_BREADS}
          title="Evolución de los Tipos de Interés"
          actions={ <ActionBack to={"/settings"} label={"A Configuración"} />}>
        <Row>
          <Col xs="12" sm="12" md="8">

                { status=='pending' 
                ? <LoadingBig/>
                : <IRatesChart iRates= {iRates}
                               onSelectIRate= {(idx) => selectIrate(idx)}/>
                }
          </Col>
          <Col xs="12" sm="12" md="4">

            { (editingIRate()!=undefined) &&

              <Card>
                <CardHeader>
                  {`Modifica el valor para ${editingIRate().year}`}
                </CardHeader>
                <CardBody>
                  <IRatesUpdate year    = {editingIRate().year}
                                value   = {editingIRate().value}
                                onChange= {(v) => handleChange(v)}
                                onCancel= {() => handleCancel()}
                                onSave  = {() => handleUpdate()}/>
                </CardBody>
              </Card>
            }

            { newIRate==undefined
            ? null
            : <Card>
                <CardHeader>
                  {`¿Sabemos ya el tipo de interés para el año ${newIRate.year}?`}
                </CardHeader>
                <CardBody>                    
                  <IRatesUpdate year     = {newIRate.year}
                                value    = {newIRate.value}
                                onChange = {(v) => handleNewIrateChange(v)}
                                onCancel = {undefined}
                                onSave   = {() => handleNewIrateSave()}/>
                </CardBody>
              </Card>
            }
          </Col>
        </Row>
      </Page>
  )
}



export default withContext( IRates)

