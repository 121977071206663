import React from 'react'
import { FForm, FInputText, FInputSelect, FInputCheckbox, FInputFile, FInputSelectSearch, FInputSelectMultiple} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'
import {I5, TAX_NAMES} from 'bl/ns/taxes'
import {PTYPE_NAMES, UNATT_REQUIREMENT} from 'bl/ns/process/process'
import {PENALTY_TYPE_NAMES, PENALTY_INFRACTION_LEVEL_NAMES, PENALTY_AGREED} from 'bl/ns/process/penalty'
import {uvl} from 'farrapa/commons'
import withContext from 'cli/context/withContext'

const addEmptyOption = (obj, v) => 
  Object.assign({}, obj, {[v]: undefined})

const boolToInt = (b) =>
  b===undefined
  ? -1
  : b===true 
    ? 1
    : 0

const intToBool = (i) => 
  (i===undefined || isNaN(i) || i==-1)
  ? undefined
  : Boolean(i)

const DocDetailsForm = (
  { doc, existingNames, processDocOptions, docOptions, councilOptions, 
    sectorOptions, onChange, onSave, onCancel, onDownload, permiss}) => {
  const handleChangeConditions = (n,v) => {
    const nConditions= {...doc.conditions || {}}
    if (v==='' || v===undefined) {
      delete nConditions[n]
    } else {
      nConditions[n]= v
    }
    
    onChange('conditions', nConditions)
  }

  return (
    <FForm onCancel = {onCancel}
           onSave   = {permiss.can_user_edit_docs() ? onSave : undefined}
           labels   = {['Volver', 'Guardar']}
           >
        
        <>

            <FInputSelect   options   = {processDocOptions}
                        name      = 'process_doc'
                        label     = 'Tipo de documento'
                        icon      = {<Icon icon="puzzle"/>}
                        value     = {doc.process_doc}
                        placeholder= {"Tipo de documento (según Tasa)"}
                        clearable = {true}
                        required  = {true}
                        onChange   = {(v) => onChange('process_doc', v)}
                        readOnly = {!permiss.can_user_edit_docs()}
            />

          <FInputText name       = "name"
                      label      = "Nombre"
                      value      = {doc.name}
                      required   = {true}
                      disallowedValues = {existingNames}
                      feedback   = {'Inserta un nombre válido y único'}
                      onChange   = {(v) => onChange('name', v)}
                      readOnly = {!permiss.can_user_edit_docs()}
          />              

          <FInputSelect options  = {docOptions}
                      name       = 'doc_id'
                      label      = 'Modelo padre'
                      icon       = {<Icon icon="puzzle"/>}
                      value      = {doc.doc_id}
                      placeholder= {"Modelo padre"}
                      clearable  = {true}
                      required   = {false}
                      onChange   = {(v) => onChange('doc_id', v)}
                      readOnly = {!permiss.can_user_edit_docs()}
          />                     
          
          <FInputSelect options   = {addEmptyOption({...TAX_NAMES}, '')}
                      id = 'tax_code'
                      name      = 'tax_code'
                      label     = 'Tributo'
                      icon      = {<Icon icon="puzzle"/>}
                      value     = {doc.conditions!=undefined ? uvl(doc.conditions?.tax_code,'') : ''}
                      placeholder= {"Tributo"}
                      clearable = {true}
                      searchable= {false}
                      required  = {false}
                      onChange   = {(v) => handleChangeConditions('tax_code', v)}
                      readOnly = {!permiss.can_user_edit_docs()}
          />
          
          <FInputSelect  options   = {addEmptyOption(PTYPE_NAMES[doc.conditions?.tax_code || I5], undefined)}
                      name      = 'process_type'
                      label     = 'Tipo de expediente'
                      icon      = {<Icon icon="puzzle"/>}
                      value     = {doc.conditions ? doc.conditions?.process_type : undefined}
                      placeholder= {"Tipo expediente"}
                      clearable = {true}
                      required  = {false}
                      onChange   = {(v) => handleChangeConditions('process_type', v)}
                      readOnly = {!permiss.can_user_edit_docs()}
          />                  

          <FInputSelectMultiple  options   = {addEmptyOption(PENALTY_TYPE_NAMES, undefined)}
                      name      = 'penalty_type'
                      label     = 'Tipo de sanción'
                      icon      = {<Icon icon="puzzle"/>}
                      value     = {doc.conditions ? doc.conditions?.penalty_type : []}
                      placeholder= {"Tipo sanción"}
                      clearable = {true}
                      required  = {false}
                      onChange   = {(v) => handleChangeConditions('penalty_type', v)}
                      readOnly = {!permiss.can_user_edit_docs()}
          />

          <FInputSelect  options   = {addEmptyOption(PENALTY_INFRACTION_LEVEL_NAMES, undefined)}
                      name      = 'penalty_infraction'
                      label     = 'Nivel infracción'
                      icon      = {<Icon icon="puzzle"/>}
                      value     = {doc.conditions ? doc.conditions?.penalty_infraction : undefined}
                      placeholder= {"Nivel infracción"}
                      clearable = {true}
                      required  = {false}
                      onChange   = {(v) => handleChangeConditions('penalty_infraction', v)}
                      readOnly = {!permiss.can_user_edit_docs()}
          />
          
          <FInputSelect  options   = {addEmptyOption(UNATT_REQUIREMENT, undefined)}
                      name      = 'unatt_requirement'
                      label     = 'Requerimiento no atendido'
                      icon      = {<Icon icon="puzzle"/>}
                      value     = {doc.conditions ? doc.conditions?.unatt_requirement : undefined}
                      placeholder= {"Requerimiento atendido"}
                      clearable = {true}
                      required  = {false}
                      onChange   = {(v) => handleChangeConditions('unatt_requirement', v)}
                      readOnly = {!permiss.can_user_edit_docs()}
          />

          <FInputSelect  options   = {addEmptyOption(PENALTY_AGREED, -1)}
                      name      = 'penalty_agreed'
                      label     = 'Conformidad'
                      icon      = {<Icon icon="puzzle"/>}
                      value     = { (doc.conditions!=undefined && doc.conditions?.penalty_agreed!=undefined)
                                     ? boolToInt(doc.conditions?.penalty_agreed)
                                     : -1}
                      placeholder= {"Conformidad"}
                      clearable = {true}
                      required  = {false}
                      onChange   = {(v) => handleChangeConditions('penalty_agreed', intToBool(v))}
                      readOnly = {!permiss.can_user_edit_docs()}
          />
          <FInputSelectSearch options  = {councilOptions}
                      name       = 'council_id'
                      label      = 'Ayuntamiento'
                      icon       = {<Icon icon="puzzle"/>}
                      value      = {doc.conditions ? doc.conditions?.council_id : undefined}
                      placeholder= {"Ayuntamiento"}
                      clearable  = {true}
                      required   = {false}
                      onChange   = {(v) => handleChangeConditions('council_id', v)}
                      readOnly = {!permiss.can_user_edit_docs()}
          />  
          <FInputSelectSearch options  = {sectorOptions}
                      name       = 'sector_id'
                      label      = 'Sector'
                      icon       = {<Icon icon="puzzle"/>}
                      value      = {doc.conditions ? doc.conditions?.sector_id : undefined}
                      placeholder= {"Sector"}
                      clearable  = {true}
                      required   = {false}
                      onChange   = {(v) => handleChangeConditions('sector_id', v)}
                      readOnly = {!permiss.can_user_edit_docs()}
          />  

          <FInputFile   name       = "doc"
                        label      = "Fichero"
                        value      = {doc.doc}
                        onChange   = {(v) => onChange('doc', v)}
                        onDownload = {() => onDownload()}
                        accept     = ".docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        icon       = {<Icon icon="file-word"/>}
                        readOnly = {!permiss.can_user_edit_docs()}
                        />
          
          <FInputCheckbox name   = "hidden"
                      label      = "Oculto"
                      value      = {doc.hidden}
                      onChange   = {(v) => onChange('hidden', v)}
                      readOnly = {!permiss.can_user_edit_docs()}
          />       
          <FInputText name       = "notes"
                      label      = "Notas"
                      value      = {doc.notes}
                      required   = {false}
                      feedback   = {'Observaciones'}
                      onChange   = {(v) => onChange('notes', v)}
                      readOnly = {!permiss.can_user_edit_docs()}
          />
          <FInputCheckbox name   = "letterhead"
                      label      = "Con Membrete"
                      value      = {doc.letterhead}
                      onChange   = {(v) => onChange('letterhead', v)}
                      readOnly = {!permiss.can_user_edit_docs()}
          />          
        </>
    </FForm>
  )
}


export default withContext(DocDetailsForm)




