import React, {useState, useEffect, useCallback} from 'react'


// Components
import Page            from 'cli/ui/pages/layout/Page'
import {Row, Col}      from 'reactstrap'
import {LoadingBig}    from 'cli/ui/components/loading'
import { IBox, IBoxRow, IBoxIconedText } from 'cli/ui/components/ibox'
import ProcessesFilter from 'cli/ui/fragments/process/filter/ProcessesFilter'
import StatsList      from './StatsList'
import { NiceTotals } from 'cli/ui/components/nice_totals'
import { COLOR_GROUPS } from 'bl/ns/process/colors'
import withContext from 'cli/context/withContext'


const Stats = ({fetcher}) => {
  
  const [processes, setProcesses]= useState([])
  const [filterMode, setFilterMode]= useState('filter')
  const [dataLoaded, setDataLoaded]= useState(true)
  const [dataEmpty, setDataEmpty]= useState(false)
    const [totals, setTotals]= useState(undefined)
  const [stats, setStats]= useState({
    count: 0, 
    totalFees: 0.0, 
    totalInterests: 0.0,
    total20: 0.0,
    total19: 0.0
  })

  const fetchStats = useCallback((filter) => {
    const _fetchStats = async () => {      
      setDataLoaded(false)
      setDataEmpty(false)
      
      // Fetch them
      const res = await fetcher.get('/api/process/read_stats', filter)
      const nProcesses = res.data
      setProcesses(nProcesses)
      setDataLoaded(true)
      if (nProcesses.length==0) {
        setDataEmpty(true)
      }
    }

    _fetchStats()

  }, [fetcher])


  const handleSearchStats = useCallback((filter) => {
    setFilterMode('resume')
    fetchStats(filter)
  }, [fetchStats])  
  
  useEffect(() => {
    const nStats= {
      count: 0, 
      totalFees: 0.0, 
      totalInterests: 0.0,
      total20: 0.0,
      total19: 0.0      
    }
    processes.map((p) => {
      nStats.count+= 1
      nStats.totalFees+= p.amounts.fees
      nStats.totalInterests+= p.amounts.interests
      nStats.total20+= p.amounts.penalty_20
      nStats.total19+= p.amounts.penalty_19
    })
    setStats(nStats)
  }, [processes])

  useEffect(() => {
    setTotals([
      {price: false, label: 'Expedientes'  , value: stats.count,          color: 'blue'   },
      {price: true , label: 'Cuotas'       , value: stats.totalFees,      color: COLOR_GROUPS['inspect']    },
      {price: true , label: 'Intereses'    , value: stats.totalInterests, color: COLOR_GROUPS['inspect']    },
      {price: true , label: 'Sanción 203'  , value: stats.total20,        color: COLOR_GROUPS['penalty_20'] },
      {price: true , label: 'Sanción 191/2', value: stats.total19,        color: COLOR_GROUPS['penalty_19'] },
    ])
  }, [stats])


  return (
    <Page breads = {[['/', 'Inicio'], ['Indicadores']]}
          title  = {`Indicadores`}
          actions= {null}>
      <Row>
        <Col xs="12">
            <ProcessesFilter filterMode  = {filterMode}
                             onToggleMode= {(mode) => setFilterMode(mode)}
                             onFilter    = {(filter) => handleSearchStats(filter)}/>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
             {!dataLoaded
              ? <LoadingBig/>  

              : processes.length>0
                ? <>
                    {totals!=undefined
                     ? <NiceTotals totals={totals}/>
                     : null
                    }
                    <StatsList 
                      processes = {processes}/>
                  </>
                : dataEmpty
                  ? <IBox>
                      <IBoxRow center>
                        <IBoxIconedText icon="empty" text="No hay expedientes con los parámetros buscados"/>
                      </IBoxRow>
                    </IBox>
                  : null
            }
          
        </Col>
      </Row>

    </Page>
  )
}



export default withContext(Stats)
