import React from 'react'

const LuLicenseImportStep5 = ({ ordinance}) =>
    <a href={`/councils/${ordinance.council_id}/lu/${ordinance.id}`}>
      {`Ir a la lista de licencias de ${ordinance.name}`}
    </a>


export default LuLicenseImportStep5
        
        
        
        
