import React from 'react'
import ActionButton from './ActionButton'

const ActionDownload = ({ onClick, to, enabled, label }) =>
  <ActionButton color  = 'rgb(189, 156, 218)'
                label  = {label}
                icon   = 'download2'
                onClick= {(e) => { onClick(e) }}
                to     = {to}
                enabled= {enabled!=undefined ? enabled : true}>
  </ActionButton>

export default ActionDownload

