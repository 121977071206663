
import {toPrice, sort} from '../../converters'

function makeI5TaxesDetailRow(detail) {
  return `
    <w:tr w:rsidR="00FD29B1" w:rsidTr="00167968">
      <w:trPr>
        <w:trHeight w:val="240"/>
      </w:trPr>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="802" w:type="dxa"/>
          <w:shd w:val="clear" w:color="DBDBDB" w:fill="DBDBDB"/>
          <w:vAlign w:val="bottom"/>
        </w:tcPr>
        <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
          <w:pPr>
            <w:jc w:val="center"/>
          </w:pPr>
          <w:r>
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
              <w:b/>
              <w:bCs/>
              <w:sz w:val="15"/>
              <w:szCs w:val="15"/>
            </w:rPr>
            <w:t>${ detail.year }</w:t>
          </w:r>
        </w:p>
      </w:tc>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="870" w:type="dxa"/>
          <w:shd w:val="clear" w:color="DBDBDB" w:fill="DBDBDB"/>
          <w:vAlign w:val="bottom"/>
        </w:tcPr>
        <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
          <w:pPr>
            <w:jc w:val="right"/>
          </w:pPr>
          <w:r>
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
              <w:sz w:val="15"/>
              <w:szCs w:val="15"/>
            </w:rPr>
            <w:t>${ toPrice(detail.earnings) }</w:t>
          </w:r>
        </w:p>
      </w:tc>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="874" w:type="dxa"/>
          <w:shd w:val="clear" w:color="DBDBDB" w:fill="DBDBDB"/>
          <w:vAlign w:val="bottom"/>
        </w:tcPr>
        <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
          <w:pPr>
            <w:jc w:val="right"/>
          </w:pPr>
          <w:r>
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
              <w:sz w:val="15"/>
              <w:szCs w:val="15"/>
            </w:rPr>
            <w:t>${ toPrice(detail.expenses) }</w:t>
          </w:r>
        </w:p>
      </w:tc>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="1142" w:type="dxa"/>
          <w:shd w:val="clear" w:color="DBDBDB" w:fill="DBDBDB"/>
          <w:vAlign w:val="bottom"/>
        </w:tcPr>
        <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
          <w:pPr>
            <w:jc w:val="right"/>
          </w:pPr>
          <w:r>
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
              <w:sz w:val="15"/>
              <w:szCs w:val="15"/>
            </w:rPr>
            <w:t>${ toPrice(detail.checked_taxbase) }</w:t>
          </w:r>
        </w:p>
      </w:tc>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="984" w:type="dxa"/>
          <w:shd w:val="clear" w:color="DBDBDB" w:fill="DBDBDB"/>
          <w:vAlign w:val="bottom"/>
        </w:tcPr>
        <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
          <w:pPr>
            <w:jc w:val="right"/>
          </w:pPr>
          <w:r>
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
              <w:sz w:val="15"/>
              <w:szCs w:val="15"/>
            </w:rPr>
            <w:t>${ toPrice(detail.declared_taxbase) }</w:t>
          </w:r>
        </w:p>
      </w:tc>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="933" w:type="dxa"/>
          <w:shd w:val="clear" w:color="DBDBDB" w:fill="DBDBDB"/>
          <w:vAlign w:val="bottom"/>
        </w:tcPr>
        <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
          <w:pPr>
            <w:jc w:val="right"/>
          </w:pPr>
          <w:r>
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
              <w:sz w:val="15"/>
              <w:szCs w:val="15"/>
            </w:rPr>
            <w:t>${ toPrice(detail.difference) }</w:t>
          </w:r>
        </w:p>
      </w:tc>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="725" w:type="dxa"/>
          <w:shd w:val="clear" w:color="DBDBDB" w:fill="DBDBDB"/>
          <w:vAlign w:val="bottom"/>
        </w:tcPr>
        <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
          <w:pPr>
            <w:jc w:val="center"/>
          </w:pPr>
          <w:r>
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
              <w:sz w:val="15"/>
              <w:szCs w:val="15"/>
            </w:rPr>
            <w:t>1,50%</w:t>
          </w:r>
        </w:p>
      </w:tc>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="751" w:type="dxa"/>
          <w:shd w:val="clear" w:color="DBDBDB" w:fill="DBDBDB"/>
          <w:vAlign w:val="bottom"/>
        </w:tcPr>
        <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
          <w:pPr>
            <w:jc w:val="right"/>
          </w:pPr>
          <w:r>
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
              <w:sz w:val="15"/>
              <w:szCs w:val="15"/>
            </w:rPr>
            <w:t>${ toPrice(detail.fee) }</w:t>
          </w:r>
        </w:p>
      </w:tc>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="992" w:type="dxa"/>
          <w:shd w:val="clear" w:color="DBDBDB" w:fill="DBDBDB"/>
          <w:vAlign w:val="bottom"/>
        </w:tcPr>
        <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
          <w:pPr>
            <w:jc w:val="right"/>
          </w:pPr>
          <w:r>
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
              <w:sz w:val="15"/>
              <w:szCs w:val="15"/>
            </w:rPr>
            <w:t>${ toPrice(detail.interest) }</w:t>
          </w:r>
        </w:p>
      </w:tc>
      <w:tc>
        <w:tcPr>
          <w:tcW w:w="852" w:type="dxa"/>
          <w:shd w:val="clear" w:color="DBDBDB" w:fill="DBDBDB"/>
          <w:vAlign w:val="bottom"/>
        </w:tcPr>
        <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
          <w:pPr>
            <w:jc w:val="right"/>
          </w:pPr>
          <w:r>
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
              <w:sz w:val="15"/>
              <w:szCs w:val="15"/>
            </w:rPr>
            <w:t>${ toPrice(detail.total) }</w:t>
          </w:r>
        </w:p>
      </w:tc>
    </w:tr>`
}

function makeI5TaxesTable(tax) {

  const detailLines= sort(tax.details, 'year')
                     .map((detail) => makeI5TaxesDetailRow(detail))
                     .join('\n')
  
  return `<w:tbl>
  <w:tblPr>
    <w:tblW w:w="8926" w:type="dxa"/>
    <w:tblBorders>
      <w:top w:val="single" w:sz="4" w:space="0" w:color="auto"/>
      <w:left w:val="single" w:sz="4" w:space="0" w:color="auto"/>
      <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto"/>
      <w:right w:val="single" w:sz="4" w:space="0" w:color="auto"/>
      <w:insideH w:val="single" w:sz="4" w:space="0" w:color="auto"/>
      <w:insideV w:val="single" w:sz="4" w:space="0" w:color="auto"/>
    </w:tblBorders>
    <w:tblCellMar>
      <w:left w:w="65" w:type="dxa"/>
      <w:right w:w="70" w:type="dxa"/>
    </w:tblCellMar>
    <w:tblLook w:val="04A0" w:firstRow="1" w:lastRow="0" w:firstColumn="1" w:lastColumn="0" w:noHBand="0" w:noVBand="1"/>
  </w:tblPr>
  <w:tblGrid>
    <w:gridCol w:w="803"/>
    <w:gridCol w:w="870"/>
    <w:gridCol w:w="874"/>
    <w:gridCol w:w="1142"/>
    <w:gridCol w:w="984"/>
    <w:gridCol w:w="933"/>
    <w:gridCol w:w="725"/>
    <w:gridCol w:w="751"/>
    <w:gridCol w:w="992"/>
    <w:gridCol w:w="852"/>
  </w:tblGrid>
  <w:tr w:rsidR="00FD29B1" w:rsidTr="00167968">
    <w:trPr>
      <w:trHeight w:val="240"/>
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="802" w:type="dxa"/>
        <w:shd w:val="clear" w:color="A5A5A5" w:fill="A5A5A5"/>
        <w:vAlign w:val="bottom"/>
      </w:tcPr>
      <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
        <w:pPr>
          <w:jc w:val="center"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:b/>
            <w:bCs/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t>AÑO</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="870" w:type="dxa"/>
        <w:shd w:val="clear" w:color="A5A5A5" w:fill="A5A5A5"/>
        <w:vAlign w:val="bottom"/>
      </w:tcPr>
      <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
        <w:pPr>
          <w:jc w:val="center"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:b/>
            <w:bCs/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t>${tax.earnings_label.toUpperCase()}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="874" w:type="dxa"/>
        <w:shd w:val="clear" w:color="A5A5A5" w:fill="A5A5A5"/>
        <w:vAlign w:val="bottom"/>
      </w:tcPr>
      <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
        <w:pPr>
          <w:jc w:val="center"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:b/>
            <w:bCs/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t>${tax.expenses_label.toUpperCase()}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1142" w:type="dxa"/>
        <w:shd w:val="clear" w:color="A5A5A5" w:fill="A5A5A5"/>
        <w:vAlign w:val="bottom"/>
      </w:tcPr>
      <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
        <w:pPr>
          <w:jc w:val="center"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:b/>
            <w:bCs/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t>BI COMPROBADA</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="984" w:type="dxa"/>
        <w:shd w:val="clear" w:color="A5A5A5" w:fill="A5A5A5"/>
        <w:vAlign w:val="bottom"/>
      </w:tcPr>
      <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
        <w:pPr>
          <w:jc w:val="center"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:b/>
            <w:bCs/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t>BI DECLARADA</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="933" w:type="dxa"/>
        <w:shd w:val="clear" w:color="A5A5A5" w:fill="A5A5A5"/>
        <w:vAlign w:val="bottom"/>
      </w:tcPr>
      <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
        <w:pPr>
          <w:jc w:val="center"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:b/>
            <w:bCs/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t>DIFERENCIA</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="725" w:type="dxa"/>
        <w:shd w:val="clear" w:color="A5A5A5" w:fill="A5A5A5"/>
        <w:vAlign w:val="bottom"/>
      </w:tcPr>
      <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
        <w:pPr>
          <w:jc w:val="center"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:b/>
            <w:bCs/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t>TI</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="751" w:type="dxa"/>
        <w:shd w:val="clear" w:color="A5A5A5" w:fill="A5A5A5"/>
        <w:vAlign w:val="bottom"/>
      </w:tcPr>
      <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
        <w:pPr>
          <w:jc w:val="center"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:b/>
            <w:bCs/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t>CUOTA</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="992" w:type="dxa"/>
        <w:shd w:val="clear" w:color="A5A5A5" w:fill="A5A5A5"/>
        <w:vAlign w:val="bottom"/>
      </w:tcPr>
      <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
        <w:pPr>
          <w:jc w:val="center"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:b/>
            <w:bCs/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t>INT DEMORA</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="852" w:type="dxa"/>
        <w:shd w:val="clear" w:color="A5A5A5" w:fill="A5A5A5"/>
        <w:vAlign w:val="bottom"/>
      </w:tcPr>
      <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
        <w:pPr>
          <w:jc w:val="center"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:b/>
            <w:bCs/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t>DEUDA TOTAL</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>
  ${detailLines}
  <w:tr w:rsidR="00FD29B1" w:rsidTr="00167968">
    <w:trPr>
      <w:trHeight w:val="240"/>
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="802" w:type="dxa"/>
        <w:shd w:val="clear" w:color="DBDBDB" w:fill="DBDBDB"/>
        <w:vAlign w:val="bottom"/>
      </w:tcPr>
      <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:color w:val="000000"/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t></w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="870" w:type="dxa"/>
        <w:shd w:val="clear" w:color="DBDBDB" w:fill="DBDBDB"/>
        <w:vAlign w:val="bottom"/>
      </w:tcPr>
      <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:color w:val="000000"/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t></w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="874" w:type="dxa"/>
        <w:shd w:val="clear" w:color="DBDBDB" w:fill="DBDBDB"/>
        <w:vAlign w:val="bottom"/>
      </w:tcPr>
      <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:color w:val="000000"/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t></w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="1142" w:type="dxa"/>
        <w:shd w:val="clear" w:color="DBDBDB" w:fill="DBDBDB"/>
        <w:vAlign w:val="bottom"/>
      </w:tcPr>
      <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:color w:val="000000"/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t></w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="984" w:type="dxa"/>
        <w:shd w:val="clear" w:color="DBDBDB" w:fill="DBDBDB"/>
        <w:vAlign w:val="bottom"/>
      </w:tcPr>
      <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:color w:val="000000"/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t></w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="933" w:type="dxa"/>
        <w:shd w:val="clear" w:color="DBDBDB" w:fill="DBDBDB"/>
        <w:vAlign w:val="bottom"/>
      </w:tcPr>
      <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:color w:val="000000"/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t></w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="725" w:type="dxa"/>
        <w:shd w:val="clear" w:color="DBDBDB" w:fill="DBDBDB"/>
        <w:vAlign w:val="bottom"/>
      </w:tcPr>
      <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
        <w:pPr>
          <w:jc w:val="right"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
            <w:b/>
            <w:bCs/>
            <w:color w:val="000000"/>
            <w:sz w:val="15"/>
            <w:szCs w:val="15"/>
          </w:rPr>
          <w:t>TOTAL</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="751" w:type="dxa"/>
        <w:shd w:val="clear" w:color="DBDBDB" w:fill="DBDBDB"/>
        <w:vAlign w:val="bottom"/>
      </w:tcPr>
        <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
          <w:pPr>
            <w:jc w:val="right"/>
          </w:pPr>
          <w:r>
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
              <w:b/>
              <w:color w:val="000000"/>
              <w:sz w:val="15"/>
              <w:szCs w:val="15"/>
            </w:rPr>
            <w:t>${ toPrice(tax.fees) }</w:t>
          </w:r>
        </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="992" w:type="dxa"/>
        <w:shd w:val="clear" w:color="DBDBDB" w:fill="DBDBDB"/>
        <w:vAlign w:val="bottom"/>
      </w:tcPr>
        <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
          <w:pPr>
            <w:jc w:val="right"/>
          </w:pPr>
          <w:r>
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
              <w:b/>
              <w:color w:val="000000"/>
              <w:sz w:val="15"/>
              <w:szCs w:val="15"/>
            </w:rPr>
            <w:t>${ toPrice(tax.interests) }</w:t>
          </w:r>
        </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="852" w:type="dxa"/>
        <w:shd w:val="clear" w:color="DBDBDB" w:fill="DBDBDB"/>
        <w:vAlign w:val="bottom"/>
      </w:tcPr>
        <w:p w:rsidR="00FD29B1" w:rsidRDefault="00FD29B1" w:rsidP="00167968">
          <w:pPr>
            <w:jc w:val="right"/>
          </w:pPr>
          <w:r>
            <w:rPr>
              <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial"/>
              <w:b/>
              <w:color w:val="000000"/>
              <w:sz w:val="15"/>
              <w:szCs w:val="15"/>
            </w:rPr>
            <w:t>${ toPrice(tax.total) }</w:t>
          </w:r>
        </w:p>
    </w:tc>
  </w:tr>
  
</w:tbl>`
}

export {makeI5TaxesTable}