import React from 'react'
import ProcessPenaltyInitForm from './ProcessPenaltyInitForm'

const ProcessPenaltyInit = ({process, onProcessChange, onCancel}) => {

  return (
    <div style={{border: "1px solid #c2cfd6", 
                 borderRadius: "0.5em",
                 padding: "1.5em",
                 margin: "1em 0",
                 default: undefined}}>
      {/* Title */}
      <div style={{margin: "1em"}}>
        <b style={{fontSize: "1.25em"}}>Iniciar sancionador</b>
      </div>

      {/* Form */}
      <ProcessPenaltyInitForm process = {process}
                                onProcessChange  = {onProcessChange}
                                onCancel= {onCancel}/>
    </div>
  )


}

export default ProcessPenaltyInit