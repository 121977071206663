


import React from 'react'
import Icon from 'cli/ui/components/icon'

const IBoxIconedText = ({icon, text}) => {
  if (text==undefined || text.length==0) {
    return null
  }
  return (
    <div className="ibox-iconed-text">
      <Icon icon={icon}></Icon>
      <div>{text}</div>
    </div>
  )
}

export default IBoxIconedText