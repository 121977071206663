import React from 'react'
import NiceList from 'cli/ui/components/nice_list'
import { HyperCouncil, HyperTaxPayer, HyperProcess, HyperTax } from 'cli/ui/components/hyper'
import { PTYPE_NAMES } from 'bl/ns/process/process'
import {TAX_NAMES } from 'bl/ns/taxes'
import getStatusName from 'bl/util/process/getStatusName'
import {asPriceWithCurrency} from 'farrapa/numbers'
import {intre_pretty_medium, intre_to_str} from 'intre'
import IncomeInvoice from './IncomeInvoice'
import IncomePaid from './IncomePaid'
import IncomeRemarks from './IncomeRemarks'

const processFields= [
  {
    label: "Tasa",
    value: (p) => TAX_NAMES[p.tax_code],
    render: (p) => <HyperTax code={p.tax_code}/>
  },
  {
    label: "Expediente",
    value: (p) => p.name,
    render: (process) =>
      <HyperProcess taxCode={process.tax_code}  id={process.id} name={process.name} editionId={process.edition_id}/>
  }, 
  {
    label: "Ayuntamiento",
    className: "main", 
    value: (process) => process.council_name,
    render: (process) =>
      <HyperCouncil id={process.council_id} name={process.council_name}/>
  },    
  {
    label: "Contribuyente",
    value: (process) => process.taxpayer_name,
    render: (process) =>
      <HyperTaxPayer id={process.taxpayer_id} name={process.taxpayer_name}/>
  }, 
  /*{
    label: "Tipo",
    value: (p) => PTYPE_NAMES[p.tax_code][p.process_type]  
  },*/
  {
    label: "Inspección",
    value: (p) => {
      return getStatusName(p, 'inspect', true /*short*/)
    }
  },  
  {
    label: "Cuota", 
    className: "right price", 
    value: (p) => p.amounts.fees,
    render: (p) => {
      return (
        <span style={{color: p.amount_colors.inspect}}>{asPriceWithCurrency(p.amounts.fees)}</span>
      )      
    }
  },
  {
    label: "Intereses", 
    className: "right price", 
    value: (p) => p.amounts.interests,
    render: (p) => {
      return (
        <span style={{color: p.amount_colors.inspect}}>{asPriceWithCurrency(p.amounts.interests)}</span>
      )      
    }    
  },
  {
    label: "Art. 203", 
    className: "right price", 
    value: (p) => p.amounts.penalty_20,
    render: (p) => {
      const i20= p.penalty_20_on===true ? asPriceWithCurrency(p.amounts.penalty_20) : ''
      return (
        <span style={{color: p.amount_colors.penalty_20}}>{i20}</span>
      )        
    }
  },
  {
    label: "Art. 191/192", 
    className: "right price", 
    value: (p) => p.amounts.penalty_19,
    render: (p) => {
      const i19= p.penalty_19_on===true ? asPriceWithCurrency(p.amounts.penalty_19) : ''
      return (
        <span style={{color: p.amount_colors.penalty_19}}>{i19}</span>
      )        
    }
  },
  {
    label: "Nº Acta", 
    className: "right", 
    value: (p) => p.inspect.serial_text
  },
  {
    label: "Fecha Firma", 
    className: "right", 
    value: (p) => intre_pretty_medium(p.appointment_date)
  },
  {
    label: "Factura", 
    className: "right", 
    render: (process, onEvent) => 
      <IncomeInvoice process= {process}
                         onChangeProcess = {(n,v) => 
                          onEvent('upsave_proc', {id: process.id, changes: {[n]: v}, confirm: true})}
      />,
    value: (p) => p.invoice_number
  },
  {
    label: "Pagado", 
    className: "right", 
    value: (p) => p.paid_amount || 0.0,
    render: (process, onEvent) => 
      <IncomePaid process= {process}
                  onChangeProcess = {(changes) => 
                    onEvent('upsave_proc', {id: process.id, changes: changes, confirm: true})}
      />
  },
  {
    label: "Observ.", 
    className: "right", 
    render: (process, onEvent) => 
      <IncomeRemarks process= {process}
                     onChangeProcess = {(n,v) => 
                      onEvent('upsave_proc', {id: process.id, changes: {[n]: v}, confirm: true})}
      />
  }
]

const exportFields= [
  {
    label: "Tasa",
    value: (p) => TAX_NAMES[p.tax_code]
  },  
  {
    name: 'name',
    label: "Expediente"
  }, 
  {
    name: 'council_name',
    label: "Ayuntamiento"
  },    
  {
    name: 'taxpayer_name',
    label: "Contribuyente",
    value: (process) => process.taxpayer_name
  }, 
  {
    name: 'process_type',
    label: "Tipo",
    value: (p) => PTYPE_NAMES[p.tax_code][p.process_type]  
  },
  {
    label: "Inspección",
    value: (p) => getStatusName(p, 'inspect', false /*short*/)
  },
  {
    label: "Cuota", 
    value: (p) => p.amounts.fees
  },
  {
    label: "Intereses", 
    value: (p) => p.amounts.interests
  },
  {
    label: "Art. 203", 
    value: (p) => p.amounts.penalty_20
  },
  {
    label: "Art. 191/192", 
    value: (p) => p.amounts.penalty_19
  },
  {
    label: "Nº Acta", 
    value: (p) => p.inspect.serial_text
  },
  {
    label: "Fecha Firma", 
    value: (p) => intre_to_str(p.appointment_date)
  },
  {
    name: 'invoice_number',
    label: "Factura", 
  },
  {
    label: "A Pagar", 
    value: (p) => p.to_be_paid
  },
  {
    label: "Pagado", 
    value: (p) => p.paid_amount || 0.0
  },
  {
    name: 'income_remarks',
    label: "Observaciones"
  }
]


const makeKey= (process) =>
  `income_${process.edition_id}_processX_${process.id}`


const IncomeList = ({processes, onProcessChange}) => {
   

  return (

    <NiceList fields     = {processFields}
              initialSort= {[1, "asc"]}
              exportable = {true}
              exportFields={exportFields}
              exportName = 'Recaudación'
              data       = {processes}
              makeKey    = {makeKey}
              onEvent    = {(ev, params) => {
                if (ev=='upsave_proc') {
                  onProcessChange(params.id, params.changes, params.confirm)
                }                                 
              }}
              transparent= {true}
    />

  )
}


export default IncomeList








