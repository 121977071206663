import React from 'react'

import {FForm, FInputTextArea} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'


const TaxPayerDetailsNotesForm = ({ taxPayer, onChange, onSave}) =>

  <FForm onCancel     = {undefined}
         onSave       = {onSave}>
          <>
            <FInputTextArea name    = "remarks"
                        label       = {undefined}
                        icon        = {<Icon icon={"text"}/>}
                        value       = {taxPayer.remarks}
                        onChange    = {(v) => onChange('remarks', v)}
            />                                            
          </>
  </FForm>

export default TaxPayerDetailsNotesForm




