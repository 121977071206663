import React from 'react'
import { LoadingBig } from 'cli/ui/components/loading'

const LuLicenseImportStep4 = () => 
  <LoadingBig/>

export default LuLicenseImportStep4
        
        
        
        
