
import React from 'react'
import Hyper from './base'

const HyperAgent = ({ id, name, color }) =>
  <Hyper bcolor  = {color || "#ecb3ff"}
         icon    = "agent"
         to      = {"/settings/agents/" + id}
         text    = {name}
         permissionCode = "setts_agent_edit">
  </Hyper>

export default HyperAgent
