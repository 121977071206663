import React from 'react'
import {FInputSelect, FInputTextArea} from 'formiga-reactstrap'
import {Row, Col} from 'reactstrap'
import TaxHeaderFields from '../common/TaxHeaderFields'
import TaxHeaderFieldsRow from '../common/TaxHeaderFieldsRow'
import TaxInputCheckbox from '../common/TaxInputCheckbox' 
import {uvl} from 'farrapa/commons'


const ProcessPenalty20 = ({process, onChangeProcess, canEdit}) => {
  const handleSave= (n, v, c) => {
    if (c) {
      const changes= {[n]: v}
      onChangeProcess(changes, c)
    }
  }

 return (
    <>
      <Row>
        <Col md="12" lg= "5">
          <TaxHeaderFields title="Infracción">
            <TaxHeaderFieldsRow>
              <div style={{display: "inline-block", color: "gray", fontSize: "1.2em", verticalAlign: "middle", marginRight: "2em"}}>
                {`Requerimiento no atendido: ${process.inspect.real_unatt_requirement}º`}
              </div>     
              
              <FInputSelect 
                options     = {[ ['', ''], [0, ''], [1, '1'], [2, '2'], [3, '3']]}
                name        = 'force_unatt_requirement'
                value       = {process.force_unatt_requirement || ''}
                onChange    = {(v, c) => handleSave('force_unatt_requirement', v=='' ? undefined : v, c)}
                showValidity= {0}
                clearable   = {true}
                required    = {false}
                autocomplete= {"off"}
                label       = {undefined}
                icon        = {'Sancionar en base a req. no atendido'}
                inline
                readOnly   = {!canEdit}
              />                       
            </TaxHeaderFieldsRow>
          </TaxHeaderFields>
        </Col>

        <Col md="12" lg= "4">

          <TaxHeaderFields title="Reducción">
            <TaxHeaderFieldsRow>
              <TaxInputCheckbox 
                  name       = "penalty_20_reduct_payment"
                  label      = "Ingreso voluntario"
                  value      = {uvl(process.penalty_20_reduct_payment,true)}
                  onChange   = {(v, c) => handleSave('penalty_20_reduct_payment', v, c)}
                  readOnly   = {!canEdit}
              />  

            </TaxHeaderFieldsRow>          

          </TaxHeaderFields>
        </Col>

        <Col md="12" lg= "3">

          <TaxHeaderFields title="Observaciones">
            <TaxHeaderFieldsRow>
            
              <FInputTextArea 
                      name       = "penalty_20_remarks"
                      icon       = {false}
                      label      = {undefined}
                      value      = {process.penalty_20_remarks}
                      onChange   = {(v, c) => handleSave('penalty_20_remarks', v, c)}
                      formGroupStyle = {{width: "100%"}}
                      inline
                      readOnly   = {!canEdit}/>
            </TaxHeaderFieldsRow>
          </TaxHeaderFields>
        </Col>               
      </Row>
     
    </>
  )
}

export default ProcessPenalty20