import React from 'react'
import {Table} from 'reactstrap'

const TaxTable = ({className, onKeyDown, children}) => 
  <div className={`taxes_table_cnt ${className || ''}`}
       
       onKeyDown={onKeyDown}>
    <Table responsive hover={false} className="taxes_table mb-0 d-sm-table"> 
      {children}
    </Table>
  </div>

export default TaxTable

