import React, {useState, useEffect} from 'react'
import SimpleModal from 'cli/ui/components/SimpleModal'
import { asPriceWithCurrency} from 'farrapa/numbers'
import { ActionAdd, ActionLess, ActionSave } from 'cli/ui/components/buttons/actions'
import {uvl} from 'farrapa/commons'
import { FInputFloat } from 'formiga-reactstrap'

const _sum = (a, b) => (uvl(a, 0.0) + uvl(b,0.0)) //.toFixed(2)
const _rest = (a, b) => (uvl(a, 0.0) - uvl(b,0.0)) //.toFixed(2)

const IncomePaidRow = ({title, value, added, onAdd, onRemove}) => {
  return (
    <tr>
      <td>{title}</td>  
      <td align="right">{asPriceWithCurrency( uvl(value, 0.0) )}</td>
      <td>
        <ActionAdd  onClick={onAdd}
                    enabled={added!==true}/>
        <ActionLess onClick={onRemove}
                    enabled={added===true}/>
      </td>
    </tr>    
  )
}

const IncomePaidModal = ({show, title, process, onChange, onClose, onSave}) => {
  const [amount, setAmount]= useState(uvl(process.paid_amount,0.0))

  useEffect(() => {
    setAmount(uvl(process.paid_amount,0.0))
  }, [process.paid_amount])

  const all_paid = uvl(process.paid_amount, 0.0) >= uvl(process.to_be_paid,0)

  return (
    <SimpleModal title  ={title}
                 show   ={show}
                 onClose={onClose}
                 onSave ={onSave}>
      <style>
      {`
        .mb-3 {
          margin-bottom: 0 !important;
        }
        input {
          width: 10em;
          text-align: right;
        }
      `}        
      </style>
      <table style={{
        borderCollapse: "separate",
        borderSpacing: "2em 0.5em"
      }}>
        <tbody>
          <IncomePaidRow title = "Tasas"
                        value = {process.amounts.fees}
                        added = {process.paid_fee}
                        onAdd = {() => onChange({
                          paid_fee: true,
                          paid_amount: _sum(process.paid_amount, process.amounts.fees)
                        })}
                        onRemove = {() => onChange({
                          paid_fee: false,
                          paid_amount: _rest(process.paid_amount, process.amounts.fees)
                        })}/>

          <IncomePaidRow title = "Intereses"
                        value = {process.amounts.interests}
                        added = {process.paid_interests}
                        onAdd = {() => onChange({
                          paid_interests: true,
                          paid_amount: _sum(process.paid_amount, process.amounts.interests)
                        })}
                        onRemove = {() => onChange({
                          paid_interests: false,
                          paid_amount: _rest(process.paid_amount, process.amounts.interests)
                        })}/>  

          {process.penalty_19_on==true
           ? 
            <IncomePaidRow title = "Sanción art 191/192"
                          value = {process.amounts.penalty_19}
                          added = {process.paid_penalty_19}
                          onAdd = {() => onChange({
                            paid_penalty_19: true,
                            paid_amount: _sum(process.paid_amount, process.amounts.penalty_19)
                          })}
                          onRemove = {() => onChange({
                            paid_penalty_19: false,
                            paid_amount: _rest(process.paid_amount, process.amounts.penalty_19)
                          })}/>            
           : null}                                              

          {process.penalty_20_on==true
           ? 
            <IncomePaidRow title = "Sanción art 203"
                          value = {process.amounts.penalty_20}
                          added = {process.paid_penalty_20}
                          onAdd = {() => onChange({
                            paid_penalty_20: true,
                            paid_amount: _sum(process.paid_amount, process.amounts.penalty_20)
                          })}
                          onRemove = {() => onChange({
                            paid_penalty_20: false,
                            paid_amount: _rest(process.paid_amount, process.amounts.penalty_20)
                          })}/>            
           : null}      

            <tr>
              <td><b>Total a pagar</b></td>  
              <td align="right"><b>{asPriceWithCurrency(uvl(process.to_be_paid, 0.0))}</b></td>
              <td>
                <ActionAdd  onClick={() => onChange({
                            paid_fee: true,
                            paid_interests: true,
                            paid_penalty_19: true,
                            paid_penalty_20: true,
                            paid_amount: uvl(process.to_be_paid,0.0)
                          })}
                            enabled={! all_paid}/>
                <ActionLess onClick={() => onChange({
                            paid_fee: false,
                            paid_interests: false,
                            paid_penalty_19: false,
                            paid_penalty_20: false,
                            paid_amount: 0.0
                          })}
                            enabled={all_paid}/>
              </td>
            </tr>  
            <tr>
              <td><b>Pagado</b></td>  
              <td>
                <FInputFloat name       = "process_paid_amouny"
                            label       = {undefined}
                            icon        = {false}
                            value       = {amount.toFixed(2)}
                            decimals    = {2}
                            decimalSign = {","}
                            onChange    = {(v) => setAmount(v)}
                            clearable   = {true}
                            showValidity= {1}
                            showArrows  = {false}
                />
              </td>
              <td>
                <ActionSave  onClick={() => onChange({
                            paid_amount: amount
                          })}
                            enabled={true}/>
              </td>

            </tr>            
        </tbody>

      </table>
    </SimpleModal>
  )
}
export default IncomePaidModal

