import React from 'react'
import { PTYPE_NAMES } from 'bl/ns/process/process'
import { HyperOrdinance, HyperTaxPayer } from 'cli/ui/components/hyper'
import NiceList from 'cli/ui/components/nice_list'


const NewEditionStep3 =  ({taxCode, edition, ordinance, taxpayerList}) => {

  const editionFields= [
    /* eslint react/display-name:0 */
    {
      label: "Contribuyente",
      className: "main", 
      value: (p) => taxpayerList.find((tp) => tp.id==p.taxpayer_id).name,
      render: (p) =>
        <HyperTaxPayer  id  = {p.taxpayer_id}
                        name={taxpayerList.find((tp) => tp.id==p.taxpayer_id).name}
                        maxWidth='unset'/>      
    }, 
    {
      label: "Tipo",
      value: (p) => PTYPE_NAMES[taxCode][p.process_type]  
    },    
    {
      label: "Expediente",
      value: (p) => p.name 
    },    
    {
      label: "Ejercicios",
      value: (p) => `${p.year_from} a ${p.year_to}`
    }, 
  ]
  
  return (
    <div>
      <table style={{width: '100%', borderCollapse: 'separate', borderSpacing: '2em'}}>
        <tbody>
          <tr>
            <td>
              <span>Ordenanza</span>
            </td>
            <td>
              <HyperOrdinance taxCode={taxCode} 
                              id={ordinance.id}
                              councilId={ordinance.council_id}
                              name={ordinance.name}/>              
            </td>
          </tr>
          <tr>
            <td>
              <span>Nombre tirada</span>
            </td>
            <td>
              <b>{edition.name}</b>      
            </td>
          </tr>
          <tr>
            <td>
              <span>Expedientes</span>
            </td>
            <td>
              <NiceList fields     = {editionFields}
                        initialSort= {[0, "asc"]}
                        data       = {edition.processes}
                        makeKey    = {(p) => `new_edition_processes_list_row_${p.taxpayer_id}`}/>     
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}


export default NewEditionStep3




