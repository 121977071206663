import React, {useState, useEffect} from 'react'
import {useParams, useLocation, useNavigate} from 'react-router-dom'


import {TAX_NAMES} from 'bl/ns/taxes'

import Page from 'cli/ui/pages/layout/Page'
import {Row, Col} from 'reactstrap'
import { LoadingBig } from 'cli/ui/components/loading'
import { ActionEdit } from 'cli/ui/components/buttons/actions'
import { HyperCouncil, HyperOrdinance } from 'cli/ui/components/hyper'
import Processes from 'cli/ui/fragments/process/list/Processes'
import { IfPermission } from 'cli/context/IfPermission'
import withContext from 'cli/context/withContext'


const EditionProcesses = ({fetcher}) => {
  const location= useLocation()
  const params= useParams()
  const navigate= useNavigate()

  const [taxCode, setTaxCode]= useState(location.pathname.split('/')[2])
  const [breads, setBreads]= useState([])
  const [title, setTitle]= useState('')
  const [edition, setEdition]= useState({})
  const [loaded, setLoaded]= useState(false)
  
  const paramId = params.idEdition

  // taxCode
  useEffect(() => {
    setTaxCode(location.pathname.split('/')[2])
  }, [location])

  // title and breads
  useEffect(() => {
    setBreads(
      [['/', 'Inicio'], 
      ['/taxes', 'Tributos'], 
      [`/taxes/${taxCode}`, TAX_NAMES[taxCode]], 
      [`/taxes/${taxCode}/edition`, 'Inspecciones'],
      edition.name||''
     ]      
    )

    setTitle(edition?.name||'')
  }, [taxCode, edition])


  // Fetch data on mount
  useEffect(() => {
    async function _fetchData() {
      
      const res = await fetcher.get('/api/edition/detail_find', {id: paramId})
      const nEdition = res.data
      if (nEdition.id === undefined) {
        navigate(`/taxes/${taxCode}/edition`)
      }
      setEdition(nEdition)

      setLoaded(true)
    }
    _fetchData()  
  }, [fetcher, navigate, paramId, taxCode])

  return (
    <Page breads = {breads}
          title  = {`Tirada: ${title}`}
          actions= {<>
                      <HyperCouncil id= {edition.council_id} name={edition.council_name} />
                      <HyperOrdinance 
                         taxCode   = {taxCode}
                         councilId = {edition.council_id} 
                         id        = {edition.ordinance_id} 
                         name      = {edition.ordinance_name}/>
                      <IfPermission code="process_edit">
                        <ActionEdit to={`/taxes/${taxCode}/edition/${edition.id}/det`} label="Editar"/>
                      </IfPermission>
                    </>
                    }>
      <Row>
        <Col xs="12">
          {!loaded
            ? <LoadingBig/>        
            : <Processes processes = {edition.processes}
                         hiddenFields={['tax_code']}/>
          }
        </Col>
      </Row>
    </Page>
  )
}


export default withContext(EditionProcesses)
