import React, {useState, useRef, useEffect} from 'react'
import ActionButton from './ActionButton'
import {uvl} from 'farrapa/commons'

const BTN_STEPS= [
  { color: '#6e6c6c', label: 'Deshacer', icon: 'undo'    , enabled: true},
  { color: '#6e6c6c', label: 'Seguro?' , icon: 'undo'    , enabled: true},
  { color: '#000000', label: ''        , icon: 'waiting' , enabled: false},
  { color: '#000000', label: 'Error!'  , icon: 'cross'   , enabled: false}
]


const ActionUndo = ({enabled, onClick, description}) => {
  const [step, setStep]= useState(0)
  const isMounted= useRef(undefined)

  useEffect(() => {
    isMounted.current= true

    return () => {
      isMounted.current= false
    }
  }, [])

  const handleClick = (ev) => {
    const curStep= step
    if (curStep<3) {
      setStep(curStep+1)

      if (curStep == 1) {
        const result= onClick(ev)
        
        if (result!=undefined) {
          if (result == Promise.resolve(result)) {
            result.then((ok) => {
              if (isMounted.current) {
                setStep(ok ? 0 : 3)
              }
            })
          } else {
            setStep(result ? 0 : 3)
          }        
        }
      }
    }
  }

  const props = BTN_STEPS[step]
  if (description!=undefined && step==1)
    props.label= description

  return (
    <ActionButton color={props.color}
                  icon ={props.icon}
                  label={props.label}
                  enabled={uvl(enabled,props.enabled)}
                  
                  onClick = {(ev) => handleClick(ev)}>
    </ActionButton>
  )
}

export default ActionUndo

