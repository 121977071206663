import React from 'react'
import EventDoc from './EventDoc'
import EventNotif from './EventNotif'

const EventDocsAndNotif = ({process, processStepId, subStep, agents, docs, onPrint, onProcessFileChange, canEdit, canPrint}) => {

  return (
    <>
      {/* Doc */}
      <EventDoc process       = {process}
                processStepId = {processStepId}
                subStep       = {subStep}
                agents        = {agents}
                docs          = {docs}
                onPrint       = {onPrint}
                isLast        = {subStep.done==false}
                canPrint      = {canPrint}/>

     {/* Extra Docs */}
     {subStep.extra_docs!=undefined
       ? subStep.extra_docs.map((doc) => 
            <EventDoc key           = {`process_inspect_${doc.id}_print_extra_doc`}
                      process       = {process}
                      processStepId = {processStepId}
                      subStep       = {subStep}
                      processDoc    = {doc}
                      agents        = {agents}
                      docs          = {docs}
                      onPrint       = {onPrint}
                      canPrint      = {canPrint}/>
         )
       : null}
      
     {/* Notification */}
     {subStep.doc!=undefined && subStep.needs_notif===true
      ?  <EventNotif subStep            = {subStep}
                     onProcessFileChange={onProcessFileChange}
                     canEdit            = {canEdit}/>
      : null}
    </>
  )


}

export default EventDocsAndNotif