import React from 'react'
import { Route } from 'react-router-dom'

import User               from 'cli/ui/pages/user/User'
import UserSettings       from 'cli/ui/pages/user/settings/UserSettings'
import UserEmails         from 'cli/ui/pages/user/emails/UserEmails'

const UserRoutes = (permiss) => {
  return [
    <Route key="users_00" path={'/user'} element={<User/>}/>,
    <Route key="users_01" path={'/user/settings'} element={<UserSettings/>}/>,
    ...permiss.can_user_read_user_email()
    ? [<Route key="users_02" path={'/user/emails'} element={<UserEmails/>}/>]
    : []
  ]
}

export default UserRoutes