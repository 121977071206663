import { SUB_STEP_DOC, SUB_STEP_PROVIDE, 
         SUB_STEP_APPOINTMENT, SUB_STEP_ARGUMENTS, 
         SUB_STEP_AGREE, SUB_STEP_SERIAL, SUB_STEP_APPEAL, 
         SUB_STEP_ESTIMATE, SUB_STEP_JUDICIAL,
         SUB_STEP_ARGUMENTS_DATE, SUB_STEP_AGREE_DATE } from 'bl/ns/process/steps'
import { hslToHex } from 'farrapa/colors'

const makeSubStepDoc = (files, processStep, doc, needsNotification, extraDocs, extra) => {

  const _getDocFile = (files, processStep, processDoc) => {
    const _file_matches = (file) => {
      if (file.process_step==processStep) {
        if (file.process_doc==processDoc) {
          return true
        }
      }  
      return false    
    }
    const f= files.find((f) => _file_matches(f)) || {}
    return f
  }
  
  const _makeDoc = (files, processStep, processDoc) => {
    const file= _getDocFile(files, processStep, processDoc)
    
    const print_status= (file==undefined || file.last_print_at==undefined)
      ? file.goon
        ? 'postponed'
        : 'pending'
      : 'printed'
    
    
    
    const notif_status= print_status!='printed'
      ? 'hidden'
      : file.notification_date==undefined
        ? file.goon
          ? 'postponed'
          : 'pending'
        : 'notificated'
          

    return {
      id      : processDoc,
      file    : file, 
      print_status : print_status,
      notif_status : notif_status
    }
  }
    

  let mdoc = undefined
  let print_status= 'pending'
  let notif_status= 'hidden'

  if (doc!=undefined) {
    mdoc= _makeDoc(files, processStep, doc)
    print_status= 
      mdoc.file!=undefined
      ? mdoc.file.last_print_at!=undefined
        ? 'printed'
        : mdoc.file.goon===true
          ? 'postponed'
          : 'pending'
      : 'pending'


    notif_status= 
      print_status!='printed'
      ? 'hidden'
      : mdoc.file.notification_date!=undefined
        ? 'notificated'
        : mdoc.file.goon===true
          ? 'postponed'
          : 'pending'
  }
          
  const edocs= extraDocs
                ? extraDocs.map((processDoc) => _makeDoc(files, processStep, processDoc))
                : undefined
  
  return {
    type        : SUB_STEP_DOC,
    doc         : mdoc,
    extra_docs  : edocs,
    print_status: print_status,
    notif_status: notif_status,
    needs_notif : needsNotification || false,
    ...extra || {},
    done        : needsNotification===true
                  ? notif_status=='notificated' || notif_status=='postponed'
                  : print_status=='printed'     || print_status=='postponed'
  }
} 

const makeSubStepDocuProvide = (diligenceNumber, satisfied, processDocus, fieldName) => {
  const docus= processDocus!=undefined
               ? processDocus.filter((d) => d.diligence==diligenceNumber) || []
               : []

  return {
    type            : SUB_STEP_PROVIDE,
    diligence_number: diligenceNumber,
    satisfied       : satisfied,
    docus           : docus,
    field_name      : fieldName,
    done            : satisfied!=undefined
  }
}

const makeSubStepAppointment = (appointmentDate, needsAppointmentTime, appointmentTime) => {
  const done= 
    needsAppointmentTime
    ? (appointmentDate != undefined && appointmentTime != undefined)
    : appointmentDate != undefined
  
  return {
    type                  : SUB_STEP_APPOINTMENT,
    appointment_date      : appointmentDate,
    needs_appointment_time: needsAppointmentTime,
    appointment_time      : appointmentTime,
    done                  : done
  }  
}

const makeSubStepArguments = (argumented, fieldName) => {
  const arguments_status= argumented==undefined
                          ? 'pending'
                          : 'done'
  return {
    type            : SUB_STEP_ARGUMENTS,
    argumented      : argumented,
    arguments_status: arguments_status,
    field_name      : fieldName,
    done            : arguments_status=='done',    
  }
}

const _makeSubStepAgree = (agreed, fieldNameAgreed, needsDate, agreedDate, fieldNameAgreedDate, labels) => {
  return {
    type       : SUB_STEP_AGREE,
    agreed     : agreed,
    needs_date : needsDate,
    agreed_date: agreedDate,
    field_name_agreed: fieldNameAgreed,
    field_name_agreed_date: fieldNameAgreedDate,
    done       : needsDate
                 ? (agreed===true && agreedDate!=undefined) || agreed===false
                 : agreed!=undefined,
    labels: labels
  }
}

const makeSubStepAgree = (agreed, fieldNameAgreed, needsDate, agreedDate, fieldNameAgreedDate) => {
  const labels= {
    'agreed': 'Conforme',
    'disagreed': 'Disconforme'
  }
  return _makeSubStepAgree(agreed, fieldNameAgreed, needsDate, agreedDate, fieldNameAgreedDate, labels)
}

const makeSubStepAgreePenalty = (agreed, fieldNameAgreed, needsDate, agreedDate, fieldNameAgreedDate) => {
  const labels= {
    'agreed': 'Oficio Conformidad',
    'disagreed': 'Informe Resolución'
  }
  return _makeSubStepAgree(agreed, fieldNameAgreed, needsDate, agreedDate, fieldNameAgreedDate, labels)
}

const makeSubStepSerial = (agreed, serialNum, serialPrefix, serialText, serialYear, fieldName) => {
  return {
    type       : SUB_STEP_SERIAL,
    agreed     : agreed,
    serial_num : serialNum,
    prefix     : serialPrefix,
    serial_text: serialText,
    serial_year: serialYear,
    field_name : fieldName,
    done       : serialNum!=undefined
  }
}

const makeSubStepAppeal = (appealed, fieldName) => {

  const appeal_status= appealed==undefined
                      ? 'pending'
                      : 'done'  
  
  return {
    type         : SUB_STEP_APPEAL,
    appealed     : appealed,
    appeal_status: appeal_status,
    field_name   : fieldName, 
    done         : appeal_status=='done'
  }
}

const makeSubStepEstimated = (estimated, fieldName) => {
  const estimate_status= estimated==undefined
                      ? 'pending'
                      : 'done'  
  
  return {
    type           : SUB_STEP_ESTIMATE,
    estimated      : estimated,
    estimate_status: estimate_status,
    field_name     : fieldName, 
    done           : estimate_status=='done'
  }
}

const makeSubStepJudicial = (judicial, fieldName) => {
  const judicial_status= judicial==undefined
                       ? 'pending'
                       : 'done'  
  
  return {
    type           : SUB_STEP_JUDICIAL,
    judicial       : judicial,
    judicial_status: judicial_status,
    field_name     : fieldName, 
    done           : judicial_status=='done'
  }
}

const makeSubStepArgumentsDate = (argumentsDate, fieldName) => {
  const arguments_date_status= 
        argumentsDate != undefined
            ? 'done'
            : 'pending'

  return {
    type                  : SUB_STEP_ARGUMENTS_DATE,
    arguments_date_status : arguments_date_status,
    arguments_date        : argumentsDate,
    field_name            : fieldName,
    done                  : arguments_date_status=='done'
  }  
}
const makeSubStepAgreeDate = (agreeDate, fieldName) => {
  const agree_date_status= 
        agreeDate != undefined
            ? 'done'
            : 'pending'

  return {
    type                  : SUB_STEP_AGREE_DATE,
    agree_date_status     : agree_date_status,
    agree_date            : agreeDate,
    field_name            : fieldName,
    done                  : agree_date_status=='done'
  }  
}


const makeStep = (id, subSteps, extra) => {
  const done= subSteps.slice(-1)[0].done
  return {
    id,
    sub: subSteps,
    done,
    completed: done,
    ...extra || {}
  }
}


const makeColor = (color, progress) => {
  if (color=='black') {
    return '#000000'
  }
  /*
  Quick ref:
      0 – red
      60 – yellow
      120 – green
      180 – turquoise
      240 – blue
      300 – pink
      360 – red
  */          
  //value from 0 to 100
  const b60= (progress*60)/100
  const hue= color=='green'
            ? b60 + 60
            : color=='red'
              ? b60 + 300
              : color=='pink'
              ? b60 + 240
              : b60 + 180
  return hslToHex(hue, 100, 50)
}



export {makeSubStepDoc, makeSubStepDocuProvide, makeSubStepAppointment, 
        makeSubStepArguments, makeSubStepAgree, makeSubStepAgreePenalty, makeSubStepSerial,
        makeSubStepAppeal, makeSubStepEstimated, makeSubStepJudicial, 
        makeSubStepArgumentsDate, makeSubStepAgreeDate,
        makeStep, makeColor}