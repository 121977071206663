import React from 'react'
import ActionButton from './ActionButton'

const ActionEye = ({ onClick, enabled, label, isOn }) =>
  <ActionButton color={isOn ? 'blue' : 'lightblue'}
    label={label}
    icon='eye'
    onClick={(e) => { if (onClick!=undefined) onClick(e) }}
    enabled={enabled != undefined ? enabled : true}>
  </ActionButton>

export default ActionEye