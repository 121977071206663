import React, {useState, useEffect} from 'react'
import { asPrice } from 'farrapa/numbers'

const NiceNumber = ({n, color}) =>
  <span style={{ color: color, textAlign: 'right', marginLeft: "1em"}}>
    {`${n!=undefined ? asPrice(n) : '...'} €`}
  </span>

const ProcessCalcsResume = ({ process }) => {
  const [totals, setTotals]= useState([])

  useEffect(() => {
    let nTotals= []
    if (process!=undefined && process.tax!=undefined && process.tax.total!=undefined) {
      let proct
      // process.tax.total can be an array too
      if (Array.isArray(process.tax.total)) {
        proct= process.tax.total
      } else {
        proct= [process.tax.total]
      }
      proct.map((t) => {
        nTotals.push({
          color: process.amount_colors.inspect,
          n: t
        })      
      })
      
      if (process.penalty_20_on===true) {
        nTotals.push({
          color: process.amount_colors.penalty_20,
          n: process.penalty_20.total
        })         
      }
  
      if (process.penalty_19_on===true) {
        nTotals.push({
          color: process.amount_colors.penalty_19,
          n: process.penalty_19.amounts_sum.total
        })         
      }      
    }
    setTotals(nTotals)
  }, [process])

  
  


  return (
    <div style={{display: 'inline-block', fontSize: '2em', whiteSpace: 'nowrap'}}>
      {totals.map((t, i) => 
        <NiceNumber key={`process_totals_${i}`}
                    {...t}/>
      )}
    </div>
  )

}

export default ProcessCalcsResume
