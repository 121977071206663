import React from 'react'
import {Link} from 'react-router-dom'

const FileMissings = ({process, missingFields}) => {
  if (! missingFields || missingFields.length==0) {
    return null
  }

  let fields= []
  missingFields.map((m, i) => {
    if (m.scope=='council') {
      fields.push(
        <Link key={`process_inspect_missing_${m.name.replace(' ', '_')}_council`}
              to={`/councils/${process.council_id}`}>{m.name}</Link>
      )
    } else if (m.scope=='tax_payer') {
      fields.push(
        <Link key={`process_inspect_missing_${m.name.replace(' ', '_')}_taxpayer`}
              to={`/taxpayers/det/${process.taxpayer_id}`}>{m.name}</Link>
      )
    } else if (m.scope=='ordinance') {
      fields.push(
        <Link key={`process_inspect_missing_${m.name.replace(' ', '_')}_ord`}
              to={`/councils/${process.council_id}/${process.tax_code}/${process.ordinance_id}`}>{m.name}</Link>
      )
    } else {
      fields.push(
        <span key={`process_inspect_missing_${m.name.replace(' ', '_')}_plain`}>{m.name}</span>
      )
    }
    if (i<missingFields.length-1) {
      fields.push(
        <span key={`process_inspect_missing_${m.name.replace(' ', '_')}_sep_${i}`}>{", "}</span>
      )
    }
  })

  return (
    <div>
      {"Falta por informar: "}{fields}
    </div>
  )
}


export default FileMissings