import React from 'react'
import {FInputCheckbox} from 'formiga-reactstrap'


const TaxInputCheckbox = (props) => {
  const nprops= {
    ...props,
    icon: props.label,
    label: undefined
  }
  return (
    <FInputCheckbox 
        label      = {undefined}
        formGroupStyle= {{verticalAlign: "middle"}}
        inputStyle = {{border: "1px solid #c2cfd6", left: "0", paddingTop: "0.5em", paddingLeft: "3.5em"}}
        inline
        {...nprops}
    />  
  )
}


export default TaxInputCheckbox