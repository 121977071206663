import React from 'react'
import { EditingIBox, IBoxRow, IBoxIconedText } from 'cli/ui/components/ibox'
import CouncilDetailsClerkForm from './CouncilDetailsClerkForm'

const CouncilDetailsClerk = ({council, onChange, onSave, onDownloadLetterhead}) => {
  const empty= ! (council.clerk_name || council.clerk_pos)
  return (
    <>
      <EditingIBox title="Actuario"
                   empty={empty}
                   fitHeight
                   permissionCode="council_edit"
                   resume={() => 
                      <>
                        {empty
                        ? <IBoxRow center >
                            {"Falta por rellenar el actuario"}
                          </IBoxRow>
                        : <>
                            <IBoxRow center >
                              <IBoxIconedText icon="user"
                                              text={council.clerk_name}/>
                            </IBoxRow>
                            <IBoxRow center >
                              <IBoxIconedText icon="briefcase"
                                              text={council.clerk_pos}/>
                            </IBoxRow>
                          </>
                        }
                      </>
                   }
                   form={(gotoResume) => 
                      <CouncilDetailsClerkForm council={council}  
                                               onChange={onChange}
                                               onSave ={() => {onSave(); gotoResume()}}
                                               onDownloadLetterhead={onDownloadLetterhead}/>
                  }>
      </EditingIBox>
    </>
  )
}

export default CouncilDetailsClerk