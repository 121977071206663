import React, {useState} from 'react'

import {SimpleMessage} from 'cli/ui/components/alerts'
import {FForm, FInputText, FInputEmail, FInputUInt, FInputCheckbox, FInputPassword} from 'formiga-reactstrap'
import {Button} from 'cli/ui/components/buttons'
import Icon from 'cli/ui/components/icon'

const EMAIL_TEST_OK= 'La configuración parece correcta!'
const EMAIL_TEST_FAIL = 'No conseguimos conectar al servidor de mail con los datos proporcionados :('

const UserSettingsEmailEdit = ({echannel, onTest, onChange, onCancel, onSave}) => {
  const [testing  , setTesting  ] = useState(false)
  const [testShow, setTestShow] = useState(false)
  const [testOk  , setTestOk  ] = useState(false)
  const [testMsg , setTestMsg ] = useState('')

  const allOk= () => {
    if (echannel.name!='' && echannel.password!='') {
      return true
    }
    return false
  }

  const showButtons = () => 
    !testing && allOk()
  

  const handleSave= async () => {
    if (allOk()) {
      await onSave()
    } 
  }

  const handleTest = () => {
    setTesting(true)
    if (allOk()) {
      onTest().then((msg) => {
        setTestOk(msg=='Ok');
        setTestShow(true)
        setTestMsg(msg)
        setTesting(false)
      });
      
    }
  }

  return (


    <FForm  onCancel     = {() => onCancel()}
            onSave       = {() => handleSave()}>
              <>
                <FInputText name        = "name"
                            label       = "Nombre"
                            placeholder = "Mi cuenta"
                            value       = {echannel.name}
                            onChange    = {(v) => onChange('name', v)}
                            required    = {true}
                            feedback    = {'Especifica un nombre'}
                />
                <FInputEmail name       = "email"
                            label       = "Email"
                            placeholder = "miemail@gmail.com"
                            value       = {echannel.email}
                            onChange    = {(v) => onChange('email', v)}
                            required    = {true}
                            feedback    = {'Especifica una cuenta de correo'}
                />
                <FInputText name       = "host"
                            label      = "Host"
                            icon       = {<Icon icon="url"/>}
                            required   = {true}
                            placeholder="imap.gmail.com, outlook.office365.com, ..."
                            value      ={echannel.host}
                            onChange   ={(v) => onChange('host', v)} 
                            feedback   ={"Aquí necesitamos una IP o el nombre de un dominio"}
                />
                <FInputUInt name       = "port"
                              label      = "Puerto"
                              icon       = {<Icon icon="filter"/>}
                              required   = {true}
                              acceptedValues={[143,993]}
                              placeholder="143 o 993"
                              value      ={echannel.port}
                              onChange   ={(v) => onChange('port', v)} 
                              feedback   ={"El puerto ha de ser o 993 o 143"}
                />
                <FInputCheckbox name     = "tls"
                              label      = "TLS"
                              value      ={echannel.tls}
                              onChange   ={(v) => onChange('tls', v)} 
                />
                <FInputPassword name   = "password"
                            label      = "Contraseña"
                            required   = {true}
                            value      ={echannel.password}
                            onChange   ={(v) => onChange('password', v)} 
                            feedback   ={"Especifica una contraseña!"}
                />      
                <Button   color   = 'warning'
                          onClick = {(e) => handleTest(e)}
                          disabled= {! showButtons()}
                          icon    = {'hand_ok'}
                          >
                    Probar
                </Button>    
                <SimpleMessage show   = {testShow}
                                onClose= {() => setTestShow(false)}
                                title  = {'Resultado del test: ' + (testOk ? 'Ok' : 'Error')}
                                message= {testOk ? EMAIL_TEST_OK : EMAIL_TEST_FAIL+'\n'+testMsg}>
                </SimpleMessage>
  
              </>
    </FForm>



  )
}


export default UserSettingsEmailEdit



