import React, {useCallback, useEffect} from 'react'
import {NavLink} from 'react-router-dom'

import withContext from 'cli/context/withContext'

import Icon from 'cli/ui/components/icon'
import {Badge, 
        Nav,
        NavItem, 
        /*NavLink as RsNavLink*/} from 'reactstrap'


import {menu} from './menu';
// import SidebarMinimizer from './SidebarMinimizer';

const SIDEBAR_FIXED= true



// badge addon to NavItem
const _Badge = ({badge}) => {
  if (!badge) return null

  return (<Badge className={ badge.class } color={ badge.variant }>{ badge.text }</Badge>)
}

// simple wrapper for nav-title item
const _Wrapper = ({item}) => { 
  return (
    item.wrapper && item.wrapper.element 
    ? React.createElement(item.wrapper.element, item.wrapper.attributes, item.name)
    : item.name 
  ) 
}

// nav list section title
const _Title =  ({title, key}) => {
  return <li key={key} className={ `nav-title ${title.class}` }>
    <_Wrapper item={title}/>
  </li>
}

// nav list divider
const _Divider = ({divider, key}) => {
  return <li key={key} className={ `divider ${divider.class}` }></li>
};

// nav item with nav link
const _NavItem = ({item, key, level, permiss}) => {
  const linkClasses = `nav-link ${item.variant ? `nav-link-${item.variant}` : ''}`
  const url = item.url ? item.url : ''
  //const _link = url ? url.substring(0, 4) : '';
  //const isExternal=  _link === 'http';
 
  return (
    <NavItem key={key} className={`nav-item-l${level} ${item.class}`}>
      {/* isExternal ?
        <RsNavLink href={url} className={linkClasses} active>
          <Icon className="nav-icon" size={12} color="white" inline={true}  icon={item.icon}/>
          {item.name}<_Badge badge={item.badge}/>
        </RsNavLink>
        :
        <NavLink to={url} 
                  className={({ isActive }) =>
                    `${linkClasses} ${isActive ? 'active' : undefined}`
                  }            
          >
          <Icon className="nav-icon" size={12} color="white" inline={true}  icon={item.icon}/>
          <span className="nav-item-text">{item.name}</span><_Badge badge={item.badge}/>
          
        </NavLink>
       */}

        <NavLink to={url} 
                  className={({ isActive }) =>
                    `${linkClasses} ${isActive ? 'active' : undefined}`
                  }            
          >
          <Icon className="nav-icon" size={12} color="white" inline={true}  icon={item.icon}/>
          <span className="nav-item-text">{item.name}</span><_Badge badge={item.badge}/>
          
        </NavLink>       
    </NavItem>
  )
};

// nav dropdown
const _NavDropdown = ({item, key, level, permiss, location}) => {
  const handleDropdownOpen = useCallback((event) => {
    event.preventDefault();
    event.target.parentElement.classList.toggle('open');

  }, [])
  
  const isActiveRoute = useCallback((routeName) => {
    return location.pathname.indexOf(routeName) > -1
  }, [])


  return (
    <li key={key} className={`nav-item nav-item-l${level} nav-dropdown ${isActiveRoute(item.url) ? 'open' : ''}`}>
      <a className= {`nav-link nav-dropdown-toggle`}
          href     = "#"
          onClick  = {(event) => handleDropdownOpen(event)}>
          <Icon className="nav-icon" size={12} color="white" inline={true}   icon={item.icon}/>{item.name}
      </a>
      <ul className="nav-dropdown-items">
        <_NavList items={item.children} level={level+1} permiss={permiss} location={location}/>
      </ul>
    </li>)
};

// nav type
const _NavType = ({item, idx, level, permiss, location}) => {
  return (
  (!permiss.has_permission(item?.permissionCode))
    ? null
    : item.title 
    ? <_Title title={item} key={idx}/>
    : item.divider 
    ? <_Divider divider={item} key={idx}/>
    : item.children 
    ? <_NavDropdown item={item} key={idx} level={level} permiss={permiss} location={location}/>
    : <_NavItem item={item} key={idx} level={level} permiss={permiss} location={location}/>

  )
}

// nav list
const _NavList = ({items, level, permiss, location}) => {
  return items.map( (item, idx) => <_NavType item={item} key={idx} level={level} permiss={permiss} location={location}/> )
}





const Sidebar = ({location, permiss}) => {

  useEffect(() => {
    if (SIDEBAR_FIXED) { 
      document.body.classList.add('sidebar-fixed')
    }
  }, [])
  
  // sidebar-nav root
  return (
    <div className="sidebar">
      <nav className="sidebar-nav">
        <Nav>
          <_NavList items={menu} level={1} permiss={permiss} location={location}/>
        </Nav>
      </nav>
      {/* <SidebarMinimizer/> */ }
    </div>
  )

}

export default withContext(Sidebar)
