import React from 'react'
import {intre_pretty_short} from 'intre'
import {TimelineEvent, TimelineEventInfo, TimelineEventRight} from 'cli/ui/components/timeline'
import EventAgreeDateForm from './EventAgreeDateForm'


const EventAgreeDate = ({subStep, onProcessChange}) => {

  const handleOnChange = (agreeDate) => {
    if (agreeDate!=subStep.agree_date) {
      const changes= {[subStep.field_name]: agreeDate}
      onProcessChange(changes)
    }
  }

  return (
    <TimelineEvent  key      ={`process_inspect_agree_date`}
                    legend   = {{icon: "pen", 
                                color: subStep.agree_date_status!='pending'
                                      ? 'green'
                                      : 'gray'}}
                    date     = {subStep.agree_date}
                    isLast   = {subStep.done===false}
                    >

      <TimelineEventInfo>
        {subStep.agree_date_status=='pending'
          ? <i style={{color: "gray"}}>Pendiente de fijar</i>
          : <i style={{color: "gray"}}>{`Fecha de conformidad: ${intre_pretty_short(subStep.agree_date)}`}</i>
        }
      </TimelineEventInfo>
      <TimelineEventRight>
             <EventAgreeDateForm subStep  = {subStep}
                                 onChange = {(agreeDate) => handleOnChange(agreeDate)}/>

      </TimelineEventRight>
    </TimelineEvent>
  )
}

export default EventAgreeDate