import React, {useState, useEffect} from 'react'


// Components
import Page          from 'cli/ui/pages/layout/Page'
import {Row, Col}    from 'reactstrap'
import { ActionAdd }   from 'cli/ui/components/buttons/actions'
import {LoadingBig}    from 'cli/ui/components/loading'
import EditionsList    from './EditionsList'
import {TAX_NAMES} from 'bl/ns/taxes'
import { arrayRemove } from 'farrapa/collections'
import { IfPermission } from 'cli/context/IfPermission'
import withContext from 'cli/context/withContext'

const Editions = ({fetcher, taxCode, showHistorical= false}) => {

  const [editions, setEditions]= useState([])
  const [status, setStatus]= useState(0)


  useEffect(() => {
    const fetchEditions = async () => {

      setStatus(0)
      
      const params= {tax_code: taxCode, historical: showHistorical}
  
      // Fetch them
      const res = await fetcher.get('/api/edition/detail', params)
      const nEditions = res.data
      setEditions(nEditions)
  
      setStatus(1)
    }


    fetchEditions()
  }, [fetcher, showHistorical, taxCode])
  
  
  const handleEditionAsHistorical= async (editionId) => {
    const pids = editions.find((c) => c.id==editionId)
                         .processes
                         .map((p) => p.id)
    
    let done= true
    if (pids.length>0) {
      const res = await fetcher.post('/api/process/as_historical', {process_ids: pids, historical: !showHistorical})
      done= res.data
    }

    if (done) {
      let neditions= [...editions]
      arrayRemove(neditions,
        neditions.find((c) => c.id==editionId)
      )
      setEditions(neditions)
    }
    return done
  }


  const handleRemoveEdition = async (editionId) => {
    const done = await fetcher.remove('/api/edition', editionId)
    if (done) {
      let neditions= [...editions]
      arrayRemove(neditions,
        neditions.find((c) => c.id==editionId)
      )
      setEditions(neditions)
    }
    return done
  }


  return (
    <Page breads = {[['/', 'Inicio'], ['/taxes', 'Tributos'], [`/taxes/${taxCode}`, TAX_NAMES[taxCode]], [showHistorical ? 'Histórico' : 'Inspecciones']]}
          title  = {`${showHistorical ? 'Histórico' : 'Inspecciones'} ${TAX_NAMES[taxCode]}`}
          actions= {<IfPermission code="process_edit">
                      <ActionAdd to={`/taxes/${taxCode}/newedition`} label="Crear nueva tirada"/>
                    </IfPermission>
                    }>

      <Row>
        <Col xs="12">
          {status < 1 
          ? <LoadingBig/>        
          : <Row>
              <Col xs="12">    
                {status < 1 ?
                  <LoadingBig /> :
                  <EditionsList taxCode  = {taxCode}
                                editions = {editions}
                                showHistorical = {showHistorical}
                                onHistorical = {(cid) => handleEditionAsHistorical(cid)}
                                onRemove = {(cid) => handleRemoveEdition(cid)}/>
                }
              </Col>
            </Row>  
          }
        </Col>
      </Row>

    </Page>
  )
}



export default withContext(Editions)
