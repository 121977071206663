import {PTYPE} from 'bl/ns/process/process'

// const DONE= {
//   NO: 0,
//   YES: 1
// }


const FEES= {
  [PTYPE.COMPLETE]: {
    1: 3000,
    2: 15000,
    3: '%' // 600000
  },
  [PTYPE.REDUCED]: {
    1: 300,
    2: 1500,
    3: '%'
  } 
}


// const DISCOUNT_PROVIDE= {
//   [PTYPE.COMPLETE] : {
//     [DONE.NO] : 1,
//     [DONE.YES]: 0.5,
//   },
//   [PTYPE.REDUCED]: {
//     [DONE.NO] : 1,
//     [DONE.YES]: 1,
//   },
// }
// 
// const DISCOUNT_PAYMENT= {
//   [PTYPE.COMPLETE] : {
//     [DONE.NO] : 1,
//     [DONE.YES]: 0.75,
//   },
//   [PTYPE.REDUCED]: {
//     [DONE.NO] : 1,
//     [DONE.YES]: 0.75,
//   },
// }


const OVER_REVENUES_PERCENT= {
  [PTYPE.COMPLETE] : 0.01,
  [PTYPE.REDUCED]: 0.02,
}
const OVER_REVENUES_LIMITS= {
  [PTYPE.COMPLETE] : [20000, 600000],
  [PTYPE.REDUCED]: [10000, 400000],
}

const get203Fee = (processType, unattRequirement, /*earlyProvide, voluntaryPayment,*/ revenues) => {
  if (unattRequirement==0) {
    return 0.0
  }

  let fee= FEES[processType][unattRequirement]

  if (fee=='%') {
    if (isNaN(revenues) || revenues==0.0) {
      return 0.0
    }
    const perc= OVER_REVENUES_PERCENT[processType]
    const [lmin, lmax]= OVER_REVENUES_LIMITS[processType]
    let calc= revenues*perc
    calc= Math.max(lmin, calc)
    calc= Math.min(lmax, calc)
    return calc
  }

  /*const provides= earlyProvide===true ? 1 : 0
  const pays= voluntaryPayment===true ? 1 : 0

  const prdis= DISCOUNT_PROVIDE[processType][provides]
  const padis= DISCOUNT_PAYMENT[processType][pays]

  fee= fee * prdis
  fee= fee * padis*/

  return fee

}

export {get203Fee}