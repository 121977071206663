import React from 'react'

import {FForm, FInputText} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'

const CouncilDetailsClerkForm = ({ council, onChange, onSave}) =>

  <FForm onCancel     = {undefined}
         onSave       = {onSave}>
          <>
            <FInputText name        = "clerk_name"
                        label       = "Nombre"
                        icon        = {<Icon icon={"user"}/>}
                        value       = {council.clerk_name}
                        onChange    = {(v) => onChange('clerk_name', v)}
            />
            <FInputText name        = "clerk_pos"
                        label       = "Posición"
                        icon        = {<Icon icon={"briefcase"}/>}
                        value       = {council.clerk_pos}
                        onChange    = {(v) => onChange('clerk_pos', v)}
            /> 
          </>
  </FForm>

export default CouncilDetailsClerkForm




