import React from 'react'
import {asPriceWithCurrency} from 'farrapa/numbers'
import getProcessInfo from 'bl/util/process/getProcessInfo'
import ProgressBar from '../bar/ProgressBar'

const ProcessProgress = ({process, what}) => {
    const i= getProcessInfo(process, what)
    return (
        <ProgressBar left = {i.status}
                     center = {i.serial}
                     right = {i.amount != 0 ? asPriceWithCurrency(i.amount) : ''}
                     rightColor = {i.amountColor}
                     progress = {i.progress}
                     color = {i.color}
                     />
    )
}

    

export default ProcessProgress