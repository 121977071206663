import React, { useEffect, useState } from 'react'
import {ActionChat} from 'cli/ui/components/buttons/actions'
import IncomeRemarksModal from './IncomeRemarksModal'
import {uvl} from 'farrapa/commons'

const IncomeRemarks = ({process, onChangeProcess}) => {
  const [remarks, setRemarks]= useState(process.income_remarks)
  const [showModal, setShowModal]= useState(false)

  useEffect(() => {
    setRemarks(process.income_remarks)
  }, [process])

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleSaveRemarks = () => {
    onChangeProcess('income_remarks', remarks)
    setShowModal(false)
  }

  return (
    <>
      <ActionChat 
        onClick  = {() => setShowModal(true)}
        enabled  = {true}
        label    = ""
        hasNotes = {uvl(process.income_remarks,'')!=''}/>
      
      <IncomeRemarksModal
        show     = {showModal}
        title    = {`Notas sobre recaudación del expediente ${process.name}`}
        remarks  = {remarks}
        onChange = {(v) => setRemarks(v)}
        onClose  = {() => handleCloseModal()}
        onSave   = {() => handleSaveRemarks()}

      />
    </>
  )
}

export default IncomeRemarks