import React from 'react'
import OrdinanceDetails from '../../common/OrdinanceDetails'
import TOOrdinanceDetailsForm from './TOOrdinanceDetailsForm'

const TOOrdinanceDetails = (props) =>
  <OrdinanceDetails
             taxCode='to'
             taxDataFields={[]}
             renderForm={(fprops) => TOOrdinanceDetailsForm({...fprops})}
             {...props}/>

export default TOOrdinanceDetails