import React from 'react'

import {FForm, FInputText, FInputUrl, FInputSelectSearch, FInputCheckbox,
        FInputTextArea, FInputSelect, FInputUInt, FInputFile} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'

import { SPANISH_PROVINCES } from 'bl/ns/geo'
import { GENRES } from 'bl/ns/genres'


const CouncilMewForm = ({ council, existingNames, onChange, onSave}) => 
  <FForm onCancel     = {undefined}
        onSave       = {onSave}>
          <>
            <FInputText name        = "name"
                        label       = "Nombre"
                        icon        = {<Icon icon={"council"}/>}
                        value       = {council.name}
                        required    = {true}
                        checkValue  = {(v) => existingNames.map((n) => n.toLowerCase()).indexOf(v.toLowerCase())==-1}
                        feedback    = {'Inserta un nombre válido y único'}
                        onChange    = {(v) => onChange('name', v)}
            />
            <FInputSelectSearch  
                        options     = {SPANISH_PROVINCES}
                        name        = 'provincia'
                        label       = 'Provincia'
                        icon        = {<Icon icon={"map-signs"}/>}
                        value       = {council.province}
                        onChange    = {(v) => onChange('province', v)}
                        feedback    = {"Seleciona una Provincia"}
                        clearable   = {true}
                        searchable  = {true}
                        required    = {true}
            />
            <FInputText name        = "address"
                        label       = "Dirección"
                        icon        = {<Icon icon={"location"}/>}
                        value       = {council.address}
                        onChange    = {(v) => onChange('address', v)}
            />
            <FInputUrl  name        = "deh"
                        label       = "Dirección Electrónica Habilitada"
                        value       = {council.deh}
                        icon        = {<Icon icon={"url"}/>}
                        feedback    = {'Inserta un email o una dirección web'}
                        onChange    = {(v) => onChange('deh', v)}
                        pattern     = {"^(https?://)?([a-zA-Z0-9]([a-zA-ZäöüÄÖÜ0-9-]{0,61}[a-zA-Z0-9])?.)+[a-zA-Z/]{2,6}$"}
            />
            <FInputText name        = "nif"
                        label       = "NIF"
                        icon        = {<Icon icon={"profile"}/>}
                        value       = {council.nif}
                        onChange    = {(v) => onChange('nif', v)}
            />
            <FInputSelect
                        options     = {GENRES}
                        name        = 'major_genre'
                        label       = 'Sexo alcalde'
                        icon        = {<Icon icon={"users"}/>}
                        value       = {council.major_genre}
                        onChange    = {(v) => onChange('major_genre', v)}
                        feedback    = {"Seleciona un sexo"}
                        clearable   = {true}
                        searchable  = {true}
            />
            <FInputText name        = "clerk_name"
                        label       = "Nombre Actuario"
                        icon        = {<Icon icon={"user"}/>}
                        value       = {council.clerk_name}
                        onChange    = {(v) => onChange('clerk_name', v)}
            />
            <FInputText name        = "clerk_pos"
                        label       = "Posición Actuario"
                        icon        = {<Icon icon={"briefcase"}/>}
                        value       = {council.clerk_pos}
                        onChange    = {(v) => onChange('clerk_pos', v)}
            /> 
            <FInputText name        = "iban"
                        label       = "Cuenta Bancaria"
                        icon        = {<Icon icon={"dollar"}/>}
                        value       = {council.iban}
                        onChange    = {(v) => onChange('iban', v)}
            /> 
            <FInputUInt name      = "inhabitants"
                        label       = "Habitantes"
                        icon        = {<Icon icon={"users"}/>}
                        value       = {council.inhabitants}
                        onChange    = {(v) => onChange('inhabitants', v)}
            />

            <FInputTextArea name    = "remarks"
                        label       = "Observaciones"
                        icon        = {<Icon icon={"text"}/>}
                        value       = {council.remarks}
                        onChange    = {(v) => onChange('remarks', v)}
            />              
            <FInputFile   name        = "letterhead_doc"
                          label       = "Fichero con membrete"
                          value       = {council.letterhead_doc}
                          onChange    = {(v) => onChange('letterhead_doc', v)}
                          onDownload  = {undefined}
            />  

            <FInputCheckbox name   = "letterhead"
                        label      = "Usar membrete"
                        disabled   = {council.letterhead_doc?.name==undefined}
                        value      = {council.letterhead}
                        onChange   = {(v) => onChange('letterhead', v)}
            />           

            <FInputCheckbox name   = "letterhead_margins"
                        label      = "Copiar márgenes del membrete"
                        disabled   = {council.letterhead_doc?.name==undefined}
                        value      = {council.letterhead_margins}
                        onChange   = {(v) => onChange('letterhead_margins', v)}
            />                                           
          </>
  </FForm>

export default CouncilMewForm




