import React, {useState, useEffect, useCallback} from 'react'
import {useParams, useNavigate} from 'react-router-dom'


// components
import Page from 'cli/ui/pages/layout/Page'
import {Row, Col} from 'reactstrap'
import { ActionBack, ActionGo } from 'cli/ui/components/buttons/actions'
import { LoadingBig } from 'cli/ui/components/loading'
import LUOrdinanceDetailsForm from './LUOrdinanceDetailsForm'
import withContext from 'cli/context/withContext'

const LUOrdinanceDetails = ({fetcher, permiss}) => {

  const params= useParams()
  const navigate= useNavigate()

  const [luOrdinance, setLuOrdinance]= useState({})
  const [nameList, setNameList]= useState([])
  const [existingNames, setExistingNames]= useState([])
  const [nameOrig, setNameOrig]= useState('')  
  const [councilName, setCouncilName]= useState('')
  const [status, setStatus]= useState(0)

  const paramCouncilId = params.idCouncil
  const paramId = params.idOrdinance
  const isNew = paramId == 'new'


  const fetchOrdinance = useCallback(async(ordinanceId) => {
    const res = await fetcher.get('/api/ordinance/detail', {id: ordinanceId})
    const nLuOrdinances = res.data
    const nLuOrdinance = nLuOrdinances[0]   
    setLuOrdinance(nLuOrdinance)
    setNameOrig(nLuOrdinance.name)    
  }, [fetcher])

  useEffect(() => {
    async function fetchData() {
      const nNameList = await fetcher.name_list('/api/ordinance')
      setNameList(nNameList) 

      if (isNew) {
        setLuOrdinance({
          council_id: paramCouncilId
        })
        setNameOrig('')
      } else {
        await fetchOrdinance(paramId)
      }

      const council= await fetcher.find('/api/council', paramCouncilId, {fields: ['id','name']})
      setCouncilName(council.name)      

      setStatus(1)
    }
    fetchData()
  }, [paramCouncilId, paramId, isNew, fetcher, fetchOrdinance])

  useEffect(() => {
    setExistingNames(
      nameList.filter((n) => n!=nameOrig)
    )
  }, [nameList, nameOrig])

  const getTitle = useCallback((short= false) => {
    return isNew 
      ? 'Creando nueva' 
      : luOrdinance.name
        ? short 
          ? luOrdinance.name.length>30
            ? luOrdinance.name.substr(0,30)+' ...'
            : luOrdinance.name
          : luOrdinance.name
        : ''
  }, [isNew, luOrdinance])

  const handleChange = (name, value) => {
    const nLuOrdinance= {...luOrdinance}

    if (['inspection_date','from_date','to_date','bulletin_date','icio_tax_rate',
         'lu_tax_rate','icio_fiscal_num','icio_license_num',
         'lu_fiscal_num','lu_license_num'].indexOf(name)>=0) {
      nLuOrdinance.tax_data= {
        ...nLuOrdinance.tax_data,
        [name]: value
      }
    } else {
      nLuOrdinance[name]= value
    }
    setLuOrdinance(nLuOrdinance)
  }

  const handleSave = async () => {
    // TODO hack this better
    luOrdinance['tax_code']= 'lu'
    const res= await fetcher.upsave('/api/ordinance', luOrdinance)
    if (isNew && res>=1) {
      setStatus(0)
      navigate(`/councils/${paramCouncilId}/lu/${res}`)
      await fetchOrdinance(res)
      setStatus(1)      
    }    
  }

  const handleCancel = () => {
    navigate(`/councils/${paramCouncilId}`)
  }
 

  return (
    <Page breads = {[['/', 'Inicio'], ['/councils', 'Ayuntamientos'], ['/councils/'+paramCouncilId, councilName], getTitle(true)]}
          title  = {`Ordenanza: ${getTitle()}`}
          actions= {<>
                      <ActionBack to={`/councils/${paramCouncilId}`} label="Al ayuntamiento"/>
                      <ActionGo to={`/councils/${luOrdinance.council_id}/lu/${luOrdinance.id}`} label="Licencias"/>
                    </>}>
      <Row>
        <Col xs="12">
        {
          status < 1 
          ? <LoadingBig/>
          : <LUOrdinanceDetailsForm
              luOrdinance      = {luOrdinance}
              existingNames    = {existingNames}
              onChange         = {(v,n) => handleChange(v,n)}
              onCancel         = {() => handleCancel()}
              onSave           = {() => handleSave()}
              isNew            = {isNew}
              permiss          = {permiss}
            />          
        }
        </Col>
      </Row>
    </Page>
  )
}


export default withContext(LUOrdinanceDetails)

