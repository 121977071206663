const PENALTY_TYPE= {
  ART_191: 1,
  ART_192: 2,
  ART_203: 3
}

const PENALTY_TYPE_NAMES= {
  [PENALTY_TYPE.ART_191]: 'Artículo 191 LGT',
  [PENALTY_TYPE.ART_192]: 'Artículo 192 LGT',
  [PENALTY_TYPE.ART_203]: 'Artículo 203 LGT',
}

const PENALTY_GROUPS= {
  '20': 'Art. 203 LGT',
  '19': 'Art. 191/192 LGT',
}

const PENALTY_19_RANK_RULE= {
  REPETITIVE_INFRACTION : 1,
  ECONOMICAL_LOSS       : 2,
  INVOICING_UNFULFILMENT: 3,
  SUBJECT_AGREE         : 4
}

const PENALTY_19_RANK_RULE_NAMES= {
  [PENALTY_19_RANK_RULE.REPETITIVE_INFRACTION ]: 'Comisión repetida de infracciones tributarias',
  [PENALTY_19_RANK_RULE.ECONOMICAL_LOSS       ]: 'Perjuicio económico para la Hacienda Pública',
  [PENALTY_19_RANK_RULE.INVOICING_UNFULFILMENT]: 'Incumplimiento sustancial de la obligación de facturación o documentación',
  [PENALTY_19_RANK_RULE.SUBJECT_AGREE         ]: 'Acuerdo o conformidad del interesado'
}

const PENALTY_INFRACTION_LEVEL= {
  SOFT   : 1,
  BAD    : 2,
  SERIOUS: 3
}

const PENALTY_INFRACTION_LEVEL_NAMES= {
  [PENALTY_INFRACTION_LEVEL.SOFT   ]: 'Leve',
  [PENALTY_INFRACTION_LEVEL.BAD    ]: 'Grave',
  [PENALTY_INFRACTION_LEVEL.SERIOUS]: 'Muy grave'  
}


const PENALTY_AGREED= {
  1: 'Conforme',
  0: 'Disconforme'
}

export {PENALTY_TYPE, PENALTY_TYPE_NAMES, PENALTY_GROUPS, PENALTY_19_RANK_RULE, PENALTY_19_RANK_RULE_NAMES, 
        PENALTY_INFRACTION_LEVEL, PENALTY_INFRACTION_LEVEL_NAMES, PENALTY_AGREED}