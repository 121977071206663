import React from 'react'
import ActionButton from './ActionButton'

const ActionDetails = ({ onClick, to, enabled, label }) =>
  <ActionButton color='blue'
    label={label}
    icon='eye'
    onClick={(e) => { if (onClick!=undefined) onClick(e) }}
    to={to}
    enabled={enabled != undefined ? enabled : true}>
  </ActionButton>

export default ActionDetails
