import React, {useState, useEffect, useCallback} from 'react'
import {useParams, useNavigate} from 'react-router-dom'


// components
import Page from 'cli/ui/pages/layout/Page'
import {Row, Col} from 'reactstrap'
import { ActionBack } from 'cli/ui/components/buttons/actions'
import { LoadingBig } from 'cli/ui/components/loading'
import RoleDetailsForm from './RoleDetailsForm'
import { IBox } from 'cli/ui/components/ibox'
import RoleDetailsPermissions from './RoleDetailsPermissions'
import withContext from 'cli/context/withContext'

let _BREADS= [['/', 'Inicio'], ['/settings', 'Configuración'], ['/settings/accontrol', 'Usuarios y perfiles'], ['/settings/accontrol/roles', 'Perfiles y permisos']]

const RoleDetails = ({fetcher}) => {

  const params= useParams()
  const navigate= useNavigate()
  
  const [role, setRole]= useState(undefined)
  const [nameList, setNameList]= useState([])
  const [nameOrig, setNameOrig]= useState('')
  const [permissionList, setPermissionList]= useState(undefined)
  
  const paramId = params.idRole
  const isNew = paramId == 'new'
  const title = isNew 
    ? 'Creando nuevo' 
    : role==undefined
    ? '...'
    : role.description||role.name

  const fetchRole = useCallback((roleId) => {
    const _fetchRole = async() => {

      const nNameList = await fetcher.name_list('/api/acc_role')
      setNameList(nNameList)
      if (roleId!=undefined) {
        const raw= await fetcher.get('/api/role/detail', {id: roleId})
        const nRole= raw.data.roles[0]
        setRole(nRole)
        setNameOrig(nRole.name)      
      }  else {
        setRole({
          admin: 0,
          protected: 0
        })
        setNameOrig('')      
      }
    }
    _fetchRole()
  }, [fetcher])

  const fetchPermissionList = useCallback(() => {
    const _fetchPermissionList = async() => {
      const nPermiss = await fetcher.read('/api/acc_permission')
      const nPermissList= {}
      for (const p of nPermiss) {
        const group = p.permission_group
        try {
          nPermissList[group].push(p)
        } catch(e) {
          nPermissList[group]= [p]
        }
      }
      setPermissionList(nPermissList)
      
    }
    _fetchPermissionList()
  }, [fetcher])



  useEffect(() => {
    async function fetchData() {
      await fetchRole(isNew ? undefined : paramId)
      await fetchPermissionList()
    }
    fetchData()
  }, [fetchRole, isNew, paramId, fetchPermissionList])

  const existingNames = () => {
    return nameList.filter((n) => n!=nameOrig)
  }

  const handleChange = (name, value) => {
    const nRole= {...role}
    nRole[name]= value
    setRole(nRole)
  }

  const handleSave = async () => {
    const res= await fetcher.upsave('/api/acc_role', role)
    if (isNew && res>=1) {
      navigate(`/settings/accontrol/roles/${res}`)
      await fetchRole(res)
    }    
  }

  const handleCancel = () => {
    navigate('/settings/accontrol/roles')
  }

  const handlePermissionToggle = async ( role_permission_id, role_id, permission_id, allowed )=> {

    if (allowed) {
      const permission= {
        acc_role_id: role_id,
        acc_permission_id: permission_id
      }
      const nPermId= await fetcher.upsave('/api/acc_role_permission', permission)

      if (nPermId>=1) {
        const nRole= {
          ...role,
          permissions: [
            ...role.permissions,
            {
              acc_role_permission_id: nPermId,
              ...permission
            }
          ]
        }
        setRole(nRole)
      } 
    } else {
      const res= await fetcher.remove('/api/acc_role_permission', role_permission_id)
      if (res>=1) {
        const nRolePermissions= role.permissions.filter(p => p.acc_role_permission_id!=role_permission_id)
        const nRole= {
          ...role,
          permissions: nRolePermissions
        }
        setRole(nRole)
      } 
    }
  }

  return (
    <Page breads={[..._BREADS, title]}
          title={`Perfil: ${title}`}
          actions={<ActionBack to={"/settings/accontrol/roles"} label="A Perfiles"/>}>
      <Row>
        
      <Col xs="12" md="6">
          <IBox title="Detalles">
            {
              role==undefined
              ? <LoadingBig/>
              :
                <RoleDetailsForm
                  role             = {role}
                  existingNames    = {existingNames()}
                  onChange         = {(v,n) => handleChange(v,n)}
                  onCancel         = {() => handleCancel()}
                  onSave           = {() => handleSave()}
                />
            }
          </IBox>
        </Col>        

        <Col xs="12" md="6">
          <IBox title="Permisos">
            {
              ( (role==undefined) || (permissionList==undefined) )
              ? <LoadingBig/>
              : <RoleDetailsPermissions
                    role = {role}
                    permissionList = {permissionList}
                    onPermissionToggle = {handlePermissionToggle}/>           
            }
          </IBox>
        </Col> 

      </Row>
    </Page>
  )
}


export default withContext(RoleDetails)
