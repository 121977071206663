import React, {useState} from 'react'
import {intre_from_str, intre_to_str, intre_now} from 'intre'
import {arrayBufferToBase64} from 'farrapa/encoding'
import { FForm, FInputDate, FInputSelect, FInputFile } from 'formiga-reactstrap'
import { DOCU_RECEPTION_MODES, DOCU_STATUSES } from 'bl/ns/process/process'
import Icon from 'cli/ui/components/icon'

const ProcessDocuAdd = ({onCancel, onSave, onDownload}) => {
  const [file, setFile]= useState({})
  const [docu, setDocu]= useState({
    name          : '',
    size          : undefined,
    dtype         : '',
    doc           : '',
    reception_date: intre_now(),
    reception_mode: 2,
    status        : 1
  })
  
  const handleDocChange = (n,v) => {
    const nDocu= {...docu}

    if (n=='doc') {
      setFile(v)
      nDocu.name = v.name
      nDocu.size = v.size
      nDocu.dtype= v.type
      nDocu.doc  = v.buffer ? arrayBufferToBase64(v.buffer) : ''
    } else {
      nDocu[n]= v
    }
    
    setDocu(nDocu)
  }

  return (
    <FForm onCancel     = {() => onCancel()} 
           onSave       = {() => onSave(docu)}>
            <>
              <FInputFile   name       = "docu"
                            label      = "Archivo"
                            value      = {file}
                            //required   = {true}
                            onChange   = {(v) => handleDocChange('doc', v)}
                            onDownload = {() => onDownload(docu)}
              />
              <FInputSelect name       = "status"
                            label      = "Estado"
                            icon       = {<Icon icon="file-zip"/>}
                            value      = {docu.status}
                            options    = {DOCU_STATUSES}
                            required   = {true}
                            feedback   = {'Seleccione un Estado'}
                            onChange   = {(v) => handleDocChange('status', v)}
              />
              <FInputSelect name       = "reception_mode"
                            label      = "Modo Recepción"
                            icon       = {<Icon icon="download2"/>}
                            value      = {docu.reception_mode}
                            options    = {DOCU_RECEPTION_MODES}
                            required   = {true}
                            feedback   = {'Seleccione un Modo de recepción'}
                            onChange   = {(v) => handleDocChange('reception_mode', v)}
              />
              <FInputDate   name       = "reception_date"
                            label      = "Recibido el"
                            value      = {intre_to_str(docu.reception_date)}
                            onChange   = {(v) => handleDocChange('reception_date', intre_from_str(v))}
                            locale     = 'es'
              /> 
            </>
    </FForm>
  )
}
export default ProcessDocuAdd
