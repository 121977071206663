
import {toPrice,toPriceN, toInt, toDecimal, toIntOrDecimal, sort} from '../../converters'

function makeIAECheckedRow(detail, coefficient, location_index) {
  return `
  <w:tr >
    <w:trPr>
      <w:trHeight w:val="25" />
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="267" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>${detail.year}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="460" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>${toPriceN(detail.checked_unit_price)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="507" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>${toInt(detail.checked_quantity)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="519" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>${toInt(detail.checked_area)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="523" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>${toPrice(detail.checked_fee_rate)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="378" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>${toDecimal(coefficient)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="472" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>${toPrice(detail.checked_weighted_rate)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="421" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>${toIntOrDecimal(location_index)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="574" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>${toPrice(detail.checked_increment_rate)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="438" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>${toPrice(detail.checked_province_rate)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="439" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="right" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:color w:val="333399" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>${toPrice(detail.checked_debt_total)}</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>
  `
}


function makeIAECheckedTable(tax) {


  const detailLines= sort(tax.details, 'year')
                     .map((detail) => makeIAECheckedRow(detail, tax.coefficient, tax.location_index))
                     .join('\n')

  return `
  <w:tbl>
  <w:tblPr>
    <w:tblW w:w="5879" w:type="pct" />
    <w:tblInd w:w="-714" w:type="dxa" />
    <w:tblBorders>
      <w:top w:val="single" w:sz="4" w:space="0" w:color="auto" />
      <w:left w:val="single" w:sz="4" w:space="0" w:color="auto" />
      <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />
      <w:right w:val="single" w:sz="4" w:space="0" w:color="auto" />
      <w:insideH w:val="single" w:sz="4" w:space="0" w:color="auto" />
      <w:insideV w:val="single" w:sz="4" w:space="0" w:color="auto" />
    </w:tblBorders>     
    <w:tblCellMar>
      <w:left w:w="70" w:type="dxa" />
      <w:right w:w="70" w:type="dxa" />
    </w:tblCellMar>
    <w:tblLook w:val="04A0" w:firstRow="1" w:lastRow="0" w:firstColumn="1" w:lastColumn="0" w:noHBand="0"
      w:noVBand="1" />
  </w:tblPr>
  <w:tblGrid>
    <w:gridCol w:w="540" />
    <w:gridCol w:w="941" />
    <w:gridCol w:w="1038" />
    <w:gridCol w:w="1065" />
    <w:gridCol w:w="1075" />
    <w:gridCol w:w="772" />
    <w:gridCol w:w="967" />
    <w:gridCol w:w="861" />
    <w:gridCol w:w="1181" />
    <w:gridCol w:w="896" />
    <w:gridCol w:w="897" />
  </w:tblGrid>
  <w:tr >
    <w:trPr>
      <w:trHeight w:val="25" />
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="5000" w:type="pct" />
        <w:gridSpan w:val="11" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:noWrap />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:ind w:left="-501" />
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t xml:space="preserve">Comprobados: Epígrafe ${tax.epigraph}</w:t>
        </w:r>
        <w:proofErr w:type="spellEnd" />
      </w:p>
    </w:tc>
  </w:tr>
  <w:tr >
    <w:trPr>
      <w:trHeight w:val="128" />
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="267" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>Años</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="460" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>Cuota o Elemento Tributario (precio por unidad)</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="507" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>Potencia instalada computable (kW)</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="519" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>Superficie Computable (cantidad m2)</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="523" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>Cuota Tarifa</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="378" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>Índice Cifra Negocio</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="472" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>Cuota Ponderada</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="421" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>Índice Situación</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="574" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p  
        >
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>Cuota Incrementada</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="438" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>Recargo provincial</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="439" w:type="pct" />
        <w:shd w:val="clear" w:color="FFFFFF" w:fill="FFFFFF" />
        <w:vAlign w:val="bottom" />
        <w:hideMark />
      </w:tcPr>
      <w:p 
        >
        <w:pPr>
          <w:jc w:val="center" />
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
        </w:pPr>
        <w:r >
          <w:rPr>
            <w:rFonts w:ascii="Arial" w:hAnsi="Arial" w:cs="Arial" />
            <w:b />
            <w:bCs />
            <w:color w:val="003366" />
            <w:sz w:val="16" />
            <w:szCs w:val="16" />
            <w:lang w:eastAsia="es-ES" />
          </w:rPr>
          <w:t>Deuda</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>
  ${detailLines}
</w:tbl>
  `
}

export {makeIAECheckedTable}