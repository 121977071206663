import React from 'react'
import ActionButton from 'cli/ui/components/buttons/actions/ActionButton'

const ActionTaxPayerMark = ({ onClick, marked }) =>
  <ActionButton color  = {marked ? '#B6E3E1' : '#558280'}
                label  = {''}
                icon   = {marked ? 'minus' : 'plus'}
                onClick= {(e) => { onClick(e) }}>
  </ActionButton>

export default ActionTaxPayerMark
