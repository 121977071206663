import React  from 'react'
import {Link} from 'react-router-dom';
import Icon from 'cli/ui/components/icon'


class ActionLink extends React.Component {

  render() {
    return (
      <div className="afi-action">
       <Icon icon={this.props.icon ? this.props.icon : 'circle-right'}/>
        <div>
          {this.props.to != undefined 
           ? 
              <Link to={this.props.to}>
                {this.props.children}
              </Link>
           :
              <a onClick={this.props.onClick}>
                {this.props.children}
              </a>
           }
        </div>
      </div>
    )
  }
}


export default ActionLink

