import {intre_noon, intre_to_str, intre_pretty_burocratic} from 'intre'
import { asDecimal, asPriceWithCurrency } from 'farrapa/numbers'
import { collSort } from 'farrapa/collections'


/**
 * 
 */
const _defDate = (e, def) => {
  if (e == undefined) {
    if (def != undefined) {
      if (def == 'today')
        return intre_noon()
    }
  }  
  return e
}
const dateLong = (e, def) => {//
  //
  // IF CURRENT YEAR: 
  //  16 de octubre del presente
  //
  e= _defDate(e,def)
  return intre_pretty_burocratic(e)
}
const dateShort = (e, def) => {
  e = _defDate(e, def)
  return intre_to_str(e)
}
const toDecimal = (p) => {
  return asDecimal(p, 2)
}
const toPrice = (p) => {
  return asPriceWithCurrency(p)
}

const toPriceN = (p) => {
  return `${p} €`
}

const toInt = (n) => {
  return `${parseInt(n)}`
}

const toIntOrDecimal = (n) => {
  if (isNaN(n)) {
    return ''
  }
  if (n == parseInt(n)) {
    return toInt(n)
  }
  return `{n}`
}

const sort = (data, by, order) => {
  return collSort(data, by, order)
}

const toUpperCase = (s) => s!=undefined ? s.toUpperCase() : ''
const toLowerCase = (s) => s!=undefined ? s.toLowerCase() : ''


function toTitleCase(str) {
  if (!str) return ''
  return str.replace(
      /\w\S*/g,
      function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
  );
}

function toFirstCap(str) {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
}

function escape(s) {
  if (!s || typeof(s)!='string') {
    return s
  }
  let lookup = {
      '&': "&amp;",
      '"': "&quot;",
      '<': "&lt;",
      '>': "&gt;"
  };
  return s.replace( /[&"<>]/g, (c) => lookup[c] );
}

export {dateLong, dateShort, toPrice, toPriceN, toInt,toDecimal, toIntOrDecimal, sort, toUpperCase, toLowerCase, toTitleCase, toFirstCap, escape}