import React from "react"
import CalendarEvent from './CalendarEvent'
import CalendarEventEmpty from './CalendarEventEmpty'
import CalendarEventMore from './CalendarEventMore'
import {intre_get_month, intre_get_day, intre_are_same} from 'intre'

const EVENTS_PER_DAY= 4

const CalendarBodyRowCell = ({day, events, month, selectedDate, hoveredEvent, onDateClick, onEventClick, onEventHover, onMoreClick}) => {
  const monthDay = intre_get_day(day)
  
  return (
    <div className={`col cell ${intre_get_month(day)!=month
                                ? "disabled"
                                : intre_are_same(day, selectedDate, 'day') 
                                  ? "selected" 
                                  : ""}`}
         onClick     = {(ev) => {
                          ev.stopPropagation()
                          onDateClick(day)
                        }}
         data-number = {monthDay}>
      <div className="cell_content">
        {Array(EVENTS_PER_DAY).fill().map((_,i) => {
          const ev= events.find((ev) => ev.position==(i+1))
          return ev==undefined
            ? <CalendarEventEmpty 
                             key      = {`calendar_day_${day}_empty_event_${i}`}/>
            : <CalendarEvent key      = {`calendar_day_${day}_event_${i}`}
                             day      = {day}
                             event    = {ev}
                             hovered  = {ev.id == hoveredEvent}
                             color    = {ev.color || ev.calendar_color}
                             onClick  = {onEventClick}
                             onHover  = {onEventHover}/>
        })}
        {events.length>EVENTS_PER_DAY
         ? <CalendarEventMore onClick = {() => onMoreClick(day)}
                              count   = {events?.length || 0}/>
         : null}
      </div>
    </div>
  )
}

export default CalendarBodyRowCell