import React, {useState, useEffect, useCallback} from 'react'

import {useParams, useNavigate} from 'react-router-dom'
import { b64toBlob, arrayBufferToBase64 } from 'farrapa/encoding'
import FileSaver from 'file-saver'

// components
import Page from 'cli/ui/pages/layout/Page'
import {Row, Col} from 'reactstrap'
import { ActionBack } from 'cli/ui/components/buttons/actions'
import { LoadingBig } from 'cli/ui/components/loading'
import DocDetailsForm from './DocDetailsForm'
import {DOC_NAMES,DOCS_FOR_PENALTY} from 'bl/ns/process/steps'

import useAlert from 'cli/ui/hooks/useAlert'
import withContext from 'cli/context/withContext'

const _isPenalty = (doc) => DOCS_FOR_PENALTY.indexOf(doc.process_doc)>=0

const DocDetails = ({fetcher}) => {
  const params= useParams()
  const navigate= useNavigate()

  const ualert= useAlert()
  const [docOptions, setDocOptions]= useState({})
  const [councilOptions, setCouncilOptions]= useState({})
  const [sectorOptions, setSectorOptions]= useState({})

  const [doc, setDoc]= useState({})
  const [status, setStatus]= useState(0)

  const [nameList, setNameList]= useState([])
  const [nameOrig, setNameOrig]= useState('')
  
  const [breads, setBreads]= useState([])
  const [title, setTitle]= useState('Documentos')


  const paramId = params.idDoc

  const isNew = paramId == 'new'

  const fetchDoc = useCallback((docId) => {
    const fetchDoc = async() => {
      const res = await fetcher.get('/api/doc/detail', {id: docId})
      const nDocs = res.data
      const nDoc= nDocs[0]
      setDoc(nDoc)
      setNameOrig(nDoc.name)    
      setStatus(1)
    }
    fetchDoc()
  }, [fetcher])


  useEffect(() => {
     const nTitle= isNew ? 'Creando nuevo' : doc.name||''
     setTitle(nTitle)

     setBreads(
        [['/', 'Inicio'], 
         ['/docs', 'Gestión Documental'], 
         _isPenalty(doc)
            ? ['/docs/penalty', 'Documentos de Sancionador']
            : ['/docs/inspect', 'Documentos de Inspección'],
         nTitle,
        ]       
     )
  }, [isNew, doc])

  useEffect(() => {
    async function fetchData() {
      const nDocOptions= await fetcher.key_list('/api/doc')
      setNameList(Object.values(nDocOptions))
      try {
        delete nDocOptions[paramId]
      } catch(e) {}
      setDocOptions(nDocOptions)

      const nCouncilOptions= await fetcher.key_list('/api/council')
      setCouncilOptions(nCouncilOptions)

      const nSectorOptions= await fetcher.key_list('/api/sector')
      setSectorOptions(nSectorOptions)

      if (isNew) {
        setDoc({
          name      : '',
          doc_id    : undefined,
          council_id: undefined,
        })
        setNameOrig('')
        setStatus(1)
      } else {
        await fetchDoc(paramId)
      }
      
    }
    fetchData()

  }, [fetcher, paramId, isNew, fetchDoc])

  const existingNames = () => {
    return nameList.filter((n) => n!=nameOrig)
  }

  const handleChange = (name, value) => {
    const nDoc= {...doc}
    if (name=='doc') {
      if (value.buffer) {
        value.buffer= arrayBufferToBase64(value.buffer)
      }
    }
    nDoc[name]= value
    setDoc(nDoc)
  }

  const handleSave = async () => {
    const res= await fetcher.upsave('/api/doc', doc)
    if (isNew && res>=1) {
      setStatus(0)
      navigate(`/docs/det/${res}`)
      await fetchDoc(res)
      setStatus(1)
    }
  }

  const handleCancel = () => {
    
    navigate(_isPenalty(doc) ? '/docs/penalty' : '/docs/inspect')
  }

  const handleDownload = async () => {
    try {
      const res = await fetcher.get('/api/doc/download_doc', {id: paramId})
      const doc = res.data
      const blob = b64toBlob(doc.buffer, doc.type)
      FileSaver.saveAs(blob, doc.name)    
            
    } catch(e) {
      ualert('Error descargando fichero', 'error')
      console.error(e)
    }
  }

  return (
    <Page breads={breads}
          title={title}
          actions={<ActionBack to={_isPenalty(doc) ? '/docs/penalty' : '/docs/inspect'} label="A documentos"/>}>
      <Row>
        <Col xs="12">
            {status < 1 
             ? <LoadingBig/>
             : 
                <DocDetailsForm
                    doc               = {doc}
                    existingNames     = {existingNames()}
                    processDocOptions = {DOC_NAMES}
                    docOptions        = {docOptions}
                    councilOptions    = {councilOptions}
                    sectorOptions     = {sectorOptions}
                    onChange          = {(n,v) => handleChange(n,v)}
                    onCancel          = {() => handleCancel()}
                    onSave            = {() => handleSave()}
                    onDownload        = {() => handleDownload()}
                />
            }
        </Col>

      </Row>
    </Page>
  )
}


export default withContext(DocDetails)

