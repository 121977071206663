import React from 'react'
import {intre_pretty_short} from 'intre'
import ProcessCloseForm from './ProcessCloseForm'
import { CLOSING_REASONS } from 'bl/ns/process/process'

const ProcessClose = ({process, onProcessClose, onCancel}) => {

  return (
    <div style={{border: "1px solid #c2cfd6", 
                 borderRadius: "0.5em",
                 padding: "1.5em",
                 margin: "1em 0",
                 default: undefined}}>
      {/* Title */}
      <div style={{margin: "1em"}}>
        <b style={{fontSize: "1.25em"}}>Cierre sin acta</b>
        {process.is_closed 
          ? <span style={{marginLeft: "2em"}}>{`Motivo: ${CLOSING_REASONS[process.closing_reason]} => ${process.closing_explanation}`}</span>
          : null
        }
      </div>

      {process.is_closed 
        ? <div>
          <i style={{color: "gray"}}>
            {process.closed_at
             ? `Cerrado el ${intre_pretty_short(process.closed_at)} `
             : ''
            } 
            {process.closed_by_name
             ? `por ${process.closed_by_name}`
             : ''
            }
          </i>
          </div>

        : <>
          {/* Form */}
          <ProcessCloseForm onProcessClose= {onProcessClose}
                        onCancel      = {onCancel}/>
        </>
      }

    </div>
  )


}

export default ProcessClose