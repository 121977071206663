import { COLOR_INSPECT_NEGATIVE } from 'bl/ns/process/colors'
import { COLOR_GROUPS } from 'bl/ns/process/colors'
import getProcessListCount from './getProcessListCount'
import makeColor from './makeColor'

const getProcessDone = (processes, what) => {
  if (what=='penalty_20') {
    return processes.filter((p) => p.penalty_20_on==true && (p.penalty_20_enabled===false || p.penalty_20.is_done==true)).length
  }
  if (what=='penalty_19') {
    return processes.filter((p) => p.penalty_19_on==true && (p.penalty_19_enabled===false || p.penalty_19.is_done==true)).length
  }
  return processes.filter((p) => p.inspect.is_done).length
}

const getProcessListCountInfo = (processes, what) => {
  
  const count= getProcessListCount(processes, what)  
  const done= getProcessDone(processes, what)  

  const status= `${done} / ${count}`

  const statusColor= count==0
                     ? 'lightgray'
                     : 'black'
  
  const progress= done*100 / count
  const color= makeColor('green', progress)

  return {
    status, statusColor, progress, color
  }

}

const getProcessListInfo = (processes, what) => {
  
  
  const info= getProcessListCountInfo(processes, what) 

  let amount= 0.0
  processes.map((p) => {
    if (! isNaN(p.amounts[what])) {
      amount+= p.amounts[what]
    }    
  })

  const amountColor= amount>0
                     ? COLOR_GROUPS[what]
                     : COLOR_INSPECT_NEGATIVE
  

  return {
    ...info,
    amount, 
    amountColor
  }

}



export {getProcessListInfo as default, getProcessListCountInfo}