import React, {useState, useEffect, useCallback} from 'react'
import {objFilter} from 'farrapa/objects'
import { PTYPE_NAMES } from 'bl/ns/process/process'

import {Container, Row, Col} from 'reactstrap'
import { ActionRemove, ActionAdd } from 'cli/ui/components/buttons/actions'
import {FForm, FInputText, FInputSelect, FInputSelectSearch} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'
import { HyperTaxPayer } from 'cli/ui/components/hyper'


const NewEditionStep2 = ({taxCode, edition, taxpayerList, onCreateTaxPayer, onAddProcess, onRemoveProcess}) => {
  const [editingProcess, setEditingProcess] = useState({})
  const [unavailableTaxPayers, setUnavailableTaxPayers]= useState([])
  const [taxpayerMap, setTaxpayerMap]= useState({})
  const [unavailableNames, setUnavailableNames]= useState([])


  

  const initProcess= useCallback(() => {
    setEditingProcess({
      taxpayer_id   : undefined,
      taxpayer_name : '',
      name          : '',
      process_type  : '',  //PTYPE.COMPLETE,
      tax_data      : undefined
    })

    const nUnavailableNames= edition.processes.map((p) => p.name)
    setUnavailableNames(nUnavailableNames)

    const nUnavailableTaxPayers= edition.processes
          .map((p) => p.taxpayer_id)
          .filter((tpid) => tpid!=undefined)
    setUnavailableTaxPayers(nUnavailableTaxPayers)    

    let nTaxpayerMap= {}
    taxpayerList.map((d) => {nTaxpayerMap[d.id]= d.name})
    setTaxpayerMap(nTaxpayerMap)

  }, [taxpayerList, edition.processes])

  useEffect(() => {
    initProcess()
  }, [initProcess])


  const handleChange = (changes) => {
    const nEditingProcess= {
      ...editingProcess,
      ...changes
    }
    setEditingProcess(nEditingProcess)
  }

  const handleChangeTaxPayer = (tpId, name) => {
    const licId= taxpayerList.find((tp) => tp.id==tpId)?.license_id
    const changes= {
      'taxpayer_id': tpId, 
      'taxpayer_name': name
    }
    if (licId) {
      changes['tax_data']= {
        'license_id': licId
      }
    }

    handleChange(changes)
  }


  const handleCreateTaxPayer = async (name) => {
    const tpId= await onCreateTaxPayer(name)
    handleChangeTaxPayer(tpId, name)
  }

  const handleAdd = () => {
    onAddProcess(editingProcess)
    initProcess()
  }

  const handleRemove = (taxpayer_id) => {
    onRemoveProcess(taxpayer_id)
    initProcess()
  }

  const renderProcessAddForm = () => {
    return (
      <FForm onCancel      = {undefined}
              onSave        = {undefined}
              renderButtons = {(valid, _readElements) => 
                <div style={{textAlign: "center", marginTop: "1em"}}>
                  <ActionAdd onClick= {() => handleAdd()} 
                              enabled= {valid}
                              label  = "Añadir expediente"/>
                </div>   
              }              
              >
          <>
            <FInputSelectSearch  
                        options     = {objFilter(taxpayerMap, (k,_v) => unavailableTaxPayers.indexOf(k)==-1)}
                        name        = 'taxpayer_id'
                        icon        = {<Icon icon={"taxpayer"}/>}
                        value       = {editingProcess.taxpayer_id}
                        onChange    = {(v) => handleChangeTaxPayer(v, taxpayerMap[v])}
                        feedback    = {"Seleciona un Contribuyente"}
                        clearable   = {true}
                        searchable  = {true}
                        required    = {true}
                        disallowedValues={unavailableTaxPayers}
                        autocomplete= {"off"}
                        creatable   = {true}
                        onCreate    = {(name) => handleCreateTaxPayer(name)}
            />
            <FInputSelect 
                        options     = {PTYPE_NAMES[taxCode]}
                        name        = 'process_type'
                        value       = {editingProcess.process_type}
                        onChange    = {(v) => handleChange({'process_type': v})}
                        feedback    = {"Selecciona tipo de expediente"}
                        clearable   = {true}
                        required    = {true}
                        autocomplete= {"off"}
            />
            <FInputText  
                        name        = 'name'
                        feedback    = 'Nº Expediente'
                        value       = {editingProcess.name}
                        onChange    = {(v) => handleChange({'name': v})}
                        required    = {true}
                        autocomplete= {"off"}
                        disallowedValues={unavailableNames}
            />
          </>
        </FForm>
    )
  }

  const renderProcessResume = () => {
    return (
      <div className="ned-table">
          <div className="table-row header">
            <div>Contribuyente</div>
            <div>Tipo</div>
            <div>Nº Expediente</div>
            <div className="actions"></div>
          </div>

          {edition.processes.map((p) => 
            <div className="table-row" key={'newedition_proc_list_' + p.taxpayer_id}>
              <div>
                <HyperTaxPayer id={p.taxpayer_id} name={p.taxpayer_name}/>
              </div>
              <div>
                {PTYPE_NAMES[taxCode][p.process_type]}
              </div>
              <div>
                {p.name}
              </div>
              <div className="actions">
                <ActionRemove onClick={() => handleRemove(p.taxpayer_id)}/>
              </div>
            </div>
          )} 
      </div>
    )
  }

  return (

    <Container fluid>
      <Row>
        <Col sm="12" md="6">
          {renderProcessAddForm()}
        </Col>

        <Col sm="12" md="6">
          {renderProcessResume()}
        </Col>

      </Row>

    </Container>
  )
}


export default NewEditionStep2
