import React, {useState, useEffect} from 'react'


// Components
import Page          from 'cli/ui/pages/layout/Page'
import {Row, Col}    from 'reactstrap'
import { ActionAdd }   from 'cli/ui/components/buttons/actions'
import {LoadingBig}    from 'cli/ui/components/loading'
import UsersList    from './UsersList'
import { arrayRemove } from 'farrapa/collections'
import withContext from 'cli/context/withContext'

// breads
const _BREADS = [['/', 'Inicio'], ['/settings', 'Configuración'], ['/settings/accontrol', 'Usuarios y perfiles'], ['Usuarios']]

const Users = ({fetcher}) => {

  const [users, setUsers]= useState([])
  const [status, setStatus]= useState(0)

  useEffect(() => {
    async function fetchData() {
      const raw= await fetcher.get('/api/user/detail')
      setUsers(raw.data.users)
      setStatus(1)
    }
    fetchData()

  }, [fetcher])

  const handleRemove = async (userId) => {
    const done = await fetcher.remove('/api/acc_user', userId)
    if (done) {
      let nusers= [...users]
      arrayRemove(nusers,
        nusers.find((c) => c.id==userId)
      )
      setUsers(nusers)
    }
    return done
  }

  return (
    <Page breads={_BREADS}
          title={"Usuarios"}
          actions={<ActionAdd to="/settings/accontrol/users/new" label="Crear nuevo"/>}>
      <Row>
        <Col xs="12">
            {status < 1 ?
              <LoadingBig /> :
              <UsersList users={users}
                            onRemove={(cid) => handleRemove(cid)}/>
            }
        </Col>
      </Row>
    </Page>
  )
}


export default withContext( Users)








