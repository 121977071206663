import React from 'react'
import Icon from 'cli/ui/components/icon'

const ActionUrl = ({ url, enabled }) =>
  <a className = {`afi-action-button ${enabled === false ? 'disabled' : ''}`}
     style     = {{color: 'gray', textDecoration: 'none'}}
     href      = {url}>
    <Icon icon = {'url'} 
          color= {'gray'}/>
  </a>



export default ActionUrl
