import React from 'react'
import Icon from 'cli/ui/components/icon'
import { HyperTaxPayer, HyperCouncil } from 'cli/ui/components/hyper'
import {PTYPE, PTYPE_NAMES} from 'bl/ns/process/process'
import {PENALTY_TYPE, PENALTY_TYPE_NAMES} from 'bl/ns/process/penalty'

const processTypeColor = (process) =>
  process.process_type == PTYPE.COMPLETE ? 'blue' : 'lightblue'

const ProcessResume = ({process}) =>
  <>
    <div>
      <div style={{ display: "inline" }}>
        {process.council_letterhead===true
          ? <Icon icon="shield" color="gray"></Icon>
          : null
        }  
        <HyperCouncil id  ={process.council_id}
                      name={process.council_name} />
      </div>
      <div style={{ display: "inline", marginLeft: "1em" }}>
        <HyperTaxPayer id  ={process.taxpayer_id}
                      name={process.taxpayer_name} />
      </div>
    </div>

    <div>
      <div style={{color: processTypeColor(process) , display: "inline-block"}}>
        <Icon icon  = {process.process_type == 1 ? 'search-plus' : 'search-minus'}
              inline= {true}
              color = {processTypeColor(process)}/>
        <b>{PTYPE_NAMES[process.tax_code][process.process_type]}</b>&nbsp;
      </div>
      <div style={{display: "inline-block", marginLeft: "2em"}}>
        <i>{"Ejercicios: "}</i>{process.year_from + " -> " + process.year_to}
      </div>  
    </div>

    <div style={{paddingTop: "0.5em"}}>
      {(process.minutes_agreed!=undefined && process.minutes_serial!=undefined)
       ? <>
           <i>{process.inspect.minutes.name}</i> <b>{process.inspect.serial_text}</b> {process.minutes_serial_year ? `(${process.minutes_serial_year})` : ''}
        </>
      : null}
      
      {process.has_penalty===true
      ? <>
         
          <i>{" // Sanción "}</i>
          {(process.penalty_20_on===true && process.penalty_20_serial!=undefined)
            ? <><i>{PENALTY_TYPE_NAMES[PENALTY_TYPE.ART_203]}</i> <b>{process.penalty_20_serial}</b></>
            : null
          }
          &nbsp;
          {(process.penalty_19_on===true && process.penalty_19_serial!=undefined)
            ? <><i>{PENALTY_TYPE_NAMES[process.penalty_19_type]}</i> <b>{process.penalty_19_serial}</b></>
            : null
          }            
        </>
      : null
      }
    </div>
  </>

export default ProcessResume