import React from 'react'
import {getProcessListCountInfo} from 'bl/util/process/getProcessListInfo'
import ProgressBarEmpty from 'cli/ui/fragments/process/fields/progress/bar/ProgressBarEmpty'
import ProgressBar from 'cli/ui/fragments/process/fields/progress/bar/ProgressBar'

const TITLES= {
  inspect: 'INS',
  penalty_20: '203',
  penalty_19: '191/2'
}

const ProcessListCount = ({processes, what}) => {
  if (processes.length==0) {
    return (
      <ProgressBarEmpty/>
    )
  }
  
  const i= getProcessListCountInfo(processes, what)

  return (
    <ProgressBar left = {i.status}
                 leftColor= {i.statusColor}
                 //title = {TITLES[what]}
                 right = {TITLES[what]}
                 rightColor= {'lightgray'}
                 progress = {i.progress}
                 color = {i.color}
                 />
  )
}


export default ProcessListCount