import React from 'react'
import { hydrateRoot } from 'react-dom/client'
import App from 'cli/App'

// Import styles for this application
import 'cli/static/scss/style.scss'

hydrateRoot(
  document.getElementById('root'),
  <App/>)

if (module.hot) {
  module.hot.accept();
}
