import React from 'react'
import Hyper from './base'

const HyperProcess = ({ taxCode, id, name, editionId }) =>
  <Hyper bcolor  = "#cce0ff"
         icon    = {"process"}
         to      = {`/taxes/${taxCode}/edition/${editionId}/process/${id}`}
         text    = {name}
         permissionCode = "process_read">
  </Hyper>

export default HyperProcess
