const FLOW_STEPS= {
  OPEN            :  1,
  START           :  2,
  FIRST_DLG       :  3,
  SECOND_REQ      :  4,
  SECOND_DLG      :  5,
  THIRD_REQ       :  6,
  THIRD_DLG       :  7,
  LIQ_PROPOSAL    :  8,
  MINUTES         :  9,
  LIQ_APPROVAL    : 10,
  PAYMENT         : 11,
  NOTIFY          : 12,
  APPEAL          : 13
}

//const STEP_CLOSED= 14

const PENALTY_20_STEPS= {
  OPEN            : 21,
  START           : 22,
  REPORT          : 23,
  PAYMENT         : 24,
}

const PENALTY_19_STEPS= {
  OPEN            : 31,
  START           : 32,
  REPORT          : 33,
  PAYMENT         : 34
}

const DOC= {
  OPEN                  :  1,
  START                 :  2,
  RIGHTS                :  3,
  PROVIDE_DLG           :  4,
  NOT_PROVIDE_DLG       :  5,
  SECOND_REQ            :  6,
  THIRD_REQ             :  7,
  LIQ_PROPOSAL          :  8,
  MINUTES_AGREE         :  9,
  MINUTES_DISAGREE      : 10,
  MINUTES_DISAGREE_AMP  : 11,
  LIQ_APPROVAL_DISAGREE : 12,
  NOTIFY                : 13,
  PAYMENT               : 14,

  PENALTY_OPEN          : 21,
  PENALTY_START         : 22,
  PENALTY_REPORT_RESOL  : 23,
  PENALTY_NOTIF_RESOL   : 24,
  PENALTY_DOC_RESOL     : 25,
  PENALTY_REPORT_LEGAL  : 26,
  PENALTY_PAYMENT       : 27,

}

const DOCS_FOR_PENALTY = [
  DOC.PENALTY_OPEN        ,
  DOC.PENALTY_START       ,
  DOC.PENALTY_REPORT_RESOL,
  DOC.PENALTY_NOTIF_RESOL ,
  DOC.PENALTY_DOC_RESOL   ,
  DOC.PENALTY_REPORT_LEGAL,
  DOC.PENALTY_PAYMENT
]

const DOC_NAMES = {
  [DOC.OPEN                 ]: 'Diligencia de Apertura',
  [DOC.START                ]: 'Inicio de Actuaciones Inspectoras',
  [DOC.RIGHTS               ]: 'Derechos y Deberes',
  [DOC.PROVIDE_DLG          ]: 'Diligencia de constancia de hechos',
  [DOC.NOT_PROVIDE_DLG      ]: 'Diligencia de no constancia de hechos',
  [DOC.SECOND_REQ           ]: 'Segundo requerimiento',
  [DOC.THIRD_REQ            ]: 'Tercer requerimiento',

  [DOC.LIQ_PROPOSAL         ]: 'Propuesta de Liquidación trámite de audiencia',
  [DOC.MINUTES_AGREE        ]: 'Acta de conformidad',
  [DOC.MINUTES_DISAGREE     ]: 'Acta de disconformidad',
  [DOC.MINUTES_DISAGREE_AMP ]: 'Informe ampliatorio al acta de disconformidad',

  [DOC.LIQ_APPROVAL_DISAGREE]: 'Aprobación de liquidación con disconformidad',
  [DOC.NOTIFY]               : 'Oficio de Requerimiento',
  [DOC.PAYMENT]              : 'Carta Pago',

  [DOC.PENALTY_OPEN]         : 'Diligencia de Apertura',
  [DOC.PENALTY_START]        : 'Acuerdo de inicio',
  [DOC.PENALTY_REPORT_RESOL] : 'Informe resolución',
  [DOC.PENALTY_NOTIF_RESOL]  : 'notificación resolución',
  [DOC.PENALTY_DOC_RESOL]    : 'resolución',
  [DOC.PENALTY_REPORT_LEGAL] : 'Informe oficio',
  [DOC.PENALTY_PAYMENT]      : 'Carta Pago',  
}

const SUB_STEP_DOC        = 1
const SUB_STEP_PROVIDE    = 2
const SUB_STEP_APPOINTMENT= 3
const SUB_STEP_ARGUMENTS  = 4
const SUB_STEP_AGREE      = 5
const SUB_STEP_SERIAL     = 6
const SUB_STEP_APPEAL     = 7
const SUB_STEP_ESTIMATE   = 8
const SUB_STEP_JUDICIAL   = 9
const SUB_STEP_ARGUMENTS_DATE  = 10
const SUB_STEP_AGREE_DATE      = 11

const getStepName = (stepId, process) => {
  const satisfiedName = (satisfied) => 
    satisfied==undefined
           ? 'Diligencia de constancia/no constancia de hechos'
           : satisfied===0
             ? 'Diligencia de no constancia de hechos'
             : 'Diligencia de constancia de hechos'
  
  const minutesName = () => 
    process.minutes_agreed==undefined
    ? 'Acta de conformidad / disconformidad'
    : process.minutes_agreed==true
      ? 'Acta de conformidad'
      : 'Acta de disconformidad'  

  const reportName = (parguments, pagreed) => 
    parguments==undefined
      ? 'Informe resolución / oficio'
      : parguments===true
        ? 'Informe resolución'
        : pagreed===false
          ?  'Informe resolución'
          : 'Informe oficio'


  switch (stepId) {
    case FLOW_STEPS.OPEN         : return 'Diligencia de Apertura'
    case FLOW_STEPS.START        : return 'Inicio de Actuaciones Inspectoras'
    case FLOW_STEPS.FIRST_DLG    : return satisfiedName(process.first_dlg_satisfied)
    case FLOW_STEPS.SECOND_REQ   : return 'Segundo requerimiento'
    case FLOW_STEPS.SECOND_DLG   : return satisfiedName(process.second_dlg_satisfied)
    case FLOW_STEPS.THIRD_REQ    : return 'Tercer requerimiento'
    case FLOW_STEPS.THIRD_DLG    : return satisfiedName(process.third_dlg_satisfied)
    case FLOW_STEPS.LIQ_PROPOSAL : return 'Propuesta de Liquidación trámite de audiencia'
    case FLOW_STEPS.MINUTES      : return minutesName()
    case FLOW_STEPS.LIQ_APPROVAL : return 'Aprobación de liquidación con disconformidad'
    case FLOW_STEPS.NOTIFY       : return 'Oficio de Requerimiento'
    case FLOW_STEPS.PAYMENT      : return 'Carta Pago'
    case FLOW_STEPS.APPEAL       : return 'Recurso de Reposición'

    case PENALTY_19_STEPS.OPEN      : return 'Diligencia de Apertura'
    case PENALTY_19_STEPS.START     : return 'Acuerdo de Inicio'
    case PENALTY_19_STEPS.REPORT    : return reportName(process.penalty_19_arguments, process.penalty_19_agreed)
    case PENALTY_19_STEPS.PAYMENT   : return 'Carta Pago'

    case PENALTY_20_STEPS.OPEN      : return 'Diligencia de Apertura'
    case PENALTY_20_STEPS.START     : return 'Acuerdo de Inicio'
    case PENALTY_20_STEPS.REPORT    : return reportName(process.penalty_20_arguments, process.penalty_20_agreed)
    case PENALTY_20_STEPS.PAYMENT   : return 'Carta Pago'        
    default:
      break;
  }
}


const getStepShortName = (stepId, process) => {
  const satisfiedName = (satisfied) => 
    satisfied==undefined
           ? 'No / Constancia'
           : satisfied===0
             ? 'No constancia'
             : 'Constancia'
  
  const minutesName = () => 
    process.minutes_agreed==undefined
    ? 'Acta'
    : process.minutes_agreed==true
      ? 'Acta conf.'
      : 'Acta disconf.'

  const reportName = (parguments, pagreed) => 
    parguments==undefined
      ? 'Res / Of'
      : parguments===true
        ? 'Resolución'
        : pagreed===false
          ?  'Resolución'
          : 'Oficio'


  switch (stepId) {
    case FLOW_STEPS.OPEN         : return 'Apertura'
    case FLOW_STEPS.START        : return 'Inicio'
    case FLOW_STEPS.FIRST_DLG    : return satisfiedName(process.first_dlg_satisfied)
    case FLOW_STEPS.SECOND_REQ   : return '2do req'
    case FLOW_STEPS.SECOND_DLG   : return satisfiedName(process.second_dlg_satisfied)
    case FLOW_STEPS.THIRD_REQ    : return '3er req'
    case FLOW_STEPS.THIRD_DLG    : return satisfiedName(process.third_dlg_satisfied)
    case FLOW_STEPS.LIQ_PROPOSAL : return 'Propuesta'
    case FLOW_STEPS.MINUTES      : return minutesName()
    case FLOW_STEPS.LIQ_APPROVAL : return 'Aprobación'
    case FLOW_STEPS.NOTIFY       : return 'Oficio Req'
    case FLOW_STEPS.PAYMENT      : return 'Pago'
    case FLOW_STEPS.APPEAL       : return 'Recurso'

    case PENALTY_19_STEPS.OPEN      : return 'Apertura'
    case PENALTY_19_STEPS.START     : return 'Inicio'
    case PENALTY_19_STEPS.REPORT    : return reportName(process.penalty_19_arguments, process.penalty_19_agreed)
    case PENALTY_19_STEPS.PAYMENT   : return 'Pago'

    case PENALTY_20_STEPS.OPEN      : return 'Apertura'
    case PENALTY_20_STEPS.START     : return 'Inicio'
    case PENALTY_20_STEPS.REPORT    : return reportName(process.penalty_20_arguments, process.penalty_20_agreed)
    case PENALTY_20_STEPS.PAYMENT   : return 'Pago'        
    default:
      break;
  }
}



export {FLOW_STEPS, PENALTY_20_STEPS, PENALTY_19_STEPS, DOC, 
        DOC_NAMES, SUB_STEP_DOC, SUB_STEP_PROVIDE, SUB_STEP_APPOINTMENT, 
        SUB_STEP_ARGUMENTS, SUB_STEP_AGREE, SUB_STEP_SERIAL, SUB_STEP_APPEAL, 
        SUB_STEP_ESTIMATE, SUB_STEP_JUDICIAL, SUB_STEP_ARGUMENTS_DATE, 
        SUB_STEP_AGREE_DATE, getStepName, getStepShortName, DOCS_FOR_PENALTY}

