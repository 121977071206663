const LU_LICENSE_FIELDS= {
  name             : 'Nº Licencia',
  cadastre_ref     : 'Referencia catastral',
  taxpayer_name    : 'Nombre Contribuyente',
  nif              : 'NIF',
  taxpayer_address : 'Dirección Contribuyente',
  work_type        : 'Tipo de Obra',
  work_description : 'Descripción Obra',
  work_address     : 'Dirección Obra',
  pem              : 'PEM',
  icio_provisional : 'ICIO provisional',
  lu_provisional   : 'LU provisional',
  is_taxpayer      : 'Es Contribuyente'
}
 
 
export {LU_LICENSE_FIELDS} 