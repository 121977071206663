import React from 'react'
import {Timeline} from 'cli/ui/components/timeline'
import ProcessStep from './steps/ProcessStep'


const ProcessInspect = (
  {process, agents, docs, onPrint, 
    onDocuDownload, onDocuSave, onDocuOpen, onDocuRemove, 
    onProcessChange, onProcessFileChange, onProcessGetNextSerial, canEdit, canPrint}) => {

  /*
  const autoScroll = () => {
    window.scrollTo(0,document.body.scrollHeight);
  }
  */

  /*useEffect(() => {
    autoScroll()      
  }, [events])*/

  return (
    <div>
      <Timeline disabled={ (process.is_closed)}>
        {process.inspect.steps.map((processStep, idx) => 
           <ProcessStep
              key            = {`process_inspect_step_${processStep.id}_${idx}`}
              process        = {process}
              processStep    = {processStep}
              agents         = {agents}
              docs           = {docs}
              onPrint        = {onPrint}
              onDocuDownload = {onDocuDownload}
              onDocuSave     = {onDocuSave}
              onDocuOpen     = {onDocuOpen}
              onDocuRemove   = {onDocuRemove}
              onProcessChange= {onProcessChange}
              onProcessFileChange={onProcessFileChange}
              onProcessGetNextSerial={onProcessGetNextSerial}
              canEdit={canEdit}
              canPrint={canPrint}
              />
        )}
      </Timeline>
    </div>
  )
}

export default ProcessInspect