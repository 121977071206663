import React from 'react'
import { ActionDetails, ActionRemove } from 'cli/ui/components/buttons/actions'
import NiceList from 'cli/ui/components/nice_list'
import { HyperSector } from 'cli/ui/components/hyper'
import {uvl} from 'farrapa/commons'

const sectorFields= [
  /* eslint react/display-name:0 */
  {
    label: "Sector",
    className: "main", 
    value: (sector) => sector.name,
    render: (sector) =>
      <HyperSector key={`sector_list_row_${sector.id}_id`} id={sector.id} name={sector.name}/>
  }, 
  {
    label: "Contribuyentes", 
    value: (sector) => uvl(sector.taxpayer_count,0)
  },
  {
    label: "Expedientes", 
    value: (sector) => uvl(sector.process_count,0)
  },
  {
    label: "Color",
    render: (sector) => <span style={{color: sector.color}}>&#9864;</span>
  },
  {
    label: "Acciones", 
    className: "right", 
    render: (sector, onEvent) => 
      <>
        <ActionDetails to={"/settings/sectors/" + sector.id} />
        <ActionRemove onClick={() => onEvent('remove', {id: sector.id})}
                      enabled={sector.taxpayer_count==0}/>
      </>
  }
]

const makeKey= (sector) =>
  `sector_list_row_${sector.id}`


const SectorsList = ({ sectors, onRemove }) =>
  <NiceList fields     = {sectorFields}
            initialSort= {[0, "asc"]}
            data       = {sectors}
            makeKey    = {makeKey}
            onEvent    = {(ev, params) => {
              if (ev=='remove') {
                onRemove(params.id)
              }
            }}
            transparent
  />

export default SectorsList
