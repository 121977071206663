import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

class Bread extends React.Component {
  render() {
    const { paths } = this.props

    let _links = []
    let _here = ''
    try {
      _links = paths.slice(0, paths.length - 1)
      _here = paths.slice(-1)
    } catch (e) { }

    return (
      <div className="afi_breadcrumb">
        <Breadcrumb>
          {_links.map((l, ii) =>
            <BreadcrumbItem key={`afi_breadcrumb_${ii}`}>
              <Link to={l[0]}>
                {l[1]}
              </Link>
            </BreadcrumbItem>
          )}
          <BreadcrumbItem key={_here} active>{_here}</BreadcrumbItem>
        </Breadcrumb>

      </div>
    );
  }
}

export default Bread;