import React from 'react'

const TimelineEventInfo = ({children}) => {
  if (! children) {
    return null
  }

  return (
    <div className="detail info">
      {children}
    </div>
  )
}

export default TimelineEventInfo