import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

import { arrayBufferToBase64 } from 'farrapa/encoding'

// components
import Page from 'cli/ui/pages/layout/Page'
import {Row, Col} from 'reactstrap'
import { LoadingBig } from 'cli/ui/components/loading'

import CouncilNewForm from './CouncilNewForm'
import withContext from 'cli/context/withContext'


let _BREADS= [['/', 'Inicio'], ['/councils', 'Ayuntamientos']]

const CouncilNew = ({fetcher}) => {
  const navigate= useNavigate()
  const [council, setCouncil]= useState({})
  const [status, setStatus]= useState(0)
  const [existingNames, setExistingNames]= useState([])
  
  useEffect(() => {
    async function fetchData() {
      const nExistingNames = await fetcher.name_list('/api/council')

      setExistingNames(nExistingNames)
      setStatus(1)
    }

    fetchData()

  }, [fetcher, council.name])

  
  const handleChange = (name, value) => {
    const nCouncil= {...council}

    if (name=='letterhead_doc') {
      if (value.buffer) {
        value.buffer= arrayBufferToBase64(value.buffer)
      }
    }

    nCouncil[name]= value
    setCouncil(nCouncil)
  }

  const handleSave = async () => {
    const res= await fetcher.upsave('/api/council', council)
    if (res>=1) {
      navigate(`/councils/${res}`)
    }
  }

  

  if (status==0) {
    return (
      <LoadingBig/>
    )
  }

  return (
    <Page breads={[..._BREADS, 'Nuevo']}
          title  = {`Ayuntamiento: Nuevo`}
          actions={null}>
      <Row>
        <Col xs="12">
          {
            status < 1 
            ? <LoadingBig/>
            : <CouncilNewForm
                                  council = {council}
                                  existingNames={existingNames}
                                  onChange= {(n,v) => handleChange(n,v)}
                                  onSave= {() => handleSave()}/>            
          }
        </Col>
      </Row>
    </Page>
  )
}


export default withContext(CouncilNew)
