import React from 'react'
import { Route } from 'react-router-dom'

import DocsIndex          from 'cli/ui/pages/docs/DocsIndex'
import Docs               from 'cli/ui/pages/docs/list/Docs'
import DocDetails         from 'cli/ui/pages/docs/det/DocDetails'

const DocRoutes = (permiss) => {
  if (!permiss.can_user_read_docs()) {
    return []
  }
  
  return [
    <Route key="docs_00" path={'/docs'} element={<DocsIndex/>}/>,
    <Route key="docs_01" path={'/docs/inspect'} element={<Docs docGroup="inspect"/>}/>,
    <Route key="docs_02" path={'/docs/penalty'} element={<Docs docGroup="penalty"/>}/>,
    <Route key="docs_03" path={'/docs/det/:idDoc'} element={<DocDetails/>}/>
  ]
}

export default DocRoutes

