import React, {useState, useEffect} from 'react'
import {FForm, FInputEpoch} from 'formiga-reactstrap'
import {SetAgree} from './parts/actions'


const EventAgreeDateForm = ({subStep, onChange}) => {
  const [agreeDate, SetAgreeDate]= useState(subStep.agree_date)
  
  useEffect(() => {
    SetAgreeDate(subStep.agree_date)
  }, [subStep])

  return (
    <FForm  inline
            renderButtons={(valid) => 
              <SetAgree onClick={() => onChange(agreeDate)} disabled={!valid}/>
            }>
                <FInputEpoch   name       = "agree_date"
                              value      = {agreeDate}
                              onChange   = {(v) => SetAgreeDate(v)}
                              icon       = {"Fecha de conformidad"}
                              required   = {true}
                              feedback   = {' '}
                              locale     = 'es'/>
    </FForm>
  )
}

export default EventAgreeDateForm
