import React from 'react'
import {asPrice, asDecimal} from 'farrapa/numbers'
import { arraySum } from 'farrapa/collections'
import TaxTable from '../common/TaxTable'
import TaxHeaderFields from'../common/TaxHeaderFields'
import {FInputFloatSum} from 'formiga-reactstrap'

const _atLeastZero = (a) => 
  Array.isArray(a)
    ? a.length==0
      ? [0.0]
      : a
    : [0.0]

const ProcessAETaxTable = ({ process, onChange, canEdit }) => {
  return (
    <>
      <TaxHeaderFields title="Declarados">
        <TaxTable className='afi_taxes_table'>
            <thead>
              <tr>
                <th>Año</th>
                <th className="right"><div>Cuota o Elemento Tributario</div><div>(precio por unidad)</div></th>
                <th className="right"><div>Elemento Tributario</div><div>(Cantidad)</div></th>
                <th className="right"><div>Elemento Tributario</div><div>Total</div></th>
                <th className="right"><div>Superficie Computable</div><div>(cantidad m2)</div></th>
                <th className="right">Importe Superficie</th>
                <th className="right">Índice Corrección</th>
                <th className="right">Cuota Tarifa</th>
                <th className="right">Indice Cifra Negocio</th>
                <th className="right">Cuota ponderada</th>
                <th className="right">Indice Situación</th>
                <th className="right">Cuota Incrementada</th>
                <th className="right">Recargo provincial</th>
                <th className="right">Deuda</th>
              </tr>
            </thead>
            <tbody>
              {process.tax.details.map((det) => 
              <tr key={'tax_det_' + process.tax.id + '_' + det.year} className="afi_taxes_det">
                  <td>{det.year}</td>
                  <td  className="right">
                    <FInputFloatSum name={`ae_tax_det_${det.year}_declared_unit_price`}
                                value={_atLeastZero(det.declared_unit_price_sum)}
                                onChange={(value, confirmed) => onChange(det.year, {declared_unit_price: arraySum(value), declared_unit_price_sum: value}, confirmed)}
                                decimalSign="."
                                decimals={5}
                                label={undefined}
                                description={undefined}
                                icon={false}
                                showValidity={0}
                                readOnly   = {!canEdit}
                                />
                  </td>
                  <td  className="right">
                    <FInputFloatSum name={`ae_tax_det_${det.year}_declared_quantity`}
                                value={_atLeastZero(det.declared_quantity_sum)}
                                onChange={(value, confirmed) => onChange(det.year, {declared_quantity: arraySum(value), declared_quantity_sum: value}, confirmed)}
                                decimalSign="."
                                decimals={5}
                                label={undefined}
                                description={undefined}
                                icon={false}
                                showValidity={0} 
                                readOnly   = {!canEdit}                               
                                />
                  </td>
                  <td  className="right"> 
                    {asDecimal(det.declared_tax_element, 5)}
                  </td>
                  <td  className="right">
                    <FInputFloatSum name={`ae_tax_det_${det.year}_declared_area`}
                                value={_atLeastZero(det.declared_area_sum)}
                                onChange={(value, confirmed) => onChange(det.year, {declared_area: arraySum(value), declared_area_sum: value}, confirmed)}
                                decimalSign="."
                                decimals={5}
                                label={undefined}
                                description={undefined}
                                icon={false}
                                showValidity={0}
                                readOnly   = {!canEdit}
                                />
                  </td>
                  <td  className="right"> 
                    {asDecimal(det.declared_area_fee)}
                  </td>
                  <td  className="right"> 
                    {asDecimal(det.declared_correct_index)}
                  </td>
                  <td  className="right"> 
                    {asPrice(det.declared_fee_rate, 2)}
                  </td>
                  <td  className="right"> 
                    {asDecimal(process.tax.coefficient)}
                  </td>
                  <td  className="right"> 
                    {asPrice(det.declared_weighted_rate)}
                  </td>
                  <td  className="right"> 
                    {asPrice(process.tax.location_index || 0)}
                  </td>
                  <td  className="right"> 
                    {asPrice(det.declared_increment_rate)}
                  </td>
                  <td  className="right"> 
                    {asPrice(det.declared_province_rate)}
                  </td>
                  <td  className="right"> 
                    {asPrice(det.declared_debt_total)}
                  </td>
              </tr> 
              )}
            </tbody>
            <tfoot>
              <tr>
              </tr>
            </tfoot>
          </TaxTable>
        </TaxHeaderFields>

        <TaxHeaderFields title="Comprobados">
          <TaxTable className='afi_taxes_table'>
            <thead>
              <tr>
                <th>Año</th>
                <th className="right"><div>Cuota o Elemento Tributario</div><div>(precio por unidad)</div></th>
                <th className="right"><div>Elemento Tributario</div><div>(Cantidad)</div></th>
                <th className="right"><div>Elemento Tributario</div><div>Total</div></th>
                <th className="right"><div>Superficie Computable</div><div>(cantidad m2)</div></th>
                <th className="right">Importe superficie</th>
                <th className="right">Índice Corrección</th>
                <th className="right">Cuota Tarifa</th>
                <th className="right">Indice Cifra Negocio</th>
                <th className="right">Cuota ponderada</th>
                <th className="right">Indice Situación</th>
                <th className="right">Cuota Incrementada</th>
                <th className="right">Recargo provincial</th>
                <th className="right">Deuda</th>
              </tr>
            </thead>
            <tbody>
              {process.tax.details.map((det) => 
              <tr key={'tax_det_' + process.tax.id + '_' + det.year} className="afi_taxes_det">
                  <td>{det.year}</td>
                  <td  className="right">
                    <FInputFloatSum name={`ae_tax_det_${det.year}_checked_unit_price`}
                                value={_atLeastZero(det.checked_unit_price_sum)}
                                onChange={(value, confirmed) => onChange(det.year, {checked_unit_price: arraySum(value), checked_unit_price_sum: value}, confirmed)}
                                decimalSign="."
                                decimals={5}
                                label={undefined}
                                description={undefined}
                                icon={false}
                                showValidity={0}
                                readOnly   = {!canEdit}                                
                                />
                  </td>
                  <td  className="right">
                    <FInputFloatSum name={`ae_tax_det_${det.year}_checked_quantity`}
                                value={_atLeastZero(det.checked_quantity_sum)}
                                onChange={(value, confirmed) => onChange(det.year, {checked_quantity: arraySum(value), checked_quantity_sum: value}, confirmed)}
                                decimalSign="."
                                decimals={5}
                                label={undefined}
                                description={undefined}
                                icon={false}
                                showValidity={0}
                                readOnly   = {!canEdit}
                                />
                  </td>
                  <td  className="right"> 
                    {asDecimal(det.checked_tax_element, 5)}
                  </td>
                  <td  className="right">
                    <FInputFloatSum name={`ae_tax_det_${det.year}_checked_area`}
                                value={_atLeastZero(det.checked_area_sum)}
                                onChange={(value, confirmed) => onChange(det.year, {checked_area: arraySum(value), checked_area_sum: value}, confirmed)}
                                decimalSign="."
                                decimals={5}
                                label={undefined}
                                description={undefined}
                                icon={false}
                                showValidity={0}
                                readOnly   = {!canEdit}
                                />
                  </td>
                  <td  className="right"> 
                    {asDecimal(det.checked_area_fee)}
                  </td>
                  <td  className="right"> 
                    {asDecimal(det.checked_correct_index)}
                  </td>
                  <td  className="right"> 
                    {asPrice(det.checked_fee_rate, 2)}
                  </td>
                  <td  className="right"> 
                    {asDecimal(process.tax.coefficient)}
                  </td>
                  <td  className="right"> 
                    {asPrice(det.checked_weighted_rate)}
                  </td>
                  <td  className="right"> 
                    {asPrice(process.tax.location_index || 0)}
                  </td>
                  <td  className="right"> 
                    {asPrice(det.checked_increment_rate)}
                  </td>
                  <td  className="right"> 
                    {asPrice(det.checked_province_rate)}
                  </td>
                  <td  className="right"> 
                    {asPrice(det.checked_debt_total)}
                  </td>
              </tr> 
              )}
            </tbody>
            <tfoot>
              <tr>
              </tr>
            </tfoot>
          </TaxTable>
        </TaxHeaderFields>



        <TaxHeaderFields title="Totales">
          <TaxTable className='afi_taxes_table'>
            <thead>
              <tr>
                <th>Año</th>
                <th className="right">Cuota Tarifa</th>
                <th className="right">Cuota ponderada</th>
                <th className="right">Recargo provincial</th>
                <th className="right">Cuota Comprobada</th>
                <th className="right">Cuota Declarada</th>
                <th className="right">Diferencia</th>
                <th className="right">Interés</th>
                <th className="right">Deuda</th>
              </tr>
            </thead>
            <tbody>
              {process.tax.details.map((det) => 
              <tr key={'tax_det_' + process.tax.id + '_' + det.year} className="afi_taxes_det">
                  <td>{det.year}</td>
                  <td  className="right"> 
                    {asPrice(det.total_fee_rate)}
                  </td>                
                  <td  className="right"> 
                    {asPrice(det.total_weighted_rate)}
                  </td>
                  <td  className="right"> 
                    {asPrice(det.total_province_rate)}
                  </td>
                  <td  className="right"> 
                    {asPrice(det.checked_debt_total)}
                  </td>
                  <td  className="right"> 
                    {asPrice(det.declared_debt_total)}
                  </td>
                  <td  className="right"> 
                    {asPrice(det.total_debt_difference)}
                  </td>
                  <td  className="right"> 
                    {asPrice(det.interest.interest)}
                  </td>
                  <td  className="right"> 
                    {asPrice(det.total_debt)}
                  </td>
              </tr> 
              )}
            </tbody>
            <tfoot>
              <tr>
              </tr>
            </tfoot>
          </TaxTable>
        </TaxHeaderFields>

    </>        
  )
}


export default ProcessAETaxTable




