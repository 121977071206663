import React from "react";
import Icon from 'cli/ui/components/icon'
import {intre_to_str} from 'intre'
const CalendarHeader = ({config, monthBounds, onPrevMonth, onNextMonth}) => {
  return (
    <div className="header row flex-middle">
      <div className="col col-start">
        <div className="icon icon-left" onClick={onPrevMonth}>
          <Icon icon="arrow-left"/>
        </div>
      </div>
      <div className="col col-center">
        <span>{intre_to_str(monthBounds.first, config.header.format)}</span>
      </div>
      <div className="col col-end">
        <div className="icon icon-right" onClick={onNextMonth}>
          <Icon icon="arrow-right"/>
        </div>
      </div>
    </div>
  )
}

export default CalendarHeader