import React from 'react'
import { Button } from 'cli/ui/components/buttons'

const TaxFooterSave = ({onClick}) => 
  <div style={{marginTop: "0.5em", textAlign: "center"}}>
    <Button icon="save" onClick={onClick}
            style   = {{backgroundColor: '#b3dbe8', color: 'black'}}>
      Guardar
    </Button>
  </div>

export default TaxFooterSave

