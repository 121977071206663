import React from 'react'
import {intre_from_str, intre_to_str} from 'intre'
import {FForm, FInputText, /*FInputFloat, */FInputDate, FInputFile} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'

const AEOrdinanceDetailsForm = ({ aeOrdinance, existingNames, onChange, onSave, onCancel, onDelete, isNew, permiss}) => 

  <FForm  onCancel     = {isNew ? onCancel : onDelete}
          labels       = {[isNew ? 'Cancelar' : 'Borrar', 'Guardar']}
          colors       = {[isNew ? 'secondary' : 'danger', 'primary']}
          onSave       = {permiss.can_user_edit_ordinances() ? onSave : undefined}>
          <>
            <FInputText name        = "name"
                        label       = "Nombre"
                        icon        = {<Icon icon={"ordinance"}/>}
                        value       = {aeOrdinance.name}
                        required    = {true}
                        checkvalue  = {(v) => existingNames.map((n) => n.toLowerCase()).indexOf(v.toLowerCase())==-1}
                        feedback    = {'Inserta un nombre válido y único'}
                        onChange    = {(v) => onChange('name', v)}
                        readOnly    = {!permiss.can_user_edit_ordinances()}
            />
            <FInputText name        = "title"
                        label       = "Título"
                        icon        = {<Icon icon={"text"}/>}
                        value       = {aeOrdinance.title}
                        placeholder = {'Inserta un título para los documentos'}
                        onChange    = {(v) => onChange('title', v)}
                        readOnly    = {!permiss.can_user_edit_ordinances()}
            />          
            {/*  
            <FInputDate   name        = "from_date"
                          label       = "Fecha Desde"
                          icon        = {"calendar"}
                          value       = {intre_to_str(aeOrdinance.tax_data?.from_date)}
                          onChange    = {(v) => onChange('from_date', intre_from_str(v))}
                        readOnly    = {!permiss.can_user_edit_ordinances()}
                                    locale      = 'es'
            />
            <FInputDate   name        = "to_date"
                          label       = "Fecha Hasta"
                          icon        = {"calendar"}
                          value       = {intre_to_str(aeOrdinance.tax_data?.to_date)}
                          onChange    = {(v) => onChange('to_date', intre_from_str(v))}
                        readOnly    = {!permiss.can_user_edit_ordinances()}
                                    locale      = 'es'
            />
            */}
            <FInputDate   name        = "bulletin_date"
                          label       = "Fecha Publicación Boletín"
                          icon        = "calendar"
                          value       = {intre_to_str(aeOrdinance.tax_data?.bulletin_date)}
                          onChange    = {(v) => onChange('bulletin_date', intre_from_str(v))}
                          readOnly    = {!permiss.can_user_edit_ordinances()}
                          locale      = 'es'
            />            
            {/*
            <FInputFloat name        = "icio_tax_rate"
                          label       = {"ICIO: Tipo Impositivo"}
                          icon        = {<Icon icon={"dollar"}/>}
                          decimalSign = ','
                          value       = {aeOrdinance.tax_data?.icio_tax_rate}
                          gt          = {0}
                          //required    = {true}
                          onChange    = {(v) => onChange('icio_tax_rate', v)}
                        readOnly    = {!permiss.can_user_edit_ordinances()}
            />
            <FInputText name        = "icio_fiscal_num"
                        label       = "ICIO: Nº Artículo"
                        icon        = {<Icon icon={"ordinance"}/>}
                        value       = {aeOrdinance.tax_data?.icio_fiscal_num}
                        onChange    = {(v) => onChange('icio_fiscal_num', v)}
                        readOnly    = {!permiss.can_user_edit_ordinances()}
            />
            <FInputText name        = "icio_license_num"
                        label       = "ICIO: Nº Licencia"
                        icon        = {<Icon icon={"file-zip"}/>}
                        value       = {aeOrdinance.tax_data?.icio_license_num}
                        onChange    = {(v) => onChange('icio_license_num', v)}
                        readOnly    = {!permiss.can_user_edit_ordinances()}
            />

            <FInputFloat name        = "ae_tax_rate"
                          label       = {"AE: Tipo Impositivo"}
                          icon        = {<Icon icon={"dollar"}/>}
                          decimalSign = ','
                          value       = {aeOrdinance.tax_data?.ae_tax_rate}
                          gt          = {0}
                          //required    = {true}
                          onChange    = {(v) => onChange('ae_tax_rate', v)}
                        readOnly    = {!permiss.can_user_edit_ordinances()}
            />
            <FInputText name        = "ae_fiscal_num"
                        label       = "AE: Nº Artículo"
                        icon        = {<Icon icon={"ordinance"}/>}
                        value       = {aeOrdinance.tax_data?.ae_fiscal_num}
                        onChange    = {(v) => onChange('ae_fiscal_num', v)}
                        readOnly    = {!permiss.can_user_edit_ordinances()}
            />
            <FInputText name        = "ae_license_num"
                        label       = "AE: Nº Licencia"
                        icon        = {<Icon icon={"file-zip"}/>}
                        value       = {aeOrdinance.tax_data?.ae_license_num}
                        onChange    = {(v) => onChange('ae_license_num', v)}
                        readOnly    = {!permiss.can_user_edit_ordinances()}
            />
            */}


            <FInputDate   name        = "inspection_date"
                          label       = "Fecha Aprobación P. Inspección"
                          icon        = {"calendar"}
                          value       = {intre_to_str(aeOrdinance.tax_data?.inspection_date)}
                          onChange    = {(v) => onChange('inspection_date', intre_from_str(v))}
                          readOnly    = {!permiss.can_user_edit_ordinances()}
                          locale      = 'es'
            />           
            <FInputFile   name        = "doc"
                          label       = "Documento"
                          value       = {aeOrdinance.doc}
                          onChange    = {(v) => onChange('doc', v)}
                          readOnly    = {!permiss.can_user_edit_ordinances()}
                          />
                          
            </>
  </FForm>

export default AEOrdinanceDetailsForm




