import React, {useState} from 'react'
import {FForm, FInputSelect, FInputText} from 'formiga-reactstrap'
import { PENALTY_TYPE, PENALTY_TYPE_NAMES } from 'bl/ns/process/penalty'
import {LU} from 'bl/ns/taxes'

const ProcessPenaltyInitForm = ({process, onProcessChange, onCancel}) => {
  const [penaltyType, setPenaltyType]= useState(undefined)
  const [serialText, setSerialText]= useState('')

  const handleSave = () => {
    let changes= {}
    if (penaltyType==PENALTY_TYPE.ART_203) {
      changes= {
        penalty_20_on: true,
        penalty_20_serial: serialText
      }
    } else {
      changes= {
        penalty_19_on: true,
        penalty_19_type: penaltyType,
        penalty_19_serial: serialText
      }
    }
    onProcessChange(changes)
  }

  const penaltyOptions = () => {
    const out= {}
    if (process.penalty_20_on==undefined) {
      out[PENALTY_TYPE.ART_203]= PENALTY_TYPE_NAMES[PENALTY_TYPE.ART_203]
    }
    if (process.tax_code != LU) {
      if (process.penalty_19_on==undefined) {
        out[PENALTY_TYPE.ART_191]= PENALTY_TYPE_NAMES[PENALTY_TYPE.ART_191]
        out[PENALTY_TYPE.ART_192]= PENALTY_TYPE_NAMES[PENALTY_TYPE.ART_192]
      }
    }
    return out
  }

  return (
    <FForm  
            onSave = {handleSave}
            onCancel={onCancel}>
            <>

              <FInputSelect 
                          options     = {penaltyOptions()}
                          name        = 'penalty_type'
                          value       = {penaltyType}
                          onChange    = {(v) => setPenaltyType(v)}
                          feedback    = {"Selecciona el tipo de sanción"}
                          clearable   = {true}
                          required    = {true}
                          autocomplete= {"off"}
                          label       = "Tipo de Sanción"
              />
              <FInputText
                          name        = 'serial_text'
                          value       = {serialText}
                          onChange    = {(v) => setSerialText(v)}
                          feedback    = {"Nº de Sanción"}
                          clearable   = {true}
                          required    = {true}
                          autocomplete= {"off"}
                          label       = "Nº de Sanción"
              />

            </>
    </FForm>
  )
}
export default ProcessPenaltyInitForm
