
import React from 'react'
import Hyper from './base'

const HyperSector = ({ id, name, color }) =>
  <Hyper bcolor  = {color || "#ecb3ff"}
         icon    = "sector"
         to      = {"/settings/sectors/" + id}
         text    = {name}
         permissionCode = "setts_sector_edit">
  </Hyper>

export default HyperSector
