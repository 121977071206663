import React, {useState} from 'react'
import {FForm, FInputPassword} from 'formiga-reactstrap'

const UserSettingsPasswordEdit = ({onCancel, onSave}) => {
  const [passwords, setPasswords]= useState({
    old   : '',
    new   : '',
    repeat: ''
  })

  const allOk = () => {
    if (passwords.old!='') {
      if (passwords.new!='') {
        if (passwords.new==passwords.repeat) {
          return true
        }
      }
    }
    return false
  }

  const handleChange = (n,v) => {
    const nPasswords= {...passwords}
    nPasswords[n]= v
    setPasswords(nPasswords)
  }

  const handleSave = (_) => {   
    if (allOk()) {
      onSave(passwords)
    } 
  }

  return (
    <FForm  onCancel     = {() => onCancel()}
            onSave       = {() => handleSave()}>
              <>
                <FInputPassword name       = "old"
                                label      = "Contraseña previa"
                                required   = {true}
                                value      = {passwords.old}
                                onChange   = {(v) => handleChange('old', v)}
                                feedback   = {"Introduce tu ontraseña actual"}
                />
                <FInputPassword name       = "new"
                                label      = "Contraseña nueva"
                                required   = {true}
                                value      = {passwords.new}
                                onChange   = {(v) => handleChange('new', v)}
                                feedback   = {"Nueva contraseña"}
                />       
                <FInputPassword name       = "repeat"
                                label      = "Repita la contraseña"
                                required   = {true}
                                doRepeat   = "new"
                                value      = {passwords.repeat}
                                onChange   = {(v) => handleChange('repeat', v)}
                                feedback   = {"Repita la nueva  contraseña"}
                /> 
              </>
    </FForm>

  )
}


export default UserSettingsPasswordEdit



