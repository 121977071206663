import React from 'react'
import ActionButton from './ActionButton'

const ActionAdd = ({ onClick, to, enabled, label }) =>
  <ActionButton color='green'
    label={label}
    icon='plus'
    onClick={onClick}
    to={to}
    enabled={enabled != undefined ? enabled : true}>
  </ActionButton>

export default ActionAdd
