import React from 'react'

const TaxHeaderFields = ({title, children}) => 
  <>
    <div style={{borderBottom: "0.5px dashed #ccc", marginTop: "1.5em"}}>
      <h4 style={{fontSize: "1.2em", color: "gray"}}>
        {title}
      </h4>
    </div>
    <div style={{marginTop: "0.5em"}}>
      {children}
    </div>   
  </>

export default TaxHeaderFields

