import React, {useState, useEffect, useCallback} from 'react'
import {useParams, useNavigate} from 'react-router-dom'


// components
import Page from 'cli/ui/pages/layout/Page'
import {Row, Col} from 'reactstrap'
import { ActionBack } from 'cli/ui/components/buttons/actions'
import { LoadingBig } from 'cli/ui/components/loading'
import LULicenseDetailsForm from './LULicenseDetailsForm'
import withContext from 'cli/context/withContext'

const shortIt = (s) => 
  s==undefined
  ? ''
  : s.length>30
    ? s.substr(0,30)+' ...'
    : s

const LicenseDetails = ({fetcher, permiss}) => {
  const params= useParams()
  const navigate= useNavigate()
  
  const [ordinance, setOrdinance]= useState(undefined)
  const [license, setLicense]= useState(undefined)
  const [nameList, setNameList]= useState([])
  const [nameOrig, setNameOrig]= useState('')  
  const [status, setStatus]= useState(0)

  const isNew = useCallback(() => {
    return params.idLicense == 'new'
  }, [params.idLicense])

  //
  // TODO correct council name
  //
  const getBreads = () => {
    if (status==0)
      return null
    if (isNew()) {
      return [['/', 'Inicio'], ['/councils', 'Ayuntamientos'], 
              [`/councils/${params.idCouncil}`, ordinance.council_name], 
              [`/councils/${params.idCouncil}/lu/${params.idOrdinance}`, shortIt(ordinance.name)], 
              'Nueva']
    } else {
      return [['/', 'Inicio'], ['/councils', 'Ayuntamientos'], 
              [`/councils/${params.idCouncil}`, license.council_name], 
              [`/councils/${params.idCouncil}/lu/${params.idOrdinance}`, shortIt(license.ordinance_name)], 
              shortIt(license.name)]
    }
  }

  const fetchLicense = useCallback(async (licenseId) => {
    const res = await fetcher.get('/api/lu/license/detail', {id: licenseId})
    const nLicenses = res.data
    const nLicense= nLicenses[0]
    setLicense(nLicense)   
    setNameOrig(nLicense.name) 
  }, [fetcher])

  useEffect(() => {
    async function fetchData() {
      const nNameList = await fetcher.name_list('/api/lu/license')
      setNameList(nNameList) 

      
      if (isNew()) {
        const res = await fetcher.get('/api/ordinance/detail', {id: params.idOrdinance})
        const nOrdinances = res.data
        const nOrdinance = nOrdinances[0]
        setOrdinance(nOrdinance)
        setLicense({
          ordinance_id: params.idOrdinance,
        })
        setNameOrig('')
      } else {
        await fetchLicense(params.idLicense)
      }
      setStatus(1)
    }
    fetchData()

  }, [fetchLicense, fetcher, isNew, params.idOrdinance, params.idLicense])


  const existingNames = () => {
    return nameList.filter((n) => n!=nameOrig)
  }  

  const handleChange = (name, value) => {
    const nLicense= {...license}
    nLicense[name]= value
    setLicense(nLicense)
  }

  const handleSave = async () => {
    const res= await fetcher.upsave('/api/lu/license', license)
    if (isNew() && res>=1) {
      setStatus(0)
      navigate(`/councils/${params.idCouncil}/lu/${params.idOrdinance}/license/${res}`)
      await fetchLicense(res)
      setStatus(1)
    }     
  }

  const handleCancel = () => {
    navigate(`/councils/${params.idCouncil}/lu/${params.idOrdinance}`)
  }

  return (
    <Page breads = {getBreads()}
          title  = {`Licencia: ${isNew() 
                                 ? 'Nueva' 
                                 : status==0
                                   ? '...'
                                   : license.name}`}
          actions= {
            <ActionBack to={`/councils/${params.idCouncil}/lu/${params.idOrdinance}`} label="A la ordenanza"/>
          }>
      <Row>
        <Col xs="12">
            {status < 1 
             ? <LoadingBig/>
             : <LULicenseDetailsForm
                license          = {license}
                existingNames    = {existingNames()}
                onChange         = {(v,n) => handleChange(v,n)}
                onCancel         = {() => handleCancel()}
                onSave           = {() => handleSave()}
                isNew            = {isNew()}
                permiss          = {permiss}
              />
            }
        </Col>

      </Row>
    </Page>
  )
}


export default withContext(LicenseDetails)

