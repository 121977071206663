import React, {useState, useEffect} from 'react'

import withContext from 'cli/context/withContext'

// components
import { ActionAdd } from 'cli/ui/components/buttons/actions'
import {LoadingBig}    from 'cli/ui/components/loading'

import UserSettingsEmailEdit from './UserSettingsEmailEdit'
import UserSettingsEmailList from './UserSettingsEmailList'
import { IBox } from 'cli/ui/components/ibox'
import { arrayRemove } from 'farrapa/collections'

const UserSettingsEmail = ({fetcher, user}) => {

  const [emailToEdit, setEmailToEdit] = useState(undefined)
  const [userEmails , setUserEmails ] = useState([]) 
  const [emailLoaded, setEmailLoaded] = useState(false)
  
  useEffect(() => {
    const fetchData= async() => {
      const res= await fetcher.get('/api/user_email/detail', { acc_user_id: user.id })
      const nUserEmails= res.data
      setUserEmails(nUserEmails)
      setEmailLoaded(true)
    }
    fetchData()

  }, [fetcher, user.id])


  const editEmailChannel= (id) => {
    setEmailToEdit(userEmails.find((e) => e.id==id))
  }

  const addEmailChannel= () => {
    setEmailToEdit({
      id          : undefined,
      acc_user_id : user.id,
      name        : '',
      email       : '',
      host        : '',
      port        : 993,
      tls         : true,
      password    : '',
      enabled     : false,
    })
  }

  const saveEmailChannel= async () => {
    await fetcher.upsave('/api/user_email', emailToEdit)
    const res= await fetcher.get('/api/user_email/detail', { acc_user_id: user.id })
    const nUserEmails= res.data
    setUserEmails(nUserEmails)
    setEmailToEdit(undefined)
  }

  const testEmail= async () => {
    const res= await fetcher.post('/api/user_email/test', emailToEdit)
    const msg= res.data
    return msg
  }

  const toggleEmailChannel= async (id) => {
    const nUserEmails= [...userEmails]
    const nUemail= nUserEmails.find((e) => e.id==id)
    nUemail.enabled= !nUemail.enabled
    
    await fetcher.upsave('/api/user_email', nUemail)
    setUserEmails(nUserEmails)
  }

  const removeEmailChannel= async (id) => {
    await fetcher.remove('/api/user_email', id)
    const nUserEmails= [...userEmails]
    arrayRemove(nUserEmails,
      nUserEmails.find((e) => e.id==id)
    )
    setUserEmails(nUserEmails)
  }

  const handleEmailChange = (n,v) => {
    const nEmailToEdit= {...emailToEdit}
    nEmailToEdit[n]= v
    setEmailToEdit(nEmailToEdit)
  }



  return (
    <IBox title= "Cuentas de correo"
          actions={emailToEdit!=undefined
                    ? null
                    : <ActionAdd key={'user_settings_email_add'}
                                    onClick={() => addEmailChannel()} label="Añadir una cuenta"/>
                   }>
      {!emailLoaded
        ? <LoadingBig/>
        : <>
            {emailToEdit!=undefined
              ? <UserSettingsEmailEdit onChange = {(n,v) => handleEmailChange(n,v)}
                                       onSave  = {() => saveEmailChannel()}
                                       onCancel= {() => setEmailToEdit(undefined)}
                                       onTest  = {() => testEmail()} 
                                       echannel= {emailToEdit}/>
              : userEmails.length==0
                ? <p>Todavía no ha configurado ninguna cuenta de correo</p>
                : <UserSettingsEmailList uemails = {userEmails}
                                         onToggle= {(id) => toggleEmailChannel(id)}
                                         onEdit  = {(id) => editEmailChannel(id)}
                                         onRemove= {(id) => removeEmailChannel(id)}/>
            }
          </>
      }
    </IBox>
  )
}



export default withContext(UserSettingsEmail)



