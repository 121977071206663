import React from 'react'
import { ActionDetails, ActionRemove, ActionDownload, ActionGo } from 'cli/ui/components/buttons/actions'
import NiceList from 'cli/ui/components/nice_list'
import {HyperDoc} from 'cli/ui/components/hyper'
import {I5, TAX_NAMES} from 'bl/ns/taxes'
import {PTYPE_NAMES, UNATT_REQUIREMENT} from 'bl/ns/process/process'
import {PENALTY_TYPE_NAMES, PENALTY_INFRACTION_LEVEL_NAMES, PENALTY_AGREED} from 'bl/ns/process/penalty'
import { IfPermission } from 'cli/context/IfPermission'

const DocConditions = ({doc}) => {
  const conditions= doc.conditions
  if (conditions==undefined) {
    return null
  }


  return (
    <>
      {conditions.tax_code!=undefined
       ? <>
           <i style={{color: "gray"}}>Tributo</i> <span>{TAX_NAMES[conditions.tax_code]}</span>
           &nbsp;
         </>
       : null
      }
      {conditions.process_type!=undefined
       ? <>
           <i style={{color: "gray"}}>Tipo Expte.</i> <span>{PTYPE_NAMES[doc.conditions.tax_code || I5][conditions.process_type]}</span>
           &nbsp;
         </>
       : null
      }
      {((conditions.penalty_type!=undefined) && (conditions.penalty_type.length>0))
       ? <>
           <i style={{color: "gray"}}>Sanción</i>
           &nbsp;
           <span>{conditions.penalty_type.map((pt) => PENALTY_TYPE_NAMES[pt]).join(', ')}</span>
           &nbsp;
         </>
       : null
      }
      {conditions.penalty_infraction!=undefined
       ? <>
           <i style={{color: "gray"}}>Infracción</i> <span>{PENALTY_INFRACTION_LEVEL_NAMES[conditions.penalty_infraction]}</span>
           &nbsp;
         </>
       : null
      }  
      {conditions.penalty_agreed!=undefined
       ? <>
           <i style={{color: "gray"}}>Conf.</i> <span>{conditions.penalty_agreed!=undefined ? PENALTY_AGREED[conditions.penalty_agreed===true ? 1 : 0] : ''}</span>
           &nbsp;
         </>
       : null
      }          
      {conditions.unatt_requirement!=undefined
       ? <>
           <i style={{color: "gray"}}>Req. no atendido</i> <span>{UNATT_REQUIREMENT[conditions.unatt_requirement]}</span>
           &nbsp;
         </>
       : null
      }          
      {conditions.council_id!=undefined
       ? <>
           <i style={{color: "gray"}}>Ayun.</i> <span>{doc.council_name}</span>
           &nbsp;
         </>
       : null
      }
      {conditions.sector_id!=undefined
       ? <>
           <i style={{color: "gray"}}>Sector.</i> <span>{doc.sector_name}</span>
         </>
       : null
      }             
             
    </>
  )
}

const docFields= [
  /* eslint react/display-name:0 */
  {
    label: "Versión",
    className: "main", 
    render: (doc) => <HyperDoc id={doc.id} name={doc.name}/>,
    value: (doc) => doc.name
  }, 
  {
    label: "Modelo Padre", 
    value: (doc) => doc.doc_name
  },
  {
    label: "Condiciones", 
    value: (doc) => <DocConditions doc={doc}/>
  },
  {
    label: "Notas", 
    value: (doc) => doc.notes
  },
  {
    label: "Acciones", 
    className: "right", 
    render: (doc, onEvent) => 
      <>
        <ActionDownload onClick={() => onEvent('download', {id: doc.id})}
                        enabled={doc.doc!={} && doc.doc.name!=null}/>
        <ActionDetails to={`/docs/det/${doc.id}`}/>
        <IfPermission code="docs_edit">
          <ActionGo onClick={() => onEvent('version', {id: doc.id})}
                    label = "Versionear"/>
          <ActionRemove onClick={() => onEvent('remove', {id: doc.id})}
                        enabled={doc.read_only != 1}/>
        </IfPermission>
      </>
  }
]

const makeKey= (doc) =>
  `doc_list_row_${doc.id}`


const DocsList = ({ docs,  onRemove, onVersion, onDownload }) =>
  <NiceList fields     = {docFields}
            initialSort= {[0, "asc"]}
            data       = {docs}
            makeKey    = {makeKey}
            onEvent    = {(ev, params) => {
              if (ev=='remove') {
                onRemove(params.id)
              }
              if (ev=='version') {
                onVersion(params.id)
              }
              if (ev=='download') {
                onDownload(params.id)
              }
            }}
            transparent
  />

export default DocsList








