import React from 'react'
import ActionButton from './ActionButton'

const ActionPayed = ({ onClick, enabled, label, checked }) =>
  <ActionButton color  = {checked ? 'green' : 'lightgray'}
                label  = {label}
                icon   = {'dollar'}
                onClick= {() => { onClick(!checked) }}
                enabled= {enabled != undefined ? enabled : true}>
  </ActionButton>
export default ActionPayed
