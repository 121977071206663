import React from 'react'
import { Link } from 'react-router-dom'
import Icon from 'cli/ui/components/icon'
import { shadeColor} from 'farrapa/colors'
import { IfPermission, IfNotPermission } from 'cli/context/IfPermission'

const _HyperContent = ({text, icon, color, bcolor}) => 
  <>
    <Icon icon   = {icon}
          inline = {true}
          color = {color || shadeColor(bcolor||'#797979', -0.4)}/>
    <span style = {{borderBottomColor: bcolor || '#797979'}}>
      {text}
    </span>
  </>


const Hyper = ({ to, text, icon, color, bcolor, maxWidth, permissionCode }) => {
  let s= {}
  if (maxWidth!=undefined)
    s.maxWidth= maxWidth
  
  return (
    <div className={"afi-hyper"} style={s}>
      <IfPermission code={permissionCode}>
        <Link to={to}>
          <_HyperContent icon   = {icon}
                color = {color}
                bcolor = {bcolor}
                text= {text}/>
        </Link>
      </IfPermission>

      <IfNotPermission code={permissionCode}>
          <_HyperContent icon   = {icon}
                color = {color}
                bcolor = {bcolor}
                text= {text}/>
      </IfNotPermission>
    </div>
  )
}
export default Hyper
