import React from 'react'
import {FForm, FInputText, FInputFile} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'

const TOOrdinanceDetailsForm = ({ ordinance, existingNames, onChange, onSave, onCancel, onDelete, isNew, permiss}) => 

  <FForm  onCancel     = {isNew ? onCancel : onDelete}
          labels       = {[isNew ? 'Cancelar' : 'Borrar', 'Guardar']}
          colors       = {[isNew ? 'secondary' : 'danger', 'primary']}
          onSave       = {permiss.can_user_edit_ordinances() ? onSave : undefined}>
          <>
            <FInputText name        = "name"
                        label       = "Nombre"
                        icon        = {<Icon icon={"ordinance"}/>}
                        value       = {ordinance.name}
                        required    = {true}
                        checkValue  = {(v) => existingNames.map((n) => n.toLowerCase()).indexOf(v.toLowerCase())==-1}
                        feedback    = {'Inserta un nombre válido y único'}
                        onChange    = {(v) => onChange('name', v)}
                        readOnly    = {!permiss.can_user_edit_ordinances()}
            />
            <FInputText name        = "title"
                        label       = "Título"
                        icon        = {<Icon icon={"text"}/>}
                        value       = {ordinance.title}
                        placeholder = {'Inserta un título para los documentos'}
                        onChange    = {(v) => onChange('title', v)}
                        readOnly    = {!permiss.can_user_edit_ordinances()}
            />           
            <FInputFile   name        = "doc"
                          label       = "Documento"
                          value       = {ordinance.doc}
                          onChange    = {(v) => onChange('doc', v)}
                          readOnly    = {!permiss.can_user_edit_ordinances()}
                          />
                          
            </>
  </FForm>

export default TOOrdinanceDetailsForm




