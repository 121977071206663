import React from 'react'
import Page          from 'cli/ui/pages/layout/Page'
import { ActionAdd, 
         ActionBack} from 'cli/ui/components/buttons/actions'
import CouncilsBase  from './CouncilsBase'
import { IfPermission } from 'cli/context/IfPermission'

// breads
const _BREADS = [['/', 'Inicio'], ['/councils', 'Ayuntamientos'], ['Histórico']]

const CouncilsHist = () => {
  
  return (
    <Page breads = {_BREADS}
          title  = "Ayuntamientos: Histórico"
          actions= {<>
                      <ActionBack label="Ver actuales"  to="/councils" />
                      <IfPermission code="council_edit">
                        <ActionAdd to="/councils/new" label="Crear nuevo"/>
                      </IfPermission>
                    </>}>
      <CouncilsBase historical={true}/>
    </Page>
  )
}




export default CouncilsHist








