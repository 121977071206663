import React, {useState, useEffect} from 'react'
import withContext from 'cli/context/withContext'
import {ACTION} from 'bl/ns/process/process'
import { ActionRemove, ActionChat, ActionUndo, ActionEdit, ActionDetails, ActionCloseProc, ActionPenalty} from 'cli/ui/components/buttons/actions'
import ProcessCalcsResume from './ProcessCalcsResume'
import ProcessActionsNotesModal from './ProcessActionsNotesModal'
import ProcessActionsCalendarModal from './ProcessActionsCalendarModal'
import { ActionCalendar } from 'cli/ui/components/buttons/actions'

const getUndoDescription = (process) => {
  if (process.undo==undefined || process.undo.length==0)
    return ''
  
  // TODO Show all descriptions?
  const undo_name= process.undo[0].name
  if (! undo_name)
    return ''

  switch(undo_name) {
    case ACTION.EDIT            : return '¿Deshacer el cambio de ejercicios / tipo de expediente?'
    case ACTION.APPOINTMENT_DATE: return '¿Revertir la fecha de firma?'
    case ACTION.INSPECTION_STEP : return '¿Deshacer el último paso en la Inspección?'
    case ACTION.CLOSE           : return '¿Reabrir expediente?'
    case ACTION.REOPEN          : return '¿Volver a cerrar expediente?'
    case ACTION.TAX_UPD         : return '¿Deshacer la modificación en el cálculo?'
    case ACTION.NOTE_ADD        : return '¿Borrar la última nota añadida?'
    case ACTION.NOTE_REM        : return '¿Revertir la nota eliminada?'
    case ACTION.DOCU_ADD        : return '¿Borrar el último documento añadido?'
    case ACTION.DOCU_REM        : return '¿Revertir el documento eliminado?'
    case ACTION.FILE_UPD        : return '¿Deshacer la última impresión?'
    case ACTION.OPEN_PENALTY    : return '¿Deshacer la apertura de proceso Sancionador?'
    case ACTION.PENALTY_TYPE    : return '¿Deshacer la cambio de tipo de Sanción?'
    case ACTION.PENALTY_STEP    : return '¿Deshacer el último paso en el Sancionador?'
    case ACTION.SERIAL          : return '¿Deshacer la asignación de Num de Acta?'
    case ACTION.PAYMENT_DATA    : return '¿Deshacer los cambios relativos a cobros/facturas?'
    default:
      return ''
  }
}  



const ProcessActions = (
  {user, process, event, onRemove, onSaveNote, onRemoveNote, onUndo, onEdit, 
    onClose, onProcessDebug, onProcessPenalty, canEdit}) => {
  const [showNotes, setShowNotes] = useState(false)
  const [showEvent, setShowEvent] = useState(false)
  const [undoDesc, setUndoDesc]= useState(getUndoDescription(process))

  useEffect(() => {
    setUndoDesc(getUndoDescription(process))
  }, [process])

  const toggleNotes = () => {
    setShowNotes(! showNotes)
  }

  const toggleEvent = () => {
    setShowEvent(! showEvent)
  }  


  return (  
    <div>
      <div>
        <div style={{ display: "inline" }}>
          <ActionChat onClick  = {() => setShowNotes(true)}
                      hasNotes = {(process.notes.length>0)}
                      label={"Notas"}/>
        </div>
        {(canEdit && undoDesc!='')
        ? <div style={{ display: "inline", marginLeft: "0.25em" }}>
              <ActionUndo onClick={() => onUndo()} 
                          description={undoDesc}/>
          </div>
        : null}
        {( (!canEdit) || process.is_closed)
         ? null
         : <>
            <div style={{ display: "inline", marginLeft: "0.25em" }}>
              <ActionCloseProc onClick={() => onClose()} label="Cerrar"/>
            </div>
            {!(process.penalty_20_on===true && process.penalty_19_on===true)
             ? <div style={{ display: "inline", marginLeft: "0.25em" }}>
                 <ActionPenalty onClick={() => onProcessPenalty()} label="Sanción"/>
               </div>
             : null}
          </>
        }

        {user.username=='devel@afialapis.com'
        ? <div style={{ display: "inline", marginLeft: "0.25em" }}>
            <ActionDetails onClick={onProcessDebug}
                           label="debug"/>
         </div>
        : null}        
        {event!=undefined
        ? <div style={{ display: "inline", marginLeft: "0.25em" }}>
            <ActionCalendar onClick={toggleEvent}
                            label="Próx. evento"/>
         </div>
        : null}               
        {canEdit
         ? <>
            <div style={{ display: "inline", marginLeft: "0.25em" }}>
              <ActionEdit onClick={() => onEdit()} />
            </div>   
            <div style={{ display: "inline", marginLeft: "0.25em" }}>
              <ActionRemove onClick={() => onRemove()}/>
            </div>
           </>
         : null}
      </div>         
      <div>           
        <ProcessCalcsResume process={process} />
      </div>

      <ProcessActionsNotesModal   title     = {`${process.name} : Notas`}
                                  show      = {showNotes}
                                  onClose   = {() => toggleNotes()} 
                                  onSave    = {(v) => onSaveNote(v)}
                                  onRemove  = {(v) => onRemoveNote(v)}
                                  notes     = {process.notes}/>    
      {event!=undefined
       ?
        <ProcessActionsCalendarModal title     = {`${process.name} : Próximo evento`}
                                    show      = {showEvent}
                                    onClose   = {() => toggleEvent()} 
                                    event     = {event}/>
       : null}
                                  

    </div>
  )
}

export default withContext(ProcessActions)
