import React from 'react'
import ActionButton from './ActionButton'

const ActionEdit = ({ onClick, to, enabled, label }) =>
  <ActionButton color='#6281aa'
    label={label}
    icon='pencil'
    onClick={(e) => { onClick(e) }}
    to={to}
    enabled={enabled != undefined ? enabled : true}>
  </ActionButton>

export default ActionEdit
