
import React from 'react'
import Hyper from './base'

const HyperRole = ({ id, name, color }) =>
  <Hyper bcolor  = {color || "rgb(115, 119, 181)"}
         icon    = "shield"
         to      = {"/settings/accontrol/roles/" + id}
         text    = {name}
         permissionCode = "setts_user_edit">
  </Hyper>

export default HyperRole
