import React from 'react'
import { ActionDetails } from 'cli/ui/components/buttons/actions'
import NiceList from 'cli/ui/components/nice_list'

const uemailFields= [
  /* eslint react/display-name:0 */
  {
    label: "Fecha", 
    value: (uemail) => uemail.mdate
  },
  {
    label: "De", 
    value: (uemail) => `${uemail.mfrom_name} (${uemail.mfrom_email})`
  },
  {
    label: "A", 
    value: (uemail) => uemail.mto
  },
  {
    label: "Asunto",
    render: (uemail) => uemail.subject
  },
  {
    label: "Leído",
    render: (uemail) => uemail.read ? 'Sí' : ''
  },
  {
    label: "Acciones", 
    className: "right", 
    render: (uemail, onEvent) => 
      <>
        <ActionDetails onClick={() => onEvent('preview', uemail.id)}/>
      </>
  }
]

const makeKey= (uemail) =>
  `uemail_list_row_${uemail.id}`


const UserEmailsList = ({ emailList, onPreview }) =>
  <NiceList fields     = {uemailFields}
            initialSort= {[0, "asc"]}
            data       = {emailList}
            makeKey    = {makeKey}
            onEvent    = {(ev, params) => {
              if (ev=='preview') {
                onPreview(params)
              }
            }}
            transparent
  />

export default UserEmailsList