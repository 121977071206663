import React from 'react'
import {useLocation} from 'react-router-dom'
import {Row, Col} from 'reactstrap'
import Page from 'cli/ui/pages/layout/Page'
import {MenuCard} from 'cli/ui/components/cards'
import {TAX_NAMES} from 'bl/ns/taxes'

const TaxIndex = () => {
  const location= useLocation()
  const taxCode= location.pathname.split('/')[2]

  return (
      <Page breads = {[['/', 'Inicio'], ['/taxes', 'Tributos'], [TAX_NAMES[taxCode]]]}
            title  = {TAX_NAMES[taxCode]}>
        <Row>
          <Col xs="12" sm="4"> 
            <MenuCard color    = "success"
                      icon     = "edition"
                      link     = {`/taxes/${taxCode}/edition`}
                      title    = "Vista General">
            </MenuCard>
          </Col>
          
          <Col xs="12" sm="4"> 
            <MenuCard color    = "info"
                      icon     = "plus"
                      link     = {`/taxes/${taxCode}/newedition`}
                      title    = "Nueva Tirada">
            </MenuCard>              
          </Col>        

          <Col xs="12" sm="4"> 
            <MenuCard color    = "warning"
                      icon     = "history"
                      link     = {`/taxes/${taxCode}/historical`}
                      title    = "Histórico">
            </MenuCard>              
          </Col>  
          {/*
          <Col xs="12" sm="4"> 
            <MenuCard color    = "secondary"
                      icon     = "search"
                      link     = {`/taxes/${taxCode}/search`}
                      title    = "Search">
            </MenuCard>              
          </Col>                       
          */}
        </Row>
      </Page>
  )
}


export default TaxIndex



