import React, {useState, useEffect} from 'react'


// Components
import Page          from 'cli/ui/pages/layout/Page'
import {Row, Col}    from 'reactstrap'
import { ActionAdd }   from 'cli/ui/components/buttons/actions'
import {LoadingBig}    from 'cli/ui/components/loading'
import AgentsList    from './AgentsList'
import { arrayRemove } from 'farrapa/collections'
import withContext from 'cli/context/withContext'

// breads
const _BREADS = [['/', 'Inicio'], ['/settings', 'Configuración'], ['Agentes']]

const Agents = ({fetcher}) => {
  const [agents, setAgents]= useState([])
  const [status, setStatus]= useState(0)

  useEffect(() => {
    async function fetchData() {
      const res = await fetcher.get('/api/agent/detail')
      const agents = res.data
      setAgents(agents)
      setStatus(1)
    }
    fetchData()

  }, [fetcher])

  const handleRemove = async (agentId) => {
    const done = await fetcher.remove('/api/agent', agentId)
    if (done) {
      let nagents= [...agents]
      arrayRemove(nagents,
        nagents.find((c) => c.id==agentId)
      )
      setAgents(nagents)
    }
    return done
  }

  return (
    <Page breads={_BREADS}
          title="Agentes"
          actions={<ActionAdd to="/settings/agents/new" label="Crear nuevo"/>}>
      <Row>
        <Col xs="12">   
          {status < 1 ?
            <LoadingBig /> :
            <AgentsList agents={agents}
                        onRemove={(cid) => handleRemove(cid)}/>
          }          
        </Col>
      </Row>
    </Page>
  )
}


export default withContext(Agents)








