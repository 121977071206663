import React, {useState, useEffect} from 'react'
import ButtonPair from 'cli/ui/components/buttons/ButtonPair'


const AvatarEdit = ({avatar, avatarList, onCancel, onSave}) => {


  const [innerAvatar, setInnerAvatar] = useState(avatar)

  useEffect(() => {
    setInnerAvatar(avatar)
  }, [avatar])


  const allOk = () => {
    if (innerAvatar!='' && innerAvatar!=avatar){
      return true
    }
    return false
  }  


  const imgsByGroups = (len= 4) => {
    let groups= [];
    let current = []
    avatarList.files.map((src, idx) => {
      current.push(src)

      if ((current.length == len) || (idx == (avatarList.length-1))) {
        groups.push(current)
        current = []
      }
      
    })
    return groups;
  }

  const avatarSelection = (src) => {
    const bgcolor = innerAvatar == src ? 'rgb(149, 149, 149)' : '#e0e0e0';
    return (
      <div style={{ display: "table-cell",
                    backgroundColor: bgcolor,
                    cursor: "pointer",
                    borderRadius: "2em"}} key={src}
          onClick={() => setInnerAvatar(src)}>
          <img style={{ width: "100%" }} src={avatarList.folder+src}></img>
      </div>)
  }


  return (
    <div>
      <div style={{ maxHeight: "200px", overflowY: "scroll", margin: "1em"}}>
        <div style={{display: "table", borderSpacing: "0.5em", borderCollapse: "separate"}}>
          {imgsByGroups().map((pair) => {
            return (
              <div style={{ display: "table-row" }} key={pair.join(',')}>
                {pair.map((src) => {
                  return avatarSelection(src)
                })}
              </div>
            )  
          })
          }  
        </div>                              
      </div>
      <ButtonPair onCancel={(e) => onCancel(e)}
                  onSave={() => onSave(innerAvatar)}
                  disabled={!allOk()}>
      </ButtonPair>

    </div>
  )

}

export default AvatarEdit



