import {uvl} from 'farrapa/commons'

const getSerialText = (process, what) => {
  if (what=='penalty_20') {
    return process.penalty_20_serial || ''
  }
  
  if (what=='penalty_19') {
    return process.penalty_19_serial || ''
  }

  return uvl(process.minutes_serial,-1)!=-1 ? process.inspect.serial_text : ''
}


export default getSerialText