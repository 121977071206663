import React, { useState } from 'react'
import { IBox } from 'cli/ui/components/ibox'
import { ActionEdit, ActionAdd, ActionBack } from 'cli/ui/components/buttons/actions'
import withContext from 'cli/context/withContext'

const EditingIBox = ({title, empty, actions, resume, form, fitHeight, permissionCode, permiss}) => {
  const [editMode, setEditMode]= useState(false)

  return (
    <>
      <IBox narrow
            title={title}
            empty={empty}
            fitHeight={fitHeight}
            actions={
                ((permissionCode==undefined) || (permiss.has_permission(permissionCode)))
                 ? 
                  editMode
                  ? <ActionBack label={undefined} onClick={() => setEditMode(false)}/>
                  : empty
                    ? <ActionAdd label={undefined} onClick={() => setEditMode(true)}/>
                    : <>
                        {actions!=undefined
                        ? actions()
                        : null}
                        <ActionEdit label={undefined} onClick={() => setEditMode(true)}/>
                      </>
                : null
            }>
        {editMode==false
         ? resume(() => setEditMode(true))
         : form(() => setEditMode(false))
        }
      </IBox>
    </>
  )
}


export default withContext(EditingIBox)