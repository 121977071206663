import React, {useCallback, useState} from 'react'
import {I5, TAX_NAMES} from 'bl/ns/taxes'
import {FInputSelect} from 'formiga-reactstrap'


const TaxSelect = ({taxCode, onChange, allowedValues, clearable= false}) => {
  const [innerTaxCode, setInnerTaxCode]= useState(taxCode || I5)

  const handleChange= useCallback((v) => {
    setInnerTaxCode(v)
    onChange(v)
  }, [onChange])
  
  return (
       <FInputSelect
                   options     = {TAX_NAMES}
                   name        = 'tax'
                   label       = {undefined}
                   icon        = {<span>Tributo</span>}
                   defaultValue= {innerTaxCode}
                   onChange    = {(v) =>  handleChange(v)}
                   showValidity= {0}
                   clearable   = {clearable}
                   allowedValues = {allowedValues}
                   searchable  = {false}/>
  )
}


export default TaxSelect
