import React, {useState, useEffect} from 'react'

import {FForm, FInputText, FInputUrl, FInputSelectSearch, FInputEmail} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'

import { SPANISH_PROVINCES } from 'bl/ns/geo'

import { LoadingBig } from 'cli/ui/components/loading'
import withContext from 'cli/context/withContext'


const TaxPayerDetailsDataForm = ({ fetcher, taxPayer, onChange, onSave}) => {
  
  const [status, setStatus]= useState(0)
  const [nameList, setNameList]= useState([])
  const [nameOrig, setNameOrig]= useState(taxPayer.name)

  
  useEffect(() => {
    async function fetchData() {
      const nNameList = await fetcher.name_list('/api/tax_payer')

      setNameList(nNameList)
      setNameOrig(taxPayer.name)
      setStatus(1)
    }

    fetchData()

  }, [fetcher, taxPayer.name])

  const existingNames= nameList.filter((n) => n!=nameOrig)

  if (status==0) {
    return (
      <LoadingBig/>
    )
  }
  
  return (
    <FForm onCancel     = {undefined}
          onSave       = {onSave}>
            <>
              <FInputText name        = "name"
                          label       = "Nombre"
                          icon        = {<Icon icon={"taxPayer"}/>}
                          value       = {taxPayer.name}
                          required    = {true}
                          checkValue  = {(v) => existingNames.map((n) =>  n.toLowerCase()).indexOf(v.toLowerCase())==-1}
                          feedback    = {'Inserta un nombre válido y único'}
                          onChange    = {(v) => onChange('name', v)}
              />
              <FInputSelectSearch  
                          options     = {SPANISH_PROVINCES}
                          name        = 'provincia'
                          label       = 'Provincia'
                          icon        = {<Icon icon={"map-signs"}/>}
                          value       = {taxPayer.province}
                          onChange    = {(v) => onChange('province', v)}
                          feedback    = {"Seleciona una Provincia"}
                          clearable   = {true}
                          searchable  = {true}
                          required    = {true}
              />
              <FInputText name        = "address"
                          label       = "Dirección"
                          icon        = {<Icon icon={"location"}/>}
                          value       = {taxPayer.address}
                          onChange    = {(v) => onChange('address', v)}
              />
              <FInputText name        = "nif"
                          label       = "NIF"
                          icon        = {<Icon icon={"profile"}/>}
                          value       = {taxPayer.nif}
                          onChange    = {(v) => onChange('nif', v)}
              />            
              <FInputUrl  name        = "deh"
                          label       = "Dirección Electrónica Habilitada"
                          value       = {taxPayer.deh}
                          icon        = {<Icon icon={"url"}/>}
                          feedback    = {'Inserta un email o una dirección web'}
                          onChange    = {(v) => onChange('deh', v)}
                          pattern     = {"^(https?://)?([a-zA-Z0-9]([a-zA-ZäöüÄÖÜ0-9-]{0,61}[a-zA-Z0-9])?.)+[a-zA-Z/]{2,6}$"}
              />
              <FInputEmail name       = "email"
                          label       = "E-Mail"
                          value       = {taxPayer.email}
                          icon        = {<Icon icon={"email"}/>}
                          feedback    = {'Inserta un email'}
                          onChange    = {(v) => onChange('email', v)}
              />

                                                   
            </>
    </FForm>
  )
}

export default withContext(TaxPayerDetailsDataForm)




