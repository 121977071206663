

import React        from 'react'
import ProcessStepContainer from './ProcessStepContainer'
import ProcessStepSubStep   from './ProcessStepSubStep'

const ProcessStep = (props) => 
  <ProcessStepContainer process = {props.process}
                         processStep = {props.processStep}
                          /*collapsed = {process.current_step.id != processStep.id}*/>

    {props.processStep.sub!=undefined
     ? 
      props.processStep.sub.map((subStep, idx) => 
        <ProcessStepSubStep key    ={`process_step_${props.processStep.id}_substep_${idx}`}
                            subStep= {subStep}
                            {...props}/>
      )
    : null}


   
  </ProcessStepContainer>
export default ProcessStep

