import {intre_pretty_medium, intre_get_month_name, intre_from_parts} from 'intre'

const getOrdinanceInfoI5 = (tax_data) => {

  const i5DateInfo= 
    tax_data?.inspection_date
    ? `Aprobado el ${intre_pretty_medium(tax_data.inspection_date)}`
    : 'Sin fecha de aprobación'

  const i5Interests=
    (tax_data?.liq_limit_day!=undefined && tax_data?.liq_limit_month!=undefined)
    ? `Inicio Intereses: ${tax_data.liq_limit_day} / ${intre_get_month_name( intre_from_parts(2020, tax_data.liq_limit_month, 1))}`
    : 'Sin inicio de intereses'

  return [
    {
      icon: 'calendar',
      text: i5DateInfo
    },
    {
      icon: 'calendar',
      text: i5Interests
    }      
  ]    
}

export default getOrdinanceInfoI5