
const COEFFICIENT_BY_REVENUES= [
 { from:         0.00, to:         0.00, coefficient: 1.31},
 { from:         0.01, to:    999999.99, coefficient: 0.00}, // TODO Check
 { from:   1000000.00, to:   5000000.00, coefficient: 1.29},
 { from:   5000000.01, to:  10000000.00, coefficient: 1.3 },
 { from:  10000000.01, to:  50000000.00, coefficient: 1.32},
 { from:  50000000.01, to: 100000000.00, coefficient: 1.33},
 { from: 100000000.00, to:     Infinity, coefficient: 1.35}  
]

const getCoefficientByRevenues = (revenues) => {
  for (let coeff of COEFFICIENT_BY_REVENUES) {
    if (revenues>=coeff.from && revenues<=coeff.to) {
      return coeff.coefficient
    }
  }
  return 0.0
}


const AREA_GROUPS= [
 {from:     0.00, to:   500.00, group: 1},
 {from:   500.01, to:  3000.00, group: 2},
 {from:  3000.01, to:  6000.00, group: 3},
 {from:  6000.01, to: 10000.00, group: 4},
 {from: 10000.01, to: Infinity, group: 5},
]

const getAreaGroupsIncr = (m2) => {
  const grps= []

  let remain= m2

  for (let area of AREA_GROUPS) {
    if (remain>0) {
      const toConsume= area.to - area.from     
      const q= Math.min(remain, toConsume)
      grps.push({q, group: area.group})
      remain-= q
    }
  }
  return grps
}


const DIVISION_GROUPS_FOR_AREA= {
  1: 'A',
  2: 'A',
  3: 'A',
  4: 'A',
  5: 'A',
  6: 'A',
  7: 'B',
  8: 'C',
  9: 'A',
}



const POPULATION_GROUPS= [
  {from:     0.00,  to:   5000.00, group: 1},
  {from:   5000.01, to:  20000.00, group: 2},
  {from:  20000.01, to:  50000.00, group: 3},
  {from:  50000.01, to: 100000.00, group: 4},
  {from: 100000.01, to: 500000.00, group: 5},
  {from: 500000.01, to:  Infinity, group: 6},  
]


const getPopulationGroup = (population) => {
  for (let popolo of POPULATION_GROUPS) {
    if (population>=popolo.from && population<=popolo.to) {
      return popolo.group
    }
  }
  return 1  
}



const AREA_FEE= {
  'A': [
    [0.042071, 0.102172, 0.204344, 0.330557, 0.504850, 0.721215],
    [0.036061, 0.078132, 0.150253, 0.252425, 0.390658, 0.558941],
    [0.030051, 0.066111, 0.126213, 0.210354, 0.312526, 0.444749],
    [0.030051, 0.060101, 0.108182, 0.174294, 0.270455, 0.384648],
    [0.024040, 0.048081, 0.090152, 0.150253, 0.234395, 0.330557]
  ],
  'B': [                      
    [0.036061, 0.078132, 0.144243, 0.246415, 0.378638, 0.540911],
    [0.030051, 0.060101, 0.114192, 0.192324, 0.288486, 0.414698],
    [0.024040, 0.054091, 0.096162, 0.156263, 0.240405, 0.336567],
    [0.024040, 0.048081, 0.084142, 0.132223, 0.204344, 0.288486],
    [0.018030, 0.042071, 0.072121, 0.120202, 0.186314, 0.258435]
  ],
  'C': [
    [0.096162, 0.240405, 0.468789, 0.781316, 1.196014, 1.706874],
    [0.078132, 0.186314, 0.366617, 0.601012, 0.919549, 1.310206],
    [0.060101, 0.144243, 0.288486, 0.480810, 0.739245, 1.045761],
    [0.054091, 0.126213, 0.246415, 0.408688, 0.625053, 0.895508],
    [0.048081, 0.108182, 0.216364, 0.348587, 0.540911, 0.769295]
  ]
}

const getAreaFee = (division, m2, population) => {
  const divGroup  = DIVISION_GROUPS_FOR_AREA[division]
  const areaGroups= getAreaGroupsIncr(m2)
  const popGroup  = getPopulationGroup(population)
 
  try {
    let fee= 0.0
    const base= AREA_FEE[divGroup]
    for (const areaGroup of areaGroups) {
      const factor= base[areaGroup.group-1][popGroup-1]
      fee+= areaGroup.q * factor
    }
    return fee
  } catch(e) {
    //console.error(e)
    //console.error(`Error on getAreaFee(${division}, ${m2}, ${population})`)
    return 0.0
  }
}



const DIVISION_GROUPS_FOR_CORRECT_INDEX= {
  1: 'A',
  2: 'A',
  3: 'A',
  4: 'A',
  5: 'A',
  6: 'A',
  7: 'A',
  8: 'B',
  9: 'A',
}



const CORRECT_INDEX = [
 {from:   37.32, to:  622.05,  'A': 1.0, 'B': 0.5},
 {from:  622.06, to: 1244.10,  'A': 1.5, 'B': 0.5},
 {from: 1244.11, to: 3110.24,  'A': 2.0, 'B': 1.0},
 {from: 3110.25, to: 6220.48,  'A': 2.5, 'B': 1.5},
 {from: 6220.48, to: Infinity, 'A': 3.0, 'B': 2.0}
]

const getCorrectIndexByActivity = (division, activity) => {
  const divGroup= DIVISION_GROUPS_FOR_CORRECT_INDEX[division]
  for (let cindex of CORRECT_INDEX) {
    if (activity>=cindex.from && activity<=cindex.to) {
      return cindex[divGroup]
    }
  }
  return 1    
}



export {getCoefficientByRevenues, getAreaFee, getCorrectIndexByActivity}
