import React, {useState, useEffect, useCallback} from 'react'
import {useParams, useLocation, useNavigate} from 'react-router-dom'

import {TAX_NAMES} from 'bl/ns/taxes'

// components
import Page from 'cli/ui/pages/layout/Page'
import {Row, Col} from 'reactstrap'
import { IBox } from 'cli/ui/components/ibox'
import { LoadingBig } from 'cli/ui/components/loading'
import { ActionSearch } from 'cli/ui/components/buttons/actions'
import { HyperCouncil, HyperOrdinance } from 'cli/ui/components/hyper'
import EditionDetailsProcessForm from './EditionDetailsProcessForm'
import EditionDetailsInfo from './EditionDetailsInfo'
import withContext from 'cli/context/withContext'




const EditionDetails = ({fetcher}) => {
  const params= useParams()
  const location= useLocation()
  const navigate= useNavigate()

  const [taxCode, setTaxCode]= useState(location.pathname.split('/')[2])
  const [breads, setBreads]= useState([])
  const [title, setTitle]= useState('')
  const [edition, setEdition]= useState(undefined)
  
  const paramId = params.idEdition

  // taxCode
  useEffect(() => {
    setTaxCode(location.pathname.split('/')[2])
  }, [location])

  // title and breads
  useEffect(() => {
    setBreads(
      [['/', 'Inicio'], 
      ['/taxes', 'Tributos'], 
      [`/taxes/${taxCode}`, TAX_NAMES[taxCode]], 
      [`/taxes/${taxCode}/edition`, 'Inspecciones'],
      edition?.name||''
     ]      
    )
    setTitle(edition?.name||'')
  }, [taxCode, edition])

  // Data fetcher
  const fetchData = useCallback(() => {
    async function _fetchData() {
      
      const res = await fetcher.get('/api/edition/detail_find', {id: paramId})
      const nEdition = res.data
      if (nEdition.id === undefined) {
        navigate(`/taxes/${taxCode}/edition`)
      }
      setEdition(nEdition)
    }
    _fetchData()    
  }, [fetcher, paramId, taxCode, navigate])

  // Fetch data on mount
  useEffect(() => {
    fetchData()
  }, [fetchData])
 
  const handleChangeEdition = useCallback((name, value) => {
    const nEdition= {...edition}
    nEdition[name]= value
    setEdition(nEdition)
  }, [edition])


  const handleProcessSave = async (addingProcess) => {
    const res= await fetcher.upsave('/api/process', addingProcess)
    if (res>=1) {
      const res = await fetcher.get('/api/edition/detail_find', {id: paramId})
      const nEdition = res.data
      setEdition(nEdition)
    }    
  }


  return (
    <Page breads = {breads}
          title  = {`Tirada: ${title}`}
          actions= {edition==undefined
                    ? null
                    : <>
                        <HyperCouncil id  ={edition.council_id} name={edition.council_name} />
                        <HyperOrdinance taxCode={taxCode}  id={edition.ordinance_id} councilId={edition.council_id} name={edition.ordinance_name}/>
                        <ActionSearch to={`/taxes/${taxCode}/edition/${edition.id}`} label="Ver expedientes"/>
                      </>
                    }>
      <Row>
       

        <Col xs="12" md="6">
          <IBox title="Detalles">
            {
              edition==undefined
              ? <LoadingBig/>
              : <EditionDetailsInfo edition ={edition}
                                    fetcher ={fetcher}
                                    history ={history}
                                    onChange={(n,v) => handleChangeEdition(n,v)}
                                    taxCode ={taxCode}/>              
            }
          </IBox>
        </Col>        

        <Col xs="12" md="6">
          <IBox title="Añadir un Expediente">
            {
              edition==undefined
              ? <LoadingBig/>
              : <EditionDetailsProcessForm 
                    edition = {edition}
                    fetcher = {fetcher}
                    onSave  = {handleProcessSave}/>            
            }
          </IBox>
        </Col>   
      </Row>
    </Page>
  )
}


export default withContext(EditionDetails)
