import {memoize} from 'farrapa/memoize'
import {makeSubStepDoc, makeSubStepAgreePenalty,
        makeSubStepArguments,  makeSubStepArgumentsDate, makeSubStepAgreeDate, 
         makeStep, makeColor} from './util'
import {PTYPE /*, CLOSING_REASONS*/ } from 'bl/ns/process/process'
import {PENALTY_20_STEPS, DOC, SUB_STEP_DOC} from 'bl/ns/process/steps'
import {get203Fee} from './Penalty20Fees'
import {PENALTY_TYPE } from 'bl/ns/process/penalty'

class Inspect {
  constructor(process) {
    this.p= process
  }

  @memoize
  get open () {
    const subDoc= makeSubStepDoc(this.p.files, PENALTY_20_STEPS.OPEN, DOC.PENALTY_OPEN, false, undefined, {
        penalty_type: PENALTY_TYPE.ART_203
      })
    return makeStep(PENALTY_20_STEPS.OPEN, [
      subDoc
    ])
  }

  @memoize
  get start () {
    const subDoc= makeSubStepDoc(this.p.files, PENALTY_20_STEPS.START, DOC.PENALTY_START, true, undefined, {
      penalty_type: PENALTY_TYPE.ART_203
    })
    return makeStep(PENALTY_20_STEPS.START, [
      subDoc
    ])
  }

  @memoize
  get report () {

    const resolDoc = () => {                  
      const subDoc= makeSubStepDoc(this.p.files, PENALTY_20_STEPS.REPORT, DOC.PENALTY_REPORT_RESOL, false, 
        [DOC.PENALTY_DOC_RESOL, DOC.PENALTY_NOTIF_RESOL], {
        penalty_type: PENALTY_TYPE.ART_203
      })     
      return subDoc 
    }
    const legalDoc = () => {
      const subDoc= makeSubStepDoc(this.p.files, PENALTY_20_STEPS.REPORT, DOC.PENALTY_REPORT_LEGAL, false, undefined, {
        penalty_type: PENALTY_TYPE.ART_203
      })     
      return subDoc 
    }

    let subSteps= []
    //Arguments substep
    const subArg= makeSubStepArguments(this.p.penalty_20_arguments, 'penalty_20_arguments')
    subSteps.push(subArg)
    if (subArg.done) {
      // If Arguments YES
      if (this.p.penalty_20_arguments==true) {
        // Arguments date
        const subArgD= makeSubStepArgumentsDate(this.p.penalty_20_arguments_date, 'penalty_20_arguments_date')
        subSteps.push(subArgD)
        if (subArgD.done) {
          // Resol Doc
          subSteps.push(resolDoc())
        }

        
      } else {
        // If Arguments NO
        // Agree substep
        const subAgr= makeSubStepAgreePenalty(this.p.penalty_20_agreed, 'penalty_20_agreed', false)
        subSteps.push(subAgr)
        if (subAgr.done) {
          // If Agree NO
          if (this.p.penalty_20_agreed===false) {
            // Resol Doc
            subSteps.push(resolDoc())
          } else {
            // If Agree YES
            // Agree date
            const subAgrD= makeSubStepAgreeDate(this.p.penalty_20_agreed_date, 'penalty_20_agreed_date')
            subSteps.push(subAgrD)
            if (subAgrD.done) {
              // Legal Doc
              subSteps.push(legalDoc())         
            }
          }
        }
      }
    }


    return makeStep(PENALTY_20_STEPS.REPORT, subSteps)
  }

  @memoize
  get payment () {
    const subDoc= makeSubStepDoc(this.p.files, PENALTY_20_STEPS.PAYMENT, DOC.PENALTY_PAYMENT, false, undefined, {
      penalty_type: PENALTY_TYPE.ART_203
    })
    return makeStep(PENALTY_20_STEPS.PAYMENT, [
      subDoc
    ])
  }  

  @memoize
  get start_notif_date() {
    try {
      return this.start.sub[0].doc.file.notification_date
    } catch(e) {}
    return undefined    
  }

  @memoize
  get start_notif_accepted() {
    try {
      return this.start.sub[0].doc.file.notification_accepted
    } catch(e) {}
    return undefined    
  }


  get needs_revenues() {
    return (this.p.inspect.unatt_requirement===3) 
  }

  get proposed_amount() {
    return get203Fee(this.p.process_type, this.p.inspect.unatt_requirement, /*this.p.penalty_20.reduct_provided, this.p.penalty_20_reduct_payment,*/ this.p.penalty_20_revenues)
  }

  get reduct_provided(){
    if (this.p.process_type==PTYPE.COMPLETE) {
      //return (this.p.inspect.unatt_requirement<3) 
      return (this.p.inspect.real_unatt_requirement<3) 
    }
    return false
  }

  get discounted() {
    const base= this.proposed_amount
    if (! isNaN(base)) {
      let ra= base || 0.0
      if (this.p.penalty_20.reduct_provided===true) {
        return ra*0.5
      }
    }
    return 0.0    
  }

  get raw_amount() {
    const ra= this.proposed_amount || 0.0
    return ra - this.discounted
  }


  get reducted() {
    let t= this.raw_amount
    const red= this.p.penalty_20_reduct_payment_value ?
               this.p.penalty_20_reduct_payment_value/100 : 0.40
    return t*red
  }  

  get total() {
    let t= this.raw_amount
    if (this.p.penalty_20_reduct_payment!==false) {
      const red= this.p.penalty_20_reduct_payment_value ?
      this.p.penalty_20_reduct_payment_value/100 : 0.40

      t-= t*red
    }
    return t
  }


  @memoize
  get steps() {
    // if (this.p.is_closed || !this.p.has_penalty)
    //  return []

    if (!this.p.has_penalty)
      return []

    let steps= []

    if (this.p.penalty_20_on===true) {
      steps.push(this.open)
      if (this.open.done) {
        steps.push(this.start)
        if (this.start.done) {
          steps.push(this.report)
          if (this.report.done) {
            steps.push(this.payment)
          }
        }
      }
    } 

    return steps
  }

  @memoize
  get status_step() {
    // if (this.p.is_closed || !this.p.has_penalty)
    //  return undefined

    if (!this.p.has_penalty)
      return undefined

    let st_step= undefined
    for (let i= this.steps.length-1; i>=0; i--) {
      if (st_step==undefined) {
        if (this.steps[i].completed) {
          st_step= this.steps[i]
        }
      }
    }
    if (st_step==undefined && this.steps.length>0) {
      return this.steps[0]
    }
    return st_step
  }

  @memoize
  get progress() {
    if (this.is_done) {
      return 100
    }
    if (this.steps.length==0) {
      return 0
    }

    const totSteps= 6
    
    let doneSteps= 0
    for (let i=0; i<this.steps.length;i++) {
      for (let j=0; j<this.steps[i].sub.length;j++) {
        if (this.steps[i].sub[j].done) {
          doneSteps+= 1
        }
      }
    }

    const progress = Math.round(doneSteps*100 / totSteps,0)
    return progress
  }


  @memoize
  get color() {
    let color= 'green'
    if ( this.p.penalty_20_on===true && this.report.agreed===false ) {
     color= 'pink'
    }
    return makeColor(color, this.progress)
  }


  @memoize
  get is_done() {
    if (this.status_step!=undefined) {
      return (this.status_step.id===PENALTY_20_STEPS.PAYMENT )
    }
    return false
  }
  @memoize
  get current_step() {
    return this.steps.slice(-1)[0]
  }

  @memoize
  get current_step_doc() {
    if (this.current_step) {
      for (let i=0; i<this.current_step.sub.length; i++) {
        if (this.current_step.sub[i].type==SUB_STEP_DOC) {
          return this.current_step.sub[i]
        }
      }    
    }
    return undefined
  }


  export() {
    return {
      
      open          : this.open,
      start         : this.start,
      report        : this.report,
      payment       : this.payment,
      discounted    : this.discounted,
      raw_amount    : this.raw_amount,
      total         : this.total,
      reducted      : this.reducted,
      reduct_provided: this.reduct_provided,
      start_notif_date : this.start_notif_date,
      start_notif_accepted : this.start_notif_accepted,
      status_step   : this.status_step,
      current_step  : this.current_step,
      is_done       : this.is_done,
    }
  }
}



export default Inspect  