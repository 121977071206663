import React from 'react'
import ActionButton from './ActionButton'

const ActionClose = ({ onClick, to, enabled, label }) =>
  <ActionButton color='blue'
    label={label}
    icon='cross'
    onClick={(e) => { onClick(e) }}
    to={to}
    enabled={enabled != undefined ? enabled : true}>
  </ActionButton>

export default ActionClose
