import React from 'react'
import {asDecimal} from 'farrapa/numbers'
import {FInputFloat} from 'formiga-reactstrap'
import TaxTable from '../common/TaxTable'

const ProcessPenalty20Table = ({process, onChangeProcess, canEdit}) => {

  const handleSave= (n, v, c) => {
    const changes= {[n]: v}
    onChangeProcess(changes, c)
  }

  const payment_red= process.penalty_20_reduct_payment_value || 40 

 return (
    <TaxTable>
    
      <thead>
        <tr>
          {process.penalty_20.needs_revenues
           ? <th style={{textAlign: "center"}}>Cifra Negocio</th>
           : null}
          <th style={{textAlign: "center"}}>Importe Base Sanción</th>
          <th style={{textAlign: "center"}}>Aporta doc.</th>
          <th style={{textAlign: "center"}}>Importe Sanción</th>
          <th style={{textAlign: "center"}}>Ingreso voluntario</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr className="afi_taxes_det">
          {process.penalty_20.needs_revenues
           ? 
            <td style={{verticalAlign: "middle", textAlign: "center"}}>
              
              <FInputFloat
                  name        = 'penalty_20_revenues'
                  value       = {process.penalty_20_revenues}
                  onChange    = {(v, c) => handleSave('penalty_20_revenues', parseFloat(v), c)}
                  showValidity= {1}
                  required    = {true}
                  autocomplete= {"off"}
                  label       = {undefined}
                  icon        = {false}
                  decimalSign= {","}
                  formGroupStyle= {{verticalAlign: "middle"}}
                  inputStyle  = {{maxWidth: "120px"}}
                  inline
                  readOnly   = {!canEdit}
              />     
            </td>           
           : null}          
          <td style={{verticalAlign: "middle", textAlign: "center"}}>
              {asDecimal(process.penalty_20.proposed_amount, 2)}         
          </td>
          <td style={{verticalAlign: "middle", textAlign: "center"}}>
            {process.penalty_20.reduct_provided===true ? "50%" : ''}
          </td>
          <td style={{verticalAlign: "middle", textAlign: "center"}}>
            {asDecimal(process.penalty_20.raw_amount, 2)}
          </td>
          <td style={{verticalAlign: "middle", textAlign: "center"}}>
            {process.penalty_20_reduct_payment!==false ? `${payment_red}%` : ''}
          </td>
          <td style={{color: "gray", fontSize: "1.2em", verticalAlign: "middle", textAlign: "center"}}>
          {asDecimal(process.penalty_20.total, 2)}
          </td>
        </tr>
      </tbody>
    </TaxTable>
  )
}

export default ProcessPenalty20Table