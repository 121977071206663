import React from 'react'
import {Row, Col} from 'reactstrap'
import Page from 'cli/ui/pages/layout/Page'
import {MenuCard} from 'cli/ui/components/cards'

const _BREADS = [['/', 'Inicio'], ['Configuración']]


const Settings = () => {
  return (
      <Page breads={_BREADS}
            title="Configuración">
        <Row>
          {/*
          <Col xs="12" sm="6" md="4"> 
            <MenuCard color    = "success"
                      icon     = "user"
                      link     = "/settings/accontrol/users"
                      title    = "Usuarios">
            </MenuCard>
          </Col>
          */}
          
          <Col xs="12" sm="6" md="4"> 
            <MenuCard color    = "info"
                      icon     = "irate"
                      link     = "/settings/irates"
                      title    = "Tipos de Interés">
            </MenuCard>   
          </Col>

          <Col xs="12" sm="6" md="4"> 
            <MenuCard color    = "success"
                      icon     = "agent"
                      link     = "/settings/agents"
                      title    = "Agentes">
            </MenuCard>   
          </Col>

          <Col xs="12" sm="6" md="4"> 
            <MenuCard color    = "warning"
                      icon     = "sector"
                      link     = "/settings/sectors"
                      title    = "Sectores">
            </MenuCard>   
          </Col> 

          <Col xs="12" sm="6" md="4"> 
            <MenuCard color    = "info"
                      icon     = "calendar"
                      link     = "/settings/calendar"
                      title    = "Calendario">
            </MenuCard>
          </Col>          
        </Row>

      </Page>
  )
}


export default Settings



