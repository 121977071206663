import React, {useCallback, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'


import PageAnonym from '../layout/PageAnonym'
import { Row, Col,  CardGroup, Card, CardHeader, CardBody, Alert } from 'reactstrap'
import {FForm, FInputEmail} from 'formiga-reactstrap'
import withContext from 'cli/context/withContext'

const Forgot = ({fetcher}) => {
  const navigate= useNavigate()

  const [email, setEmail]= useState('')
  const [message, setMessage]= useState(null)

  const handleForgot = useCallback(async () => {
    setMessage(undefined)

    const params= {email}
    
    const res= await fetcher.post('/user/forgot', params)

    if (res.success) {
      navigate('/page/login')  
    } else {
      setMessage(res.info)
    }
  }, [fetcher, navigate, email])


  return (
    <PageAnonym>

      <Row className="justify-content-center">
        <Col md="6">
            <CardGroup>
              <Card className="p-4"> 
                <CardHeader style={{textAlign: "center"}}>
                  <h1>Solicitar nueva contraseña</h1>
                </CardHeader>
                <CardBody>

                  <FForm  onCancel     = {undefined}
                          onSave       = {() => handleForgot()}
                          labels       = {["","Solicitar"]}>
                            <>
                              <FInputEmail name        = "email"
                                          value       = {email}
                                          required    = {true}
                                          onChange    = {(v) => {setEmail(v); setMessage(null)}}
                                          placeholder = "Su dirección de email"
                                          feedback    = "Su dirección de email"
                              />
                              {message!=undefined
                              ? <Alert color="danger">{message}</Alert>
                              : null
                              }
                              <Link to="/page/login" className="pull-left">
                                Login
                              </Link>                                 
                            </>
                  </FForm>

                 
                </CardBody>
              </Card>
            </CardGroup>

        </Col>
      </Row>

    </PageAnonym>
  )
}

export default withContext(Forgot)
