
const SPANISH_PROVINCES= {
  '01': 'Alava',                      //VI
  '02': 'Albacete',                   //AB
  '03': 'Alicante',                   //A
  '04': 'Almería',                    //AL
  '05': 'Ávila',                      //AV
  '06': 'Badajoz',                    //BA
  '07': 'Baleares',                   //PM / IB
  '08': 'Barcelona',                  //B
  '09': 'Burgos',                     //BU
  '10': 'Cáceres',                    //CC
  '11': 'Cádiz',                      //CA
  '12': 'Castellón',                  //CS
  '13': 'Ciudad Real',                //CR
  '14': 'Córdoba',                    //CO
  '15': 'Coruña',                     //C
  '16': 'Cuenca',                     //CU
  '17': 'Gerona',                     //GE / GI
  '18': 'Granada',                    //GR
  '19': 'Guadalajara',                //GU
  '20': 'Guipúzcoa',                  //SS
  '21': 'Huelva',                     //H
  '22': 'Huesca',                     //HU
  '23': 'Jaén',                       //J
  '24': 'León',                       //LE
  '25': 'Lérida',                     //L
  '26': 'La Rioja',                   //LO
  '27': 'Lugo',                       //LU
  '28': 'Madrid',                     //M
  '29': 'Málaga',                     //MA
  '30': 'Murcia',                     //MU
  '31': 'Navarra',                    //NA
  '32': 'Orense',                     //OR / OU
  '33': 'Asturias',                   //O
  '34': 'Palencia',                   //P
  '35': 'Las Palmas',                 //GC
  '36': 'Pontevedra',                 //PO
  '37': 'Salamanca',                  //SA
  '38': 'Santa Cruz de Tenerife',     //TF
  '39': 'Cantabria (Santander)',      //S
  '40': 'Segovia',                    //SG
  '41': 'Sevilla',                    //SE
  '42': 'Soria',                      //SO
  '43': 'Tarragona',                  //T
  '44': 'Teruel',                     //TE
  '45': 'Toledo',                     //TO
  '46': 'Valencia',                   //V
  '47': 'Valladolid',                 //VA
  '48': 'Vizcaya (Bilbao)',           //BI
  '49': 'Zamora',                     //ZA
  '50': 'Zaragoza',                   //Z
  '51': 'Ceuta',                      //CE
  '52': 'Melilla',                    //ML 
}

export {SPANISH_PROVINCES}