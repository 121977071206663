import React, {useState, useEffect} from 'react'
import { ActionDetails, ActionRemove, ActionHistory } from 'cli/ui/components/buttons/actions'
import NiceList from 'cli/ui/components/nice_list'
import { HyperCouncil } from 'cli/ui/components/hyper'
import { SPANISH_PROVINCES } from 'bl/ns/geo'
import processListCountThreeFlowsAllTaxes from 'cli/ui/fragments/process/fields/progress/multi/count/processListCountThreeFlowsAllTaxes'
import { IfPermission } from 'cli/context/IfPermission'


const makeKey= (council) =>
  `council_list_row_${council.id}`


const exportFields= [
  /* eslint react/display-name:0 */
  {
    name: 'name',
    label: "Ayuntamiento"
  }, 
  {
    name: 'nif',
    label: "NIF"
  }, 
  {
    name: 'province',
    label: "Provincia", 
    value: (council) => SPANISH_PROVINCES[council.province] || ''
  },
  {
    name: 'address',
    label: "Dirección"
  }, 
  {
    name: 'deh',
    label: "DEH"
  },
  {
    name: 'clerk_name',
    label: "Nombre actuario"
  }, 
  {
    name: 'clerk_pos',
    label: "Puesto actuario"
  }, 
  {
    name: 'iban',
    label: "IBAN"
  }, 
  {
    name: 'inhabitants',
    label: "Habitantes"
  }, 
]



const CouncilsList = ({ councils, onRemove, isHistorical, onHistorical }) => {
  const [councilFields, setCouncilFields]= useState([])

  useEffect(() => {

    const nCouncilFields= [
      /* eslint react/display-name:0 */
      {
        label: "Ayuntamiento",
        className: "main", 
        value: (council) => council.name,
        render: (council) =>
          <HyperCouncil key={`council_list_row_${council.id}_id`} id={council.id} name={council.name}/>
      }, 
      {
        label: "Provincia", 
        value: (council) => SPANISH_PROVINCES[council.province] || ''
      },
      {
        label: "DEH", 
        value: (council) => council.deh
      },
      ...processListCountThreeFlowsAllTaxes(),
      {
        label: "Acciones", 
        className: "right", 
        render: (council, onEvent) => 
          <>
            <ActionDetails to={"/councils/" + council.id} />
            <IfPermission code="council_edit">
              <ActionHistory onClick={() => onEvent('historical', {id: council.id})}
                            isHistorical={isHistorical}
                            enabled={true}/>          
              <ActionRemove onClick={() => onEvent('remove', {id: council.id})}
                            enabled={council.ordinance_count== 0}/>
            </IfPermission>
          </>
      }
    ]

    setCouncilFields(nCouncilFields)
  
  }, [isHistorical])

  if (! councilFields.length) {
    return null
  }

  return (
    <NiceList transparent
              fields     = {councilFields}
              exportable  = {false}
              exportFields= {exportFields}
              exportName  = 'Ayuntamientos'
              initialSort= {[0, "asc"]}
              data       = {councils}
              makeKey    = {makeKey}
              onEvent    = {(ev, params) => {
                if (ev=='remove') {
                  onRemove(params.id)
                }
                if (ev=='historical') {
                  onHistorical(params.id)
                }   
              }}
    />
  )
}

export default CouncilsList








