import ProcessI5TaxCalc from './ProcessI5Tax'
import ProcessLUTaxCalc from './ProcessLUTax'
import ProcessAETaxCalc from './ProcessAETax'
import ProcessTOTaxCalc from './ProcessTOTax'
import ProcessVTTaxCalc from './ProcessVTTax'

function processTaxCalc (tax, process) {
  return process.tax_code=='i5'
    ? new ProcessI5TaxCalc(tax, process)
    : process.tax_code=='lu'
      ? new ProcessLUTaxCalc(tax, process)
      : process.tax_code=='ae'
        ? new ProcessAETaxCalc(tax, process)
        : process.tax_code=='to'
          ? new ProcessTOTaxCalc(tax, process)
          : process.tax_code=='vt'
            ? new ProcessVTTaxCalc(tax, process)
            : {}
}

export default processTaxCalc