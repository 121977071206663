import React from 'react'
import {TimelineEvent, TimelineEventTitle, TimelineEventLeft} from 'cli/ui/components/timeline'
import Icon from 'cli/ui/components/icon'

const EventTitle = ({id, title, collapsed, onCollapse}) => {

  return (
    <TimelineEvent  key       = {`process_inspect_${id}_print_title`}
                    legend   = {{icon: "tree"}}
                    date     = {undefined}
                    style    = {{backgroundColor: '#faf0f0'}}
                    mini noIcon
                    >
        <TimelineEventLeft>
          <div className="timeline_collapse" onClick={() => onCollapse(! collapsed)}>
            <Icon icon={collapsed==true ? "circle-right" : "circle-down"} size={24} color="#967d7d" ></Icon>
          </div>
        </TimelineEventLeft>

        <TimelineEventTitle>
          {title}
        </TimelineEventTitle>
        

        
    </TimelineEvent>
  )
}

export default EventTitle