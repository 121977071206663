import React from 'react'
import { ActionsRow } from 'cli/ui/components/buttons/actions'
import {Row, Col} from 'reactstrap'

const PageHeader = ({title, actions, left}) => {
  if (title==undefined && actions==undefined) {
    return null
  }

  if ((actions==undefined) && (left!= undefined)) {
    throw "PageHeader: pass actions before left!"
  }

  const colWidth= 
    left!=undefined
    ? [5, 2, 5]
    : actions!=undefined 
    ? [0, 3, 9] 
    : [0, 12, 0]
    

  return (
    <Row className="page-header">
      <>
      {left!=undefined
          ? <Col xs="12" md={colWidth[0]}>
              <div className="page-left">
                {left}
              </div>
            </Col>
          : null
        }


        <Col xs="12" md={colWidth[1]}>
          <h1 className={`page-title`}>
            {title || ''}
          </h1>
        </Col>


        {actions != undefined
          ? <Col xs="12" md={colWidth[2]}>
              <div className="page-actions">
                <ActionsRow>
                  {actions}
                </ActionsRow>
              </div>
            </Col>
          : null}
      </>
    </Row>
  )
}

export default PageHeader