import React from 'react'

import {FForm, FInputSelect, FInputFile, FInputCheckbox} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'

import { GENRES } from 'bl/ns/genres'


const CouncilDetailsWordForm = ({ council, onChange, onSave, onDownloadLetterhead}) =>

  <FForm onCancel     = {undefined}
         onSave       = {onSave}>
          <>
            <FInputSelect
                        options     = {GENRES}
                        name        = 'major_genre'
                        label       = 'Sexo alcalde'
                        icon        = {<Icon icon={"users"}/>}
                        value       = {council.major_genre}
                        onChange    = {(v) => onChange('major_genre', v)}
                        feedback    = {"Seleciona un sexo"}
                        clearable   = {true}
                        searchable  = {true}
            />          
            <FInputFile   name        = "letterhead_doc"
                          label       = "Fichero con membrete"
                          value       = {council.letterhead_doc}
                          onChange    = {(v) => onChange('letterhead_doc', v)}
                          onDownload  = {() => onDownloadLetterhead()}
            />  

            <FInputCheckbox name   = "letterhead"
                        label      = "Usar membrete"
                        disabled   = {council.letterhead_doc?.name==undefined}
                        value      = {council.letterhead}
                        onChange   = {(v) => onChange('letterhead', v)}
            />           

            <FInputCheckbox name   = "letterhead_margins"
                        label      = "Copiar márgenes del membrete"
                        disabled   = {council.letterhead_doc?.name==undefined}
                        value      = {council.letterhead_margins}
                        onChange   = {(v) => onChange('letterhead_margins', v)}
            />                                           
          </>
  </FForm>

export default CouncilDetailsWordForm




