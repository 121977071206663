import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import {FForm} from 'formiga-reactstrap'
import TaxSelect from './TaxSelect'
import { ActionGo, ActionBack } from 'cli/ui/components/buttons/actions'


const CouncilDetailsAddEdition = ({handleBack}) => {
  const navigate= useNavigate()
  const [taxCode, setTaxCode]= useState('i5')

  const handleAdd = () => {
    navigate(`/taxes/${taxCode}/newedition`)
  }

  return (
    <>
    <style>{`
      .mb-3 {
        margin-bottom: 0 !important;
      }
    `}</style>
    <FForm inline      
           renderButtons= {() => 
              <>
                <ActionGo   onClick={() => handleAdd()}
                            label="Crear Tirada"/>
                <ActionBack onClick={() => handleBack()}
                            label=""/>
              </>
           }>
     <>
     <TaxSelect taxCode={taxCode}
                  onChange={(v) => setTaxCode(v)}/>
     </>
    </FForm>
    </>
  )
}


export default CouncilDetailsAddEdition
