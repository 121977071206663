import React from 'react'
import {intre_from_str, intre_to_str} from 'intre'
import {asDecimal} from 'farrapa/numbers'
import {Row, Col} from 'reactstrap'
import {FForm, FInputInt, FInputFloat, FInputText, FInputDate} from 'formiga-reactstrap'
import TaxHeaderFields from'../common/TaxHeaderFields'
import TaxHeaderFieldsRow from'../common/TaxHeaderFieldsRow'
import {PTYPE} from 'bl/ns/process/process'

const ProcessAETaxFields = ({ process, onChangeProcess, onChange, onGoToInspect, canEdit }) => {


  const handleCustomEndLiqDateChange = (v) => {
    const nTaxData= {...process.tax_data}
    nTaxData.custom_end_liq_date= v
    onChangeProcess({tax_data: nTaxData}, true)
  }  

  const handleChange = (name, value, confirmed) => {
    onChange(value, name, confirmed)
  }

  const missingLinks = () => {
    let mlinks= []
    if (process.tax.ready_for_interests.missing.council) {
      mlinks.push(
        <a href={`/councils/${process.council_id}`}>
          {`la población del ayuntamiento de ${process.council_name}`}
        </a>)
    }
    if (process.tax.ready_for_interests.missing.to) {
      mlinks.push(
        process.process_type==PTYPE.COMPLETE
        ? <a href="#!" onClick={() => onGoToInspect()}>
            {"la Fecha de Firma del Expediente"}
          </a>
        : <span>{"la Fecha Límite de intereses"}</span>        
      )
    }
    if (process.tax.ready_for_interests.missing.activity) {
      mlinks.push(
        <a href={`/taxpayers/det/${process.taxpayer_id}`}>
          {`la actividad del contribuyente ${process.taxpayer_name}`}
        </a>    
      )
    }    
    return mlinks
  }

  const mlinks= missingLinks()

  return (
      <FForm  inline
              renderButtons={(_valid) => null}>
        <>

          {process.tax.ready_for_interests.ok===false
           ? <Row>
              <Col lg="6">
                <TaxHeaderFields title="Falta info">
                  <TaxHeaderFieldsRow>
                    <p style={{color: "gray"}}>
                      <span>Falta info para los cálculos. Revisa </span>
                      {mlinks.map((l,idx) => 
                        <span key={`missing_link_idx_${idx}`}>
                          {l}
                          {idx<(mlinks.length-1)
                          ? <span>, </span>
                          : null}
                        </span>
                      )}
                      <span>.</span>
                    </p>
                  </TaxHeaderFieldsRow>
                </TaxHeaderFields>           
              </Col>
              {process.process_type==PTYPE.REDUCED
               ?             
                  <Col lg="6" >    
                    <TaxHeaderFields title="Límite intereses">
                      <TaxHeaderFieldsRow>
                        <FInputDate   name       = "custom_end_liq_date"
                                      value      = {intre_to_str(process.tax_data.custom_end_liq_date)}
                                      onChange   = {(v) => handleCustomEndLiqDateChange(intre_from_str(v))}
                                      icon       = {"Fecha Límite Intereses"}
                                      required   = {true}
                                      feedback   = {' '}
                                      readOnly   = {!canEdit}
                                      locale     = 'es'
                                      />
                      </TaxHeaderFieldsRow>
                    </TaxHeaderFields>
                  </Col>
               : null}
             </Row>
            : null}

          <Row>
            <Col lg="12" >    
              <TaxHeaderFields title="Datos">
                <TaxHeaderFieldsRow>
                  <Row>
                    <Col md="3" >   
                      <FInputInt name       = "section"
                                    value      = {process.tax.section}
                                    onChange   = {(v, c) => handleChange('section', v, c)}
                                    icon       = {"Sección"}
                                    required   = {true}
                                    feedback   = {' '}
                                    readOnly   = {!canEdit}/>
                    </Col>
                    <Col md="3" >   

                      <FInputText   name       = "epigraph"
                                    value      = {process.tax.epigraph || ''}
                                    onChange   = {(v, c) => handleChange('epigraph', v, c)}
                                    icon       = {"Epígrafe"}
                                    required   = {true}
                                    feedback   = {' '}
                                    readOnly   = {!canEdit}/>
                    
                    </Col>
                    <Col md="3" >   

                      <FInputInt    name       = "division"
                                    value      = {process.tax.division}
                                    onChange   = {(v, c) => handleChange('division', v, c)}
                                    icon       = {"División"}
                                    required   = {true}
                                    feedback   = {' '}
                                    readOnly   = {!canEdit}/>
                    
                    </Col>
                    <Col md="3" >   

                      <FInputText   name       = "description"
                                    value      = {process.activity || ''}
                                    onChange   = {(_v, _c) => {}}
                                    icon       = {"Description"}
                                    required   = {true}
                                    feedback   = {' '}
                                    placeholder= {"Contribuyente sin actividad"}
                                    disabled   = {true}
                                    readOnly   = {!canEdit}/>   
                    </Col>
                  </Row>
                </TaxHeaderFieldsRow>
              </TaxHeaderFields>
            </Col>

            <Col sm="12">
              <Row>
                <Col lg="6">

                  <TaxHeaderFields title="Cifra Negocio / Coeficiente">
                    <TaxHeaderFieldsRow>

                          <FInputFloat name       = "revenues"
                                        value      = {process.tax.revenues}
                                        onChange   = {(v, c) => handleChange('revenues', v, c)}
                                        icon       = {"Cifra Neg."}
                                        decimalSign= {","}
                                        required   = {false}
                                        feedback   = {' '}
                                        readOnly   = {!canEdit}/>
                          <div style={{display: "inline-block", color: "gray", fontSize: "1.2em", verticalAlign: "middle"}}>
                            {asDecimal(process.tax.coefficient, 2)}
                          </div>

                          {/*
                          <FInputFloat name       = "custom_coefficient"
                                        value      = {process.tax.custom_coefficient}
                                        onChange   = {(v, v) => handleChange('custom_coefficient', v, c)}
                                        icon       = {"Coeficiente"}
                                        required   = {false}
                                        decimalSign= {","}
                                        max        = {3}
                                        feedback   = {' '}
                                        readOnly   = {!canEdit}/> 
                          */}
                    </TaxHeaderFieldsRow>
                  </TaxHeaderFields>
                </Col>
                <Col lg="6">

                  <TaxHeaderFields title="Situación / Recargo">
                    <TaxHeaderFieldsRow>

                          <FInputFloat name       = "location_index"
                                        value      = {process.tax.location_index}
                                        onChange   = {(v, c) => handleChange('location_index', v, c)}
                                        icon       = {"Índice Situación"}
                                        required   = {false}
                                        decimalSign= {","}
                                        feedback   = {' '}
                                        max        = {3}
                                        readOnly   = {!canEdit}/>


                          <FInputFloat name       = "province_tax"
                                        value      = {process.tax.province_tax}
                                        onChange   = {(v, c) => handleChange('province_tax', v, c)}
                                        icon       = {"% Recargo provincial"}
                                        required   = {false}
                                        decimalSign= {","}
                                        feedback   = {' '}
                                        max        = {100}
                                        readOnly   = {!canEdit}/>
                    </TaxHeaderFieldsRow>
                  </TaxHeaderFields>
                </Col>
              </Row>

            </Col>                    
          </Row>
        </>
      </FForm>
  )
}


export default ProcessAETaxFields




