import React, {useState, useEffect} from 'react'


// Components
import Page          from 'cli/ui/pages/layout/Page'
import {Row, Col}    from 'reactstrap'
import { ActionAdd }   from 'cli/ui/components/buttons/actions'
import {LoadingBig}    from 'cli/ui/components/loading'
import SectorsList    from './SectorsList'
import { arrayRemove } from 'farrapa/collections'
import withContext from 'cli/context/withContext'

// breads
const _BREADS = [['/', 'Inicio'], ['/settings', 'Configuración'], ['Sectores']]

const Sectors = ({fetcher}) => {

  const [sectors, setSectors]= useState([])
  const [status, setStatus]= useState(0)

  useEffect(() => {
    async function fetchData() {
      const res= await fetcher.get('/api/sector/detail')
      const sectors= res.data
      setSectors(sectors)
      setStatus(1)
    }
    fetchData()

  }, [fetcher])

  const handleRemove = async (sectorId) => {
    const done = await fetcher.remove('/api/sector', sectorId)
    if (done) {
      let nsectors= [...sectors]
      arrayRemove(nsectors,
        nsectors.find((c) => c.id==sectorId)
      )
      setSectors(nsectors)
    }
    return done
  }

  return (
    <Page breads={_BREADS}
          title={"Sectores"}
          actions={<ActionAdd to="/settings/sectors/new" label="Crear nuevo"/>}>
      <Row>
        <Col xs="12">
            {status < 1 ?
              <LoadingBig /> :
              <SectorsList sectors={sectors}
                            onRemove={(cid) => handleRemove(cid)}/>
            }
        </Col>
      </Row>
    </Page>
  )
}


export default withContext(Sectors)








