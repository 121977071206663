import React from 'react'
import TaxSection from '../common/TaxSection'
import ProcessLUTaxFields from './ProcessLUTaxFields'
import ProcessLUTaxTable from './ProcessLUTaxTable'

const ProcessLUTaxes =  ({ process, onChange, canEdit }) => {

  return (
    <TaxSection title="Tasas">
      <ProcessLUTaxFields tax={process.tax}
                          onChange={onChange}
                          canEdit       = {canEdit}/>

      <ProcessLUTaxTable  tax={process.tax}
                          onChange={onChange}
                          canEdit       = {canEdit}/>
    </TaxSection>
  )
}


  

export default ProcessLUTaxes




