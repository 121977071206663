import React, { useEffect } from 'react'

//import { NavLink } from 'react-router-dom'

import { Nav /*, NavItem, NavLink, Badge*/ } from 'reactstrap';
import HeaderSidebarToggler from './HeaderSidebarToggler'
import HeaderNavbarBrand from './HeaderNavbarBrand'
//import HeaderMessage from './HeaderMessage'
import HeaderDropdown from './HeaderDropdown';
import Breadcrumb from './Breadcrumb'


// logos
const logo= '/static/img/bonafide_logo_lg.png'
const sygnet= '/static/img/bonafide_logo_sm.png'

const HEADER_FIXED= true


const Header = ({breads}) => {

  useEffect(() => {
    if (HEADER_FIXED) { 
      document.body.classList.add('header-fixed')
    }
  }, [])

  return (
    <header className="app-header navbar">
      <HeaderSidebarToggler className="d-lg-none" display="md" mobile />
      <HeaderNavbarBrand
        full={{ src: logo, width: 89, height: 25, alt: 'Bonages' }}
        minimized={{ src: sygnet, width: 30, height: 30, alt: 'Bonages' }}
      />
      <HeaderSidebarToggler className="d-md-down-none" display="lg" />
      <Breadcrumb paths={breads} display="lg" />
      <Nav className="ml-auto" navbar>
  
        {/*
        <HeaderMessage 
            type={state.alertType}
            onClose={() => state.showAlert(undefined)}
            />              
        <NavItem className="d-md-down-none">
          <NavLink href="#"><i className="cui-bell icons font-xl d-block"></i><Badge pill color="danger">5</Badge></NavLink>
        </NavItem>
        <NavItem className="d-md-down-none">
          <NavLink href="#"><i className="cui-list icons icons font-xl d-block"></i></NavLink>
        </NavItem>
        <NavItem className="d-md-down-none">
          <NavLink href="#"><i className="cui-location-pin icons icons font-xl d-block"></i></NavLink>
        </NavItem>
        */}
        <HeaderDropdown/>
      </Nav>
    </header>      
  )
}


export default Header
