import React from 'react'
import {intre_pretty_medium} from 'intre'
import { asPrice } from 'farrapa/numbers'
import {FInputCheckbox, FInputFloatSum} from 'formiga-reactstrap'
import { arraySum } from 'farrapa/collections'

const _atLeastZero = (a) => 
  Array.isArray(a)
    ? a.length==0
      ? [0.0]
      : a
    : [0.0]


const ProcessI5TaxesDetLine = (tax, det, onChange, showInterests, canEdit) => {

  const handleChange = (changes, confirmed) => {
    onChange(det.year, changes, confirmed)
  }

  return (
    <tr key={'tax_det_' + tax.id + '_' + det.year} className="afi_taxes_det">
      <td>{det.year}</td>
      <td  className="right">
        <FInputFloatSum name={`i5_tax_det_${det.year}_earnings`}
                     value={_atLeastZero(det.earnings_sum)}
                     onChange={(value, confirmed) => handleChange({earnings: arraySum(value), earnings_sum: value}, confirmed)}
                     decimalSign="."
                     decimals={5}
                     label={undefined}
                     description={undefined}
                     icon={false}
                     showValidity={0}
                     readOnly   = {!canEdit}


                     />
      </td>
      <td  className="right">
        <FInputFloatSum name={`i5_tax_det_${det.year}_expenses`}
                     value={_atLeastZero(det.expenses_sum)}
                     onChange={(value, confirmed) => {
                       handleChange({expenses: arraySum(value), expenses_sum: value}, confirmed)}
                     }
                     decimalSign="."
                     decimals={5}
                     icon={false}
                     showValidity={0}
                     readOnly   = {!canEdit}
                     />
      </td>
      <td className="right">{asPrice(det.checked_taxbase)}</td>
      <td  className="right">
        <FInputFloatSum name={`i5_tax_det_${det.year}_declared_taxbase`}
                     value={_atLeastZero(det.declared_taxbase_sum)}
                     onChange={(value, confirmed) => handleChange({declared_taxbase: arraySum(value), declared_taxbase_sum: value}, confirmed)}
                     decimalSign="."
                     decimals={5}
                     icon={false}
                     showValidity={0}
                     readOnly   = {!canEdit}
                     />
      </td>                  
      <td className="right">{asPrice(det.difference)}</td>
      <td className="right">{asPrice(tax.tax_rate)}</td>
      <td className="right">{asPrice(det.fee)}</td>
      <td className="right" style={tax.ready_for_interests.ok ? {} : {color: "gray"}}>
        {<div >   
            <span style={det.with_interests==0 ? {"color": "lightgray"} : {}}>
              {asPrice(det.interest)}
            </span>
            
            {showInterests 
              ?  <FInputCheckbox name={`i5_tax_det_${det.year}_with_int`}
                                  value={det.with_interests!==0}
                                  onChange={(value, confirmed) => handleChange({with_interests: value ? 1 : 0}, confirmed)}
                                  icon={false}
                                  showValidity={0}
                                  inline
                                  readOnly   = {!canEdit}/>
              : null
             }
          </div>

        }
      </td>
      <td className="right">{asPrice(det.total)}</td>
    </tr>
  )
}

const ProcessI5TaxesInterestLine = (tax, det, int) => 
  <tr key={'tax_int_' + tax.id + '_' + det.year + '_' + int.dfrom} className="afi_taxes_int">
    <td>{int.year}</td>
    <td colSpan={5}>
      {`${intre_pretty_medium(int.dfrom)} => ${intre_pretty_medium(int.dto)} ( ${int.days} días) ${int.missing_liq_limit ? '[Ojo: falta la fecha de Inicio de Intereses en la Ordenanza]': ''}`}
    </td>
    <td>{asPrice(int.irate)}</td>
    <td></td>
    <td>{asPrice(int.interest)}</td>
    <td></td>
  </tr>

const ProcessI5TaxesLines =  ({tax, onChange, showInterests, canEdit}) => {
    let elems= []
    tax.details.map((det) => {
      elems.push(ProcessI5TaxesDetLine(tax, det, onChange, showInterests, canEdit))
      if (showInterests) {
        det.interests.map((int) => {
          elems.push(ProcessI5TaxesInterestLine(tax, det, int))
        })
      }
    })
    return elems
  }


export default ProcessI5TaxesLines
