import React from 'react'
import { toMB } from 'bl/ns/docus'

const DocuList = ({docus, onDocuOpen, onDocuRemove, canEdit}) => {
  if (! docus || docus.length==0) {
    return null
  }

  const items= []

  docus.map((docu, i) => {
    items.push(
      <span key     = {`process_inspect_docu_list_${docu.id}`}>
        <span style   = {{ cursor: "pointer", textDecorationLine: "underline" }} 
              onClick = {() => onDocuOpen(docu.id)}>
          {docu.name + ' (' + toMB(docu.size) + 'mb)'}
        </span>
        {canEdit
         ? <>
            &nbsp;
            <span style   = {{ cursor: "pointer", color: "gray"}} 
                  onClick = {() => onDocuRemove(docu.id)}>( X )</span>
          </>
         : null}
      </span>
    )
    if (i<docus.length-1) {
      items.push(<span key = {`process_inspect_docu_list_${docu.id}_spce`}>, &nbsp;&nbsp;</span>)
    }
  }
)  


  return (
    <span>
      {items}
    </span>
  )
}


export default DocuList