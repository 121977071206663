import React from 'react'

import {FForm, FInputText, FInputSelectSearch} from 'formiga-reactstrap'
import Icon from 'cli/ui/components/icon'

const TaxPayerDetailsSectorForm = ({ taxPayer, sectorOptions, onChange, onSave}) =>

  <FForm onCancel     = {undefined}
         onSave       = {onSave}>
          <>
            <FInputSelectSearch  
                        options     = {sectorOptions}
                        name        = 'sector_id'
                        label       = 'Sector principal'
                        icon        = {<Icon icon={"sector"}/>}
                        value       = {taxPayer.sector_id!=undefined ? taxPayer.sector_id.toString() : ''}
                        onChange    = {(v) => onChange('sector_id', parseInt(v))}
                        feedback    = {"Seleciona un Sector"}
                        clearable   = {true}
                        searchable  = {true}
                        required    = {true}
            />          
            <FInputText name        = "activity"
                        label       = "Descripción de la actividad principal"
                        icon        = {<Icon icon={"text"}/>}
                        value       = {taxPayer.activity}
                        required    = {true}
                        feedback    = {'Inserta una descripción de actividad para los docs'}
                        onChange    = {(v) => onChange('activity', v)}
            />
                    

          </>
  </FForm>

export default TaxPayerDetailsSectorForm




