import React from 'react';
import { Button, ButtonPair } from './buttons'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class SimpleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      backdrop: true
    };
  }

  close() {
    this.setState({
      modal: false
    });

  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.show} 
               toggle={(e) => this.props.onClose(e)} 
               backdrop={true}
               modalTransition={{ timeout: 20 }}
               backdropTransition={{ timeout: 10 }}>
          <ModalHeader toggle={(e) => this.props.onClose(e)}>{this.props.title}</ModalHeader>
          <ModalBody>
            {this.props.children}
          </ModalBody>
          <ModalFooter>
            {this.props.onSave!=undefined
             ? <ButtonPair onCancel={() => this.props.onClose()}
                           onSave={() => this.props.onSave()}
                           disabled={this.props.disabled}
                           icons={this.props.icons}
                           labels={this.props.labels}/>
             : <Button onClick={() => this.props.onClose()}
                       icon   ={this.props.icons!=undefined ? this.props.icons[0] : "cross"}>
                {this.props.labels!=undefined ? this.props.labels[0] : "Cerrar"}
               </Button>
            }
            {this.props.extraButton ? this.props.extraButton : null}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default SimpleModal;