import makeColor from './makeColor'

const getColor = (process, what, progress) => {
  let color= 'green'

  if (what=='penalty_20') {
    if ( process.penalty_20_on===true) {
      if (process.penalty_20_enabled===false) {
        color= 'black'
      } else if (process.penalty_20.report.agreed===false ) {
        color= 'pink'
      }
    }
  }
  
  if (what=='penalty_19') {
    if ( process.penalty_19_on===true) {
      if (process.penalty_19_enabled===false) {
        color= 'black'
      } else if (process.penalty_19.report.agreed===false ) {
        color= 'pink'
      }
    }
  }

  if (what=='inspect') {
    if (process.is_closed) {
      color= 'black'
    } else {      
      if (process.minutes_agreed===false) {
        color= 'pink'
      } else {
        const someDlgDone = 
          process.inspect.first_dlg.done === true || 
          process.inspect.second_dlg.done=== true || 
          process.inspect.third_dlg.done === true        
        if (! someDlgDone) {
          color= 'turqoise'
        }
      }
    }
  }


  return makeColor(color, progress)
}




export default getColor