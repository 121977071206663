import React from 'react'
import { ActionDetails, ActionRemove } from 'cli/ui/components/buttons/actions'
import NiceList from 'cli/ui/components/nice_list'
import { HyperUser, HyperRole } from 'cli/ui/components/hyper'
import {intre_pretty_short_with_time} from 'intre'

const userFields= [
  /* eslint react/display-name:0 */
  {
    label: "Usuario",
    className: "main", 
    value: (user) => user.name,
    render: (user) =>
      <HyperUser key={`user_list_row_${user.id}_id`} id={user.id} name={user.name}/>
  }, 
  {
    label: "Email",
    className: "main", 
    value: (user) => user.email,
  },   
  {
    label: "Última conexión", 
    value: (user) => intre_pretty_short_with_time(user.last_conn_at)
  },
  {
    label: "Perfiles",
    value: (user) => {
      try {
        return user.roles.map((r) => 
          <HyperRole key={`user_list_row_${user.id}_id_role${r.id}`} id={r.id} name={r.description}/>
        )
      } catch(_) {return ''}
    }
  },
  {
    label: "Ayuntamientos",
    value: (user) => {
      if (user.councils!=undefined) {
        let cnames= user.councils.map((c) => c.name).sort()
        return cnames.join(', ')
      }
      return <i style={{color: "gray"}}>Todos</i>
    }
  },
  {
    label: "Acciones", 
    className: "right", 
    render: (user, onEvent) => 
      <>
        <ActionDetails to={"/settings/accontrol/users/" + user.id} />
        <ActionRemove onClick={() => onEvent('remove', {id: user.id})}/>
      </>
  }
]

const makeKey= (user) =>
  `user_list_row_${user.id}`


const UsersList = ({ users, onRemove }) =>
  <NiceList fields     = {userFields}
            initialSort= {[0, "asc"]}
            data       = {users}
            makeKey    = {makeKey}
            onEvent    = {(ev, params) => {
              if (ev=='remove') {
                onRemove(params.id)
              }
            }}
            transparent
  />

export default UsersList
