import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'


// components
import Page from 'cli/ui/pages/layout/Page'
import {Row, Col} from 'reactstrap'
import { LoadingBig } from 'cli/ui/components/loading'
import Processes       from 'cli/ui/fragments/process/list/Processes'
import TaxPayerDetailsPlanEmpty from './plans/TaxPayerDetailsPlanEmpty'
import TaxPayerDetailsSector from './boxes/TaxPayerDetailsSector'
import TaxPayerDetailsData from './boxes/TaxPayerDetailsData'
import TaxPayerDetailsNotes from './boxes/TaxPayerDetailsNotes'

import { ActionAdd } from 'cli/ui/components/buttons/actions'
import { IfPermission } from 'cli/context/IfPermission'
import withContext from 'cli/context/withContext'

let _BREADS= [['/', 'Inicio'], ['/taxpayers', 'Contribuyentes']]

const TaxPayerDetails = ({fetcher}) => {
  const params= useParams()
  
  const [taxPayer, setTaxPayer]= useState({})
  const [sectorOptions, setSectorOptions]= useState({})
  // const [plans, setPlans]= useState([])
  const [processes, setProcesses]= useState([])
  const [status, setStatus]= useState(0)
  
  const paramId = params.idTaxPayer
  const title =  taxPayer.name || '...'


  useEffect(() => {
    async function fetchData() {

      const nTaxPayer = await fetcher.find('/api/tax_payer', paramId)
      setTaxPayer(nTaxPayer)

      const sOptions = await fetcher.key_list('/api/sector')
      setSectorOptions(sOptions)      

      setStatus(1)  
      
      const nFilter= {
        taxpayer_id: paramId
      }
      const res = await fetcher.get('/api/process/search_processes', nFilter)
      const nProcesses = res.data
      
      setProcesses(nProcesses)
      setStatus(2)



    }
    fetchData()

  }, [fetcher, paramId])

  const handleChange = (name, value) => {
    const nTaxPayer= {...taxPayer}
    nTaxPayer[name]= value
    setTaxPayer(nTaxPayer)
  }

  const handleSave = async () => {
    await fetcher.upsave('/api/tax_payer', taxPayer)
  }


  return (
    <Page breads={[..._BREADS, title]}
          title  = {`Contribuyente: ${title}`}
          actions={
            <IfPermission code="tax_payer_edit">
              <ActionAdd label="Crear nuevo" to="/taxpayers/new"/>
            </IfPermission>}>
      <Row>

        <Col xs="12" md="4" lg="4">
          <TaxPayerDetailsSector taxPayer={taxPayer}
                                  sectorOptions={sectorOptions}
                                  onChange={(n,v) => handleChange(n,v)}
                                  onSave={() => handleSave()}/>
        </Col>
        <Col xs="12" md="4">
          <TaxPayerDetailsData taxPayer={taxPayer}
                              onChange={(n,v) => handleChange(n,v)}
                              onSave={() => handleSave()}/>
        </Col>
        <Col xs="12" md="4">
          <TaxPayerDetailsNotes taxPayer={taxPayer}
                              onChange={(n,v) => handleChange(n,v)}
                              onSave={() => handleSave()}/>
        </Col>

      </Row>            
      <Row>
        <Col xs="12">
          <div>
            <h2 style={{marginTop: "2em"}}>Expedientes</h2>
          </div>          
          {status<2
          ? <LoadingBig/>
          : <>
            {  processes==undefined || processes.length==0
               ? <TaxPayerDetailsPlanEmpty taxPayer={taxPayer}/>
               : <Processes   processes = {processes}
                              initialSort= {[1, 'asc']}
                              hiddenFields={['taxpayer', 'historic', 'remove']}/>
            }            
            </>
          }
        </Col>
      </Row>

    </Page>
  )
}


export default withContext (TaxPayerDetails)

