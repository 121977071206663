import React, {useState} from "react"
import {intre_pretty_short} from 'intre'
import {PopupOk} from 'cli/ui/components/popups'
import CalendarEvent from './CalendarEvent'

const CalendarShowDayEvents = ({day, events, onClose, onEventClick}) => {
  const [hoveredEvent, setHoveredEvent]= useState(undefined)

  const onEventHover = (event, on) => {
    if (on) {
      setHoveredEvent(event.id)
    } else {
      setHoveredEvent(undefined)
    }
  }  

  if (events==undefined) {
    return null
  }

  return (
    <PopupOk show={events!=undefined}
            title={`Eventos de ${intre_pretty_short(day)}`}
            onClose={onClose}>

      <div className="calendar_popup">
        {events.map((ev, eidx) => 
          <div key={`events_of_the_day_${day}_${eidx}`}
                className="row">
            <div className="col">
              <CalendarEvent day     = {day}
                            event    = {ev}
                            color    = {ev.calendar_color}
                            hovered  = {ev.id == hoveredEvent}
                            onClick  = {onEventClick}
                            onHover  = {onEventHover}/>            
            </div>
          </div>        
        )}
      </div>
    </PopupOk>
  )
}

export default CalendarShowDayEvents