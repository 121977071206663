import React from 'react'
import { EditingIBox, IBoxRow, IBoxIconedText } from 'cli/ui/components/ibox'
import CouncilDetailsDataForm from './CouncilDetailsDataForm'
import { SPANISH_PROVINCES } from 'bl/ns/geo'

const CouncilDetailsData = ({council, onChange, onSave}) => {
  const addrStatus = 
        council.address
        ? `${council.address} (${SPANISH_PROVINCES[council.province]})`
        : SPANISH_PROVINCES[council.province]
  const ibanStatus = 
        !council.iban
        ? 'IBAN pendiente de informar'
        : 'IBAN OK'

  const dehStatus = 
        !council.deh
        ? 'DEH pendiente de informar'
        : council.deh.indexOf('http')>=0
        ? <a href={council.deh}>{council.deh}</a>
        : council.deh.indexOf('@')>=0
        ? <a href={`mailto:${council.deh}`}>{council.deh}</a>
        : council.deh

  return (
    <>
      <EditingIBox title="Info"
                   empty={false}
                   fitHeight
                   permissionCode="council_edit"
                   resume={() => 
                      <>
                        <IBoxRow center >
                          <IBoxIconedText icon="location"
                                          text={addrStatus}/>
                        </IBoxRow>
                        <IBoxRow center >
                          <IBoxIconedText icon="url"
                                          text={dehStatus}/>
                        </IBoxRow>
                        <IBoxRow center >
                          <IBoxIconedText icon="dollar"
                                          text={ibanStatus}/>
                        </IBoxRow>
                        {council.inhabitants!=undefined
                         ? 
                          <IBoxRow center >
                            <IBoxIconedText icon="users"
                                            text={`${council.inhabitants} habitantes`}/>
                          </IBoxRow>
                         : null}
                      </>
                   }
                   form={(gotoResume) => 
                      <CouncilDetailsDataForm council={council}  
                                               onChange={onChange}
                                               onSave ={() => {onSave(); gotoResume()}}/>
                  }>
      </EditingIBox>
    </>
  )
}

export default CouncilDetailsData