import React, {useState, useEffect} from 'react'


// Components
import Page          from 'cli/ui/pages/layout/Page'
import {Row, Col}    from 'reactstrap'
import { ActionAdd }   from 'cli/ui/components/buttons/actions'
import {LoadingBig}    from 'cli/ui/components/loading'
import TaxPayersList   from './TaxPayersList'
import { arrayRemove } from 'farrapa/collections'
import { IfPermission } from 'cli/context/IfPermission'
import withContext from 'cli/context/withContext'


// breads
const _BREADS = [['/', 'Inicio'], ['Contribuyentes']]



const TaxPayers = ({fetcher}) => {
  
  const [taxPayers, setTaxPayers]= useState([])
  const [status, setStatus]= useState('loading')

  useEffect(() => {
    async function fetchData() {
      const res = await fetcher.get('/api/tax_payer/detail')
      const nTaxPayers = res.data
      setTaxPayers(nTaxPayers)

      setStatus('loaded')
    }
    fetchData()

  }, [fetcher])


  const handleRemove = async (taxPayerId) => {
    const done = await fetcher.remove('/api/tax_payer', taxPayerId)
    if (done) {
      let ntaxPayers= [...taxPayers]
      arrayRemove(ntaxPayers,
        ntaxPayers.find((c) => c.id==taxPayerId)
      )
      setTaxPayers(ntaxPayers)
    }
    return done
  }


  return (
    <Page breads={_BREADS}
          title="Contribuyentes"
          actions={<>
                    <IfPermission code="tax_payer_edit">
                       <ActionAdd to="/taxpayers/new" label="Crear nuevo"/>
                    </IfPermission>
                  </>}>
      <Row>
        <Col xs="12">     
          {status=='loading' ?
            <LoadingBig /> :
            <TaxPayersList taxPayers={taxPayers}
                            onRemove={(cid) => handleRemove(cid)}/>
          }
        </Col>
      </Row>
    </Page>
  )
}


export default withContext(TaxPayers)








