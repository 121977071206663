import React from 'react'
import ProcessI5Tax     from './taxi5/ProcessI5Tax'
import ProcessLUTax     from './taxlu/ProcessLUTax'
import ProcessAETax     from './taxae/ProcessAETax'
import ProcessTOTax     from './taxto/ProcessTOTax'
import ProcessVTTax     from './taxvt/ProcessVTTax'
import ProcessPenalty19 from './penalty19/ProcessPenalty19'
import ProcessPenalty20 from './penalty20/ProcessPenalty20'
import {ACTION}         from 'bl/ns/process/process'


const ProcessCalcs = (
  { process, onChangeProcess, canEdit,
    onI5Change, onI5Save, onLUChange, onAEChange, onAEDetChange, onAESave, 
    onTOChange, onVTChange, onGoToInspect }) => {
  
  return (
  <>
    {process.tax_code=='i5'
      ? 
      <ProcessI5Tax process       = {process}
                    onChangeProcess = {(changes, confirmed) => onChangeProcess(changes, ACTION.EDIT, confirmed)}
                    onChange      = {onI5Change}
                    onSave        = {onI5Save}
                    onGoToInspect = {onGoToInspect}
                    canEdit       = {canEdit}
                    />
      : process.tax_code=='lu'
        ?
          <ProcessLUTax process  = {process}
                        onChange = {onLUChange}
                        canEdit  = {canEdit}/>
        : process.tax_code=='ae'
          ?
            <ProcessAETax process  = {process}
                          onChangeProcess = {(changes, confirmed) => onChangeProcess(changes, ACTION.EDIT, confirmed)}
                          onChange = {onAEChange}
                          onDetChange = {onAEDetChange}
                          onSave   = {onAESave}
                          onGoToInspect = {onGoToInspect}
                          canEdit       = {canEdit}/>
          : process.tax_code=='to'
            ?
              <ProcessTOTax process  = {process}
                            onChange = {onTOChange}
                            canEdit  = {canEdit}/>
            : process.tax_code=='vt'
              ?
                <ProcessVTTax process  = {process}
                              onChange = {onVTChange}
                              canEdit  = {canEdit}
                              />
              : null
    }



    {process.penalty_20_on==true
     ?  
       <ProcessPenalty20 process={process}
                         onChangeProcess={(changes, confirmed) => onChangeProcess(changes, ACTION.PENALTY_DATA, confirmed)}
                         canEdit       = {canEdit}/>
     : null}

    {process.penalty_19_on==true
     ?
       <ProcessPenalty19 process={process}
                         onChangeProcess={(changes, confirmed) => onChangeProcess(changes, ACTION.PENALTY_DATA, confirmed)}
                         canEdit       = {canEdit}/>
     : null}

  </>
  )
}


export default ProcessCalcs