
import React from 'react'
import Hyper from './base'

const HyperUser = ({ id, name, color }) =>
  <Hyper bcolor  = {color || "#ecb3ff"}
         icon    = "users"
         to      = {"/settings/accontrol/users/" + id}
         text    = {name}
         permissionCode = "setts_user_edit">
  </Hyper>

export default HyperUser
