import React, {useState, useEffect, useCallback} from 'react'
import {useParams} from 'react-router-dom'


// components
import Page from 'cli/ui/pages/layout/Page'
import { LoadingBig } from 'cli/ui/components/loading'
import CouncilDetailsActions from './actions/CouncilDetailsActions'
import CouncilDetailsInfo from './info/CouncilDetailsInfo'
import CouncilDetailsProcesses from './CouncilDetailsProcesses'
import withContext from 'cli/context/withContext'

let _BREADS= [['/', 'Inicio'], ['/councils', 'Ayuntamientos']]

const CouncilDetails = ({fetcher}) => {
  const params= useParams()
  const [council, setCouncil]= useState({})
  const [plans, setPlans]= useState([])
  const [loading, setLoading]= useState(1)
  
  const councilId = params.idCouncil
  const title = council.name||'...'


  const fetchCouncil= useCallback(() => {
    const _fetchCouncil= async () => {
      setLoading(1)

      const res = await fetcher.get('/api/process/read_council_plan', {id: councilId})
      const nPlans = res.data
      setPlans(nPlans)

      const nCouncil = await fetcher.find('/api/council', councilId)
      setCouncil(nCouncil)
      setLoading(0)
    }
    _fetchCouncil()
  }, [fetcher, councilId])


  useEffect(() => {
    async function fetchData() {
      await fetchCouncil(councilId)
    }
    fetchData()
  }, [fetchCouncil, councilId])

  const handleChangeCouncil = (name, value) => {
    setCouncil({
      ...council,
      [name]: value
    })    
  }


  return (
    <Page breads={[..._BREADS, title]}
          title  = {`Ayuntamiento: ${title}`}
          actions={
            <CouncilDetailsActions
                     councilId    = {councilId}
                     hasOrdinances= {plans!=undefined && plans.length>0}
                   />}>
      {loading==1
       ? <LoadingBig/>
       : <>
           
          <CouncilDetailsInfo council={council}
                               plans={plans}
                               onChangeCouncil={handleChangeCouncil}/>
          
          <CouncilDetailsProcesses council={council}/>
         </>
      }
    </Page>
  )
}


export default withContext(CouncilDetails)
