import React from 'react'
import {Row, Col} from 'reactstrap'
import { ActionsRow } from '../buttons/actions'

const IBox = ({title, children, narrow, fitHeight, empty, watermark, actions}) => {
  return (
    <div className={`ibox ${narrow ? 'ibox-narrow' : ''} ${empty ? 'ibox-empty' : ''} ${fitHeight ? 'ibox-height' : ''}`}>
      {watermark!=undefined
      ? 
        <div className="ibox-watermark" style={{color: watermark?.color}}>
          {watermark.text}
        </div>
      : null}
      
      <Row>
        <Col xs="12">
          <div className="ibox-header">
          
            {title!=undefined
            ? <div className="ibox-title">
                <h3>{title}</h3>
              </div>
            : null}
            {actions!=undefined
            ? <div className="ibox-actions">
                <ActionsRow>
                  {actions}
                </ActionsRow>
              </div>
            : null}
          </div>      
        </Col>
      </Row>     

      <div className={"ibox-content"}>
        <Row>
          <Col xs="12">
          {children}
          </Col>
        </Row>
      </div>
      
    </div>
  )
}

export default IBox