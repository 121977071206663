

import React from 'react'
import {SUB_STEP_DOC, SUB_STEP_PROVIDE, 
        SUB_STEP_APPOINTMENT, SUB_STEP_ARGUMENTS, 
        SUB_STEP_AGREE, SUB_STEP_SERIAL, SUB_STEP_APPEAL, 
        SUB_STEP_ESTIMATE, SUB_STEP_JUDICIAL,
        SUB_STEP_ARGUMENTS_DATE, SUB_STEP_AGREE_DATE  } from 'bl/ns/process/steps'

import EventDocsAndNotif  from './events/EventDocsAndNotif'
import EventProvide       from './events/EventProvide'
import EventAppointment   from './events/EventAppointment'
import EventArguments     from './events/EventArguments'
import EventArgumentsDate from './events/EventArgumentsDate'
import EventAgree         from './events/EventAgree'
import EventAgreeDate     from './events/EventAgreeDate'
import EventSerial        from './events/EventSerial'
import EventAgreeWithDate from './events/EventAgreeWithDate'
import EventAppeal        from './events/EventAppeal'
import EventEstimate      from './events/EventEstimate'
import EventJudicial      from './events/EventJudicial'

const ProcessStepSubStep = (
                            {process, processStep, subStep, agents, docs, onPrint, 
                             onDocuDownload, onDocuSave, onDocuOpen, onDocuRemove,
                             onProcessChange, onProcessFileChange, onProcessGetNextSerial, canEdit, canPrint}) => {
  
  {/* Doc */}
  if (subStep.type==SUB_STEP_DOC) {
    return (
      <EventDocsAndNotif  process       = {process}
                          processStepId = {processStep.id}
                          subStep       = {subStep}
                          agents        = {agents}
                          docs          = {docs}
                          onPrint       = {onPrint}
                          onProcessFileChange={onProcessFileChange}
                          isLast        = {subStep.done===false}
                          canEdit       = {canEdit}
                          canPrint      = {canPrint}/>  
    )
  }

  {/* Provides or not */}
  if (subStep.type==SUB_STEP_PROVIDE) {
    return (
      <EventProvide subStep        = {subStep}
                    onDocuDownload = {onDocuDownload}
                    onDocuSave     = {onDocuSave}
                    onDocuOpen     = {onDocuOpen}
                    onDocuRemove   = {onDocuRemove}
                    onProcessChange= {onProcessChange}
                    canEdit        = {canEdit}/>
    )
  }
      
  {/* Appointment date */}
  if (subStep.type==SUB_STEP_APPOINTMENT) {
    return (  
      <EventAppointment subStep           = {subStep}
                        onProcessChange   = {onProcessChange}
                        canEdit           = {canEdit}/>
    )
  }
      
  {/* Arguments */}
  if (subStep.type==SUB_STEP_ARGUMENTS) {
    return (  
      <EventArguments subStep         = {subStep}
                      onProcessChange = {onProcessChange}
                      canEdit         = {canEdit}/>
    )
  }

  {/* Arguments Date */}
  if (subStep.type==SUB_STEP_ARGUMENTS_DATE) {
    return (  
      <EventArgumentsDate subStep         = {subStep}
                          onProcessChange = {onProcessChange}
                          canEdit         = {canEdit}/>
    )
  }
      
  {/* Agreed */}
  if (subStep.type==SUB_STEP_AGREE) {
    if (subStep.needs_date) {
      return (  
        <EventAgreeWithDate subStep         = {subStep}
                            onProcessChange = {onProcessChange}
                            canEdit         = {canEdit}/>
      )
    }

    return (  
      <EventAgree subStep         = {subStep}
                  onProcessChange = {onProcessChange}
                  canEdit         = {canEdit}/>
    )    
  }

  {/* Agreed Date */}
  if (subStep.type==SUB_STEP_AGREE_DATE) {
    return (  
      <EventAgreeDate subStep         = {subStep}
                      onProcessChange = {onProcessChange}
                      canEdit         = {canEdit}/>
    )
  }


  {/* Serial */}
  if (subStep.type==SUB_STEP_SERIAL) {
    return (  
      <EventSerial subStep         = {subStep}
                   onProcessChange = {onProcessChange}
                   onProcessGetNextSerial = {onProcessGetNextSerial}
                   canEdit        = {canEdit}/>
    )    
  }  

  {/* Appeal */}
  if (subStep.type==SUB_STEP_APPEAL) {
    return (  
      <EventAppeal subStep     = {subStep}
                   onProcessChange= {onProcessChange}
                   canEdit        = {canEdit}/>
    )
  }

  {/* Estimate */}
  if (subStep.type==SUB_STEP_ESTIMATE) {
    return (  
      <EventEstimate subStep      = {subStep}
                     onProcessChange= {onProcessChange}
                     canEdit        = {canEdit}/>
    )
  }  

  {/* Judicial */}
  if (subStep.type==SUB_STEP_JUDICIAL) {
    return (  
      <EventJudicial subStep      = {subStep}
                     onProcessChange= {onProcessChange}
                     canEdit        = {canEdit}/>
    )
  }  
  





}

export default ProcessStepSubStep

