import React from 'react'
import { ActionDetails, ActionRemove, ActionEmail, ActionUrl } from 'cli/ui/components/buttons/actions'
import NiceList from 'cli/ui/components/nice_list'
import { HyperTaxPayer , HyperSector } from 'cli/ui/components/hyper'
import processListCountThreeFlowsAllTaxes from 'cli/ui/fragments/process/fields/progress/multi/count/processListCountThreeFlowsAllTaxes'
import {uvl} from 'farrapa/commons'
import { SPANISH_PROVINCES } from 'bl/ns/geo'
import { IfPermission } from 'cli/context/IfPermission'

const makeKey= (taxPayer) =>
  `taxpayer_list_row_${taxPayer.id}`



const exportFields= [
  /* eslint react/display-name:0 */
  {
    name: 'name',
    label: "Ayuntamiento"
  }, 
  {
    name: 'nif',
    label: "DNI"
  }, 
  {
    name: 'sector_name',
    label: "Sector principal"
  }, 
  {
    name: 'activity',
    label: "Actividad principal"
  }, 
  {
    name: 'province',
    label: "Provincia", 
    value: (council) => SPANISH_PROVINCES[council.province] || ''
  },
  {
    name: 'address',
    label: "Dirección"
  }, 
  {
    name: 'email',
    label: "Email"
  },
  {
    name: 'url',
    label: "Web"
  }
]

const taxPayerFields= [
  /* eslint react/display-name:0 */
  {
    label: "Contribuyente",
    className: "main", 
    value: (taxPayer) => taxPayer.name,
    render: (taxPayer) =>
      <HyperTaxPayer key={`taxpayer_list_row_${taxPayer.id}_id`} id={taxPayer.id} name={taxPayer.name}/>
  }, 
  
  {
    label: "Sector principal", 
    value: (taxPayer) => taxPayer.sector_name,
    render: (taxPayer) => {
      return (
          taxPayer.sector_id != undefined
          ? <HyperSector    id      = {taxPayer.sector_id}
                            name    = {taxPayer.sector_name}
                            color   = {taxPayer.sector_color} />
          : null
    )}
  },
  
  {
    label: "DNI", 
    value: (taxPayer) => taxPayer.nif
  },
  ...processListCountThreeFlowsAllTaxes(),
  {
    label: "Observaciones", 
    value: (taxPayer) => uvl(taxPayer.remarks,'').replace(new RegExp("\\n", 'g'), ' ')
  },
  {
    label: "Acciones", 
    className: "right", 
    render: (taxPayer, onEvent) => 
      <>
        {taxPayer.email 
          ? <ActionEmail email={taxPayer.email}/>
          : taxPayer.url
          ? <ActionUrl url={taxPayer.url}/>
          : null
        }
        
        <ActionDetails to={"/taxpayers/det/" + taxPayer.id} />
        <IfPermission code="tax_payer_edit">
          <ActionRemove onClick={() => onEvent('remove', {id: taxPayer.id})}
                        enabled={taxPayer.processes.length == 0}/>
        </IfPermission>
      </>
  }
]
  

const TaxPayersList = ({ taxPayers, onRemove }) => {



  return (
    <NiceList transparent
              fields     = {taxPayerFields}
              exportable  = {false}
              exportFields= {exportFields}
              exportName  = 'Contribuyentes'
              initialSort= {[0, "asc"]}
              data       = {taxPayers}
              makeKey    = {makeKey}
              onEvent    = {(ev, params) => {
                if (ev=='remove') {
                  onRemove(params.id)
                }
              }}
    />
  )
}
export default TaxPayersList








