import React from 'react'
import {TimelineEvent, TimelineEventInfo, TimelineEventRight} from 'cli/ui/components/timeline'
import {Disagree, Agree} from './parts/actions'



const EventAgree = ({subStep, onProcessChange, canEdit}) => {

  const handleOnAgreed = (v) => {
    onProcessChange({[subStep.field_name_agreed]: v})
  }

  return (
      <TimelineEvent  key      ={`process_inspect_${subStep.id}_agree`}
                      legend   = {{icon: "agree", 
                                  color: subStep.agreed==undefined
                                         ? 'gray'
                                         : subStep.agreed 
                                           ? 'lightgreen' 
                                           : 'red'}}
                      date     = {undefined}
                      isLast   = {subStep.done==false}
                      >

        <TimelineEventInfo>
          <span>
            <i style={{color: "gray"}}>
              {subStep.agreed==undefined
              ? `¿${subStep.labels.agreed} o ${subStep.labels.disagreed}?`
              : subStep.agreed==true
                ? `${subStep.labels.agreed}`
                : `${subStep.labels.disagreed}`
              }
            </i>
          </span>              
        </TimelineEventInfo>
          <TimelineEventRight>
            {canEdit
             ? <>
                  <Disagree     disabled={subStep.agreed==false}
                                onClick={() => handleOnAgreed(false)}
                                label= {subStep.labels.disagreed}/>
                  <Agree        disabled={subStep.agreed==true}
                                onClick={() => handleOnAgreed(true)}
                                label= {subStep.labels.agreed}/>
               </>
             : null}
          </TimelineEventRight>
      </TimelineEvent>
  )


}

export default EventAgree