import React, {useState, useEffect} from 'react'

import withContext from 'cli/context/withContext'
import { ActionEdit } from 'cli/ui/components/buttons/actions'
import {LoadingBig} from 'cli/ui/components/loading'

import UserSettingsAvatarEdit from './UserSettingsAvatarEdit'
import { IBox } from 'cli/ui/components/ibox'

const UserSettingsAvatar = ({fetcher, user}) => {

  const [showUserSettingsAvatarEdit, setShowUserSettingsAvatarEdit] = useState(false)
  const [avatarList                , setAvatarList                ] = useState({})  
  const [userSetts                 , setUserSetts                 ] = useState(undefined)
  const [usettsLoaded              , setUsettsLoaded              ] = useState(false)
  
  useEffect(() => {
    const fetchData= async() => {
      const params= { acc_user_id: user.id }
      const result= await fetcher.read('/api/user_settings', params)
      const nUserSetts = (result && result.length>0) ? result[0] : params

      const res= await fetcher.get('/api/user_settings/avatar_list')
      const nAvatarList= res.data
      
      setUserSetts(nUserSetts)
      setAvatarList(nAvatarList)
      setUsettsLoaded(true)
    }
    fetchData()

  }, [fetcher, user.id])


  const updateAvatarUi= async (avatar) => {
    const nUserSetts= {...userSetts}
    nUserSetts.avatar = avatar
    const nid= await fetcher.upsave('/api/user_settings', nUserSetts)
    nUserSetts.id= nid
    setUserSetts(nUserSetts)
    setShowUserSettingsAvatarEdit(false)
    
    
    
    try {
      location.reload()
    } catch(_) {}

  }



  return (
    <IBox title="Avatar"
          actions={
            showUserSettingsAvatarEdit
            ? null
            : <ActionEdit key    = {'user_settings_avatar_edit'}
                           onClick= {() => setShowUserSettingsAvatarEdit(true)} 
                           label  = "Modificar"/>
          }>
        {!usettsLoaded
          ? <LoadingBig/>
          : <div>
            {showUserSettingsAvatarEdit
              ? <UserSettingsAvatarEdit 
                            onSave    = {(src) => updateAvatarUi(src)}
                            onCancel  = {() => { setShowUserSettingsAvatarEdit(false) }}
                            avatarList= {avatarList}
                            avatar    = {userSetts.avatar}/>
              : <div style={{textAlign: "center"}}>
                  <div>
                    <img src={avatarList.folder + userSetts.avatar} style={{width: "120px"}}></img>
                  </div>
                </div>
            }
            </div>
        }
    </IBox>
  )
}



export default withContext(UserSettingsAvatar)



