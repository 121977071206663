import React, {useState, useEffect, useCallback} from 'react'


import FileSaver from 'file-saver'
import { arrayRemove, collSort } from 'farrapa/collections'
import { b64toBlob } from 'farrapa/encoding'
import ProcessCalc from 'bl/calc/Process'
import {DOC} from 'bl/ns/process/steps'

import { LoadingBig } from 'cli/ui/components/loading'

import ProcessesList from './ProcessesList'
import useAlert from 'cli/ui/hooks/useAlert'
import withContext from 'cli/context/withContext'


const extendProcesses = (processes) => {
  return collSort(processes, 'name')
         .map((p) => new ProcessCalc(p))
}

const Processes = ({fetcher, processes, hiddenFields, initialSort}) => {
  const ualert= useAlert()
  const [extProcesses, setExtProcesses]= useState([])
  const [docs, setDocs]= useState([])
  const [loaded, setLoaded]= useState(0)


  // Fetch data on mount
  useEffect(() => {
    async function _fetchData() {
      const res = await fetcher.get('/api/doc/detail')
      const nDocs = res.data
      setDocs(nDocs)
      setLoaded(true)
    }
    _fetchData()    
  }, [fetcher])

  // Extend processes
  useEffect(() => {
    if (processes) {
      const nExtProcesses= extendProcesses(processes)
      setExtProcesses(nExtProcesses)
    }
  }, [processes])
    

  const handleProcessHistorical = useCallback((processId, historical) => {
    const _handleProcessHistorical = async () => {
      const done = await fetcher.remove('/api/process', {id: processId, historical: historical})
      if (done) {
        let nExtProcesses= [...extProcesses]
        nExtProcesses
          .find((c) => c.id==processId)
          .historical= historical
        setExtProcesses(nExtProcesses)
      }
      return done
    }  
    return _handleProcessHistorical()
  }, [fetcher, extProcesses])


  const handleProcessRemove = useCallback((processId) => {
    const _handleProcessRemove = async () => {
      const done = await fetcher.remove('/api/process', processId)
      if (done) {
        let nExtProcesses= [...extProcesses]
        arrayRemove(nExtProcesses,
          nExtProcesses.find((c) => c.id==processId)
        )
        setExtProcesses(nExtProcesses)
      }
      return done
    }
    return _handleProcessRemove()
  }, [fetcher, extProcesses])

  const _handlePrintDocList = async (printList) => {
    const params = { processes: printList }

    const res = await fetcher.post('/api/process/print_file_list', params)
    const doc = res.data
    if (doc) {
      // Print docs.
      // TODO Some transition please      
      if (doc.buffer) {
        const b = b64toBlob(doc.buffer, 'application/zip')
        FileSaver.saveAs(b, doc.filename)    
      } else {
        ualert('Oops! Parece que no hay documentos asociados!', 'error')
      }

      // Update processes
      if (doc?.nfiles!=undefined) {
        let nProcesses= extProcesses.map((p) => p.toJson())
        doc.nfiles.map((n) => {
          const procIdx= extProcesses.findIndex((p) => p.id==n.process_id)
          const nProcess= extProcesses[procIdx]

          const fileIdx= nProcess.files.findIndex((f) => f.process_step==n.file.process_step && f.process_doc==n.file.process_doc)
          if (fileIdx>=0) {
            nProcess.files[fileIdx] = n.file
          } else {
            nProcess.files.push(n.file)
          }
          nProcess.undo= n.undo

          nProcesses[procIdx]= nProcess
        })

        setExtProcesses(extendProcesses(nProcesses))
      }
    }
  }

  const getPrintUnitFromDocId = (process, docId, flow) => {
    const doc= docs.find((d) => d.id==docId)
    const processDoc= doc.process_doc

    let file= process.files.find((f) => f.process_doc==processDoc)
    const processStep= file?.process_step || flow.current_step.id

    return [process.id, processStep, processDoc, docId]
  }

  const handleProcessesListPrint = async (selectedDocs) => {
    let printList= []

    selectedDocs.map(([pid, docs]) => {
      const process= extProcesses.find((p) => p.id==pid)

      const insDocId= parseInt(docs.inspect)
      const p20DocId= parseInt(docs.penalty_20)
      const p19DocId= parseInt(docs.penalty_19)

      if (! isNaN(insDocId)) {
        printList.push(
          getPrintUnitFromDocId(process, insDocId, process.inspect)
        )
      }

      if (! isNaN(p20DocId)) {
        printList.push(
          getPrintUnitFromDocId(process, p20DocId, process.penalty_20)
        )
      }
      
      if (! isNaN(p19DocId)) {
        printList.push(
          getPrintUnitFromDocId(process, p19DocId, process.penalty_19)
        )
      }      
 
    })

    _handlePrintDocList(printList)
  }  


  const handleProcessesPrintStart = () => {
    let printList= []

    const docId= docs.filter((d) => d.process_doc==DOC.OPEN)[0].id

    const sortedProcs= collSort(extProcesses, 'name', 'asc')
    sortedProcs.map((p) => {
     printList.push(
       getPrintUnitFromDocId(p, docId, p.inspect)
     )
    })
    _handlePrintDocList(printList)
  }

  if (!loaded) {
    return (
      <LoadingBig/>
    )
  }

  return (
    <ProcessesList 
        processes            = {extProcesses}
        hiddenFields         = {hiddenFields}
        initialSort          = {initialSort}
        docs                 = {docs}
        onProcessesListPrint = {(selectedDocs) => handleProcessesListPrint(selectedDocs)}
        onProcessesPrintStart= {() => handleProcessesPrintStart()}
        onProcessHistorical  = {(pid, historical) => handleProcessHistorical(pid, historical)}
        onProcessRemove      = {(pid) => handleProcessRemove(pid)}/>
  )
}


export default withContext(Processes)
