import React from 'react'
import ActionButton from './ActionButton'

const ActionLess = ({ onClick, to, enabled, label, color }) =>
  <ActionButton color={color || 'orange'}
    label={label}
    icon='minus'
    onClick={onClick}
    to={to}
    enabled={enabled != undefined ? enabled : true}>
  </ActionButton>

export default ActionLess
